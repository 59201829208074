export const experimentSnippetText = `def train(training_data, parameters, context,  base_directory):

\t# <YOUR CODE>

\treturn {
    \t\t"artifact": "path/to/artefact",
    \t\t"metadata": {},
    \t\t"metrics": {},
    \t\t"additional_output_files": []
\t}`;

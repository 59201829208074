import { parseUrl, stringifyUrl } from "query-string";

import type { StringifiableRecord } from "query-string";

export const routeMaker = (url: string, query?: StringifiableRecord) =>
  query ? stringifyUrl({ url, query }) : url;

export const routeParser = (url: string) => parseUrl(url);

export const routes = {
  index: () => `/`,
  signout: {
    index: () => `/sign-out`,
  },
  signup: {
    index: () => `/sign-up`,
    terms: {
      index: () => `/sign-up/terms`,
    },
    ":email": (email: string) => ({
      index: () => `/sign-up/${email}`,
    }),
    activate: {
      ":token": (token: string) => ({
        index: () => `/sign-up/activate/${token}`,
      }),
    },
  },
  signin: {
    index: () => `/sign-in`,
    twoFA: {
      index: () => `/sign-in/2fa`,
    },
    password: {
      index: () => `/sign-in/password`,
    },
    ":provider": (provider: string) => ({
      index: () => `/sign-in/${provider}`,
    }),
  },
  resetPassword: {
    index: () => `/reset-password`,
    ":token": (token: string) => ({
      index: () => `/reset-password/${token}`,
    }),
  },
  profile: {
    index: () => `/profile`,
    account: {
      index: () => `/profile/account`,
    },
    twoFa: {
      index: () => `/profile/2fa`,
      create: {
        index: () => `/profile/2fa/create`,
      },
    },
  },
  organizations: {
    index: () => `/organizations`,
    ":organizationName": (organizationName: string) => ({
      index: () => `/organizations/${organizationName}`,
      dashboard: {
        index: () => `/organizations/${organizationName}/dashboard`,
      },
      subscription: {
        index: () => `/organizations/${organizationName}/subscription`,
      },
      team: {
        index: () => `/organizations/${organizationName}/team`,
        create: {
          index: () => `/organizations/${organizationName}/team/create`,
        },
      },
      overview: {
        index: () => `/organizations/${organizationName}/overview`,
      },
      projects: {
        index: () => `/organizations/${organizationName}/projects`,
        ":projectName": (projectName: string) => ({
          index: () =>
            `/organizations/${organizationName}/projects/${projectName}`,
          deployments: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/deployments`,
            create: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/deployments/create`,
            },
            ":deploymentName": (deploymentName: string) => ({
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}`,
              versions: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions`,
                create: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/create`,
                },
                ":versionName": (versionName: string) => ({
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}`,
                  edit: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/edit`,
                  },
                  duplicate: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/duplicate`,
                  },
                  general: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/general`,
                  },
                  requests: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/requests`,
                    batch: {
                      create: {
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/requests/batch/create`,
                      },
                    },
                  },
                  metrics: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/metrics`,
                  },
                  environmentVariables: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/environment-variables`,
                    copy: {
                      index: () =>
                        `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/environment-variables/copy`,
                    },
                  },
                  revisions: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/revisions`,
                  },
                  activeInstances: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/active-instances`,
                  },
                }),
              },
              edit: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/edit`,
              },
              duplicate: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/duplicate`,
              },
              general: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/general`,
              },
              configuration: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/configuration`,
              },
              auditEvents: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/audit-events`,
              },
              environmentVariables: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/environment-variables`,
                copy: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/environment-variables/copy`,
                },
              },
              useDeployment: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/use-deployment`,
                configure: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/use-deployment/configure`,
                },
              },
            }),
          },
          pipelines: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/pipelines`,
            create: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/pipelines/create`,
            },
            ":pipelineName": (pipelineName: string) => ({
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}`,
              versions: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions`,
                create: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/create`,
                },
                ":versionName": (versionName: string) => ({
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}`,
                  edit: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/edit`,
                  },
                  duplicate: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/duplicate`,
                  },
                  editMode: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/edit-mode`,
                  },
                  general: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/general`,
                    objects: {
                      create: {
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/general/objects/create`,
                      },
                      ":objectName": (objectName: string) => ({
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/general/objects/${objectName}`,
                      }),
                    },
                    attachments: {
                      create: {
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/general/attachments/create`,
                      },
                      ":attachmentId": (attachmentId: string) => ({
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/general/attachments/${attachmentId}`,
                      }),
                    },
                  },
                  requests: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/requests`,
                    batch: {
                      create: {
                        index: () =>
                          `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/requests/batch/create`,
                      },
                    },
                  },
                  metrics: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/metrics`,
                  },
                }),
              },
              edit: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/edit`,
              },
              general: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/general`,
              },
              configuration: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/configuration`,
              },
              auditEvents: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/audit-events`,
              },
              usePipeline: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/use-pipeline`,
                configure: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/pipelines/${pipelineName}/use-pipeline/configure`,
                },
              },
            }),
          },
          storage: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/storage/buckets`,
            create: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/storage/buckets/create`,
            },
            ":bucketName": (bucketName: string) => ({
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}`,
              general: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}`,
                files: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}/files`,
                },
                configuration: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}/configuration`,
                },
                permissions: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}/permissions`,
                },
              },
              edit: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/storage/buckets/${bucketName}/edit`,
              },
            }),
          },
          training: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/training`,
            experiments: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/training/experiments`,
              ":experimentName": (experimentName: string) => ({
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}`,
                general: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/general`,
                },
                environmentVariables: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/environment-variables`,
                },
                edit: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/edit`,
                },
                runs: {
                  create: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/runs/create`,
                  },
                  ":runId": (runId: string) => ({
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/runs/${runId}`,
                    general: {
                      index: () =>
                        `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/runs/${runId}/general`,
                    },
                    metrics: {
                      index: () =>
                        `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/runs/${runId}/metrics`,
                    },
                    duplicate: {
                      index: () =>
                        `/organizations/${organizationName}/projects/${projectName}/training/experiments/${experimentName}/runs/${runId}/duplicate`,
                    },
                  }),
                },
              }),
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/training/experiments/create`,
              },
            },
            evaluation: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/training/evaluation`,
            },
          },
          requestSchedules: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/request-schedules`,
            create: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/request-schedules/create`,
            },
            ":scheduleName": (scheduleName: string) => ({
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/request-schedules/${scheduleName}`,
              edit: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/request-schedules/${scheduleName}/edit`,
              },
              duplicate: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/request-schedules/${scheduleName}/duplicate`,
              },
              general: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/request-schedules/${scheduleName}/general`,
              },
            }),
          },
          importsExports: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/imports-exports`,
            imports: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/imports-exports/imports`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/imports-exports/imports/create`,
              },
            },
            exports: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/imports-exports/exports`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/imports-exports/exports/create`,
              },
            },
          },
          projectSettings: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/project-settings`,
            general: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/project-settings/general`,
            },
            instanceTypes: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/project-settings/instance-types`,
            },
            corsDomains: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/project-settings/cors-domains`,
            },
            instanceTypeGroups: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/project-settings/instance-type-groups`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/project-settings/instance-type-groups/create`,
              },
              ":groupId": (groupId: string) => ({
                edit: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/project-settings/instance-type-groups/${groupId}/edit`,
                },
              }),
            },
          },
          logs: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/logs`,
          },
          monitoring: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/monitoring`,
            requests: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/requests`,
            },
            general: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/general`,
            },
            metrics: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/metrics`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/monitoring/metrics/create`,
              },
              ":metricName": (metricName: string) => ({
                edit: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/monitoring/metrics/${metricName}/edit`,
                },
              }),
            },
            webHooks: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/webhooks`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/monitoring/webhooks/create`,
              },
              ":webHookName": (webHookName: string) => ({
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/monitoring/webhooks/${webHookName}`,
                details: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/monitoring/webhooks/${webHookName}/general`,
                },
                edit: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/monitoring/webhooks/${webHookName}/edit`,
                },
              }),
            },
            auditEvents: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/audit-events`,
            },
            activeInstances: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/monitoring/active-instances`,
            },
          },
          environments: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/environments`,
            base: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/environments/base`,
            },
            custom: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/environments/custom`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/environments/custom/create`,
              },
              ":environmentName": (environmentName: string) => ({
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}`,
                details: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}/details`,
                  general: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}/details/general`,
                  },
                  revisions: {
                    index: () =>
                      `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}/details/revisions`,
                  },
                },
                duplicate: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}/duplicate`,
                },
                edit: {
                  index: () =>
                    `/organizations/${organizationName}/projects/${projectName}/environments/custom/${environmentName}/edit`,
                },
              }),
            },
          },
          settings: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/settings`,
            users: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/settings/users`,
            },
            roles: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/settings/roles`,
            },
            apiTokens: {
              index: () =>
                `/organizations/${organizationName}/projects/${projectName}/settings/api-tokens`,
              create: {
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/settings/api-tokens/create`,
              },
              ":userId": (userId: string) => ({
                index: () =>
                  `/organizations/${organizationName}/projects/${projectName}/settings/api-tokens/${userId}`,
              }),
            },
          },
          dashboard: {
            index: () =>
              `/organizations/${organizationName}/projects/${projectName}/dashboard`,
          },
        }),
      },
    }),
  },
};

Object.freeze(routes);

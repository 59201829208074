import * as Sentry from "@sentry/react";

import { sentryEnabled } from "libs/env/helpers";

import type { History } from "history";

export const setupSentry = ({
  dsn,
  release,
  environment,
  history,
}: {
  dsn: string;
  release: string;
  environment: string;
  history: History;
}) => {
  Sentry.init({
    dsn: dsn,
    release: release,
    environment,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
    tracesSampleRate: 1.0,
    // Redux state normalization
    normalizeDepth: 3,
  });
};

interface ConfigureSentryScopeProps {
  email?: string;
  organization?: string | number;
  project?: string | number;
}

export const configureSentryScope = ({
  email,
  organization,
  project,
}: ConfigureSentryScopeProps) => {
  if (sentryEnabled()) {
    const scope = Sentry.getCurrentScope();

    if (scope) {
      if (email) scope.setUser({ email });
      if (organization) scope.setTag("organization_id", organization);
      if (project) scope.setTag("project_id", project);
    }
  }
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    return {
      ...state,
      auth: null,
      status: null,
    };
  },
});

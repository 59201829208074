import { Grid } from "@mui/material";
import { filter } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useDeploymentVersionEnvironmentVariablesList } from "libs/data/endpoints/deployments/deployments";
import { explanations } from "libs/utilities/explanations";

import { FormSection } from "components/molecules";
import { EnvVarsCopy, EnvVarsOverview } from "components/organisms";

import { SYS_DEFAULT_BUCKET, TRAINING_DEPLOYMENT } from "../../constants";

type ExperimentUpdateEnvVarsProps = {
  experimentName: string;
};

export const ExperimentUpdateEnvVars = ({
  experimentName,
}: ExperimentUpdateEnvVarsProps) => {
  const [showOverview, setShowOverview] = useState(true);
  const { projectName } = useParams<{ projectName: string }>();
  const { data: variables } = useDeploymentVersionEnvironmentVariablesList(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );
  const filteredVariables = variables
    ? filter(variables, (variable) => variable.name !== SYS_DEFAULT_BUCKET)
    : undefined;

  const onEnvVarCreate = () => {
    setShowOverview(false);
  };

  const onEnvVarCopy = () => {
    setShowOverview(true);
  };

  return (
    <FormSection
      title="Environment variables"
      description={explanations.training.experiments.environment_variables}
    >
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {showOverview ? (
          <Grid item xs={12}>
            <EnvVarsOverview
              deploymentName={TRAINING_DEPLOYMENT}
              deploymentVersionName={experimentName}
              warningMessage="The experiment is restarting and your changes will be available in ~30 seconds"
              onCopyClick={onEnvVarCreate}
              isUsedInForm
              envVars={filteredVariables}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <EnvVarsCopy
              deploymentName={TRAINING_DEPLOYMENT}
              deploymentVersionName={experimentName}
              onCopySuccess={onEnvVarCopy}
              isPage={false}
            />
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
};

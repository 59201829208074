import { Field } from "formik";
import { get } from "lodash";

import { ControlledCheckbox } from "components/molecules/ControlledInputs";

import type { ControlledCheckboxProps } from "components/molecules/ControlledInputs";
import type { FormikProps, FormikValues } from "formik";

export type FormikCheckboxProps = Omit<
  ControlledCheckboxProps,
  "error" | "name" | "onChange"
> & {
  control: FormikProps<FormikValues>;
  fastField?: boolean;
  name: string;
  showErrors?: boolean;
  validation?: any;
};

export const FormikCheckbox = ({
  control,
  name,
  ...props
}: FormikCheckboxProps) => {
  return (
    <Field name={name}>
      {() => (
        <ControlledCheckbox
          value={get(control.values, name) ?? ""}
          onChange={async (_e, checked: boolean) => {
            await control.setFieldValue(name, checked);
            await control.setFieldTouched(name, true);
          }}
          name={name}
          {...props}
        />
      )}
    </Field>
  );
};

import FolderIcon from "@mui/icons-material/Folder";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { FILES_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { validatePermissionsForUser } from "libs/data/endpoints/roles/roles";

import { FullScreenLoader, Loader } from "components/atoms";
import { ItemsListCard, PageContainer } from "components/molecules";
import { ExceededLimitsDialog } from "components/organisms";

import { IncorrectPermissionsDialog } from "./:bucketName/dialogs/IncorrectPermissionsDialog";
import { Bucket } from "./Bucket";

export const BucketsOverview = () => {
  const [isExceededLimitsDialogOpen, setIsExceededLimitsDialogOpen] =
    useState(false);
  const [bucketLimitPassed, setBucketLimitPassed] = useState(false);
  const [
    isIncorrectPermissionsDialogOpen,
    setIsIncorrectPermissionsDialogOpen,
  ] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const baseUrl = useContext(BaseUrlContext);
  const { projectName, organizationName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: buckets, error, isValidating } = useBucketsList(projectName);
  const { data: organizationFeaturesLimit } =
    useOrganizationsFeaturesGet(organizationName);

  const isValidatingOnEmptyData = !buckets?.length && isValidating;
  const isLoading = !buckets && !error;

  useEffect(() => {
    buckets &&
      organizationFeaturesLimit &&
      setBucketLimitPassed(
        buckets.length >= organizationFeaturesLimit.max_buckets
      );
  }, [organizationFeaturesLimit, buckets]);

  const handleBucketCreate = () => {
    if (bucketLimitPassed) setIsExceededLimitsDialogOpen(true);
    else history.push(`${baseUrl}/create`);
  };

  const handleBucketOpen = async (bucketName: string) => {
    const permissions = await validatePermissionsForUser(projectName, {
      resource: bucketName,
      resource_type: "bucket",
      permissions: Object.values(FILES_PERMISSIONS),
    });
    if (permissions.find((item) => item.success))
      history.push(`${baseUrl}/${bucketName}`);
    else setIsIncorrectPermissionsDialogOpen(true);
  };

  return (
    <PageContainer>
      <PageHeader title="Storage" />
      <BreadcrumbsItem to={match.url}>Storage</BreadcrumbsItem>
      <FullScreenLoader displayed={isLoading} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {buckets?.length ? (
            <ItemsListCard
              title="Your buckets"
              itemName="bucket"
              titleIcon={FolderIcon}
              items={buckets}
              onCreateClick={handleBucketCreate}
              hasAddBox
              maxHeight={spacing[500]}
            >
              {(item) => (
                <Bucket
                  item={item}
                  onClick={() => handleBucketOpen(item.name)}
                />
              )}
            </ItemsListCard>
          ) : (
            isValidatingOnEmptyData && <Loader />
          )}
        </Grid>
      </Grid>
      <ExceededLimitsDialog
        open={isExceededLimitsDialogOpen}
        onClose={() => setIsExceededLimitsDialogOpen(false)}
        name="buckets"
      />
      <IncorrectPermissionsDialog
        open={isIncorrectPermissionsDialogOpen}
        onClose={() => setIsIncorrectPermissionsDialogOpen(false)}
      />
    </PageContainer>
  );
};

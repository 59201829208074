import { Box, CircularProgress } from "@mui/material";

import type { ReactNode } from "react";

export const ButtonLoadingOverlay = ({ children }: { children: ReactNode }) => (
  <>
    {children}
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  </>
);

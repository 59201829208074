import { Paper, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { ConnectedRouter } from "connected-react-router";
import { useEffect, useState } from "react";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";
import { useHistory } from "react-router-dom";
import { SWRConfig } from "swr";

import { darkTheme, lightTheme } from "assets/styles/theme";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { swrConfig } from "libs/data/axios";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import {
  googleAnalyticsEnabled,
  hotjarEnabled,
  productFruitsEnabled,
  sentryEnabled,
  trackingEnabled,
} from "libs/env/helpers";
import {
  ProductFruitsProvider,
  setupGoogleAnalytics,
  setupHotjar,
  setupHubspot,
  setupSentry,
} from "libs/third-parties";
import { IndexPage } from "pages";
import { useGetThemeMode } from "store/features/themeMode";
import { history, store } from "store/store";

import { CookieBanner, Notifier } from "components/organisms";
import {
  ComposeProviders,
  ErrorBoundary,
  SnackbarCustomProvider,
} from "components/utilities";

import "./App.scss";
import "assets/styles/global.scss";

if (sentryEnabled()) {
  setupSentry({
    dsn: env.get(ENV_NAMES.SENTRY_DSN),
    release: process.env.REACT_APP_SENTRY_RELEASE || "",
    environment: env.get(ENV_NAMES.NAME),
    history,
  });
}

interface AppProps {
  cookiesEnabled?: boolean;
}

const App = ({ cookiesEnabled }: AppProps) => {
  const themeMode = useGetThemeMode();
  const history = useHistory();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [hotjarLoaded, setHotjarLoaded] = useState(false);
  const [hubspotLoaded, setHubspotLoaded] = useState(false);
  const [cookies, , removeCookie] = useCustomCookies();

  useEffect(() => {
    const path = history.location.pathname;
    const hubspotEnabled =
      path.includes("/sign-in") || path.includes("/sign-up");

    if (googleAnalyticsEnabled() && cookiesEnabled && !googleLoaded) {
      setGoogleLoaded(true);
      setupGoogleAnalytics();
    }

    if (
      cookiesEnabled &&
      trackingEnabled() &&
      hubspotEnabled &&
      !hubspotLoaded
    ) {
      setHubspotLoaded(true);
      setupHubspot();
    }

    if (cookiesEnabled && hotjarEnabled() && !hotjarLoaded) {
      setHotjarLoaded(true);
      setupHotjar();
    }

    if (!cookiesEnabled) {
      Object.keys(cookies).forEach((cookie) => {
        if (
          ![COOKIE_NAMES.COOKIE_CONSENT, COOKIE_NAMES.STATE_KEY].includes(
            cookie
          )
        ) {
          removeCookie(cookie);
          removeCookie(cookie, { path: "/", domain: ".ubiops.dev" });
          removeCookie(cookie, { path: "/", domain: ".ubiops.com" });
        }
      });
    }
    // skip the refresh after `cookie` changes to avoid recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleLoaded, hotjarLoaded, hubspotLoaded, history, cookiesEnabled]);

  const mainTheme = themeMode.mode === "dark" ? darkTheme : lightTheme;

  return (
    // All theme-related providers need to wrap the app directly to work properly
    <ThemeProvider key="8" theme={mainTheme}>
      <Notifier />
      <Paper
        sx={{
          backgroundColor: mainTheme.palette.background.paper,
        }}
        elevation={0}
      >
        <IndexPage />
      </Paper>
    </ThemeProvider>
  );
};

const AppWrapper = () => {
  const [cookies] = useCustomCookies();
  const [isAllCookiesEnabled, setAllCookiesEnabled] = useState<boolean>(
    cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing
  );

  return (
    <ComposeProviders
      providers={[
        <ErrorBoundary key="0" />,
        <CookiesProvider key="1" />,
        <ReduxProvider key="2" store={store} />,
        <ConnectedRouter key="3" history={history} />,
        <SWRConfig key="4" value={swrConfig} />,
        <BreadcrumbsProvider key="5" />,
        <StyledEngineProvider key="7" injectFirst></StyledEngineProvider>,
        <SnackbarCustomProvider key="9" />,
      ]}
    >
      <App cookiesEnabled={isAllCookiesEnabled} />
      <CookieBanner enableCookies={() => setAllCookiesEnabled(true)} />
      {productFruitsEnabled() && <ProductFruitsProvider />}
    </ComposeProviders>
  );
};

export default AppWrapper;

import ArrowBack from "@mui/icons-material/ArrowBackRounded";
import User from "@mui/icons-material/PersonRounded";
import Shield from "@mui/icons-material/SecurityRounded";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { routes } from "routes";

import { MainLayout } from "components/organisms";
import { Route } from "components/utilities";

import { DevicesOverview } from "./2fa/DevicesOverview";
import DeviceCreate from "./2fa/create/DeviceCreate";
import AccountDetails from "./account/AccountDetails";

const profileRoutes = (baseUrl: string) => [
  [
    {
      label: "Back to organization",
      path: "/organizations",
      icon: ArrowBack,
    },
  ],
  [
    {
      label: "Account",
      path: `${baseUrl}/account`,
      icon: User,
    },
    {
      label: "2FA",
      path: `${baseUrl}/2fa`,
      icon: Shield,
    },
  ],
];

export const ProfileRoutesType = typeof profileRoutes;

export const ProfilePage = () => {
  const match = useRouteMatch();

  return (
    <RootUrlContext.Provider value={match.url}>
      <BaseUrlContext.Provider value={match.url}>
        <BreadcrumbsItem to={match.url}>Profile</BreadcrumbsItem>
        <MainLayout routes={profileRoutes}>
          <Switch>
            <Route
              exact
              path={routes.profile.account.index()}
              component={AccountDetails}
            />
            <Route
              path={routes.profile.twoFa.create.index()}
              component={DeviceCreate}
            />
            <Route
              exact
              path={routes.profile.twoFa.index()}
              component={DevicesOverview}
            />
            <Redirect to={routes.profile.account.index()} />
          </Switch>
        </MainLayout>
      </BaseUrlContext.Provider>
    </RootUrlContext.Provider>
  );
};

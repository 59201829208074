import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";

import { spacing } from "assets/styles/theme";

type CardAccordionProps = {
  summary?: React.ReactNode;
  details?: React.ReactNode;
  icon?: React.ReactNode;
};

export const CardAccordion = ({
  summary,
  details,
  icon,
}: CardAccordionProps) => {
  return (
    <Accordion disableGutters elevation={0} square>
      <AccordionSummary
        sx={{
          padding: 0,
          justifyContent: "start",
          alignItems: "center",
          minHeight: spacing[16],
          "& > .MuiAccordionSummary-content": {
            flexGrow: "unset",
            margin: 0,
            marginRight: spacing[16],
          },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container direction="row" alignItems="center">
          <Box marginRight={1} display="flex">
            {icon}
          </Box>
          <Typography sx={{ fontSize: 17 }} variant="h5">
            {summary}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: spacing[8], paddingBottom: 0 }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

import Cancel from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material";

import { MaterialIcon } from "components/atoms/Icon";

import { Chip } from "./Chip";

import type { AppThemeProps } from "assets/styles/theme/theme";

export interface DeleteChipProps {
  label: string;
  onDelete: () => void;
}

export const DeleteChip = (props: DeleteChipProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Chip
      deleteIcon={
        <MaterialIcon
          component={Cancel}
          htmlColor={theme.palette.primary.main}
        />
      }
      {...props}
    />
  );
};

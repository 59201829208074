import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  DeploymentRequestParameters,
  PipelineRequestParameters,
} from "components/organisms/RequestDialog/RequestDialog";
import type { REQUEST_TASK, UPLOAD_TASK } from "libs/constants/constants";
import type {
  DeploymentRequestCreateResponseResult,
  DeploymentRequestsCreateDataBody,
  PipelineRequestCreateResponseResult,
} from "libs/data/models";
import type { ERROR, LOADED, LOADING } from "libs/utilities/request-statuses";
import type { RootState } from "store/store";

export type RequestTask = {
  id: string;
  status: typeof LOADED | typeof LOADING | typeof ERROR;
  message: string;
  type: typeof REQUEST_TASK;
  requestParameters: PipelineRequestParameters | DeploymentRequestParameters;
  result?: {
    responseData?:
      | DeploymentRequestCreateResponseResult
      | PipelineRequestCreateResponseResult;
    requestData: DeploymentRequestsCreateDataBody;
  };
};

export type UploadTask = {
  id: string;
  status: typeof LOADED | typeof LOADING;
  message: string;
  type: typeof UPLOAD_TASK;
  version?: { id?: string; deployment: string; version: string };
  bucket?: { name: string; prefix: string };
  progress?: number;
};

export interface TaskState {
  tasks: (UploadTask | RequestTask)[];
  tasksManager: boolean;
}

const initialState: TaskState = {
  tasks: [],
  tasksManager: false,
};

export const taskManager = createSlice({
  name: "taskManager",
  initialState,
  reducers: {
    toggleTasksManager: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.tasksManager = action.payload;
      else state.tasksManager = !state.tasksManager;
    },
    addTask: (state, action: PayloadAction<RequestTask | UploadTask>) => {
      state.tasks.unshift(action.payload);
      taskManager.caseReducers.toggleTasksManager(state, {
        payload: true,
        type: "",
      });
    },
    updateTask: (
      state,
      action: PayloadAction<Partial<RequestTask | UploadTask>>
    ) => {
      state.tasks = state.tasks
        .slice()
        .map((task) =>
          task.id === action.payload.id ? { ...task, ...action.payload } : task
        ) as RequestTask[] | UploadTask[];
    },
    deleteTask: (state, action: PayloadAction<string>) => {
      state.tasks = state.tasks
        .slice()
        .filter((task) => task.id !== action.payload);
    },
  },
});

export const useTaskManager = () => {
  const tasks = useSelector((store: RootState) => store.taskManager.tasks);

  const tasksManager = useSelector(
    (store: RootState) => store.taskManager.tasksManager
  );

  return { tasks, tasksManager };
};

export const { addTask, updateTask, deleteTask, toggleTasksManager } =
  taskManager.actions;

export default taskManager.reducer;

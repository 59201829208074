/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  ScheduleList,
  RequestSchedulesListParams,
  ScheduleCreate,
  ScheduleUpdate,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List the request schedules in a project. The user has to have 'requests.list' permission on either 'deployments.versions' or 'pipelines.versions' to list the request schedules.

### Optional Parameters
- `labels`: Filter on labels of the request schedules. Should be given in the format 'label:label_value'. Separate multiple label-pairs with a comma (,). This parameter should be given as query parameter.

### Response Structure
A list of details of all request schedules in a project
- `name`: Name of the request
- `object_type`: Type of object for which the request is made
- `object_name`: Name of deployment/pipeline for which the request is made
- `schedule`: Schedule in crontab format
- `version`: Name of version for which the request schedule is made
- `request_data`: Input data for the request schedule
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled
- `creation_date`: The date when the request schedule was created
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Response Examples
```
[
  {
    "id": "b4a06aed-f7ab-48b3-b579-b12b62db8058",
    "name": "test-request",
    "object_type": "deployment",
    "object_name": "test-deployment",
    "version": "v1",
    "schedule": "0 * 3 * *",
    "request_data": {
      "input_field_1": 2345,
      "input_field_2": 8765
    },
    "timeout": 200,
    "enabled": true,
    "creation_date": "2020-09-16T08:06:34.457679Z",
    "description": "Daily request schedule",
    "labels": {
      "type": "daily"
    }
  }
]
```

 * @summary List request schedules
 */
export const requestSchedulesList = (
  projectName: string,
  params?: RequestSchedulesListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ScheduleList[]>(
    { url: `/projects/${projectName}/schedules`, method: "get", params },
    options
  );
};

export const getRequestSchedulesListKey = (
  projectName: string,
  params?: RequestSchedulesListParams
) => [`/projects/${projectName}/schedules`, ...(params ? [params] : [])];

export type RequestSchedulesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof requestSchedulesList>>
>;
export type RequestSchedulesListQueryError = ErrorType<unknown>;

export const useRequestSchedulesList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: RequestSchedulesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof requestSchedulesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getRequestSchedulesListKey(projectName, params) : null);
  const swrFn = () => requestSchedulesList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a new request schedule with the provided name

### Required Parameters
- `name`: Name of the request. The name is unique per project. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `object_type`: Type of object for which the request is made. Can be either 'deployment' or 'pipeline'.
- `object_name`: Name of deployment or pipeline for which the request is made
- `schedule`: Schedule in crontab format

### Optional Parameters
- `version`: Name of version for which the request schedule is made. If not provided, default version of the deployment/pipeline will be used.
- `request_data`: Input data for the request schedule. For structured deployments/pipelines, it must be a dictionary.
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled. Default is 'True'.
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Request Examples
```
{
  "name": "test-request",
  "object_type": "deployment",
  "object_name": "test-deployment",
  "version": "v1",
  "schedule": "0 * 3 * *",
  "request_data": {
    "input_field_1": 2345,
    "input_field_2": 8765
  },
  "timeout": 300,
  "enabled": true,
  "description": "Daily request schedule",
  "labels": {
    "type": "daily"
  }
}
```

### Response Structure
Details of the created request schedule
- `name`: Name of the request
- `object_type`: Type of object for which the request is made
- `object_name`: Name of deployment/pipeline for which the request schedule is made
- `schedule`: Schedule in crontab format
- `version`: Name of version for which the request schedule is made
- `request_data`: Input data for the request schedule
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled
- `creation_date`: The date when the request schedule was created
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Response Examples
```
{
  "id": "b4a06aed-f7ab-48b3-b579-b12b62db8058",
  "name": "test-request",
  "object_type": "deployment",
  "object_name": "test-deployment",
  "version": "v1",
  "schedule": "0 * 3 * *",
  "request_data": {
    "input_field_1": 2345,
    "input_field_2": 8765
  },
  "timeout": 300,
  "enabled": true,
  "creation_date": "2020-09-16T08:06:34.457679Z",
  "description": "Daily request schedule",
  "labels": {
    "type": "daily"
  }
}
```

 * @summary Create request schedules
 */
export const requestSchedulesCreate = (
  projectName: string,
  scheduleCreate: ScheduleCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ScheduleList>(
    {
      url: `/projects/${projectName}/schedules`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: scheduleCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a single request schedule

### Response Structure
Details of a request schedule
- `name`: Name of the request
- `object_type`: Type of object for which the request is made
- `object_name`: Name of deployment/pipeline for which the request is made
- `schedule`: Schedule in crontab format
- `version`: Name of version for which the request schedule is made
- `request_data`: Input data for the request schedule
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled
- `creation_date`: The date when the request schedule was created
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Response Examples
```
{
  "id": "b4a06aed-f7ab-48b3-b579-b12b62db8058",
  "name": "test-request",
  "object_type": "deployment",
  "object_name": "test-deployment",
  "version": "v1",
  "schedule": "0 * 3 * *",
  "request_data": {
    "input_field_1": 2345,
    "input_field_2": 8765
  },
  "timeout": 200,
  "enabled": true,
  "creation_date": "2020-09-16T08:06:34.457679Z",
  "description": "Daily request schedule",
  "labels": {
    "type": "daily"
  }
}
```

 * @summary Get details of a request schedule
 */
export const requestSchedulesGet = (
  projectName: string,
  scheduleName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ScheduleList>(
    {
      url: `/projects/${projectName}/schedules/${scheduleName}`,
      method: "get",
    },
    options
  );
};

export const getRequestSchedulesGetKey = (
  projectName: string,
  scheduleName: string
) => [`/projects/${projectName}/schedules/${scheduleName}`];

export type RequestSchedulesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof requestSchedulesGet>>
>;
export type RequestSchedulesGetQueryError = ErrorType<unknown>;

export const useRequestSchedulesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  scheduleName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof requestSchedulesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && scheduleName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getRequestSchedulesGetKey(projectName, scheduleName) : null);
  const swrFn = () =>
    requestSchedulesGet(projectName, scheduleName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a request schedule in a project

### Optional Parameters
- `name`: Name of the request. The name is unique per project. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `schedule`: Schedule in crontab format
- `request_data`: Input data for the request schedule. For structured deployments/pipelines, it must be a dictionary.
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled. Default is 'True'.
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Request Examples
```
{
  "name": "test-request",
  "schedule": "0 * 3 * *",
  "request_data": {
    "input_field_1": 2345,
    "input_field_2": 8765
  },
  "timeout": 360,
  "enabled": false
}
```

### Response Structure
Details of the updated request schedule
- `name`: Name of the request
- `object_type`: Type of object for which the request is made
- `object_name`: Name of deployment/pipeline for which the request is made
- `schedule`: Schedule in crontab format
- `version`: Name of version for which the request schedule is made
- `request_data`: Input data for the request schedule
- `timeout`: Timeout of the request in seconds
- `enabled`: Boolean value indicating whether the request schedule is enabled or disabled
- `creation_date`: The date when the request schedule was created
- `description`: Description of the request schedule
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Response Examples
```
{
  "id": "b4a06aed-f7ab-48b3-b579-b12b62db8058",
  "name": "test-request",
  "object_type": "deployment",
  "object_name": "test-deployment",
  "version": "v1",
  "schedule": "0 * 3 * *",
  "request_data": {
    "input_field_1": 2345,
    "input_field_2": 8765
  },
  "timeout": 360,
  "enabled": true,
  "creation_date": "2020-09-16T08:06:34.457679Z",
  "description": "Daily request schedule",
  "labels": {
    "type": "daily"
  }
}
```

 * @summary Update a request schedule
 */
export const requestSchedulesUpdate = (
  projectName: string,
  scheduleName: string,
  scheduleUpdate: ScheduleUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ScheduleList>(
    {
      url: `/projects/${projectName}/schedules/${scheduleName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: scheduleUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete the request schedule from the project.

If you want to temporarily disable a request schedule, update the request with `enabled` set to False.

 * @summary Delete a request schedule
 */
export const requestSchedulesDelete = (
  projectName: string,
  scheduleName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/schedules/${scheduleName}`,
      method: "delete",
    },
    options
  );
};

import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties } from "react";

export const WarningIcon = ({
  color,
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <div style={style}>
      <svg
        width={style?.width ?? "30"}
        height={style?.height ?? "30"}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.251 17.5H13.751V11.25H16.251M16.251 22.5H13.751V20H16.251M1.25098 26.25H28.751L15.001 2.5L1.25098 26.25Z"
          fill={color ?? theme.palette.warning.main}
        />
      </svg>
    </div>
  );
};

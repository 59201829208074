import { Chip as MaterialChip, Typography } from "@mui/material";

import type { ChipProps as MaterialChipProps, SxProps } from "@mui/material";
import type { CSSProperties } from "react";

export interface ChipProps
  extends Pick<MaterialChipProps, "deleteIcon" | "onDelete" | "size"> {
  label: string;
  style?: CSSProperties;
  variant?: "filled" | "outlined";
  sx?: SxProps;
  color?: "primary" | "secondary";
}

export const Chip = ({ label, size = "small", color, ...props }: ChipProps) => {
  return (
    <MaterialChip
      label={
        <Typography variant="body2" component="span">
          {label}
        </Typography>
      }
      size={size}
      color={color}
      {...props}
    />
  );
};

import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import MenuBookIcon from "@mui/icons-material/MenuBookRounded";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { EmptyDatabase } from "assets/images/EmptyDatabase";
import { SetupDatabase } from "assets/images/SetupDatabase";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { RootUrlContext } from "libs/contexts";
import { explanations } from "libs/utilities/explanations";

import { ExternalLink, PrimaryButton } from "components/atoms";

type NoneRequestRetentionProps = {
  pipelineName?: string;
  deploymentName?: string;
  requestParameters: { type: string };
  versionName: string;
};
export const NoneRequestRetention = ({
  pipelineName,
  deploymentName,
  requestParameters,
  versionName,
}: NoneRequestRetentionProps) => {
  const history = useHistory();
  const rootUrl = useContext(RootUrlContext);

  const updateVersionLink = useMemo(
    () =>
      `${rootUrl}/${requestParameters?.type}s/${
        deploymentName ?? pipelineName
      }/versions/${versionName}/edit`,
    [
      deploymentName,
      pipelineName,
      requestParameters?.type,
      rootUrl,
      versionName,
    ]
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h1">No requests to display!</Typography>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
      {explanations.deployments.versions.retentionModeNone(updateVersionLink)}
      <Grid container item justifyContent="center" alignItems="center">
        <Container>
          <Box component={EmptyDatabase} maxWidth="150px" maxHeight="150px" />
          <Typography style={{ textAlign: "center" }}>
            There are no requests for this version
          </Typography>
        </Container>

        <ArrowForwardIcon color="secondary" fontSize="large" />

        <Container>
          <Box component={SetupDatabase} maxWidth="150px" maxHeight="150px" />
          <Typography style={{ textAlign: "center" }}>
            To see request history please update the request retention
          </Typography>
        </Container>
      </Grid>

      <Grid container alignItems="center" direction="column">
        <PrimaryButton
          startIcon={<EditIcon />}
          onClick={() => history.push(updateVersionLink)}
        >
          Update version
        </PrimaryButton>
        <Box display="flex" alignItems="center" marginTop="10px">
          <Typography variant="h6">
            Read our{" "}
            <ExternalLink
              href={DOC_LINKS.RETENTION_MODE}
              target="_blank"
              variant="h6"
            >
              documentation
            </ExternalLink>{" "}
            to find out more
          </Typography>
          <MenuBookIcon color="secondary" />
        </Box>
      </Grid>
    </Grid>
  );
};

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin: 50px 0;
`;

import DownloadIcon from "@mui/icons-material/GetApp";
import { Box } from "@mui/material";
import { omit } from "lodash";
import { useParams } from "react-router-dom";

import { downloadCSV, downloadFile } from "libs/utilities/download-helper";
import { isArrayHasData } from "libs/utilities/utils";

import {
  Dialog,
  DialogHeader,
  DialogHeaderTitle,
  PrimaryButton,
} from "components/atoms";

import type { RequestRow } from "./types";

interface RequestDownloadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  requests: RequestRow[];
}

export const RequestsDownloadDialog = ({
  isOpen,
  onClose,
  requests,
}: RequestDownloadDialogProps) => {
  const { projectName, versionName } =
    useParams<{
      projectName: string;
      versionName: string;
    }>();

  const handleDownloadRequests = (type: "csv" | "json") => () => {
    if (isArrayHasData(requests)) {
      const filteredData = requests.map((request: RequestRow) =>
        omit(request, "tableData")
      );
      if (type === "csv") {
        downloadCSV({
          data: filteredData,
          fileName: `${versionName ?? projectName}-requests`,
        });
      } else if (type === "json") {
        downloadFile(
          JSON.stringify(filteredData),
          `${versionName ?? projectName}-requests.json`
        );
      }
      onClose();
    }
  };

  return (
    <Dialog
      Actions={
        <>
          <PrimaryButton
            onClick={handleDownloadRequests("csv")}
            startIcon={<DownloadIcon />}
            style={{ marginRight: 14 }}
          >
            CSV
          </PrimaryButton>
          <PrimaryButton
            onClick={handleDownloadRequests("json")}
            startIcon={<DownloadIcon />}
          >
            JSON
          </PrimaryButton>
        </>
      }
      open={isOpen}
      Header={
        <DialogHeader
          header={
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DownloadIcon style={{ marginRight: 10 }} />
              <DialogHeaderTitle>Download Requests</DialogHeaderTitle>
            </Box>
          }
        />
      }
      onClose={onClose}
    >
      Download your requests as a CSV or JSON file.
    </Dialog>
  );
};

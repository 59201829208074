import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import Plus from "@mui/icons-material/AddBoxRounded";
import UserIcon from "@mui/icons-material/People";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import { Box, Divider, Typography, TextField } from "@mui/material";
import { toLower } from "lodash";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import {
  deploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  pipelineVersionsList,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";
import { useProjectUsersList } from "libs/data/endpoints/projects/projects";

import {
  Dialog,
  Icon,
  InfoAlert,
  Loader,
  PrimaryButton,
} from "components/atoms";

import type { AutocompleteRenderInputParams } from "@mui/material";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

export type MonitoringObject = {
  id: string;
  type: string;
  label: string;
  userId?: string;
};
type AddObjectDialogProps = {
  open: boolean;
  onClose: () => void;
  onSelect: ({ id, type, label }: MonitoringObject) => void;
};

const objectTypes = [
  {
    label: "Deployments",
    value: "deployment",
    icon: DeploymentIcon,
  },
  {
    label: "Pipelines",
    value: "pipeline",
    icon: PipelineIcon,
  },
  {
    label: "Users & Service users",
    value: "user",
    icon: UserIcon,
  },
];

const defaultSelectedObject = {
  object: null,
  type: "",
  version: null,
};

export const AddObjectDialog = ({
  open,
  onClose,
  onSelect,
}: AddObjectDialogProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] =
    useState<AutocompleteSelectOption | null>(null);
  const [versionList, setVersionList] = useState<AutocompleteSelectOption[]>(
    []
  );
  const [selectedObject, setSelectedObject] = useState<{
    object: AutocompleteSelectOption | null;
    type: string;
    version: AutocompleteSelectOption | null;
    user?: AutocompleteSelectOption;
  }>(defaultSelectedObject);

  const { data: pipelines } = usePipelinesList(projectName);
  const { data: deployments } = useDeploymentsList(projectName);
  const { data: users } = useProjectUsersList(projectName);

  const objectList = useMemo(() => {
    if (selectedType?.value === "deployment")
      return (
        deployments?.map((dep) => ({ label: dep.name, value: dep.name })) ?? []
      );
    else if (selectedType?.value === "pipeline")
      return (
        pipelines?.map((pipe) => ({ label: pipe.name, value: pipe.name })) ?? []
      );
    else if (selectedType?.value === "user")
      return (
        users?.map((user) => ({
          label: user.email.includes("@serviceuser")
            ? (user.name as string)
            : user.email,
          value: user.id,
        })) ?? []
      );
    else return [];
  }, [deployments, pipelines, selectedType?.value, users]);

  const deploymentPipelineList = useMemo(() => {
    return (deployments || [])
      .map((dep) => ({
        label: dep.name,
        value: dep.name,
        group: "Deployments",
      }))
      .concat(
        (pipelines || []).map((pipe) => ({
          label: pipe.name,
          value: pipe.name,
          group: "Pipelines",
        }))
      );
  }, [deployments, pipelines]);

  const handleObjectSelect = (newValue: AutocompleteSelectOption | null) => {
    if (!newValue) return;
    if (selectedType?.value === "user") {
      setSelectedObject({
        ...selectedObject,
        user: newValue,
      });
    } else {
      setSelectedObject({
        ...selectedObject,
        type: selectedType?.value as string,
        object: newValue,
      });
      getVersions(
        selectedType?.value as "deployment" | "pipeline",
        newValue?.value as string
      );
    }
  };

  const getVersions = async (type: "deployment" | "pipeline", name: string) => {
    setIsLoading(true);
    const getFn =
      type === "deployment" ? deploymentVersionsList : pipelineVersionsList;
    const versions = await getFn(projectName, name);
    setVersionList(
      versions.map((version) => ({ label: version.version, value: version.id }))
    );
    setIsLoading(false);
  };

  const onObjectSelect = () => {
    const monitoringObject = {
      label: selectedObject?.user
        ? `${selectedObject?.user.label} - ${selectedObject?.object?.label} - ${selectedObject?.version?.label}`
        : `${selectedObject?.object?.label} - ${selectedObject?.version?.label}`,
      type: selectedObject?.type,
      userId: selectedObject?.user?.value as string | undefined,
      id: selectedObject?.version?.value as string,
    };
    selectedObject && onSelect(monitoringObject);
    setSelectedObject(defaultSelectedObject);
    setSelectedType(null);
  };

  return (
    <Dialog
      title="Add data of an object"
      open={open}
      onClose={() => {
        setSelectedObject(defaultSelectedObject);
        setSelectedType(null);
        onClose();
      }}
      Actions={
        <PrimaryButton
          startIcon={<Plus />}
          disabled={!selectedObject.version}
          onClick={onObjectSelect}
        >
          Add object
        </PrimaryButton>
      }
    >
      <Box display="flex" flexDirection="column" gap={3} position="relative">
        <Typography variant="body2" textAlign="center">
          Select an object below to add the data to your dashboard.
        </Typography>
        <Divider />
        {isLoading && (
          <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
            <Loader />
          </Box>
        )}
        <AutoCompleteSelect
          options={objectTypes}
          renderOption={(props: any, option: AutocompleteSelectOption) => {
            return (
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  gridColumnGap: spacing[8],
                  paddingLeft: spacing[16],
                }}
                {...props}
              >
                <Icon
                  component={option.icon}
                  style={{ height: "20px", width: "20px" }}
                />
                <Typography variant="body2">{option.label} </Typography>
              </Box>
            );
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              label="Object type"
              variant="outlined"
              value={selectedType?.label}
              placeholder="Select..."
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Icon
                    component={selectedType?.icon || null}
                    style={{ height: "20px", width: "20px" }}
                  />
                ),
              }}
            >
              {selectedType?.label || ""}
            </TextField>
          )}
          onChange={(value) => {
            value && setSelectedType(value);
            setSelectedObject(defaultSelectedObject);
          }}
          styles={{ maxWidth: "60%" }}
        />

        {selectedType?.label && (
          <>
            <AutoCompleteSelect
              label={selectedType?.label.substring(
                0,
                selectedType?.label?.length - 1
              )}
              options={objectList}
              value={
                selectedType?.value === "user"
                  ? selectedObject?.user
                  : selectedObject?.object
              }
              onChange={handleObjectSelect}
              styles={{ maxWidth: "60%" }}
              placeholder="Select..."
            />
            {selectedType?.value === "user" ? (
              <>
                <InfoAlert style={{ margin: 0 }}>
                  User related data is collected per object. For which object do
                  you want to see the data of this user?
                </InfoAlert>
                <Box display="flex" gap={2}>
                  <AutoCompleteSelect
                    label="Object selection"
                    options={deploymentPipelineList}
                    onChange={(value) => {
                      if (!value) return;
                      const type = toLower(value?.group).substring(
                        0,
                        (value?.group?.length || 0) - 1
                      ) as "deployment" | "pipeline";
                      setSelectedObject({
                        ...selectedObject,
                        object: value,
                        type: type as string,
                      });
                      getVersions(type, value?.value as string);
                    }}
                    groupBy={(option) => option.group || ""}
                  />
                  <AutoCompleteSelect
                    label="Version"
                    options={versionList}
                    onChange={(value) =>
                      value &&
                      setSelectedObject({
                        ...selectedObject,
                        version: value,
                      })
                    }
                    placeholder="Select..."
                  />
                </Box>
              </>
            ) : (
              <AutoCompleteSelect
                label={"Version"}
                placeholder="Select..."
                options={versionList}
                onChange={(value) =>
                  value &&
                  setSelectedObject({
                    ...selectedObject,
                    version: value,
                  })
                }
                styles={{ maxWidth: "60%" }}
              />
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};

import type { FileDetail } from "libs/data/models";

export type File = {
  file: string;
  size?: number | null;
  time_created?: string;
  isFolder?: boolean;
};

export const mapFileDetailToFileItems = (data: FileDetail): File[] => {
  const directories: File[] =
    data.prefixes.map((x: string) => ({
      file: x.charAt(x.length - 1) === "/" ? x.slice(0, -1) : x,
      size: 0,
      time_created: "",
      isFolder: true,
    })) ?? [];

  const files: File[] =
    data.files.map(({ file, size, time_created }) => ({
      file,
      size,
      time_created,
      isFolder: false,
    })) ?? [];

  return directories.concat(files);
};

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { EnvironmentDuplicate } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/EnvironmentDuplicate";
import { routes } from "routes";

import { Route } from "components/utilities";

import { EnvironmentDetails } from "./EnvironmentDetails";
import { EnvironmentDetailsGeneral } from "./EnvironmentDetailsGeneral";
import { EnvironmentEdit } from "./EnvironmentEdit";
import { EnvironmentRevisions } from "./EnvironmentRevisions";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .environments.custom[":environmentName"](":environmentName");

export const EnvironmentRoutesContainer = () => {
  const match = useRouteMatch();

  const { organizationName, projectName, environmentName } =
    useParams<{
      projectName: string;
      environmentName: string;
      organizationName: string;
    }>();
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .environments.custom[":environmentName"](environmentName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{environmentName}</BreadcrumbsItem>
      <Switch>
        <Route exact path={basePath.edit.index()} component={EnvironmentEdit} />
        <Route
          exact
          path={basePath.duplicate.index()}
          component={EnvironmentDuplicate}
        />
        <Route path={basePath.details.index()}>
          <EnvironmentDetails>
            <Switch>
              <Route
                exact
                path={basePath.details.general.index()}
                component={EnvironmentDetailsGeneral}
              />
              <Route
                exact
                path={basePath.details.revisions.index()}
                component={EnvironmentRevisions}
              />
              <Redirect to={baseUrl.details.general.index()} />
            </Switch>
          </EnvironmentDetails>
        </Route>
        <Redirect to={baseUrl.details.general.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

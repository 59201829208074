import { Link, useTheme } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink, useParams } from "react-router-dom";

import { routes } from "routes";

export const FilesOverviewBreadcrumbs = ({
  onNavigate,
  pathname,
}: {
  onNavigate: () => void;
  pathname: string;
}) => {
  const { bucketName, organizationName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
      bucketName: string;
    }>();

  const theme = useTheme();
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .storage[":bucketName"](bucketName)
    .general.files.index();

  const pathArray = pathname
    .slice(baseUrl.length + 1)
    .split("/")
    .filter((x) => !!x);

  return (
    <Breadcrumbs separator="›">
      <Link
        key={bucketName}
        component={NavLink}
        to={baseUrl}
        onClick={onNavigate}
        sx={{ color: theme.palette.text.primary }}
      >
        {bucketName}
      </Link>
      {pathArray.map((folder, index) => (
        <Link
          key={folder}
          component={NavLink}
          to={`${baseUrl}/${pathArray.slice(0, index + 1).join("/")}`}
          onClick={onNavigate}
          sx={{ color: theme.palette.text.primary }}
        >
          {folder}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { stringifyUrl } from "query-string";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { borders, spacing } from "assets/styles/theme";
import {
  DEPLOYMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useLogsUrl } from "libs/hooks";
import { getFormattedDate } from "libs/utilities/date-util";
import { useGetPermissions } from "store/features/permissions";

import { NavLink, OverflowTooltip } from "components/atoms";
import { shouldFetchMetadataOnly } from "components/organisms";

import { TRAINING_DEPLOYMENT } from "../constants";
import { RunResultsDialog } from "../experiments/:experimentName/RunResultsDialog";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  DeploymentRequestDetail,
  DeploymentRequestSingleDetail,
} from "libs/data/models";

// CodeSandbox example this table is from: https://codesandbox.io/s/youthful-tdd-u4bqj?file=/src/App.js
const StickyTableCell = styled(TableCell)<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  position: sticky;
  left: 0;
  padding: 0;
  border-bottom: none;
  border-right: ${borders.secondary};
  z-index: 2;
`;

const StyledTableCell = styled(TableCell)<{ color: string }>`
  border-bottom: none;
  font-size: 14px;
  color: ${(props) => props.color};
  max-width: ${spacing[220]};
  width: ${spacing[220]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: ${borders.tertiary};
`;

const StyledTableRowWithHover = styled(StyledTableRow)`
  :hover {
    filter: brightness(95%);
  }
`;

const StyledHeaderCell = styled(StyledTableCell)<{ color: string }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color};
`;

const ActionsContainer = styled(Box)<{ backgroundColor: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  padding: 16px;
  height: 50px;
  position: sticky;
  right: 0;
  top: 50%;
  background-color: ${(props) => props.backgroundColor};
`;

type ComparisonTableProps = {
  columns: string[];
  data?: DeploymentRequestDetail[];
};

type Order = "asc" | "desc";

const descendingComparator = (
  a: { [key: string]: string | number },
  b: { [key: string]: string | number },
  orderBy: string
) => {
  if (b?.[orderBy] < a?.[orderBy] || b?.[orderBy] === undefined) {
    return -1;
  }
  if (b?.[orderBy] > a?.[orderBy] || a?.[orderBy] === undefined) {
    return 1;
  }

  return 0;
};

const getComparator =
  (order: Order, orderBy: string) =>
  (a: DeploymentRequestDetail, b: DeploymentRequestDetail) => {
    const metricsA: { [key: string]: string | number } = a?.result?.metrics;
    const metricsB: { [key: string]: string | number } = b?.result?.metrics;

    return order === "desc"
      ? descendingComparator(metricsA, metricsB, orderBy)
      : -descendingComparator(metricsA, metricsB, orderBy);
  };

export const ComparisonTable = ({ columns, data }: ComparisonTableProps) => {
  const [selectedRunResults, setSelectedRunResults] =
    useState<DeploymentRequestDetail | undefined>(undefined);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const theme = useTheme() as AppThemeProps;
  const { organizationName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
    }>();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    TRAINING_DEPLOYMENT,
    "deployment"
  );

  const [projectPermissions] = useGetPermissions();

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_name: TRAINING_DEPLOYMENT,
    },
  });
  const RenderLogsIcon = useCallback(
    ({ rowData }: any) => {
      const toDate = moment(rowData.time_completed).add(1, "minutes");
      const fromDate = moment(rowData.time_created);

      const requestUrl = stringifyUrl({
        url: logsUrl,
        query: {
          deployment_request_id: rowData.id,
          deployment_version: rowData.version,
          from_date: getFormattedDate(fromDate),
          to_date: getFormattedDate(toDate),
        },
      });

      return (
        <Tooltip title={"View logs"}>
          <span>
            <IconButton
              component={NavLink}
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              to={requestUrl}
              color="secondary"
            >
              <LogsIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    [logsUrl, projectPermissions]
  );

  const visibleRows = useMemo(
    () => (data ? data.sort(getComparator(order, orderBy)) : []),
    [data, order, orderBy]
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Example for table sort: https://codesandbox.io/s/7dp035?file=/demo.tsx
  return (
    <>
      <TableContainer style={{ maxHeight: "760px" }}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StickyTableCell
                backgroundColor={theme.palette.table.comparison.fixed}
              >
                <StyledHeaderCell
                  color={theme.palette.text.primary}
                  style={{ minWidth: spacing[220] }}
                >
                  Name
                </StyledHeaderCell>
                <StyledHeaderCell
                  color={theme.palette.text.primary}
                  style={{ minWidth: spacing[220] }}
                >
                  Experiment
                </StyledHeaderCell>
              </StickyTableCell>
              {columns.map((column) => (
                <StyledHeaderCell
                  color={theme.palette.text.primary}
                  sortDirection={orderBy === column ? order : false}
                  key={column}
                >
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : "asc"}
                    onClick={() => handleRequestSort(column)}
                  >
                    {column}
                  </TableSortLabel>
                </StyledHeaderCell>
              ))}
              <ActionsContainer
                backgroundColor={theme.palette.background.paper}
              />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((entry) => {
              return (
                <StyledTableRowWithHover key={entry?.id}>
                  <StickyTableCell
                    style={{ width: spacing[440] }}
                    backgroundColor={theme.palette.table.comparison.fixed}
                  >
                    <StyledTableCell
                      color={theme.palette.neutrals[500]}
                      style={{
                        fontWeight: "bold",
                        width: spacing[220],
                        maxWidth: spacing[220],
                      }}
                    >
                      <OverflowTooltip>
                        {entry?.request_data?.name ?? "Name not available"}
                      </OverflowTooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      color={theme.palette.neutrals[500]}
                      style={{ width: spacing[220], maxWidth: spacing[220] }}
                    >
                      <OverflowTooltip>{entry.version}</OverflowTooltip>
                    </StyledTableCell>
                  </StickyTableCell>
                  {columns.map((column) => (
                    <StyledTableCell
                      color={theme.palette.neutrals[500]}
                      key={column}
                    >
                      {entry?.result?.metrics?.[column]
                        ? JSON.stringify(entry?.result?.metrics?.[column])
                        : "Null"}
                    </StyledTableCell>
                  ))}
                  <ActionsContainer
                    backgroundColor={theme.palette.background.paper}
                  >
                    <Tooltip title="View results">
                      <span>
                        <IconButton
                          disabled={
                            !currentPermissions[
                              DEPLOYMENT_PERMISSIONS["version_request_get"]
                            ]
                          }
                          color="secondary"
                          onClick={() => setSelectedRunResults(entry)}
                        >
                          <GetResultsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <RenderLogsIcon rowData={entry} />
                  </ActionsContainer>
                </StyledTableRowWithHover>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <RunResultsDialog
        onClose={() => setSelectedRunResults(undefined)}
        isOpen={!!selectedRunResults}
        organizationName={organizationName}
        projectName={projectName}
        experimentName={selectedRunResults?.version as string}
        id={selectedRunResults?.id as string}
        shouldFetchMetadaDataOnly={shouldFetchMetadataOnly(
          selectedRunResults as DeploymentRequestSingleDetail
        )}
      />
    </>
  );
};

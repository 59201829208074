import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { RootUrlContext } from "libs/contexts";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";

import { Loader } from "components/atoms";

import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

export const DeploymentVersionDefault = () => {
  const history = useHistory();
  const rootUrl = useContext(RootUrlContext);

  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const { data: deployment, error } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  useEffect(() => {
    if (deployment || error) {
      const deploymentRoute = `${rootUrl}/deployments/${deploymentName}`;
      if (deployment?.default_version) {
        history.replace(
          `${deploymentRoute}/versions/${deployment.default_version}`
        );
      } else {
        history.replace(deploymentRoute);
      }
    }
  }, [deployment, deploymentName, error, history, rootUrl]);

  return <Loader />;
};

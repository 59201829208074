import { FormControlLabel, FormHelperText, Switch } from "@mui/material";

import type { SwitchProps } from "@mui/material";

export type ControlledSwitchProps = Omit<SwitchProps, "error" | "onChange"> & {
  error?: string | false;
  label?: string;
  onChange?: (value: boolean) => void;
  showErrors?: boolean;
  value?: boolean;
};

export const ControlledSwitch = ({
  error,
  label,
  onChange,
  showErrors,
  value,
  ...props
}: ControlledSwitchProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            onChange={(e) => onChange && onChange(e.target.checked)}
            checked={value}
            {...props}
          />
        }
        label={label}
      />
      {showErrors && error && (
        <FormHelperText error={!!error} hidden={!error}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

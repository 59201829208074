import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useBucketsGet } from "libs/data/endpoints/files/files";
import { createErrorNotification } from "libs/utilities/notifications";
import { routes } from "routes";

import { Route } from "components/utilities";

import { BucketDetails } from "./BucketDetails";
import { BucketEdit } from "./BucketEdit";
import { Configuration } from "./Configuration";
import { FilesOverview } from "./FilesOverview";
import { Permissions } from "./Permissions";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .storage[":bucketName"](":bucketName");

export const BucketDetailsRoutesContainer = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationName, projectName, bucketName } =
    useParams<{
      projectName: string;
      bucketName: string;
      organizationName: string;
    }>();

  const { data: bucketDetails, error } = useBucketsGet(projectName, bucketName);

  const bucketsOverviewUrl = routes.organizations[":organizationName"](
    organizationName
  )
    .projects[":projectName"](projectName)
    .storage.index();
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .storage[":bucketName"](bucketName);

  useEffect(() => {
    if (error) {
      if (error.status === 404) history.push(bucketsOverviewUrl);
      dispatch(createErrorNotification(error.message));
    }
  }, [error, bucketsOverviewUrl, dispatch, history]);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{bucketName}</BreadcrumbsItem>
      <Switch>
        <Route exact path={basePath.edit.index()} component={BucketEdit} />
        <Route path={basePath.index()}>
          <BucketDetails bucketDetails={bucketDetails}>
            <Switch>
              <Route
                path={basePath.general.files.index()}
                component={FilesOverview}
              />
              <Route
                exact
                path={basePath.general.configuration.index()}
                component={() => <Configuration bucket={bucketDetails} />}
              />
              <Route
                exact
                path={basePath.general.permissions.index()}
                component={Permissions}
              />
              <Redirect to={baseUrl.general.files.index()} />
            </Switch>
          </BucketDetails>
        </Route>
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { Box } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { deploymentVersionRequestsBatchCancel } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { pipelineVersionRequestsBatchCancel } from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import { ActionDialog } from "components/atoms";

import type { AxiosError } from "axios";

interface RequestsBulkCancelDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  requestIds: string[] | undefined;
  status: string;
}

export const RequestsBulkCancelDialog = ({
  isOpen,
  onClose,
  onSuccess,
  requestIds,
  status,
}: RequestsBulkCancelDialogProps) => {
  const dispatch = useDispatch();
  const { deploymentName, pipelineName, projectName, versionName } =
    useParams<{
      deploymentName?: string;
      pipelineName?: string;
      projectName: string;
      versionName: string;
    }>();

  const cancelRequest = useCallback(async () => {
    const cancelFunction = deploymentName
      ? deploymentVersionRequestsBatchCancel
      : pipelineVersionRequestsBatchCancel;

    if (requestIds?.length) {
      try {
        await cancelFunction(
          projectName,
          (deploymentName || pipelineName) as string,
          versionName,
          [],
          {
            status,
          }
        );
        dispatch(createSuccessNotification(`Requests are being cancelled`));
        onSuccess();
      } catch (error) {
        dispatch(createErrorNotification((error as AxiosError).message));
      } finally {
        onClose();
      }
    }
  }, [
    deploymentName,
    dispatch,
    onClose,
    onSuccess,
    pipelineName,
    projectName,
    requestIds,
    status,
    versionName,
  ]);

  return (
    <ActionDialog
      open={isOpen}
      dialogTitle="Cancelling Requests"
      onClose={onClose}
      onAction={() => cancelRequest()}
      actionButtonText="Cancel requests"
    >
      <Box textAlign="center">
        We are currently cancelling your requests, please be aware that this
        might take some time to update depending on the amount of requests being
        canceled.
      </Box>
    </ActionDialog>
  );
};

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  instanceTypeGroupsUpdate,
  useInstanceTypeGroupsGet,
  useInstanceTypeGroupsList,
} from "libs/data/endpoints/instances/instances";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import type { AxiosError } from "axios";
import type { FormikHelpers } from "formik";
import type { InstanceTypeGroupCreateBody } from "libs/data/models";

export const useEditInstanceTypeGroup = (
  organizationName: string,
  projectName: string,
  groupId: string
) => {
  const { mutate } = useInstanceTypeGroupsList(projectName);
  const { mutate: mutateDetails } = useInstanceTypeGroupsGet(
    projectName,
    groupId
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSubmit = async (
    data: InstanceTypeGroupCreateBody,
    actions: FormikHelpers<InstanceTypeGroupCreateBody>
  ) => {
    try {
      const response = await instanceTypeGroupsUpdate(
        projectName,
        groupId,
        data
      );
      await mutate();

      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .projectSettings.instanceTypeGroups.index()
      );

      await mutateDetails(response);

      dispatch(createSuccessNotification("Instance type group was updated"));
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError).message));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return handleOnSubmit;
};

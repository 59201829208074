import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { FIELD_TIMEOUT } from "libs/constants/fields";
import {
  batchDeploymentVersionRequestsCreate,
  useDeploymentVersionRequestsList,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import type { BatchDeploymentRequestsCreateDataBody } from "libs/data/models";

export const useRunCreate = (projectName: string, experimentName: string) => {
  const dispatch = useDispatch();

  const { mutate } = useDeploymentVersionRequestsList(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );

  return useCallback(
    async (runData: any, timeout: any) => {
      try {
        const response = await batchDeploymentVersionRequestsCreate(
          projectName,
          TRAINING_DEPLOYMENT,
          experimentName,
          runData as unknown as BatchDeploymentRequestsCreateDataBody,
          { [FIELD_TIMEOUT]: timeout }
        );
        mutate();
        dispatch(createSuccessNotification("Run was created"));

        return response;
      } catch (err: any) {
        dispatch(createErrorNotification(err.message));

        return;
      }
    },
    [dispatch, experimentName, mutate, projectName]
  );
};

import { Box } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useReactFlow, addEdge } from "reactflow";

import { spacing } from "assets/styles/theme";
import { useDeleteNode } from "components/templates/Diagram/useDeleteNode";
import { operatorIsInvalid } from "components/templates/Diagram/utils";
import { FIELD_INPUT_FIELDS, FIELD_OUTPUT_FIELDS } from "libs/constants/fields";
import { formatInputOutputFields } from "libs/utilities/input-parser";

import { Divider, PrimaryButton } from "components/atoms";

import { SidebarRight } from "../SidebarRight";
import { OPERATOR_MANY_TO_ONE, OPERATOR_ONE_TO_MANY } from "../constants";
import { getId } from "../getId";

import type { OperatorType } from "../constants";
import type { OperatorFormValues } from "components/templates/Diagram/OperatorsForms/types";
import type {
  EdgeDataType,
  NodeDataType,
} from "components/templates/Diagram/types";
import type {
  PipelineVersionObjectList,
  DeploymentInputFieldCreate,
  DeploymentOutputFieldCreate,
  PipelineVersionObjectConfigurationList,
  InputOutputFieldList,
} from "libs/data/models";
import type { Edge, Node } from "reactflow";

type OperatorEditSidebarProps = {
  id: string;
  isOpen: boolean;
  operator: OperatorType;
  onClose: () => void;
};

interface OnSubmitProps {
  operatorForm: PipelineVersionObjectConfigurationList;
}

export const OperatorEditSidebar = ({
  id,
  isOpen,
  onClose,
  operator,
}: OperatorEditSidebarProps) => {
  const { getEdges, getNode, getNodes, setNodes, setEdges } =
    useReactFlow<NodeDataType, EdgeDataType>();
  const node = getNode(id) as Node<NodeDataType>;
  const deleteNode = useDeleteNode();
  const formMethods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { control, handleSubmit } = formMethods;
  const [operatorFormData, setOperatorFormData] =
    useState<PipelineVersionObjectConfigurationList>(
      (node?.data?.pipelineObject
        ?.configuration as PipelineVersionObjectConfigurationList) ?? {}
    );
  const addManyToOneEdge = (destinationObject: PipelineVersionObjectList) => {
    const nodes = getNodes();

    const destinationNode = nodes.find(
      (n) => n.data.pipelineObject.name == destinationObject.name
    ) as Node<NodeDataType>;

    const mappingObjects = destinationObject.input_fields?.map(
      (field: { name: string }) => {
        return {
          source_field_name: field.name,
          destination_field_name: field.name,
        };
      }
    );

    createUniqueEdge({
      source: node.id,
      target: destinationNode?.id,
      data: {
        sources: [
          {
            source_name: node?.data?.pipelineObject?.name,
            mapping: mappingObjects,
          },
        ],
      },
    });
  };

  const addOneToManyEdge = (sourceObject: PipelineVersionObjectList) => {
    const nodes = getNodes();

    const sourceNode = nodes.find(
      (n) => n.data.pipelineObject.name == sourceObject.name
    ) as Node<NodeDataType>;

    const mappingObjects = sourceObject.output_fields?.map(
      (field: { name: string }) => {
        return {
          source_field_name: field.name,
          destination_field_name: field.name,
        };
      }
    );

    createUniqueEdge({
      source: sourceNode.id || "",
      target: node.id,
      data: {
        sources: [
          {
            source_name: sourceObject.name,
            mapping: mappingObjects,
          },
        ],
      },
    });
  };

  const createUniqueEdge = (edge: Partial<Edge<Partial<EdgeDataType>>>) => {
    const { organizationName, projectName, pipelineName, versionName } =
      node.data;
    const { sources } = edge.data as EdgeDataType;

    const newEdge = {
      ...edge,
      id: getId(),
      type: "custom",
      data: {
        sources,
        organizationName,
        pipelineName,
        projectName,
        versionName,
      },
    } as Edge<EdgeDataType>;

    const nodeIsDestination = edge.target === id;

    setEdges((edges) => {
      // As this function is only used to create unique edges, we remove old edges automatically
      const filteredEdges = edges.filter((edge) =>
        nodeIsDestination ? edge.target !== id : edge.source !== id
      );

      return addEdge(newEdge, filteredEdges);
    });
  };

  const onSubmit = ({
    destinationObject,
    operatorForm: _operatorForm,
    sourceObject,
    ...data
  }: OnSubmitProps &
    PipelineVersionObjectConfigurationList &
    OperatorFormValues) => {
    const nodes = getNodes();
    // Ensure that we do not actually mutate the data itself as this may cause unintended side effects
    const formattedData = { ...data };

    // The one-to-many and many-to-one operator maps the source/destination input's output_fields as input and output
    if (sourceObject && sourceObject.output_fields) {
      formattedData[FIELD_INPUT_FIELDS] = sourceObject.output_fields;
      formattedData[FIELD_OUTPUT_FIELDS] = sourceObject.output_fields;
    }
    if (destinationObject && destinationObject.input_fields) {
      formattedData[FIELD_INPUT_FIELDS] = destinationObject.input_fields;
      formattedData[FIELD_OUTPUT_FIELDS] = destinationObject.input_fields;
    }
    // The function operator contains one output field that requires us to manually set a name
    if (formattedData.output_field) {
      formattedData[FIELD_OUTPUT_FIELDS] = Object.values(
        formattedData.output_field
      ).map((field) => ({
        ...field,
        name: "output",
      }));
      delete formattedData.output_field;
    }

    // Format any input fields
    if (formattedData[FIELD_INPUT_FIELDS]) {
      formattedData[FIELD_INPUT_FIELDS] = formatInputOutputFields(
        formattedData[FIELD_INPUT_FIELDS] as InputOutputFieldList[]
      );

      if (node?.data?.pipelineObject)
        node.data.pipelineObject.input_fields = formattedData[
          FIELD_INPUT_FIELDS
        ] as DeploymentInputFieldCreate[];
    }

    // Format any output fields
    if (formattedData[FIELD_OUTPUT_FIELDS]) {
      formattedData[FIELD_OUTPUT_FIELDS] = formatInputOutputFields(
        formattedData[FIELD_OUTPUT_FIELDS] as InputOutputFieldList[]
      );
      if (node?.data?.pipelineObject)
        node.data.pipelineObject.output_fields = formattedData[
          FIELD_OUTPUT_FIELDS
        ] as DeploymentOutputFieldCreate[];
    }

    // Cast batch size as an integer
    if (formattedData.batch_size) {
      formattedData.batch_size = parseInt(
        formattedData.batch_size as unknown as string
      );
    }

    // Update the node's configuration
    if (node?.data?.pipelineObject?.configuration) {
      node.data.pipelineObject.configuration = {
        ...node.data.pipelineObject.configuration,
        ...formattedData,
      };

      setNodes(nodes.map((n) => (n.id === node.id ? node : n)));
      setOperatorFormData(
        formattedData as PipelineVersionObjectConfigurationList
      );

      // If it's a one-to-many we also automatically have to create an edge for it
      if (operator.id === OPERATOR_ONE_TO_MANY && sourceObject) {
        addOneToManyEdge(sourceObject);
      }

      // If it's a one-to-many we also automatically have to create an edge for it
      if (operator.id === OPERATOR_MANY_TO_ONE && destinationObject) {
        addManyToOneEdge(destinationObject);
      }

      onClose();
    } else {
      // todo: throw a silent error
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    if (operatorIsInvalid(node, getEdges())) {
      deleteNode(id);
    }

    onClose();
  };

  const Form = operator.form;

  return (
    <SidebarRight
      title={`${operator.title} Operator`}
      description={operator.description}
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {Form && (
                <>
                  <Divider marginY={1} />

                  <Controller
                    defaultValue={operatorFormData}
                    name="operatorForm"
                    control={control}
                    render={({ value }) => <Form id={id} value={value} />}
                  />
                </>
              )}
            </Box>
            <Box marginTop={spacing[12]}>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </SidebarRight>
  );
};

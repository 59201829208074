import { createErrorNotification } from "libs/utilities/notifications";

import { TERMS_CONDITIONS_ERROR, TWO_FACTOR_AUTH_ERROR } from "./constants";
import { signOut } from "./utilities";

import type { AxiosError } from "axios";
import type { AppStore } from "store/store";

export const errorHandler =
  (store: AppStore) => (error: AxiosError<any, any>) => {
    const unauthorized =
      error?.response?.status === 401 &&
      error?.response?.data?.error_type !== TWO_FACTOR_AUTH_ERROR;
    const isTermsError =
      error?.response?.data?.error_type === TERMS_CONDITIONS_ERROR;
    if (unauthorized && !isTermsError) {
      signOut(store);
      store.dispatch(
        createErrorNotification(
          "Authentication failed. Your login session expired",
          {
            key: "login-session",
          }
        )
      );
    } else if (
      error.response?.status === 403 &&
      error.config?.method === "get"
    ) {
      store.dispatch(
        createErrorNotification(
          "Authorization failed. You don't have permission to view this resource",
          {
            key: "login-session",
          }
        )
      );
    } else if (
      error.response?.status === 429 &&
      error.config?.method === "get"
    ) {
      store.dispatch(
        createErrorNotification("You have reached your rate limit", {
          key: "rate-limited",
        })
      );
    }
  };

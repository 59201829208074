import styled from "@emotion/styled";
import { Box, Typography, useTheme } from "@mui/material";
import { LogIn } from "react-feather";
import { Handle, Position } from "reactflow";

import { spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { PipelineVersionObjectList } from "libs/data/models";
import type { NodeProps } from "reactflow";

const S_Handle = styled(Handle)`
  &&& {
    background: transparent;
    z-index: 999;
    border: none;
    bottom: 0;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export type NodePipelineEndProps = NodeProps & {
  data: {
    pipelineObject: PipelineVersionObjectList;
  };
};

export const NodePipelineEnd = ({
  //   data: { pipelineObject, projectName },
  isConnectable,
}: NodePipelineEndProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      height="50px"
      width="85px"
      display="flex"
      borderRadius="999px"
      color={theme.palette.pipelineDiagram.startEndNodesText}
      bgcolor={theme.palette.pipelineDiagram.startEndNodesBackground}
      border={`2px solid ${theme.palette.error.main}`}
    >
      <Box
        display="flex"
        position="relative"
        alignItems="center"
        px={spacing[4]}
      >
        <S_Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <LogIn size={18} />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        height="100%"
        px={spacing[12]}
        style={{
          borderLeft: `2px solid ${theme.palette.error.main}`,
        }}
      >
        <Typography variant="h5">End</Typography>
      </Box>
    </Box>
  );
};

import { Box, useTheme } from "@mui/material";

export const JsonPreview = ({ value }: { value: string }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        margin: "8px 0",
        borderRadius: 8,
        fontFamily: "Courier",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        overflow: "hidden",
        padding: "8px 16px",
        whiteSpace: "pre-wrap",
      }}
    >
      {value}
    </Box>
  );
};

import { Grid, Typography } from "@mui/material";
import { get, isEmpty } from "lodash";

import { ControlledRequestCreate } from "components/organisms/RequestCreate/ControlledRequestCreate";
import { FIELD_REQUEST_DATA } from "libs/constants/fields";

import { Loader } from "components/atoms";

import type { FormikProps, FormikValues } from "formik";
import type {
  DeploymentList,
  PipelineList,
  ScheduleCreateRequestData,
} from "libs/data/models";
import type { fieldTypesLabels } from "libs/utilities/labels-mapping";

type RequestScheduleInputDataProps = {
  object: DeploymentList | PipelineList | undefined;
  type: "deployment" | "pipeline";
  defaultRequestData?: ScheduleCreateRequestData;
  control: FormikProps<FormikValues>;
};

export const RequestScheduleInputData = ({
  object,
  defaultRequestData,
  type,
  control,
}: RequestScheduleInputDataProps) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <Typography variant="h5">Input data</Typography>
    </Grid>
    <Grid item>
      {!isEmpty(object) ? (
        <>
          <ControlledRequestCreate
            control={control}
            objectName={object?.name}
            objectType={type}
            fieldName={FIELD_REQUEST_DATA}
            inputType={object?.input_type}
            fields={
              object?.input_fields as {
                name: string;
                data_type: keyof typeof fieldTypesLabels;
              }[]
            }
            defaultFormFields={defaultRequestData}
          />
          <Typography variant="caption" color="error">
            {control.touched[FIELD_REQUEST_DATA] &&
              (get(control.errors, FIELD_REQUEST_DATA) as string)}
          </Typography>
        </>
      ) : (
        <Loader />
      )}
    </Grid>
  </Grid>
);

import { Box } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { NoData } from "components/atoms";

type EmptyRowProps = {
  text?: string;
};

export const EmptyRow = ({ text }: EmptyRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        left: "40%",
        paddingTop: spacing[32],
        justifyContent: "center",
        height: spacing[220],
      }}
    >
      <NoData
        height={spacing[156]}
        style={{
          width: spacing[156],
          position: "relative",
        }}
        text={text}
      />
    </Box>
  );
};

import { useTheme } from "@mui/material";
import { rgba } from "polished";
import { useCallback } from "react";

import type { AppThemeProps } from "../theme";

export const useGetPercentageColor = (opacity = 1) => {
  const theme = useTheme() as AppThemeProps;

  return useCallback(
    (percentage: number) => {
      if (!percentage) {
        // unlimited
        return rgba(theme.palette.disabled.tertiaty, 0.3);
      } else if (percentage >= 100) {
        return rgba(theme.palette.error.main, opacity);
      } else if (percentage > 75) {
        return rgba(theme.palette.specials.orange.bright, opacity);
      } else if (percentage > 50) {
        return rgba(theme.palette.specials.yellow.bright, opacity);
      } else {
        return rgba(theme.palette.success.main, opacity);
      }
    },
    [theme, opacity]
  );
};

import { useCallback, useEffect, useState } from "react";
import { createPortal, unmountComponentAtNode } from "react-dom";

import type { ReactNode, ReactPortal } from "react";

type CustomPortal = {
  render: (props: { children: ReactNode }) => ReactPortal | null;
  remove: () => boolean;
};

export const usePortal = (element: Element | null) => {
  const [portal, setPortal] = useState<CustomPortal>({
    render: () => null,
    remove: () => false,
  });

  const createPortalCallback = useCallback((element: Element) => {
    const Portal: CustomPortal["render"] = ({ children }) =>
      createPortal(children, element);
    const remove = () => unmountComponentAtNode(element);

    return { render: Portal, remove };
  }, []);

  useEffect(() => {
    let newPortal: CustomPortal | null = null;

    if (element) {
      portal.remove();
      newPortal = createPortalCallback(element);
      setPortal(newPortal);
    }

    return () => {
      if (newPortal) {
        newPortal.remove();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPortalCallback, element]);

  return portal.render;
};

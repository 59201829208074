import { DeploymentRequestListStatus } from "libs/data/models";

export const STATUS_FILTERS: {
  title: string;
  value: string;
  statusForColor?: string;
}[] = [
  {
    title: "finished",
    value:
      DeploymentRequestListStatus.completed +
      "," +
      DeploymentRequestListStatus.failed +
      "," +
      DeploymentRequestListStatus.cancelled,
  },
  { title: "success", value: DeploymentRequestListStatus.completed },
  {
    title: DeploymentRequestListStatus.failed,
    value:
      DeploymentRequestListStatus.failed +
      "," +
      DeploymentRequestListStatus.cancelled,
  },
  {
    title: DeploymentRequestListStatus.processing,
    value: DeploymentRequestListStatus.processing,
  },
  {
    title: DeploymentRequestListStatus.pending,
    value: DeploymentRequestListStatus.pending,
  },
];

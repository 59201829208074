export const TAG_ID = "G-G04FBH9MZ1";

// args are not used, but kept for the function signature
export function gtag(...args: unknown[]) {
  window.dataLayer = window.dataLayer || [];
  // only works with legacy `arguments`
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);

  return args;
}

export function gtmEvent(event: string, data: any) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, ...data });
}

export const setupGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

  const firstScript = document.getElementsByTagName("script")[0];
  const googleScript = document.createElement("script");

  googleScript.async = true;
  googleScript.src = "https://www.googletagmanager.com/gtm.js?id=" + TAG_ID;
  firstScript.parentNode?.insertBefore(googleScript, firstScript);
};

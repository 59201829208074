import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

import { ScalingStrategyDefault } from "assets/images/ScalingStrategyDefault";
import { ScalingStrategyReserved } from "assets/images/ScalingStrategyReserved";
import { borderRadius, shadows, spacing } from "assets/styles/theme";
import { FIELD_SCALING_STRATEGY } from "libs/constants/fields";
import { DeploymentVersionCreateScalingStrategy } from "libs/data/models";
import { explanations } from "libs/utilities/explanations";
import { toUpperFirstCase } from "libs/utilities/util-functions";

import { FormSection } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";

const options = [
  {
    Image: ScalingStrategyDefault,
    description: explanations.deployments.versions.scalingStrategy.default,
    value: DeploymentVersionCreateScalingStrategy.default,
  },
  {
    Image: ScalingStrategyReserved,
    description: explanations.deployments.versions.scalingStrategy.moderate,
    value: DeploymentVersionCreateScalingStrategy.moderate,
  },
];

export const DeploymentVersionScalingStrategy = ({
  defaultValue = DeploymentVersionCreateScalingStrategy.default,
}: {
  defaultValue?: string;
}) => {
  const theme = useTheme() as AppThemeProps;
  const { register, control, setValue } = useFormContext();
  const value: string | undefined = useWatch({
    name: FIELD_SCALING_STRATEGY,
    control,
  });

  return (
    <FormSection
      title="Scaling strategy"
      description={explanations.deployments.versions.scalingStrategy.main}
    >
      <input
        type="hidden"
        name={FIELD_SCALING_STRATEGY}
        ref={register}
        defaultValue={defaultValue}
        value={value}
      />
      <Grid container spacing={4} padding={spacing[4]}>
        {options.map((opt) => (
          <Grid
            item
            container
            flexDirection="column"
            xs={6}
            gap={2}
            key={toUpperFirstCase(opt.value)}
            onClick={() => setValue(FIELD_SCALING_STRATEGY, opt.value)}
          >
            <Box
              display="flex"
              alignItems="stretch"
              justifyContent="center"
              flexDirection="column"
              boxShadow={shadows.secondary}
              sx={{
                height: spacing[256],
                cursor: "pointer",
                background:
                  value === opt.value
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,

                ":hover": {
                  background: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                },
                border: "solid",
                borderWidth: value === opt.value ? "5px" : "2px",
                borderRadius: borderRadius[10],
                borderColor:
                  value === opt.value
                    ? theme.palette.secondary.main
                    : theme.palette.border.card,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                sx={{
                  flexGrow: 1,
                  background: theme.palette.background.paper,
                  borderTopRightRadius: borderRadius[8],
                  borderTopLeftRadius: borderRadius[8],
                }}
              >
                <opt.Image
                  fill={
                    value === opt.value
                      ? theme.palette.secondary.main
                      : theme.palette.charts.ticks
                  }
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderBottomRightRadius: borderRadius[10],
                  borderBottomLeftRadius: borderRadius[10],
                  height: spacing[48],
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Typography variant="h5">
                  {toUpperFirstCase(opt.value)}
                </Typography>
              </Box>
            </Box>
            {opt.description}
          </Grid>
        ))}
      </Grid>
    </FormSection>
  );
};

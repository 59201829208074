import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";
import { FIELD_PROVIDER } from "libs/constants/fields";
import { externalBucketTypes } from "libs/utilities/labels-mapping";

import { Icon } from "components/atoms";
import { FormikSelect } from "components/molecules";

import type { AutocompleteRenderInputParams } from "@mui/material";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { FormikProps, FormikValues } from "formik";

export interface ProviderSelectProps {
  control: FormikProps<FormikValues>;
}

export const ProviderSelect = ({ control }: ProviderSelectProps) => {
  const [selectedProvider, setSelectedProvider] =
    useState<AutocompleteSelectOption>({ label: "", value: "" });

  return (
    <FormikSelect
      control={control}
      name={FIELD_PROVIDER}
      options={externalBucketTypes}
      value={selectedProvider}
      disableInputChange
      onChange={(value) => value && setSelectedProvider(value)}
      // props need to be passed here, but there isn't a pre defined type for them
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderOption={(props: any, option: AutocompleteSelectOption) => {
        return (
          <Box
            style={{
              alignItems: "center",
              display: "flex",
              gridColumnGap: spacing[8],
              paddingLeft: spacing[16],
            }}
            {...props}
          >
            <Icon
              component={option.icon}
              style={{ height: "25px", width: "25px" }}
            />
            <Typography variant="body2">{option.label} </Typography>
          </Box>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          value={selectedProvider.label}
          placeholder="Select..."
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Icon
                component={selectedProvider?.icon || null}
                style={{ height: "25px", width: "25px" }}
              />
            ),
          }}
        >
          {selectedProvider?.label || ""}
        </TextField>
      )}
    />
  );
};

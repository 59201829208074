import Plus from "@mui/icons-material/AddBoxRounded";
import DownloadIcon from "@mui/icons-material/CloudDownloadRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import { Link, Tooltip, Box, IconButton, useTheme } from "@mui/material";
import React, {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { IMPORTS_STATUS_FILTER } from "libs/constants/fields";
import { IMPORT_EXPORT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { axios } from "libs/data/axios";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate, DATE_TIME_FORMAT } from "libs/utilities/date-util";
import { downloadFileStreaming } from "libs/utilities/download-helper";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { formatQueryParams } from "libs/utilities/request-util";
import { isArrayHasData, isObjectHasData } from "libs/utilities/utils";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  StatusIcon,
  DeleteDialog,
  PrimaryButton,
  ToggleButtonGroup,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { ImportExportDetailsDialog } from "components/organisms";

const ImportsOverview = () => {
  useGoogleAnalytics();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const baseUrl = useContext(BaseUrlContext);
  const { projectName } = useParams();
  const dispatch = useDispatch();
  const timeoutId = useRef();
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imports, setImports] = useState([]);
  const [selectedImport, setSelectedImport] = useState(undefined);
  const [selectedFilters, setSelectedFilters] = useState(
    IMPORTS_STATUS_FILTER[0].title
  );

  const [currentPermissions] = useGetPermissions();

  const currentOrganization = useGetCurrentOrganization();

  const permissions = useMemo(
    () => ({
      import_list: currentPermissions[IMPORT_EXPORT_PERMISSIONS["import_list"]],
      import_get: currentPermissions[IMPORT_EXPORT_PERMISSIONS["import_get"]],
      import_update:
        currentPermissions[IMPORT_EXPORT_PERMISSIONS["import_update"]],
      import_create:
        currentPermissions[IMPORT_EXPORT_PERMISSIONS["import_create"]],
      import_delete:
        currentPermissions[IMPORT_EXPORT_PERMISSIONS["import_delete"]],
    }),
    [currentPermissions]
  );

  const fetchTableData = useCallback(
    (loading = true) => {
      setIsLoading(loading);
      if (timeoutId) {
        clearTimeout(timeoutId.current);
      }
      let queryParams = {};
      if (selectedFilters !== "all") queryParams["status"] = selectedFilters;

      queryParams = isObjectHasData(queryParams)
        ? `?${formatQueryParams(queryParams)}`
        : "";
      const url = `projects/${projectName}/imports${queryParams}`;

      return axios({
        method: "GET",
        url,
      })
        .then(({ data }) => {
          const buildingData = data.findIndex(
            (element) =>
              element.status === "pending" ||
              element.status === "processing" ||
              element.status === "scanning" ||
              element.status === "confirmation_pending"
          );
          if (buildingData === -1) {
            setRefresh(false);
          }
          setImports(data);
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [projectName, dispatch, selectedFilters]
  );

  useEffect(() => {
    // Fetch data on initial mount and when filters change
    if (permissions.import_list) {
      fetchTableData();
    }
  }, [fetchTableData, permissions.import_list]);

  useEffect(() => {
    // Refresh table if there are in-building-process imports
    const intervalId = setInterval(() => {
      if (isArrayHasData(imports) && refresh) {
        fetchTableData();
      }
    }, CONTINUOUS_REQUEST_DELAY);

    return () => clearInterval(intervalId);
  }, [fetchTableData, refresh, imports]);

  const onRowClick = useCallback(
    (_, rowData) => {
      if (permissions.import_get) {
        setSelectedImport(rowData);
        setImportDialogOpen(true);
      }
    },
    [permissions.import_get, setImportDialogOpen]
  );

  const downloadImport = useCallback(
    (rowData, allowDownload) => (event) => {
      event.stopPropagation();
      if (allowDownload) {
        axios({
          method: "POST",
          url: `/authorize/single-use-token`,
        })
          .then((response) => {
            downloadFileStreaming(
              `/projects/${projectName}/imports/${rowData?.id}/download?authtoken=${response.data.token}`
            );
          })
          .catch((error) => {
            dispatch(createErrorNotification(error.message));
          });
      }
    },
    [dispatch, projectName]
  );

  const deleteImport = useCallback(
    (importId) => {
      axios({
        method: "DELETE",
        url: `projects/${projectName}/imports/${importId}`,
      })
        .then((response) => {
          if (response.status === 204) {
            setImports(
              Object.values(imports).filter(({ id }) => id !== importId)
            );
            dispatch(createSuccessNotification("Import deleted successfully"));
          }
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        });
    },
    [dispatch, imports, projectName]
  );

  const handleDeleteImport = useCallback(
    (rowData, allowAction) => (event) => {
      event.stopPropagation();
      if (allowAction) {
        setSelectedImport(rowData);
        setDeleteDialogOpen(true);
      }
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        title: "Status",
        field: "status",
        width: "20%",
        render: ({ status }) => {
          return (
            <StatusIcon
              label={
                status === "confirmation" ? "waiting for confirmation" : status
              }
              status={status === "scanning" ? "pending" : status}
            />
          );
        },
      },
      {
        title: "ID",
        field: "id",
        width: "30%",
        nowrap: true,
        render: ({ id }) => (
          <Link
            component="button"
            variant="h5"
            sx={{ color: theme.palette.text.primary }}
          >
            {id}
          </Link>
        ),
      },
      {
        title: "Created",
        field: "creation_date",
        type: "datetime",
        width: "35%",
        nowrap: true,
        render: (rowData) =>
          getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
        defaultSort: "desc",
      },
      {
        disableClick: true,
        width: "2%",
        render: (rowData) => {
          const allowedStatus =
            rowData?.status === "confirmation" ||
            rowData?.status === "failed" ||
            rowData?.status === "completed";

          const allowDelete = permissions.import_delete && allowedStatus;
          const allowDownload = permissions.import_get && allowedStatus;

          return (
            <div className="actions_container">
              <Tooltip title="Download">
                <span>
                  <IconButton
                    color="secondary"
                    onClick={downloadImport(rowData, allowDownload)}
                    disabled={!allowDownload}
                  >
                    <DownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    onClick={handleDeleteImport(rowData, allowDelete)}
                    disabled={!allowDelete}
                  >
                    <Trash sx={{ color: theme.palette.text.primary }} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [
      permissions.import_delete,
      permissions.import_get,
      downloadImport,
      handleDeleteImport,
      theme.palette.text.primary,
    ]
  );

  return (
    <>
      <BaseTable
        columns={columns}
        data={imports}
        onRowClick={onRowClick}
        isLoading={isLoading}
        defaultSortColumn="creation_date"
        defaultSortDirection="asc"
        header={
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            width="fit-content"
          >
            <PrimaryButton
              startIcon={<Plus />}
              disabled={
                !permissions.import_create ||
                currentOrganization.status !== "active"
              }
              onClick={() => history.push(`${baseUrl}/imports/create`)}
              style={{ width: "fit-content" }}
            >
              Create import
            </PrimaryButton>
            <ToggleButtonGroup
              onChange={(value) => {
                if (value != null) {
                  setSelectedFilters(value);
                  setImports([]);
                }
              }}
              value={selectedFilters}
              exclusive
              size="small"
              items={IMPORTS_STATUS_FILTER}
            />
          </Box>
        }
      />

      <ImportExportDetailsDialog
        onClose={() => setImportDialogOpen(false)}
        open={importDialogOpen}
        page="Import"
        recordData={selectedImport}
        importConfirmPermission={permissions.import_update}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => deleteImport(selectedImport?.id)}
      >
        Are you sure you want to delete import {`"`}
        <b>{selectedImport?.id}</b>
        {`"`}?
      </DeleteDialog>
    </>
  );
};

export default ImportsOverview;

import { useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { useGoogleAnalytics } from "libs/hooks";

import { Loader } from "components/atoms";
import { BatchRequestCreate } from "components/organisms";

import type { DeploymentVersionDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/:versionName/types";

export const DeploymentVersionBatchRequestCreate = () => {
  useGoogleAnalytics();
  const { projectName, deploymentName } =
    useParams<DeploymentVersionDetailsRouteParams>();
  const { data: deployment, error } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const baseUrl = useContext(BaseUrlContext);

  return (
    <>
      <BreadcrumbsItem to={`${baseUrl}/requests`}>Requests</BreadcrumbsItem>
      {deployment || error ? (
        <BatchRequestCreate
          inputFields={deployment?.input_fields ?? []}
          inputType={deployment?.input_type ?? ""}
          type="deployments"
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

import { Tab as MaterialTab, Typography } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { TabProps as MaterialTabProps } from "@mui/material";
import type { CSSProperties } from "react";

type TabProps = Pick<MaterialTabProps, "value"> & {
  label: string;
  padding?: "normal" | "compact";
  style?: CSSProperties;
};

export const Tab = ({ label, padding, style = {}, ...props }: TabProps) => {
  if (padding === "compact") {
    style = {
      minWidth: 80,
      padding: 2,
      ...style,
    };
  }

  return (
    <MaterialTab
      label={
        <Typography variant="h3" style={{ padding: spacing[8] }}>
          {label}
        </Typography>
      }
      style={style}
      {...props}
    />
  );
};

export const PROJECT_PERMISSIONS = {
  project_get: "projects.get",
  project_update: "projects.update",
  project_delete: "projects.delete",

  logs_get: "projects.logs.get",
  audit_list: "projects.audit.list",

  metrics_list: "projects.metrics.list",
  metrics_get: "projects.metrics.get",
  metrics_create: "projects.metrics.create",
  metrics_update: "projects.metrics.update",
  metrics_delete: "projects.metrics.delete",

  // we currently don't use these in the ui but let's keep them for later.
  env_vars_create: "projects.environment_variables.create",
  env_vars_update: "projects.environment_variables.update",
  env_vars_delete: "projects.environment_variables.delete",
};

export const IMPORT_EXPORT_PERMISSIONS = {
  export_list: "projects.exports.list",
  export_get: "projects.exports.get",
  export_create: "projects.exports.create",
  export_delete: "projects.exports.delete",

  import_list: "projects.imports.list",
  import_get: "projects.imports.get",
  import_create: "projects.imports.create",
  import_update: "projects.imports.update",
  import_delete: "projects.imports.delete",
};

export const DEPLOYMENT_PERMISSIONS = {
  list: "deployments.list",
  get: "deployments.get",
  create: "deployments.create",
  update: "deployments.update",
  delete: "deployments.delete",

  audit_list: "deployments.audit.list",

  env_vars_list: "deployments.environment_variables.list",
  env_vars_get: "deployments.environment_variables.get",
  env_vars_create: "deployments.environment_variables.create",
  env_vars_update: "deployments.environment_variables.update",
  env_vars_delete: "deployments.environment_variables.delete",

  version_list: "deployments.versions.list",
  version_get: "deployments.versions.get",
  version_create: "deployments.versions.create",
  version_update: "deployments.versions.update",
  version_delete: "deployments.versions.delete",
  version_upload: "deployments.versions.file.update",
  version_download: "deployments.versions.file.get",

  version_env_vars_list: "deployments.versions.environment_variables.list",
  version_env_vars_get: "deployments.versions.environment_variables.get",
  version_env_vars_create: "deployments.versions.environment_variables.create",
  version_env_vars_update: "deployments.versions.environment_variables.update",
  version_env_vars_delete: "deployments.versions.environment_variables.delete",

  version_request_list: "deployments.versions.requests.list",
  version_request_get: "deployments.versions.requests.get",
  version_request_create: "deployments.versions.requests.create",
  version_request_update: "deployments.versions.requests.update",
  version_request_delete: "deployments.versions.requests.delete",
};

export const ENVIRONMENT_PERMISSIONS = {
  list: "environments.list",
  get: "environments.get",
  create: "environments.create",
  update: "environments.update",
  delete: "environments.delete",

  version_download: "environments.file.get",
  version_upload: "environments.file.update",
};

export const PIPELINE_PERMISSIONS = {
  list: "pipelines.list",
  get: "pipelines.get",
  create: "pipelines.create",
  update: "pipelines.update",
  delete: "pipelines.delete",

  audit_list: "pipelines.audit.list",

  version_list: "pipelines.versions.list",
  version_get: "pipelines.versions.get",
  version_create: "pipelines.versions.create",
  version_update: "pipelines.versions.update",
  version_delete: "pipelines.versions.delete",

  version_request_create: "pipelines.versions.requests.create",
  version_request_list: "pipelines.versions.requests.list",
  version_request_get: "pipelines.versions.requests.get",
  version_request_update: "pipelines.versions.requests.update",
  version_request_delete: "pipelines.versions.requests.delete",
};

export const USER_ROLE_PERMISSIONS = {
  role_list: "roles.list",
  role_get: "roles.get",
  role_create: "roles.create",
  role_update: "roles.update",
  role_delete: "roles.delete",

  assignment_list: "roles.assignments.list",
  assignment_get: "roles.assignments.get",
  assignment_create: "roles.assignments.create",
  assignment_delete: "roles.assignments.delete",
};

export const BUCKET_PERMISSIONS = {
  list: "buckets.list",
  get: "buckets.get",
  create: "buckets.create",
  update: "buckets.update",
  delete: "buckets.delete",
};

export const FILES_PERMISSIONS = {
  list: "files.list",
  get: "files.get",
  create: "files.create",
  delete: "files.delete",
};

export const WEBHOOKS_PERMISSIONS = {
  list: "webhooks.list",
  get: "webhooks.get",
  create: "webhooks.create",
  update: "webhooks.update",
  delete: "webhooks.delete",
};

export const SERVICE_USER_PERMISSIONS = {
  list: "serviceusers.list",
  get: "serviceusers.get",
  create: "serviceusers.create",
  update: "serviceusers.update",
  delete: "serviceusers.delete",
};

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { oauthComplete } from "libs/data/endpoints/authorize/authorize";
import { ENV_NAMES, env } from "libs/env";
import { setError } from "store/features";

import type { AxiosError } from "axios";
import type { OauthComplete } from "libs/data/models";

export const useSignInOauth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const signInOauth = async (payload: OauthComplete) => {
    try {
      setIsLoading(true);
      if (!isLoading) {
        const { access, refresh } = await oauthComplete(payload);
        env.set(ENV_NAMES.ACCESS_TOKEN, access);
        env.set(ENV_NAMES.REFRESH_TOKEN, refresh);
        env.set(ENV_NAMES.LAST_LOGIN, Date.now());
      }

      setIsLoading(false);
      history.push("/");
    } catch (e) {
      setIsLoading(false);
      const error = e as AxiosError;
      dispatch(setError(error?.message));
      history.push("/sign-in");
    }
  };

  return { signInOauth, isLoading };
};

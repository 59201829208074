import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  serviceUsersCreate,
  useServiceUsersList,
} from "libs/data/endpoints/service-users/service-users";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { ServiceUserCreate } from "libs/data/models";

export const useServiceUserCreate = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useServiceUsersList(projectName);

  return useCallback(
    async (data: ServiceUserCreate) => {
      if (!projectName) {
        return Promise.reject();
      }

      return serviceUsersCreate(projectName, data)
        .then(async (result) => {
          dispatch(createSuccessNotification("The service user was created"));
          mutate();

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, mutate, dispatch]
  );
};

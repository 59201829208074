import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  DEFAULT_TIME_OUT_PIPELINE_REQUESTS,
  DEFAULT_TIME_OUT_REQUESTS,
  STRUCTURED_TYPE,
  TIME_OUT_PIPELINE_REQUESTS,
  TIME_OUT_REQUESTS,
} from "libs/constants/constants";
import { useDeploymentDirectRequestCreate } from "libs/data/customized/deployment-requests";
import { useDeploymentStreamingRequestCreate } from "libs/data/customized/deployment-requests/useDeploymentStreamingRequestCreate";
import { usePipelineDirectRequestCreate } from "libs/data/customized/pipeline-requests";
import { gtmEvent } from "libs/hooks/useGoogleAnalytics";
import { routes } from "routes";
import { useGetOrganizationFeatures } from "store/features";

import { WarningText, Divider, Loader, Dialog } from "components/atoms";

import { CreateBatchRequestSection } from "./CreateBatchRequestSection";
import { CreateDirectRequestSection } from "./CreateDirectRequestSection";
import { RequestDetails } from "../RequestResultsDialog";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  DeploymentDetail,
  PipelineDetail,
  DeploymentRequestCreateResponse,
  PipelineRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
} from "libs/data/models";
import type { Dispatch, SetStateAction } from "react";

export type DeploymentRequestParameters = {
  type: "deployment";
  resourceName?: string;
  resourceVersion?: string;
  inputType?: DeploymentDetail["input_type"];
  inputFields?: DeploymentDetail["input_fields"];
  outputType?: DeploymentDetail["output_type"];
  outputFields?: DeploymentDetail["output_fields"];
  requestScheduleName?: string;
};

export type PipelineRequestParameters = {
  type: "pipeline";
  resourceName?: string;
  resourceVersion?: string;
  inputType?: PipelineDetail["input_type"];
  inputFields?: PipelineDetail["input_fields"];
  outputType?: PipelineDetail["output_type"];
  outputFields?: PipelineDetail["output_fields"];
  requestScheduleName?: string;
};

type RequestDialogProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  organizationName: string;
  projectName: string;
  warningText?: string;
  isForcedCreateOpen?: boolean;
  setForcedCreateOpen?: (value: boolean) => void;
  requestParameters: DeploymentRequestParameters | PipelineRequestParameters;
  isRetentionModeNone: boolean;
};

export const RequestDialog = ({
  isOpen,
  setIsOpen,
  organizationName,
  projectName,
  warningText,
  requestParameters,
  isRetentionModeNone,
  isForcedCreateOpen,
  setForcedCreateOpen,
}: RequestDialogProps) => {
  const {
    type,
    resourceName,
    resourceVersion,
    inputType = STRUCTURED_TYPE,
    inputFields = [],
  } = requestParameters;
  const theme = useTheme() as AppThemeProps;
  const [loading, setLoading] = useState(false);
  const organizationFeatures = useGetOrganizationFeatures();
  const [requestTimeout, setRequestTimeout] = useState(
    type === "deployment"
      ? DEFAULT_TIME_OUT_REQUESTS
      : DEFAULT_TIME_OUT_PIPELINE_REQUESTS
  );
  const [requestResults, setRequestResults] =
    useState<
      | {
          responseData:
            | DeploymentRequestCreateResponse
            | PipelineRequestCreateResponse
            | undefined;
          requestData: DeploymentRequestsCreateDataBody;
        }
      | undefined
    >(undefined);
  const {
    location: { pathname },
  } = useHistory();

  const isStreamingEnabled = type === "deployment";

  const { startStream, streamedResponse } = useDeploymentStreamingRequestCreate(
    projectName,
    type === "deployment" && resourceName ? resourceName : "",
    requestParameters as DeploymentRequestParameters,
    requestTimeout,
    resourceVersion,
    isRetentionModeNone
  );

  useEffect(() => {
    if (isStreamingEnabled) {
      setRequestResults(streamedResponse);
    }
  }, [streamedResponse, isStreamingEnabled]);

  useEffect(() => {
    return () => {
      setRequestResults(undefined);
    };
  }, []);

  const createDeploymentDirectRequest = useDeploymentDirectRequestCreate(
    projectName,
    type === "deployment" && resourceName ? resourceName : "",
    resourceVersion,
    requestTimeout
  );
  const createPipelineDirectRequest = usePipelineDirectRequestCreate(
    projectName,
    type === "pipeline" && resourceName ? resourceName : "",
    resourceVersion,
    requestTimeout
  );
  useEffect(() => {
    setIsOpen(false);
  }, [pathname, setIsOpen]);

  const onSubmitCreateRequest = async (
    requestData: DeploymentRequestsCreateDataBody
  ) => {
    setForcedCreateOpen?.(false);
    setLoading(true);
    const results =
      type === "deployment"
        ? await createDeploymentDirectRequest(requestData, {
            ...requestParameters,
            isRetentionModeNone,
          })
        : await createPipelineDirectRequest(requestData, {
            ...requestParameters,
            isRetentionModeNone,
          });

    setRequestResults(results);
    setLoading(false);
    gtmEvent("direct_request_created", {
      event_category: "requests",
    });
  };

  const createBatchRequestsUrl =
    type === "deployment"
      ? routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .deployments[":deploymentName"](resourceName || "")
          .versions[":versionName"](resourceVersion || "")
          .requests.batch.create.index()
      : type === "pipeline"
      ? routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines[":pipelineName"](resourceName || "")
          .versions[":versionName"](resourceVersion || "")
          .requests.batch.create.index()
      : "";

  const maxRequestTimeout = useMemo(
    () =>
      type === "deployment"
        ? organizationFeatures?.max_express_deployment_timeout ||
          TIME_OUT_REQUESTS
        : organizationFeatures?.max_express_pipeline_timeout ||
          TIME_OUT_PIPELINE_REQUESTS,
    [organizationFeatures, type]
  );

  const shouldShowLoading = loading && !isForcedCreateOpen;
  const shouldShowResults = Boolean(requestResults);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setRequestResults(undefined);
      }}
      maxWidth={shouldShowResults && !loading ? "lg" : undefined}
      title={
        shouldShowResults || shouldShowLoading ? "Results" : "Create request"
      }
    >
      {shouldShowLoading ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} component={Loader} />
        </Grid>
      ) : shouldShowResults ? (
        <RequestDetails
          organizationName={organizationName}
          projectName={projectName}
          resourceType={type}
          resourceName={resourceName}
          resourceVersion={resourceVersion}
          requestId={requestResults?.responseData?.id}
          directResult={requestResults?.responseData}
          fetchOnlyMetadata={false}
        />
      ) : (
        <Grid container spacing={2} style={{ marginBottom: 3 }}>
          {warningText && (
            <Grid item>
              <WarningText color={theme.palette.primary.main}>
                {warningText}
              </WarningText>
            </Grid>
          )}

          <CreateBatchRequestSection
            objectType={type}
            createBatchRequestsUrl={createBatchRequestsUrl}
            onClickCreateBatchRequest={() => setIsOpen(false)}
          />

          <Grid item xs={12}>
            <Divider my={1} />
            <Typography variant="subtitle1" align="center">
              OR
            </Typography>
            <Divider my={1} />
          </Grid>

          <CreateDirectRequestSection
            objectName={resourceName as string}
            objectType={type}
            inputType={inputType}
            inputFields={inputFields}
            onSubmit={isStreamingEnabled ? startStream : onSubmitCreateRequest}
            requestTimeout={requestTimeout}
            setRequestTimeout={setRequestTimeout}
            maxRequestTimeout={maxRequestTimeout}
          />
        </Grid>
      )}
    </Dialog>
  );
};

import styled from "@emotion/styled";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Typography, useTheme } from "@mui/material";

import { ReactComponent as AWS } from "assets/images/bucket-logo-aws.svg";
import { ReactComponent as Azure } from "assets/images/bucket-logo-azure.svg";
import { ReactComponent as GCS } from "assets/images/bucket-logo-gcs.svg";
import { ReactComponent as Ubiops } from "assets/images/bucket-logo-ubiops.svg";
import { borderRadius, shadows, spacing } from "assets/styles/theme";
import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";

import { Icon } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BucketList } from "libs/data/models";

type BucketProps = {
  item: BucketList;
  onClick: () => void;
};

export const Bucket = ({ item, onClick }: BucketProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      position="relative"
      width="14rem"
      height="9rem"
      display="flex"
      flex-direction="column"
      className="cursor-pointer"
    >
      <FolderDivider color={useStringToColor(item.name)} />
      <Folder onClick={() => onClick()} color={useStringToColor(item.name)}>
        <Box
          width={"100%"}
          padding={spacing[8]}
          alignSelf="flex-start"
          style={{ flex: 1 }}
        ></Box>
        <Details
          style={{
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Box display="flex" alignItems="center">
            <Icon
              component={Icons[item.provider ?? "default"]}
              style={{
                height: spacing[30],
                width: spacing[30],
                marginRight: spacing[8],
              }}
            />
            <Typography variant="h5" className="name">
              {item?.name}
            </Typography>
          </Box>
          <ArrowRightIcon />
        </Details>
      </Folder>
    </Box>
  );
};

export const Icons = {
  amazon_s3: AWS,
  azure_blob_storage: Azure,
  google_cloud_storage: GCS,
  ubiops: Ubiops,
  default: null,
};

const Folder = styled(Box)<{ color: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: ${borderRadius[10]};
  margin-top: -${spacing[16]};
  border-top-left-radius: 0;
  align-items: center;
  z-index: 20;
  align-self: flex-end;
  position: absolute;
  top: ${spacing[16]};
  :hover {
    filter: brightness(70%);
  }
`;

const FolderDivider = styled.div<{ color: string }>`
  height: ${spacing[16]};
  width: ${spacing[80]};
  margin-top: -${spacing[16]};
  z-index: 0;
  align-self: flex-start;
  border-top-right-radius: ${borderRadius[8]};
  border-top-left-radius: ${borderRadius[8]};
  background-color: ${(props) => props.color};
  filter: brightness(80%);
`;

const Details = styled.div`
  width: 100%;
  height: 35%;
  border-bottom-right: 8px;
  border-bottom-right-radius: ${borderRadius[10]};
  border-bottom-left-radius: ${borderRadius[10]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em;
  box-shadow: ${shadows.primaryLight};
  &name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: -webkit-fill-available;
  }
`;

import { TextField as MuiTextField } from "@mui/material";

import type { TextFieldProps as MuiTextFieldProps } from "@mui/material";

export type TextFieldProps = MuiTextFieldProps & {
  name: string;
};

export const TextField = ({ name, ...props }: TextFieldProps) => {
  return (
    <MuiTextField
      name={name}
      margin="dense"
      variant="outlined"
      fullWidth
      {...props}
    />
  );
};

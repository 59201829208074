import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import PipelineRoutesContainer from "./:pipelineName";
import PipelineCreate from "./PipelineCreate";
import PipelinesOverview from "./PipelinesOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").pipelines;

export const PipelinesPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).pipelines;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Pipelines</BreadcrumbsItem>
      <Switch>
        <Route exact path={basePath.index()} component={PipelinesOverview} />
        <Route path={basePath.create.index()} component={PipelineCreate} />
        <Route
          path={basePath[":pipelineName"](":pipelineName").index()}
          component={PipelineRoutesContainer}
        />
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

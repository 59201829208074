import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { authorizeSingleUseToken } from "libs/data/endpoints/authorize/authorize";
import { downloadFileStreaming } from "libs/utilities/download-helper";
import { createErrorNotification } from "libs/utilities/notifications";

export const useEnvironmentRevisionDownload = (projectName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (environmentName: string, revisionId?: string) => {
      try {
        const response = await authorizeSingleUseToken({});
        downloadFileStreaming(
          `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/download?authtoken=${response.token}`
        );
      } catch (error: any) {
        dispatch(createErrorNotification(error.message));
      }
    },
    [projectName, dispatch]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  useWebhooksGet,
  webhooksUpdate,
} from "libs/data/endpoints/webhooks/webhooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { WebhookUpdate } from "libs/data/models";

export const useWebHookUpdate = (projectName: string, webHookName: string) => {
  const dispatch = useDispatch();

  const { mutate } = useWebhooksGet(projectName, webHookName);

  return useCallback(
    async (data: WebhookUpdate) => {
      try {
        const response = await webhooksUpdate(projectName, webHookName, data);

        dispatch(createSuccessNotification("The webhook was updated"));
        mutate();

        return response;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [dispatch, projectName, mutate, webHookName]
  );
};

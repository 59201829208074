import { useMemo } from "react";
import useSWR from "swr";

import {
  DEDUPING_INTERVAL,
  FOCUS_THROTTLE_INTERVAL,
} from "libs/constants/constants";
import { validatePermissionsForUser } from "libs/data/endpoints/roles/roles";
import { useUserGet } from "libs/data/endpoints/user/user";

import type { PermissionValidationList } from "libs/data/models";

export const usePermissionValidation = (
  projectName: string,
  permissionsNames: (string | null)[],
  entityName?: string,
  entityType?: "pipeline" | "deployment" | "bucket" | "environment" | "project"
) => {
  const { data: user, isValidating: isUserLoading } = useUserGet();

  const { data: validationResult, isValidating: isPermissionLoading } = useSWR<
    PermissionValidationList[]
  >(
    // This string is just a key to cache the result under it. It isn't a real endpoint.
    user?.id && projectName
      ? [
          `/projects/${projectName}/validate-permissions`,
          entityType,
          entityName,
          ...permissionsNames,
        ]
      : null,
    user?.id && projectName
      ? () =>
          validatePermissionsForUser(projectName, {
            resource: entityName,
            resource_type: entityType,
            permissions: permissionsNames,
          })
      : null,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
      dedupingInterval: DEDUPING_INTERVAL,
    }
  );

  const permissions = useMemo(() => {
    return Object.fromEntries(
      (validationResult || [])?.map((item) => [item.permission, item.success])
    );
  }, [validationResult]);

  return [permissions, isUserLoading || isPermissionLoading] as [
    permissions: typeof permissions,
    loading: boolean
  ];
};

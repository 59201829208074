import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { borderRadius, borders } from "assets/styles/theme";
import {
  DEFAULT_TIME_OUT_BATCH_REQUESTS,
  DICT_TYPE,
  MAX_TIME_OUT_BATCH_REQUESTS,
} from "libs/constants/constants";
import {
  FIELD_PARAMETERS,
  FIELD_TIMEOUT,
  FIELD_TRAINING_DATA,
  FIELD_TRAINING_SCRIPT,
  FIELD_DESCRIPTION,
  FIELD_NAME,
} from "libs/constants/fields";
import { useRunCreate } from "libs/data/customized/training";
import { explanations } from "libs/utilities/explanations";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import { FormTextField } from "components/atoms";
import {
  FileInputField,
  FormSection,
  GeneralFieldsSection,
} from "components/molecules";
import { FormContainer } from "components/organisms";

import type { DeploymentRequestSingleDetail } from "libs/data/models";

type FormData = {
  [FIELD_NAME]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_TIMEOUT]?: number;
  [FIELD_TRAINING_SCRIPT]: string;
  [FIELD_TRAINING_DATA]: string | null;
  [FIELD_PARAMETERS]: string;
};

type RunFormProps = {
  runDetails?: DeploymentRequestSingleDetail;
};
export const RunForm = ({ runDetails }: RunFormProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { organizationName, projectName, experimentName } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
    }>();

  const baseUrl = useMemo(() => {
    return routes.organizations[":organizationName"](organizationName)
      .projects[":projectName"](projectName)
      .training.experiments[":experimentName"](experimentName).runs;
  }, [organizationName, projectName, experimentName]);

  const createRun = useRunCreate(projectName, experimentName);
  const createRunFormMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = createRunFormMethods;

  const runParameters = useMemo(() => {
    if (
      runDetails?.request_data?.[FIELD_PARAMETERS] &&
      Object.keys(runDetails?.request_data?.[FIELD_PARAMETERS]).length
    )
      return JSON.stringify(runDetails?.request_data?.[FIELD_PARAMETERS]);

    return null;
  }, [runDetails]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const timeout = data[FIELD_TIMEOUT];
    const runData = {
      ...data,
      [FIELD_PARAMETERS]: data[FIELD_PARAMETERS]
        ? JSON.parse(data[FIELD_PARAMETERS])
        : {},
    };
    delete runData[FIELD_TIMEOUT];
    if (!data[FIELD_TRAINING_DATA]) {
      runData[FIELD_TRAINING_DATA] = null;
    }
    const response = await createRun(runData, timeout);
    setLoading(false);
    if (response?.[0]?.id)
      history.push(baseUrl[":runId"](response?.[0]?.id).general.index());
  };

  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit)}
      status={loading ? LOADING : LOADED}
      formMethods={createRunFormMethods}
      buttonLabel="Create"
    >
      <GeneralFieldsSection
        title="General"
        description={explanations.training.experiments.runs_general}
        namePlaceholder="Ex: training-run-10-01-2023"
        descriptionPlacholder="Ex: Implementation with sklearn"
        nameDefaultValue={
          runDetails?.request_data?.[FIELD_NAME] &&
          `${runDetails?.request_data?.[FIELD_NAME]}-copy`
        }
        descriptionDefaultValue={runDetails?.request_data?.[FIELD_DESCRIPTION]}
      />
      <FormSection
        title="Maximum duration"
        description={explanations.training.experiments.runs_duration}
      >
        <FormTextField
          id={FIELD_TIMEOUT}
          name={FIELD_TIMEOUT}
          tooltip={explanations.training.experiments.runs_timeout}
          label="Timeout (seconds)"
          placeholder={DEFAULT_TIME_OUT_BATCH_REQUESTS.toString()}
          rules={{
            required: false,
            min: {
              value: 0,
              message: validators.minimum_timeout.message,
            },
            max: {
              value: MAX_TIME_OUT_BATCH_REQUESTS,
              message: validators.maximum_timeout.message(
                MAX_TIME_OUT_BATCH_REQUESTS
              ),
            },
          }}
        />
      </FormSection>
      <FormSection
        title="Training code"
        description={explanations.training.experiments.runs_training_script}
      >
        <Box
          border={borders.primary}
          borderRadius={borderRadius[4]}
          padding={4}
        >
          <FileInputField
            label={FIELD_TRAINING_SCRIPT}
            name={FIELD_TRAINING_SCRIPT}
            defaultFileName={runDetails?.request_data?.[FIELD_TRAINING_SCRIPT]}
          />
        </Box>
      </FormSection>
      <FormSection
        title="Training data and parameters"
        description={explanations.training.experiments.runs_training_data}
      >
        <Box
          border={borders.primary}
          borderRadius={borderRadius[4]}
          padding={4}
        >
          <FileInputField
            label={FIELD_TRAINING_DATA}
            name={FIELD_TRAINING_DATA}
            defaultFileName={runDetails?.request_data?.[FIELD_TRAINING_DATA]}
          />
        </Box>

        <FormTextField
          name={FIELD_PARAMETERS}
          rules={{ required: false, ...validators[DICT_TYPE] }}
          label="Parameters"
          tooltip={explanations.training.experiments.runs_training_parameters}
          placeholder='Ex: &#10;{&#10;"epochs" : 15, &#10;"batch_size": 32 &#10;}'
          multiline
          minRows={4}
          defaultValue={runParameters}
        />
      </FormSection>
    </FormContainer>
  );
};

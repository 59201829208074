import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import QRCode from "qrcode.react";
import { useEffect, useState, useContext, useCallback } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { BaseUrlContext } from "libs/contexts";
import { getParameterByName } from "libs/data/axios/url-helpers";
import {
  useDeviceCreate,
  useDeviceDelete,
  useDeviceUpdate,
} from "libs/data/customized/devices";
import { useDevicesGet } from "libs/data/endpoints/user/user";
import { env, ENV_NAMES } from "libs/env";
import { useGoogleAnalytics, useSignOut } from "libs/hooks";
import { createSuccessNotification } from "libs/utilities/notifications";
import validators from "libs/utilities/validators";

import { CopyToClipboardButton, SecondaryButton } from "components/atoms";
import { PageContainer } from "components/molecules";

const STEPS = ["Device name", "Scan QR code or use key", "Confirm device"];

const DeviceCreate = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ forced2FA?: boolean; deviceName?: string }>();
  const signOut = useSignOut();

  const baseUrl = useContext(BaseUrlContext);
  const [activeStep, setActiveStep] = useState(0);
  const [configUrl, setConfigUrl] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [loading, setLoading] = useState(false);
  const isForced2FA = location?.state?.forced2FA;
  const backLink = "/profile/security";

  const { data: device, mutate } = useDevicesGet(deviceName);

  const createDevice = useDeviceCreate();
  const updateDevice = useDeviceUpdate(false);
  const clearDevice = useDeviceDelete(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const step0 = useForm({ mode: "onBlur" });
  const step2 = useForm({ mode: "onBlur" });
  const { setValue } = step0;

  const handleStep0Submit = useCallback(
    async ({ name }: { name: string }) => {
      setLoading(true);
      const response = await createDevice({
        name,
      });
      if (response?.config_url) {
        setDeviceName(name);
        setConfigUrl(response.config_url);
      }
      setLoading(false);
      handleNext();
    },
    [createDevice]
  );

  const resetPage = useCallback(async () => {
    if (location?.state?.deviceName) {
      setValue("name", location?.state?.deviceName);
      await clearDevice(location?.state?.deviceName);
      handleStep0Submit({ name: location?.state?.deviceName });
    }
  }, [clearDevice, handleStep0Submit, location?.state, setValue]);

  useEffect(() => {
    if (location?.state?.deviceName && activeStep === 0) {
      resetPage();
    }
  }, [location?.state, activeStep, resetPage]);

  const handleStep2Submit = async ({ token }: { token: string }) => {
    await updateDevice(deviceName, { token });
    mutate();
  };

  const forceLogOut = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1500));
    env.set(ENV_NAMES.REDIRECT_URL, { ...location, pathname: "/" });
    signOut();
  }, [location, signOut]);

  useEffect(() => {
    if (device?.confirmed) {
      const notificationMessage = isForced2FA
        ? "Device was confirmed. Please sign in again to continue."
        : "Device was confirmed.";
      dispatch(createSuccessNotification(notificationMessage));
      if (isForced2FA) {
        forceLogOut();
      } else history.push(`${backLink}`);
    }
  }, [device?.confirmed, history, dispatch, isForced2FA, forceLogOut]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <form
            name="step0form"
            onSubmit={step0.handleSubmit(handleStep0Submit)}
          >
            <Box width={spacing[300]}>
              <FormControl margin="dense" fullWidth error={!!step0.errors.name}>
                <InputLabel htmlFor="name">Device name</InputLabel>
                <Input
                  id="name"
                  name="name"
                  autoFocus
                  inputRef={step0.register({
                    required: validators.required.message("name"),
                  })}
                />
                <FormHelperText error={!!step0.errors.name}>
                  {step0.errors.name && step0.errors.name.message}
                </FormHelperText>
              </FormControl>
              <SecondaryButton
                style={{ width: "100%" }}
                type="submit"
                loading={loading}
              >
                Next
              </SecondaryButton>
            </Box>
          </form>
        );
      case 1:
        return (
          <Box width={spacing[300]}>
            {!loading && configUrl ? (
              <Grid container spacing={3} direction="row" alignItems="center">
                <Grid item>
                  <QRCode value={configUrl} />
                </Grid>
                <Grid item display="flex" alignItems="center">
                  <Typography variant="h5" display="inline">
                    Key:{" "}
                  </Typography>
                  <br />
                  <Typography display="inline">
                    {getParameterByName("secret", configUrl)}
                  </Typography>
                  <CopyToClipboardButton
                    defaultLabel="Copy to clipboard"
                    htmlColor="secondary"
                    hoverColor="secondary"
                    contentToCopy={
                      getParameterByName("secret", configUrl) as string
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              "Loading..."
            )}
            <SecondaryButton style={{ width: "100%" }} onClick={handleNext}>
              Next
            </SecondaryButton>
          </Box>
        );
      case 2:
        return (
          <form
            name="step2form"
            onSubmit={step2.handleSubmit(handleStep2Submit)}
          >
            <Box width={spacing[300]}>
              <FormControl
                margin="dense"
                fullWidth
                error={!!step2.errors.token}
              >
                <InputLabel htmlFor="token">Token</InputLabel>
                <Input
                  id="token"
                  name="token"
                  autoFocus
                  type="number"
                  inputRef={step2.register({
                    required: validators.required.message("token"),
                  })}
                />
                <FormHelperText error={!!step2.errors.token}>
                  {step2.errors.token && step2.errors.token.message}
                </FormHelperText>
              </FormControl>
              <SecondaryButton
                style={{ width: "100%" }}
                type="submit"
                loading={loading}
              >
                Next
              </SecondaryButton>
            </Box>
          </form>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <PageContainer>
      <PageHeader title="Create device" />
      <BreadcrumbsItem to={`${baseUrl}/security`}>Security</BreadcrumbsItem>
      <p>
        Please, make sure you have an authenticator app installed on your
        smartphone. You could use, for example, Google Authenticator or
        Microsoft Authenticator, which are free for Android, iOS and Windows 10
        Mobile. Specify a name for your device and open the authenticator app to
        perform the second and third configuration step.
      </p>
      <Stepper activeStep={activeStep} orientation="vertical">
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>{getStepContent(index)}</StepContent>
          </Step>
        ))}
      </Stepper>
    </PageContainer>
  );
};

export default DeviceCreate;

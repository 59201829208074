import type {
  AttachmentSourcesList,
  DeploymentOutputFieldCreateDataType,
  OutputValueListValue,
  PipelineVersionObjectList,
} from "libs/data/models";

export enum NodeTypes {
  "diamond" = "diamond",
  "deployment" = "deployment",
  "pipeline" = "pipeline",
  "pipeline_start" = "pipeline_start",
  "pipeline_end" = "pipeline_end",
  "operator" = "operator",
  "variable" = "variable",
}

export type NodeDataType = {
  isReadonly: boolean;
  type: NodeTypes;
  pipelineObject: PipelineVersionObjectList;
  organizationName: string;
  projectName: string;
  pipelineName: string;
  versionName: string;
  new?: boolean;
};

export type EdgeDataType = {
  new?: boolean;
  sources: AttachmentSourcesList[];
  organizationName: string;
  projectName: string;
  pipelineName: string;
  versionName: string;
};

export type NewVariableDataType = {
  field_value?: OutputValueListValue;
  file_value?: OutputValueListValue;
  name: string;
  type: { value: DeploymentOutputFieldCreateDataType };
};

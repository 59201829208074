import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import { gtag, TAG_ID } from ".";

export * from "libs/third-parties/google-analytics";

export const useGoogleAnalytics = () => {
  const match = useRouteMatch();

  useEffect(() => {
    gtag("config", TAG_ID, {
      page_path: match.path,
    });
  }, [match.path]);
};

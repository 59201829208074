import { Box } from "@mui/material";
import { forwardRef, useState } from "react";

import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import type { CSSProperties } from "react";

export type IconProps = {
  color?: "primary";
  component: OverridableComponent<SvgIconTypeMap>;
  fontSize?: "small";
  htmlColor?: string;
  hoverColor?: string;
  size?: number;
  style?: CSSProperties;
  className?: string;
};

export const MaterialIcon = forwardRef<SVGSVGElement | null, IconProps>(
  (
    {
      component: Icon,
      size,
      style = {},
      hoverColor = "inherit",
      htmlColor,
      ...props
    }: IconProps,
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const iconStyle = {
      ...style,
      color: isHovered ? hoverColor : htmlColor ? htmlColor : "inherit",
    };
    if (size) {
      style = {
        height: size,
        width: size,
        ...iconStyle,
      };
    }

    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        display="flex"
      >
        <Icon ref={ref} style={iconStyle} {...props} />
      </Box>
    );
  }
);

MaterialIcon.displayName = "MaterialIcon";

import { useEffect } from "react";

import { useSignOut } from "../../libs/hooks/useSignOut";

const SignOut = () => {
  const signOut = useSignOut();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <div></div>;
};

export default SignOut;

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ControlledFileUpload } from "components/molecules/ControlledInputs/ControlledFileUpload";
import { parseErrorFromControl } from "components/molecules/FormikInputs/utils";
import {
  FIELD_EXISTING_PACKAGE,
  FIELD_NEW_PACKAGE,
  FIELD_REQUIREMENTS_FILE,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";

import { FormSection } from "components/molecules";

import type { FormikProps, FormikValues } from "formik";
import type { EnvironmentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/types";
import type { ChangeEvent } from "react";

export interface CustomDependenciesSectionProps {
  control: FormikProps<FormikValues>;
  dependenciesRequired?: boolean;
}

export const CustomDependenciesSection = ({
  control,
  dependenciesRequired,
}: CustomDependenciesSectionProps) => {
  const { environmentName } = useParams<EnvironmentDetailsRouteParams>();
  const [selected, setSelected] = useState(
    dependenciesRequired ? FIELD_NEW_PACKAGE : FIELD_EXISTING_PACKAGE
  );

  const handleOnChange = async (file: File) => {
    await control.setFieldValue(FIELD_REQUIREMENTS_FILE, { file }, true);
    await control.setFieldTouched(FIELD_REQUIREMENTS_FILE, true);
  };

  const handleOnToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;

    setSelected(value);

    if (value === FIELD_EXISTING_PACKAGE) {
      await control.setFieldValue(FIELD_REQUIREMENTS_FILE, {
        source_environment: environmentName,
      });
    } else {
      await control.setFieldValue(FIELD_REQUIREMENTS_FILE, null, true);
      await control.setFieldTouched(FIELD_REQUIREMENTS_FILE, true);
    }
  };

  useEffect(() => {
    if (!dependenciesRequired) {
      control.setFieldValue(FIELD_REQUIREMENTS_FILE, {
        source_environment: environmentName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesRequired]);

  return (
    <FormSection
      title="Custom dependencies"
      description={explanations.environments.customDependencies}
    >
      <>
        {!dependenciesRequired && (
          <RadioGroup onChange={handleOnToggle} value={selected}>
            <FormControlLabel
              control={<Radio color="secondary" />}
              label="Use existing dependency files"
              value={FIELD_EXISTING_PACKAGE}
            />
            <FormControlLabel
              control={<Radio color="secondary" />}
              label="Upload new dependency files"
              value={FIELD_NEW_PACKAGE}
            />
          </RadioGroup>
        )}
        {selected === FIELD_NEW_PACKAGE && (
          <ControlledFileUpload
            error={parseErrorFromControl(control, FIELD_REQUIREMENTS_FILE)}
            onChange={handleOnChange}
            value={control.values[FIELD_REQUIREMENTS_FILE]?.file}
            allowedTypes={["zip", "txt", "R", "yaml"]}
          />
        )}
      </>
    </FormSection>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

const initialState: {
  permissions: {
    [k: string]: boolean;
  };
  loading: boolean;
} = {
  permissions: {},
  loading: true,
};

export const permissions = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (
      state,
      action: PayloadAction<{ [k: string]: boolean }>
    ) => {
      state.permissions = action.payload;
      state.loading = false;
    },
  },
});

export const useGetPermissions = () => {
  return useSelector(
    (store: RootState) =>
      [store.permissions.permissions, store.permissions.loading] as [
        permissions: typeof store.permissions.permissions,
        loading: boolean
      ]
  );
};

export const { setPermissions } = permissions.actions;

export default permissions.reducer;

import { Tooltip } from "@mui/material";

import { BaseButton } from "components/atoms/Button/BaseButton";

import type { BaseButtonProps } from "components/atoms/Button/BaseButton";

export interface BaseButtonWithTooltipProps extends BaseButtonProps {
  tooltip?: string | null | false;
}

export const BaseButtonWithTooltip = ({
  tooltip,
  ...props
}: BaseButtonWithTooltipProps) => {
  return tooltip ? (
    <Tooltip title={<>{tooltip}</>} style={props.style}>
      <span>
        <BaseButton
          {...props}
          sx={{
            "& svg": { color: "inherit" },
          }}
        />
      </span>
    </Tooltip>
  ) : (
    <BaseButton
      {...props}
      sx={{
        "& svg": { color: "inherit" },
      }}
    />
  );
};

import { useEffect, useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { useOrganizationUser } from "libs/data/customized/user";
import {
  useOrganizationSubscriptionsList,
  useOrganizationsCreditsGet,
  useOrganizationsFeaturesGet,
  useOrganizationsGet,
  useOrganizationsResourceUsage,
  useOrganizationsUsageGet,
} from "libs/data/endpoints/organizations/organizations";
import { configureSentryScope } from "libs/third-parties";
import { getComputeUsageDates } from "libs/utilities/metrics-helper";
import { routes } from "routes";
import {
  setOrganization,
  setOrganizationCreditResetDates,
  setOrganizationFeatures,
  setOrganizationResources,
  setOrganizationSubscription,
  setOrganizationUsage,
  useGetCurrentOrganization,
  useGetOrganizations,
} from "store/features";

import { Route, AdminRoute } from "components/utilities";

import OrganizationDashboard from "./dashboard/OrganizationDashboard";
import ProjectsOverview from "./overview/ProjectsOverview";
import ProjectsRoutesContainer from "./projects";
import { SubscriptionDetails } from "./subscription";
import OrganizationUsersOverview from "./team/OrganizationUsersOverview";
import OrganizationUsersCreate from "./team/create/OrganizationUserCreate";

const basePath = routes.organizations[":organizationName"](":organizationName");

const OrganizationRoutesContainer = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { organizationName } = useParams<{ organizationName: string }>();
  const { isAdmin } = useOrganizationUser(organizationName);

  const organizations = useGetOrganizations();
  const currentOrganization = useGetCurrentOrganization();

  const isCurrentOrgDifferrentFromUrl = useMemo(() => {
    return (
      !currentOrganization ||
      (currentOrganization.name !== organizationName &&
        !!organizations.find((org) => org.name === organizationName))
    );
  }, [currentOrganization, organizationName, organizations]);

  const { data: organization, error: organizationError } =
    useOrganizationsGet(organizationName);

  const { data: organizationFeatures } = useOrganizationsFeaturesGet(
    organizationName,
    { swr: { enabled: isCurrentOrgDifferrentFromUrl } }
  );
  const { data: organizationSubscription } = useOrganizationSubscriptionsList(
    organizationName,
    { swr: { enabled: isCurrentOrgDifferrentFromUrl && isAdmin } }
  );
  const { data: organizationUsage } = useOrganizationsUsageGet(
    currentOrganization?.name || organizationName,
    { interval: "day" },
    {
      swr: {
        dedupingInterval: 60 * 60,
      },
    }
  );

  const { data: creditUsageData } = useOrganizationsCreditsGet(
    currentOrganization?.name || organizationName
  );

  const { data: organizationResources } = useOrganizationsResourceUsage(
    currentOrganization?.name || organizationName
  );
  const isOrganizationLoading = !organization && !organizationError;

  useEffect(() => {
    if (currentOrganization?.id) {
      configureSentryScope({
        organization: currentOrganization?.id,
      });
    }
  }, [currentOrganization?.id]);
  useEffect(() => {
    if (!isOrganizationLoading) {
      organization && dispatch(setOrganization(organization));
      organizationFeatures &&
        dispatch(setOrganizationFeatures(organizationFeatures));
      organizationSubscription &&
        dispatch(setOrganizationSubscription(organizationSubscription));
      organizationResources &&
        dispatch(setOrganizationResources(organizationResources));
      organizationUsage && dispatch(setOrganizationUsage(organizationUsage));
      creditUsageData &&
        dispatch(
          setOrganizationCreditResetDates(getComputeUsageDates(creditUsageData))
        );
    }
  }, [
    dispatch,
    organization,
    isOrganizationLoading,
    organizationFeatures,
    organizationSubscription,
    organizationResources,
    organizationUsage,
    creditUsageData,
  ]);

  const baseUrl = routes.organizations[":organizationName"](
    organizationName ?? currentOrganization?.name
  );

  if (!currentOrganization) return null;

  return (
    <RootUrlContext.Provider value={match.url}>
      <BaseUrlContext.Provider value={match.url}>
        <BreadcrumbsItem to={match.url}>
          {currentOrganization?.name}
        </BreadcrumbsItem>
        <Switch>
          <Route
            path={basePath.projects.index()}
            component={ProjectsRoutesContainer}
          />
          <Route
            path={basePath.dashboard.index()}
            component={OrganizationDashboard}
          />
          <AdminRoute
            path={basePath.subscription.index()}
            component={SubscriptionDetails}
            organizationName={organizationName}
          />
          <AdminRoute
            exact
            path={basePath.team.index()}
            component={OrganizationUsersOverview}
            organizationName={organizationName}
          />
          <AdminRoute
            exact
            path={basePath.team.create.index()}
            component={OrganizationUsersCreate}
            organizationName={organizationName}
          />
          <AdminRoute
            exact
            path={basePath.overview.index()}
            component={ProjectsOverview}
            organizationName={organizationName}
          />

          <Redirect to={baseUrl.dashboard.index()} />
        </Switch>
      </BaseUrlContext.Provider>
    </RootUrlContext.Provider>
  );
};

export default OrganizationRoutesContainer;

import { useTheme } from "@mui/material";

export const OperatorNodeCollectSubRequests = () => {
  const theme = useTheme();

  return (
    <svg
      width="80"
      height="77"
      viewBox="0 0 80 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_26018)">
        <path
          d="M41.6422 76.219C62.4314 76.219 79.2844 59.366 79.2844 38.5768C79.2844 17.7876 62.4314 0.93457 41.6422 0.93457C20.853 0.93457 4 17.7876 4 38.5768C4 59.366 20.853 76.219 41.6422 76.219Z"
          fill={theme.palette.primary.main}
        />
        <path d="M21 31H48V47H21" stroke="white" strokeWidth="3" />
        <path d="M38 31V47" stroke="white" strokeWidth="3" />
        <path d="M29 31V46" stroke="white" strokeWidth="3" />
        <path
          d="M66.7778 39.7779C67.2074 39.3483 67.2074 38.6519 66.7778 38.2223L59.7775 31.2219C59.3479 30.7923 58.6514 30.7923 58.2218 31.2219C57.7922 31.6515 57.7922 32.348 58.2218 32.7776L64.4444 39.0001L58.2218 45.2226C57.7922 45.6522 57.7922 46.3487 58.2218 46.7783C58.6514 47.2079 59.3479 47.2079 59.7775 46.7783L66.7778 39.7779ZM53 40.1001H66V37.9001H53V40.1001Z"
          fill="white"
        />
        <path
          d="M5 43.5C7.48528 43.5 9.5 41.4853 9.5 39C9.5 36.5147 7.48528 34.5 5 34.5C2.51472 34.5 0.5 36.5147 0.5 39C0.5 41.4853 2.51472 43.5 5 43.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4316_26018">
          <rect width="85" height="77" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

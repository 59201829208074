import moment from "moment";

import type { Moment } from "moment";

export const DATE_FORMAT = "DD-MM-YYYY";
export const INVERSE_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const FULL_TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const FULL_DATE_TIME_FORMAT = `${DATE_FORMAT} ${FULL_TIME_FORMAT}`;
export const FULL_DATE_TIME_UTC_FORMAT = `${INVERSE_DATE_FORMAT}T${FULL_TIME_FORMAT}`;
export const DATE_TIME_TZ_FORMAT = `${DATE_TIME_FORMAT} zZ`;
export const DATE_TIME_HUMAN_READABLE = `${DATE_FORMAT} [at] ${TIME_FORMAT}`;
/**
 * @param date anything accepted by moment() (like Dates, timestamps, strings), or empty to get the current datetime
 * @returns a timezone aware moment date
 */

export type MomentDateType = moment.Moment;
export type DateType = string | Date | number;

export const getTzAwareDate = (
  date?: DateType | MomentDateType | string | null
) => (date ? moment(date) : moment());

export const getFormattedDate = (date?: DateType | Moment) =>
  getTzAwareDate(date).toISOString();

export const getTimeFromNow = (date: DateType | MomentDateType | string) => {
  const now = getTzAwareDate(new Date());
  const tzAwareDate = getTzAwareDate(date);
  const diff = now.diff(tzAwareDate, "hours");

  return diff <= 1
    ? getTzAwareDate(date).fromNow()
    : getTzAwareDate(date).format(DATE_TIME_FORMAT);
};

export const timeWithinLastMinute = (date: DateType) => {
  return moment().diff(moment(date), "seconds");
};

export const isDatePassed = (date: DateType) =>
  getTzAwareDate().isAfter(getTzAwareDate(date));

export const renderTimestamp = (
  rowData: Record<string, DateType>,
  fieldName: string
) => {
  if (!rowData?.[fieldName]) {
    return "-";
  }

  return getTzAwareDate(rowData[fieldName]).format(FULL_DATE_TIME_FORMAT);
};

export const calculateDuration = (
  startTime: string | DateType,
  endTime: string | DateType
) => {
  const milliseconds = moment(endTime).diff(moment(startTime));

  let seconds = milliseconds / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds =
    seconds % 60 > 59 ? Math.floor(seconds % 60) : Math.ceil(seconds % 60);

  return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
};

const addZero = (number: number) => {
  return number < 0 ? "00" : number < 10 ? "0" + number : number;
};

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { requestResetPassword } from "libs/data/endpoints/user/user";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const resetPassword = async (email: string) => {
    setIsLoading(true);
    try {
      await requestResetPassword({ email });
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError)?.message));
    } finally {
      setIsLoading(false);
      history.push("/sign-in");
      dispatch(
        createSuccessNotification(
          "If your password reset succeeded, you will receive a password recovery link at your email address in a few minutes."
        )
      );
    }
  };

  return { resetPassword, isLoading };
};

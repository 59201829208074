import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { serviceUsersToken } from "libs/data/endpoints/service-users/service-users";
import { createErrorNotification } from "libs/utilities/notifications";

export const useServiceUserReset = (projectName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (id: string) => {
      if (!projectName) {
        return Promise.reject();
      }

      return serviceUsersToken(projectName, id, {})
        .then(async (result) => {
          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, dispatch]
  );
};

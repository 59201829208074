import { Typography } from "@mui/material";

import type { TypographyProps } from "@mui/material";

/**
 * We use the SubHeading Typography component for third-level titles. They are typically bold, and used as a header
 * for a minor section. They are not to be confused with the Typography used for page tabs (Header), labels within
 * a card (KeyLabel) or bold text used as a paragraph (BoldText).
 */
export const SubHeader = ({ style = {}, ...props }: TypographyProps) => {
  return (
    <Typography
      style={{ fontSize: 14, fontWeight: "700", lineHeight: "19px", ...style }}
      variant="h3"
      {...props}
    />
  );
};

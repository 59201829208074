import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { PipelineVersion } from "./:versionName";
import { PipelineVersionCreate } from "./PipelineVersionCreate";
import PipelineVersionDefault from "./PipelineVersionDefault";

import type { PipelineRouteParams } from "./types";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .pipelines[":pipelineName"](":pipelineName");

export const PipelineVersionsPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, pipelineName } =
    useParams<PipelineRouteParams>();

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .pipelines[":pipelineName"](pipelineName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <Switch>
        <Route
          path={basePath.versions.index()}
          exact
          component={PipelineVersionDefault}
        />
        <Route
          path={basePath.versions.create.index()}
          component={PipelineVersionCreate}
        />
        <Route
          path={basePath.versions[":versionName"](":versionName").index()}
          component={PipelineVersion}
        />
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { AUDIT_LOGS_LIMIT } from "libs/constants/constants";

import type {
  DeploymentAuditEventsListAction,
  DeploymentAuditEventsListParams,
} from "libs/data/models";

export const parseAuditParams = (
  offset: number,
  action?: DeploymentAuditEventsListAction
) => {
  const baseRequest: DeploymentAuditEventsListParams = {
    limit: AUDIT_LOGS_LIMIT + 1,
    offset,
  };

  if (action) {
    baseRequest.action = action;
  }

  return baseRequest;
};

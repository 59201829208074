import { Typography } from "@mui/material";

import { Dialog, PrimaryButton, SecondaryButton } from "components/atoms";

export type PageHeaderExitDialogProps = {
  isTouched: boolean;
  isOpen: boolean;
  onClose: () => void;
  onExit?: () => void;
};

export const PageHeaderExitDialog = ({
  isTouched = false,
  isOpen = false,
  onClose,
  onExit,
}: PageHeaderExitDialogProps) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    title="Are you sure you want to exit edit mode?"
    Actions={
      <>
        <SecondaryButton onClick={onClose} style={{ marginRight: 14 }}>
          Continue editing
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            onClose();
            onExit?.();
          }}
        >
          {isTouched ? "Exit without save" : "Exit"}
        </PrimaryButton>
      </>
    }
  >
    {isTouched && (
      <Typography variant="body1">
        You have unsaved changes, if you exit now those changes will be
        discarded.
      </Typography>
    )}
  </Dialog>
);

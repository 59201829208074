import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  bucketsUpdate,
  useBucketsGet,
  useBucketsList,
} from "libs/data/endpoints/files/files";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { BucketUpdate } from "libs/data/models";

export const useBucketUpdate = (projectName: string, bucketName: string) => {
  const { mutate } = useBucketsList(projectName);
  const { mutate: mutateDetails } = useBucketsGet(projectName, bucketName);
  const dispatch = useDispatch();

  return useCallback(
    async (data: BucketUpdate) => {
      try {
        const result = await bucketsUpdate(projectName, bucketName, data);
        await mutate();
        await mutateDetails(() => result, { revalidate: false });
        dispatch(createSuccessNotification(`The bucket was updated`));

        return result;
      } catch (err: unknown) {
        const axiosError = err as AxiosError;
        dispatch(
          createErrorNotification(axiosError?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, bucketName, mutate, mutateDetails, projectName]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { userUpdate } from "libs/data/endpoints/user/user";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { UserUpdate } from "libs/data/models";

export const useUserUpdate = (message?: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (data: UserUpdate) => {
      try {
        await userUpdate(data);
        dispatch(
          createSuccessNotification(message ?? "User details were updated")
        );
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, message]
  );
};

import { Tabs as MaterialTabs, useTheme } from "@mui/material";

import type { TabsProps as MaterialTabProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

export interface TabsProps
  extends Pick<MaterialTabProps, "children" | "value" | "variant"> {
  onChange: (value: any) => void;
}

export const Tabs = ({ onChange, ...props }: TabsProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <MaterialTabs
      indicatorColor="secondary"
      scrollButtons="auto"
      onChange={(_, value) => {
        onChange(value);
      }}
      sx={{
        "& button": {
          color: theme.palette.pipelineDiagram.tab,
        },
        "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
          "& h3": {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...props}
    />
  );
};

import { Typography } from "@mui/material";

import type { TypographyProps } from "@mui/material";

/**
 * We use the PageTitle Typography component for top-level titles, indicate the primary purpose of the page. They are
 * not to be confused with the Typography used for page tabs (Header) or any other smaller typography.
 */
export const PageTitle = ({ ...props }: TypographyProps) => {
  return <Typography variant="h1" {...props} />;
};

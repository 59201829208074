import { useEffect, useMemo, useState } from "react";

import type { BaseColumn } from "./types";

export type SortableRow = { [key: string]: string };

type SortedRowsProps = {
  columnName: string;
  rows?: SortableRow[];
  defaultSortDirection?: string;
  columns?: BaseColumn[];
};

export const useSortedRowsByColumnName = ({
  rows = [],
  columnName,
  defaultSortDirection = "desc",
  columns,
}: SortedRowsProps) => {
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [sortColumn, setSortColumn] = useState(columnName);

  useEffect(() => {
    setSortDirection(defaultSortDirection);
  }, [defaultSortDirection]);

  const setSorting = (columnName: string) => {
    setSortColumn(columnName);
    const nextDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(nextDirection);
  };

  const sortedRows = useMemo(() => {
    const numericDirection = sortDirection === "desc" ? 1 : -1;
    const currentColumn = columns?.find(({ field }) => field === sortColumn);
    if (currentColumn?.type === "datetime") {
      return rows.sort(
        (a, b) =>
          numericDirection *
          (Date.parse(a[sortColumn]) - Date.parse(b[sortColumn]))
      );
    }
    if (typeof rows?.[0]?.[sortColumn] === "number") {
      return rows.sort(
        (a, b) =>
          numericDirection * (Number(a[sortColumn]) - Number(b[sortColumn]))
      );
    }
    if (sortColumn) {
      return rows.sort(
        (a, b) =>
          numericDirection *
          JSON.stringify(a[sortColumn])?.localeCompare(
            JSON.stringify(b[sortColumn])
          )
      );
    }

    return rows;
  }, [sortDirection, columns, sortColumn, rows]);

  return {
    sortedRows,
    sortDirection: sortDirection as "desc" | "asc",
    setSorting,
    columnName: sortColumn,
  };
};

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type MetricCreateMetricType =
  typeof MetricCreateMetricType[keyof typeof MetricCreateMetricType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricCreateMetricType = {
  gauge: "gauge",
  delta: "delta",
} as const;

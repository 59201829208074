import { useCallback } from "react";
import { addEdge, useReactFlow } from "reactflow";

import {
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "./constants";
import { getId } from "./getId";
import { NodeTypes } from "./types";
import { useDeleteNode } from "./useDeleteNode";

import type { EdgeDataType } from "./types";
import type { Edge } from "reactflow";

export const useDeleteEdge = () => {
  const { setNodes, setEdges, getEdge, getEdges, getNode } = useReactFlow();
  const deleteNode = useDeleteNode();

  const deleteEdge = useCallback(
    (id: string) => {
      const edge = getEdge(id);

      if (edge) {
        const sourceNode = getNode(edge.source);
        const targetNode = getNode(edge.target);
        const edges = getEdges();
        const isSourceDiamond = sourceNode?.type === NodeTypes.diamond;

        const isOneToManyOperator =
          targetNode?.data.pipelineObject.reference_type ===
            OBJECT_REFERENCE_TYPE_OPERATOR &&
          targetNode?.data.pipelineObject.reference_name ===
            OPERATOR_ONE_TO_MANY;

        const isManyToOneOperator =
          sourceNode?.data.pipelineObject.reference_type ===
            OBJECT_REFERENCE_TYPE_OPERATOR &&
          sourceNode?.data.pipelineObject.reference_name ===
            OPERATOR_MANY_TO_ONE;

        if (isSourceDiamond) {
          setNodes((nodes) => {
            let newNodes = nodes.filter((node) => node.id !== sourceNode?.id);

            const cleanupUnneededDiamonds = () => {
              const diamonds = newNodes.filter(
                (node) => node.type === NodeTypes.diamond
              );

              diamonds.forEach((diamond) => {
                const {
                  organizationName,
                  projectName,
                  pipelineName,
                  versionName,
                } = diamond.data;

                const incomerEdges = edges.filter(
                  (edge) => edge.target === diamond.id
                );
                const outgoingEdge = edges.find(
                  (edge) => edge.source === diamond.id
                );

                if (incomerEdges.length === 1 && outgoingEdge) {
                  const newEdge: Edge<EdgeDataType> = {
                    id: getId(),
                    source: incomerEdges[0].source,
                    target: outgoingEdge?.target,
                    type: "custom",
                    data: {
                      sources: outgoingEdge?.data?.sources || [],
                      organizationName,
                      projectName,
                      pipelineName,
                      versionName,
                    },
                  };

                  setEdges((edges) => addEdge(newEdge, edges));

                  newNodes = nodes.filter((node) => node.id !== diamond.id);
                }
              });
            };
            cleanupUnneededDiamonds();

            return newNodes;
          });
        } else if (isOneToManyOperator) {
          deleteNode(targetNode?.id as string);
        } else if (isManyToOneOperator) {
          deleteNode(sourceNode?.id as string);
        } else {
          setEdges((edges) => edges.filter((edge) => edge.id !== id));
        }
      }
    },
    [getEdge, getEdges, getNode, setEdges, setNodes, deleteNode]
  );

  return deleteEdge;
};

import { deploymentMetricsGraphIds } from "libs/data/customized/deployment-metrics";
import { pipelineMetricsGraphIds } from "libs/data/customized/pipeline/constants";

export const graphs = [
  {
    title: "Deployment request rate",
    metric: [deploymentMetricsGraphIds.requests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Deployment failed request rate",
    metric: [deploymentMetricsGraphIds.failedRequests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Pipeline request rate",
    metric: [pipelineMetricsGraphIds.requests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Pipeline failed request rate",
    metric: [pipelineMetricsGraphIds.failedRequests],
    isDynamicUnitPeriod: true,
  },
];

import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { pipelineVersionsValidate } from "libs/data/endpoints/pipelines/pipelines";
import { createErrorNotification } from "libs/utilities/notifications";

import type {
  PipelineVersionValidate,
  PipelineVersionValidateResponse,
} from "libs/data/models";

export const usePipelineValidate = (
  projectName: string,
  pipelineName: string,
  versionName: string,
  isDisabled = false
) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<PipelineVersionValidateResponse>({
    errors: [],
    warnings: [],
  });
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const validatePipeline = useCallback(
    (data: PipelineVersionValidate, canBeCancelled = true) => {
      return new Promise<PipelineVersionValidateResponse>((resolve) => {
        if (isDisabled) {
          resolve({ errors: [], warnings: [] });

          return;
        }

        if (timeout.current && canBeCancelled) {
          // Without clearTimeout duplicate validation calls are frequently made
          // This cancels a previous request if a new one is made very shortly afterwards
          clearTimeout(timeout.current);
        }

        const newTimeout = setTimeout(() => {
          pipelineVersionsValidate(projectName, pipelineName, versionName, data)
            .then(({ errors, warnings }) => {
              setStatus({ errors, warnings });

              resolve({ errors, warnings });
            })
            .catch((e) => {
              dispatch(createErrorNotification(e?.message));
            });
        }, 500);

        // Specifically the Save call we don't want cancelled afterwards, which is why this option was added
        if (canBeCancelled) {
          timeout.current = newTimeout;
        }
      });
    },
    [dispatch, isDisabled, pipelineName, projectName, versionName]
  );

  return [validatePipeline, status] as [typeof validatePipeline, typeof status];
};

import styled from "@emotion/styled";
import LaunchIcon from "@mui/icons-material/Launch";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import Deployments from "@mui/icons-material/WidgetsRounded";
import {
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useContext, useMemo, useState, useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm } from "react-hook-form";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";

import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import {
  useDeploymentsGet,
  useDeploymentVersionsGet,
} from "libs/data/endpoints/deployments/deployments";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";
import {
  pythonRequestCodeGenerator,
  rRequestCodeGenerator,
  javascriptRequestCodeGenerator,
  pythonClientlibraryRequestCodeGenerator,
} from "libs/utilities/code-snippets-generator";
import { formatInputOutputFields } from "libs/utilities/input-parser";

import {
  Card,
  InfoTooltip,
  Loader,
  Link,
  CardAccordion,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";
import { MultiCodeBlock } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { InputOutputField } from "libs/utilities/code-snippets-generator";
import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

const BatchModeToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 2rem;
`;

export const DeploymentUsing = () => {
  useGoogleAnalytics();
  const theme = useTheme() as AppThemeProps;

  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const { data: deployment, error: deploymentError } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const { data: defaultVersion, error: versionError } =
    useDeploymentVersionsGet(
      projectName,
      deploymentName,
      deployment?.default_version as string,
      { swr: { enabled: !!deployment?.default_version } }
    );

  const methods = useForm({
    mode: "onChange",
  });
  const history = useHistory();

  const inputFields = useMemo(
    () => formatInputOutputFields(deployment?.input_fields ?? []),
    [deployment?.input_fields]
  );
  const match = useRouteMatch();

  const baseUrl = useContext(BaseUrlContext);
  const rootUrl = useContext(RootUrlContext);

  const blobsUrl = `${env.get(ENV_NAMES.HOST)}/projects/${projectName}/blobs`;
  const configureUrl = `${baseUrl}/use-deployment/configure`;

  const baseEndpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/deployments/${deploymentName}`;

  const endpointUrl = `${baseEndpointUrl}/requests`;

  const simpleRequestUrl = `${env.get(
    ENV_NAMES.REQUESTS_PAGE_URL
  )}/projects/${projectName}/deployments/${deploymentName}`;

  const [isLoading, setIsLoading] = useState(true);
  const [isBatch, setIsBatch] = useState(false);

  const isPlain = deployment?.input_type === "plain";

  useEffect(() => {
    if ((deployment || deploymentError) && (defaultVersion || versionError)) {
      setIsLoading(false);
    }
  }, [deployment, defaultVersion, deploymentError, versionError]);

  const codeBlocks = useMemo(
    () => [
      {
        title: "Python (client library)",
        language: "python",
        codeBlock: pythonClientlibraryRequestCodeGenerator({
          inputFields: inputFields as InputOutputField[],
          projectName,
          objectType: "deployment",
          objectName: deploymentName,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "Python",
        language: "python",
        codeBlock: pythonRequestCodeGenerator({
          inputFields: inputFields as InputOutputField[],
          endpointUrl,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "R",
        language: "r",
        codeBlock: rRequestCodeGenerator({
          inputFields: inputFields as InputOutputField[],
          endpointUrl,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "JavaScript",
        language: "javascript",
        codeBlock: javascriptRequestCodeGenerator({
          inputFields: inputFields as InputOutputField[],
          endpointUrl,
          blobsUrl,
          isBatch,
          isPlain,
        }),
      },
    ],
    [
      inputFields,
      endpointUrl,
      projectName,
      deploymentName,
      blobsUrl,
      isBatch,
      isPlain,
    ]
  );

  const handleTypeChange = (_: unknown, value: boolean) => {
    if (isBatch !== value) {
      setIsBatch(value);
    }
  };

  return (
    <FormProvider {...methods}>
      <form style={{ width: "100%" }}>
        <BreadcrumbsItem to={match.url}>Use deployment</BreadcrumbsItem>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            {env.get(ENV_NAMES.REQUESTS_PAGE_ENABLED) && (
              <Grid item xs={12}>
                <Card
                  title="Deployment interface"
                  icon={Deployments}
                  iconColor="primary"
                >
                  <Grid container spacing={4}>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <div>
                          <PrimaryButton
                            startIcon={<SettingsIcon fontSize="small" />}
                            onClick={() => history.push(configureUrl)}
                          >
                            Configure deployment interface
                          </PrimaryButton>
                        </div>
                        <InfoTooltip>
                          Configure the input and output widgets shown on the
                          deployment interface.
                        </InfoTooltip>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <div>
                          <SecondaryButton
                            startIcon={<LaunchIcon fontSize="small" />}
                            href={simpleRequestUrl}
                            target="_blank"
                          >
                            Go to deployment interface
                          </SecondaryButton>
                        </div>
                        <InfoTooltip>
                          An interface for your deployment or pipeline which is
                          accessible with an API token.
                        </InfoTooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        You can use our automatically generated interface for
                        your deployment or pipeline. For authentication, you
                        will need{" "}
                        <Link to={`${rootUrl}/settings/api-tokens/create`}>
                          an API token
                        </Link>
                        .
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={2}
                  >
                    <CardAccordion
                      icon={<PlayArrowIcon />}
                      summary="Make requests to this deployment from your code"
                      details={
                        <Typography variant="body1">
                          You can use the code snippets below for making a
                          request to your deployment. You will need{" "}
                          <Link to={`${rootUrl}/settings/api-tokens/create`}>
                            to create an API token
                          </Link>{" "}
                          and paste the token in the indicated spot for them to
                          work. The token will need at least{" "}
                          <code>deployment-request-user</code> rights.
                        </Typography>
                      }
                    />
                    <Box>
                      <BatchModeToggleButtonGroup
                        value={isBatch}
                        exclusive
                        onChange={handleTypeChange}
                      >
                        <ToggleButton
                          disableFocusRipple={true}
                          value={false}
                          sx={{ color: theme.palette.text.primary }}
                        >
                          Express
                        </ToggleButton>
                        <ToggleButton
                          disableFocusRipple={true}
                          value={true}
                          sx={{ color: theme.palette.text.primary }}
                        >
                          Batch
                        </ToggleButton>
                      </BatchModeToggleButtonGroup>
                    </Box>
                  </Box>
                  <Grid item xs={12}>
                    {/* @ts-ignore */}
                    <MultiCodeBlock codeBlocks={codeBlocks} />
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};

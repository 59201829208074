import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";

import type { ReactNode } from "react";

type FormPageWrapperProps = {
  children: ReactNode;
  name: string;
  baseUrl: string;
};
export const FormPageWrapper = ({
  children,
  name,
  baseUrl,
}: FormPageWrapperProps) => {
  const match = useRouteMatch();

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={baseUrl}>{name}</BreadcrumbsItem>
      {children}
    </BaseUrlContext.Provider>
  );
};

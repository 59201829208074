import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const IlluEmptyCustomMetrics = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="350"
      height="247"
      viewBox="0 0 350 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10263_18877)">
        <path
          d="M285.871 154.905H60.4505C58.9117 154.903 57.4365 154.287 56.3484 153.192C55.2603 152.097 54.6482 150.612 54.6465 149.064V9.16734C54.6477 8.03273 55.0961 6.94494 55.8933 6.14263C56.6904 5.34032 57.7712 4.889 58.8985 4.8877H287.349C288.496 4.88901 289.596 5.34812 290.407 6.1643C291.218 6.98048 291.674 8.08709 291.675 9.24133V149.064C291.673 150.612 291.061 152.097 289.973 153.192C288.885 154.287 287.41 154.903 285.871 154.905Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M277.648 146.633H67.5616C64.482 146.633 61.9766 144.271 61.9766 141.369V19.5454C61.9766 17.3929 63.8331 15.6416 66.1152 15.6416H279.026C281.345 15.6416 283.233 17.4218 283.233 19.6097V141.369C283.233 144.271 280.727 146.633 277.648 146.633Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M291.594 8.83312H54.5645V5.85913C54.5667 4.30525 55.1812 2.81571 56.2733 1.7173C57.3653 0.618878 58.8457 0.00128376 60.3896 0H285.769C287.312 0.00127628 288.793 0.618869 289.885 1.71729C290.977 2.81571 291.592 4.30525 291.594 5.85913V8.83312Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M65.1513 5.9421C65.9834 5.9421 66.658 5.26316 66.658 4.42564C66.658 3.58812 65.9834 2.90918 65.1513 2.90918C64.3191 2.90918 63.6445 3.58812 63.6445 4.42564C63.6445 5.26316 64.3191 5.9421 65.1513 5.9421Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M70.87 5.9421C71.7022 5.9421 72.3767 5.26316 72.3767 4.42564C72.3767 3.58812 71.7022 2.90918 70.87 2.90918C70.0379 2.90918 69.3633 3.58812 69.3633 4.42564C69.3633 5.26316 70.0379 5.9421 70.87 5.9421Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M76.5888 5.9421C77.4209 5.9421 78.0955 5.26316 78.0955 4.42564C78.0955 3.58812 77.4209 2.90918 76.5888 2.90918C75.7566 2.90918 75.082 3.58812 75.082 4.42564C75.082 5.26316 75.7566 5.9421 76.5888 5.9421Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M254.524 126.201H91.7163C90.0843 126.199 88.5196 125.545 87.3656 124.384C86.2116 123.222 85.5624 121.648 85.5605 120.005V41.0018C85.5619 39.7734 86.0474 38.5956 86.9105 37.7269C87.7735 36.8582 88.9437 36.3696 90.1643 36.3682H256.002C257.242 36.3696 258.431 36.866 259.308 37.7486C260.185 38.6311 260.678 39.8277 260.68 41.0758V120.005C260.678 121.648 260.029 123.222 258.875 124.384C257.721 125.545 256.156 126.199 254.524 126.201ZM90.1643 37.0762C89.1303 37.0774 88.1389 37.4914 87.4077 38.2273C86.6765 38.9633 86.2652 39.9611 86.2641 41.0018V120.005C86.2657 121.46 86.8406 122.855 87.8628 123.883C88.8849 124.912 90.2708 125.491 91.7163 125.492H254.524C255.969 125.491 257.355 124.912 258.377 123.883C259.4 122.855 259.974 121.46 259.976 120.005V41.0758C259.975 40.0154 259.556 38.9988 258.811 38.249C258.066 37.4992 257.056 37.0774 256.002 37.0762H90.1643Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M97.343 120.005H248.894C250.387 120.005 251.818 119.409 252.874 118.346C253.929 117.284 254.522 115.843 254.522 114.341V48.2973C254.522 46.795 253.929 45.3542 252.874 44.2919C251.818 43.2296 250.387 42.6328 248.894 42.6328H97.343C95.8503 42.6328 94.4188 43.2296 93.3633 44.2919C92.3078 45.3542 91.7148 46.795 91.7148 48.2973V114.341C91.7148 115.843 92.3078 117.284 93.3633 118.346C94.4188 119.409 95.8503 120.005 97.343 120.005Z"
          fill={theme.palette.svgs.moonWhite}
        />
        <path
          d="M114.551 61.8471C116.299 61.8471 117.716 60.4206 117.716 58.6609C117.716 56.9012 116.299 55.4746 114.551 55.4746C112.802 55.4746 111.385 56.9012 111.385 58.6609C111.385 60.4206 112.802 61.8471 114.551 61.8471Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M114.551 73.8843C116.299 73.8843 117.716 72.4577 117.716 70.698C117.716 68.9383 116.299 67.5117 114.551 67.5117C112.802 67.5117 111.385 68.9383 111.385 70.698C111.385 72.4577 112.802 73.8843 114.551 73.8843Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M114.551 85.9214C116.299 85.9214 117.716 84.4948 117.716 82.7351C117.716 80.9754 116.299 79.5488 114.551 79.5488C112.802 79.5488 111.385 80.9754 111.385 82.7351C111.385 84.4948 112.802 85.9214 114.551 85.9214Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M114.551 97.9585C116.299 97.9585 117.716 96.5319 117.716 94.7722C117.716 93.0125 116.299 91.5859 114.551 91.5859C112.802 91.5859 111.385 93.0125 111.385 94.7722C111.385 96.5319 112.802 97.9585 114.551 97.9585Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M234.502 59.0147H130.381C130.288 59.0147 130.198 58.9774 130.132 58.911C130.066 58.8446 130.029 58.7546 130.029 58.6607C130.029 58.5668 130.066 58.4767 130.132 58.4103C130.198 58.3439 130.288 58.3066 130.381 58.3066H234.502C234.595 58.3066 234.684 58.3439 234.75 58.4103C234.816 58.4767 234.853 58.5668 234.853 58.6607C234.853 58.7546 234.816 58.8446 234.75 58.911C234.684 58.9774 234.595 59.0147 234.502 59.0147Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M234.502 71.0518H130.381C130.288 71.0518 130.198 71.0145 130.132 70.9481C130.066 70.8817 130.029 70.7917 130.029 70.6978C130.029 70.6039 130.066 70.5138 130.132 70.4474C130.198 70.3811 130.288 70.3438 130.381 70.3438H234.502C234.595 70.3438 234.684 70.3811 234.75 70.4474C234.816 70.5138 234.853 70.6039 234.853 70.6978C234.853 70.7917 234.816 70.8817 234.75 70.9481C234.684 71.0145 234.595 71.0518 234.502 71.0518Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M234.502 83.0889H130.381C130.288 83.0889 130.198 83.0516 130.132 82.9852C130.066 82.9188 130.029 82.8288 130.029 82.7349C130.029 82.641 130.066 82.551 130.132 82.4846C130.198 82.4182 130.288 82.3809 130.381 82.3809H234.502C234.595 82.3809 234.684 82.4182 234.75 82.4846C234.816 82.551 234.853 82.641 234.853 82.7349C234.853 82.8288 234.816 82.9188 234.75 82.9852C234.684 83.0516 234.595 83.0889 234.502 83.0889Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M234.502 95.126H130.381C130.288 95.126 130.198 95.0887 130.132 95.0223C130.066 94.9559 130.029 94.8659 130.029 94.772C130.029 94.6781 130.066 94.5881 130.132 94.5217C130.198 94.4553 130.288 94.418 130.381 94.418H234.502C234.595 94.418 234.684 94.4553 234.75 94.5217C234.816 94.5881 234.853 94.6781 234.853 94.772C234.853 94.8659 234.816 94.9559 234.75 95.0223C234.684 95.0887 234.595 95.126 234.502 95.126Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M150.079 107.164H139.878C139.412 107.163 138.965 106.976 138.635 106.644C138.305 106.313 138.12 105.863 138.119 105.393V95.8346C138.12 95.3653 138.305 94.9154 138.635 94.5835C138.965 94.2517 139.412 94.065 139.878 94.0645H150.079C150.545 94.065 150.992 94.2517 151.322 94.5835C151.652 94.9154 151.837 95.3653 151.838 95.8346V105.393C151.837 105.863 151.652 106.313 151.322 106.644C150.992 106.976 150.545 107.163 150.079 107.164Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M172.944 107.163H162.743C162.277 107.163 161.83 106.976 161.5 106.644C161.17 106.313 160.985 105.863 160.984 105.393V83.7975C160.985 83.3282 161.17 82.8783 161.5 82.5464C161.83 82.2146 162.277 82.0279 162.743 82.0273H172.944C173.41 82.0279 173.858 82.2146 174.187 82.5464C174.517 82.8783 174.702 83.3282 174.703 83.7975V105.393C174.702 105.863 174.517 106.313 174.187 106.644C173.858 106.976 173.41 107.163 172.944 107.163Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M196.159 107.163H185.958C185.492 107.163 185.045 106.976 184.715 106.644C184.385 106.312 184.2 105.863 184.199 105.393V71.7604C184.2 71.2911 184.385 70.8411 184.715 70.5093C185.045 70.1774 185.492 69.9908 185.958 69.9902H196.159C196.625 69.9908 197.072 70.1774 197.402 70.5093C197.732 70.8411 197.917 71.2911 197.918 71.7604V105.393C197.917 105.863 197.732 106.312 197.402 106.644C197.072 106.976 196.625 107.163 196.159 107.163Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M220.079 107.163H209.878C209.412 107.162 208.965 106.976 208.635 106.644C208.305 106.312 208.12 105.862 208.119 105.393V60.0768C208.12 59.6075 208.305 59.1576 208.635 58.8257C208.965 58.4939 209.412 58.3072 209.878 58.3066H220.079C220.545 58.3072 220.992 58.4939 221.322 58.8257C221.652 59.1576 221.837 59.6075 221.838 60.0768V105.393C221.837 105.862 221.652 106.312 221.322 106.644C220.992 106.976 220.545 107.162 220.079 107.163Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M234.502 107.163H130.381C130.288 107.163 130.198 107.126 130.132 107.059C130.066 106.993 130.029 106.903 130.029 106.809C130.029 106.715 130.066 106.625 130.132 106.559C130.198 106.492 130.288 106.455 130.381 106.455H234.502C234.595 106.455 234.684 106.492 234.75 106.559C234.816 106.625 234.853 106.715 234.853 106.809C234.853 106.903 234.816 106.993 234.75 107.059C234.684 107.126 234.595 107.163 234.502 107.163Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M280.246 173.206C280.531 172.772 280.908 172.406 281.348 172.134C281.789 171.862 282.284 171.69 282.798 171.632C283.312 171.573 283.832 171.628 284.322 171.793C284.812 171.959 285.261 172.231 285.635 172.589L296.806 166.787L296.111 173.324L285.61 177.832C284.97 178.425 284.135 178.762 283.265 178.777C282.394 178.793 281.548 178.487 280.887 177.917C280.226 177.347 279.795 176.552 279.677 175.684C279.559 174.816 279.762 173.934 280.246 173.206Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M289.858 175.645C289.604 175.444 289.417 175.171 289.322 174.862L288.433 171.973C288.338 171.66 288.34 171.325 288.44 171.013C288.54 170.702 288.732 170.429 288.991 170.231L298.045 163.348L307.85 145.04C308.261 144.508 308.772 144.062 309.354 143.729C309.935 143.395 310.577 143.18 311.241 143.096C311.906 143.012 312.58 143.06 313.226 143.238C313.872 143.416 314.476 143.721 315.005 144.134C315.534 144.548 315.977 145.062 316.309 145.647C316.64 146.233 316.854 146.879 316.937 147.547C317.021 148.216 316.973 148.895 316.796 149.545C316.619 150.195 316.316 150.803 315.905 151.336L310.327 163.185C308.634 166.782 305.702 169.637 302.074 171.22L291.464 175.852C291.26 175.941 291.039 175.986 290.817 175.983C290.595 175.981 290.375 175.932 290.173 175.838C290.061 175.786 289.955 175.721 289.858 175.645Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M316.151 137.399C320.923 137.399 324.791 133.506 324.791 128.704C324.791 123.902 320.923 120.009 316.151 120.009C311.38 120.009 307.512 123.902 307.512 128.704C307.512 133.506 311.38 137.399 316.151 137.399Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M312.647 242.771L308.334 242.771L306.283 226.029L312.648 226.03L312.647 242.771Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M305.254 241.531H313.571V246.802H300.018C300.018 246.11 300.153 245.424 300.416 244.785C300.679 244.145 301.065 243.564 301.551 243.075C302.038 242.586 302.615 242.197 303.25 241.932C303.886 241.668 304.566 241.531 305.254 241.531Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M326.717 242.772L322.405 242.772L320.354 226.03L326.719 226.031L326.717 242.772Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M319.324 241.531H327.641V246.802H314.088C314.088 246.11 314.223 245.424 314.486 244.785C314.75 244.145 315.135 243.564 315.622 243.075C316.108 242.586 316.685 242.197 317.321 241.932C317.956 241.668 318.637 241.531 319.324 241.531Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M327.032 238.524L321.979 238.524C321.631 238.523 321.293 238.408 321.017 238.195C320.74 237.982 320.541 237.683 320.451 237.345L313.176 210.191C313.165 210.148 313.138 210.112 313.102 210.088C313.065 210.064 313.021 210.055 312.978 210.062C312.935 210.069 312.896 210.092 312.869 210.126C312.842 210.161 312.828 210.204 312.831 210.247L314.3 236C314.313 236.228 314.278 236.456 314.196 236.669C314.114 236.881 313.987 237.074 313.825 237.233C313.662 237.392 313.468 237.514 313.255 237.591C313.042 237.668 312.814 237.698 312.588 237.679L306.908 237.203C306.544 237.171 306.202 237.014 305.94 236.759C305.677 236.503 305.511 236.164 305.468 235.799L300.293 181.723L302.295 182.932L321.054 179.727L328.602 236.721C328.631 236.946 328.613 237.174 328.548 237.392C328.483 237.609 328.372 237.81 328.223 237.98C328.075 238.151 327.892 238.288 327.686 238.382C327.481 238.476 327.258 238.524 327.032 238.524Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M306.568 187.43C304.498 187.414 302.471 186.839 300.697 185.767C300.423 185.604 300.205 185.362 300.071 185.072C299.937 184.782 299.893 184.458 299.945 184.143C300.605 180.076 304.055 159.216 307.194 147.844C308.473 143.212 311.3 140.718 315.597 140.43C321.882 140.015 327.468 144.964 328.05 151.475C329.076 162.963 324.908 179.284 323.068 185.769C322.997 186.019 322.865 186.248 322.686 186.435C322.506 186.623 322.283 186.763 322.037 186.844C321.791 186.924 321.529 186.943 321.274 186.898C321.019 186.853 320.779 186.745 320.575 186.585L316.825 183.649C316.594 183.471 316.308 183.381 316.018 183.393C315.728 183.405 315.451 183.519 315.236 183.716C312.075 186.553 309.072 187.431 306.568 187.43Z"
          fill={theme.palette.primary.main}
        />
        <path
          opacity="0.2"
          d="M318.971 157.097L322.136 170.55L317.562 181.879L320.729 170.904L318.971 157.097Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M321.083 135.855C321.851 135.869 322.615 135.73 323.33 135.446C323.644 135.324 323.971 135.223 324.285 135.101C327.057 134.023 328.884 131.052 328.949 128.061C329.015 125.07 327.476 122.168 325.191 120.255C322.906 118.342 319.947 117.359 316.983 117.132C313.791 116.887 310.196 117.702 308.412 120.378C307.937 121.09 307.605 121.954 307.887 122.814C307.971 123.073 308.121 123.306 308.323 123.489C309.12 124.202 309.914 123.666 310.726 123.618C311.842 123.553 312.845 124.463 313.205 125.528C313.565 126.593 313.407 127.766 313.116 128.852C312.894 129.506 312.754 130.185 312.7 130.874C312.676 131.219 312.741 131.565 312.887 131.878C313.033 132.191 313.257 132.462 313.536 132.664C314.234 133.088 315.15 132.842 315.847 132.419C316.545 131.995 317.148 131.406 317.902 131.094C318.656 130.783 319.669 130.852 320.108 131.543C320.247 131.795 320.342 132.069 320.387 132.353C320.78 134.157 320.69 134.051 321.083 135.855Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M307.691 230.626L253.592 211.664C252.663 211.337 251.902 210.654 251.474 209.762C251.046 208.871 250.986 207.846 251.309 206.911L265.367 166.283C265.605 165.598 266.102 165.037 266.751 164.721C267.4 164.406 268.146 164.362 268.827 164.6L324.755 184.203C325.447 184.446 326.014 184.956 326.333 185.62C326.652 186.284 326.696 187.048 326.456 187.745L312.413 228.328C312.089 229.263 311.409 230.029 310.524 230.46C309.639 230.891 308.62 230.95 307.691 230.626Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M258.209 209.33L305.515 225.911C306.415 226.226 307.404 226.169 308.263 225.751C309.122 225.333 309.78 224.589 310.094 223.683L321.446 190.876C321.76 189.969 321.703 188.974 321.288 188.11C320.872 187.246 320.133 186.583 319.232 186.267L271.926 169.686C271.026 169.37 270.037 169.427 269.178 169.845C268.319 170.263 267.661 171.007 267.347 171.914L255.995 204.721C255.84 205.169 255.774 205.645 255.801 206.119C255.828 206.594 255.948 207.059 256.153 207.487C256.359 207.914 256.646 208.298 256.999 208.614C257.352 208.93 257.763 209.173 258.209 209.33Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M270.088 186.279L267.745 185.458C267.618 185.414 267.513 185.32 267.455 185.198C267.396 185.076 267.388 184.936 267.432 184.808L268.248 182.45C268.293 182.322 268.386 182.217 268.507 182.158C268.628 182.099 268.767 182.091 268.894 182.136L271.237 182.957C271.364 183.002 271.468 183.095 271.527 183.217C271.586 183.339 271.594 183.479 271.549 183.607L270.734 185.965C270.689 186.093 270.596 186.198 270.475 186.257C270.354 186.316 270.215 186.324 270.088 186.279Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M267.974 192.389L265.631 191.568C265.504 191.523 265.4 191.429 265.342 191.307C265.283 191.186 265.275 191.045 265.319 190.917L266.135 188.559C266.179 188.432 266.272 188.327 266.393 188.268C266.515 188.209 266.654 188.201 266.781 188.245L269.124 189.066C269.251 189.111 269.355 189.204 269.414 189.326C269.472 189.448 269.48 189.589 269.436 189.717L268.62 192.074C268.576 192.202 268.483 192.307 268.362 192.366C268.241 192.425 268.101 192.433 267.974 192.389Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M265.859 198.498L263.516 197.677C263.389 197.632 263.285 197.539 263.226 197.417C263.168 197.295 263.16 197.155 263.204 197.027L264.02 194.669C264.064 194.541 264.157 194.436 264.278 194.377C264.399 194.318 264.539 194.31 264.666 194.354L267.009 195.176C267.136 195.22 267.24 195.314 267.298 195.436C267.357 195.558 267.365 195.698 267.321 195.826L266.505 198.184C266.461 198.312 266.368 198.417 266.247 198.475C266.125 198.534 265.986 198.543 265.859 198.498Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M285.831 190.475L274.543 186.519C274.48 186.497 274.422 186.463 274.372 186.418C274.322 186.374 274.281 186.319 274.252 186.259C274.223 186.199 274.206 186.133 274.202 186.066C274.198 185.999 274.207 185.932 274.229 185.868C274.251 185.805 274.286 185.746 274.33 185.696C274.374 185.646 274.428 185.605 274.488 185.576C274.549 185.547 274.614 185.53 274.68 185.526C274.747 185.522 274.814 185.532 274.877 185.554L286.165 189.511C286.228 189.533 286.286 189.567 286.336 189.612C286.386 189.656 286.427 189.71 286.456 189.771C286.485 189.831 286.502 189.897 286.506 189.964C286.51 190.031 286.5 190.098 286.478 190.162C286.456 190.225 286.422 190.283 286.378 190.334C286.333 190.384 286.28 190.425 286.219 190.454C286.159 190.483 286.094 190.5 286.027 190.503C285.961 190.507 285.894 190.498 285.831 190.475Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M283.717 196.585L272.428 192.629C272.301 192.584 272.197 192.491 272.138 192.369C272.08 192.247 272.072 192.106 272.116 191.978C272.16 191.851 272.253 191.746 272.374 191.687C272.496 191.628 272.635 191.62 272.762 191.664L284.05 195.621C284.113 195.643 284.172 195.677 284.221 195.722C284.271 195.766 284.312 195.82 284.341 195.881C284.37 195.941 284.387 196.007 284.391 196.074C284.395 196.141 284.386 196.208 284.364 196.271C284.342 196.335 284.308 196.393 284.263 196.443C284.219 196.493 284.165 196.534 284.105 196.563C284.045 196.593 283.979 196.61 283.913 196.613C283.846 196.617 283.779 196.608 283.717 196.585Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M281.601 202.694L270.313 198.737C270.186 198.693 270.082 198.599 270.023 198.477C269.965 198.355 269.957 198.215 270.001 198.087C270.045 197.959 270.138 197.854 270.259 197.795C270.38 197.736 270.52 197.728 270.647 197.773L281.935 201.729C282.062 201.774 282.166 201.867 282.225 201.989C282.284 202.111 282.292 202.251 282.247 202.379C282.203 202.507 282.11 202.612 281.989 202.671C281.868 202.73 281.728 202.738 281.601 202.694Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M311.641 206.004C311.724 205.806 311.801 205.604 311.872 205.398C312.8 202.717 312.631 199.775 311.404 197.218C310.176 194.662 307.99 192.701 305.326 191.768L301.615 202.49L311.641 206.004Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M300.536 203.142L304.247 192.42C302.286 191.733 300.168 191.638 298.154 192.147C296.141 192.657 294.319 193.748 292.915 195.287C291.51 196.826 290.584 198.745 290.25 200.808C289.916 202.87 290.189 204.986 291.035 206.894C291.882 208.802 293.265 210.419 295.014 211.546C296.762 212.673 298.8 213.259 300.877 213.234C302.953 213.209 304.976 212.573 306.697 211.404C308.418 210.235 309.762 208.585 310.562 206.657L300.536 203.142Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M313.82 187.761C313.778 187.242 313.85 186.721 314.03 186.233C314.21 185.745 314.494 185.303 314.862 184.937C315.23 184.572 315.674 184.292 316.161 184.118C316.648 183.943 317.168 183.879 317.682 183.928L322.914 172.425L326.376 178L320.877 188.069C320.737 188.934 320.287 189.717 319.611 190.27C318.936 190.823 318.082 191.107 317.213 191.07C316.343 191.032 315.517 190.674 314.892 190.064C314.267 189.454 313.885 188.635 313.82 187.761Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M322.877 183.71C322.555 183.71 322.24 183.611 321.975 183.427L319.505 181.705C319.238 181.518 319.034 181.253 318.922 180.946C318.809 180.639 318.793 180.304 318.876 179.988L321.78 168.949L318.27 148.453C318.266 147.779 318.394 147.11 318.647 146.485C318.899 145.861 319.272 145.293 319.744 144.813C320.215 144.333 320.775 143.952 321.393 143.691C322.011 143.43 322.674 143.293 323.344 143.29C324.014 143.287 324.679 143.417 325.299 143.672C325.919 143.927 326.483 144.303 326.959 144.778C327.435 145.253 327.813 145.817 328.072 146.44C328.331 147.062 328.466 147.729 328.468 148.404L331.344 161.192C332.218 165.074 331.661 169.142 329.777 172.641L324.268 182.876C324.162 183.072 324.016 183.244 323.84 183.381C323.663 183.517 323.46 183.614 323.244 183.666C323.124 183.695 323.001 183.71 322.877 183.71Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M349.649 247H282.815C282.721 247 282.632 246.963 282.566 246.896C282.5 246.83 282.463 246.74 282.463 246.646C282.463 246.552 282.5 246.462 282.566 246.396C282.632 246.329 282.721 246.292 282.815 246.292H349.649C349.742 246.292 349.832 246.329 349.898 246.396C349.964 246.462 350.001 246.552 350.001 246.646C350.001 246.74 349.964 246.83 349.898 246.896C349.832 246.963 349.742 247 349.649 247Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M172.551 242.595L176.864 242.595L178.916 225.854H172.551L172.551 242.595Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M171.453 241.178L179.946 241.178C180.657 241.178 181.361 241.319 182.017 241.592C182.674 241.866 183.271 242.267 183.773 242.773C184.276 243.279 184.675 243.879 184.947 244.54C185.219 245.201 185.359 245.91 185.359 246.625V246.802L171.453 246.802L171.453 241.178Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M155.315 242.595L159.628 242.595L161.679 225.854H155.314L155.315 242.595Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M154.217 241.178L162.71 241.178C163.42 241.178 164.124 241.319 164.781 241.592C165.438 241.866 166.034 242.267 166.537 242.773C167.04 243.279 167.438 243.879 167.71 244.54C167.982 245.201 168.122 245.91 168.122 246.625V246.802L154.217 246.802L154.217 241.178Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M173.413 126.692C178.184 126.692 182.053 122.799 182.053 117.997C182.053 113.195 178.184 109.302 173.413 109.302C168.642 109.302 164.773 113.195 164.773 117.997C164.773 122.799 168.642 126.692 173.413 126.692Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M179.223 238.982H171.221C171.011 238.982 170.804 238.94 170.611 238.859C170.417 238.778 170.242 238.659 170.095 238.509C169.947 238.359 169.831 238.181 169.753 237.985C169.674 237.79 169.635 237.581 169.638 237.37L170.103 199.123C170.105 198.988 170.055 198.856 169.963 198.756C169.871 198.657 169.745 198.596 169.611 198.587C169.476 198.578 169.343 198.621 169.239 198.708C169.135 198.794 169.068 198.918 169.052 199.053L164.394 237.582C164.347 237.968 164.161 238.323 163.872 238.581C163.582 238.839 163.209 238.982 162.823 238.982H154.743C154.525 238.982 154.309 238.937 154.109 238.849C153.909 238.761 153.729 238.632 153.581 238.471C153.433 238.31 153.319 238.119 153.248 237.912C153.176 237.704 153.148 237.484 153.165 237.265L158.5 168.556L158.645 168.541L184.193 165.764L180.805 237.465C180.786 237.874 180.611 238.26 180.316 238.543C180.022 238.825 179.63 238.983 179.223 238.982Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M158.81 172.775C158.4 172.774 158.006 172.612 157.711 172.324C157.417 172.036 157.245 171.644 157.233 171.232C157.093 166.887 156.854 151.855 159.632 140.818C160.391 137.804 162.093 135.114 164.487 133.148C166.881 131.182 169.84 130.043 172.926 129.901C175.965 129.734 178.978 130.534 181.539 132.187C184.1 133.841 186.078 136.265 187.193 139.114C191.863 151.046 196.275 165.753 190.774 168.06C183.565 171.083 164.56 172.431 158.907 172.771C158.875 172.773 158.842 172.775 158.81 172.775Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M191.831 109.208C191.967 109.349 192.113 109.478 192.269 109.595L191.362 127.129L187.59 129.073L189.76 135.186L196.771 132.398C197.337 132.173 197.816 131.769 198.135 131.247C198.453 130.725 198.595 130.112 198.539 129.502L196.702 109.613C197.303 109.162 197.753 108.537 197.993 107.822C198.233 107.107 198.25 106.335 198.043 105.61C197.837 104.884 197.415 104.239 196.836 103.761C196.256 103.282 195.545 102.993 194.798 102.931C194.05 102.868 193.302 103.037 192.652 103.413C192.002 103.79 191.482 104.356 191.16 105.038C190.838 105.719 190.729 106.483 190.849 107.228C190.969 107.973 191.311 108.664 191.831 109.208Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M191.434 127.782L194.088 135.372C194.163 135.588 194.191 135.818 194.171 136.046C194.15 136.274 194.081 136.495 193.968 136.693C193.855 136.892 193.7 137.064 193.515 137.197C193.33 137.33 193.119 137.421 192.895 137.465L185.07 138.99C183.97 139.373 182.764 139.301 181.717 138.79C180.67 138.279 179.866 137.371 179.483 136.265C179.1 135.159 179.169 133.945 179.673 132.889C180.178 131.834 181.078 131.023 182.176 130.634L189.202 126.902C189.403 126.795 189.625 126.733 189.852 126.72C190.079 126.707 190.307 126.744 190.518 126.828C190.73 126.911 190.921 127.04 191.079 127.204C191.237 127.369 191.358 127.566 191.434 127.782Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M154.408 109.208C154.272 109.349 154.125 109.478 153.969 109.595L154.876 127.129L158.649 129.073L156.478 135.186L149.467 132.398C148.901 132.173 148.423 131.769 148.104 131.247C147.785 130.725 147.643 130.112 147.7 129.502L149.536 109.613C148.935 109.162 148.485 108.537 148.245 107.822C148.006 107.107 147.988 106.335 148.195 105.61C148.402 104.884 148.823 104.239 149.403 103.761C149.983 103.282 150.693 102.993 151.441 102.931C152.188 102.868 152.936 103.037 153.586 103.413C154.236 103.79 154.757 104.356 155.079 105.038C155.401 105.719 155.509 106.483 155.389 107.228C155.269 107.973 154.927 108.664 154.408 109.208Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M155.722 126.828C155.933 126.744 156.161 126.707 156.388 126.72C156.615 126.733 156.836 126.795 157.038 126.902L164.064 130.634C165.162 131.023 166.062 131.834 166.566 132.889C167.071 133.945 167.14 135.159 166.757 136.265C166.373 137.371 165.57 138.279 164.523 138.79C163.475 139.301 162.269 139.373 161.169 138.99L153.344 137.465C153.121 137.422 152.91 137.33 152.725 137.197C152.54 137.064 152.385 136.892 152.272 136.693C152.159 136.495 152.09 136.274 152.069 136.046C152.048 135.818 152.077 135.588 152.152 135.372L154.806 127.782C154.881 127.566 155.002 127.369 155.16 127.204C155.318 127.04 155.51 126.911 155.722 126.828Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M176.768 119.48C176.722 118.37 176.999 117.272 177.564 116.318C178.129 115.365 178.957 114.597 179.948 114.11C182.574 112.745 184.224 109.835 183.501 107.177C182.576 103.775 177.975 101.569 174.248 102.74C173.239 103.1 172.266 103.555 171.342 104.099L168.907 105.413C167.867 105.937 166.864 106.533 165.905 107.196C164.469 108.261 163.344 109.694 162.646 111.347C161.949 112.999 161.705 114.809 161.941 116.589C162.42 120.047 164.67 123.219 167.751 125.39C168.929 126.276 170.289 126.885 171.732 127.171C173.209 127.416 174.852 127.138 175.932 126.214C177.619 124.769 177.445 122.351 176.899 120.331C176.827 120.052 176.783 119.767 176.768 119.48Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M206.131 247H139.297C139.204 247 139.114 246.963 139.048 246.896C138.982 246.83 138.945 246.74 138.945 246.646C138.945 246.552 138.982 246.462 139.048 246.396C139.114 246.329 139.204 246.292 139.297 246.292H206.131C206.225 246.292 206.314 246.329 206.38 246.396C206.446 246.462 206.483 246.552 206.483 246.646C206.483 246.74 206.446 246.83 206.38 246.896C206.314 246.963 206.225 247 206.131 247Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M97.0528 182.415L41.905 205.185C40.9579 205.575 39.8959 205.571 38.9519 205.173C38.0078 204.776 37.259 204.018 36.8695 203.066L19.988 161.65C19.7042 160.952 19.7073 160.169 19.9965 159.473C20.2857 158.777 20.8374 158.224 21.5306 157.937L78.5427 134.397C79.2485 134.107 80.0401 134.11 80.7436 134.406C81.4472 134.702 82.0053 135.267 82.2956 135.977L99.1586 177.347C99.5459 178.3 99.5417 179.369 99.1469 180.319C98.7521 181.269 97.999 182.023 97.0528 182.415Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M43.9041 200.162L92.1269 180.251C93.0452 179.872 93.7763 179.141 94.1592 178.219C94.5422 177.298 94.5456 176.261 94.1689 175.336L80.5372 141.894C80.3506 141.436 80.0764 141.02 79.73 140.669C79.3837 140.318 78.972 140.039 78.5186 139.848C78.0652 139.657 77.5789 139.558 77.0874 139.557C76.5959 139.555 76.109 139.651 75.6543 139.838L27.4314 159.749C26.9767 159.937 26.5632 160.213 26.2145 160.562C25.8658 160.91 25.5887 161.325 25.3991 161.781C25.2095 162.237 25.1111 162.727 25.1094 163.222C25.1077 163.716 25.2029 164.206 25.3894 164.664L39.0212 198.107C39.2077 198.564 39.482 198.98 39.8283 199.331C40.1747 199.682 40.5863 199.961 41.0397 200.152C41.4931 200.343 41.9795 200.442 42.4709 200.444C42.9624 200.445 43.4494 200.35 43.9041 200.162Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M80.2695 151.014L79.5605 151.307L79.609 151.426L80.318 151.133L80.2695 151.014Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M78.1776 152.017L76.7453 152.609L76.6968 152.49L78.1291 151.898L78.1776 152.017ZM75.313 153.2L73.8807 153.791L73.8323 153.673L75.2645 153.081L75.313 153.2ZM72.4485 154.383L71.0162 154.974L70.9677 154.855L72.4 154.264L72.4485 154.383ZM69.5839 155.566L68.1516 156.157L68.1032 156.038L69.5354 155.447L69.5839 155.566ZM66.7193 156.748L65.2871 157.34L65.2386 157.221L66.6709 156.629L66.7193 156.748ZM63.8548 157.931L62.4225 158.523L62.3741 158.404L63.8063 157.812L63.8548 157.931ZM60.9902 159.114L59.558 159.705L59.5095 159.586L60.9418 158.995L60.9902 159.114ZM58.1257 160.297L56.6934 160.888L56.645 160.769L58.0772 160.178L58.1257 160.297ZM55.2611 161.479L53.8289 162.071L53.7804 161.952L55.2127 161.361L55.2611 161.479ZM52.3966 162.662L50.9643 163.254L50.9159 163.135L52.3481 162.543L52.3966 162.662ZM49.532 163.845L48.0998 164.436L48.0513 164.317L49.4836 163.726L49.532 163.845ZM46.6675 165.028L45.2352 165.619L45.1867 165.5L46.619 164.909L46.6675 165.028ZM43.8029 166.211L42.3707 166.802L42.3222 166.683L43.7545 166.092L43.8029 166.211ZM40.9384 167.393L39.5061 167.985L39.4576 167.866L40.8899 167.274L40.9384 167.393ZM38.0738 168.576L36.6416 169.167L36.5931 169.048L38.0254 168.457L38.0738 168.576ZM35.2093 169.759L33.777 170.35L33.7285 170.231L35.1608 169.64L35.2093 169.759Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M32.2969 170.823L31.5879 171.115L31.6364 171.234L32.3453 170.941L32.2969 170.823Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M61.5 171.686L60.791 171.979L60.8395 172.097L61.5485 171.805L61.5 171.686Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M59.4284 172.68L58.0173 173.263L57.9689 173.144L59.3799 172.562L59.4284 172.68ZM56.6063 173.846L55.1954 174.428L55.1469 174.309L56.5579 173.727L56.6063 173.846ZM53.7844 175.011L52.3734 175.593L52.3249 175.474L53.7359 174.892L53.7844 175.011ZM50.9623 176.176L49.5513 176.759L49.5029 176.64L50.9139 176.057L50.9623 176.176ZM48.1404 177.341L46.7293 177.924L46.6808 177.805L48.0919 177.222L48.1404 177.341ZM45.3183 178.506L43.9073 179.089L43.8588 178.97L45.2698 178.388L45.3183 178.506ZM42.4963 179.672L41.0853 180.254L41.0368 180.135L42.4478 179.553L42.4963 179.672ZM39.6743 180.837L38.2633 181.419L38.2148 181.301L39.6258 180.718L39.6743 180.837Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M36.8047 181.883L36.0957 182.176L36.1442 182.295L36.8532 182.002L36.8047 181.883Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M49.8086 182.627L49.0996 182.92L49.1481 183.039L49.8571 182.746L49.8086 182.627Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M47.6957 183.637L46.244 184.237L46.1955 184.118L47.6472 183.519L47.6957 183.637ZM44.7923 184.836L43.3406 185.436L43.2921 185.317L44.7438 184.717L44.7923 184.836ZM41.8889 186.035L40.4371 186.635L40.3887 186.516L41.8404 185.916L41.8889 186.035Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M38.9375 187.115L38.2285 187.408L38.277 187.527L38.986 187.234L38.9375 187.115Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M53.4473 191.557L52.7383 191.85L52.7868 191.969L53.4957 191.676L53.4473 191.557Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M51.3363 192.568L49.8846 193.168L49.8362 193.049L51.2879 192.449L51.3363 192.568ZM48.4329 193.767L46.9812 194.366L46.9327 194.247L48.3844 193.648L48.4329 193.767ZM45.5295 194.966L44.0778 195.565L44.0293 195.446L45.481 194.847L45.5295 194.966Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M42.5781 196.045L41.8691 196.338L41.9176 196.457L42.6266 196.164L42.5781 196.045Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M45.9512 194.897L86.8168 178.024C87.8058 177.614 88.593 176.827 89.0056 175.833C89.4182 174.84 89.4226 173.723 89.0178 172.727L80.7611 152.471L80.6812 152.506L70.6528 176.64L63.215 173.51C63.0778 173.452 62.929 173.426 62.7804 173.436C62.6318 173.445 62.4873 173.489 62.3583 173.564C62.1646 173.681 62.0148 173.859 61.932 174.071L56.6763 186.718L51.2381 184.43C51.1208 184.38 50.9949 184.355 50.8677 184.354C50.7405 184.354 50.6144 184.378 50.4968 184.427C50.3791 184.476 50.2721 184.547 50.1818 184.637C50.0916 184.728 50.0199 184.835 49.9708 184.953C49.936 185.039 46.5829 193.319 45.9512 194.897Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M45.8249 195.089L45.707 195.039L49.9122 184.918C50.0235 184.651 50.2356 184.44 50.5018 184.33C50.7681 184.22 51.0669 184.221 51.3325 184.332L56.6633 186.576L61.8891 173.999C62.0004 173.732 62.2125 173.52 62.4787 173.41C62.745 173.3 63.0438 173.301 63.3094 173.412L70.6398 176.498L80.7198 152.238L80.8376 152.288L70.7084 176.666L63.2602 173.531C63.1441 173.482 63.0196 173.457 62.8938 173.456C62.7681 173.456 62.6434 173.48 62.527 173.528C62.4106 173.576 62.3048 173.647 62.2156 173.736C62.1263 173.826 62.0554 173.932 62.0069 174.048L56.7318 186.744L51.2833 184.451C51.0489 184.352 50.7853 184.352 50.5503 184.449C50.3154 184.546 50.1282 184.732 50.03 184.968L45.8249 195.089Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M49.8315 183.586C50.3253 183.586 50.7256 183.183 50.7256 182.686C50.7256 182.189 50.3253 181.786 49.8315 181.786C49.3378 181.786 48.9375 182.189 48.9375 182.686C48.9375 183.183 49.3378 183.586 49.8315 183.586Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M61.5249 172.645C62.0187 172.645 62.4189 172.242 62.4189 171.746C62.4189 171.249 62.0187 170.846 61.5249 170.846C61.0311 170.846 60.6309 171.249 60.6309 171.746C60.6309 172.242 61.0311 172.645 61.5249 172.645Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M79.73 151.942C80.2237 151.942 80.624 151.539 80.624 151.042C80.624 150.545 80.2237 150.143 79.73 150.143C79.2362 150.143 78.8359 150.545 78.8359 151.042C78.8359 151.539 79.2362 151.942 79.73 151.942Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M29.6893 181.665C30.1541 181.432 30.5625 181.1 30.8857 180.691C31.209 180.282 31.4393 179.807 31.5603 179.299C31.6814 178.791 31.6903 178.262 31.5864 177.75C31.4826 177.238 31.2684 176.756 30.9591 176.336L33.6144 150.207H27.8761L25.7658 175.721C25.0999 176.29 24.6647 177.086 24.5427 177.957C24.4208 178.828 24.6206 179.714 25.1042 180.447C25.5878 181.179 26.3217 181.708 27.1667 181.933C28.0118 182.157 28.9093 182.062 29.6893 181.665Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M34.9585 135.787C39.4422 135.787 43.0771 132.129 43.0771 127.616C43.0771 123.104 39.4422 119.445 34.9585 119.445C30.4747 119.445 26.8398 123.104 26.8398 127.616C26.8398 132.129 30.4747 135.787 34.9585 135.787Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M36.4858 165.424C36.1608 165.424 35.8449 165.317 35.5864 165.118C35.3279 164.92 35.1412 164.642 35.0548 164.327C34.4044 161.948 32.7121 157.927 30.0251 152.373C29.4188 151.118 29.0831 149.748 29.0399 148.353C28.9968 146.958 29.2472 145.569 29.7747 144.278C30.3022 142.988 31.095 141.824 32.1009 140.863C33.1069 139.902 34.3033 139.166 35.6117 138.703C36.8516 138.263 38.1667 138.077 39.4792 138.157C40.7918 138.237 42.0751 138.581 43.2532 139.168C44.4313 139.756 45.4803 140.576 46.3381 141.579C47.196 142.582 47.8452 143.748 48.2475 145.008C50.3302 151.58 50.2297 158.619 50.0897 161.341C50.0723 161.668 49.9487 161.981 49.7377 162.232C49.5267 162.482 49.24 162.656 48.9216 162.726L36.8048 165.389C36.7001 165.412 36.5931 165.424 36.4858 165.424Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M48.2195 243.015H52.2717L54.1997 227.283L48.2188 227.284L48.2195 243.015Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M60.0879 246.802L47.3516 246.802L47.3514 241.85L55.1668 241.849C55.813 241.849 56.4529 241.977 57.05 242.226C57.647 242.475 58.1895 242.84 58.6465 243.3C59.1034 243.76 59.4659 244.306 59.7132 244.906C59.9606 245.507 60.0879 246.151 60.0879 246.802Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.69727 237.729L11.2486 239.694L20.4689 226.844L15.2281 223.943L7.69727 237.729Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M16.2852 246.803L5.12437 240.627L7.49507 236.287L14.3437 240.077C15.4874 240.71 16.3344 241.774 16.6985 243.035C17.0626 244.296 16.9139 245.652 16.2852 246.803Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M48.0301 234.283C47.669 234.283 47.3204 234.15 47.0492 233.91C46.778 233.67 46.6029 233.339 46.5565 232.978L41.4884 193.673C41.466 193.499 41.3896 193.336 41.2701 193.209C41.1506 193.081 40.9941 192.995 40.8229 192.962C40.6518 192.93 40.4748 192.952 40.3171 193.027C40.1595 193.101 40.0294 193.224 39.9452 193.378L18.8236 231.946C18.6493 232.261 18.3686 232.502 18.0324 232.627C17.6961 232.751 17.3268 232.749 16.9915 232.623L12.3876 230.857C12.2024 230.786 12.0332 230.679 11.8899 230.541C11.7466 230.403 11.6321 230.238 11.553 230.055C11.474 229.872 11.4321 229.675 11.4298 229.476C11.4275 229.277 11.4647 229.079 11.5395 228.894L26.1709 192.719C26.208 192.627 26.2331 192.531 26.2457 192.432C28.2274 176.995 33.2697 169.144 34.7678 167.106C34.8772 166.956 34.9492 166.782 34.9777 166.598C35.0063 166.415 34.9905 166.227 34.9317 166.05L34.7257 165.429C34.6435 165.182 34.6266 164.918 34.6768 164.662C34.727 164.407 34.8425 164.169 35.012 163.972C40.4103 157.784 49.8193 161.161 49.9137 161.196L49.9608 161.213L49.9904 161.254C60.5407 175.816 56.6399 223.114 55.7508 232.448C55.7176 232.799 55.5622 233.126 55.3121 233.373C55.0621 233.619 54.7334 233.769 54.3843 233.795L48.1457 234.279C48.1069 234.282 48.0684 234.283 48.0301 234.283Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M66.5207 137.108C66.4802 137.287 66.4537 137.47 66.4416 137.653L52.2421 145.898L48.7907 143.898L45.1113 148.746L50.8795 152.884C51.3455 153.218 51.9067 153.391 52.4787 153.377C53.0508 153.363 53.6029 153.163 54.0522 152.806L68.6943 141.18C69.3551 141.427 70.0747 141.464 70.7574 141.289C71.4401 141.113 72.0536 140.733 72.5162 140.198C72.9788 139.663 73.2686 138.999 73.3471 138.294C73.4256 137.59 73.289 136.877 72.9556 136.253C72.6221 135.628 72.1076 135.12 71.4804 134.797C70.8533 134.474 70.1432 134.35 69.4447 134.443C68.7461 134.536 68.0922 134.841 67.5699 135.317C67.0476 135.793 66.6816 136.417 66.5207 137.108Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M51.7671 146.289L47.1778 152.275C47.0473 152.445 46.8817 152.585 46.6926 152.685C46.5034 152.786 46.295 152.843 46.0815 152.855C45.8681 152.867 45.6547 152.832 45.4557 152.754C45.2568 152.675 45.0771 152.554 44.9288 152.399L39.7335 146.969C38.8714 146.292 38.3114 145.298 38.1762 144.206C38.041 143.114 38.3417 142.012 39.0123 141.142C39.683 140.273 40.6688 139.707 41.7536 139.568C42.8385 139.428 43.9339 139.728 44.7997 140.401L51.3158 144.068C51.5023 144.173 51.6641 144.317 51.7903 144.491C51.9164 144.664 52.0039 144.863 52.0467 145.074C52.0895 145.285 52.0867 145.503 52.0385 145.712C51.9902 145.922 51.8977 146.118 51.7671 146.289Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M33.9794 156.689L26.5864 155.345C26.3761 155.307 26.1764 155.224 26.001 155.101C25.8255 154.978 25.6785 154.818 25.5698 154.633C25.4611 154.448 25.3934 154.241 25.3713 154.027C25.3491 153.813 25.3731 153.597 25.4415 153.393L27.8381 146.251C28.0385 145.169 28.6571 144.211 29.5582 143.588C30.4593 142.964 31.5695 142.725 32.6452 142.924C33.7209 143.123 34.6744 143.743 35.2966 144.648C35.9188 145.553 36.1589 146.67 35.9643 147.753L35.7304 155.263C35.7237 155.478 35.671 155.689 35.5759 155.882C35.4808 156.074 35.3455 156.244 35.1793 156.38C35.0132 156.515 34.82 156.613 34.6129 156.666C34.4058 156.719 34.1898 156.727 33.9794 156.689Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M36.2428 125.737C38.3295 127.175 41.0693 128.652 43.2005 127.121C43.8671 126.611 44.3514 125.896 44.5809 125.085C44.8103 124.274 44.7726 123.41 44.4733 122.622C43.4528 119.685 40.649 118.392 37.9679 117.401C34.4825 116.112 30.6874 115.087 27.0892 116.01C23.4911 116.933 20.3111 120.439 20.9606 124.118C21.483 127.077 24.2562 129.539 23.8625 132.518C23.4663 135.517 20.1161 137.095 17.1585 137.631C14.2008 138.166 10.8912 138.339 8.76562 140.477C6.0541 143.204 6.73121 148.08 9.28264 150.959C11.8341 153.839 15.6843 155.145 19.4152 156.032C24.3584 157.208 29.7049 157.826 34.3997 155.874C39.0945 153.923 42.7479 148.67 41.3879 143.745C40.8134 141.664 39.4602 139.906 38.1523 138.194C36.8444 136.483 35.5201 134.692 35.0212 132.592C34.6055 130.842 34.9132 128.81 36.0664 127.497C36.2761 127.268 36.4081 126.977 36.4434 126.667C36.4787 126.357 36.4155 126.043 36.2628 125.772L36.2428 125.737Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M67.1859 247H0.351759C0.258467 247 0.168995 246.963 0.103027 246.896C0.0370599 246.83 0 246.74 0 246.646C0 246.552 0.0370599 246.462 0.103027 246.396C0.168995 246.329 0.258467 246.292 0.351759 246.292H67.1859C67.2792 246.292 67.3687 246.329 67.4346 246.396C67.5006 246.462 67.5377 246.552 67.5377 246.646C67.5377 246.74 67.5006 246.83 67.4346 246.896C67.3687 246.963 67.2792 247 67.1859 247Z"
          fill={theme.palette.svgs.celebralGray}
        />
      </g>
      <defs>
        <clipPath id="clip0_10263_18877">
          <rect width="350" height="247" fill={theme.palette.svgs.paperWhite} />
        </clipPath>
      </defs>
    </svg>
  );
};

import BackIcon from "@mui/icons-material/ArrowBack";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MailIcon from "@mui/icons-material/EmailOutlined";
import {
  CssBaseline,
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Button,
  Collapse,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { LogoVertical } from "assets/images/LogoVertical";
import { LINK_COPYRIGHT } from "libs/constants/documentation-links";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";

import {
  AuthenticationLink,
  Divider,
  Copyright,
  SecondaryButton,
} from "components/atoms";

import "./SignUp.scss";
import SignUpOAuth from "./SignUpOAuth";
import SignUpRegister from "./SignUpRegister";

import type { AppThemeProps } from "assets/styles/theme/theme";

const POINTS = [
  "Test UbiOps with your team! <br/> With our 2 user limit.",
  "Run multiple models and pipelines.",
  "Sufficient computing power and data bandwidth <br/> to deploy any AI model",
  "<b>5 GB</b> Data storage available",
  "Personal support from our product expert <br/> to deploy your first models.",
];

const SignUp = () => {
  useGoogleAnalytics();
  const methods = useForm({
    mode: "onBlur",
  });

  const { email } = useParams<{ email: string }>();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      const voucherCode = Object.fromEntries(
        new URLSearchParams(history.location.search)
      )?.voucher;

      voucherCode && env.set(ENV_NAMES.PROMO_CODE, voucherCode);
    }
  }, [history.location]);

  const theme = useTheme() as AppThemeProps;

  const [signUpWithEmail, setSignUpWithEmail] = useState(!!email);

  const toggleSignUpWithEmail = () => setSignUpWithEmail((prev) => !prev);

  return (
    <Box
      className="sign-up"
      sx={{
        // as these are unique colors only used for the sign-up page
        //and nowhere else, it's safe to keep them out of theme
        // TO DO: incorporate colors from sign up gradient into theme
        background: `linear-gradient(0.25turn, #103B72 30%, #0A1D36 70%)`,
      }}
    >
      <CssBaseline />
      <Box
        className="sign-up__form-section"
        sx={{ backgroundColor: theme.palette.background.auth }}
      >
        <Link href={LINK_COPYRIGHT}>
          <Avatar
            className="sign-up__logo"
            alt="Ubiops logo"
            variant="square"
            sx={{ backgroundColor: "transparent" }}
          >
            <LogoVertical />
          </Avatar>
        </Link>
        <Typography variant="h2" align="center" gutterBottom>
          Sign up
        </Typography>
        <Typography variant="subtitle1" align="center">
          Create your free account
        </Typography>
        <Card
          className="sign-up__form"
          variant="outlined"
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <CardContent>
            <FormProvider {...methods}>
              {env.get(ENV_NAMES.OAUTH_ENABLED) && (
                <div>
                  <SignUpOAuth />
                  <Divider my={1} />
                  <Typography variant="subtitle1" align="center">
                    OR
                  </Typography>
                  <Divider mt={1} mb={3} />
                </div>
              )}
              <SecondaryButton
                startIcon={<MailIcon />}
                disabled={signUpWithEmail}
                onClick={() => toggleSignUpWithEmail()}
                style={{ width: "100%" }}
              >
                Sign up with email
              </SecondaryButton>
              <Collapse in={signUpWithEmail}>
                <Box mt={3}>
                  <SignUpRegister />
                </Box>
              </Collapse>
              <Divider my={3} />
              <Box textAlign="center">
                <Box component="span">Already have an account? </Box>
                <AuthenticationLink to="/sign-in">Sign in</AuthenticationLink>
              </Box>
            </FormProvider>
          </CardContent>
        </Card>
        {signUpWithEmail && (
          <Button
            className="sign-up__back-button"
            startIcon={<BackIcon />}
            color="primary"
            onClick={() => toggleSignUpWithEmail()}
          >
            Back
          </Button>
        )}
      </Box>
      <Box
        className="sign-up__presentation-section"
        color={theme.palette.primary.contrastText}
      >
        <Box className="sign-up__selling-points">
          <Typography variant="h1" className="sign-up__title">
            Start today with your <br />
            14-days free UbiOps trial
          </Typography>
          <Typography
            style={{
              color: theme.palette.primary.contrastText,
              fontSize: 22,
              fontWeight: "500",
              lineHeight: 1.4,
              marginBottom: 60,
            }}
          >
            Turn your AI & ML models into powerful services
          </Typography>
          <List>
            {POINTS.map((point, key) => (
              <ListItem className="sign-up__selling-point" key={key}>
                <ListItemIcon>
                  <CheckRoundedIcon color="secondary" fontSize="large" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="h4"
                    dangerouslySetInnerHTML={{ __html: point }}
                  />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          component="span"
          className="sign-up__copyright"
          color={theme.palette.tertiary.light}
        >
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;

import { Typography, useTheme } from "@mui/material";

import type { TypographyProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const CardHeaderTitle = ({
  variant = "h3",
  ...props
}: TypographyProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Typography
      variant={variant}
      component="span"
      style={{
        color: theme.palette.font.cardHeader,
      }}
      {...props}
    />
  );
};

import type { InputOutputFieldList } from "libs/data/models";

export const TRAINING_DEPLOYMENT = "training-base-deployment";
export const SYS_DEFAULT_BUCKET = "SYS_DEFAULT_BUCKET";
export const trainingInputFields: InputOutputFieldList[] = [
  {
    name: "parameters",
    data_type: "dict",
  },
  {
    name: "training_code",
    data_type: "file",
  },
  {
    name: "training_data",
    data_type: "file",
  },
];

export const trainingOutputFields: InputOutputFieldList[] = [
  {
    name: "additional_output_files",
    data_type: "array_file",
  },
  {
    name: "artifact",
    data_type: "file",
  },
  {
    name: "metadata",
    data_type: "dict",
  },
  {
    name: "metrics",
    data_type: "dict",
  },
];

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { usePipelineVersionsList } from "libs/data/endpoints/pipelines/pipelines";
import { gtmEvent } from "libs/third-parties";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { useCreatePipelineVersion } from "./:versionName/useCreatePipelineVersion";
import { PipelineVersionBaseForm } from "./PipelineVersionBaseForm";

import type { PipelineVersionRouteParams } from "./:versionName/types";
import type { PipelineVersionBaseProps } from "./PipelineVersionBaseForm/constants";
import type { AxiosError } from "axios";
import type { FormikHelpers } from "formik";

export const PipelineVersionCreate = () => {
  const { projectName, pipelineName, organizationName } =
    useParams<PipelineVersionRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: pipelineVersions, mutate } = usePipelineVersionsList(
    projectName,
    pipelineName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/pipelines/${pipelineName}/versions`,
      },
    }
  );
  const latestVersion = pipelineVersions?.sort((version1, version2) =>
    version1?.creation_date &&
    version2?.creation_date &&
    version1?.creation_date < version2?.creation_date
      ? 1
      : -1
  )?.[0];

  const createPipelineVersion = useCreatePipelineVersion();

  const handleOnSubmit = async (
    data: any,
    actions: FormikHelpers<PipelineVersionBaseProps>
  ) => {
    try {
      const response = await createPipelineVersion(data);
      await mutate();
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines[":pipelineName"](pipelineName)
          .versions[":versionName"](response.version)
          .general.index()
      );
    } catch (e: unknown) {
      const error = e as AxiosError;
      createErrorNotification(error.message);

      return;
    }
    actions.setSubmitting(false);
    gtmEvent("pipeline_version_duplicate", {
      event_category: "pipeline_versions",
    });
    dispatch(createSuccessNotification("The pipeline version was created"));
  };

  return (
    <PageContainer>
      <PageHeader title="Create new version" />
      <FormWrapper>
        {pipelineVersions && (
          <PipelineVersionBaseForm
            latestVersion={latestVersion?.version}
            onSubmit={handleOnSubmit}
          />
        )}
      </FormWrapper>
    </PageContainer>
  );
};

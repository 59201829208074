import { Grid, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { explanations } from "libs/utilities/explanations";

import { InfoTooltip, Loader, StatusIcon } from "components/atoms";
import { BaseTable } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BaseColumn } from "components/molecules/BaseTable";

export const BaseEnvironments = () => {
  const theme = useTheme() as AppThemeProps;
  const { projectName } = useParams<{ projectName: string }>();
  const { data: environments, error } = useEnvironmentsList(projectName, {
    environment_type: "base",
  });

  const isLoading = !error && !environments;

  const columns = [
    {
      title: "Name",
      width: "15%",
      field: "display_name",
      nowrap: true,
      render: ({ display_name }: { display_name: string }) => (
        <Typography variant="h5" color={theme.palette.table.nameColumn}>
          {display_name}
        </Typography>
      ),
      defaultSort: "asc",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      nowrap: true,
      render: () => <StatusIcon status="available" label="available" />,
    },
    {
      title: (
        <Grid container alignItems="center" component="span">
          <Grid item component="span">
            <p>Reference Name</p>
          </Grid>
          <Grid item component={InfoTooltip}>
            {explanations.environments.referenceName}
          </Grid>
        </Grid>
      ),
      width: "20%",
      field: "name",
    },
    {
      title: "GPU Required",
      width: "20%",
      field: "gpu_required",
      nowrap: true,
      render: ({ gpu_required }: { gpu_required: boolean }) => (
        <span>{gpu_required ? "Yes" : "No"}</span>
      ),
    },
  ];

  const filteredEnvironments = useMemo(
    () => environments?.filter((env) => !env.deprecated && !env.hidden),
    [environments]
  );

  return isLoading ? (
    <Loader />
  ) : (
    <BaseTable columns={columns as BaseColumn[]} data={filteredEnvironments} />
  );
};

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { RunsRoutesContainer } from "./:experimentName/:runId";
import { ExperimentDetails } from "./:experimentName/ExperimentDetails";
import { ExperimentEnvVars } from "./:experimentName/ExperimentEnvVars";
import { ExperimentGeneral } from "./:experimentName/ExperimentGeneral";
import { ExperimentUpdate } from "./:experimentName/ExperimentUpdate";
import { RunCreate } from "./:experimentName/RunCreate";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .training.experiments[":experimentName"](":experimentName");

export const ExperimentsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, experimentName } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
    }>();

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .training.experiments[":experimentName"](experimentName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{experimentName}</BreadcrumbsItem>
      <Switch>
        <Route
          path={basePath.edit.index()}
          component={ExperimentUpdate}
          exact
        />
        <Route path={basePath.runs.create.index()} component={RunCreate} />
        <Route
          path={basePath.runs[":runId"](":runId").index()}
          component={RunsRoutesContainer}
        />
        <Route path={basePath.index()}>
          <ExperimentDetails>
            <Switch>
              <Route
                exact
                path={basePath.general.index()}
                component={ExperimentGeneral}
              />
              <Route
                exact
                path={basePath.environmentVariables.index()}
                component={ExperimentEnvVars}
              />
              <Redirect to={baseUrl.general.index()} />
            </Switch>
          </ExperimentDetails>
        </Route>
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

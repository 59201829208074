import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper?: (node: ReactJSXElement) => ReactJSXElement;
  children?: ReactJSXElement;
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) =>
  children ? (condition && wrapper ? wrapper(children) : children) : null;

export default ConditionalWrapper;

import { useEffect } from "react";

import type { DependencyList } from "react";

export const useInterval = (
  callback: () => void,
  deps: DependencyList,
  delay: number,
  startInstantly?: boolean
) =>
  useEffect(() => {
    if (startInstantly) callback();
    const interval = setInterval(callback, delay);

    return () => {
      clearInterval(interval);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, startInstantly, ...deps]);

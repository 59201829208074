import {
  BOOLEAN_TYPE,
  INT_TYPE,
  DOUBLE_TYPE,
  STRING_TYPE,
  ARRAY_DICT_TYPE,
  FILE_TYPE,
} from "libs/constants/constants";

import { formatInput } from "./input-parser";
import validators from "./validators";

import type {
  DeploymentInputFieldCreate,
  InputFieldWidgetCreate,
  InputOutputFieldDetail,
  PipelineInputFieldCreate,
} from "libs/data/models";

const dummyData = {
  int: 123,
  double: 12.3,
  string: "test data",
  bool: true,
  file: "blobId",
  array_int: [1, 2, 3, 4],
  array_double: [1.1, 1.2, 1.3, 1.4],
  array_string: ["first string", "second string", "third string"],
  dict: `{ "example": 5 }`,
  array_file: ["example-1", "example-2"],
};

export const defineDummyResults = (
  outputFields: Array<DeploymentInputFieldCreate>
) => {
  const results: Record<
    string,
    number | string | Array<string | number> | boolean
  > = {};
  outputFields.forEach(
    ({ name, data_type }: DeploymentInputFieldCreate) =>
      (results[name] = dummyData?.[data_type as keyof typeof dummyData])
  );

  return results;
};

const defaultStringInputWidgetConfiguration = {
  name: "default",
  label: "Default value",
  data_type: STRING_TYPE,
};

const labelInputWidgetConfiguration = {
  name: "label",
  label: "Label",
  data_type: STRING_TYPE,
  help: "Displayed field name. This doesn't affect the actual input field name.",
};

const labelOutputWidgetConfiguration = {
  name: "label",
  label: "Label",
  data_type: STRING_TYPE,
  help: "Displayed field name. This doesn't affect the actual output field name.",
};

const fileBucketConfiguration = {
  name: "bucket",
  label: "Bucket",
  data_type: FILE_TYPE,
  help: "This bucket will be used for all file uploads of this input field.",
};

export const widgetsMapping = {
  input: {
    int: [
      {
        label: "Numberbox",
        name: "numberbox",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "min",
            label: "Minimum",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
          {
            name: "max",
            label: "Maximum",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
          {
            name: "step",
            label: "Step",
            type: "number",
            rules: validators[INT_TYPE],
            defaultValue: 1,
            data_type: INT_TYPE,
          },
          {
            name: "default",
            label: "Default value",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
        ],
      },
      {
        label: "Slider",
        name: "slider",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "min",
            label: "Minimum",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
          {
            name: "max",
            label: "Maximum",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
          {
            name: "step",
            label: "Step",
            type: "number",
            rules: validators[INT_TYPE],
            defaultValue: 1,
            data_type: INT_TYPE,
          },
          {
            name: "default",
            label: "Default value",
            type: "number",
            rules: validators[INT_TYPE],
            data_type: INT_TYPE,
          },
        ],
      },
    ],
    double: [
      {
        label: "Numberbox",
        name: "numberbox",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "min",
            label: "Minimum",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "max",
            label: "Maximum",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "step",
            label: "Step",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "default",
            label: "Default value",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
        ],
      },
      {
        label: "Slider",
        name: "slider",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "min",
            label: "Minimum",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "max",
            label: "Maximum",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "step",
            label: "Step",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
          {
            name: "default",
            label: "Default value",
            rules: validators[DOUBLE_TYPE],
            data_type: DOUBLE_TYPE,
          },
        ],
      },
    ],
    string: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [
          labelInputWidgetConfiguration,
          defaultStringInputWidgetConfiguration,
        ],
      },
      {
        label: "Dropdown",
        name: "dropdown",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "options",
            label: "Options",
            data_type: ARRAY_DICT_TYPE,
            help: 'e.g. [{"label": "A", "value": "a"}, {"label": "B", "value": "b"}]',
          },
          {
            name: "default",
            label: "Default value",
            data_type: STRING_TYPE,
            rules: {
              required: true,
              validate: () => true,
            },
            help: "Default selected dropdown value. This must be equal to one value (not label) of the dropdown options.",
          },
        ],
      },
    ],
    bool: [
      {
        label: "Switch",
        name: "switch",
        configuration: [
          labelInputWidgetConfiguration,
          {
            name: "default",
            label: "True by default",
            data_type: BOOLEAN_TYPE,
          },
        ],
      },
    ],
    array_int: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [
          labelInputWidgetConfiguration,
          defaultStringInputWidgetConfiguration,
        ],
      },
    ],
    array_double: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [
          labelInputWidgetConfiguration,
          defaultStringInputWidgetConfiguration,
        ],
      },
    ],
    array_string: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [
          labelInputWidgetConfiguration,
          defaultStringInputWidgetConfiguration,
        ],
      },
    ],
    file: [
      {
        label: "Button",
        name: "button",
        configuration: [labelInputWidgetConfiguration, fileBucketConfiguration],
      },
      {
        label: "Image Preview",
        name: "image_preview",
        configuration: [labelInputWidgetConfiguration, fileBucketConfiguration],
      },
      {
        label: "Image Drawer",
        name: "drawer",
        configuration: [labelInputWidgetConfiguration, fileBucketConfiguration],
      },
    ],
    array_file: [
      {
        label: "Button",
        name: "textbox",
        configuration: [labelInputWidgetConfiguration],
      },
    ],
    dict: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [
          labelInputWidgetConfiguration,
          defaultStringInputWidgetConfiguration,
        ],
      },
    ],
  },
  output: {
    int: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    double: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    string: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    bool: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    array_int: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    array_double: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    array_string: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    file: [
      {
        label: "Button",
        name: "button",
        configuration: [labelOutputWidgetConfiguration],
      },
      {
        label: "Image Preview",
        name: "image_preview",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    array_file: [
      {
        label: "Button",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
    dict: [
      {
        label: "Textbox",
        name: "textbox",
        configuration: [labelOutputWidgetConfiguration],
      },
    ],
  },
};

export const updateFieldsWidgets = (
  fields: Array<DeploymentInputFieldCreate>,
  widgets: Record<string, any>,
  direction = "input"
): (DeploymentInputFieldCreate | PipelineInputFieldCreate)[] => {
  return fields.map(({ name, data_type }: DeploymentInputFieldCreate) =>
    // If "default" is chosen, reset the widget so the default behavior is used
    !widgets?.[name]?.type?.value
      ? {
          name,
          data_type,
        }
      : {
          name,
          data_type,
          widget: {
            type: widgets[name].type.value,
            configuration: formatInput(
              (widgetsMapping[direction as keyof typeof widgetsMapping]?.[
                data_type
              ]?.find(({ name: value }) => value === widgets[name].type.value)
                ?.configuration as InputOutputFieldDetail[]) ?? [],
              // 2. The configuration values past by the customer
              widgets[name]?.configuration ?? {}
            ),
          } as InputFieldWidgetCreate,
        }
  );
};

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { RunDetails } from "./RunDetails";
import { RunDetailsGeneral } from "./RunDetailsGeneral";
import { RunDuplicate } from "./RunDuplicate";
import { RunMetrics } from "./RunMetrics";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .training.experiments[":experimentName"](":experimentName")
  .runs[":runId"](":runId");

export const RunsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, experimentName, runId } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
      runId: string;
    }>();

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .training.experiments[":experimentName"](experimentName)
    .runs[":runId"](runId);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{runId}</BreadcrumbsItem>
      <Switch>
        <Route
          path={basePath.duplicate.index()}
          component={RunDuplicate}
          exact
        />
        <Route path={basePath.index()}>
          <RunDetails>
            <Switch>
              <Route
                path={basePath.general.index()}
                component={RunDetailsGeneral}
                exact
              />
              <Route path={basePath.metrics.index()} component={RunMetrics} />
              <Redirect to={baseUrl.general.index()} />
            </Switch>
          </RunDetails>
        </Route>
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  useWebhooksList,
  webhooksDelete,
} from "libs/data/endpoints/webhooks/webhooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useWebHookDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { data: webHooks, mutate } = useWebhooksList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/webhooks`,
    },
  });

  return useCallback(
    async (webHookName: string) => {
      try {
        await webhooksDelete(projectName, webHookName);
        dispatch(createSuccessNotification("The webhook was deleted"));
        mutate(webHooks?.filter((item) => item.name !== webHookName));
      } catch (err: any) {
        dispatch(createErrorNotification(err.message));
      }
    },
    [projectName, dispatch, mutate, webHooks]
  );
};

import styled from "@emotion/styled";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { useContext } from "react";

import { spacing } from "assets/styles/theme";
import { BaseUrlContext } from "libs/contexts";
import {
  FULL_DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";

import { CopyToClipboardButton, Icon, Link } from "components/atoms";

import { TRAINING_DEPLOYMENT } from "../training/constants";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { Logs } from "libs/data/models";
import type { ReactNode } from "react";

const referenceLinks = (itemProps: Logs) =>
  ({
    deployment_name: `deployments/${itemProps["deployment_name"]}`,
    deployment_version: `deployments/${itemProps["deployment_name"]}/versions/${itemProps["deployment_version"]}`,
    pipeline_name: `pipelines/${itemProps["pipeline_name"]}`,
    pipeline_version: `pipelines/${itemProps["pipeline_name"]}/versions/${itemProps["pipeline_version"]}`,
    pipeline_object_name: `pipelines/${itemProps["pipeline_name"]}/versions/${itemProps["pipeline_version"]}/general/objects/${itemProps["pipeline_object_name"]}`,
    environment_name: `environments/custom/${itemProps["environment_name"]}`,
    experiment: `training/experiments/${itemProps["deployment_version"]}`,
    revision: `deployments/${itemProps["deployment_name"]}/versions/${itemProps["deployment_version"]}/revisions`,
  } as { [key: string]: string });

export const Log = ({ item }: { item: Logs }) => {
  const { date, log, level, ...itemProps } = item;
  const theme = useTheme() as AppThemeProps;
  const baseUrl = useContext(BaseUrlContext);

  const getLink = (label: string) => {
    if (
      item.deployment_name === TRAINING_DEPLOYMENT &&
      label === "deployment_version"
    ) {
      return referenceLinks(itemProps).experiment;
    }

    if (label === "deployment_version_revision_id") {
      return referenceLinks(itemProps).revision;
    }

    const linkPart = referenceLinks(itemProps)[label];

    return linkPart && `${baseUrl}/${referenceLinks(itemProps)[label]}`;
  };

  const getKey = (key: string) => {
    if (
      item.deployment_name === TRAINING_DEPLOYMENT &&
      key === "deployment_version"
    ) {
      return "experiment";
    }

    return key;
  };

  return (
    <S_Accordion
      backgroundColor={theme.palette.logs.background}
      hoverColor={theme.palette.logs.hover}
      borderColor={`0.5 solid ${theme.palette.border.tertiary}`}
      square
    >
      <S_AccordionSummary
        expandIcon={<ExpandMore style={{ height: "20px", width: "20px" }} />}
      >
        <Title>
          <S_Icon
            component={level === "error" ? WarningIcon : InfoIcon}
            iconColor={
              level === "info"
                ? theme.palette.info.light
                : theme.palette.error.main
            }
            background={level === "info" ? theme.palette.info.main : "inherit"}
            borderRadius={level === "info" ? "50%" : ""}
          />
          <span
            style={{
              width: "160px",
              color: theme.palette.neutrals[500],
              fontSize: "12px",
            }}
          >
            {getTzAwareDate(date).format(`${FULL_DATE_TIME_FORMAT}`)}
          </span>
          <Summary>{log}</Summary>
          <CopyToClipboardButton
            id={"copy-button"}
            defaultLabel="Copy log"
            contentToCopy={date + "\t" + log}
            size="small"
            hoverColor="secondary"
          />
        </Title>
      </S_AccordionSummary>
      <S_AccordionDetails>
        {Object.entries(itemProps).map(([key, value]) => {
          return (
            <div
              key={`${date}-${log}-${key}`}
              style={{ display: "flex", gap: "20px" }}
            >
              <Tooltip title={getKey(key)}>
                <Key>{getKey(key)}</Key>
              </Tooltip>
              {value?.toString().startsWith("deactive_") || !getLink(key) ? (
                <span>{value?.toString()}</span>
              ) : (
                <Link
                  to={{
                    pathname: getLink(key),
                  }}
                >
                  {value?.toString()}
                </Link>
              )}
            </div>
          );
        })}
        <Message>{log}</Message>
      </S_AccordionDetails>
    </S_Accordion>
  );
};

interface S_AccordionProps {
  hoverColor: string;
  backgroundColor: string;
  borderColor: string;
  children: NonNullable<ReactNode>;
  square: boolean;
}

const S_Accordion = styled(
  ({
    hoverColor: _h,
    backgroundColor: _b,
    borderColor: _c,
    children,
    square,
    ...props
  }: S_AccordionProps) => (
    <Accordion square={square} {...props}>
      {children}
    </Accordion>
  )
)<S_AccordionProps>`
  width: 100%;
  font-family: monospace, monospace, "Nunito Sans", "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial",
    "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  &:nth-of-type(even) {
    background-color: ${(props) => props.backgroundColor};
    border-top: ${(props) => props.borderColor};
  }

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }

  &.Mui-expanded {
    margin: 0;
    #copy-button {
      opacity: 1;
    }
  }
`;

const S_AccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  min-height: 1em;

  &.Mui-expanded {
    min-height: 0.2em;
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
    margin-left: -12px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    width: 95%;

    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionSummary-expandIcon {
    padding: 0 0.3em;
  }
`;

const S_AccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  font-size: 0.9em;
  && div,
  span {
    cursor: default;
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;
  font-size: 0.95em;

  > * {
    align-self: center;
  }

  #copy-button {
    margin-left: auto;
    opacity: 0;
  }
  &:hover {
    #copy-button {
      opacity: 1;
    }
  }
`;

const Summary = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const Message = styled.div`
  margin-top: ${spacing[16]};
  overflow-wrap: break-word;
`;

const Key = styled.div`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const S_Icon = styled(Icon, {
  shouldForwardProp: (prop) =>
    prop !== "iconColor" && prop !== "backgroud" && prop !== "borderRadius",
})<{
  iconColor: string | undefined;
  background: string | undefined;
  borderRadius: string | undefined;
}>`
  font-size: 16px;
  color: ${(props) => `${props.iconColor} !important`};
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius};
`;

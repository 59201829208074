export const cookieList = {
  necessary: [
    {
      name: "UbiopsCookieConsent",
      provider: "app.ubiops.com  requests.ubiops.com  ubiops.com",
      purpose: "Stores the user's cookie consent state for the current domain",
      expiry: "1 year",
      type: "HTTP",
    },
    {
      name: "UBIOPS_ENVIRONMENT_MANAGER",
      provider: "app.ubiops.com  requests.ubiops.com",
      purpose:
        "manages and stores preferred settings for the user across sessions",
      expiry: "Persistent",
      type: "HTML",
    },
  ],
  marketing: [
    {
      name: "hjActiveViewportIds",
      provider: "Hotjar",
      purpose:
        "This cookie contains an ID string on the current session. This contains non-personal information on what subpages the visitor enters – this information is used to optimize the visitor's experience.",
      expiry: "Persistent",
      type: "HTML",
    },
    {
      name: "hjViewportId",
      provider: "Hotjar",
      purpose:
        "Saves the user's screen size in order to adjust the size of images on the website.",
      expiry: "Session",
      type: "HTML",
    },

    {
      name: "__hssc",
      provider: "Hubspot",
      purpose:
        "Identifies if the cookie data needs to be updated in the visitor's browser.",
      expiry: "0 day",
      type: "HTTP",
    },
    {
      name: "__hssrc",
      provider: "Hubspot",
      purpose:
        "Used to recognise the visitor's browser upon reentry on the website.",
      expiry: "Session",
      type: "HTTP",
    },
    {
      name: "__hstc",
      provider: "Hubspot",
      purpose:
        "Sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.",
      expiry: "179 days",
      type: "HTTP",
    },
    {
      name: "_ga",
      provider: "Google",
      purpose:
        "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
      expiry: "2 years",
      type: "HTTP",
    },
    {
      name: "_ga_#",
      provider: "Google",
      purpose:
        "Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit. ",
      expiry: "2 years",
      type: "HTTP",
    },
    {
      name: "_hjSession_#",
      provider: "Hotjar",
      purpose:
        "Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.",
      expiry: "0 day",
      type: "HTTP",
    },
    {
      name: "_hjSessionUser_#",
      provider: "Hotjar",
      purpose:
        "Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.",
      expiry: "1 year",
      type: "HTTP",
    },
    {
      name: "hubspotutk",
      provider: "Hubspot",
      purpose:
        "Sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.",
      expiry: "179 days",
      type: "HTTP",
    },
  ],
};

export const getLink = (provider: string) => {
  switch (provider) {
    case "Google":
      return "https://business.safety.google/privacy/";
    case "Hubspot":
      return "https://legal.hubspot.com/privacy-policy";
    case "Hotjar":
      return "https://www.hotjar.com/legal/policies/privacy/";
    default:
      return "";
  }
};

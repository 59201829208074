export const colors = {
  // BRAND:
  tangerine: "#FF6000",
  pomelo: "#F7EBE8",
  royalBlue: "#263548",
  midnightBlue: "#485262",
  causeway: "#6C788C",
  wisteria: "#B1ACC4",

  stargaze: "#495275", // dark secondary main
  larkspur: "#666E8B", // dark secondary light

  inkjet: "#44576D", // dark tertiary main
  stratus: "#8390A1", // dark tertiary light

  // INFO:
  denim: "#2D5ae0", // main
  windstormBlue: "#1b3fa7", // dark
  skyBlue: "#EAF4FC", // light

  vapor: "#BEDBFB", // darkmode light

  // SUCCESS:
  aspenGreen: "#1e8718", //  dark (hover)
  energiseGreen: "#44AC3E", // main (background)
  mistyLakeGreen: "#E2EEE1", // light

  grinch: "#29AB21", // dark mode dark
  pesticide: "#35C52C", // dark mode main
  ginko: "#BDE3BB", // dark mode light

  //ERROR:
  russianRed: "#8b1010", // dark
  picassoRed: "#B91C1C", // main
  chalkRed: "#F2D5D5", // light

  puckerup: "#D81818", // darkmode dark
  romeo: "#F50F0F", // darkmode main
  rumour: "#FF8282", // darkmode light

  //WARNING:
  burntToast: "#dc9b10", // dark
  submarineYellow: "#f9be40", // main
  vanillaCreamYellow: "#FFE8B5", // light

  mustard: "#E9A40F", // dd
  mango: "#F9BE40", // dm
  butter: "#FFE8B5", //dl

  // DISABLED / GREYS:
  cerebralGrey: "#CCC",
  quickSilver: "#E5E6E9",
  ghost: "#F6F6F9",

  // NEUTRALS
  pitchBlack: "#000",
  smokeGrey: "#7D848F",
  slabGrey: "#9F9F9F",
  mist: "#EBECF0",
  moonWhite: "#F9F9FC",
  paperWhite: "#FFFFFF",

  // SPECIALS - BRIGHT:
  heirloomBlue: "#518BD3",
  amethyst: "#8251D3",
  thistle: "#A251D3",
  orchid: "#C951D3",
  phloxPink: "#D35197",
  coral: "#D35151",
  pumpkinSpiceLatte: "#D38051",
  goldenSnitch: "#ffe247",
  lizardGreen: "#9AD351",
  lawn: "#51D35E",
  peppermint: "#51D3BC",
  glacierBlue: "#51B4D3",

  // SPECIALS - PASTELS:
  frost: "#C8daf1",
  grapeSoda: "#B29ED3",
  lavenderHaze: "#6F68A3",
  petunia: "#BF9ED3",
  mulberry: "#CF9ED3",
  berry: "#D39EBB",
  rosewood: "#D39E9E",
  chestnut: "#D3B19E",
  solsticeYellow: "#D3CB9E",
  lawnGreen: "#9ECE9B",
  spring: "#BCD39E",
  fennel: "#9ED3A3",
  menthol: "#9ED3C9",
  saltSpray: "#9EC6D3",

  // old colors:
  white: "#ffffff",
  primary: "#263548",
  secondary: "#f2682a",
  link: "#0273ff",
  green: "#20C743",

  shadows: {
    primary: "rgba(60, 64, 67, 0.3)",
    secondary: "rgba(60, 64, 67, 0.15)",
  },

  // DARK
  styx: "#131924",
  squidInk: "#151F32",
  quill: "#222B49",
  deploymentGreen: "#35C52C",
  pipelineBlue: "#3E8BFF",

  borders: {
    darkGrey: "#9F9F9F",
    lightGrey: "#c8c8c8",
    primary: "#c4c4c4",
    textAreaLight: "#0000003b",
    textAreaDark: "#ffffff3b",
  },

  //SVGs (should be deleted later or integrated with the theme properly):
  svg: {
    f5: "#f5f5f5",
    softGrey: "#dce0e6",
    mediumGrey: "#8692a8",
    brownSkin: "#a06162",
    pinkSkin: "#ffb9b9",
    black: "#000000",
    widgetBackground: "#141923",
    celebralGrey: "#cccccc",
    starDust: "#354a65",
    darkenScreen: "#f3f3fb",
  },
};

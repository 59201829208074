import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { DeploymentVersionRoutesContainer } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/:versionName";
import { DeploymentVersionDefault } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/DeploymentVersionDefault";
import { routes } from "routes";

import { Route } from "components/utilities";

import { DeploymentVersionCreate } from "./DeploymentVersionCreate";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .deployments[":deploymentName"](":deploymentName");

export const DeploymentVersionsPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, deploymentName } =
    useParams<{
      organizationName: string;
      projectName: string;
      deploymentName: string;
    }>();

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .deployments[":deploymentName"](deploymentName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <Switch>
        <Route
          path={basePath.versions.create.index()}
          component={DeploymentVersionCreate}
        />
        <Route
          path={basePath.versions[":versionName"](":versionName").index()}
          component={DeploymentVersionRoutesContainer}
        />
        <Route
          path={basePath.versions.index()}
          component={DeploymentVersionDefault}
        />
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type BucketDetailProvider =
  typeof BucketDetailProvider[keyof typeof BucketDetailProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BucketDetailProvider = {
  ubiops: "ubiops",
  google_cloud_storage: "google_cloud_storage",
  amazon_s3: "amazon_s3",
  azure_blob_storage: "azure_blob_storage",
} as const;

import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { IlluRestrictedPermissions } from "assets/images/IlluRestrictedPermissions";
import { DetailsContainer } from "components/molecules/PageLayout";
import {
  ENVIRONMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { useEnvironmentDelete } from "libs/data/customized/environments/useEnvironmentDelete";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useEnvironmentsUsage } from "libs/data/endpoints/environments/environments";
import { useLogsUrl } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  DeleteDialog,
  Dialog,
  DialogWarningHeader,
  PageTabs,
  PrimaryButton,
  SecondaryButton,
  StatusIcon,
  TextButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { EnvironmentUsage } from "libs/data/models";
import type { EnvironmentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/types";
import type { ReactNode } from "react";

export const EnvironmentDetails = ({ children }: { children: ReactNode }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const history = useHistory();
  const { organizationName, projectName, environmentName } =
    useParams<EnvironmentDetailsRouteParams>();

  const currentOrganization = useGetCurrentOrganization();

  const { data: environmentUsage } = useEnvironmentsUsage(
    projectName,
    environmentName
  );

  const rootUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).environments.custom,
    [organizationName, projectName]
  );
  const baseUrl = useMemo(
    () => rootUrl[":environmentName"](environmentName),
    [rootUrl, environmentName]
  );
  const deleteEnvironment = useEnvironmentDelete(projectName);

  const handleDelete = async (name: string) => {
    await deleteEnvironment(name);
    history.push(rootUrl.index());
  };

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(ENVIRONMENT_PERMISSIONS),
    environmentName,
    "environment"
  );
  const [projectPermissions] = useGetPermissions();

  const logsUrl = useLogsUrl({
    queryParameters: {
      environment_name: environmentName,
    },
  });

  const tabs = [
    {
      link: baseUrl.details.general.index(),
      label: "General",
    },
    {
      link: baseUrl.details.revisions.index(),
      label: "Revisions",
    },
  ];

  const columns = [
    {
      title: "Name",
      width: "25%",
      field: "deployment",
      nowrap: true,
      render: ({ deployment }: EnvironmentUsage) => deployment,
    },
    {
      title: "Version",
      width: "10%",
      field: "version",
      nowrap: true,
      render: ({ version }: EnvironmentUsage) => version,
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      nowrap: true,
      render: ({ status }: EnvironmentUsage) => (
        <StatusIcon status={status as string} label={status} />
      ),
    },
  ];

  return (
    <DetailsContainer
      title={environmentName}
      actions={
        <ButtonGroup>
          <TextButton
            color="secondary"
            disabled={
              !currentPermissions[ENVIRONMENT_PERMISSIONS["update"]] ||
              currentOrganization?.status !== "active"
            }
            onClick={() => setIsEditDialogOpen(true)}
            startIcon={<Edit />}
          >
            Edit
          </TextButton>
          <TextButton
            color="secondary"
            disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
            link={logsUrl}
            startIcon={<LogsIcon />}
          >
            Logs
          </TextButton>
          <TextButton
            color="secondary"
            disabled={
              !projectPermissions[ENVIRONMENT_PERMISSIONS["create"]] ||
              currentOrganization?.status !== "active"
            }
            link={baseUrl.duplicate.index()}
            startIcon={<Duplicate />}
          >
            Duplicate
          </TextButton>
          <TextButton
            disabled={!currentPermissions[ENVIRONMENT_PERMISSIONS["delete"]]}
            onClick={() => setIsDeleteDialogOpen(true)}
            startIcon={<Trash />}
          >
            Delete
          </TextButton>
        </ButtonGroup>
      }
    >
      <PageTabs tabs={tabs}>{children}</PageTabs>
      <DeleteDialog
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={() => handleDelete(environmentName)}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete environment <b>{environmentName}</b>?
      </DeleteDialog>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        Header={
          <DialogWarningHeader title="Do you want to edit this environment?" />
        }
        fullWidth
        Actions={
          <>
            <SecondaryButton
              onClick={() => setIsEditDialogOpen(false)}
              style={{ marginRight: 14 }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={() => history.push(baseUrl.edit.index())}>
              Continue to edit
            </PrimaryButton>
          </>
        }
      >
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <Box display="flex" justifyContent="center" marginBottom={4}>
            <IlluRestrictedPermissions />
          </Box>
          {environmentUsage?.length ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              gap={2}
            >
              {explanations.environments.editEnvironmentDialogText.message}
              <BaseTable
                data={environmentUsage}
                columns={columns as unknown as BaseColumn[]}
                defaultPageSize={5}
                rowsPerPageOptions={[]}
              />
            </Box>
          ) : null}

          <Typography>
            Are you sure you want to edit this environment?
          </Typography>
        </Box>
      </Dialog>
    </DetailsContainer>
  );
};

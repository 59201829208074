import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, Grid, FormHelperText } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNodes } from "reactflow";

import { spacing } from "assets/styles/theme";
import {
  AutoCompleteMultipleSelect,
  AutoCompleteSelect,
} from "components/atoms/AutoCompleteSelect";
import { explanations } from "libs/utilities/explanations";
import { fieldTypesLabels } from "libs/utilities/labels-mapping";

import { Divider, PrimaryButton } from "components/atoms";

import { areFieldTypesCompatible } from "./utils";
import { NodeTypes } from "../types";

import type { NodeDataType } from "../types";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type {
  AttachmentFieldsList,
  AttachmentsList,
  AttachmentSourcesList,
  DeploymentOutputFieldCreate,
  PipelineVersionObjectList,
} from "libs/data/models";
import type { BaseSyntheticEvent } from "react";

export type EdgeFormProps = {
  defaultSources?: AttachmentSourcesList[];
  defaultSourceObjects?: PipelineVersionObjectList[];
  defaultDestinationObject: PipelineVersionObjectList;
  onSubmit: (data: Omit<AttachmentsList, "id">) => void | Promise<void>;
};

type EdgeFormDataType = {
  sourceObjects: PipelineVersionObjectList[];
  destinationObject: PipelineVersionObjectList;
  mapping: {
    destination_field_name: string;
    source: (DeploymentOutputFieldCreate & { source: string }) | undefined;
  }[];
};

const getDefaultValues = (
  defaultSources: AttachmentSourcesList[],
  defaultSourceObjects: PipelineVersionObjectList[],
  defaultDestinationObject: PipelineVersionObjectList
): EdgeFormDataType => ({
  sourceObjects: defaultSourceObjects,
  destinationObject: defaultDestinationObject,
  mapping: (defaultDestinationObject.input_fields || []).map((inputField) => {
    const destinationFieldName = inputField.name;

    const sourceMapping = defaultSources.find(
      (source) =>
        !!source.mapping?.find(
          (mappingItem) =>
            mappingItem.destination_field_name === destinationFieldName
        )
    );

    const sourceName = sourceMapping?.source_name;
    const sourceObject = defaultSourceObjects.find(
      (sourceObject) => sourceObject.name === sourceName
    );
    const sourceOutputField = sourceObject?.output_fields?.find((outputField) =>
      sourceMapping?.mapping?.find(
        (mappingItem) =>
          mappingItem.source_field_name === outputField.name &&
          mappingItem.destination_field_name === destinationFieldName
      )
    );

    const source = !sourceName
      ? undefined
      : {
          ...sourceOutputField,
          source: sourceName,
        };

    return {
      destination_field_name: destinationFieldName,
      source: source,
    };
  }) as EdgeFormDataType["mapping"],
});

export const EdgeForm = ({
  defaultSources = [],
  defaultSourceObjects = [],
  defaultDestinationObject,
  onSubmit,
}: EdgeFormProps) => {
  const nodes = useNodes<NodeDataType>();
  const [selectedMapping, setSelectedMapping] = useState(
    getDefaultValues(
      defaultSources,
      defaultSourceObjects,
      defaultDestinationObject
    ).mapping
  );
  const [selectedSourceIds, setSelectedSourceIds] = useState<string[]>(
    getDefaultValues(
      defaultSources,
      defaultSourceObjects,
      defaultDestinationObject
    ).sourceObjects?.map(({ name }) => name)
  );
  const { errors, register, handleSubmit, getValues, setValue } =
    useForm<EdgeFormDataType>({
      mode: "onBlur",
      defaultValues: getDefaultValues(
        defaultSources,
        defaultSourceObjects,
        defaultDestinationObject
      ),
    });

  const isDestinationStructured =
    !!defaultDestinationObject &&
    (defaultDestinationObject?.input_type === "structured" ||
      defaultDestinationObject?.reference_type === NodeTypes.operator);

  const pipelineDiagramObjects = nodes
    .filter((node) => node.type !== NodeTypes.diamond)
    .map((node) => node.data.pipelineObject);

  const selectedSourceObjects = pipelineDiagramObjects.filter((obj) =>
    selectedSourceIds.includes(obj.name)
  );

  const destinationObjectsOptions = pipelineDiagramObjects.filter(
    (object) => object.name !== NodeTypes.pipeline_start
  );
  const sourceObjectsOptions = pipelineDiagramObjects.filter(
    (object) =>
      object.name !== defaultDestinationObject?.name &&
      object.name !== NodeTypes.pipeline_end
  );

  // When user changes the source objects,
  // we should remove the fields that are related to the deleted object
  useEffect(() => {
    const sourceNames = (selectedSourceObjects || []).map(
      (sourceObject) => sourceObject.name
    );

    const { mapping = [] } = getValues();
    mapping.forEach((mappingItem, index) => {
      if (mappingItem.source?.source) {
        if (!sourceNames.includes(mappingItem.source?.source)) {
          setValue(`mapping[${index}].source`, undefined);
        }
      }
    });
  }, [getValues, selectedSourceObjects, setValue]);

  const handleAttachmentCreate = (
    _data: EdgeFormDataType,
    e?: BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    const sourceObjects = selectedSourceIds.map((id) =>
      sourceObjectsOptions.find((option) => option.name === id)
    );

    const initialSources: Record<string, AttachmentFieldsList[]> =
      sourceObjects?.reduce(
        (sources, sourceObject) => ({
          ...sources,
          [sourceObject?.name || ""]: [],
        }),
        {}
      ) || {};

    const mappingsBySources = (selectedMapping || []).reduce(
      (sources, mappingItem) => {
        if (!mappingItem.source) {
          return sources;
        }

        return {
          ...sources,
          [mappingItem.source.source]: [
            ...(sources[mappingItem.source.source] || []),
            {
              destination_field_name: mappingItem.destination_field_name,
              source_field_name: mappingItem.source.name,
            },
          ],
        };
      },
      initialSources
    );

    const sources = Object.entries(mappingsBySources).map(
      ([source_name, mapping]) => ({
        source_name,
        mapping,
      })
    );

    onSubmit({
      destination_name: defaultDestinationObject.name,
      sources: sources,
    });
  };

  const setMapping = (
    source: DeploymentOutputFieldCreate & { source: string },
    destination: string,
    i: number
  ) => {
    const newValue = [...(selectedMapping || [])];
    newValue[i] = {
      ...(newValue?.[i] || {}),
      source,
      destination_field_name: destination,
    };
    setSelectedMapping(newValue);
  };

  const sourceOptions = useMemo(
    () =>
      sourceObjectsOptions?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [],
    [sourceObjectsOptions]
  );

  const sourceOptionsValue = useMemo(
    () =>
      selectedSourceIds.map(
        (name) =>
          sourceOptions.find((option) => option.value === name) || {
            label: "",
            value: "",
          }
      ),
    [selectedSourceIds, sourceOptions]
  );

  return (
    <form onSubmit={handleSubmit(handleAttachmentCreate)}>
      <Box
        padding={spacing[16]}
        display="flex"
        flexDirection="column"
        maxWidth="100%"
      >
        <Box display="flex" flexDirection="column" margin={`${spacing[16]} 0`}>
          <Box display="flex" justifyContent="space-around" mb={spacing[16]}>
            <Typography variant="h5" color="textPrimary">
              Source object(s):
            </Typography>
            <ArrowForwardIcon color="secondary" />
            <Typography variant="h5" color="textPrimary">
              Destination object:
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box width="100%">
              <AutoCompleteMultipleSelect
                label="Source object(s)"
                options={sourceOptions}
                value={sourceOptionsValue || []}
                onChange={(change) => {
                  const newSourceNames = change?.map(({ label }) => label);
                  setSelectedMapping(
                    selectedMapping.filter((map) =>
                      newSourceNames?.includes(map.source?.source ?? "")
                    )
                  );
                  setSelectedSourceIds(
                    change?.map(({ value }) => value as string) || []
                  );
                }}
              />
            </Box>
            <Box width="100%">
              <AutoCompleteSelect
                label="Destination object"
                options={[
                  defaultDestinationObject.name as unknown as AutocompleteSelectOption,
                ]}
                disabled
                value={
                  defaultDestinationObject.name as unknown as AutocompleteSelectOption
                }
                onChange={() => {}}
                styles={{
                  marginTop: spacing[12],
                }}
              />
              {!destinationObjectsOptions.length && (
                <FormHelperText id="no-destination-objects" error>
                  No destination objects available for these source objects
                </FormHelperText>
              )}
            </Box>
          </Box>
        </Box>

        {isDestinationStructured && !!selectedSourceObjects?.length && (
          <Box>
            <Typography variant="h4" style={{ marginBottom: spacing[16] }}>
              Mapping
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {explanations.pipelines.connections.mapping}
            </Typography>
            <Divider marginY={2} />

            <Grid container>
              <Grid item xs={2}>
                <Typography variant="h6" color="textPrimary">
                  Type
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" color="textPrimary">
                  Source fields
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Typography variant="h6" color="textPrimary">
                  Destination fields
                </Typography>
              </Grid>
            </Grid>

            {defaultDestinationObject?.input_fields?.map((inputField, key) => {
              const name = `mapping[${key}]`;
              const sourceFieldName = `${name}.source`;
              // @ts-ignore
              const sourceFieldError = errors?.[sourceFieldName];
              const destinationFieldName = `${name}.destination_field_name`;
              const options = (selectedSourceObjects || []).map((source) => ({
                label: source.name,
                options: (source.output_fields || [])
                  .filter((field) =>
                    areFieldTypesCompatible(
                      inputField.data_type,
                      field.data_type
                    )
                  )
                  .map((field) => ({
                    ...field,
                    source: source.name,
                  })),
              }));

              const optionsWithGroup = options
                .map(({ label, options }) =>
                  options.map(({ name, ...data }) => ({
                    name,
                    label: name,
                    value: name,
                    group: label,
                    groupLength: options.length,
                    ...data,
                  }))
                )
                .flat();
              const currentMapping = selectedMapping[key]?.source;
              const currentOption = optionsWithGroup.find(
                ({ name, source }) =>
                  name === currentMapping?.name &&
                  source === currentMapping?.source
              );

              const defaultOption =
                optionsWithGroup?.find(
                  ({ name }) => name === inputField.name
                ) || optionsWithGroup[0];

              if (!currentOption && defaultOption) {
                setMapping(defaultOption, inputField.name, key);
              }

              return (
                <Grid
                  key={key}
                  container
                  alignItems="center"
                  style={{ marginTop: spacing[8] }}
                >
                  <Grid item xs={2}>
                    <Typography variant="body1">
                      {
                        // @ts-ignore
                        fieldTypesLabels[inputField.data_type]
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <AutoCompleteSelect
                      label="Source field"
                      groupBy={(option) => option.group || ""}
                      options={optionsWithGroup}
                      onChange={(change: any) => {
                        change && setMapping(change, inputField.name, key);
                      }}
                      value={currentOption ?? defaultOption}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ArrowForwardIcon color="secondary" />
                  </Grid>

                  <Grid item xs={4}>
                    <Box display="flex">
                      <Typography variant="body1">{inputField.name}</Typography>
                      <input
                        hidden
                        ref={register}
                        name={destinationFieldName}
                        value={inputField.name}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormHelperText
                      id={sourceFieldName}
                      error={!!sourceFieldError}
                      hidden={!sourceFieldError}
                    >
                      <Typography variant="body1">
                        {sourceFieldError?.message}
                      </Typography>
                    </FormHelperText>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent="center">
        <PrimaryButton type="submit" disabled={selectedSourceIds.length === 0}>
          Confirm
        </PrimaryButton>
      </Box>
    </form>
  );
};

import DownloadIcon from "@mui/icons-material/CloudDownloadRounded";
import { Card, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import { downloadZip } from "libs/utilities/download-helper";

import { SecondaryButton } from "components/atoms";
import { SimpleTabs } from "components/organisms";

type MultiCodeBlockProps = {
  codeBlocks: { title: string; language: string; codeBlock: string }[];
  withDownload?: boolean;
};

const MultiCodeBlock = ({ codeBlocks, withDownload }: MultiCodeBlockProps) => {
  const downloadCode = useCallback((codeSnippet: any, language: string) => {
    const { file: fileHelper, name: fileNameHelper } =
      getDeploymentFileHelper(language);
    const extension = getFileExtension(language);
    downloadZip([
      { name: fileNameHelper, content: fileHelper },
      { name: `deployment.${extension}`, content: codeSnippet },
    ]);
  }, []);

  const tabsContents = codeBlocks.map(({ title, language, codeBlock }) => ({
    title: title,
    content: (
      <>
        <CopyBlock
          // @ts-ignore
          language={language}
          text={codeBlock}
          showLineNumbers
          theme={dracula}
          codeBlock
        />
        {withDownload && (
          <Tooltip title="Download the generated deployment package template">
            <SecondaryButton
              size="small"
              startIcon={<DownloadIcon />}
              onClick={() => downloadCode(codeBlock, language)}
              style={{
                float: "right",
                margin: "5px",
              }}
            >
              Download code
            </SecondaryButton>
          </Tooltip>
        )}
      </>
    ),
  }));

  return (
    <Card variant="outlined">
      <SimpleTabs tabs={tabsContents} />
    </Card>
  );
};

const getFileExtension = (language: string) => {
  switch (language) {
    case "python":
      return "py";

    case "r":
      return "R";

    default:
      return;
  }
};

const getDeploymentFileHelper = (language: string) => {
  switch (language) {
    case "python":
      return {
        name: "requirements.txt",
        file: `# This file can contain package requirements for the deployment to be installed via Python pip. 
# Packages defined here will be installed with your deployment automatically.
#
# Example:
# requests==2.24.0`,
      };

    default:
      return { name: "", file: "" };
  }
};

export default MultiCodeBlock;

import styled from "@emotion/styled";
import { Chip as MuiChip, Link, Typography } from "@mui/material";

import { NavLink } from "components/atoms";

import type { ChipProps } from "@mui/material";

const Chip = styled(MuiChip)<{ backgroundColor: string; fontColor?: string }>`
  color: ${({ fontColor }) => fontColor};
  font-weight: 200;
  background-color: ${({ backgroundColor }) => backgroundColor};
  &.MuiChip-clickable {
    &:hover,
    &:focus {
      background-color: ${({ backgroundColor }) => backgroundColor};
      filter: brightness(110%);
    }
  & .MuiChip-deleteIcon {
    color: ${({ fontColor }) => fontColor};
  }
`;

interface MonitoringChipProps extends Omit<ChipProps, "color"> {
  itemId?: string;
  itemLabel?: string;
  objectName?: string;
  versionName?: string;
  link?: string;
  color: string;
}

const MonitoringChip = ({
  itemId = "",
  itemLabel = "",
  objectName = "",
  versionName = "",
  link = "",
  color,
  ...props
}: MonitoringChipProps) => {
  return (
    <Chip
      size="small"
      backgroundColor={color}
      key={itemId}
      label={
        objectName && versionName ? (
          <Link
            component={NavLink}
            to={link}
            variant="h6"
            color="inherit"
            underline="none"
          >
            <Typography variant="subtitle1" component="span" gutterBottom>
              {objectName}
            </Typography>
            <Typography component="span"> - </Typography>
            <Typography variant="subtitle1" component="span">
              {versionName}
            </Typography>
          </Link>
        ) : (
          <Typography variant="subtitle1" component="span" gutterBottom>
            {itemLabel}
          </Typography>
        )
      }
      clickable
      {...props}
    />
  );
};

export default MonitoringChip;

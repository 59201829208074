import { useTheme } from "@mui/material";

export const OperatorCountMany = () => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1767_19395)">
        <circle
          cx="40.3902"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
        <path
          d="M18.916 29.1926H42.8542V43.2739H18.916"
          stroke="white"
          strokeWidth="3"
        />
        <path d="M35.1094 29.1926V43.978" stroke="white" strokeWidth="3" />
        <path d="M26.6611 29.897V43.2742" stroke="white" strokeWidth="3" />
        <path
          d="M50.6032 43.411L51.2505 39.764H47.6035L47.9226 37.9405H51.5696L52.5361 32.47H48.8891L49.2082 30.6465H52.8552L53.5025 26.9995H55.3261L54.6787 30.6465H60.1492L60.7966 26.9995H62.6201L61.9727 30.6465H65.6197L65.3006 32.47H61.6536L60.6871 37.9405H64.3342L64.015 39.764H60.368L59.7207 43.411H57.8972L58.5445 39.764H53.074L52.4267 43.411H50.6032ZM54.3596 32.47L53.3931 37.9405H58.8636L59.8301 32.47H54.3596Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1767_19395"
          x="0.834961"
          y="0.326172"
          width="79.1104"
          height="79.1106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1767_19395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1767_19395"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

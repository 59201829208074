/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  InstanceListPaginated,
  InstancesListParams,
  InstanceDetail,
  InstanceEventPaginated,
  InstanceEventsListParams,
  InstanceTypeGroupListPaginated,
  InstanceTypeGroupsListParams,
  InstanceTypeGroupList,
  InstanceTypeGroupCreateBody,
  InstanceTypeGroupUsagePaginated,
  InstanceTypeGroupsUsageParams,
  InstanceTypeListPaginated,
  InstanceTypesListParams,
  ProjectInstanceListPaginated,
  ProjectInstancesListParams,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List the instances running for a deployment version. The results are paginated, use `cursor` and `limit` parameters to go between pages.

### Optional Parameters
- `status`: Filter on status of the instance. Separate multiple statuses with a comma (,). This parameter should be given as query parameter.

### Response Structure
A list of instance details
- `id`: Unique identifier for the instance (UUID)
- `status`: Status of the instance. It can be one of the following: pending, initialising, running, stopping.
- `time_created`: The date when the instance was created
- `time_updated`: The date when the instance was last updated
- `instance_type`: A dictionary containing instance type details of the instance. If the instance has no instance type set yet, it is null.
  - `id`: UUID of the instance type
  - `name`: Name of the instance type
  - `display_name`: Display name of the instance type

#### Response Examples
```
{
  "previous": null,
  "next": null,
  "results": [
    {
      "id": "33a0541a-11a5-44f7-8722-b7428d1faf80",
      "status": "pending",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "time_updated": "2024-05-01T08:32:14.876451Z",
      "instance_type": null
    },
    {
      "id": "12d376cf-11ee-4bec-b52a-d76f4daf4314",
      "status": "running",
      "time_created": "2024-05-01T07:13:13.973651Z",
      "time_updated": "2024-05-01T08:32:14.876451Z",
      "instance_type": {
        "id": "fe1485df-f4d8-466e-913e-06d3569e4b39",
        "name": "512mb",
        "display_name": "512 MB",
    }
  ]
}
```

 * @summary List instances for deployment versions
 */
export const instancesList = (
  projectName: string,
  deploymentName: string,
  version: string,
  params?: InstancesListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceListPaginated>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances`,
      method: "get",
      params,
    },
    options
  );
};

export const getInstancesListKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  params?: InstancesListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances`,
  ...(params ? [params] : []),
];

export type InstancesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof instancesList>>
>;
export type InstancesListQueryError = ErrorType<unknown>;

export const useInstancesList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  params?: InstancesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instancesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getInstancesListKey(projectName, deploymentName, version, params)
        : null);
  const swrFn = () =>
    instancesList(projectName, deploymentName, version, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get the details of an instance running for a deployment version

### Response Structure
- `id`: Unique identifier for the instance (UUID)
- `status`: Status of the instance. It can be one of the following: pending, initialising, running, stopping.
- `time_created`: The date when the instance was created
- `time_updated`: The date when the instance was last updated
- `instance_type`: A dictionary containing instance type details of the instance. If the instance has no instance type set yet, it is null.
  - `id`: UUID of the instance type
  - `name`: Name of the instance type
  - `display_name`: Display name of the instance type
- `node`: A dictionary containing the node details of the instance
  - `ipv4_address`: IPv4 address of the node
  - `ipv6_address`: IPv6 address of the node
- `node_pool`: A dictionary containing the node pool details of the instance. If the instance has no node pool set yet, it is null.
  - `cluster`: A dictionary containing the cluster details of the node pool
    - `type`: Type of the cluster
- `deployment`: Name of the deployment for which the instance is running for
- `version`: Name of the version for which the instance is running for

#### Response Examples
```
{
  "id": "33a0541a-11a5-44f7-8722-b7428d1faf80",
  "status": "pending",
  "time_created": "2024-05-01T08:32:14.876451Z",
  "time_updated": "2024-05-01T08:32:14.876451Z",
  "instance_type": null,
  "node": {
    "ipv4_address": null,
    "ipv6_address": null
  },
  "node_pool": null,
  "deployment": "deployment-1",
  "version": "v1"
}
```

 * @summary Get instance for deployment versions
 */
export const instancesGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances/${instanceId}`,
      method: "get",
    },
    options
  );
};

export const getInstancesGetKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances/${instanceId}`,
];

export type InstancesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof instancesGet>>
>;
export type InstancesGetQueryError = ErrorType<unknown>;

export const useInstancesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof instancesGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && instanceId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getInstancesGetKey(projectName, deploymentName, version, instanceId)
        : null);
  const swrFn = () =>
    instancesGet(
      projectName,
      deploymentName,
      version,
      instanceId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the events for an instance. The results are paginated, use `cursor` and `limit` parameters to go between pages.

### Response Structure
A list of event details
- `id`: Unique identifier for the event (UUID)
- `time_created`: The date when the event was created
- `description`: Description of the event

#### Response Examples
```
{
  "previous": null,
  "next": null,
  "results": [
    {
      "id": "d2727027-b681-43f1-be84-5f7bc7cbec4f",
      "time_created": "2023-05-01T16:25:11.195716Z",
      "description": "Instance status changed to initialising"
    },
    {
      "id": "36489db5-0fa8-467a-b2d3-8edcb7479726",
      "time_created": "2023-05-01T16:25:05.987451Z",
      "description": "Deployed on node pool 'Google CPU'"
    },
    {
      "id": "08c8bf93-4ece-4d99-9ecf-4f19d6703a58",
      "time_created": "2023-05-01T16:24:03.987451Z",
      "description": "Attempting to deploy with instance type '2048mb' on node pool 'Google CPU' (GCP cluster)"
    },
    {
      "id": "63ef2820-71ba-4a90-ba4e-a57035d1350f",
      "time_created": "2023-05-01T16:23:15.456812Z",
      "description": "Instance created"
    }
  ]
}
```

 * @summary List events for instances
 */
export const instanceEventsList = (
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string,
  params?: InstanceEventsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceEventPaginated>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances/${instanceId}/events`,
      method: "get",
      params,
    },
    options
  );
};

export const getInstanceEventsListKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string,
  params?: InstanceEventsListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/instances/${instanceId}/events`,
  ...(params ? [params] : []),
];

export type InstanceEventsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof instanceEventsList>>
>;
export type InstanceEventsListQueryError = ErrorType<unknown>;

export const useInstanceEventsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  instanceId: string,
  params?: InstanceEventsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instanceEventsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && instanceId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getInstanceEventsListKey(
            projectName,
            deploymentName,
            version,
            instanceId,
            params
          )
        : null);
  const swrFn = () =>
    instanceEventsList(
      projectName,
      deploymentName,
      version,
      instanceId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List instance type groups in a project. The results are paginated, use `cursor` and `limit` parameters to go between pages.

### Response Structure
A list of instance type groups
- `id`: Unique identifier for the instance type group (UUID)
- `name`: Name of the instance type group
- `time_created`: The date when the instance type group was created
- `time_updated`: The date when the instance type group was last updated
- `instance_types`: A list of instance types that are in this group
  - `id`: Unique identifier for the instance type (UUID)
  - `time_created`: The date when the instance type was created
  - `name`: Name of the instance type
  - `display_name`: Readable name of the instance type
  - `cpu`: Float indicating vCPU allocation
  - `memory`: Float indicating memory allocation (Mi)
  - `storage`: Float indicating the maximum storage that can be used (MB)
  - `accelerator`: Float indicating number of GPU cores
  - `credit_rate`: Credits used per hour
  - `dedicated_node`: A boolean indicating whether an entire node is dedicated to this instance type
  - `node_pool`: A dictionary containing the node pool details of the instance type
    - `cluster`: A dictionary containing the cluster details of the node pool
      - `type`: Type of the cluster
  - `priority`: Priority of the instance type in the group. The lower the value, the more priority the instance type has.
  - `schedule_timeout`: Timeout in seconds that indicates how long to wait until the instance type is scheduled.

#### Response Examples
```
{
  "previous": null,
  "next": null,
  "results": [
    {
      "id": "6e1b5dcb-cb35-4fa6-9120-cfc1ba0c5f07",
      "name": "high-memory-instance-type-group",
      "time_created": "2024-05-05T12:14:12.081753Z",
      "time_updated": "2024-05-05T12:14:12.081753Z",
      "instance_types": [
        {
          "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
          "time_created": "2024-05-01T08:32:14.876451Z",
          "name": "16384mb",
          "display_name": "16384 MB",
          "cpu": 4,
          "memory": 16384,
          "accelerator": 0,
          "storage": 65536,
          "credit_rate": 16,
          "dedicated_node": false,
          "node_pool": {
            "cluster": {
              "type": "gcp" 
            }
          },
          "priority": 0,
          "schedule_timeout": 3600
        },
        {
          "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
          "time_created": "2024-05-01T08:32:14.876451Z",
          "name": "8192mb",
          "display_name": "8192 MB",
          "cpu": 2,
          "memory": 8192,
          "accelerator": 0,
          "storage": 32768,
          "credit_rate": 8,
          "dedicated_node": false,
          "node_pool": {
            "cluster": {
              "type": "aws" 
            }
          },
          "priority": 1,
          "schedule_timeout": 3600
        }
      ]
    },
    {
      "id": "4bb1a7d8-24b4-49e3-9b63-34fbbf604c35",
      "name": "low-memory-instance-type-group",
      "time_created": "2024-05-06T15:32:49.916572Z",
      "time_updated": "2024-05-06T15:32:49.916572Z",
      "instance_types": [
        {
          "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
          "time_created": "2024-05-01T08:32:14.876451Z",
          "name": "256mb",
          "display_name": "256 MB",
          "cpu": 0.0625,
          "memory": 256,
          "accelerator": 0,
          "storage": 1024,
          "credit_rate": 0.25,
          "dedicated_node": false,
          "node_pool": {
            "cluster": {
              "type": "gcp" 
            }
          },
          "priority": 0,
          "schedule_timeout": 3600
        }
      ]
    }
  ]
}
```

 * @summary List instance type groups
 */
export const instanceTypeGroupsList = (
  projectName: string,
  params?: InstanceTypeGroupsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeGroupListPaginated>(
    {
      url: `/projects/${projectName}/instance-type-groups`,
      method: "get",
      params,
    },
    options
  );
};

export const getInstanceTypeGroupsListKey = (
  projectName: string,
  params?: InstanceTypeGroupsListParams
) => [
  `/projects/${projectName}/instance-type-groups`,
  ...(params ? [params] : []),
];

export type InstanceTypeGroupsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof instanceTypeGroupsList>>
>;
export type InstanceTypeGroupsListQueryError = ErrorType<unknown>;

export const useInstanceTypeGroupsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: InstanceTypeGroupsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instanceTypeGroupsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getInstanceTypeGroupsListKey(projectName, params) : null);
  const swrFn = () =>
    instanceTypeGroupsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an instance type group in a project

### Required Parameters
- `name`: Name of the instance type group
- `instance_types`: A list of dictionaries containing the instance types that should be in the group
  - `id`: ID of the instance type
  - `priority`: Priority of the instance type. The lower the value, the more priority the instance type has.

#### Request Examples
```
{
  "name": "instance-type-group-1",
  "instance_types": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "priority": 0
    },
    {
      "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
      "priority": 1
    }
  ]
}
```

### Response Structure
- `id`: Unique identifier for the created instance type group (UUID)
- `name`: Name of the instance type group
- `time_created`: The date when the instance type group was created
- `time_updated`: The date when the instance type group was last updated
- `instance_types`: A list of instance types that are in this group
  - `id`: Unique identifier for the instance type (UUID)
  - `time_created`: The date when the instance type was created
  - `name`: Name of the instance type
  - `display_name`: Readable name of the instance type
  - `cpu`: Float indicating vCPU allocation
  - `memory`: Float indicating memory allocation (Mi)
  - `storage`: Float indicating the maximum storage that can be used (MB)
  - `accelerator`: Float indicating number of GPU cores
  - `credit_rate`: Credits used per hour
  - `dedicated_node`: A boolean indicating whether an entire node is dedicated to this instance type
  - `node_pool`: A dictionary containing the node pool details of the instance type
    - `cluster`: A dictionary containing the cluster details of the node pool
      - `type`: Type of the cluster
  - `priority`: Priority of the instance type in the group. The lower the value, the more priority the instance type has.
  - `schedule_timeout`: Timeout in seconds that indicates how long to wait until the instance type is scheduled.

#### Response Examples
```
{
  "id": "6e1b5dcb-cb35-4fa6-9120-cfc1ba0c5f07",
  "name": "instance-type-group-1",
  "time_created": "2024-05-05T12:14:12.081753Z",
  "time_updated": "2024-05-05T12:14:12.081753Z",
  "instance_types": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "16384mb",
      "display_name": "16384 MB",
      "cpu": 4,
      "memory": 16384,
      "accelerator": 0,
      "storage": 65536,
      "credit_rate": 16,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "gcp" 
        }
      },
      "priority": 0,
      "schedule_timeout": 3600
    },
    {
      "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "8192mb",
      "display_name": "8192 MB",
      "cpu": 2,
      "memory": 8192,
      "accelerator": 0,
      "storage": 32768,
      "credit_rate": 8,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "aws" 
        }
      },
      "priority": 1,
      "schedule_timeout": 3600
    }
  ]
}
```

 * @summary Create instance type group
 */
export const instanceTypeGroupsCreate = (
  projectName: string,
  instanceTypeGroupCreateBody: InstanceTypeGroupCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeGroupList>(
    {
      url: `/projects/${projectName}/instance-type-groups`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: instanceTypeGroupCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Get the details of an instance type group

### Response Structure
- `id`: Unique identifier for the instance type group (UUID)
- `name`: Name of the instance type group
- `time_created`: The date when the instance type group was created
- `time_updated`: The date when the instance type group was last updated
- `instance_types`: A list of instance types that are in this group
  - `id`: Unique identifier for the instance type (UUID)
  - `time_created`: The date when the instance type was created
  - `name`: Name of the instance type
  - `display_name`: Readable name of the instance type
  - `cpu`: Float indicating vCPU allocation
  - `memory`: Float indicating memory allocation (Mi)
  - `storage`: Float indicating the maximum storage that can be used (MB)
  - `accelerator`: Float indicating number of GPU cores
  - `credit_rate`: Credits used per hour
  - `dedicated_node`: A boolean indicating whether an entire node is dedicated to this instance type
  - `node_pool`: A dictionary containing the node pool details of the instance type
    - `cluster`: A dictionary containing the cluster details of the node pool
      - `type`: Type of the cluster
  - `priority`: Priority of the instance type in the group. The lower the value, the more priority the instance type has.
  - `schedule_timeout`: Timeout in seconds that indicates how long to wait until the instance type is scheduled.

#### Response Examples
```
{
  "id": "6e1b5dcb-cb35-4fa6-9120-cfc1ba0c5f07",
  "name": "high-memory-instance-type-group",
  "time_created": "2024-05-05T12:14:12.081753Z",
  "time_updated": "2024-05-05T12:14:12.081753Z",
  "instance_types": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "16384mb",
      "display_name": "16384 MB",
      "cpu": 4,
      "memory": 16384,
      "accelerator": 0,
      "storage": 65536,
      "credit_rate": 16,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "gcp" 
        }
      },
      "priority": 0,
      "schedule_timeout": 3600
    },
    {
      "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "8192mb",
      "display_name": "8192 MB",
      "cpu": 2,
      "memory": 8192,
      "accelerator": 0,
      "storage": 32768,
      "credit_rate": 8,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "aws" 
        }
      },
      "priority": 1,
      "schedule_timeout": 3600
    }
  ]
}
```

 * @summary Get instance type group
 */
export const instanceTypeGroupsGet = (
  projectName: string,
  instanceTypeGroupId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeGroupList>(
    {
      url: `/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}`,
      method: "get",
    },
    options
  );
};

export const getInstanceTypeGroupsGetKey = (
  projectName: string,
  instanceTypeGroupId: string
) => [`/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}`];

export type InstanceTypeGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof instanceTypeGroupsGet>>
>;
export type InstanceTypeGroupsGetQueryError = ErrorType<unknown>;

export const useInstanceTypeGroupsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  instanceTypeGroupId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instanceTypeGroupsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && instanceTypeGroupId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getInstanceTypeGroupsGetKey(projectName, instanceTypeGroupId)
        : null);
  const swrFn = () =>
    instanceTypeGroupsGet(projectName, instanceTypeGroupId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an instance type group in a project

### Optional Parameters
- `name`: Name of the instance type group
- `instance_types`: A list of dictionaries containing the instance types that should be in the group. Previously added instance types will be removed.
  - `id`: ID of the instance type
  - `priority`: Priority of the instance type. The lower the value, the more priority the instance type has.

#### Request Examples
```
{
  "name": "instance-type-group-1",
  "instance_types": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "priority": 0
    },
    {
      "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
      "priority": 1
    }
  ]
}
```

### Response Structure
- `id`: Unique identifier for the created instance type group (UUID)
- `name`: Name of the instance type group
- `time_created`: The date when the instance type group was created
- `time_updated`: The date when the instance type group was last updated
- `instance_types`: A list of instance types that are in this group
  - `id`: Unique identifier for the instance type (UUID)
  - `time_created`: The date when the instance type was created
  - `name`: Name of the instance type
  - `display_name`: Readable name of the instance type
  - `cpu`: Float indicating vCPU allocation
  - `memory`: Float indicating memory allocation (Mi)
  - `storage`: Float indicating the maximum storage that can be used (MB)
  - `accelerator`: Float indicating number of GPU cores
  - `credit_rate`: Credits used per hour
  - `dedicated_node`: A boolean indicating whether an entire node is dedicated to this instance type
  - `node_pool`: A dictionary containing the node pool details of the instance type
    - `cluster`: A dictionary containing the cluster details of the node pool
      - `type`: Type of the cluster
  - `priority`: Priority of the instance type in the group. The lower the value, the more priority the instance type has.
  - `schedule_timeout`: Timeout in seconds that indicates how long to wait until the instance type is scheduled.

#### Response Examples
```
{
  "id": "6e1b5dcb-cb35-4fa6-9120-cfc1ba0c5f07",
  "name": "instance-type-group-1",
  "time_created": "2024-05-05T12:14:12.081753Z",
  "time_updated": "2024-05-05T12:14:12.081753Z",
  "instance_types": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "16384mb",
      "display_name": "16384 MB",
      "cpu": 4,
      "memory": 16384,
      "accelerator": 0,
      "storage": 65536,
      "credit_rate": 16,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "gcp" 
      },
      "priority": 0,
      "schedule_timeout": 3600
    },
    {
      "id": "64dfc63d-a1fd-41b6-a01f-90caa74a270b",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "8192mb",
      "display_name": "8192 MB",
      "cpu": 2,
      "memory": 8192,
      "accelerator": 0,
      "storage": 32768,
      "credit_rate": 8,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "aws"
        }
      },
      "priority": 1,
      "schedule_timeout": 3600
    }
  ]
}
```

 * @summary Update instance type group
 */
export const instanceTypeGroupsUpdate = (
  projectName: string,
  instanceTypeGroupId: string,
  instanceTypeGroupCreateBody: InstanceTypeGroupCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeGroupList>(
    {
      url: `/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: instanceTypeGroupCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete an instance type group. If the instance type group is referenced by any deployment versions, it cannot be deleted.

 * @summary Delete instance type group
 */
export const instanceTypeGroupsDelete = (
  projectName: string,
  instanceTypeGroupId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
List the deployment versions used by an instance type group

### Response Structure
A list of details of the deployment versions
- `id`: Unique identifier for the deployment version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `instance_type_group_id`: ID of the instance type group
- `instance_type_group_name`: Name of the instance type group

#### Response Examples
```
[
  {
    "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
    "deployment": "deployment-1",
    "version": "version-1",
    "instance_type_group_id": "67615c94-547e-48f2-87a2-f57aca2921c5",
    "instance_type_group_name": "256 MB + 0.0625 vCPU"
  },
  {
    "id": "24f6b80a-08c3-4d52-ac1a-2ea7e70f16a6",
    "deployment": "deployment-1",
    "version": "version-2",
    "instance_type_group_id": "67615c94-547e-48f2-87a2-f57aca2921c5",
    "instance_type_group_name": "256 MB + 0.0625 vCPU"
  }
]
```

 * @summary List usage of instance type group
 */
export const instanceTypeGroupsUsage = (
  projectName: string,
  instanceTypeGroupId: string,
  params?: InstanceTypeGroupsUsageParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeGroupUsagePaginated>(
    {
      url: `/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}/usage`,
      method: "get",
      params,
    },
    options
  );
};

export const getInstanceTypeGroupsUsageKey = (
  projectName: string,
  instanceTypeGroupId: string,
  params?: InstanceTypeGroupsUsageParams
) => [
  `/projects/${projectName}/instance-type-groups/${instanceTypeGroupId}/usage`,
  ...(params ? [params] : []),
];

export type InstanceTypeGroupsUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof instanceTypeGroupsUsage>>
>;
export type InstanceTypeGroupsUsageQueryError = ErrorType<unknown>;

export const useInstanceTypeGroupsUsage = <TError = ErrorType<unknown>>(
  projectName: string,
  instanceTypeGroupId: string,
  params?: InstanceTypeGroupsUsageParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instanceTypeGroupsUsage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && instanceTypeGroupId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getInstanceTypeGroupsUsageKey(
            projectName,
            instanceTypeGroupId,
            params
          )
        : null);
  const swrFn = () =>
    instanceTypeGroupsUsage(
      projectName,
      instanceTypeGroupId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List available instance types in a project. The results are paginated, use `cursor` and `limit` parameters to go between pages.

### Response Structure
A list of instance types
- `id`: Unique identifier for the instance type (UUID)
- `time_created`: The date when the instance type was created
- `name`: Name of the instance type
- `display_name`: Readable name of the instance type
- `cpu`: Float indicating vCPU allocation
- `memory`: Float indicating memory allocation (Mi)
- `storage`: Float indicating the maximum storage that can be used (MB)
- `accelerator`: Float indicating number of GPU cores
- `credit_rate`: Credits used per hour
- `dedicated_node`: A boolean indicating whether an entire node is dedicated to this instance type
- `node_pool`: A dictionary containing the node pool details of the instance type
  - `cluster`: A dictionary containing the cluster details of the node pool
    - `type`: Type of the cluster

#### Response Examples
```
{
  "previous": null,
  "next": null,
  "results": [
    {
      "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "name": "512mb",
      "display_name": "512 MB",
      "cpu": 0.125,
      "memory": 512.0,
      "storage": 2048.0,
      "accelerator": 0,
      "credit_rate": 0.5,
      "dedicated_node": false,
      "node_pool": {
        "cluster": {
          "type": "gcp"
        } 
      }
    }
  ]
}
```

 * @summary List instance types
 */
export const instanceTypesList = (
  projectName: string,
  params?: InstanceTypesListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceTypeListPaginated>(
    { url: `/projects/${projectName}/instance-types`, method: "get", params },
    options
  );
};

export const getInstanceTypesListKey = (
  projectName: string,
  params?: InstanceTypesListParams
) => [`/projects/${projectName}/instance-types`, ...(params ? [params] : [])];

export type InstanceTypesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof instanceTypesList>>
>;
export type InstanceTypesListQueryError = ErrorType<unknown>;

export const useInstanceTypesList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: InstanceTypesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof instanceTypesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getInstanceTypesListKey(projectName, params) : null));
  const swrFn = () => instanceTypesList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the instances running in a project. The results are paginated, use `cursor` and `limit` parameters to go between pages.

### Optional Parameters
- `status`: Filter on status of the instance. Separate multiple statuses with a comma (,). This parameter should be given as query parameter.

### Response Structure
A list of instance details
- `id`: Unique identifier for the instance (UUID)
- `status`: Status of the instance. It can be one of the following: pending, initialising, running, stopping.
- `time_created`: The date when the instance was created
- `time_updated`: The date when the instance was last updated
- `instance_type`: A dictionary containing instance type details of the instance. If the instance has no instance type set yet, it is null.
  - `id`: UUID of the instance type
  - `name`: Name of the instance type
  - `display_name`: Display name of the instance type
- `deployment`: Name of the deployment for which the instance is running for
- `version`: Name of the version for which the instance is running for

#### Response Examples
```
{
  "previous": null,
  "next": null,
  "results": [
    {
      "id": "33a0541a-11a5-44f7-8722-b7428d1faf80",
      "status": "pending",
      "time_created": "2024-05-01T08:32:14.876451Z",
      "time_updated": "2024-05-01T08:32:14.876451Z",
      "instance_type": null,
      "deployment": "deployment-1",
      "version": "v1"
    },
    {
      "id": "12d376cf-11ee-4bec-b52a-d76f4daf4314",
      "status": "running",
      "time_created": "2024-05-01T07:13:13.973651Z",
      "time_updated": "2024-05-01T08:32:14.876451Z",
      "instance_type": {
        "id": "fe1485df-f4d8-466e-913e-06d3569e4b39",
        "name": "512mb",
        "display_name": "512 MB",
      },
      "deployment": "deployment-2",
      "version": "v1"
    }
  ]
}
```

 * @summary List instances for projects
 */
export const projectInstancesList = (
  projectName: string,
  params?: ProjectInstancesListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectInstanceListPaginated>(
    { url: `/projects/${projectName}/instances`, method: "get", params },
    options
  );
};

export const getProjectInstancesListKey = (
  projectName: string,
  params?: ProjectInstancesListParams
) => [`/projects/${projectName}/instances`, ...(params ? [params] : [])];

export type ProjectInstancesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectInstancesList>>
>;
export type ProjectInstancesListQueryError = ErrorType<unknown>;

export const useProjectInstancesList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ProjectInstancesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectInstancesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getProjectInstancesListKey(projectName, params) : null);
  const swrFn = () => projectInstancesList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get the details of an instance running in a project

### Response Structure
- `id`: Unique identifier for the instance (UUID)
- `status`: Status of the instance. It can be one of the following: pending, initialising, running, stopping.
- `time_created`: The date when the instance was created
- `time_updated`: The date when the instance was last updated
- `instance_type`: A dictionary containing instance type details of the instance. If the instance has no instance type set yet, it is null.
  - `id`: UUID of the instance type
  - `name`: Name of the instance type
  - `display_name`: Display name of the instance type
- `node`: A dictionary containing the node details of the instance
  - `ipv4_address`: IPv4 address of the node
  - `ipv6_address`: IPv6 address of the node
- `node_pool`: A dictionary containing the node pool details of the instance. If the instance has no node pool set yet, it is null.
  - `cluster`: A dictionary containing the cluster details of the node pool
    - `type`: Type of the cluster
- `deployment`: Name of the deployment for which the instance is running for
- `version`: Name of the version for which the instance is running for

#### Response Examples
```
{
  "id": "33a0541a-11a5-44f7-8722-b7428d1faf80",
  "status": "pending",
  "time_created": "2024-05-01T08:32:14.876451Z",
  "time_updated": "2024-05-01T08:32:14.876451Z",
  "instance_type": null,
  "node": {
    "ipv4_address": null,
    "ipv6_address": null
  },
  "node_pool": null,
  "deployment": "deployment-1",
  "version": "v1"
}
```

 * @summary Get instance for projects
 */
export const projectInstancesGet = (
  projectName: string,
  instanceId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InstanceDetail>(
    { url: `/projects/${projectName}/instances/${instanceId}`, method: "get" },
    options
  );
};

export const getProjectInstancesGetKey = (
  projectName: string,
  instanceId: string
) => [`/projects/${projectName}/instances/${instanceId}`];

export type ProjectInstancesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectInstancesGet>>
>;
export type ProjectInstancesGetQueryError = ErrorType<unknown>;

export const useProjectInstancesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  instanceId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectInstancesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && instanceId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getProjectInstancesGetKey(projectName, instanceId) : null);
  const swrFn = () =>
    projectInstancesGet(projectName, instanceId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

import { useTheme } from "@mui/material";

export const OperatorNodeCountMany = () => {
  const theme = useTheme();

  return (
    <svg
      width="85"
      height="77"
      viewBox="0 0 85 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_25987)">
        <path
          d="M41.6422 76.219C62.4314 76.219 79.2844 59.366 79.2844 38.5768C79.2844 17.7876 62.4314 0.93457 41.6422 0.93457C20.853 0.93457 4 17.7876 4 38.5768C4 59.366 20.853 76.219 41.6422 76.219Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M18.9072 31.4951H44.2505V46.4029H18.9072"
          stroke="white"
          strokeWidth="3"
        />
        <path d="M36.0518 31.4951V47.1483" stroke="white" strokeWidth="3" />
        <path d="M27.1074 32.2412V46.4036" stroke="white" strokeWidth="3" />
        <path
          d="M52.454 46.5486L53.1394 42.6876H49.2783L49.6162 40.757H53.4772L54.5004 34.9654H50.6393L50.9772 33.0349H54.8383L55.5236 29.1738H57.4541L56.7688 33.0349H62.5604L63.2457 29.1738H65.1763L64.4909 33.0349H68.352L68.0142 34.9654H64.1531L63.1299 40.757H66.991L66.6531 42.6876H62.7921L62.1067 46.5486H60.1762L60.8615 42.6876H55.0699L54.3846 46.5486H52.454ZM56.431 34.9654L55.4078 40.757H61.1994L62.2226 34.9654H56.431Z"
          fill="white"
        />
        <path
          d="M80 43.5C82.4853 43.5 84.5 41.4853 84.5 39C84.5 36.5147 82.4853 34.5 80 34.5C77.5147 34.5 75.5 36.5147 75.5 39C75.5 41.4853 77.5147 43.5 80 43.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
        <path
          d="M5 43.5C7.48528 43.5 9.5 41.4853 9.5 39C9.5 36.5147 7.48528 34.5 5 34.5C2.51472 34.5 0.5 36.5147 0.5 39C0.5 41.4853 2.51472 43.5 5 43.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4316_25987">
          <rect width="85" height="77" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";

import { sentryEnabled } from "libs/env/helpers";
import { sentryReduxEnhancer } from "libs/third-parties";

import authentication from "./features/authentication";
import notifications from "./features/notifications";
import organizationProjects from "./features/organizationProjects";
import { default as newOrganizations } from "./features/organizations";
import permissions from "./features/permissions";
import taskManager from "./features/taskManager";
import themeMode from "./features/themeMode";

import type { Store, ThunkDispatch } from "@reduxjs/toolkit";
import type { AnyAction, Reducer } from "redux";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const configuredStore = (preloadedState?: any) => {
  const store = configureStore({
    reducer: {
      taskManager: taskManager,
      projects: organizationProjects,
      newOrganizations,
      permissions,
      router: connectRouter(history) as Reducer<unknown, AnyAction>,
      themeMode,
      notifications,
      authentication,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
      routerMiddleware(history),
    ],
    enhancers: sentryEnabled() ? [sentryReduxEnhancer] : undefined,
  });

  return store;
};

export const store = configuredStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppThunkDispatch = ThunkDispatch<RootState, any, any>;

export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { organizationsUpdate } from "libs/data/endpoints/organizations/organizations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { OrganizationUpdate } from "libs/data/models";

export const useOrganizationUpdate = (name: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (data: OrganizationUpdate) => {
      try {
        const updatedOrg = await organizationsUpdate(name, data);
        dispatch(createSuccessNotification("Organization was updated."));

        return updatedOrg;
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [name, dispatch]
  );
};

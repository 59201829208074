/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type OrganizationDetailStatus =
  typeof OrganizationDetailStatus[keyof typeof OrganizationDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationDetailStatus = {
  active: "active",
  pending: "pending",
  suspended_usage: "suspended_usage",
  suspended_manual: "suspended_manual",
  cancelled: "cancelled",
} as const;

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type OrganizationUserDetailStatus =
  typeof OrganizationUserDetailStatus[keyof typeof OrganizationUserDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationUserDetailStatus = {
  active: "active",
  pending: "pending",
  invited: "invited",
  deleted: "deleted",
} as const;

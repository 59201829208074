import { rgba } from "polished";

import { colors } from "./colors";

import type { PaletteMode } from "@mui/material";

export const darkPalette = {
  type: "dark",
  mode: "dark" as PaletteMode,
  primary: {
    main: colors.stargaze,
    light: colors.larkspur,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.tangerine,
    light: colors.pomelo,
    contrastText: colors.white,
  },
  tertiary: {
    main: colors.inkjet,
    light: colors.wisteria,
  },
  error: {
    main: colors.romeo,
    contrastText: colors.white,
    dark: colors.puckerup,
    light: colors.rumour,
  },
  warning: {
    main: colors.mango,
    contrastText: colors.white,
    dark: colors.mustard,
    light: colors.butter,
  },
  success: {
    main: colors.pesticide,
    contrastText: colors.white,
    dark: colors.grinch,
    light: colors.ginko,
  },
  info: {
    main: colors.denim,
    light: colors.vapor,
    contrastText: colors.white,
    dark: colors.windstormBlue,
  },
  logs: {
    background: colors.squidInk,
    hover: colors.stargaze,
  },
  text: {
    primary: colors.paperWhite,
    secondary: colors.stratus,
    tertiary: colors.wisteria,
    emphasis: colors.tangerine,
    disabled: colors.cerebralGrey,
    hint: rgba(colors.royalBlue, 0.38),
    formLabel: colors.paperWhite,
  },
  action: {
    active: colors.stargaze,
    hover: colors.quill,
    selected: rgba(colors.royalBlue, 0.14),
    disabled: colors.royalBlue,
    disabledBackground: colors.larkspur,
  },
  neutrals: {
    900: colors.paperWhite,
    500: colors.moonWhite,
    400: colors.ghost,
    300: colors.mist,
    200: colors.cerebralGrey,
    100: colors.slabGrey,
    50: colors.smokeGrey,
    0: colors.pitchBlack,
  },
  charts: {
    legend: colors.paperWhite,
    ticks: colors.mist,
    crosshair: colors.paperWhite,
    grid: colors.stargaze,
  },
  chip: {
    date: colors.inkjet,
  },
  disabled: {
    primary: colors.cerebralGrey,
    secondary: colors.quickSilver,
    tertiaty: colors.ghost,
    dark: colors.slabGrey,
  },
  pipelineDiagram: {
    backgroundLine: colors.stargaze,
    tab: colors.larkspur,
    nodeBackground: colors.royalBlue,
    nodeHover: colors.inkjet,
    deploymentAccent: colors.deploymentGreen,
    pipelineAccent: colors.pipelineBlue,
    startEndNodesBackground: colors.royalBlue,
    startEndNodesText: colors.paperWhite,
    controlsBackground: colors.stargaze,
    controlsIcons: colors.paperWhite,
    controlsBorder: colors.causeway,
    dialogBackground: colors.squidInk,
    dialogBorder: colors.stargaze,
    sidebarButton: colors.quill,
    operatorContainer: colors.royalBlue,
    operatorHover: colors.quill,
    cardDragIndicator: colors.paperWhite,
    selectMultiLabel: colors.wisteria,
    selectMultiLabelText: colors.royalBlue,
    dateSaved: colors.paperWhite,
    varDetails: colors.wisteria,
  },
  background: {
    default: colors.squidInk,
    paper: colors.styx,
    active: colors.quill,
    subtle: colors.styx,
    codeBlock: colors.inkjet,
    auth: colors.styx,
    tableRow: colors.squidInk,
  },
  body: {
    background: colors.styx,
    componentBackground: colors.squidInk,
    text: colors.paperWhite,
    floatingChip: colors.larkspur,
    radioHover: colors.quill,
  },
  sidebarMenu: {
    organization: colors.squidInk,
    organizationText: colors.paperWhite,
    background: colors.squidInk,
    active: colors.quill,
    hover: colors.quill,
    contrastText: colors.paperWhite,
    chevronButton: colors.inkjet,
    chevronColor: colors.ghost,
  },
  border: {
    primary: colors.stratus,
    secondary: colors.borders.darkGrey,
    tertiary: colors.borders.lightGrey,
    input: colors.borders.textAreaDark,
    bold: colors.tangerine,
    pipelineNodeBorder: colors.styx,
    card: colors.inkjet,
    divider: colors.cerebralGrey,
  },
  list: {
    item: {
      background: {
        selected: colors.pomelo,
        hover: colors.mist,
      },
    },
  },
  select: {
    option: {
      background: {
        selected: colors.stargaze,
      },
    },
    multiValue: colors.larkspur,
  },
  buttons: {
    toggleButtonHover: colors.stargaze,
    toggleButtonBorder: colors.inkjet,
    tertiaryBackground: colors.stargaze,
    tertiaryColor: colors.paperWhite,
    disabled: colors.slabGrey,
  },
  stepper: {
    inactiveCircle: colors.slabGrey,
  },
  input: {
    label: {
      background: colors.white,
    },
  },
  font: {
    link: colors.link,
    cardHeader: colors.paperWhite,
    primary: colors.royalBlue,
    secondary: colors.stargaze,
  },
  shadow: {
    primary: colors.shadows.primary,
    secondary: colors.shadows.secondary,
  },
  table: {
    nameColumn: colors.paperWhite,
    comparison: {
      fixed: colors.stargaze,
      scroll: colors.squidInk,
    },
    deleteIcon: colors.stargaze,
    searchIcon: colors.paperWhite,
  },
  draggable: {
    background: rgba(colors.royalBlue, 0.54),
  },
  specials: {
    deployment: colors.green,
    pipelines: colors.heirloomBlue,
    blue: {
      bright: colors.heirloomBlue,
      pastel: colors.frost,
    },
    lilac: {
      bright: colors.orchid,
      pastel: colors.mulberry,
    },
    yellow: {
      bright: colors.goldenSnitch,
      pastel: colors.solsticeYellow,
    },
    acqua: {
      bright: colors.peppermint,
      pastel: colors.menthol,
    },
    red: {
      bright: colors.coral,
      pastel: colors.rosewood,
    },
    purple: {
      bright: colors.amethyst,
      pastel: colors.grapeSoda,
      darkPastel: colors.lavenderHaze,
    },
    verdent: {
      bright: colors.lawn,
      pastel: colors.fennel,
    },
    orange: {
      bright: colors.pumpkinSpiceLatte,
      pastel: colors.chestnut,
    },
    violet: {
      bright: colors.thistle,
      pastel: colors.petunia,
    },
    turquoise: {
      bright: colors.glacierBlue,
      pastel: colors.saltSpray,
    },
    pink: {
      bright: colors.phloxPink,
      pastel: colors.berry,
    },
    green: {
      bright: colors.lizardGreen,
      pastel: colors.spring,
    },
  },
  header: {
    color: colors.white,
    background: colors.quill,
    search: {
      color: colors.white,
    },
    indicator: {
      background: colors.picassoRed,
    },
  },
  svgs: {
    pair1: colors.larkspur,
    pair2: colors.stargaze,
    pair3: colors.stratus,
    pair4: colors.royalBlue,
    pair5: colors.paperWhite,
    brownSkin: colors.svg.brownSkin,
    royalBlue: colors.royalBlue,
    ghost: colors.stargaze,
    celebralGray: colors.svg.mediumGrey,
    paperWhite: colors.paperWhite,
    mist: colors.mist,
    black: colors.svg.black,
    pinkSkin: colors.svg.pinkSkin,
    contrastText: colors.paperWhite,
    widgetBackGround: colors.svg.widgetBackground,
    moonWhite: colors.moonWhite,
    starDust: colors.svg.starDust,
    darkenScreen: colors.svg.darkenScreen,
  },
};

import CloseIcon from "@mui/icons-material/CloseRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import OpenIcon from "@mui/icons-material/LaunchRounded";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { RootUrlContext } from "libs/contexts";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import { useGetCurrentProject } from "store/features";

import { CircularProgressWithLabel, StatusIcon } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";

type UploadTaskItemProps = {
  id: string;
  message: string;
  status: string;
  deleteTask: (id: string) => void;
  version?: { id: string; deployment: string; version: string };
  bucket?: { name: string; prefix: string };
  progress?: number;
};
export const UploadTaskItem = ({
  id,
  message,
  status,
  deleteTask,
  version,
  bucket,
  progress,
}: UploadTaskItemProps) => {
  const rootUrl = useContext(RootUrlContext);
  const theme = useTheme() as AppThemeProps;
  const currentProjectName = useGetCurrentProject()?.name;

  const onDelete = () => {
    deleteTask(id);
  };
  const url = `${rootUrl}/projects/${currentProjectName}`.concat(
    bucket
      ? `/storage/buckets/${bucket.name}/${bucket.prefix}`
      : `/deployments/${version?.deployment}/versions/${version?.version}`
  );

  return (
    <MenuItem divider dense component={Link} to={url}>
      <ListItemIcon>
        {status === LOADING ? (
          <CircularProgressWithLabel percentage={progress || 0} size={32} />
        ) : (
          <StatusIcon status={status === LOADED ? "completed" : "failed"} />
        )}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Box display="flex" alignItems="center" mr={1}>
            <Box component={CloudUploadIcon} fontSize="small" mr={1} />
            <Box
              component={Typography}
              variant="subtitle2"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {message}
            </Box>
          </Box>
        }
        secondary={
          <Box display="flex" alignItems="center" mr={2}>
            <Box
              component={Typography}
              variant="caption"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {bucket
                ? `bucket: ${bucket.name}`
                : `deployment: ${version?.deployment} - version: ${version?.version}`}
            </Box>
          </Box>
        }
      />
      <Tooltip title={`Go to ${bucket ? "bucket" : "version"}`}>
        <span>
          <IconButton color="secondary" component={Link} to={url}>
            <OpenIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Remove">
        <span>
          <IconButton edge="end" onClick={onDelete}>
            <CloseIcon sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </span>
      </Tooltip>
    </MenuItem>
  );
};

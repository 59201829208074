import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { PrimaryButton } from "components/atoms/Button/PrimaryButton";
import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";
import { FIELD_NAME } from "libs/constants/fields";
import { useFileUpload } from "libs/data/customized/files/useFileUpload";
import { explanations } from "libs/utilities/explanations";
import { isObjectHasData } from "libs/utilities/utils";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import { Dialog, DialogBody, FormTextField, Icon } from "components/atoms";
import { FormSection } from "components/molecules";

import { getPrefix } from "../../../utils";

type CreateFolderDialogProps = {
  open: boolean;
  closeDialog: () => void;
  handleSuccessfulUpload: () => void;
};

export const CreateFolderDialog = ({
  open,
  closeDialog,
  handleSuccessfulUpload,
}: CreateFolderDialogProps) => {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
    criteriaMode: "all",
    defaultValues: {
      [FIELD_NAME]: "",
    },
  });
  const { errors, control, reset } = formMethods;

  const folderNameValue = useWatch({
    name: FIELD_NAME,
    control,
  });
  const [inputFiles, setInputFiles] = useState<File[] | null>(null);
  const { projectName, bucketName, organizationName } =
    useParams<{
      projectName: string;
      bucketName: string;
      organizationName: string;
    }>();

  const { pathname } = useLocation();
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .storage[":bucketName"](bucketName)
    .general.files.index();
  const prefix = getPrefix(baseUrl, pathname);

  const [loading, setLoading] = useState(false);
  const [folderCreateButtonIsDisabled, setFolderCreateButtonIsDisabled] =
    useState(true);
  const fileUpload = useFileUpload(projectName, bucketName);

  useEffect(() => {
    // Enables the folder create button when a correct folder name and the uploaded file are available
    if (
      !isObjectHasData(errors) &&
      folderNameValue !== "" &&
      inputFiles?.length &&
      inputFiles?.length > 0
    ) {
      setFolderCreateButtonIsDisabled(false);
    } else {
      setFolderCreateButtonIsDisabled(true);
    }
  }, [errors, folderNameValue, inputFiles]);

  const handleFileChange = (files: File | FileList) => {
    if (files instanceof FileList) {
      setInputFiles([...files]);
    } else {
      setInputFiles([files]);
    }
  };

  const handleClose = () => {
    setInputFiles(null);
    reset();
    closeDialog();
  };

  const onFolderCreate = async () => {
    setLoading(true);
    if (inputFiles?.length) {
      // The prefix makes sure the uploaded folder is put inside the current folder
      await Promise.all(
        inputFiles.map(
          async (inputFile) =>
            await fileUpload(
              `${prefix}${folderNameValue}/${inputFile?.name}`,
              inputFile
            )
        )
      );
    }
    setLoading(false);

    handleSuccessfulUpload();
    handleClose();
  };

  return (
    <Dialog
      title="Create a folder"
      open={open}
      onClose={handleClose}
      onSubmit={onFolderCreate}
      maxWidth="sm"
      Actions={
        <PrimaryButton
          disabled={folderCreateButtonIsDisabled}
          onClick={formMethods.handleSubmit(onFolderCreate)}
          startIcon={<AddBoxRoundedIcon />}
        >
          Create folder
        </PrimaryButton>
      }
    >
      <DialogBody
        style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}
        px={1}
      >
        <FormProvider {...formMethods}>
          <Box display="flex" justifyContent="center" mb={5}>
            <Typography variant="body2">
              Create a folder within &apos;{bucketName}&apos;. Use folders to
              group files within a bucket.
            </Typography>
          </Box>

          <Box mb={5}>
            <FormSection title="Name your folder">
              <FormTextField
                name={FIELD_NAME}
                label="Folder name"
                required
                rules={{
                  required: validators.required.message(
                    "Folder name is required"
                  ),
                }}
                placeholder="Ex: Deployment folder"
              />
            </FormSection>
          </Box>

          <Box mb={5}>
            <FormSection
              title="Add a file to your folder"
              // This explanation needs to be a function otherwise the WarningText component seems to not work
              description={explanations.storage.folder.file()}
            >
              <>
                <DropSelectFile multiple handleChange={handleFileChange}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    marginBottom={2}
                  >
                    <Typography color="textSecondary">
                      Drag & drop your file here to upload it
                    </Typography>
                    <Typography>or</Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <PrimaryButton
                      startIcon={<AddBoxRoundedIcon />}
                      loading={loading}
                    >
                      Add file
                    </PrimaryButton>
                  </Box>
                  {inputFiles?.length &&
                    inputFiles.map(
                      (inputFile) =>
                        inputFile &&
                        inputFile?.name && (
                          <Box
                            display="flex"
                            key={inputFile.name}
                            alignItems="center"
                            mt={2}
                          >
                            <Icon
                              component={CheckCircleRoundedIcon}
                              status="available"
                            />
                            <Typography variant="body2" display="inline">
                              {inputFile?.name}
                            </Typography>
                          </Box>
                        )
                    )}
                </DropSelectFile>
              </>
            </FormSection>
          </Box>
        </FormProvider>
      </DialogBody>
    </Dialog>
  );
};

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  DeploymentRequestList,
  DeploymentRequestsListParams,
  DeploymentRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
  DeploymentRequestsCreateParams,
  DeploymentRequestBatchCreateResponse,
  BatchDeploymentRequestsCreateDataBody,
  BatchDeploymentRequestsCreateParams,
  EmptyRequest,
  DeploymentRequestsBatchCancelDataBody,
  DeploymentRequestsBatchCancelParams,
  DeploymentRequestDetail,
  DeploymentRequestsStreamingCreateParams,
  DeploymentRequestSingleDetail,
  DeploymentRequestsGetParams,
  DeploymentRequestUpdateBody,
  DeploymentVersionRequestsListParams,
  DeploymentVersionRequestsCreateParams,
  BatchDeploymentVersionRequestsCreateParams,
  DeploymentVersionRequestsBatchCancelParams,
  DeploymentVersionRequestsStreamingCreateParams,
  DeploymentVersionRequestsGetParams,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all requests for the default version of a deployment

### Optional Parameters
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled', defaults to 'completed'. A combination of statuses can also be requested. 'pending' and 'processing' requests cannot be combined with other statuses.
- `limit`: The maximum number of requests given back, defaults to 50
- `offset`: The number which forms the starting point of the requests given back, defaults to 0. If offset equals 2, then the first 2 requests will be omitted from the list.
- `start_date`: Start date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `end_date`: End date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `search_id`: A string to search inside request ids. It will filter all request ids that contain this string. *Only available* for completed/failed/cancelled requests.

If no start or end date is provided, the most recent requests are returned.

### Response Structure
A list of dictionaries containing the details of the deployment requests with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  }
]
```

 * @summary List deployment requests
 */
export const deploymentRequestsList = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentRequestsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentRequestsListKey = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentRequestsListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/requests`,
  ...(params ? [params] : []),
];

export type DeploymentRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentRequestsList>>
>;
export type DeploymentRequestsListQueryError = ErrorType<unknown>;

export const useDeploymentRequestsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  params?: DeploymentRequestsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentRequestsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentRequestsListKey(projectName, deploymentName, params)
        : null);
  const swrFn = () =>
    deploymentRequestsList(projectName, deploymentName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Request a prediction from a deployment. Deployment requests are made for the default version of a deployment.
When using the 'requests' function of a deployment a list should be provided as input, see the example below.

### Required Parameters
The input for the request. In case of a structured deployment, this is a dictionary which contains the input fields of the deployment as keys. In case of a plain deployment, give a string or list of strings.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the deployment request in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300 (5 minutes).

#### Request Examples
A structured deployment request
```
{
  "input-field-1": 5.0,
  "input-field-2": "N",
  "input-field-3": [0.25, 0.25, 2.1, 16.3]
}
```

A structured deployment request with a file field
```
{
  "input-field-1": 5.0,
  "file-input-field": "ubiops-file://my-bucket/file-1.jpg"
}
```

A plain deployment request
```
"example-plain-data"
```

Multiple structured deployment requests using the 'requests' function of a deployment
```
[
    {
        "input-field-1": 5.0
    },
    {
        "input-field-1": 10.0
    }
]
```

### Response Structure
Details of the created deployment request
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request. It can be 'completed' or 'failed'.
- `result`: Deployment request result value. NULL if the request failed.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `timeout`: Timeout of the request in seconds

#### Response Examples
A failed deployment request
```
{
  "id": "85ae32a7-fe3a-4a55-be27-9db88ae68501",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "failed",
  "result": None,
  "error_message": "Asset ID not supported"
  "timeout": 300
}
```

A successful deployment request
```
{
  "id": "ffce45da-1562-419a-89a0-0a0837e55392",
  "deployment": "deployment-1",
  "version": "v2",
  "status": "completed",
  "result": {
    "output-field-1": "2.1369",
    "output-field-2": "5.5832",
  },
  "error_message": null,
  "timeout": 300
}
```

 * @summary Create a direct deployment request
 */
export const deploymentRequestsCreate = (
  projectName: string,
  deploymentName: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: DeploymentRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestCreateResponse>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Request multiple predictions from the default version of a deployment. The request follows an asynchronous method, as the requests are queued in our back-end and can be collected at a later time using the deployment request collect methods.

If one of the requests is faulty, all requests are denied. The maximum number of requests per batch call is 250.

### Required Parameters
In case of structured input deployment: A list of dictionaries, where each dictionary contains the input fields of the deployment as keys. It is also possible to send a single dictionary as input.
In case of plain input deployment: A list of strings. It is also possible to send a single string as input.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the batch deployment request in seconds. The maximum allowed value is 172800 (48 hours) and the default value is 14400 (4 hours).

#### Request Examples
Multiple structured batch deployment requests
```
[
  {
    "input-field-1": 5.0,
    "input-field-2": "N",
    "input-field-3": [0.25, 0.25, 2.1, 16.3]
  },
  {
    "input-field-1": 3.0,
    "input-field-2": "S",
    "input-field-3": [4.23, 3.27, 2.41, 12.4]
  }
]
```

Multiple plain batch deployment requests
```
[
  "plain-data-goes-here", "plain-example-data"
]
```

### Response Structure
A list of dictionaries containing the details of the created deployment requests with the following fields:
 - `id`: Unique identifier for the deployment request, which can be used to collect the result
 - `deployment`: Name of the deployment the request was made to
 - `version`: Name of the version the request was made to
 - `status`: Status of the request. Always 'pending' when initialised, later it can be 'processing', 'failed', 'completed', 'cancelled' or 'cancelled_pending'.
 - `time_created`: Server time that the request was made (current time)

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  }
]
```

 * @summary Create a batch deployment request
 */
export const batchDeploymentRequestsCreate = (
  projectName: string,
  deploymentName: string,
  batchDeploymentRequestsCreateDataBody: BatchDeploymentRequestsCreateDataBody,
  params?: BatchDeploymentRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestBatchCreateResponse[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/batch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: batchDeploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Cancel multiple deployment requests for the default version of a deployment. A maximum of 250 deployment requests can be cancelled with this method.

To cancel all pending or processing requests, use the query parameter `status`, with the value 'pending' or 'processing', with an empty request body.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

 * @summary Cancel multiple deployment requests
 */
export const deploymentRequestsBatchCancel = (
  projectName: string,
  deploymentName: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  params?: DeploymentRequestsBatchCancelParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/cancel`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Retrieve multiple deployment requests for the default version of a deployment. If one of the given deployment requests does not exist, an error message is given and no request is returned. A maximum of 250 deployment requests can be retrieved with this method. The deployment requests are NOT returned in the order they are given in.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

### Response Structure
A list of dictionaries containing the details of the retrieved deployment requests with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled'
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `timeout`: Timeout in seconds for the request
- `request_data`: Input of the request
- `result`: Output of the request. It is set to null if the request is in pending/processing/failed statuses.
- `error_message`: An error message explaining why the request has failed. It is set to null if the request was successful.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "2f909aeb-5c7e-4974-970d-cd0a6a073aca",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-29T08:09:10.729+00:00",
    "time_started": null,
    "time_completed": null,
    "timeout": 300,
    "request_data": {"input": 82.2},
    "result": null,
    "error_message": null,
    "input_size": 14,
    "output_size": null
  },
  {
    "id": "85711124-54db-4794-b83d-24492247c6e1",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-06-25T09:37:17.765+00:00",
    "timeout": 300,
    "time_started": null,
    "time_completed": null,
    "request_data": {"input": 52.4},
    "result": null,
    "error_message": null,
    "input_size": 14,
    "output_size": null
  }
]
```

 * @summary Retrieve multiple deployment requests
 */
export const deploymentRequestsBatchGet = (
  projectName: string,
  deploymentName: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestDetail[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/collect`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
    },
    options
  );
};

/**
 * 
### Description
Delete multiple deployment requests for the default version of a deployment. If one of the given deployment requests does not exist, an error message is given and no request is deleted. A maximum of 250 deployment requests can be deleted with this method.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

 * @summary Delete multiple deployment requests
 */
export const deploymentRequestsBatchDelete = (
  projectName: string,
  deploymentName: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/delete`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
    },
    options
  );
};

/**
 * 
### Description
Request a prediction from a deployment, with responses streamed back in real-time.  Deployment requests are made for the default version of a deployment.
When using the 'requests' function of a deployment a list should be provided as input, see the example below.

### Required Parameters
The input for the request. In case of a structured deployment, this is a dictionary which contains the input fields of the deployment as keys. In case of a plain deployment, give a string or list of strings.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the deployment request in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300 (5 minutes).

#### Request Examples
A structured deployment request
```
{
  "input-field-1": 5.0,
  "input-field-2": "N",
  "input-field-3": [0.25, 0.25, 2.1, 16.3]
}
```

A structured deployment request with a file field
```
{
  "input-field-1": 5.0,
  "file-input-field": "ubiops-file://my-bucket/file-1.jpg"
}
```

A plain deployment request
```
"example-plain-data"
```

Multiple structured deployment requests using the 'requests' function of a deployment
```
[
    {
        "input-field-1": 5.0
    },
    {
        "input-field-1": 10.0
    }
]
```

### Response Structure
Details of the created deployment request
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request. It can be 'completed' or 'failed'.
- `result`: Deployment request result value. NULL if the request failed.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `timeout`: Timeout of the request in seconds

#### Response Examples
A failed deployment request
```
{
  "id": "85ae32a7-fe3a-4a55-be27-9db88ae68501",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "failed",
  "result": None,
  "error_message": "Asset ID not supported"
  "timeout": 300
}
```

A successful deployment request
```
{
  "id": "ffce45da-1562-419a-89a0-0a0837e55392",
  "deployment": "deployment-1",
  "version": "v2",
  "status": "completed",
  "result": {
    "output-field-1": "2.1369",
    "output-field-2": "5.5832",
  },
  "error_message": null,
  "timeout": 300
}
```

 * @summary Create a streaming deployment request
 */
export const deploymentRequestsStreamingCreate = (
  projectName: string,
  deploymentName: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: DeploymentRequestsStreamingCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestCreateResponse>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/stream`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a request for the default version of a deployment

### Optional Parameters
- `metadata_only`: A boolean value that indicates whether the response should include the request data and result, defaults to False

### Response Structure
A dictionary containing the details of the deployment request with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled'
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `timeout`: Timeout in seconds for the request
- `request_data`: Input of the request
- `result`: Output of the request. It is set to null if the request is in pending/processing/failed statuses.
- `error_message`: An error message explaining why the request has failed. It is set to null if the request was successful.
- `origin`: A dictionary containing the information on where the request originated from. It contains:
    - `created_by` field with the email of the user that created the request. In case the request is created by a service, the field will have a "UbiOps" value.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
{
  "id": "2f909aeb-5c7e-4974-970d-cd0a6a073aca",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "pending",
  "time_created": "2020-03-29T08:09:10.729+00:00",
  "time_started": null,
  "time_completed": null,
  "timeout": 300,
  "request_data": {"input": 82.3},
  "result": null,
  "error_message": null,
  "origin": {
    "created_by": "my.example.user@ubiops.com"
  },
  "input_size": 14,
  "output_size": null
}
```

 * @summary Get a deployment request
 */
export const deploymentRequestsGet = (
  projectName: string,
  deploymentName: string,
  requestId: string,
  params?: DeploymentRequestsGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestSingleDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/${requestId}`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentRequestsGetKey = (
  projectName: string,
  deploymentName: string,
  requestId: string,
  params?: DeploymentRequestsGetParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/requests/${requestId}`,
  ...(params ? [params] : []),
];

export type DeploymentRequestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentRequestsGet>>
>;
export type DeploymentRequestsGetQueryError = ErrorType<unknown>;

export const useDeploymentRequestsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  requestId: string,
  params?: DeploymentRequestsGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentRequestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && requestId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentRequestsGetKey(
            projectName,
            deploymentName,
            requestId,
            params
          )
        : null);
  const swrFn = () =>
    deploymentRequestsGet(
      projectName,
      deploymentName,
      requestId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Cancel a deployment request for the default version of a deployment

### Required Parameters
- `status`: Status that the request will be updated to. It can only be `cancelled`.

#### Request Examples
```
{
"status": "cancelled"
}
```

 * @summary Update a deployment request
 */
export const deploymentRequestsUpdate = (
  projectName: string,
  deploymentName: string,
  requestId: string,
  deploymentRequestUpdateBody: DeploymentRequestUpdateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/${requestId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestUpdateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete a deployment request for the default version of a deployment

 * @summary Delete a deployment request
 */
export const deploymentRequestsDelete = (
  projectName: string,
  deploymentName: string,
  requestId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/requests/${requestId}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
List all requests for a deployment version

### Optional Parameters
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled', defaults to 'completed'. A combination of statuses can also be requested. 'pending' and 'processing' requests cannot be combined with other statuses.
- `limit`: The maximum number of requests given back, defaults to 50
- `offset`: The number which forms the starting point of the requests given back, defaults to 0. If offset equals 2, then the first 2 requests will be omitted from the list.
- `start_date`: Start date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `end_date`: End date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `search_id`: A string to search inside request ids. It will filter all request ids that contain this string. *Only available* for completed/failed/cancelled requests.

If no start or end date is provided, the most recent requests are returned.

### Response Structure
A list of dictionaries containing the details of the deployment requests with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  }
]
```

 * @summary List deployment version requests
 */
export const deploymentVersionRequestsList = (
  projectName: string,
  deploymentName: string,
  version: string,
  params?: DeploymentVersionRequestsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentVersionRequestsListKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  params?: DeploymentVersionRequestsListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests`,
  ...(params ? [params] : []),
];

export type DeploymentVersionRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionRequestsList>>
>;
export type DeploymentVersionRequestsListQueryError = ErrorType<unknown>;

export const useDeploymentVersionRequestsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  params?: DeploymentVersionRequestsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionRequestsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionRequestsListKey(
            projectName,
            deploymentName,
            version,
            params
          )
        : null);
  const swrFn = () =>
    deploymentVersionRequestsList(
      projectName,
      deploymentName,
      version,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Request a prediction from a deployment version. It is only possible to make a request if a deployment file is uploaded for that version and the deployment build has succeeded (meaning that the version is in available state).
When using the 'requests' function of a deployment a list should be provided as input, see the example below.

### Required Parameters
The input for the request. In case of a structured deployment, this is a dictionary which contains the input fields of the deployment as keys. In case of a plain deployment, give a string or list of strings.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the deployment request in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300 (5 minutes).

#### Request Examples
A structured deployment request
```
{
  "input-field-1": 5.0,
  "input-field-2": "N",
  "input-field-3": [0.25, 0.25, 2.1, 16.3]
}
```

A structured deployment request with a file field
```
{
  "input-field-1": 5.0,
  "file-input-field": "ubiops-file://my-bucket/file-1.jpg"
}
```

A plain deployment request
```
"example-plain-data"
```

Multiple structured deployment requests using the 'requests' function of a deployment version
```
[
    {
        "input-field-1": 5.0
    },
    {
        "input-field-1": 10.0
    }
]
```

### Response Structure
Details of the created deployment request
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request. It can be 'completed' or 'failed'.
- `result`: Deployment request result value. NULL if the request failed.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `timeout`: Timeout of the request in seconds

#### Response Examples
A failed deployment request
```
{
  "id": "85ae32a7-fe3a-4a55-be27-9db88ae68501",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "failed",
  "result": None,
  "error_message": "Asset ID not supported",
  "timeout": 300
}
```

A successful deployment request
```
{
  "id": "ffce45da-1562-419a-89a0-0a0837e55392",
  "deployment": "deployment-1",
  "version": "v2",
  "status": "completed",
  "result": {
    "output-field-1": "2.1369",
    "output-field-2": "5.5832",
  },
  "error_message": None,
  "timeout": 300
}
```

 * @summary Create a direct deployment version request
 */
export const deploymentVersionRequestsCreate = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: DeploymentVersionRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestCreateResponse>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Request multiple predictions from a deployment version. The request follows an asynchronous method, as the requests are queued in our back-end and can be collected at a later time using the deployment request collect methods. It is only possible to make a request if a deployment file is uploaded for that version and the deployment build has succeeded (meaning that the version is in available state).

If one of the requests is faulty, all requests are denied. The maximum number of requests per batch call is 250.

### Required Parameters
In case of structured input deployment: A list of dictionaries, where each dictionary contains the input fields of the deployment as keys. It is also possible to send a single dictionary as input.
In case of plain input deployment: A list of strings. It is also possible to send a single string as input.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the batch deployment request in seconds. The maximum allowed value is 172800 (48 hours) and the default value is 14400 (4 hours).
- `notification_group`: Name of a notification group to send notifications (e.g., emails) when the request is completed

#### Request Examples
Multiple structured batch deployment requests
```
[
  {
    "input-field-1": 5.0,
    "input-field-2": "N",
    "input-field-3": [0.25, 0.25, 2.1, 16.3]
  },
  {
    "input-field-1": 3.0,
    "input-field-2": "S",
    "input-field-3": [4.23, 3.27, 2.41, 12.4]
  }
]
```

Multiple plain batch deployment requests
```
[
  "plain-data-goes-here", "plain-example-data"
]
```

### Response Structure
A list of dictionaries containing the details of the created deployment requests with the following fields:
 - `id`: Unique identifier for the deployment request, which can be used to collect the result
 - `deployment`: Name of the deployment the request was made to
 - `version`: Name of the version the request was made to
 - `status`: Status of the request. Always 'pending' when initialised, later it can be 'processing', 'failed', 'completed', 'cancelled' or 'cancelled_pending'.
 - `time_created`: Server time that the request was made (current time)

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  }
]
```

 * @summary Create a batch deployment version request
 */
export const batchDeploymentVersionRequestsCreate = (
  projectName: string,
  deploymentName: string,
  version: string,
  batchDeploymentRequestsCreateDataBody: BatchDeploymentRequestsCreateDataBody,
  params?: BatchDeploymentVersionRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestBatchCreateResponse[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/batch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: batchDeploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Cancel multiple deployment requests for a deployment version. A maximum of 250 deployment requests can be deleted with this method. 

To cancel all pending or processing requests, use the query parameter `status`, with the value 'pending' or 'processing', with an empty request body.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

 * @summary Delete multiple deployment version requests
 */
export const deploymentVersionRequestsBatchCancel = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  params?: DeploymentVersionRequestsBatchCancelParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/cancel`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Retrieve multiple deployment requests for a deployment version. If one of the given deployment requests does not exist, an error message is given and no request is returned. A maximum of 250 deployment requests can be retrieved with this method. The deployment requests are NOT returned in the order they are given in.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

### Response Structure
A list of dictionaries containing the details of the retrieved deployment requests with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled'
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `timeout`: Timeout in seconds for the request
- `request_data`: Input of the request
- `result`: Output of the request. It is set to null if the request is in pending/processing/failed statuses.
- `error_message`: An error message explaining why the request has failed. It is set to null if the request was successful.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "2f909aeb-5c7e-4974-970d-cd0a6a073aca",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-29T08:09:10.729+00:00",
    "time_started": null,
    "time_completed": null,
    "timeout": 300,
    "request_data": {"input": 82.2},
    "result": null,
    "error_message": null,
    "input_size": 14,
    "output_size": null
  },
  {
    "id": "85711124-54db-4794-b83d-24492247c6e1",
    "deployment": "deployment-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-06-25T09:37:17.765+00:00",
    "time_started": null,
    "time_completed": null,
    "timeout": 300,
    "request_data": {"input": 52.4},
    "result": null,
    "error_message": null,
    "input_size": 14,
    "output_size": null
  }
]
```

 * @summary Retrieve multiple deployment version requests
 */
export const deploymentVersionRequestsBatchGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestDetail[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/collect`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
    },
    options
  );
};

/**
 * 
### Description
Delete multiple deployment requests for a deployment version. If one of the given deployment requests does not exist, an error message is given and no request is deleted. A maximum of 250 deployment requests can be deleted with this method.

### Required Parameters
A list of ids for the requests

#### Request Examples
```
["2f909aeb-5c7e-4974-970d-cd0a6a073aca", "85711124-54db-4794-b83d-24492247c6e1"]
```

 * @summary Delete multiple deployment version requests
 */
export const deploymentVersionRequestsBatchDelete = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsBatchCancelDataBody: DeploymentRequestsBatchCancelDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/delete`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchCancelDataBody,
    },
    options
  );
};

/**
 * 
### Description
Request a prediction from a deployment version, with responses streamed back in real-time.
It is only possible to make a request if a deployment file is uploaded for that version and the deployment build has succeeded (meaning that the version is in available state).
When using the 'requests' function of a deployment a list should be provided as input, see the example below.

### Required Parameters
The input for the request. In case of a structured deployment, this is a dictionary which contains the input fields of the deployment as keys. In case of a plain deployment, give a string or list of strings.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the deployment request in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300 (5 minutes).

#### Request Examples
A structured deployment request
```
{
  "input-field-1": 5.0,
  "input-field-2": "N",
  "input-field-3": [0.25, 0.25, 2.1, 16.3]
}
```

A structured deployment request with a file field
```
{
  "input-field-1": 5.0,
  "file-input-field": "ubiops-file://my-bucket/file-1.jpg"
}
```

A plain deployment request
```
"example-plain-data"
```

Multiple structured deployment requests using the 'requests' function of a deployment version
```
[
    {
        "input-field-1": 5.0
    },
    {
        "input-field-1": 10.0
    }
]
```

### Response Structure
Details of the created deployment request
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request. It can be 'completed' or 'failed'.
- `result`: Deployment request result value. NULL if the request failed.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `timeout`: Timeout of the request in seconds

#### Response Examples
A failed deployment request
```
{
  "id": "85ae32a7-fe3a-4a55-be27-9db88ae68501",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "failed",
  "result": None,
  "error_message": "Asset ID not supported",
  "timeout": 300
}
```

A successful deployment request
```
{
  "id": "ffce45da-1562-419a-89a0-0a0837e55392",
  "deployment": "deployment-1",
  "version": "v2",
  "status": "completed",
  "result": {
    "output-field-1": "2.1369",
    "output-field-2": "5.5832",
  },
  "error_message": None,
  "timeout": 300
}
```

 * @summary Create a streaming deployment version request
 */
export const deploymentVersionRequestsStreamingCreate = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: DeploymentVersionRequestsStreamingCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestCreateResponse>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/stream`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a request for a deployment version

### Optional Parameters
- `metadata_only`: A boolean value that indicates whether the response should include the request data and result, defaults to False

### Response Structure
A dictionary containing the details of the deployment request with the following fields:
- `id`: Unique identifier for the deployment request
- `deployment`: Name of the deployment the request was made to
- `version`: Name of the version the request was made to
- `status`: Status of the request, one of the following 'pending', 'processing', 'failed', 'completed' or 'cancelled'
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `timeout`: Timeout in seconds for the request
- `request_data`: Input of the request
- `result`: Output of the request. It is set to null if the request is in pending/processing/failed statuses.
- `error_message`: An error message explaining why the request has failed. It is set to null if the request was successful.
- `origin`: A dictionary containing the information on where the request originated from. It contains:
    - `created_by` field with the email of the user that created the request. In case the request is created by a service, the field will have a "UbiOps" value.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
{
  "id": "2f909aeb-5c7e-4974-970d-cd0a6a073aca",
  "deployment": "deployment-1",
  "version": "v1",
  "status": "pending",
  "time_created": "2020-03-29T08:09:10.729+00:00",
  "time_started": null,
  "time_completed": null,
  "timeout": 300,
  "request_data": {"input": 82.3},
  "result": null,
  "error_message": null,
  "origin": {
    "created_by": "my.example.user@ubiops.com"
  },
  "input_size": 14,
  "output_size": null
}
```

 * @summary Get a deployment version request
 */
export const deploymentVersionRequestsGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  requestId: string,
  params?: DeploymentVersionRequestsGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentRequestSingleDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/${requestId}`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentVersionRequestsGetKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  requestId: string,
  params?: DeploymentVersionRequestsGetParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/${requestId}`,
  ...(params ? [params] : []),
];

export type DeploymentVersionRequestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionRequestsGet>>
>;
export type DeploymentVersionRequestsGetQueryError = ErrorType<unknown>;

export const useDeploymentVersionRequestsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  requestId: string,
  params?: DeploymentVersionRequestsGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionRequestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && requestId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionRequestsGetKey(
            projectName,
            deploymentName,
            version,
            requestId,
            params
          )
        : null);
  const swrFn = () =>
    deploymentVersionRequestsGet(
      projectName,
      deploymentName,
      version,
      requestId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Cancel a deployment request for the default version of a deployment

### Required Parameters
- `status`: Status that the request will be updated to. It can only be `cancelled`.

#### Request Examples
```
{
"status": "cancelled"
}
```

 * @summary Update a deployment version request
 */
export const deploymentVersionRequestsUpdate = (
  projectName: string,
  deploymentName: string,
  version: string,
  requestId: string,
  deploymentRequestUpdateBody: DeploymentRequestUpdateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EmptyRequest>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/${requestId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestUpdateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete a deployment request for a deployment version

 * @summary Delete a deployment version request
 */
export const deploymentVersionRequestsDelete = (
  projectName: string,
  deploymentName: string,
  version: string,
  requestId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/${requestId}`,
      method: "delete",
    },
    options
  );
};

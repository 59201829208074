import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Typography, Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import type { AppThemeProps } from "assets/styles/theme/theme";

import "./ClickableCard.scss";

type ClickableCardProps = {
  title: string;
  amount?: number;
  onClick?: () => void;
  overviewLink?: string;
  icon?: React.ReactElement;
  children?: React.ReactNode;
};

const ClickableCard = ({
  title,
  amount,
  icon,
  onClick,
  overviewLink,
  children,
}: ClickableCardProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      className="clickable-card"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      component={overviewLink ? Link : "div"}
      to={overviewLink}
      onClick={onClick}
      sx={{
        ":hover": { backgroundColor: theme.palette.neutrals[100] },
      }}
    >
      <Box display="flex" alignItems="center">
        {icon}
        <Box ml={2}>
          <Box component="span" mr={1}>
            {amount}
          </Box>
          <Typography id="org-name-title" variant="h5" display="inline">
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ArrowForwardRoundedIcon />
      </Box>
    </Box>
  );
};

export default ClickableCard;

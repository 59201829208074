import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_REQUIREMENTS_FILE,
} from "libs/constants/fields";
import { useEnvironmentCreate } from "libs/data/customized/environments/useEnvironmentCreate";
import { useEnvironmentsGet } from "libs/data/endpoints/environments/environments";
import { createId } from "libs/utilities/utils";
import { routes } from "routes";

import { FormWrapper, Loader } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import { PageContainer } from "components/molecules";

import { EnvironmentBaseForm } from "../EnvironmentsBaseForm";

import type { EnvironmentBaseProps } from "../EnvironmentsBaseForm";
import type { FormikHelpers } from "formik";
import type {
  EnvironmentRevisionsFileUploadBody,
  EnvironmentCreate,
} from "libs/data/models";
import type { EnvironmentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/types";

export const EnvironmentDuplicate = () => {
  const { environmentName, organizationName, projectName } =
    useParams<EnvironmentDetailsRouteParams>();
  const createEnvironment = useEnvironmentCreate(projectName);
  const history = useHistory();
  const { data: environmentDetails } = useEnvironmentsGet(
    projectName,
    environmentName
  );

  const handleOnSubmit = async (
    values: EnvironmentBaseProps,
    actions: FormikHelpers<EnvironmentBaseProps>
  ) => {
    const { [FIELD_REQUIREMENTS_FILE]: uploadBody, ...request } = values;
    const response = await createEnvironment(
      request as EnvironmentCreate,
      uploadBody as EnvironmentRevisionsFileUploadBody
    );
    actions.setSubmitting(false);

    if (response) {
      actions.resetForm();

      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .environments.custom[":environmentName"](response.name)
          .details.index()
      );
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Duplicate environment" />
      <FormWrapper>
        {environmentDetails ? (
          <EnvironmentBaseForm
            initialValues={{
              [FIELD_NAME]: `${
                environmentDetails[FIELD_NAME]
              }-copy-${createId()}`,
              [FIELD_DESCRIPTION]: environmentDetails[FIELD_DESCRIPTION],
              [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]:
                environmentDetails[FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT],
              [FIELD_LABELS]: (environmentDetails[FIELD_LABELS] ??
                {}) as LabelsDict,
            }}
            onSubmit={handleOnSubmit}
          />
        ) : (
          <Loader />
        )}
      </FormWrapper>
    </PageContainer>
  );
};

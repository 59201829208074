import AddBoxIcon from "@mui/icons-material/AddBox";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { spacing } from "assets/styles/theme";
import { explanations } from "libs/utilities/explanations";

import {
  Dialog,
  InfoAlert,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";

import { EntityChip } from "./EntityChip";
import useEntitySelection from "./useEntitySelection";
import useResolveDeploymentDependencies from "./useResolveDeploymentDependencies";
import { useSelectAllEntities } from "./useSelectAllEntities";
import { ImportConfirmSection } from "../../imports/create/ImportConfirmSection";
import { EntityType } from "../../imports/create/types";
import { defaultNewNamesLayer } from "../../imports/create/useImportConflicts";

import type { AddedEntity, Entity } from "./types";
import type {
  DeploymentVersionDetail,
  ImportDetailDeployments,
} from "libs/data/models";

interface AddDeploymentsProps {
  deployments: AddedEntity[];
  deploymentEntities: ImportDetailDeployments | undefined;
  fetchDeploymentVersions: (
    name: string
  ) => Promise<{ [key: string]: DeploymentVersionDetail }>;
  setDeployments: (deployments: AddedEntity[]) => void;
  requiredDeployments: string[];
  setDeploymentEntities: (entity: Entity) => void;
}

export const AddDeployments = ({
  deployments,
  setDeployments,
  deploymentEntities,
  fetchDeploymentVersions,
  requiredDeployments,
  setDeploymentEntities,
}: AddDeploymentsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    isEntitySelected,
    toggleEntity,
    isVersionSelected,
    toggleVersion,
    setSelectedVersions,
    selectedVersions,
  } = useEntitySelection(deploymentEntities, fetchDeploymentVersions);

  const { selectAll } = useSelectAllEntities(
    deploymentEntities,
    fetchDeploymentVersions,
    setSelectedVersions,
    setDeploymentEntities
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedVersions([]);
    }
  }, [isOpen, setSelectedVersions]);

  useResolveDeploymentDependencies(
    requiredDeployments,
    deployments,
    setDeployments,
    fetchDeploymentVersions
  );

  const onAdd = () => {
    setDeployments(selectedVersions);
    setIsOpen(false);
  };

  return (
    <>
      <SecondaryButton
        style={{
          width: "fit-content",
        }}
        startIcon={<AddBoxIcon />}
        onClick={() => {
          setSelectedVersions(deployments);
          setIsOpen(true);
        }}
      >
        Add deployments
      </SecondaryButton>
      <Dialog
        title="Select deployments"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        Actions={
          <>
            <SecondaryButton
              onClick={() => setIsOpen(false)}
              style={{ marginRight: spacing[16] }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onAdd}>Add</PrimaryButton>
          </>
        }
      >
        <>
          <Typography variant="h5" marginBottom={spacing[8]}>
            Select which deployments to add to your export
          </Typography>
          <ImportConfirmSection
            title=""
            entityType="deployment"
            newNamesLayer={defaultNewNamesLayer}
            onRowExpand={fetchDeploymentVersions}
            entityRequirementType={EntityType.pipelines}
            entities={deploymentEntities}
            isEntitySelected={(name) =>
              isEntitySelected(name) || requiredDeployments.includes(name)
            }
            isEntityRequired={(name) => requiredDeployments.includes(name)}
            toggleEntity={toggleEntity}
            isVersionSelected={isVersionSelected}
            toggleVersion={toggleVersion}
            toggleAll={selectAll}
            openConflictDialog={() => false}
            isMissingEnvironmentVariables={() => false}
            isEntityNameConflict={() => false}
            disableAutoToggle
            innerScroll
          />
        </>
      </Dialog>
      {deployments.length === 0 && (
        <InfoAlert style={{ width: "fit-content" }}>
          No deployments selected
        </InfoAlert>
      )}
      {deployments.length > 0 && <Typography>Added deployments: </Typography>}
      <Box>
        {deployments.map(({ name, version }) => (
          <EntityChip
            key={`${name}-${version}`}
            name={name}
            version={version}
            disabled={requiredDeployments.includes(name)}
            title={
              explanations.importsExports.exports.createExport
                .requiredDeployment
            }
            onDelete={() =>
              setDeployments(
                deployments.filter(
                  (deployment) =>
                    deployment.name !== name || deployment.version !== version
                )
              )
            }
          />
        ))}
      </Box>
    </>
  );
};

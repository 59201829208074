import { Box, Typography } from "@mui/material";

import { IlluIncorrectPermissions } from "assets/images/IlluIncorrectPermissions";
import { spacing } from "assets/styles/theme";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";

import { Dialog } from "components/atoms";

export const IncorrectPermissionsDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      Header={
        <DialogWarningHeader title="You do not have the correct permissions" />
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={spacing[12]}
      >
        <IlluIncorrectPermissions />
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: spacing[20], fontWeight: 600 }}
        >
          You do not have the correct permissions to access this bucket. Please
          contact your admin to enable permissions.
        </Typography>
      </Box>
    </Dialog>
  );
};

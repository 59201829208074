import { ENV_NAMES, env } from "libs/env";

export const useSignOut = () => {
  return () => {
    env.unset(ENV_NAMES.ACCESS_TOKEN);
    env.unset(ENV_NAMES.REFRESH_TOKEN);
    env.unset(ENV_NAMES.ACTIVATION_TOKEN);
    env.unset(ENV_NAMES.REMEMBER_ME);
    window.location.pathname = "/sign-in";
  };
};

const loadExternalScript = (
  src: string,
  id: string,
  targetElement = document.head
) => {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = id;
      script.src = src;

      script.addEventListener("load", resolve);
      script.addEventListener("error", (e) => reject(e.error));

      targetElement.appendChild(script);
    } else {
      reject("script already loaded");
    }
  });
};

export async function injectExternalScript(
  src: string,
  id: string,
  targetElement = document.head
) {
  return loadExternalScript(src, id, targetElement)
    .then(() => {
      const DOMContentLoaded_event = document.createEvent("Event");
      DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);

      return;
    })
    .catch((error) => {
      console.error(error);
    });
}

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentVersionsDelete,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeploymentVersionDelete = (
  projectName: string,
  deploymentName: string
) => {
  const dispatch = useDispatch();
  const { mutate } = useDeploymentVersionsList(
    projectName,
    deploymentName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      },
    }
  );

  return useCallback(
    async (versionName: string) => {
      try {
        await deploymentVersionsDelete(
          projectName,
          deploymentName,
          versionName
        );
        dispatch(createSuccessNotification("Deployment version was deleted"));
        await mutate((versions) =>
          versions?.filter((version) => version.version !== versionName)
        );

        return true;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return false;
      }
    },
    [projectName, deploymentName, dispatch, mutate]
  );
};

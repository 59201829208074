import React, { useContext, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import { STRUCTURED_TYPE } from "libs/constants/constants";
import {
  FIELD_NAME,
  FIELD_DESCRIPTION,
  FIELD_INPUT_TYPE,
  FIELD_INPUT_FIELDS,
  FIELD_OUTPUT_TYPE,
  FIELD_OUTPUT_FIELDS,
  FIELD_LABELS,
  FIELD_DEFAULT_VERSION,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import { usePipelineUpdate } from "libs/data/customized/pipeline";
import {
  usePipelinesGet,
  usePipelineVersionsList,
} from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import {
  formatInputOutputFields,
  formatOldInputOutputFields,
} from "libs/utilities/input-parser";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { getChanges } from "libs/utilities/patch-helper";

import {
  InputOutputField,
  FormSection,
  GeneralFieldsSection,
  PageContainer,
} from "components/molecules";
import { FormContainer, LabelsForm } from "components/organisms";

const PipelineUpdate = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const rootUrl = useContext(RootUrlContext);

  const history = useHistory();
  const match = useRouteMatch();
  const { projectName, pipelineName } = useParams();
  const [originalPipeline, setOriginalPipeline] = useState(undefined);

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const { data: pipelineVersions } = usePipelineVersionsList(
    projectName,
    pipelineName
  );
  const updatePipeline = usePipelineUpdate(projectName, pipelineName);
  const labels = useMemo(
    () => (pipeline?.labels ? formatLabels(pipeline.labels) : []),
    [pipeline]
  );

  useEffect(() => {
    if (originalPipeline === undefined && pipeline) {
      setOriginalPipeline({ ...pipeline });
    }
  }, [originalPipeline, pipeline]);

  const methods = useForm({ mode: "onBlur", defaultValues: { labels } });

  const handlePipelineUpdate = (data) => {
    // Make sure old pipeline input/output fields are structured similarly for a fair comparison (remove "tableData")
    let oldPipeline = pipeline;
    if (
      oldPipeline[FIELD_INPUT_TYPE] === STRUCTURED_TYPE &&
      oldPipeline[FIELD_INPUT_FIELDS]
    ) {
      oldPipeline[FIELD_INPUT_FIELDS] = formatOldInputOutputFields(
        oldPipeline[FIELD_INPUT_FIELDS]
      );
    }
    if (
      oldPipeline[FIELD_OUTPUT_TYPE] === STRUCTURED_TYPE &&
      oldPipeline[FIELD_OUTPUT_FIELDS]
    ) {
      oldPipeline[FIELD_OUTPUT_FIELDS] = formatOldInputOutputFields(
        oldPipeline[FIELD_OUTPUT_FIELDS]
      );
    }

    const newPipeline = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_INPUT_TYPE]: data[FIELD_INPUT_TYPE],
      [FIELD_OUTPUT_TYPE]: data[FIELD_OUTPUT_TYPE],
    };

    if (data[FIELD_DEFAULT_VERSION]) {
      newPipeline[FIELD_DEFAULT_VERSION] = data[FIELD_DEFAULT_VERSION].value;
    }

    if (data[FIELD_INPUT_TYPE] === STRUCTURED_TYPE) {
      newPipeline[FIELD_INPUT_FIELDS] = data[FIELD_INPUT_FIELDS]
        ? formatInputOutputFields(data[FIELD_INPUT_FIELDS])
        : [];
    }

    if (data[FIELD_OUTPUT_TYPE] === STRUCTURED_TYPE) {
      newPipeline[FIELD_OUTPUT_FIELDS] = data[FIELD_OUTPUT_FIELDS]
        ? formatInputOutputFields(data[FIELD_OUTPUT_FIELDS])
        : [];
    }
    let changes = getChanges(newPipeline, oldPipeline);
    changes[FIELD_LABELS] = formatLabelsForRequest(data[FIELD_LABELS]);

    updatePipeline(changes)
      .then(() => {
        dispatch(createSuccessNotification("The pipeline was updated"));
        history.replace(`${rootUrl}/pipelines/${data[FIELD_NAME]}/general`);
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      });
  };

  const options = useMemo(
    () =>
      pipelineVersions?.map(({ version }) => ({
        label: version,
        value: version,
      })) || [],
    [pipelineVersions]
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Edit</BreadcrumbsItem>
      <PageContainer>
        <PageHeader title={`Edit "${pipelineName}"`} />

        <FormContainer
          buttonLabel="Save"
          onSubmit={handlePipelineUpdate}
          formMethods={methods}
        >
          {!!pipeline && !!originalPipeline && (
            <>
              <GeneralFieldsSection
                validateValue="pipeline"
                nameDefaultValue={pipeline.name}
                descriptionDefaultValue={pipeline.description}
                namePlaceholder="Ex: my-pipeline-1"
              >
                <AutoCompleteSelectHookForm
                  name={FIELD_DEFAULT_VERSION}
                  label="Default version"
                  options={options || []}
                  defaultValue={options?.find(
                    ({ value }) => value === pipeline.default_version
                  )}
                  isClearable={false}
                  isSearchable={true}
                />
              </GeneralFieldsSection>
              <InputOutputField
                title="Input"
                description={explanations.pipelines.input}
                defaultType={originalPipeline.input_type}
                defaultFields={originalPipeline.input_fields}
                typeName={FIELD_INPUT_TYPE}
                fieldsName={FIELD_INPUT_FIELDS}
              />
              <InputOutputField
                title="Output"
                description={explanations.pipelines.output}
                defaultType={originalPipeline.output_type}
                defaultFields={originalPipeline.output_fields}
                typeName={FIELD_OUTPUT_TYPE}
                fieldsName={FIELD_OUTPUT_FIELDS}
              />
              <FormSection title="Labels">
                <LabelsForm name={FIELD_LABELS} />
              </FormSection>
            </>
          )}
        </FormContainer>
      </PageContainer>
    </>
  );
};

export default PipelineUpdate;

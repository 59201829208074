import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Grid, List, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import { useOrganizationUser } from "libs/data/customized/user";

import { ConditionalWrapper, Loader } from "components/atoms";
import { ComputeUsageProgressBar } from "components/molecules";

import type { OrganizationDetail, OrganizationFeature } from "libs/data/models";

type SidebarUsageProps = {
  toggleSidebar: () => void;
  sidebarIsOpen: boolean;
  organization: OrganizationDetail | null;
  organizationFeatures: OrganizationFeature | null;
  usage: number;
};

const SidebarUsage = ({
  toggleSidebar,
  organization,
  organizationFeatures,
  sidebarIsOpen: isOpen,
  ...props
}: SidebarUsageProps) => {
  const { isAdmin } = useOrganizationUser(organization?.name);

  const history = useHistory();

  const handleClick = () => {
    toggleSidebar();
    history.push(`/organizations/${organization?.name}/subscription`);
  };

  return isAdmin ? (
    <List>
      {!isEmpty(organization) ? (
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {isOpen ? (
            <Grid item xs={10}>
              <ComputeUsageProgressBar
                maxUsage={organizationFeatures?.max_credits}
                isSidebar
                {...props}
              />
            </Grid>
          ) : (
            <ConditionalWrapper
              condition={!isOpen}
              wrapper={(children) => (
                <Tooltip title="My subscription" placement="right">
                  {children}
                </Tooltip>
              )}
            >
              <ListItem
                onClick={handleClick}
                className="sidebar-support__item"
                button
                disabled={!isOpen && !isAdmin}
              >
                <ListItemIcon>
                  <CreditCardIcon fontSize="small" color="primary" />
                </ListItemIcon>
              </ListItem>
            </ConditionalWrapper>
          )}
        </Grid>
      ) : (
        <Loader />
      )}
    </List>
  ) : null;
};

export default SidebarUsage;

import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";

export const useStringToColor = (object: string) => {
  const colors = useGetSpecialColors();

  const index = hashString(object) % colors.length;

  return colors[index];
};

const hashString = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return Math.abs(hash);
};

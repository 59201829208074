import { Grid } from "@mui/material";
import { find } from "lodash";
import { useState } from "react";

import { explanations } from "libs/utilities/explanations";
import { getRandomId } from "libs/utilities/utils";

import { FormSection } from "components/molecules";
import { EnvVarsCopy, EnvVarsOverview } from "components/organisms";

import { TRAINING_DEPLOYMENT } from "../constants";

import type { InheritedEnvironmentVariableList } from "libs/data/models";

type ExperimentCreateEnvVarsProps = {
  variables: InheritedEnvironmentVariableList[] | [];
  setVariables: React.Dispatch<
    React.SetStateAction<InheritedEnvironmentVariableList[] | []>
  >;
};

export const ExperimentCreateEnvVars = ({
  variables,
  setVariables,
}: ExperimentCreateEnvVarsProps) => {
  const [showOverview, setShowOverview] = useState(true);

  const onEnvVarCreate = () => {
    setShowOverview(false);
  };

  const onEnvVarCopy = () => {
    setShowOverview(true);
  };

  const handleCopy = (copiedEnvVars: InheritedEnvironmentVariableList[]) => {
    setVariables((envVars) => [...envVars, ...copiedEnvVars]);
  };

  const handleOnAdd = (variable: InheritedEnvironmentVariableList) => {
    setVariables((envVars) => [
      ...envVars,
      {
        name: variable.name,
        value: variable?.value,
        // @ts-expect-error TODO secret value, string gets passed but should be bool due to AdvancedTable add functionality
        secret: variable.secret === "true" || variable.secret === true,
        id: getRandomId(),
      },
    ]);
  };

  const handleOnDelete = (variable: InheritedEnvironmentVariableList) => {
    setVariables((envVars) =>
      envVars.filter((envVar) => envVar.name !== variable.name)
    );
  };

  const handleOnChange = (
    id: string,
    variable: InheritedEnvironmentVariableList
  ) => {
    const oldVariable = find(variables, (oldEnvVar) => oldEnvVar.id === id);

    if (oldVariable) handleOnDelete(oldVariable);
    handleOnAdd(variable);
  };

  return (
    <FormSection
      title="Environment variables"
      description={explanations.training.experiments.environment_variables}
    >
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {showOverview ? (
          <Grid item xs={12}>
            <EnvVarsOverview
              deploymentName={TRAINING_DEPLOYMENT}
              warningMessage="The experiment is restarting and your changes will be available in ~30 seconds"
              onCopyClick={onEnvVarCreate}
              isUsedInForm
              onAdd={handleOnAdd}
              onDelete={handleOnDelete}
              onChange={handleOnChange}
              envVars={variables}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <EnvVarsCopy
              deploymentName={TRAINING_DEPLOYMENT}
              onCopySuccess={onEnvVarCopy}
              isPage={false}
              onCopy={handleCopy}
            />
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
};

import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";
import { Box, Typography, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { BoxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const StatusIcon = ({
  variant,
  ...props
}: BoxProps & { variant?: "error" | "warning" }) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      component={!variant ? CheckCircle : Warning}
      width={spacing[28]}
      height={spacing[28]}
      color={
        variant && variant === "error"
          ? theme.palette.error.main
          : theme.palette.warning.main
      }
      {...props}
    />
  );
};

export const StatusTitle = ({ variant }: { variant: "error" | "warning" }) => (
  <Typography
    variant="h5"
    display="inline"
    style={{ marginTop: "1px", marginRight: "2px" }}
  >
    {variant === "error" ? "Error: " : "Warning: "}
  </Typography>
);

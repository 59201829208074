import { useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useParams, useRouteMatch } from "react-router-dom";

import { CustomToggle } from "components/atoms/CustomToggle";
import { EmptyCustomMetricsCard } from "components/organisms/MetricsLayout/EmptyCustomMetricsCard";
import { FIELD_DATES } from "libs/constants/fields";
import { useCustomMetrics } from "libs/data/customized/custom-metrics/useCustomMetrics";
import {
  deploymentMetricsGraphIds,
  useDeploymentMetrics,
} from "libs/data/customized/deployment-metrics";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import { getStoredChartType } from "libs/utilities/metrics-helper";

import { MetricsLayout } from "components/organisms";

import type { DeploymentVersionDetailsRouteParams } from "./types";

const options = [
  {
    title: "Request rate",
    metric: [deploymentMetricsGraphIds.requests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Failed request rate",
    metric: [deploymentMetricsGraphIds.failedRequests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Request duration",
    metric: [deploymentMetricsGraphIds.requestDuration],
  },
  {
    title: "Deployment credit usage",
    metric: [deploymentMetricsGraphIds.credits],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Active instances",
    metric: [deploymentMetricsGraphIds.instances],
  },
  {
    title: "Queue time per express request",
    metric: [deploymentMetricsGraphIds.expressQueueTime],
  },
  {
    title: "Queue time per batch request",
    metric: [deploymentMetricsGraphIds.batchQueueTime],
  },
  {
    title: "Queue express size",
    metric: [deploymentMetricsGraphIds.expressQueueSize],
  },
  {
    title: "Queue batch size",
    metric: [deploymentMetricsGraphIds.batchQueueSize],
  },
];

export const DeploymentVersionMetrics = () => {
  const { projectName, deploymentName, versionName } =
    useParams<DeploymentVersionDetailsRouteParams>();

  const { data: version } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const methods = useForm({
    defaultValues: {
      dates: {
        startDate: getTzAwareDate().subtract(1, "hour"),
        endDate: getTzAwareDate(),
      },
    },
  });
  const { control } = methods;

  const dates: any = useWatch({ control, name: FIELD_DATES });

  useGoogleAnalytics();

  const match = useRouteMatch();

  const [forceRefresh, setForceRefresh] = useState(false);

  const {
    isCustom,
    isLoading,
    setCustom,
    options: customOptions,
  } = useCustomMetrics(version?.id && `deployment_version_id:${version?.id}`);

  const optionsWithChartType = useMemo(
    () => getStoredChartType("deployment", isCustom ? customOptions : options),
    [customOptions, isCustom]
  );

  const { metricGraphs, isMetricGraphsLoading, setGraphType } =
    useDeploymentMetrics(
      [version?.id],
      optionsWithChartType,
      forceRefresh,
      dates.startDate,
      dates.endDate
    );

  const onGraphRefresh = () => {
    setForceRefresh(!forceRefresh);
  };

  return (
    <FormProvider {...methods}>
      <BreadcrumbsItem to={match.url}>Metrics</BreadcrumbsItem>
      <MetricsLayout
        graphs={metricGraphs || []}
        onGraphRefresh={onGraphRefresh}
        isLoading={(isMetricGraphsLoading || isLoading) && !metricGraphs}
        enableCrosshair
        setGraphType={setGraphType}
        defaultSelectedRange={"Last Hour"}
      >
        <CustomToggle
          label="Filter graphs:"
          isCustom={isCustom}
          setCustom={setCustom}
        />
      </MetricsLayout>
      {isCustom && !isMetricGraphsLoading && metricGraphs?.length === 0 && (
        <EmptyCustomMetricsCard type="deployment" />
      )}
    </FormProvider>
  );
};

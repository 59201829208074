import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams } from "react-router-dom";

import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { formatLabels } from "libs/utilities/labels-util";
import PipelineVersionsOverview from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/PipelineVersionsOverview";

import {
  DetailsItem,
  Card,
  OverflowTooltip,
  CopyToClipboardButton,
} from "components/atoms";
import { DescriptionBlock, EndpointUrlCopy } from "components/molecules";

const PipelineGeneral = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();
  const { projectName, pipelineName } =
    useParams<{ projectName: string; pipelineName: string }>();

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const labels = useMemo(
    () => (pipeline?.labels ? formatLabels(pipeline.labels) : []),
    [pipeline]
  );

  const endpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/pipelines/${pipelineName}`;

  return (
    <>
      <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="ID">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <OverflowTooltip title={pipeline?.id}>
                  {pipeline?.id}
                </OverflowTooltip>
                {pipeline?.id && (
                  <CopyToClipboardButton
                    defaultLabel="Copy ID"
                    contentToCopy={pipeline?.id}
                    size="small"
                    htmlColor="secondary"
                  />
                )}
              </Box>
            </DetailsItem>
            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(pipeline?.creation_date).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Edited">
              <Typography>
                {getTzAwareDate(pipeline?.last_updated).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <EndpointUrlCopy url={endpointUrl} />
          </Card>
        </Grid>
        <DescriptionBlock description={pipeline?.description} labels={labels} />
        <Grid item xs={12}>
          {!!pipeline && <PipelineVersionsOverview />}
        </Grid>
      </Grid>
    </>
  );
};

export default PipelineGeneral;

import { Box, Typography, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { TypographyProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

type LogMessageProps = TypographyProps & {
  backgroundColor?: string;
};

export const LogMessage = ({ backgroundColor, ...props }: LogMessageProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      style={{
        alignItems: "center",
        backgroundColor:
          backgroundColor || theme.palette.list.item.background.selected,
        display: "flex",
        justifyContent: "center",
        padding: spacing[8],
      }}
    >
      <Typography
        {...props}
        style={{
          color: theme.palette.neutrals[400],
          fontSize: 12,
          fontWeight: 500,
          textAlign: "center",
        }}
      />
    </Box>
  );
};

import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  useTheme,
} from "@mui/material";

import { useColorByStatus } from "libs/hooks";
import { toUpperFirstCase } from "libs/utilities/util-functions";

import type { ToggleButtonGroupProps as MuiButtonGroupProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

type ToggleButtonGroupProps = {
  items: { title: string; value?: string }[];
  onChange: (value: string) => void;
  value?: string;
} & Omit<MuiButtonGroupProps, "onChange">;

export const ToggleButtonGroup = ({
  items,
  onChange,
  value,
  ...props
}: ToggleButtonGroupProps) => {
  const theme = useTheme() as AppThemeProps;
  const colorByStatus = useColorByStatus();

  const buttonStyles = (status: string) => {
    const backgroundColor = colorByStatus(status);
    const color = theme.palette.primary.contrastText;

    return {
      height: "28px",
      padding: "2px 11px",
      color: theme.palette.text.secondary,
      borderColor: theme.palette.buttons.toggleButtonBorder,
      "&:hover": {
        backgroundColor: backgroundColor,
        color: color,
        borderLeft: `1px solid ${theme.palette.buttons.toggleButtonBorder} !important`,
      },
      "&.Mui-selected": {
        backgroundColor: backgroundColor,
        color: color,
      },
      "&.Mui-selected:hover": {
        backgroundColor: backgroundColor,
        color: color,
      },
    };
  };

  return (
    <MuiToggleButtonGroup
      onChange={(_e, newValue) => onChange(newValue)}
      value={value}
      exclusive
      size="small"
      {...props}
    >
      {items.map(({ title, value }) => (
        <ToggleButton
          disableFocusRipple={true}
          value={value ?? title}
          key={title}
          sx={buttonStyles(title)}
        >
          {toUpperFirstCase(title)}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

import { useHistory, useParams } from "react-router";

import {
  Dialog,
  DialogWarningHeader,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";

type Props = {
  onClose: () => void;
  handleGoBack?: () => void;
  children: React.ReactNode;
  open: boolean;
};

export const UpgradeSubscriptionDialog = ({
  onClose,
  children,
  open,
  handleGoBack,
}: Props) => {
  const history = useHistory();
  const { organizationName } = useParams<{ organizationName: string }>();

  const upgradeSubscription = () => {
    history.push(`/organizations/${organizationName}/subscription`);
  };

  return (
    <Dialog
      open={open}
      Header={<DialogWarningHeader title="Subscription limit reached" />}
      onClose={onClose}
      dismissible={false}
      Actions={
        <>
          <SecondaryButton
            onClick={handleGoBack || history.goBack}
            style={{ marginRight: 14 }}
          >
            Go back
          </SecondaryButton>
          <PrimaryButton onClick={upgradeSubscription}>
            Upgrade subscription
          </PrimaryButton>
        </>
      }
      disableEscapeKeyDown
    >
      {children}
    </Dialog>
  );
};

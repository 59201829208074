import styled from "@emotion/styled";
import { Box, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";

import { TextField } from "components/atoms/TextField";
import { validateRange } from "components/molecules/Fields/DateTimeRangeSelector/utils";
import { getTzAwareDate } from "libs/utilities/date-util";

import { _FROM, _TO, _INTERNAL, defaultRange } from "./constants";

import type {
  InternalDateTimeRange,
  DateTimeRangeSelectorProps,
  RangeObject,
} from "./types";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import type { AppThemeProps } from "assets/styles/theme/theme";

const DateTimeRangeSelectorComponent = ({
  name,
  onChange,
  value,
  minDate,
  isMobile,
}: DateTimeRangeSelectorProps) => {
  const [range, setRange] = useState(
    value ? { ...value, key: "range" } : { ...defaultRange, key: "range" }
  );
  const minAvailableDate =
    minDate ?? getTzAwareDate().subtract(1, "year").add(1, "day").toDate();

  useEffect(() => {
    if (
      value.endDate !== range.endDate ||
      value.endTime !== range.endTime ||
      value.startDate !== range.startDate ||
      value.startTime !== range.startTime
    ) {
      onChange(range);
    }
  }, [onChange, range, value]);

  const theme = useTheme() as AppThemeProps;

  const handleOnChange = (data: RangeObject) => {
    updateRange(data.range);
  };

  const validationErrors = useMemo(() => validateRange(range), [range]);

  const updateRange = (partialRange: Partial<InternalDateTimeRange>) => {
    setRange((previousRange) => ({ ...previousRange, ...partialRange }));
  };

  return (
    <StyledBox theme={theme}>
      <StyledDateRangePicker
        theme={theme}
        color={theme.palette.primary.main}
        direction="horizontal"
        inputRanges={[]}
        months={isMobile ? 1 : 2}
        maxDate={new Date()}
        minDate={minAvailableDate}
        onChange={(data) => handleOnChange(data as unknown as RangeObject)}
        rangeColors={[theme.palette.primary.main]}
        ranges={[range]}
        showDateDisplay={false}
        staticRanges={[]}
        scroll={{ enabled: isMobile, calendarHeight: 300 }}
        isMobile={isMobile}
      />
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <TextField
          error={!!validationErrors?.startTime}
          helperText={validationErrors?.startTime}
          label="From"
          size="small"
          name={name + _INTERNAL + _FROM}
          onChange={({ target: { value } }) =>
            updateRange({ startTime: value })
          }
          style={{ width: "100%", maxWidth: 312 }}
          type="time"
          value={range.startTime}
          sx={{
            "input[type='time']::-webkit-calendar-picker-indicator": {
              filter:
                "invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg) brightness(90%) contrast(95%)",
            },
          }}
        />

        <TextField
          error={!!validationErrors?.endTime}
          helperText={validationErrors?.endTime}
          label="To"
          size="small"
          name={name + _INTERNAL + _TO}
          onChange={({ target: { value } }) => updateRange({ endTime: value })}
          style={{ width: "100%", maxWidth: 312 }}
          type="time"
          value={range.endTime}
          sx={{
            "input[type='time']::-webkit-calendar-picker-indicator": {
              filter:
                "invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg) brightness(90%) contrast(95%)",
            },
          }}
        />
      </Box>
    </StyledBox>
  );
};

export const DateTimeRangeSelector = React.memo(
  DateTimeRangeSelectorComponent,
  (prev, curr) => {
    const sameMinDate =
      prev?.minDate?.toISOString?.() === curr?.minDate?.toISOString?.();

    const sameName = prev?.name === curr?.name;

    const sameStartDate =
      prev?.value?.startDate?.toISOString?.() ===
      curr?.value?.startDate?.toISOString?.();

    const sameEndDate =
      prev?.value?.endDate?.toISOString?.() ===
      curr?.value?.endDate?.toISOString?.();

    const sameEndTime = prev?.value?.endTime === curr?.value?.endTime;
    const sameStartTime = prev?.value?.startTime === curr?.value?.startTime;

    return (
      sameStartDate &&
      sameEndDate &&
      sameEndTime &&
      sameStartTime &&
      sameMinDate &&
      sameName
    );
  }
);

const StyledBox = styled(Box)<{ theme: AppThemeProps }>`
  padding: 6px;
  background-color: transparent;
  .rdrDefinedRangesWrapper {
    width: 80px;
  }
  ,
  .rdrDateRangePickerWrapper {
    display: flex;
    margin-bottom: -55px;

    .rdrDefinedRangesWrapper {
      align-self: center;
    }

    .rdrDateRangeWrapper {
      padding-bottom: 40px;

      .rdrDayToday .rdrDayNumber span:after {
        background: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)<{
  theme: AppThemeProps;
  isMobile: boolean;
}>`
  .rdrCalendarWrapper {
    background-color: transparent;

    .rdrDay {
      span {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
    .rdrDayDisabled {
      background-color: transparent;
      span {
        color: ${(props) => props.theme.palette.text.disabled};
      }
    }
  }

  .rdrDefinedRangesWrapper {
    background-color: transparent;
    border-right: solid 1px ${(props) => props.theme.palette.background.active};
  }

  .rdrStaticRange {
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.primary};
    bordercolor: transparent;
    border-bottom: 1px solid ${(props) => props.theme.palette.background.active};
  }

  .rdrMonthAndYearWrapper {
    select {
      color: ${(props) => props.theme.palette.text.primary};
      svg {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
  .rdrInfiniteMonths.rdrMonthsHorizontal {
    margin-bottom: 8px;
  }

  .rdrNextPrevButton {
    display: ${(props) => (props.isMobile ? "none" : "block")};
    background: ${(props) => props.theme.palette.background.active};
    color: ${(props) => props.theme.palette.text.primary};
    i {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

import { spacing } from "assets/styles/theme";
import { Alert } from "components/atoms/Alert/Alert";

import type { AlertProps } from "components/atoms/Alert/types";

export const InfoAlert = ({ children, style = {} }: AlertProps) => (
  // We set classname to "" so that we can eventually remove this from Alert.tsx
  <Alert
    className=""
    severity="info"
    style={{ marginBottom: spacing[16], ...style }}
  >
    {children}
  </Alert>
);

import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { PLAIN_TYPE, STRUCTURED_TYPE } from "libs/constants/constants";
import { explanations } from "libs/utilities/explanations";
import { FIELD_EDITOR, JSON_EDITOR } from "libs/utilities/input-parser";

import { Radio } from "components/atoms";

import { DynamicFields } from "../../DynamicFields";
import { EditorsView } from "../../EditorsView/EditorsView";
import { FormSection } from "../../FormSection";

import type { InputOutputFieldList } from "libs/data/models";
import type { InputOutputDataField } from "libs/utilities/input-parser";
import type { ChangeEvent } from "react";

type InputOutputFieldProps = {
  title: string;
  typeName: string;
  fieldsName: string;
  defaultType?: string;
  defaultFields?: InputOutputFieldList[];
  description?: string;
  disabled?: boolean;
  placeholder?: string;
  isEditForm?: boolean;
};

export const InputOutputField = ({
  title,
  defaultType = STRUCTURED_TYPE,
  defaultFields = [],
  typeName,
  fieldsName,
  description,
  disabled = false,
  placeholder,
  isEditForm = false,
}: InputOutputFieldProps) => {
  const { getValues, register, setValue, setError } = useFormContext();
  const [fields, setFields] = useState(defaultFields);
  const jsonAreaName = useMemo(
    () => `${fieldsName}-${JSON_EDITOR}`,
    [fieldsName]
  );
  const editorFieldName = useMemo(
    () => `${fieldsName}-${FIELD_EDITOR}`,
    [fieldsName]
  );
  const jsonField: string | undefined = useWatch({
    name: jsonAreaName,
    defaultValue: "[]",
  });
  const type = useWatch({ name: typeName, defaultValue: defaultType });

  useEffect(() => {
    if (
      defaultFields &&
      JSON.stringify(defaultFields) !== JSON.stringify(fields)
    ) {
      setFields(defaultFields);
    }
    //eslint-disable-next-line
  }, [defaultFields, setFields]);

  useEffect(() => {
    if (jsonField && type === STRUCTURED_TYPE) {
      try {
        setFields(JSON.parse(jsonField.toString()));
      } catch (e) {
        setError(jsonAreaName, {
          type: "validate",
          message: "Invalid JSON format",
        });
      }
    }
  }, [jsonField, jsonAreaName, setError, type]);

  const handleEditorChange = (
    _event: ChangeEvent<unknown>,
    newValue: string
  ) => {
    if (newValue === JSON_EDITOR) {
      const updatedFields = getFieldsValues();
      setFields(updatedFields);
      setValue(jsonAreaName, JSON.stringify(updatedFields, null, 1));
    } else {
      try {
        const updatedFields = getValues(jsonAreaName);
        setFields(JSON.parse(updatedFields));
      } catch (e) {
        setError(jsonAreaName, {
          type: "validate",
          message: "Invalid JSON format",
        });
      }
    }
    setValue(editorFieldName, newValue);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFields = getFieldsValues(fields);

    if (event.target.value === PLAIN_TYPE) {
      setFields(updatedFields);
    }
  };

  const getFieldsValues = (fields?: InputOutputFieldList[]) => {
    const currentFields = getValues()[fieldsName];

    return currentFields
      ? (Object.values(currentFields) as InputOutputDataField[]).map(
          (field) => ({
            name: field.name,
            data_type: field.data_type?.value,
          })
        )
      : fields
      ? fields
      : [];
  };

  return (
    <FormSection title={title} description={description}>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography variant="h4">Type</Typography>
        </Box>
        <Box display="flex" paddingY={spacing[12]}>
          <Radio
            value={STRUCTURED_TYPE}
            name={typeName}
            register={register}
            onChange={handleTypeChange}
            id={`${typeName}_structured`}
            label="Structured"
            tooltip={explanations.inputOutputType.structured}
            defaultChecked={defaultType === STRUCTURED_TYPE}
            disabled={disabled || isEditForm}
            style={{ width: "40%", minWidth: "max-content" }}
          />
          <Radio
            value={PLAIN_TYPE}
            name={typeName}
            id={`${typeName}_plain`}
            register={register}
            onChange={handleTypeChange}
            label="Plain"
            tooltip={explanations.inputOutputType.plain}
            defaultChecked={defaultType === PLAIN_TYPE}
            disabled={disabled || isEditForm}
          />
        </Box>

        {type === STRUCTURED_TYPE && (
          <>
            <Box>
              <Typography variant="h4">Fields</Typography>
            </Box>
            <Box paddingY={spacing[12]} id={fieldsName}>
              <EditorsView
                disabled={disabled}
                editorFieldName={editorFieldName}
                jsonAreaName={jsonAreaName}
                formFields={fields}
                onEditorChange={handleEditorChange}
              >
                <Box>
                  <DynamicFields
                    name={fieldsName}
                    fields={fields}
                    disabled={disabled}
                    placeholder={placeholder}
                  />
                </Box>
              </EditorsView>
            </Box>
          </>
        )}
      </Box>
    </FormSection>
  );
};

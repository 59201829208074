import { Grid, Typography, AlertTitle } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { RequestResultSection } from "components/molecules/RequestResults/RequestResultSection";
import { deploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  pipelineVersionObjectRequestsGet,
  pipelineVersionRequestsGet,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { PipelineVersionUpdateRequestRetentionMode } from "libs/data/models";
import {
  FULL_DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { createErrorNotification } from "libs/utilities/notifications";

import {
  Alert,
  Card,
  Loader,
  OverflowTooltip,
  DetailsDialogItem,
  StatusIcon,
  Link,
} from "components/atoms";

import {
  getObjectLink,
  openDataInNewTab,
  shouldFetchMetadataOnly,
} from "./utils";

import type { PipelineRequestResultsRecord } from "./PipelineRequestDetails";
import type { AxiosError } from "axios";
import type {
  DeploymentRequestSingleDetail,
  OperatorRequestDetail,
} from "libs/data/models";

export const PipelineObjectRequestResultsDetails = ({
  request,
  projectName,
  organizationName,
  pipelineName,
  pipelineVersion,
  pipelineRequestId,
}: {
  request: PipelineRequestResultsRecord;
  projectName: string;
  organizationName: string;
  pipelineName: string;
  pipelineVersion: string;
  pipelineRequestId: string;
}) => {
  const dispatch = useDispatch();

  const {
    object_type,
    object,
    id,
    deployment: deploymentName,
    pipeline: subPipelineName,
    operator: operatorName,
    version,
    status,
  } = request;

  const { data: pipelineVersionObject } = usePipelineVersionsGet(
    projectName,
    pipelineName || "",
    pipelineVersion || ""
  );

  const [isLoading, setIsLoading] = useState(true);
  const [objectResults, setObjectResults] =
    useState<DeploymentRequestSingleDetail | OperatorRequestDetail | undefined>(
      undefined
    );
  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined);

  const getPipelineObjectRequestDetails = useCallback(
    async (metadataOnly: boolean) => {
      let response;
      if (object_type === "deployment" && version && deploymentName) {
        try {
          response = await deploymentVersionRequestsGet(
            projectName,
            deploymentName,
            version,
            id,
            {
              metadata_only: !!metadataOnly,
            }
          );
        } catch (e) {
          const error = e as AxiosError;
          if (error.status !== 404) {
            dispatch(createErrorNotification(error.message));
          } else {
            setErrorMessage(
              "Try changing the retention mode of this deployment version to save in-output data and/or metadata."
            );
          }
        }
      } else if (object_type === "pipeline" && version && subPipelineName)
        try {
          response = await pipelineVersionRequestsGet(
            projectName,
            subPipelineName,
            version,
            id,
            {
              metadata_only: !!metadataOnly,
            }
          );
        } catch (e) {
          const error = e as AxiosError;
          if (error.status !== 404) {
            dispatch(createErrorNotification(error.message));
          } else {
            setErrorMessage(
              "Try changing the retention mode of this pipeline version to save in-output data and/or metadata."
            );
          }
        }
      else {
        try {
          response = await pipelineVersionObjectRequestsGet(
            projectName,
            pipelineName,
            pipelineVersion,
            id,
            {
              metadata_only: !!metadataOnly,
              pipeline_request_id: pipelineRequestId,
              pipeline_object_id: object?.id,
            }
          );
        } catch (e) {
          const error = e as AxiosError;
          if (error.status !== 404) {
            dispatch(createErrorNotification(error.message));
          } else {
            setErrorMessage(
              "Try changing the retention mode of this pipeline version to save in-output data and/or metadata."
            );
          }
        }
      }

      return response;
    },
    [
      object_type,
      version,
      deploymentName,
      subPipelineName,
      projectName,
      id,
      dispatch,
      pipelineName,
      pipelineVersion,
      pipelineRequestId,
      object?.id,
    ]
  );

  useEffect(() => {
    async function getData() {
      if (!deploymentName && !subPipelineName && !operatorName) {
        setErrorMessage("Reference object was removed.");
        setIsLoading(false);
      } else if (id) {
        setIsLoading(true);
        const response = await getPipelineObjectRequestDetails(
          shouldFetchMetadataOnly(request)
        );
        setObjectResults(response);
        setIsLoading(false);
      }
    }
    getData();
  }, [
    deploymentName,
    id,
    subPipelineName,
    operatorName,
    request,
    getPipelineObjectRequestDetails,
  ]);

  const handleViewHugeResult = async () => {
    const response = await getPipelineObjectRequestDetails(false);
    if (response) openDataInNewTab(response);
  };

  const hideResults =
    pipelineVersionObject &&
    pipelineVersionObject?.request_retention_mode !==
      PipelineVersionUpdateRequestRetentionMode.full;

  return (
    <Card>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {objectResults?.error_message && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {objectResults?.error_message}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="warning">
              <AlertTitle>No data found</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DetailsDialogItem title="Request ID" isLoaded={!!id}>
                {object_type === "operator" ||
                (!deploymentName && !subPipelineName) ? (
                  <Typography>{id}</Typography>
                ) : (
                  <Link
                    to={getObjectLink(
                      object_type,
                      organizationName,
                      projectName,
                      deploymentName ?? subPipelineName ?? "",
                      version,
                      true
                    )}
                    underline="none"
                  >
                    <Typography>{id}</Typography>
                  </Link>
                )}
              </DetailsDialogItem>
              <DetailsDialogItem title="Status" isLoaded={!!status}>
                <StatusIcon label={status} status={status} />
              </DetailsDialogItem>
              {object_type === "deployment" && (
                <DetailsDialogItem title="Deployment">
                  <Link
                    to={getObjectLink(
                      object_type,
                      organizationName,
                      projectName,
                      object?.reference_name || ""
                    )}
                    underline="always"
                  >
                    <Typography>{object?.reference_name}</Typography>
                  </Link>
                </DetailsDialogItem>
              )}
            </Grid>
            <Grid item xs={6}>
              <DetailsDialogItem
                title="Created"
                isLoaded={!!objectResults?.time_created}
              >
                <OverflowTooltip component={Typography}>
                  {getTzAwareDate(objectResults?.time_created).format(
                    FULL_DATE_TIME_FORMAT
                  )}
                </OverflowTooltip>
              </DetailsDialogItem>
              <DetailsDialogItem
                title="Started"
                isLoaded={!!objectResults?.time_started}
              >
                <OverflowTooltip component={Typography}>
                  {getTzAwareDate(objectResults?.time_started).format(
                    FULL_DATE_TIME_FORMAT
                  )}
                </OverflowTooltip>
              </DetailsDialogItem>
              <DetailsDialogItem
                title="Completed"
                isLoaded={!!objectResults?.time_completed}
              >
                <OverflowTooltip component={Typography}>
                  {getTzAwareDate(objectResults?.time_completed).format(
                    FULL_DATE_TIME_FORMAT
                  )}
                </OverflowTooltip>
              </DetailsDialogItem>
            </Grid>

            {!hideResults && (
              <RequestResultSection
                data={objectResults?.request_data}
                result={objectResults?.result}
                error={!!objectResults?.error_message}
                inputFields={
                  (object_type === "operator"
                    ? object?.configuration?.input_fields
                    : object?.input_fields) || []
                }
                outputFields={
                  (object_type === "operator"
                    ? object?.configuration?.output_fields
                    : object?.output_fields) || []
                }
                projectName={projectName}
                organizationName={organizationName}
                inputSize={objectResults?.input_size}
                outputSize={objectResults?.output_size}
                handleViewHugeResult={handleViewHugeResult}
              />
            )}
          </Grid>
        </>
      )}
    </Card>
  );
};

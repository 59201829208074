import LogsIcon from "@mui/icons-material/SubjectRounded";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { stringifyUrl } from "query-string";

import { DeploymentRequestSingleDetailStatus } from "libs/data/models";
import { getFormattedDate } from "libs/utilities/date-util";

import { NavLink } from "components/atoms";

interface RequestsLogsIconProps {
  logsUrl: string;
  rowData: any;
  allowLogs?: boolean;
}

export const RequestsLogsIcon = ({
  logsUrl,
  rowData,
  allowLogs,
}: RequestsLogsIconProps) => {
  const isPendingRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.pending;

  const toDate = moment(rowData.time_completed);
  const fromDate = moment(rowData.time_created);

  const requestUrl = stringifyUrl({
    url: logsUrl,
    query: {
      deployment_request_id: rowData.deployment ? rowData.id : undefined,
      pipeline_request_id: rowData.pipeline ? rowData.id : undefined,
      from_date: getFormattedDate(fromDate),
      to_date: getFormattedDate(toDate),
    },
  });

  const disabled = !allowLogs || isPendingRequest;

  return (
    <Tooltip
      title={
        isPendingRequest
          ? "Cannot view logs while request is still pending"
          : "View logs"
      }
    >
      <span>
        <IconButton component={NavLink} disabled={disabled} to={requestUrl}>
          <LogsIcon color="secondary" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

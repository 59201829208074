import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { RootUrlContext } from "libs/contexts";
import {
  importsUpdate,
  useImportsGet,
} from "libs/data/endpoints/imports-and-exports/imports-and-exports";
import { explanations } from "libs/utilities/explanations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import { Loader, PrimaryButton } from "components/atoms";
import { FormSection, PageContainer } from "components/molecules";

import { ImportConfirmSection } from "./ImportConfirmSection";
import { ImportConflictDialog } from "./ImportConflictDialog";
import { EntityType } from "./types";
import { useImportConfirmEdit } from "./useImportConfirmEdit";
import { useImportConflicts } from "./useImportConflicts";
import { resolveConflict } from "./utils";

import type { AxiosError } from "axios";
import type { ImportUpdate } from "libs/data/models";

interface Props {
  importId: string;
}

export const ImportConfirm = ({ importId }: Props) => {
  const { projectName } = useParams<{ projectName: string }>();
  const { data: imports, error } = useImportsGet(projectName, importId);
  const dispatch = useDispatch();
  const history = useHistory();
  const rootUrl = useContext(RootUrlContext);
  const [isConflictDialogOpen, setConflictDialogOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] =
    useState<{ type: string; name: string } | null>(null);

  const {
    editedImport,
    isEntityRequired,
    isEntitySelected,
    isVersionSelected,
    toggleEntity,
    toggleVersion,
    toggleAll,
    addEnvironmentVariableValue,
  } = useImportConfirmEdit(imports);

  const {
    isEntityNameConflict,
    isMissingEnvironmentVariables,
    newNamesLayer,
    resolveNameConflict,
    isAnyConflict,
    missingVariables,
  } = useImportConflicts(editedImport);

  const onConfirm = async () => {
    if (editedImport) {
      try {
        const editedImportWithConflictResolution = resolveConflict(
          editedImport,
          newNamesLayer
        );

        await importsUpdate(projectName, importId, {
          ...editedImportWithConflictResolution,
          status: "confirmation",
        } as ImportUpdate);
        dispatch(createSuccessNotification(`Imports ${importId} confirmed`));
        history.push(`${rootUrl}/imports-exports/imports`);
      } catch (error) {
        const axiosError = error as AxiosError;
        dispatch(createErrorNotification(axiosError.message));
      }
    }
  };

  const isEmpty = !Object.keys(imports || {}).length;
  const showPipelines = Object.keys(imports?.pipelines || {}).length > 0;
  const showDeployments = Object.keys(imports?.deployments || {}).length > 0;
  const showEnvironments = Object.keys(imports?.environments || {}).length > 0;
  const isLoading = isEmpty && !error;

  const openConflictDialog = (type: string, name: string) => {
    setSelectedEntity({ type, name });
    setConflictDialogOpen(true);
  };

  return isLoading ? (
    <Grid container spacing={6}>
      <Grid item lg={12} md={12} xs={12}>
        <Loader />
      </Grid>
    </Grid>
  ) : (
    <PageContainer>
      <Grid container spacing={spacing[32]} paddingTop={spacing[24]}>
        <FormSection
          title="Verify import"
          description={explanations.importsExports.imports.confirmDescription}
        >
          {showPipelines && (
            <ImportConfirmSection
              title="Pipelines"
              entityType="pipeline"
              newNamesLayer={newNamesLayer}
              entityRequirementType={EntityType.pipelines}
              isEntityNameConflict={isEntityNameConflict(EntityType.pipelines)}
              openConflictDialog={openConflictDialog}
              entities={imports?.pipelines}
              isEntityRequired={isEntityRequired(editedImport?.pipelines)}
              isEntitySelected={isEntitySelected(editedImport?.pipelines)}
              toggleEntity={toggleEntity(EntityType.pipelines)}
              isVersionSelected={isVersionSelected(editedImport?.pipelines)}
              toggleVersion={toggleVersion(EntityType.pipelines)}
              toggleAll={toggleAll(EntityType.pipelines)}
            />
          )}
          {showDeployments && (
            <ImportConfirmSection
              title="Deployments"
              entityType="deployment"
              newNamesLayer={newNamesLayer}
              entityRequirementType={EntityType.pipelines}
              openConflictDialog={openConflictDialog}
              isMissingEnvironmentVariables={isMissingEnvironmentVariables(
                EntityType.deployments
              )}
              isEntityNameConflict={isEntityNameConflict(
                EntityType.deployments
              )}
              entities={imports?.deployments}
              isEntityRequired={isEntityRequired(editedImport?.pipelines)}
              isEntitySelected={isEntitySelected(editedImport?.deployments)}
              toggleEntity={toggleEntity(EntityType.deployments)}
              isVersionSelected={isVersionSelected(editedImport?.deployments)}
              toggleVersion={toggleVersion(EntityType.deployments)}
              toggleAll={toggleAll(EntityType.deployments)}
            />
          )}
          {showEnvironments && (
            <ImportConfirmSection
              title="Environments"
              entityType="environment"
              newNamesLayer={newNamesLayer}
              entityRequirementType={EntityType.deployments}
              openConflictDialog={openConflictDialog}
              isEntityNameConflict={isEntityNameConflict(
                EntityType.environments
              )}
              entities={imports?.environments}
              isEntityRequired={isEntityRequired(
                editedImport?.deployments,
                imports?.environments
              )}
              isEntitySelected={isEntitySelected(editedImport?.environments)}
              toggleEntity={toggleEntity(EntityType.environments)}
              isVersionSelected={isVersionSelected(editedImport?.environments)}
              toggleVersion={toggleVersion(EntityType.environments)}
              toggleAll={toggleAll(EntityType.environments)}
            />
          )}
        </FormSection>
      </Grid>
      <Grid container flexDirection="row-reverse">
        <Grid item>
          <PrimaryButton disabled={isAnyConflict()} onClick={onConfirm}>
            Confirm import
          </PrimaryButton>
        </Grid>
      </Grid>
      {selectedEntity && (
        <ImportConflictDialog
          open={isConflictDialogOpen}
          onClose={() => setConflictDialogOpen(false)}
          selectedEntity={selectedEntity}
          resolveNameConflict={resolveNameConflict(selectedEntity?.type)}
          addEnvironmentVariableValue={addEnvironmentVariableValue(
            selectedEntity?.type as EntityType
          )}
          isEntityNameConflict={isEntityNameConflict(
            EntityType[selectedEntity?.type as EntityType]
          )}
          isMissingEnvVariables={isMissingEnvironmentVariables(
            EntityType[selectedEntity?.type as EntityType]
          )}
          missingVars={missingVariables(selectedEntity?.type as EntityType)}
        />
      )}
    </PageContainer>
  );
};

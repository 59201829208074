import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { ButtonLoadingOverlay } from "components/atoms/Button/ButtonLoadingOverlay";

import type { ButtonProps } from "@mui/material";

export interface BaseButtonProps extends ButtonProps {
  link?: string;
  loading?: boolean;
  type?: "button" | "reset" | "submit";
}

export const BaseButton = ({
  children,
  disabled,
  link,
  loading,
  ...props
}: BaseButtonProps) => {
  const mappedProps = link
    ? {
        component: Link,
        to: link as string,
        ...props,
      }
    : {
        ...props,
      };

  return (
    <Button disabled={disabled || loading} {...mappedProps}>
      {loading ? (
        <ButtonLoadingOverlay>{children}</ButtonLoadingOverlay>
      ) : (
        children
      )}
    </Button>
  );
};

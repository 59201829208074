import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import { ChevronDown } from "react-feather";

import { getRandomId } from "libs/utilities/utils";

import type {
  AutocompleteSelectOption,
  BaseAutoCompleteSelectProps,
} from "./types";
import type { AutocompleteInputChangeReason } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { SyntheticEvent } from "react";

export interface AutoCompleteSelectProps extends BaseAutoCompleteSelectProps {
  onChange: (selectedValue: AutocompleteSelectOption | null) => void;
  onInputChange?: (value: string) => void;
  value?: AutocompleteSelectOption | null;
  freeSolo?: boolean;
}

export const AutoCompleteSelect = ({
  options,
  onChange,
  onInputChange,
  label,
  value,
  renderOption,
  error,
  showErrors = true,
  freeSolo,
  getOptionLabel,
  styles,
  placeholder,
  ...props
}: AutoCompleteSelectProps) => {
  const theme = useTheme() as AppThemeProps;

  const handleOnChange = (
    _e: SyntheticEvent<Element>,
    selectedValue: AutocompleteSelectOption | string | null
  ) => {
    onChange?.(
      typeof selectedValue === "string"
        ? { value: selectedValue, label: selectedValue }
        : selectedValue
    );
  };

  const handleInputChange = (
    _e: SyntheticEvent<Element>,
    selectedValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (onInputChange && (reason === "clear" || reason === "input")) {
      onInputChange(selectedValue);
    }
  };

  return (
    <>
      <Autocomplete
        id={`auto-complete-${getRandomId()}`}
        options={options}
        renderInput={(params) => (
          <TextField
            error={error !== undefined}
            placeholder={placeholder}
            {...params}
            label={label}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                lineHeight: "20px",
                fontSize: 14,
                height: "38px",
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
        size="small"
        popupIcon={<ChevronDown color={theme.palette.border.primary} />}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        // @ts-expect-error to be able to handle select for controlled components we need to pass null but somehow it doesn't accept it
        value={value}
        getOptionLabel={
          getOptionLabel
            ? (option: AutocompleteSelectOption | string) =>
                typeof option === "string" ? option : getOptionLabel(option)
            : undefined
        }
        freeSolo={freeSolo}
        forcePopupIcon
        isOptionEqualToValue={(option, value) => {
          if (option?.value && value?.value)
            return option.value === value.value;

          return option === value;
        }}
        renderOption={renderOption}
        disableClearable
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingLeft: `${options[0]?.icon ? "18px" : "8px"}`,
          },
          "& .MuiAutocomplete-endAdornment": {
            borderLeft: `1px solid ${theme.palette.border.primary} `,
            height: "20px",
            top: "9px",
            display: "flex",
            width: "24px",
            "& svg": {
              height: "1.2rem",
            },
          },
          width: "100%",
          ...styles,
        }}
        {...props}
      />
      {error && showErrors && (
        // negative mt to compensate for margin bottoms for dropdowns from FormSection
        <Typography variant="caption" color="error" mt={-1.5} mb={1.5}>
          {error}
        </Typography>
      )}
    </>
  );
};

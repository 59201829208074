import { Box } from "@mui/material";
import { useState } from "react";

import { Checkbox } from "components/atoms";

type MultipleCheckboxesProps = {
  list: { name: string }[];
  label?: string;
};

const MultipleCheckboxes = ({
  list,
  label = "Select all",
}: MultipleCheckboxesProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      const items: string[] = [];
      list.forEach(({ name }) => {
        items.push(name);
      });
      setCheckedValues(items);
    } else {
      setCheckedValues([]);
    }
  };

  const handleSelectItem = (checkedName: string) => () => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
  };

  return (
    <Box>
      <Checkbox
        name="selectAll"
        label={label}
        onChange={handleSelectAll}
        indeterminate
      />
      <Box ml={2}>
        {list.map(({ name }) => (
          <Checkbox
            name={`projects.${name}`}
            key={name}
            label={name}
            checked={checkedValues.includes(name)}
            onChange={handleSelectItem(name)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MultipleCheckboxes;

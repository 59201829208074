import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { devicesUpdate, useDevicesList } from "libs/data/endpoints/user/user";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeviceUpdate = (withMessage = true) => {
  const dispatch = useDispatch();
  const { mutate } = useDevicesList();

  return useCallback(
    async (name: any, data: any) => {
      try {
        await devicesUpdate(name, data);
        mutate();
        withMessage &&
          dispatch(createSuccessNotification("Device was updated."));
      } catch (err) {
        const message = (err as AxiosError).message;

        dispatch(createErrorNotification(message));
      }
    },
    [dispatch, mutate, withMessage]
  );
};

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { instanceTypeGroupsCreate } from "libs/data/endpoints/instances/instances";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import type { AxiosError } from "axios";
import type { FormikHelpers } from "formik";
import type { InstanceTypeGroupCreateBody } from "libs/data/models";

export const useCreateInstanceTypeGroup = () => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSubmit = async (
    data: InstanceTypeGroupCreateBody,
    actions: FormikHelpers<InstanceTypeGroupCreateBody>
  ) => {
    try {
      await instanceTypeGroupsCreate(projectName, data);

      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .projectSettings.instanceTypeGroups.index()
      );
      dispatch(createSuccessNotification("Instance type group was created."));
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError).message));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return handleOnSubmit;
};

import { Grid, Typography } from "@mui/material";

import {
  useOrganizationsCreditsGet,
  useOrganizationSubscriptionsList,
} from "libs/data/endpoints/organizations/organizations";
import { env, ENV_NAMES } from "libs/env";
import { DATE_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { roundAccurately } from "libs/utilities/metrics-helper";

import { BaseTable } from "components/molecules";
import { SimpleTabs } from "components/organisms";

import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  OrganizationDetail,
  OrganizationSubscriptionList,
  UsageDetail,
} from "libs/data/models";
import type { Moment } from "moment";

const billingColumns = [
  {
    title: "Start date",
    field: "start_date",
    defaultSort: "desc",
    render: ({ start_date }: OrganizationSubscriptionList) =>
      getTzAwareDate(start_date).format(DATE_FORMAT),
  },
  {
    title: "End date",
    field: "end_date",
    render: ({ end_date }: OrganizationSubscriptionList) =>
      getTzAwareDate(end_date).format(DATE_FORMAT),
  },
  {
    title: "Subscription type",
    field: "subscription",
  },
  {
    title: "Updated by (UbiOps)",
    field: "subscription_update_user",
    nowrap: true,
  },
];

const usageColumns = [
  {
    title: "Start date",
    field: "period_start",
    defaultSort: "desc",
    render: ({ period_start }: UsageDetail) =>
      getTzAwareDate(period_start).format(DATE_FORMAT),
  },
  {
    title: "End date",
    field: "period_end",
    render: ({ period_end }: UsageDetail) =>
      getTzAwareDate(period_end).format(DATE_FORMAT),
  },
  {
    title: "Actual usage",
    field: "usage",
    render: ({ usage }: UsageDetail) => roundAccurately(usage, 0),
  },
  {
    title: "Allowed usage",
    field: "limit",
  },
];

type SubscriptionHistoryTablesProps = {
  organization?: OrganizationDetail;
  lastSubscriptionDate: Moment | null;
  historyStatus: boolean;
};

export const SubscriptionHistoryTables = ({
  organization,
  lastSubscriptionDate,
}: SubscriptionHistoryTablesProps) => {
  const organizationName = organization?.name ?? "";
  const isNotSelfService = env.get(ENV_NAMES.BILLING_ENABLED);

  const { data: billingHistory } =
    useOrganizationSubscriptionsList(organizationName);

  const { data: usageData } = useOrganizationsCreditsGet(organizationName);

  const tabsContent = [
    {
      title: "Usage history",
      content: (
        <Grid padding={4}>
          <BaseTable
            data={usageData}
            columns={usageColumns as BaseColumn[]}
            defaultPageSize={10}
          />
        </Grid>
      ),
    },
    isNotSelfService && {
      title: "Billing history",
      content: (
        <Grid padding={4}>
          <BaseTable
            data={billingHistory}
            columns={billingColumns as BaseColumn[]}
            defaultPageSize={10}
          />
          {lastSubscriptionDate && (
            <Grid
              item
              style={{ gridRowStart: 1, gridColumnStart: 2, zIndex: 2 }}
            >
              <Typography>
                Your next billing date is{" "}
                <b>{lastSubscriptionDate?.format("LL")}</b>
              </Typography>
            </Grid>
          )}
        </Grid>
      ),
    },
  ].filter(Boolean);

  return (
    <Grid
      container
      direction="column"
      style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
      }}
    >
      <Grid style={{ gridColumnStart: 1, gridColumnEnd: 12 }}>
        <SimpleTabs tabs={tabsContent} />
      </Grid>
    </Grid>
  );
};

// @ts-ignore
import ProductFruitsApp, { useProductFruits } from "react-product-fruits";

import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useAuthentication, useGetCurrentProject } from "store/features";

export const ProductFruitsProvider = () => {
  const projectName = useGetCurrentProject()?.name;
  const { isAuthenticated } = useAuthentication();

  const { data: deployments } = useDeploymentsList(projectName ?? "");
  const { data: currentUser } = useUserGet({
    swr: {
      enabled: isAuthenticated,
    },
  });

  const props = deployments?.length
    ? {
        numberOfDeployments: deployments?.length,
      }
    : undefined;

  const userInfo = {
    username: currentUser?.id,
    email: currentUser?.email,
    firstname: currentUser?.name,
    lastname: currentUser?.surname,
    signUpAt: currentUser?.registration_date,
    props,
  };

  return (
    <>
      {!!userInfo?.username && (
        <ProductFruitsApp
          {...userInfo}
          projectCode="KGuHjLIUOnTmRL5c"
          language="en"
        />
      )}
    </>
  );
};

export const useProductsFruitApi = (startTour: boolean) => {
  return useProductFruits(
    //@ts-ignore
    (api) =>
      window.productFruits && startTour && api.tours.tryStartTour("3162"),
    [startTour, window.productFruits]
  );
};

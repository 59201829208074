import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState, useCallback, useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider } from "react-hook-form";
import {
  useLocation,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_DATE } from "libs/constants/fields";
import { PROJECT_PERMISSIONS } from "libs/constants/permissions";
import { useDeviceDetect, useGoogleAnalytics } from "libs/hooks";
import { getFormattedDate, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import { isObjectHasData } from "libs/utilities/utils";
import { useGetPermissions } from "store/features/permissions";

import { Alert, InfoAlert } from "components/atoms";
import { PageContainer } from "components/molecules";

import { LogFilterChip } from "./LogFilterChip";
import { LoggingFilters } from "./LoggingFilters";
import { Logs } from "./Logs";
import {
  loadInitialLogs,
  prependOlderLogs,
  appendNewerLogs,
  createUrl,
  getFiltersFromUrl,
} from "./utils";

import type { Filters, LogSet } from "./types";
import type { DateRange } from "components/molecules/Fields/DateTimeRangeSelector/types";

const Logging = () => {
  useGoogleAnalytics();

  const { isMobile } = useDeviceDetect();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { projectName } = useParams<{ projectName: string }>();
  const [projectPermissions] = useGetPermissions();

  const parametersFromUrl = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [filters, setFilters] = useState<Filters>(
    getFiltersFromUrl(parametersFromUrl) ?? {}
  );
  const [logSet, setLogSet] = useState<LogSet | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const [fetchLogsAutomaticallyEnabled, setFetchLogsAutomaticallyEnabled] =
    useState(true);
  const formMethods = useForm({});
  const permissions = projectPermissions[PROJECT_PERMISSIONS["logs_get"]];

  const loadFirstLogs = useCallback(async () => {
    setLoading(true);

    const { from_date, to_date, ..._filters } = filters;

    const _logSet = await loadInitialLogs(
      projectName,
      _filters,
      from_date ? moment(from_date) : undefined,
      to_date ? moment(to_date) : undefined
    );
    setLogSet(_logSet);
    setLoading(false);
  }, [filters, projectName]);

  useEffect(() => {
    loadFirstLogs();
  }, [loadFirstLogs]);

  useEffect(() => {
    const newUrl = createUrl(
      location.pathname,
      filters as Record<string, string>
    );
    history.replace(newUrl);
  }, [filters, history, location.pathname]);

  useEffect(() => {
    if (isObjectHasData(filters)) {
      setFetchLogsAutomaticallyEnabled(false);
    }
  }, [filters]);

  const handleDateChange = (value: DateRange) => {
    if (value) {
      setFilters((prevFilters: Filters) => ({
        ...prevFilters,
        from_date: getFormattedDate(value.from),
        to_date: getFormattedDate(value.to),
      }));
    }
  };

  const onRefresh = useCallback(() => {
    loadFirstLogs();
  }, [loadFirstLogs]);

  const onNewerClick = useCallback(async () => {
    const _logSet = await appendNewerLogs(projectName, logSet as LogSet);
    setLogSet(_logSet);
  }, [logSet, projectName]);

  const onOlderClick = useCallback(async () => {
    const _logSet = await prependOlderLogs(projectName, logSet as LogSet);
    setLogSet(_logSet);
  }, [logSet, projectName]);

  const onFilterAdd = ({
    key,
    value,
  }: {
    key: keyof Filters;
    value: string;
  }) => {
    setFilters((prevFilters: Filters) => ({ ...prevFilters, [key]: value }));
  };

  const onFilterRemove = (filter: keyof Filters) => {
    // wipe date field to remove date range selection in the modal
    if (["from_date", "to_date"].includes(filter)) {
      formMethods.setValue(FIELD_DATE, undefined);
    }

    setFilters((prevFilters: Filters) => {
      const { [filter]: _, ...remaining } = prevFilters;

      return remaining;
    });
  };

  const onFiltersClear = () => setFilters({});

  const handleAutomaticLogsFetchChange = ({
    target: { checked },
  }: {
    target: { checked: boolean };
  }) => {
    setFetchLogsAutomaticallyEnabled(checked);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchLogsAutomaticallyEnabled) {
        onNewerClick();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchLogsAutomaticallyEnabled, onNewerClick]);

  const renderLogs = useMemo(() => {
    return (
      <Logs
        autoLoading={fetchLogsAutomaticallyEnabled}
        disabled={!permissions}
        logSet={logSet}
        logsStatus={loading ? LOADING : LOADED}
        onNewerClick={onNewerClick}
        onOlderClick={onOlderClick}
        onRefresh={onRefresh}
      />
    );
  }, [
    fetchLogsAutomaticallyEnabled,
    loading,
    logSet,
    onNewerClick,
    onOlderClick,
    onRefresh,
    permissions,
  ]);

  return (
    <PageContainer
      fullHeight={true}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "700px",
        maxHeight: "90vh",
        position: "relative",
      }}
    >
      {!isMobile && (
        <InfoAlert
          style={{ position: "absolute", top: 30, right: 0, margin: 0 }}
        >
          {explanations.logging.troubleshootingPage}
        </InfoAlert>
      )}
      <BreadcrumbsItem to={match.url}>Logging</BreadcrumbsItem>
      <FormProvider {...formMethods}>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          height={"100%"}
        >
          <PageHeader title="Logging monitor" />

          {!permissions && (
            <Alert severity="warning">
              {`You currently don't have permission to see the logs.`}
            </Alert>
          )}

          <LoggingFilters
            onAdd={onFilterAdd}
            onClear={onFiltersClear}
            handleDateChange={handleDateChange}
            onRefresh={onRefresh}
            disabled={!permissions}
            defaultDate={getTzAwareDate()}
            handleAutoFetch={handleAutomaticLogsFetchChange}
            isFetchLogsAutomaticallyEnabled={fetchLogsAutomaticallyEnabled}
          />
          <Grid container alignItems="center" spacing={1}>
            {(
              Object.entries(filters) as [keyof Filters, string | boolean][]
            ).map(
              ([filter, value]) =>
                (value === false || value) && (
                  <Grid item key={filter}>
                    {/* @ts-ignore*/}
                    <LogFilterChip
                      filterName={filter}
                      filterValue={value as string}
                      onDelete={() => onFilterRemove(filter)}
                    />
                  </Grid>
                )
            )}
          </Grid>
          {renderLogs}
        </Box>
      </FormProvider>
    </PageContainer>
  );
};

export default Logging;

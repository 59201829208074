import { Grid } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";

import {
  ControlledEnvironmentVariablesCopy,
  FormSection,
} from "components/molecules";
import { EnvVarsOverview } from "components/organisms";

type DeploymentCreateEnvVarsProps = {
  deploymentName: string;
  deploymentVersionName?: string;
};

export const DeploymentCreateEnvVars = ({
  deploymentName,
  deploymentVersionName,
}: DeploymentCreateEnvVarsProps) => {
  const [showOverview, setShowOverview] = useState(true);

  const { projectName } = useParams<{ projectName: string }>();

  const onCopyClick = () => {
    setShowOverview(false);
  };

  const onEnvVarCreate = () => {
    setShowOverview(true);
  };

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  return (
    <FormSection title="Deployment environment variables">
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {showOverview ? (
          <Grid item xs={12}>
            <EnvVarsOverview
              deploymentName={deploymentName}
              deploymentVersionName={deploymentVersionName}
              onCopyClick={onCopyClick}
              isUsedInForm={true}
              isCreatable={
                currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_create"]]
              }
              isEditable={
                currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_update"]]
              }
              isDeletable={
                currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_delete"]]
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ControlledEnvironmentVariablesCopy
              deploymentName={deploymentName}
              deploymentVersionName={deploymentVersionName}
              onCopySuccess={onEnvVarCreate}
              isPage={false}
            />
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
};

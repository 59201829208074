import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import moment from "moment";
import { createStaticRanges } from "react-date-range";

import type { InternalDateTimeRange } from "components/molecules/Fields/DateTimeRangeSelector/types";
import type { StaticRange } from "react-date-range";

export const END_OF_DAY = "23:59";
export const START_OF_DAY = "00:00";

export const _INTERNAL = "_range_time";
export const _FROM = "_from";
export const _TO = "_to";

export const definitions = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const createRange = (
  label: string,
  partialRange: Partial<InternalDateTimeRange> = {}
) => {
  const range: InternalDateTimeRange = {
    endDate: definitions.endOfToday,
    endTime: END_OF_DAY,
    startDate: definitions.startOfToday,
    startTime: START_OF_DAY,
    ...partialRange,
  };

  return {
    label,
    range: () => range,
    isSelected: (current: InternalDateTimeRange) =>
      current.endDate === range.endDate &&
      current.endTime === range.endTime &&
      current.startDate === range.startDate &&
      current.startTime === range.startTime,
  } as unknown as StaticRange;
};

export const getPredefinedRanges = () =>
  createStaticRanges([
    createRange("Last Hour", {
      startTime: moment().subtract(1, "hour").format("HH:mm"),
      endTime: moment().format("HH:mm"),
    }),
    createRange("Today"),
    createRange("Yesterday", {
      startDate: definitions.startOfYesterday,
      endDate: definitions.endOfYesterday,
    }),
    createRange("This Week", {
      startDate: definitions.startOfWeek,
      endDate: definitions.endOfWeek,
    }),
    createRange("Last Week", {
      startDate: definitions.startOfLastWeek,
      endDate: definitions.endOfLastWeek,
    }),
    createRange("This Month", {
      startDate: definitions.startOfMonth,
      endDate: definitions.endOfMonth,
    }),
    createRange("Last Month", {
      startDate: definitions.startOfLastMonth,
      endDate: definitions.endOfLastMonth,
    }),
  ]);

export const defaultRange = {
  endDate: new Date(),
  endTime: "23:59",
  startDate: new Date(),
  startTime: "00:00",
};

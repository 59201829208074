import { useMemo } from "react";

import { FIELD_ASSIGNEE, FIELD_ROLE } from "libs/constants/fields";
import {
  useRoleAssignmentsCreate,
  useRoleAssignmentsDelete,
} from "libs/data/customized/roles";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { BucketPermissionsFormSection } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/formSections/BucketPermissionsFormSection";

import type { DeploymentBucketRole } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/formSections/BucketPermissionsFormSection";

export interface BucketPermissionsWithDeploymentProps {
  deploymentName: string;
  projectName: string;
}

export const BucketPermissionsWithDeployment = ({
  deploymentName,
  projectName,
}: BucketPermissionsWithDeploymentProps) => {
  const { data: buckets } = useBucketsList(projectName);
  const roleAssignmentsCreate = useRoleAssignmentsCreate(
    projectName,
    undefined,
    deploymentName,
    "bucket"
  );
  const roleAssignmentsDelete = useRoleAssignmentsDelete(
    projectName,
    undefined,
    deploymentName,
    "bucket"
  );

  const { data: permissions, mutate } = useRoleAssignmentsPerObjectList(
    projectName,
    {
      assignee: deploymentName,
      assignee_type: "deployment",
    }
  );

  const cleanPermissions = useMemo(() => {
    return permissions?.map((permission) => ({
      [FIELD_ASSIGNEE]: permission.resource as string,
      [FIELD_ROLE]: permission.role,
    }));
  }, [permissions]);

  const handleOnAdd = async (role: DeploymentBucketRole) => {
    await roleAssignmentsCreate({
      assignee: deploymentName,
      assignee_type: "deployment",
      resource: role.assignee,
      resource_type: "bucket",
      role: role.role,
    });

    mutate();
  };

  const handleOnDelete = async (role: DeploymentBucketRole) => {
    const permission = permissions?.find(
      (permission) =>
        permission.resource === role.assignee && permission.role === role.role
    );

    if (permission) {
      await roleAssignmentsDelete(permission.id);

      mutate((roles) => roles?.filter((x) => x.id !== permission.id));
    }
  };

  return (
    <BucketPermissionsFormSection
      buckets={buckets ?? []}
      onAdd={handleOnAdd}
      onDelete={handleOnDelete}
      permissions={cleanPermissions ?? []}
    />
  );
};

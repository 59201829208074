import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useState, useEffect, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { MultiplierIllu } from "assets/images/MultiplierIllu";
import { PageHeader } from "components/molecules/PageLayout";
import { STRUCTURED_TYPE } from "libs/constants/constants";
import {
  FIELD_NAME,
  FIELD_DESCRIPTION,
  FIELD_INPUT_TYPE,
  FIELD_OUTPUT_TYPE,
  FIELD_INPUT_FIELDS,
  FIELD_OUTPUT_FIELDS,
  FIELD_LABELS,
  FIELD_ASSIGNEE,
  FIELD_ROLE,
} from "libs/constants/fields";
import { BaseUrlContext } from "libs/contexts";
import { useDeploymentCreate } from "libs/data/customized/deployment/useDeploymentCreate";
import {
  useDeploymentsList,
  useTemplateDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { roleAssignmentsCreate } from "libs/data/endpoints/roles/roles";
import { useDeviceDetect, useGoogleAnalytics } from "libs/hooks";
import { pythonCodeGenerator } from "libs/utilities/code-snippets-generator";
import { explanations } from "libs/utilities/explanations";
import { formatInputOutputFields } from "libs/utilities/input-parser";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { createErrorNotification } from "libs/utilities/notifications";

import { Dialog, PrimaryButton } from "components/atoms";
import {
  InputOutputField,
  FormSection,
  MultiCodeBlock,
  FirstDeploymentCard,
  GeneralFieldsSection,
  PageContainer,
} from "components/molecules";
import { FormContainer, LabelsForm } from "components/organisms";

import { DeploymentCreateEnvVars } from "./DeploymentCreateEnvVars";
import { BucketPermissionsWithoutDeployment } from "./formSections/BucketPermissionsWithoutDeployment";
import { DeploymentVersionCreate } from "./versions/DeploymentVersionCreate";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  TemplateDeploymentListDetails,
  DeploymentCreate as DeploymentCreateModel,
  DeploymentInputFieldCreate,
  DeploymentOutputFieldCreate,
  TemplateDeploymentList,
  RoleAssignmentCreate,
} from "libs/data/models";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";

const defaultValues = {
  [FIELD_NAME]: "",
  [FIELD_INPUT_TYPE]: STRUCTURED_TYPE,
  [FIELD_OUTPUT_TYPE]: STRUCTURED_TYPE,
  [FIELD_INPUT_FIELDS]: [],
  [FIELD_OUTPUT_FIELDS]: [],
};

const DeploymentCreate = () => {
  useGoogleAnalytics();
  const { isMobile } = useDeviceDetect();
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const theme = useTheme() as AppThemeProps;
  const { watch, setValue, getValues } = methods;
  const dispatch = useDispatch();
  const baseUrl = useContext(BaseUrlContext);
  const { projectName } = useParams<ProjectDetailsRouteParams>();
  const { data: preloadedDeployments } = useTemplateDeploymentsList();
  const { data: deployments } = useDeploymentsList(projectName);
  const { data: buckets } = useBucketsList(projectName);
  const createDeployment = useDeploymentCreate(projectName);

  const [deploymentName, setDeploymentName] = useState(() => "");
  const [isWizardDialogOpen, setIsWizardDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [preloadedDeploymentChoice, setPreloadedDeploymentChoice] =
    useState<TemplateDeploymentList | undefined>(undefined);
  const [defaultInputFields, setDefaultInputFields] = useState(() => []);
  const [defaultOutputFields, setDefaultOutputFields] = useState(() => []);
  const [defaultInputType, setDefaultInputType] = useState(
    () => STRUCTURED_TYPE
  );
  const [defaultOutputType, setDefaultOutputType] = useState(
    () => STRUCTURED_TYPE
  );
  const [permissionsCreated, setPermissionsCreated] = useState(false);

  const preloadedDeployment = useMemo(
    () =>
      (preloadedDeployments || []).find(
        (deployment: TemplateDeploymentList) =>
          deployment.details?.name === "multiply"
      ),
    [preloadedDeployments]
  );

  const showDeploymentWizard = useMemo(
    () => deployments && !deployments.length && preloadedDeployment,
    [deployments, preloadedDeployment]
  );

  useEffect(() => {
    if (activeStep !== 1 && deploymentName) {
      setActiveStep(1);
    }
  }, [activeStep, deploymentName]);

  useEffect(() => {
    if (deploymentName && !permissionsCreated) {
      setPermissionsCreated(true);
      const values: any = getValues();
      const permissions = values["permissions"];

      if (permissions && permissions.length) {
        permissions.map((item: any) => {
          const bucketName = item[FIELD_ASSIGNEE];
          const role = item[FIELD_ROLE];

          const newRole: RoleAssignmentCreate = {
            assignee: values?.name,
            assignee_type: "deployment",
            resource: bucketName,
            resource_type: "bucket",
            role: role,
          };

          roleAssignmentsCreate(projectName, newRole).catch((e) =>
            dispatch(createErrorNotification(e.message))
          );
        });
      }
    }
  }, [deploymentName, dispatch, getValues, permissionsCreated, projectName]);

  const onSubmit = async (data: any) => {
    const values = getValues();

    const newDeployment: DeploymentCreateModel = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_INPUT_TYPE]: data[FIELD_INPUT_TYPE],
      [FIELD_OUTPUT_TYPE]: data[FIELD_OUTPUT_TYPE],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
    };

    if (data?.[FIELD_INPUT_FIELDS]) {
      newDeployment[FIELD_INPUT_FIELDS] = formatInputOutputFields(
        values[FIELD_INPUT_FIELDS]
      ) as DeploymentInputFieldCreate[];
    }

    if (data?.[FIELD_OUTPUT_FIELDS]) {
      newDeployment[FIELD_OUTPUT_FIELDS] = formatInputOutputFields(
        values[FIELD_OUTPUT_FIELDS]
      ) as DeploymentOutputFieldCreate[];
    }

    const response = await createDeployment(newDeployment);

    if (response) {
      setDeploymentName(data[FIELD_NAME]);
    }
  };

  const codeGeneratorFields = useMemo(
    () => ({
      deploymentName: watch(FIELD_NAME),
      inputFields: formatInputOutputFields(watch(FIELD_INPUT_FIELDS)),
      outputFields: formatInputOutputFields(watch(FIELD_OUTPUT_FIELDS)),
      inputType: watch(FIELD_INPUT_TYPE),
      outputType: watch(FIELD_OUTPUT_TYPE),
    }),
    [watch]
  );

  const codeBlocks = useMemo(
    () => [
      {
        title: "Python",
        language: "python",
        codeBlock: pythonCodeGenerator({ ...(codeGeneratorFields as any) }),
      },
    ],
    [codeGeneratorFields]
  );

  const loadPreloadedDeployment = () => {
    setPreloadedDeploymentChoice(preloadedDeployment);
    const multiply = (preloadedDeployment as TemplateDeploymentList)
      .details as TemplateDeploymentListDetails;
    setIsWizardDialogOpen(false);
    setDefaultInputFields(multiply.input_fields);
    setDefaultOutputFields(multiply.output_fields);
    setDefaultInputType(multiply.input_type);
    setDefaultOutputType(multiply.output_type);
    setValue(FIELD_NAME, multiply.name);
    setValue(FIELD_DESCRIPTION, multiply.description);
    setValue(FIELD_LABELS, formatLabels(multiply.labels));
  };

  const warningTextColor =
    theme.palette.mode === "dark"
      ? theme.palette.warning.main
      : theme.palette.primary.main;

  return (
    <PageContainer>
      <PageHeader title="Create new deployment">
        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          className="deployment-create__stepper"
        >
          {(!isMobile || (isMobile && activeStep === 0)) && (
            <Step key={0}>
              <StepLabel>Define your deployment</StepLabel>
            </Step>
          )}
          {(!isMobile || (isMobile && activeStep === 1)) && (
            <Step
              key={1}
              sx={{
                "& circle": { fill: theme.palette.stepper.inactiveCircle },
              }}
            >
              <StepLabel>Create your first version</StepLabel>
            </Step>
          )}
        </Stepper>
      </PageHeader>

      <div hidden={activeStep !== 0}>
        <FormContainer
          onSubmit={onSubmit}
          buttonLabel="Next: Create a version"
          formMethods={methods}
          status={"LOADED"}
        >
          {showDeploymentWizard && (
            <FirstDeploymentCard onAction={() => setIsWizardDialogOpen(true)} />
          )}
          <GeneralFieldsSection
            title="Name your deployment"
            description={explanations.deployments.templates}
            validateValue="deployment"
            namePlaceholder="Ex: my-deployment-1"
            descriptionPlacholder="Ex: A deployment that does XYZ"
          />
          <InputOutputField
            title="Input"
            placeholder="Ex: my-input-1"
            description={
              explanations.deployments.inputOutput({
                type: "input",
                withPreFilled: !!preloadedDeploymentChoice,
                color: warningTextColor,
              } as any) as any
            }
            typeName={FIELD_INPUT_TYPE}
            fieldsName={FIELD_INPUT_FIELDS}
            defaultType={defaultInputType}
            defaultFields={defaultInputFields}
            disabled={!!preloadedDeploymentChoice}
          />
          <InputOutputField
            title="Output"
            placeholder="Ex: my-output-1"
            description={
              explanations.deployments.inputOutput({
                type: "output",
                withPreFilled: !!preloadedDeploymentChoice,
                color: warningTextColor,
              } as any) as any
            }
            typeName={FIELD_OUTPUT_TYPE}
            fieldsName={FIELD_OUTPUT_FIELDS}
            defaultType={defaultOutputType}
            defaultFields={defaultOutputFields}
            disabled={!!preloadedDeploymentChoice}
          />
          {!!buckets && buckets?.length > 1 && (
            <FormSection
              title="Bucket permissions"
              description={explanations.deployments.bucketPermissions}
            >
              <BucketPermissionsWithoutDeployment buckets={buckets} />
            </FormSection>
          )}
          <FormSection
            title="Deployment template"
            description={explanations.deployments.deploymentTemplate}
          >
            <MultiCodeBlock codeBlocks={codeBlocks} withDownload />
          </FormSection>
          <FormSection
            title="Labels"
            description={explanations.labels.description("deployment")}
          >
            <LabelsForm name={FIELD_LABELS} />
          </FormSection>
        </FormContainer>
      </div>

      <div hidden={activeStep !== 1}>
        {activeStep === 1 && (
          <DeploymentVersionCreate
            deployment={deploymentName}
            isPage={false}
            preloadedDeploymentChoice={preloadedDeploymentChoice}
            // @ts-ignore
            overwriteRedirectUrl={`${baseUrl}/${deploymentName}`}
          >
            <DeploymentCreateEnvVars deploymentName={deploymentName} />
          </DeploymentVersionCreate>
        )}
      </div>

      <Dialog
        open={isWizardDialogOpen}
        title="Let's create your first deployment together"
        onClose={() => setIsWizardDialogOpen(false)}
        Actions={
          <PrimaryButton onClick={() => loadPreloadedDeployment()}>
            Continue
          </PrimaryButton>
        }
      >
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography align="center">
              We will make a multiplication deployment together, which simply
              multiplies an input number by 2.
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center">
              <Typography component="span" variant="h5" color="secondary">
                Once you continue we will prefill the form
              </Typography>{" "}
              for you with the necessary values. Just scroll down and click the
              create button to continue.
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item>
              <Box>
                <MultiplierIllu />
              </Box>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </PageContainer>
  );
};

export default DeploymentCreate;

import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { FIELD_EMAIL, FIELD_REMEMBER_ME } from "libs/constants/fields";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { useHandleLogin } from "libs/data/customized/authorize/useHandleLogin";
import { useGoogleAnalytics } from "libs/hooks";
import validators from "libs/utilities/validators";
import { useAuthentication } from "store/features";

import {
  Alert,
  AuthenticationLink,
  FormTextField,
  Checkbox,
  Divider,
  SecondaryButton,
} from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import type { HandleLoginPayload } from "libs/data/customized/authorize/useHandleLogin";

const SignIn = () => {
  useGoogleAnalytics();
  const [cookies, , removeCookie] = useCustomCookies();
  const { error } = useAuthentication();
  const { handleLogin, isLoading } = useHandleLogin();
  const history = useHistory();
  const methods = useForm({
    mode: "onSubmit",
  });

  const [userCreated, userActivated] = useMemo(() => {
    const state = (history?.location?.state ??
      cookies[COOKIE_NAMES.NEW_OAUTH_USER] ??
      {}) as {
      userCreated: boolean;
      userActivated: boolean;
    };

    return [state?.userCreated, state?.userActivated];
  }, [history?.location?.state, cookies]);

  const resetUserCreated = () => {
    history.replace("/sign-in", null);
    removeCookie(COOKIE_NAMES.NEW_OAUTH_USER);
  };

  const { handleSubmit } = methods;

  return (
    <AuthenticationContainer title="Sign in">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((fieldValues) =>
            handleLogin(fieldValues as HandleLoginPayload)
          )}
        >
          {userCreated && (
            <>
              <Alert variant="filled" severity="info">
                <Typography variant="h5">Verify your email</Typography>
                <br />
                <Typography>
                  Thank you for registering! You will receive an{" "}
                  <b>activation link</b> at your email address in a few minutes.
                  If you do not see the email in a few minutes, check your “junk
                  mail” folder or “spam” folder.
                </Typography>
              </Alert>
              <Grid container direction="column">
                <SecondaryButton onClick={resetUserCreated}>
                  Continue
                </SecondaryButton>
              </Grid>
            </>
          )}
          {userActivated && (
            <Alert variant="filled" severity="success">
              <Typography variant="h5">
                Your account is now activated!
              </Typography>
              <br />
              <Typography>Please login.</Typography>
            </Alert>
          )}
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
          {!userCreated && (
            <Grid container direction="column">
              <FormTextField
                id={FIELD_EMAIL}
                name={FIELD_EMAIL}
                autoComplete={FIELD_EMAIL}
                autoCapitalize="none"
                type={FIELD_EMAIL}
                label="Email"
                rules={{
                  required: validators.required.message(FIELD_EMAIL),
                  pattern: {
                    value: validators.email.pattern,
                    message: validators.email.message,
                  },
                }}
              />
              <Checkbox
                name={FIELD_REMEMBER_ME}
                color="primary"
                label="Remember me"
              />
              <SecondaryButton type="submit" disabled={isLoading}>
                Continue
              </SecondaryButton>
              <Divider my={3} />
              <Box textAlign="center">
                <Box component="span">New to UbiOps? </Box>
                <AuthenticationLink to="/sign-up">
                  Sign up now.
                </AuthenticationLink>
              </Box>
            </Grid>
          )}
        </form>
      </FormProvider>
    </AuthenticationContainer>
  );
};

export default SignIn;

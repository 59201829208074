import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { userCreate } from "libs/data/endpoints/user/user";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { UserPendingCreate } from "libs/data/models";

export const useCreateUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const createUser = async (payload: UserPendingCreate) => {
    setIsLoading(true);

    try {
      await userCreate(payload);
      setIsLoading(false);
      history.push("/sign-in", { userCreated: true });
    } catch (e) {
      setIsLoading(false);
      dispatch(createErrorNotification((e as AxiosError)?.message));
    }
  };

  return { createUser, isLoading };
};

import type { CSSProperties } from "react";
import "./PageContainer.scss";

export type PageContainerProps = {
  children?: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
};

export const PageContainer = ({
  children,
  className,
  fullHeight = false,
  style,
  contentStyle,
}: PageContainerProps) => (
  <div
    className={[
      "page-container",
      fullHeight && "page-container--full-height",
      className,
    ]
      .filter((x) => x)
      .join(" ")}
    style={style}
  >
    <div className="page-container__content" style={contentStyle}>
      {children}
    </div>
  </div>
);

import Flash from "@mui/icons-material/FlashOn";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import { Typography, Grid, useTheme, Hidden } from "@mui/material";
import { useState } from "react";

import { GetStartedIllustration } from "assets/images/IlluGetStartedDashboard";
import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { gtmEvent } from "libs/hooks/useGoogleAnalytics";
import { useProductsFruitApi } from "libs/third-parties";

import { PrimaryButton, SecondaryButton } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";

const GetStarted = () => {
  const [startProductFruitsTour, setStartProductFruitsTour] = useState(false);
  const theme = useTheme() as AppThemeProps;
  useProductsFruitApi(startProductFruitsTour);

  const onProductFruitsTourClick = () => setStartProductFruitsTour(true);

  return (
    <Grid container wrap="nowrap" marginTop={"-16px"}>
      <Grid
        xs
        container
        direction="column"
        justifyContent="flex-end"
        marginBottom={"6px"}
      >
        <Grid item>
          <Typography
            variant="body1"
            style={{
              color: theme.palette.neutrals[400],
              marginBottom: spacing[12],
            }}
          >
            Start using UbiOps and take our guided tour.
          </Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item>
            <PrimaryButton
              href={DOC_LINKS.STARTER_TUTORIAL}
              startIcon={<Flash />}
              target="_blank"
              onClick={() => {
                gtmEvent("quickstart_clicked", {
                  event_category: "quickstart",
                });
              }}
            >
              Starter tutorial
            </PrimaryButton>
          </Grid>
          <Grid item>
            <SecondaryButton
              onClick={onProductFruitsTourClick}
              startIcon={<PlayCircleOutlineRoundedIcon />}
            >
              Get a tour
            </SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <GetStartedIllustration />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default GetStarted;

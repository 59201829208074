import { Typography, useTheme } from "@mui/material";

import type { TypographyProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const DialogHeaderTitle = (props: TypographyProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Typography
      variant="h2"
      style={{
        textAlign: "center",
        color: theme.palette.text.primary,
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.35,
        marginTop: 12,
        marginBottom: 12,
      }}
      {...props}
    />
  );
};

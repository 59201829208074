export const DEFAULT_BUCKET_NAME = "default";

export const PROVIDERS = {
  azure: "azure_blob_storage",
  ubiops: "ubiops",
};

export const FILE_REF = "ubiops-file://";

export const SSLOptions = [
  {
    label: "None",
    value: "null",
  },
  {
    label: "True",
    value: true,
  },
  { label: "False", value: false },
];

export const verifyOptions = [
  {
    label: "None",
    value: null,
  },
  { label: "False", value: false },
];

import { FIELD_STATIC_IP } from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";

import { FormSwitch } from "components/atoms";
import { FormSection } from "components/molecules";

type NetworkSettingsProps = {
  defaultValue?: boolean;
  entity?: string;
};

export const NetworkSettings = ({
  defaultValue,
  entity = "deployment",
}: NetworkSettingsProps) => {
  return (
    <FormSection
      title="Static IP address"
      description={explanations.deployments.versions.networkSettings(entity)}
    >
      <FormSwitch name={FIELD_STATIC_IP} defaultValue={defaultValue || false} />
    </FormSection>
  );
};

import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { DOC_LINKS } from "libs/constants/documentation-links";
import {
  roleAssignmentsCreate,
  useRoleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import { fileAccessRoleOptions } from "libs/utilities/labels-mapping";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import {
  Dialog,
  ExternalLink,
  InfoAlert,
  PrimaryButton,
} from "components/atoms";

import type { AxiosError } from "axios";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { DeploymentList } from "libs/data/models";

interface AddDeploymentPermissionDialogProps {
  availableDeployments: DeploymentList[];
  bucketName: string;
  onClose: () => void;
  open: boolean;
  projectName: string;
}

const DEPLOYMENT_NAME = "deployment";
const ROLE_NAME = "role";

export const AddDeploymentPermissionDialog = ({
  bucketName,
  availableDeployments,
  onClose,
  open,
  projectName,
}: AddDeploymentPermissionDialogProps) => {
  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { mutate } = useRoleAssignmentsPerObjectList(projectName, {
    resource: bucketName,
    resource_type: "bucket",
  });

  const deployment = useWatch({ control, name: DEPLOYMENT_NAME });
  const role = useWatch({ control, name: ROLE_NAME });

  const formIsValid = useMemo(() => deployment && role, [deployment, role]);

  const createPermission = async ({
    deployment,
    role,
  }: {
    [DEPLOYMENT_NAME]: AutocompleteSelectOption;
    [ROLE_NAME]: AutocompleteSelectOption;
  }) => {
    setLoading(true);
    try {
      await roleAssignmentsCreate(projectName, {
        assignee: (deployment.value as string) || "",
        assignee_type: "deployment",
        resource: bucketName,
        resource_type: "bucket",
        role: (role?.value as string) || "",
      });

      dispatch(
        createSuccessNotification("The deployment was successfully added")
      );

      await mutate();
      setLoading(false);
      onClose();
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError)?.message));
      setLoading(false);
    }
  };

  const deploymentOptions = useMemo(
    () =>
      availableDeployments?.map(({ name }) => ({
        value: name,
        label: name,
      })),
    [availableDeployments]
  );

  return (
    <FormProvider {...formMethods}>
      <Dialog
        Actions={
          <PrimaryButton
            disabled={!formIsValid}
            onClick={handleSubmit(createPermission)}
            loading={loading}
          >
            Add Permission
          </PrimaryButton>
        }
        onClose={onClose}
        open={open}
        title="Give deployment access to this bucket"
      >
        <InfoAlert>
          If you want deployments to read from and write to this bucket, you’ll
          need to give them permission.
          <br />
          <ExternalLink href={DOC_LINKS.DEFAULT_BUCKET}>
            Read more about this here
          </ExternalLink>
        </InfoAlert>
        <Box my={2}>
          <AutoCompleteSelectHookForm
            label="Deployment"
            name={DEPLOYMENT_NAME}
            options={deploymentOptions}
            placeholder="Select..."
          />
        </Box>
        <Box my={2}>
          <AutoCompleteSelectHookForm
            label="Role"
            name={ROLE_NAME}
            options={fileAccessRoleOptions}
            placeholder="Select..."
          />
        </Box>
      </Dialog>
    </FormProvider>
  );
};

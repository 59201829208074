import { REQUEST_DATA_MAX_SIZE } from "libs/constants/constants";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { routes } from "routes";

import type {
  DeploymentRequestSingleDetail,
  PipelineRequestDeploymentRequest,
  PipelineRequestOperatorRequest,
  PipelineRequestPipelineRequest,
  PipelineRequestSingleDetail,
} from "libs/data/models";

export const getCreatedByFromOrigin = (
  origin:
    | {
        type: "deployment";
        created_by: string;
        deployment: string;
        deployment_version?: string;
      }
    | {
        type: "pipeline";
        created_by: string;
        pipeline: string;
        pipeline_version?: string;
      }
    | {
        type: "request_schedule";
        created_by: string;
        request_schedule?: string;
      },
  rootUrl: string
) => {
  // The created by field will be "Ubiops" if the request was not initiated by the user
  if (origin.created_by && origin.created_by.toLowerCase() !== "ubiops")
    return { createdBy: origin.created_by, url: undefined };
  if (origin.type === "pipeline") {
    return {
      createdBy: `Pipeline ${origin.pipeline} (${
        origin.pipeline_version || "default"
      })`,
      url: `${rootUrl}/pipelines/${origin.pipeline}${
        origin.pipeline_version ? `/versions/${origin.pipeline_version}` : ""
      }`,
    };
  }

  if (origin.type === "request_schedule") {
    return {
      createdBy: `Request schedule ${origin.request_schedule}`,
      url: `${rootUrl}/request-schedules/${origin.request_schedule}`,
    };
  }

  return {
    createdBy: "",
    url: undefined,
  };
};

export const getObjectLink = (
  object_type: string,
  organizationName: string,
  projectName: string,
  name: string,
  versionName?: string | null,
  isRequest?: boolean
) => {
  const redirectUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName);

  switch (object_type) {
    case "deployment":
      // separate training from deployments
      if (name === TRAINING_DEPLOYMENT) {
        if (versionName) {
          return redirectUrl.training.experiments[":experimentName"](
            versionName
          ).index();
        } else return redirectUrl.training.experiments.index();
      }
      if (versionName) {
        if (isRequest)
          return redirectUrl.deployments[":deploymentName"](name)
            .versions[":versionName"](versionName)
            .requests.index();
        else
          return redirectUrl.deployments[":deploymentName"](name)
            .versions[":versionName"](versionName)
            .index();
      } else return redirectUrl.deployments[":deploymentName"](name).index();

    case "pipeline":
      if (versionName) {
        if (isRequest)
          return redirectUrl.pipelines[":pipelineName"](name)
            .versions[":versionName"](versionName)
            .requests.index();
        else
          return redirectUrl.pipelines[":pipelineName"](name)
            .versions[":versionName"](versionName)
            .index();
      } else return redirectUrl.pipelines[":pipelineName"](name).index();

    default:
      return "";
  }
};

export const shouldFetchMetadataOnly = (
  request:
    | DeploymentRequestSingleDetail
    | PipelineRequestSingleDetail
    | PipelineRequestDeploymentRequest
    | PipelineRequestPipelineRequest
    | PipelineRequestOperatorRequest
    | undefined
    | null
) => {
  return !!(
    (request?.input_size && request?.input_size > REQUEST_DATA_MAX_SIZE) ||
    (request?.output_size && request?.output_size > REQUEST_DATA_MAX_SIZE)
  );
};

export const openDataInNewTab = (
  data: {
    request_data?: { [key: string]: any };
    result?: { [key: string]: any };
  },
  isTrainingRun?: boolean
) => {
  const request_data = isTrainingRun ? "run_data" : "request_data";
  const blob = new Blob(
    [
      JSON.stringify(
        {
          [request_data]: data?.request_data,
          results: data?.result,
        },
        null,
        2
      ),
    ],
    { type: "text/plain" }
  );
  const objecturl = URL.createObjectURL(blob);
  window.open(objecturl, "_blank");
};

import {
  DEFAULT_BUCKET_NAME,
  FILE_REF,
} from "pages/organizations/:organizationName/projects/:projectName/storage/constants";

export const bucketNameIsDefault = (name: string) =>
  name === DEFAULT_BUCKET_NAME;

// Any path within the bucket is automatically added at the end of the bucket
// This prefix contains any path in case a (sub) folder has been opened
export const getPrefix = (baseUrl: string, pathname: string) =>
  pathname.startsWith(baseUrl) && pathname.slice(baseUrl.length)
    ? pathname.slice(baseUrl.length + 1) + "/"
    : "";

export const isStorageLink = (link: string) =>
  link.substring(0, 14) === FILE_REF;

export const mapStorageLinkToAppLink = (
  link: string,
  organizationName: string,
  projectName: string
) => {
  if (!link) return "";
  const { bucket, path } = destructFilePath(link);
  const urlPath = path ? "/" + path.slice(0, -1) : "";
  const rootUrl = `/organizations/${organizationName}/projects/${projectName}`;

  return `${rootUrl}/storage/buckets/${bucket}/files${urlPath}`;
};

export const destructFilePath = (link: string) => {
  const fullPath = link.substring(FILE_REF.length);
  const explosion = fullPath.split("/");
  const bucket = explosion.shift() ?? "";
  const file = explosion.pop() ?? "";

  const path = explosion.length ? `${explosion.join("/")}/` : "";

  return { bucket, path, file };
};

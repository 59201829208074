import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  organizationUsersDelete,
  useOrganizationUsersList,
} from "libs/data/endpoints/organizations/organizations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useOrganizationUserDelete = (name: string) => {
  const dispatch = useDispatch();
  const { mutate } = useOrganizationUsersList(name);

  return useCallback(
    async (id: string) => {
      try {
        await organizationUsersDelete(name, id);
        dispatch(createSuccessNotification("User was deleted."));
        mutate();
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, mutate, name]
  );
};

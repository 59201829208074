import { FULL_DATE_TIME_FORMAT } from "libs/utilities/date-util";

import { LogMessage } from "./LogMessage";

import type { Moment } from "moment";

export const NoNewLogs = ({
  minDate,
  maxDate,
}: {
  minDate: Moment | null;
  maxDate: Moment | null;
}) => {
  return (
    <LogMessage backgroundColor="unset">
      No logs available between {minDate?.format(FULL_DATE_TIME_FORMAT)} and{" "}
      {maxDate?.format(FULL_DATE_TIME_FORMAT)}
    </LogMessage>
  );
};

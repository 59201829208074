import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  serviceUsersUpdate,
  useServiceUsersList,
} from "libs/data/endpoints/service-users/service-users";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { ServiceUserCreate } from "libs/data/models";

export const useServiceUserUpdate = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useServiceUsersList(projectName);

  return useCallback(
    async (id: string, data: ServiceUserCreate) => {
      if (!projectName) {
        return Promise.reject();
      }

      return serviceUsersUpdate(projectName, id, data)
        .then(async (result) => {
          dispatch(createSuccessNotification("The service user was updated"));
          mutate();

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, dispatch, mutate]
  );
};

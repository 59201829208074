import styled from "@emotion/styled";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import ContactIcon from "@mui/icons-material/QuestionAnswer";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Grid, Typography } from "@mui/material";

import {
  LINK_CREATE_BUG,
  LINK_SUPPORT,
  LINK_YOUTUBE,
} from "libs/constants/documentation-links";

import { SecondaryButton } from "components/atoms";

export const Contact = () => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Typography variant="body2">
        Are you in need of support, did you find a bug, or do you have a feature
        request? Get in touch with us and let us know.
      </Typography>
    </Grid>

    <ButtonWrapper container justifyContent="space-evenly">
      <SecondaryButton
        href={LINK_CREATE_BUG}
        target="_blank"
        size="small"
        startIcon={<BugReportRoundedIcon />}
      >
        Report a bug
      </SecondaryButton>

      <SecondaryButton
        href={LINK_SUPPORT}
        target="_blank"
        size="small"
        startIcon={<ContactIcon />}
      >
        Contact support
      </SecondaryButton>

      <SecondaryButton
        href={LINK_YOUTUBE}
        target="_blank"
        size="small"
        startIcon={<YouTubeIcon />}
      >
        YouTube channel
      </SecondaryButton>
    </ButtonWrapper>
  </Grid>
);

const ButtonWrapper = styled(Grid)`
  > * {
    margin: 8px 0;
  }
`;

import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Box, IconButton, Tooltip } from "@mui/material";
import { sortBy } from "lodash";
import { useMemo, useState } from "react";

import { PaginatedPipelineObjectRequestResultsDetails } from "components/organisms/RequestResultsDialog/PaginatedPipelineObjectRequestResultsDetails";
import { useLogsUrl } from "libs/hooks";
import { routeMaker, routes } from "routes";

import { StatusIcon, NavLink, TableLink, Alert } from "components/atoms";
import { BaseTable } from "components/molecules";

import { getObjectLink } from "./utils";

import type { PipelineRequestResultsRecord } from "./PipelineRequestDetails";
import type { BaseColumn } from "components/molecules/BaseTable";

type PipelineObjectRequestsTableProps = {
  results: PipelineRequestResultsRecord[];
  projectName: string;
  organizationName: string;
  pipelineName: string;
  pipelineVersion: string;
  pipelineRequestId: string;
};
export const PipelineObjectRequestsTable = ({
  results,
  projectName,
  organizationName,
  pipelineName,
  pipelineVersion,
  pipelineRequestId,
}: PipelineObjectRequestsTableProps) => {
  const [selectedRow, setSelectedRow] =
    useState<PipelineRequestResultsRecord | null>(null);
  const redirectUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName),
    [organizationName, projectName]
  );

  const logsUrl = useLogsUrl({
    redirectUrl: redirectUrl.index(),
  });
  const columns = useMemo(
    () => [
      {
        title: "",
        field: "expand",
        width: "1%",
      },
      {
        title: "Status",
        field: "status",
        render: ({ status }: PipelineRequestResultsRecord) => (
          <StatusIcon label={status} status={status} />
        ),
      },
      {
        title: "Object",
        field: "pipeline_object",
        render: (rowData: PipelineRequestResultsRecord) => {
          const name = rowData.deployment ?? rowData.pipeline ?? "";
          const link = getObjectLink(
            rowData.object_type,
            organizationName,
            projectName,
            name
          );

          return rowData.object_type === "operator" ? (
            <span>{rowData.pipeline_object}</span>
          ) : (
            <TableLink to={link}>{rowData.pipeline_object}</TableLink>
          );
        },
      },
      {
        title: "Type",
        field: "object_type",
        nowrap: true,
      },
      {
        title: "Version",
        field: "version",
        nowrap: true,
        render: (rowData: PipelineRequestResultsRecord) => {
          const name = rowData.deployment ?? rowData.pipeline ?? "";
          const link = getObjectLink(
            rowData.object_type,
            organizationName,
            projectName,
            name,
            rowData.version
          );

          return rowData.object_type === "operator" ? (
            <span>{rowData.operator}</span>
          ) : (
            <TableLink to={link}>{rowData.version}</TableLink>
          );
        },
      },
      {
        width: "3%",
        render: (rowData: PipelineRequestResultsRecord) => {
          const queryParams = rowData.deployment
            ? {
                deployment_name: rowData.deployment,
                deployment_version: rowData.version,
                ...(!rowData.subRequests
                  ? { deployment_request_id: rowData.id }
                  : { pipeline_name: pipelineName }),
              }
            : {
                pipeline_name: rowData.pipeline,
                pipeline_version: rowData.version,
                pipeline_request_id: rowData.id,
              };

          return (
            <div className="actions_container">
              <Tooltip title="View logs">
                <span>
                  <IconButton
                    component={NavLink}
                    to={routeMaker(logsUrl, queryParams)}
                  >
                    <LogsIcon color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [logsUrl, pipelineName, organizationName, projectName]
  );

  const data = useMemo(() => sortBy(results, ["sequence_id"]), [results]);

  return (
    <BaseTable
      columns={columns as BaseColumn[]}
      data={data}
      hasSearchBar={false}
      onRowClick={(_e, rowData) => {
        if (rowData?.id !== selectedRow?.id) {
          setSelectedRow(rowData);
        } else {
          setSelectedRow(null);
        }
      }}
      defaultPageSize={5}
      rowsPerPageOptions={[5, 10]}
      expandedRowIndex={data?.findIndex((obj) => obj.id === selectedRow?.id)}
      clearExpandedRow={() => setSelectedRow(null)}
      renderDetailPanel={(rowData) => (
        <Box display={"flex"} flexDirection={"column"}>
          {!!rowData?.subRequests?.length && rowData.error_message && (
            <Alert severity="error">{rowData.error_message}</Alert>
          )}
          <PaginatedPipelineObjectRequestResultsDetails
            resultsRecord={rowData as PipelineRequestResultsRecord}
            projectName={projectName}
            organizationName={organizationName}
            pipelineName={pipelineName}
            pipelineVersion={pipelineVersion}
            pipelineRequestId={pipelineRequestId}
          />
        </Box>
      )}
    />
  );
};

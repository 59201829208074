import {
  DEDUPING_INTERVAL,
  FOCUS_THROTTLE_INTERVAL,
} from "libs/constants/constants";

import { axios } from "./axios";

import type { AxiosResponse } from "axios";
import type { Middleware, SWRConfiguration } from "swr";

const axiosResponseMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);

    return Object.assign({}, swr, {
      data:
        (<AxiosResponse | undefined>(swr.data as unknown))?.data ?? swr.data,
    });
  };
};

export const swrConfig: SWRConfiguration = {
  fetcher: (args: string | Record<string, unknown>) =>
    axios(typeof args === "string" ? { method: "GET", url: args } : args),
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  revalidateOnMount: true,
  focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
  dedupingInterval: DEDUPING_INTERVAL,
  use: [axiosResponseMiddleware],
  onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
    // Never retry on 4XX responses
    if (parseInt(String(error.status)[0]) === 4) return;

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
  },
};

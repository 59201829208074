import { FormControlLabel, Switch as MuiSwitch } from "@mui/material";

import { SwitchWrapper } from "./SwitchWrapper";

export type SwitchProps = {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  type?: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  label?: string;
  infoTooltipText?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
};

export const Switch = ({
  checked,
  disabled = false,
  disabledTooltipText,
  onChange,
  label,
  infoTooltipText,
  labelPlacement,
}: SwitchProps) => (
  <SwitchWrapper
    tooltip={
      disabled && disabledTooltipText ? disabledTooltipText : infoTooltipText
    }
  >
    <FormControlLabel
      control={
        <MuiSwitch
          checked={checked}
          color="secondary"
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement={labelPlacement || "start"}
      style={{ margin: 0 }}
    />
  </SwitchWrapper>
);

import { isObject, isString, pick } from "lodash";
import { useEffect, useState } from "react";
import { string } from "yup";

import { DOC_LINKS } from "libs/constants/documentation-links";
import {
  FIELD_BUCKET,
  FIELD_CONFIGURATION,
  FIELD_CONTAINER,
  FIELD_ENDPOINT_URL,
  FIELD_PREFIX,
  FIELD_PROVIDER,
  FIELD_REGION,
} from "libs/constants/fields";
import { BucketDetailProvider } from "libs/data/models";

import { ExternalLink, Text } from "components/atoms";
import { ControlledSwitch, FormikTextInput } from "components/molecules";

import type { FormikProps, FormikValues } from "formik";
import type { KeyboardEvent } from "react";

export interface ConfigurationFieldProps {
  control: FormikProps<FormikValues>;
}

export const handleTabs = (e: KeyboardEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement;
  const { value } = target;

  if (e.key === "Tab") {
    e.preventDefault();

    const cursorPosition = target.selectionStart as number;
    const cursorEndPosition = target.selectionEnd as number;
    const tab = "\t";

    target.value =
      value.substring(0, cursorPosition) +
      tab +
      value.substring(cursorEndPosition);

    // if you modify the value programmatically, the cursor is moved
    // to the end of the value, we need to reset it to the correct
    // position again
    target.selectionStart = cursorPosition + 1;
    target.selectionEnd = cursorPosition + 1;
  }
};

export const S3ConfigurationField = ({ control }: ConfigurationFieldProps) => {
  const [advanced, setAdvanced] = useState(
    control.values[FIELD_CONFIGURATION]?.[FIELD_ENDPOINT_URL] ? true : false
  );

  useEffect(() => {
    const current = control.values[FIELD_CONFIGURATION];

    if (!isObject(current)) {
      const complete = {
        [FIELD_BUCKET]: "",
        [FIELD_PREFIX]: "/",
        [FIELD_REGION]: "",
      };
      control.setFieldValue(FIELD_CONFIGURATION, complete);
    }

    return () => control.setFieldValue(FIELD_CONFIGURATION, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const current = control.values[FIELD_CONFIGURATION];
    if (advanced && isObject(current)) {
      const currentObject = current as any;
      const currentIsEmpty =
        !currentObject?.[FIELD_BUCKET] &&
        !currentObject?.[FIELD_PREFIX] &&
        !currentObject?.[FIELD_REGION];
      control.setFieldValue(
        FIELD_CONFIGURATION,
        currentIsEmpty ? "" : JSON.stringify(current, null, 1)
      );
    } else if (!advanced && isString(current)) {
      try {
        control.setFieldValue(
          FIELD_CONFIGURATION,
          pick(JSON.parse(current), [FIELD_BUCKET, FIELD_PREFIX, FIELD_REGION])
        );
      } catch (e) {
        // throw silent error
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced]);

  return (
    <>
      <ControlledSwitch
        label="Advanced configuration"
        onChange={setAdvanced}
        value={advanced}
      />
      {advanced ? (
        <>
          <Text style={{ marginBottom: 20 }}>
            Enter your custom JSON connection configuration. Refer to{" "}
            <ExternalLink href={DOC_LINKS.BUCKET_ADVANCED_CONFIG}>
              the documentation
            </ExternalLink>{" "}
            for all options.
          </Text>
          <FormikTextInput
            control={control}
            label="Advanced configuration"
            minRows={7}
            name={FIELD_CONFIGURATION}
            onKeyDown={handleTabs}
            placeholder='Ex: {&#10;"bucket" : "abc", &#10;"endpoint_url" : "abc", &#10;"signature_version" : "abc", &#10;"signature_version" : "abc", &#10;"use_ssl": "abc" &#10;}'
            validation={string().test(
              FIELD_CONFIGURATION,
              "Invalid dictionary format",
              (value) => {
                if (value?.length) {
                  try {
                    JSON.parse(value ?? "");

                    return true;
                  } catch (error) {
                    return false;
                  }
                }

                return true;
              }
            )}
            multiline
          />
        </>
      ) : (
        <>
          <FormikTextInput
            control={control}
            label="Bucket"
            placeholder="bucket-name"
            name={`${FIELD_CONFIGURATION}.${FIELD_BUCKET}`}
            validation={string().required()}
            required
          />
          <FormikTextInput
            control={control}
            label="Prefix"
            required
            name={`${FIELD_CONFIGURATION}.${FIELD_PREFIX}`}
            validation={string().required()}
          />
          <FormikTextInput
            control={control}
            label="Region"
            placeholder="eu-central-1"
            required
            name={`${FIELD_CONFIGURATION}.${FIELD_REGION}`}
            validation={string().required()}
          />
        </>
      )}
    </>
  );
};

export const AzureConfigurationField = ({
  control,
}: ConfigurationFieldProps) => {
  useEffect(() => {
    const current = control.values[FIELD_CONFIGURATION];

    if (!isObject(current)) {
      const complete = {
        [FIELD_CONTAINER]: "",
        [FIELD_PREFIX]: "/",
      };
      control.setFieldValue(FIELD_CONFIGURATION, complete);
    }

    return () => control.setFieldValue(FIELD_CONFIGURATION, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikTextInput
        control={control}
        label="Container"
        placeholder="container-name"
        name={`${FIELD_CONFIGURATION}.${FIELD_CONTAINER}`}
        required
        validation={string().required()}
      />
      <FormikTextInput
        control={control}
        label="Prefix"
        required
        name={`${FIELD_CONFIGURATION}.${FIELD_PREFIX}`}
        validation={string().required()}
      />
    </>
  );
};

export const GoogleConfigurationField = ({
  control,
}: ConfigurationFieldProps) => {
  useEffect(() => {
    const current = control.values[FIELD_CONFIGURATION];

    if (!isObject(current)) {
      const complete = {
        [FIELD_BUCKET]: "",
        [FIELD_PREFIX]: "/",
      };
      control.setFieldValue(FIELD_CONFIGURATION, complete);
    }

    return () => control.setFieldValue(FIELD_CONFIGURATION, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikTextInput
        control={control}
        label="Bucket Name"
        placeholder="bucket-name"
        name={`${FIELD_CONFIGURATION}.${FIELD_BUCKET}`}
        required
        validation={string().required()}
      />
      <FormikTextInput
        control={control}
        label="Prefix"
        required
        name={`${FIELD_CONFIGURATION}.${FIELD_PREFIX}`}
        validation={string().required()}
      />
    </>
  );
};

export const ConfigurationField = ({ control }: ConfigurationFieldProps) => {
  useEffect(() => {
    return () => {
      control.setFieldValue(FIELD_CONFIGURATION, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (control.values[FIELD_PROVIDER]) {
    case BucketDetailProvider.amazon_s3:
      return <S3ConfigurationField control={control} />;
    case BucketDetailProvider.azure_blob_storage:
      return <AzureConfigurationField control={control} />;
    case BucketDetailProvider.google_cloud_storage:
      return <GoogleConfigurationField control={control} />;
    default:
      return null;
  }
};

import { Box, CircularProgress, Typography } from "@mui/material";

import type { CircularProgressProps } from "@mui/material";

interface CircularProgressWithLabelProps extends CircularProgressProps {
  percentage?: number;
}

const CircularProgressWithLabel = ({
  percentage,
  ...props
}: CircularProgressWithLabelProps) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress
      variant="determinate"
      value={percentage}
      color="secondary"
      thickness={4.5}
      {...props}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {!!percentage && (
        <Typography variant="caption" component="div" color="textSecondary">
          {percentage}%
        </Typography>
      )}
    </Box>
  </Box>
);

export default CircularProgressWithLabel;

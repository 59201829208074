import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  Redirect,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { PipelineVersionsPage } from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/versions";
import { routes } from "routes";

import { RequestsPageConfiguration } from "components/subpages";
import { Route } from "components/utilities";

import PipelineDetails from "./PipelineDetails";
import PipelineUpdate from "./PipelineUpdate";
import { PipelineAuditEvents } from "./audit-events/PipelineAuditEvents";
import PipelineConfiguration from "./configuration/PipelineConfiguration";
import PipelineGeneral from "./general/PipelineGeneral";
import PipelineUsing from "./use-pipeline/PipelineUsing";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .pipelines[":pipelineName"](":pipelineName");

const PipelineRoutesContainer = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { organizationName, projectName, pipelineName } =
    useParams<{
      organizationName: string;
      projectName: string;
      pipelineName: string;
    }>();

  const { error: pipelineError } = usePipelinesGet(projectName, pipelineName);

  useEffect(() => {
    if (pipelineError) {
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines.index()
      );
    }
  }, [pipelineError, history, organizationName, projectName]);

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .pipelines[":pipelineName"](pipelineName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{pipelineName}</BreadcrumbsItem>
      <Switch>
        <Route
          path={basePath.versions.index()}
          component={PipelineVersionsPage}
        />
        <Route exact path={basePath.edit.index()} component={PipelineUpdate} />
        <Route path={match.path}>
          <PipelineDetails>
            <Switch>
              <Route
                exact
                path={basePath.general.index()}
                component={PipelineGeneral}
              />
              <Route
                exact
                path={basePath.configuration.index()}
                component={PipelineConfiguration}
              />
              <Route
                exact
                path={basePath.auditEvents.index()}
                component={PipelineAuditEvents}
              />
              <Route
                exact
                path={basePath.usePipeline.index()}
                component={PipelineUsing}
              />
              {env.get(ENV_NAMES.REQUESTS_PAGE_ENABLED) && (
                <Route
                  exact
                  path={basePath.usePipeline.configure.index()}
                  component={RequestsPageConfiguration}
                />
              )}
              <Redirect to={baseUrl.general.index()} />
            </Switch>
          </PipelineDetails>
        </Route>
        <Redirect to={baseUrl.general.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

export default PipelineRoutesContainer;

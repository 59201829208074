import { useEffect, useState } from "react";

import { env } from "libs/env";

export type Graph = {
  metric: string[];
  title: string;
  labels?: string[];
  isBarChart?: boolean;
  isDynamicUnitPeriod?: boolean;
};

const toggleGraphType = (storageKey: string) => {
  const storedBarCharts = env.get("storedBarCharts") || [];
  if (storedBarCharts.includes(storageKey)) {
    env.set(
      "storedBarCharts",
      storedBarCharts.filter((key: string) => key !== storageKey)
    );
  } else {
    env.set("storedBarCharts", [...storedBarCharts, storageKey]);
  }
};

export const useGraphType = (
  initialGraphs: Graph[],
  storageName: "deployment" | "pipeline" | "monitoring"
) => {
  const [graphs, setGraphs] = useState(initialGraphs);

  useEffect(() => {
    setGraphs(initialGraphs);
  }, [initialGraphs]);

  const setGraphType = (title: string) => {
    toggleGraphType(`${storageName}.${title}`);
    setGraphs(
      graphs.map((graph) => ({
        ...graph,
        isBarChart:
          title === graph.title ? !graph.isBarChart : graph.isBarChart,
      }))
    );
  };

  return { graphs, setGraphType };
};

import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { Box, Grid, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { DetailsContainer } from "components/molecules/PageLayout";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  deploymentVersionsDelete,
  useDeploymentVersionsGet,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import { DeploymentVersionDetailStatus } from "libs/data/models";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import {
  ButtonGroup,
  DeleteDialog,
  Dialog,
  ExternalLink,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";

import { experimentSnippetText } from "./constants";
import { TRAINING_DEPLOYMENT } from "../../constants";

import type { ReactNode } from "react";

type ExperimentDetailsProps = { children?: ReactNode };

export const ExperimentDetails = ({ children }: ExperimentDetailsProps) => {
  const location: { state: { shouldOpenCodeSnippet?: boolean } } =
    useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSnippetDialogOpen, setSnippetDialogOpen] = useState(
    !!location?.state?.shouldOpenCodeSnippet
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { organizationName, experimentName, projectName } =
    useParams<{
      experimentName: string;
      projectName: string;
      organizationName: string;
    }>();

  const { data: experimentDetails } = useDeploymentVersionsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );
  const { mutate } = useDeploymentVersionsList(
    projectName,
    TRAINING_DEPLOYMENT
  );

  const rootUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .training.experiments.index();

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .training.experiments[":experimentName"](experimentName),
    [experimentName, organizationName, projectName]
  );

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.environmentVariables.index(),
      label: "Environment variables",
    },
  ];
  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    TRAINING_DEPLOYMENT,
    "deployment"
  );
  const handleExperimentDelete = () => {
    deploymentVersionsDelete(projectName, TRAINING_DEPLOYMENT, experimentName)
      .then(() => {
        dispatch(createSuccessNotification("Experiment deleted"));
        mutate((experiments) =>
          experiments?.filter(
            (experiment) => experiment.version !== experimentName
          )
        );
        history.push(rootUrl);
      })
      .catch((err) => dispatch(createErrorNotification(err.message)))
      .finally(() => setIsDeleteDialogOpen(false));
  };

  return (
    <>
      <DeleteDialog
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleExperimentDelete}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete experiment <b>{experimentName}</b> and
        all of the associated runs?
      </DeleteDialog>
      <DetailsContainer
        title={experimentName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_update"]]
              }
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              onClick={() => setSnippetDialogOpen(true)}
              startIcon={<IntegrationInstructionsIcon />}
            >
              Template
            </TextButton>
            <TextButton
              onClick={() => setIsDeleteDialogOpen(true)}
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_delete"]]
              }
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<PlayArrow />}
              disabled={
                experimentDetails?.status !==
                  DeploymentVersionDetailStatus.available ||
                !currentPermissions[
                  DEPLOYMENT_PERMISSIONS["version_request_create"]
                ]
              }
              tooltip={
                experimentDetails?.status !==
                DeploymentVersionDetailStatus.available
                  ? "You can only create a training run when your experiment is finished building and has status available."
                  : !currentPermissions[
                      DEPLOYMENT_PERMISSIONS["version_request_create"]
                    ]
                  ? "You don't have the permissions to create a run"
                  : ""
              }
              onClick={() => history.push(baseUrl.runs.create.index())}
              style={{ marginLeft: "auto" }}
            >
              Create run
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs}>{children}</PageTabs>
      </DetailsContainer>
      <Dialog
        open={isSnippetDialogOpen}
        onClose={() => {
          setSnippetDialogOpen(false);
        }}
        title="Training code snippet"
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight={spacing[128]}
          justifyContent="space-between"
          paddingY={spacing[8]}
          gap={spacing[4]}
        >
          <Typography textAlign="center" marginBottom={spacing[16]}>
            Your experiment is created and you can start triggering training
            runs.
            <br />
            <ExternalLink href={DOC_LINKS.TRAINING_RUNS}>
              Check our documentation
            </ExternalLink>
          </Typography>

          <CopyBlock
            {...{
              text: experimentSnippetText,
              showLineNumbers: true,
              language: "python",
              codeBlock: true,
              theme: dracula,
              wrapLines: true,
            }}
          />
          <Grid
            display="flex"
            justifyContent="flex-end"
            marginTop={spacing[16]}
          >
            <PrimaryButton
              onClick={() => {
                setSnippetDialogOpen(false);
              }}
            >
              Got it
            </PrimaryButton>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { useMetricsCreate } from "libs/data/customized/metrics";
import { gtmEvent } from "libs/hooks";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { MetricBaseForm } from "./MetricBaseForm";

import type { FormikHelpers } from "formik";
import type { MetricCreate as MetricCreateType } from "libs/data/models";

export const MetricCreate = () => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const createMetric = useMetricsCreate(projectName);
  const history = useHistory();

  const handleOnSubmit = async (
    data: MetricCreateType,
    actions: FormikHelpers<MetricCreateType>
  ) => {
    try {
      await createMetric(data);

      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .monitoring.metrics.index(),
        data
      );
    } finally {
      actions.setSubmitting(false);
    }
    gtmEvent("custom_metric_create", {
      event_category: "custom_metric_create",
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Create custom metric" />
      <FormWrapper>
        <MetricBaseForm handleOnSubmit={handleOnSubmit} />
      </FormWrapper>
    </PageContainer>
  );
};

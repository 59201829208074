import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import * as Yup from "yup";

import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_METRIC_TYPE,
  FIELD_NAME,
  FIELD_UNIT,
} from "libs/constants/fields";
import { MetricCreateMetricType } from "libs/data/models";
import validators from "libs/utilities/validators";

export const METRIC_CREATE_VALUES = {
  [FIELD_NAME]: "custom.",
  [FIELD_DESCRIPTION]: "",
  [FIELD_METRIC_TYPE]: MetricCreateMetricType.gauge,
  [FIELD_UNIT]: "",
  [FIELD_LABELS]: ["deployment_version_id"],
};

export const metricCreateSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string()
    .required(validators.required.message(FIELD_NAME))
    .matches(validators.fieldMetricName.pattern, validators.fieldMetricName),
  [FIELD_LABELS]: Yup.array().required(),
});

export const metricLevels = [
  { label: "Deployment", value: "deployment_version_id", icon: DeploymentIcon },
  { label: "Pipeline", value: "pipeline_version_id", icon: PipelineIcon },
  {
    label: "Training run",
    value: "deployment_request_id",
    icon: ModelTrainingIcon,
  },
];

export const metricSnippetText = (
  projectName?: string,
  metricName?: string,
  label?: string
) => {
  const idString =
    {
      request_id: "your_run_id",
      pipeline_version_id: "your_pipeline_version_id",
      deployment_version_id: "your_deployment_version_id",
    }[label || ""] || "your_run_id";

  return `from ubiops.utils.metrics import MetricClient

metric_client = MetricClient(
  project_name = '${projectName}'
)

metric_client.start()
  
metric_client.log_metric(
  metric_name='${metricName}',
  labels = {'${label}':'<${idString}>'},
  value = <your value>
)`;
};

export const metricGraphIds = {
  memoryUtilization: "deployment_requests.memory_utilization",
  gpuUtilization: "deployment_requests.gpu_utilization",
  cpuUtilization: "deployment_requests.cpu_utilization",
};

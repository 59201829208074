import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  createErrorNotification,
  createSuccessNotification,
} from "../../../utilities/notifications";
import {
  revisionsRebuild,
  useRevisionsList,
} from "../../endpoints/deployments/deployments";

export const useDeploymentVersionRebuild = (
  projectName: string,
  deploymentName: string,
  versionName: string
) => {
  const dispatch = useDispatch();
  const { mutate } = useRevisionsList(projectName, deploymentName, versionName);

  return useCallback(
    async (revisionId: string) => {
      try {
        const result = await revisionsRebuild(
          projectName,
          deploymentName,
          versionName,
          revisionId,
          {}
        );
        dispatch(createSuccessNotification(`Version rebuild successful`));
        mutate();

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, versionName, projectName, deploymentName, mutate]
  );
};

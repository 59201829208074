import { Box, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import { IlluSubscriptionLimit } from "assets/images/IlluSubscriptionLimit";
import { spacing } from "assets/styles/theme";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";
import { routes } from "routes";

import { PrimaryButton, Dialog, Link } from "components/atoms";

type ExceededLimitsDialogProps = {
  open: boolean;
  onClose: () => void;
  name: string;
};
export const ExceededLimitsDialog = ({
  open,
  onClose,
  name,
}: ExceededLimitsDialogProps) => {
  const { organizationName } = useParams<{ organizationName: string }>();
  const history = useHistory();
  const subscriptionPageRoute =
    routes.organizations[":organizationName"](
      organizationName
    ).subscription.index();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      Header={
        <DialogWarningHeader title="You have reached your subscription limit" />
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={spacing[28]}
      >
        <Box component={IlluSubscriptionLimit} />
        <Typography variant="subtitle1" align="center">
          You have reached your subscription limit, you can no longer create{" "}
          {name} or make changes in your organisation. Please visit{" "}
          <Link
            to={routes.organizations[":organizationName"](
              organizationName
            ).subscription.index()}
          >
            your subscription page{" "}
          </Link>
          for more details as you may have exceeded some of your limitations.
        </Typography>
        <PrimaryButton onClick={() => history.push(subscriptionPageRoute)}>
          Manage subscription
        </PrimaryButton>
      </Box>
    </Dialog>
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentVersionsUpdate,
  useDeploymentVersionsGet,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { DeploymentVersionUpdate } from "libs/data/models";

export const useDeploymentVersionsUpdate = (
  projectName: string,
  deploymentName: string,
  version: string,
  versionType: "deployment version" | "experiment" = "deployment version"
) => {
  const dispatch = useDispatch();
  const { mutate: mutateList } = useDeploymentVersionsList(
    projectName,
    deploymentName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      },
    }
  );
  const { mutate } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    version
  );

  return useCallback(
    async (data: DeploymentVersionUpdate) => {
      try {
        const result = await deploymentVersionsUpdate(
          projectName,
          deploymentName,
          version,
          data
        );

        dispatch(
          createSuccessNotification(`The ${versionType} has been updated`)
        );

        await mutate(() => result, { revalidate: false });
        await mutateList(
          (versions) =>
            versions?.map((version) =>
              version.version === result.version ? result : version
            ),
          { revalidate: false }
        );

        return result;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [
      deploymentName,
      dispatch,
      mutate,
      mutateList,
      projectName,
      version,
      versionType,
    ]
  );
};

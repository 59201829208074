import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, FormHelperText, Grid, IconButton, useTheme } from "@mui/material";
import { get } from "lodash";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { fieldTypes, fieldTypesLabels } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

import { FormTextField } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { InputOutputFieldList } from "libs/data/models";
import type { MouseEventHandler } from "react";

export type FieldProps = {
  name: string;
  index: string;
  onRowDelete?: MouseEventHandler<HTMLButtonElement>;
  defaultValue?: InputOutputFieldList;
  disabled?: boolean;
  isEditForm?: boolean;
  placeholder?: string;
  dataTypeDiasbled?: boolean;
  maxMenuHeight?: number;
  hasCloseButton?: boolean;
  isNew?: boolean;
};

export const Field = ({
  name,
  onRowDelete,
  index,
  defaultValue,
  placeholder,
  disabled = false,
  dataTypeDiasbled = false,
  hasCloseButton = true,
  isNew = false,
}: FieldProps) => {
  const [dataTypeDefaultValue, setDataTypeDefaultValue] = useState(
    fieldTypes[0]
  );
  const [hasGreenBorder, setHasGreenBorder] = useState(false);
  const { errors } = useFormContext();
  const theme = useTheme() as AppThemeProps;
  const fieldName = `${name}[${index}].name`;
  const fieldNameError = get(errors, fieldName);
  const fieldType = `${name}[${index}].data_type`;
  const fieldTypeError = get(errors, fieldType);

  useEffect(() => {
    if (defaultValue) {
      setDataTypeDefaultValue({
        label:
          fieldTypesLabels[
            defaultValue.data_type as keyof typeof fieldTypesLabels
          ],
        value: defaultValue.data_type as string,
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (hasGreenBorder) {
      timeOut = setTimeout(() => setHasGreenBorder(false), 3000);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [hasGreenBorder]);

  const handleOnBlur = () => {
    if (isNew && !fieldNameError) {
      setHasGreenBorder(true);
    }
  };

  return (
    <FieldContainer
      className="Field"
      hasGreenBorder={hasGreenBorder}
      borderColor={theme.palette.success.main}
    >
      <Grid item style={{ flex: 1 }}>
        <FormTextField
          name={fieldName}
          label="Name"
          placeholder={placeholder}
          rules={{
            pattern: {
              value: validators.fieldName.pattern,
              message: validators.fieldName.message("name"),
            },
          }}
          defaultValue={defaultValue?.name ?? ""}
          disabled={disabled}
          withError={false}
          style={{
            margin: 0,
          }}
          onBlur={handleOnBlur}
        />
      </Grid>
      <Grid item xs={5}>
        <AutoCompleteSelectHookForm
          name={fieldType}
          label="Type"
          options={fieldTypes}
          defaultValue={dataTypeDefaultValue}
          disabled={disabled && dataTypeDiasbled}
          isSearchable
        />
      </Grid>
      {hasCloseButton && (
        <IconButton
          aria-label="Delete"
          onClick={onRowDelete}
          style={{ padding: "0px 0px 0px 4px" }}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      )}
      <Grid item xs={12} hidden={!fieldNameError}>
        <FormHelperText id={fieldName} error={!!fieldNameError}>
          {fieldNameError?.message}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} hidden={!fieldTypeError}>
        <FormHelperText id={fieldType} error={!!fieldTypeError}>
          {fieldTypeError?.message}
        </FormHelperText>
      </Grid>
    </FieldContainer>
  );
};

const FieldContainer = styled(Box)<{
  hasGreenBorder: boolean;
  borderColor: string;
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5%;

  :not(:last-child) {
    margin-bottom: 16px;
  }
  fieldset,
  .advanced-select__select__control {
    border-color: ${(props) => props.hasGreenBorder && props.borderColor};
    border-width: ${(props) => props.hasGreenBorder && "2px"};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.hasGreenBorder && props.borderColor};
    fieldset:hover {
      border-color: ${(props) => props.hasGreenBorder && props.borderColor};
      border-width: ${(props) => props.hasGreenBorder && "2px"};
    }
  }
`;

import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Icon, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import { borderRadius, borders, spacing } from "assets/styles/theme";

import { Card } from "components/atoms";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties, ReactNode } from "react";

export type DefaultItem = { id?: string; name: string };
export type ItemsGridCardProps<Item = DefaultItem> = {
  items: Item[];
  title?: string;
  itemName: string;
  titleIcon?: OverridableComponent<SvgIconTypeMap>;
  onCreateClick: () => void;
  hasAddBox: boolean;
  maxHeight?: string;
  cardContentStyle?: CSSProperties;
  children: (item: Item) => ReactNode;
};
export function ItemsListCard<Item extends DefaultItem = DefaultItem>({
  items,
  title,
  itemName,
  titleIcon,
  onCreateClick,
  hasAddBox,
  children,
  maxHeight,
  cardContentStyle,
}: ItemsGridCardProps<Item>) {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme() as AppThemeProps;

  return (
    <Card
      title={title}
      icon={titleIcon}
      contentStyle={cardContentStyle}
      actions={
        <TextField
          value={searchTerm}
          size="small"
          variant="standard"
          placeholder={`Search ${itemName}`}
          onChange={({ target: { value } }) => setSearchTerm(value)}
          style={{ marginTop: "8px", marginRight: "8px", paddingBottom: "5px" }}
          InputProps={{
            startAdornment: <Icon component={SearchIcon} />,
            endAdornment: (
              <Icon onClick={() => setSearchTerm("")} component={ClearIcon} />
            ),
          }}
        />
      }
    >
      <ItemsContainer maxHeight={maxHeight}>
        {hasAddBox && (
          <CreateBox
            backgroundColor={theme.palette.background.subtle}
            key="new-project"
            title={`Create new ${itemName}`}
            onClick={onCreateClick}
          >
            <Icon component={AddIcon} color="primary" />
            <Typography variant="h4">Create new {itemName}</Typography>
          </CreateBox>
        )}

        {(items || [])
          ?.filter(({ name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((item) => (
            <div key={item.id}>{children(item)}</div>
          ))}
      </ItemsContainer>
    </Card>
  );
}

const ItemsContainer = styled(Box)<{ maxHeight?: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  gap: ${spacing[28]} ${spacing[16]};
  padding: ${spacing[16]} ${spacing[12]};
  max-height: ${(props) => props.maxHeight || spacing[560]};
  overflow-y: auto;
`;

const CreateBox = styled(Box)<{ backgroundColor: string }>`
  width: 14rem;
  height: 9rem;
  cursor: pointer;
  border-radius: ${borderRadius[10]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: ${borders.dashed};
  background-color: ${(props) => props.backgroundColor};
  :hover {
    filter: brightness(90%);
  }
`;

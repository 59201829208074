import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_CONFIGURATION, FIELD_USE_SSL } from "libs/constants/fields";
import { useBucketCreate } from "libs/data/customized/buckets/useBucketCreate";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { BucketBaseForm } from "./BucketsBaseForm";

import type { BucketBaseProps } from "./BucketsBaseForm/constants";
import type { FormikHelpers } from "formik";
import type { BucketCreate as BucketCreateRequest } from "libs/data/models";

export const BucketCreate = () => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const createBucket = useBucketCreate(projectName);
  const history = useHistory();

  const handleOnSubmit = async (
    values: BucketBaseProps,
    actions: FormikHelpers<BucketBaseProps>
  ) => {
    // Note: React does not like null as a text field value
    if (values[FIELD_CONFIGURATION]?.[FIELD_USE_SSL] === "null") {
      values[FIELD_CONFIGURATION][FIELD_USE_SSL] = null;
    }
    const response = await createBucket(values as BucketCreateRequest);
    actions.setSubmitting(false);

    if (response) {
      actions.resetForm();

      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .storage[":bucketName"](response.name)
          .index()
      );
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Create a new bucket" />
      <FormWrapper>
        <BucketBaseForm onSubmit={handleOnSubmit} />
      </FormWrapper>
    </PageContainer>
  );
};

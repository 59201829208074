import { Box } from "@mui/material";

import { ReactComponent as WarningIcon } from "assets/images/warning.svg";
import { borders } from "assets/styles/theme";
import { DialogHeaderTitle } from "components/atoms/Dialog/DialogHeaderTitle";

interface DialogWarningHeaderProps {
  title: string;
}

export const DialogWarningHeader = ({ title }: DialogWarningHeaderProps) => (
  <Box
    alignItems="center"
    borderBottom={borders.primary}
    display="flex"
    justifyContent="center"
    paddingTop={2}
    paddingBottom={1}
  >
    <WarningIcon style={{ marginRight: 10 }} />
    <DialogHeaderTitle>{title}</DialogHeaderTitle>
  </Box>
);

import { LINK_COPYRIGHT } from "libs/constants/documentation-links";
import { getTzAwareDate } from "libs/utilities/date-util";

import "./Copyright.scss";

export const Copyright = () => (
  <a
    className="copyright"
    href={LINK_COPYRIGHT}
    target="_blank"
    rel="noopener noreferrer"
  >
    © {getTzAwareDate().year()} UbiOps
  </a>
);

import moment from "moment";

import type { Query } from "./types";
import type {
  DeploymentRequestParameters,
  PipelineRequestParameters,
} from "../RequestDialog";
import type { DeploymentVersionRequestsListStatus } from "libs/data/models";

export const getRequestsParams = (
  requestParameters?: DeploymentRequestParameters | PipelineRequestParameters,
  query?: Query,
  resetPage?: boolean
) => {
  const requestBody: {
    limit: number;
    offset: number;
    sort: "asc" | "desc";
    object_type?: string;
    search_id?: string;
    success?: boolean;
    status?: DeploymentVersionRequestsListStatus;
    pipeline?: boolean;
    request_schedule?: string;
    start_date?: string;
    end_date?: string;
  } = {
    limit: query?.pageSize || 20,
    offset: resetPage ? 0 : (query?.page || 0) * (query?.pageSize || 20),
    sort: query?.orderDirection ? query?.orderDirection : "desc",
  };

  if (requestParameters?.requestScheduleName) {
    requestBody.request_schedule = requestParameters.requestScheduleName;
  }
  if (requestParameters?.type) {
    requestBody.object_type = requestParameters.type;
  }

  for (const property in query?.filters) {
    if (
      // old merging logic
      // @ts-ignore
      query?.filters[property] !== "all" &&
      // @ts-ignore
      query?.filters[property] !== null
    ) {
      if (property === "status") {
        requestBody[property] = query?.filters[
          property
        ] as DeploymentVersionRequestsListStatus;
      } else {
        // @ts-ignore
        requestBody[property] = query?.filters[property] as string;
      }
    }
  }
  if (query?.search) {
    requestBody["search_id"] = query.search;
  }

  // convert start and end date to utc format
  if (query?.filters?.start_date) {
    requestBody.start_date = moment(query.filters.start_date)
      .utc()
      .toISOString();
  }

  if (query?.filters?.end_date) {
    requestBody.end_date = moment(query.filters.end_date).utc().toISOString();
  }

  return requestBody;
};

import { Box, Grid, Typography } from "@mui/material";

import { Card, CardHeader } from "components/atoms";

import { LabeledTitle } from "../LabeledTitle";

interface DescriptionBlockProps {
  description?: string;
  labels?: {
    key: string;
    value: string | null;
  }[];
  xs?: number;
}

const DescriptionBlock = ({
  description,
  labels = [],
  xs,
}: DescriptionBlockProps) => (
  <Grid item xs={12} md={xs ?? 6}>
    <Card>
      <CardHeader
        header={<LabeledTitle title="Description" labels={labels} />}
      />
      {description ? (
        <Box whiteSpace="pre-wrap">{description.replace(/\\n/g, "\n")}</Box>
      ) : (
        <Typography variant="caption">No description available</Typography>
      )}
    </Card>
  </Grid>
);

export default DescriptionBlock;

import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";
import { MaterialIcon } from "components/atoms/Icon/MaterialIcon";
import { DetailsContainer } from "components/molecules/PageLayout/Container/DetailsContainer";
import { BUCKET_PERMISSIONS } from "libs/constants/permissions";
import { bucketsDelete } from "libs/data/endpoints/files/files";
import { bytesFormatter } from "libs/utilities/metrics-helper";
import { createSuccessNotification } from "libs/utilities/notifications";
import { bucketNameIsDefault } from "pages/organizations/:organizationName/projects/:projectName/storage/utils";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import {
  TextButton,
  ButtonGroup,
  PageTabs,
  Dialog,
  DeleteButton,
} from "components/atoms";
import { ControlledTextInput } from "components/molecules";

import { DialogDetails } from "./dialogs/DialogDetails";

import type { BucketDetail } from "libs/data/models";
import type { ReactNode } from "react";

type BucketDetailsProps = {
  children: ReactNode;
  bucketDetails: BucketDetail | undefined;
};
export const BucketDetails = ({
  children,
  bucketDetails,
}: BucketDetailsProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [actionDisabled, setActionDisabled] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationName, projectName, bucketName } =
    useParams<{
      projectName: string;
      bucketName: string;
      organizationName: string;
    }>();
  const [permissions] = useGetPermissions();

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .storage[":bucketName"](bucketName),
    [organizationName, projectName, bucketName]
  );

  const handleBucketDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const onDelete = () => {
    bucketsDelete(projectName, bucketName).then(() => {
      setIsDeleteDialogOpen(false);
      dispatch(createSuccessNotification("Bucket was deleted"));
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .storage.index()
      );
    });
  };

  const tabs = [
    {
      link: baseUrl.general.files.index(),
      label: "File Overview",
    },
    {
      link: baseUrl.general.configuration.index(),
      label: "Configuration",
    },
    {
      link: baseUrl.general.permissions.index(),
      label: "Permissions",
    },
  ];

  const isDefaultBucket = bucketNameIsDefault(bucketName);

  const handleCheckBucketNameMatch = (value: string) => {
    if (value === bucketName) setActionDisabled(false);
  };

  return (
    <DetailsContainer
      title={bucketName}
      actions={
        <ButtonGroup>
          <TextButton
            color="secondary"
            disabled={
              isDefaultBucket || !permissions[BUCKET_PERMISSIONS["update"]]
            }
            link={baseUrl.edit.index()}
            startIcon={<MaterialIcon component={Edit} />}
            tooltip={isDefaultBucket && "This bucket cannot be edited."}
          >
            Edit
          </TextButton>
          <TextButton
            disabled={
              isDefaultBucket || !permissions[BUCKET_PERMISSIONS["delete"]]
            }
            onClick={handleBucketDelete}
            startIcon={<MaterialIcon component={Trash} />}
            tooltip={isDefaultBucket && "This bucket cannot be deleted."}
          >
            Delete
          </TextButton>
        </ButtonGroup>
      }
    >
      <PageTabs tabs={tabs}>{children}</PageTabs>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        dialogBodyStyles={{ padding: 0 }}
        Header={
          <DialogWarningHeader title="Are you sure you want to delete this bucket?" />
        }
        Actions={<DeleteButton disabled={actionDisabled} onClick={onDelete} />}
      >
        {bucketDetails && (
          <DialogDetails
            name="Bucket"
            entity={{
              name: bucketDetails.name,
              size: bytesFormatter(bucketDetails.size, undefined),
              creation_time: bucketDetails.creation_date ?? "",
            }}
          />
        )}
        <Typography style={{ marginTop: spacing[12] }}>
          Please type <b>{bucketName}</b> to confirm
        </Typography>
        <ControlledTextInput onChange={handleCheckBucketNameMatch} />
      </Dialog>
    </DetailsContainer>
  );
};

import Plus from "@mui/icons-material/AddBoxRounded";
import { Typography, Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

import { CurvedArrow } from "assets/images/CurvedArrow";
import { IlluCodePackage } from "assets/images/IlluCodePackage";
import { IlluEmptyCustomEnvironments } from "assets/images/IlluEmptyCustomEnvironments";
import { IlluMakeRequest } from "assets/images/IlluMakeRequest";
import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { useDeviceDetect } from "libs/hooks";

import { ExternalLink, PrimaryButton, SecondaryButton } from "components/atoms";

const contentList = [
  {
    Icon: IlluCodePackage,
    title: "Package your code",
    subtitle: "Put your Python script in a",
    link: DOC_LINKS.DEPLOYMENT_ZIP,
    textLink: "deployment package",
  },
  {
    Icon: IlluEmptyCustomEnvironments,
    title: "Create a deployment",
    subtitle: "Specify the expected inputs and outputs and upload your code",
  },
  {
    Icon: IlluMakeRequest,
    title: "Make requests",
    subtitle: "Make requests to your deployment",
  },
];

interface DeploymentEmptyOverviewProps {
  baseUrl: string;
  permission: boolean;
}

export const DeploymentEmptyOverview = ({
  baseUrl,
  permission,
}: DeploymentEmptyOverviewProps) => {
  const history = useHistory();
  const { isMobile } = useDeviceDetect();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width={"100%"}
      gap={4}
      paddingRight={spacing[40]}
      paddingLeft={spacing[12]}
    >
      <Box>
        <Typography align="center" variant="h2">
          Create your first deployment!
        </Typography>
      </Box>
      <Grid xs={12} md={5} style={{ width: spacing[440] }}>
        <Typography align="center" variant="body1">
          <b>Deployments</b> are objects within UbiOps that <b>serve code</b>.
          From the uploaded code, UbiOps builds a container, running as a
          microservice inside UbiOps, that can receive requests to transform
          input data into output data.
        </Typography>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
        width="100%"
        marginY={spacing[2]}
        gap={4}
      >
        {contentList.map(({ Icon, title, subtitle, link, textLink }, key) => (
          <Box
            key={key}
            display="flex"
            flexWrap="wrap"
            alignItems="flex-start"
            gap={2}
          >
            {!isMobile && key === 1 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                alignSelf="center"
              >
                <CurvedArrow />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width={spacing[250]}
              gap={1}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  height: spacing[200],
                  width: spacing[220],
                  marginBottom: spacing[8],
                }}
              >
                <Icon />
              </Box>{" "}
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography textAlign="center" variant="h3">
                    {title}
                  </Typography>
                </Box>
                <Box>
                  <Typography align="center" variant="body1">
                    {subtitle} <br />
                    {link && (
                      <ExternalLink href={link} mr={1}>
                        {textLink}
                      </ExternalLink>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!isMobile && key === 1 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                alignSelf="center"
              >
                <CurvedArrow />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Box>
          {!!permission && (
            <PrimaryButton
              startIcon={<Plus />}
              onClick={() => history.push(`${baseUrl}/create`)}
            >
              Create your first deployment
            </PrimaryButton>
          )}
        </Box>
        <SecondaryButton href={DOC_LINKS.STARTER_TUTORIAL} target="_blank">
          Starter tutorial
        </SecondaryButton>
        <Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Need some inspiration?</Typography>
            <ExternalLink href={DOC_LINKS.TUTORIALS}>
              Check our tutorials
            </ExternalLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import Plus from "@mui/icons-material/AddBoxRounded";
import CancelIcon from "@mui/icons-material/CancelRounded";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DEFAULT_TIME_OUT_BATCH_REQUESTS } from "libs/constants/constants";
import { FIELD_TIMEOUT } from "libs/constants/fields";
import { BaseUrlContext, LoaderContextGlobal } from "libs/contexts";
import { useDeploymentBatchRequestsCreate } from "libs/data/customized/deployment-requests";
import { usePipelineBatchRequestsCreate } from "libs/data/customized/pipeline-requests";
import { gtmEvent } from "libs/third-parties";
import { formatRequestData } from "libs/utilities/input-parser";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import validators from "libs/utilities/validators";
import { useGetOrganizationFeatures } from "store/features";

import { FormTextField, SecondaryButton } from "components/atoms";
import { PageContainer } from "components/molecules";
import { FormContainer, RequestCreate } from "components/organisms";

import "./BatchRequestCreate.scss";
import type { InputOutputFieldDetail } from "libs/data/models";

type BatchRequestCreateProps = {
  type: "deployments" | "pipelines";
  inputType: string;
  inputFields: InputOutputFieldDetail[];
};

const BatchRequestCreate = ({
  inputFields,
  inputType,
  type,
}: BatchRequestCreateProps) => {
  const { projectName, deploymentName, pipelineName, versionName } =
    useParams<{
      projectName: string;
      deploymentName?: string;
      pipelineName?: string;
      versionName: string;
    }>();

  const methods = useForm({
    mode: "onBlur",
  });
  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();
  const { showLoader } = useContext(LoaderContextGlobal);
  const dispatch = useDispatch();
  const orgFeatures = useGetOrganizationFeatures();
  const maxTimeout =
    (type === "deployments"
      ? orgFeatures?.max_batch_deployment_timeout
      : orgFeatures?.max_batch_pipeline_timeout) ??
    DEFAULT_TIME_OUT_BATCH_REQUESTS;

  const [lastKey, setLastKey] = useState(0);
  const [requestList, setRequestList] = useState([0]);
  const timeout: number | undefined = useWatch({
    control: methods.control,
    name: FIELD_TIMEOUT,
  });

  const deploymentBatchRequestsCreate = useDeploymentBatchRequestsCreate(
    projectName,
    deploymentName,
    versionName,
    timeout
  );

  const pipelineBatchRequestsCreate = usePipelineBatchRequestsCreate(
    projectName,
    pipelineName,
    versionName,
    timeout
  );

  const onDeleteCard = (keyToDelete: number) => {
    setRequestList(requestList.filter((key) => key !== keyToDelete));
  };

  const onAddMore = () => {
    const newKey = lastKey + 1;
    setLastKey(newKey);
    setRequestList([...requestList, newKey]);
  };

  const handleCreate = ({
    requests = [],
  }: {
    requests?: any[];
    [FIELD_TIMEOUT]: any;
  }) => {
    // keep only non-deleted items
    let filteredRequests = requests.filter((_, key) =>
      requestList.includes(key)
    );

    // format request data
    filteredRequests = filteredRequests.map((request) => {
      return formatRequestData(inputFields, inputType, request);
    });

    showLoader(true);

    const request =
      type === "deployments"
        ? deploymentBatchRequestsCreate(filteredRequests)
        : pipelineBatchRequestsCreate(filteredRequests);

    request
      .then(() => {
        dispatch(
          createSuccessNotification("Requests were successfully created")
        );
        gtmEvent("batch_request_created", {
          event_category: "requests",
        });
        history.push(`${baseUrl}/requests`);
      })
      .catch((error) => {
        dispatch(createErrorNotification(error.message));
      })
      .finally(() => {
        showLoader(false);
      });
  };

  return (
    <PageContainer>
      <FormContainer
        onSubmit={handleCreate}
        buttonLabel="Send all requests"
        formMethods={methods}
      >
        <Box className="batch-request-create__container">
          <Box mt={2} mb={2}>
            <Typography variant="h3">Create batch requests</Typography>
          </Box>
          <Grid
            className="batch-request-create__timeout"
            container
            item
            wrap="nowrap"
            xs={12}
          >
            <Grid item xs={3}>
              <FormTextField
                name={FIELD_TIMEOUT}
                label="Timeout (seconds)"
                type="number"
                rules={{
                  min: {
                    value: 10,
                    message: validators.minimum_timeout.message,
                  },
                  max: {
                    value: maxTimeout,
                    message: validators.maximum_timeout.message(maxTimeout),
                  },
                }}
                defaultValue={DEFAULT_TIME_OUT_BATCH_REQUESTS}
              />
            </Grid>
          </Grid>
        </Box>
        {requestList.map((key) => (
          <Box key={key} className="batch-request-create__card">
            <Grid container spacing={1}>
              <Grid item className="batch-request-create__item">
                <RequestCreate
                  fieldName={`requests[${key}]`}
                  inputType={inputType}
                  fields={inputFields}
                />
              </Grid>
              <Grid item display="flex" alignItems="center">
                <IconButton onClick={() => onDeleteCard(key)}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}

        {requestList.length < 250 && (
          <Grid container justifyContent="left" component={Box} m={2}>
            <Grid item>
              <SecondaryButton startIcon={<Plus />} onClick={onAddMore}>
                Add more
              </SecondaryButton>
            </Grid>
          </Grid>
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default BatchRequestCreate;

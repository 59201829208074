import { requiresConsent } from "libs/env/helpers";

import type { useCookies } from "react-cookie";

export const cookieSettings: Exclude<
  Parameters<ReturnType<typeof useCookies>[1]>[2],
  undefined
> = { sameSite: "lax", path: "/" };

export const COOKIE_NAMES = {
  COOKIE_CONSENT: "UbiopsCookieConsent",
  COOKIES_PREFERENCE: "cookiesPreference",
  STATE_KEY: "stateKey",
  NEWSLETTER: "newsletter",
  TERMS_CONDITIONS: "termsConditions",
  NEW_OAUTH_USER: "oauthUser",
};

export const allCookies = {
  necessary: true,
  preferences: true,
  statistics: true,
  marketing: true,
};

export const requiredCookies = {
  necessary: true,
  preferences: false,
  statistics: false,
  marketing: false,
};

export const defaultConsent = requiresConsent() ? undefined : allCookies;
export interface CleanCookie {
  necessary?: boolean;
  preferences?: boolean;
  statistics?: boolean;
  marketing?: boolean;
}

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";

import type { CheckboxProps } from "@mui/material";

export type ControlledCheckboxProps = Omit<
  CheckboxProps,
  "checked" | "error"
> & {
  error?: string | false;
  label?: string;
  value?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
};

export const ControlledCheckbox = ({
  label,
  value,
  ...props
}: ControlledCheckboxProps) => {
  return (
    <FormControl style={{ display: "block" }}>
      <FormControlLabel
        label={label}
        control={<Checkbox color="secondary" checked={value} {...props} />}
      />
    </FormControl>
  );
};

import appTheme from "./theme";

import type { AppThemeProps } from "./theme";

export const borders = {
  primary: `1px solid ${appTheme.palette.border.primary}`,
  secondary: `2px solid ${appTheme.palette.border.primary}`,
  tertiary: `0.5px solid ${appTheme.palette.border.tertiary}`,
  dashed: `1px dashed ${appTheme.palette.border.tertiary}`,
  dashedLight: `0.5px dashed ${appTheme.palette.border.primary}`,
  dotted: `2px dotted ${appTheme.palette.border.tertiary}`,
  thick: `4px solid ${appTheme.palette.border.bold}`,
};

export type bordersProps = typeof borders;

export const borderRadius = {
  4: "4px",
  5: "5px",
  8: "8px",
  10: "10px",
  15: "15px",
};

export const getBorders = (theme: AppThemeProps) => ({
  primary: `1px solid ${theme.palette.border.primary}`,
  secondary: `2px solid ${theme.palette.border.primary}`,
  tertiary: `0.5px solid ${theme.palette.border.tertiary}`,
  dashed: `1px dashed ${theme.palette.border.tertiary}`,
  dotted: `2px dotted ${theme.palette.border.tertiary}`,
  thick: `4px solid ${theme.palette.border.bold}`,
});

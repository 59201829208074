import { get } from "lodash";

import type { FormikErrors } from "formik";

export const parseErrorFromControl = (control: any, name: string) =>
  parseError(control.touched[name] && get(control.errors, name));

export const parseError = (
  error?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[]
): string | undefined => {
  if (error) {
    if (Array.isArray(error)) {
      return parseError(error[0]);
    } else if (typeof error === "string") {
      return error;
    } else {
      return JSON.stringify(error);
    }
  }

  return undefined;
};

import type { NodeDataType } from "./types";
import type { Node } from "reactflow";

export const suggestNewNameForPipelineObject = (
  nodes: Node<NodeDataType>[],
  name: string,
  aNumberToAddAfterTheName = 0
): string => {
  const newName = aNumberToAddAfterTheName
    ? `${name}-${aNumberToAddAfterTheName}`
    : name;

  if (!nodes.find((node) => node.data.pipelineObject.name === newName)) {
    return newName;
  }

  return suggestNewNameForPipelineObject(
    nodes,
    name,
    aNumberToAddAfterTheName + 1
  );
};

import { Typography } from "@mui/material";

import type { TypographyProps } from "@mui/material";

export const ListItemValue = (props: TypographyProps) => (
  <Typography
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    {...props}
  />
);

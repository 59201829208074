import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { authorizeSingleUseToken } from "libs/data/endpoints/authorize/authorize";
import { downloadFileStreaming } from "libs/utilities/download-helper";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeploymentVersionRevisionDownload = (
  projectName: string,
  deploymentName: string,
  versionName: string
) => {
  const dispatch = useDispatch();

  return useCallback(
    async (revisionId?: string) => {
      try {
        const response = await authorizeSingleUseToken({});
        downloadFileStreaming(
          `/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/revisions/${revisionId}/download?authtoken=${response.token}`
        );
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [projectName, deploymentName, versionName, dispatch]
  );
};

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { useMetricsEdit } from "libs/data/customized/metrics/useMetricEdit";
import { useMetricsGet } from "libs/data/endpoints/metrics/metrics";
import { routes } from "routes";

import { FormWrapper, Loader } from "components/atoms";
import { PageContainer } from "components/molecules";

import { MetricBaseForm } from "./MetricBaseForm";

import type { FormikHelpers } from "formik";
import type { MetricCreate } from "libs/data/models";

export const MetricEdit = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, metricName } =
    useParams<{
      organizationName: string;
      projectName: string;
      metricName: string;
    }>();
  const {
    data: metricDetails,
    error,
    mutate,
  } = useMetricsGet(projectName, metricName);
  const history = useHistory();
  const editMetric = useMetricsEdit(projectName);
  const isLoading = !metricDetails && !error;

  const onSubmit = async (
    data: MetricCreate,
    actions: FormikHelpers<MetricCreate>
  ) => {
    try {
      await editMetric(metricDetails?.name, data);
      mutate();
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .monitoring.metrics.index()
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <BreadcrumbsItem to={match.url}>Edit</BreadcrumbsItem>

      <PageHeader title={`Edit ${metricDetails?.name}`} />
      <FormWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <MetricBaseForm
            defaultValues={metricDetails}
            handleOnSubmit={onSubmit}
            isEditForm
          />
        )}
      </FormWrapper>
    </PageContainer>
  );
};

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { authorizeCreate } from "libs/data/endpoints/authorize/authorize";
import { ENV_NAMES, env } from "libs/env";
import { routes } from "routes";
import { setError, setIsAuthenticated } from "store/features";

import type { AxiosError } from "axios";
import type { CustomTokenRefresh } from "libs/data/models";

export type HandleAuthPayload = {
  email: string;
  password: string;
  token?: string;
};

export const useAuthorize = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const authorize = async ({ email, password, token }: HandleAuthPayload) => {
    setIsLoading(true);

    try {
      const { access, refresh } = (await authorizeCreate({
        email,
        password,
        // 'token' does not exist in type 'CustomTokenObtainPair'
        // but supported by the api
        // @ts-ignore
        token,
      })) as unknown as CustomTokenRefresh;
      setIsLoading(false);
      env.set(ENV_NAMES.ACCESS_TOKEN, access);
      env.set(ENV_NAMES.REFRESH_TOKEN, refresh);
      env.set(ENV_NAMES.LAST_LOGIN, Date.now());

      const redirectUrl = env.get(ENV_NAMES.REDIRECT_URL) || "/";
      dispatch(setIsAuthenticated(true));
      history.push(redirectUrl?.pathname || redirectUrl);
    } catch (e: unknown) {
      const error = e as AxiosError;

      // check if it's a 2FA error
      if (error?.response?.status === 406) {
        history.push(routes.signin.twoFA.index(), { email, password });
      } else {
        dispatch(setError(error.message));
        setIsLoading(false);
      }
    }
  };

  return { authorize, isLoading };
};

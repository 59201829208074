import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useTimeSeriesSearch } from "libs/data/endpoints/metrics/metrics";

export const useCustomMetrics = (labels?: string) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isCustom, setCustom] = useState(false);
  const { data, isValidating } = useTimeSeriesSearch(
    projectName,
    {
      custom: true,
      ...(labels ? { labels } : {}),
    },
    { swr: { enabled: isCustom } }
  );

  const options = useMemo(() => {
    const uniqueIds = [...new Set(data?.map(({ metric }) => metric))];

    return uniqueIds.map((id) => ({
      metric: [id],
      title: id,
    }));
  }, [data]);

  return { isCustom, setCustom, options, isLoading: isValidating };
};

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { MonitoringRequestsPage } from "pages/organizations/:organizationName/projects/:projectName/monitoring/requests";
import { routes } from "routes";

import { FormPageWrapper } from "components/molecules";
import { Route } from "components/utilities";

import Monitoring from "./Monitoring";
import { MonitoringActiveInstances } from "./active-instances";
import { MonitoringAuditEvents } from "./audit-events";
import { MonitoringGeneral } from "./general/MonitoringGeneral";
import { MetricsOverview } from "./metrics";
import { MetricCreate } from "./metrics/MetricCreate";
import { MetricEdit } from "./metrics/MetricEdit";
import { MonitoringWebHooks } from "./webHooks";
import { WebHookEdit } from "./webHooks/:webHookName/WebHookEdit";
import { WebHookDetails } from "./webHooks/:webHookName/general/WebHookDetails";
import { WebHookCreate } from "./webHooks/WebHookCreate";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").monitoring;

export const MonitoringPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).monitoring;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Monitoring</BreadcrumbsItem>

      <Switch>
        <Route path={basePath.metrics.create.index()}>
          <FormPageWrapper name="Metrics" baseUrl={baseUrl.metrics.index()}>
            <MetricCreate />
          </FormPageWrapper>
        </Route>
        <Route
          path={basePath.metrics[":metricName"](":metricName").edit.index()}
          exact
        >
          <FormPageWrapper name="Metrics" baseUrl={baseUrl.metrics.index()}>
            <MetricEdit />
          </FormPageWrapper>
        </Route>
        <Route path={basePath.webHooks.create.index()}>
          <FormPageWrapper name="Webhooks" baseUrl={baseUrl.webHooks.index()}>
            <WebHookCreate />
          </FormPageWrapper>
        </Route>
        <Route
          path={basePath.webHooks[":webHookName"](":webHookName").index()}
          exact
        >
          <FormPageWrapper name="Webhooks" baseUrl={baseUrl.webHooks.index()}>
            <WebHookDetails />
          </FormPageWrapper>
        </Route>
        <Route
          path={basePath.webHooks[":webHookName"](":webHookName").edit.index()}
          exact
        >
          <FormPageWrapper name="Webhooks" baseUrl={baseUrl.webHooks.index()}>
            <WebHookEdit />
          </FormPageWrapper>
        </Route>

        <Route path={basePath.index()}>
          <Monitoring>
            <Switch>
              <Route
                path={basePath.requests.index()}
                component={MonitoringRequestsPage}
                exact
              />
              <Route
                path={basePath.general.index()}
                component={MonitoringGeneral}
                exact
              />
              <Route
                path={basePath.auditEvents.index()}
                component={MonitoringAuditEvents}
              />
              <Route
                path={basePath.metrics.index()}
                component={MetricsOverview}
              />
              <Route
                path={basePath.webHooks.index()}
                component={MonitoringWebHooks}
                exact
              />
              <Route
                path={basePath.activeInstances.index()}
                component={MonitoringActiveInstances}
                exact
              />
              <Redirect to={baseUrl.general.index()} />
            </Switch>
          </Monitoring>
        </Route>
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { devicesCreate, useDevicesList } from "libs/data/endpoints/user/user";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeviceCreate = () => {
  const dispatch = useDispatch();
  const { mutate } = useDevicesList();

  return useCallback(
    async (data: any) => {
      try {
        const response = await devicesCreate(data);
        mutate();

        return response;
      } catch (err) {
        const message = (err as AxiosError).message;

        dispatch(createErrorNotification(message));

        return;
      }
    },
    [dispatch, mutate]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { environmentBuildsUpdate } from "libs/data/endpoints/environments/environments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useEnvironmentRevisionBuildCancel = (
  projectName: string,
  environmentName: string
) => {
  const dispatch = useDispatch();

  return useCallback(
    async (revisionId: string | undefined, buildId: string | undefined) => {
      try {
        const result = await environmentBuildsUpdate(
          projectName,
          environmentName,
          revisionId || "",
          buildId || "",
          { status: "cancelled" }
        );
        dispatch(
          createSuccessNotification(
            `Cancelling environment revision build successful`
          )
        );

        return result;
      } catch (error: unknown) {
        const message = (error as AxiosError)?.message;
        dispatch(createErrorNotification(message ?? "Something went wrong"));

        return;
      }
    },
    [dispatch, environmentName, projectName]
  );
};

import * as Yup from "yup";

import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DESCRIPTION,
  FIELD_NAME,
  FIELD_REQUIREMENTS_FILE,
  FIELD_LABELS,
} from "libs/constants/fields";
import validators, { yupValidators } from "libs/utilities/validators";

import type { LabelsDict } from "components/molecules";

import type { EnvironmentRevisionsFileUploadBody } from "libs/data/models";

export interface EnvironmentBaseProps {
  [FIELD_NAME]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]?: string;
  [FIELD_REQUIREMENTS_FILE]?: EnvironmentRevisionsFileUploadBody;
  [FIELD_LABELS]?: LabelsDict;
}

export const EnvironmentBaseValues: EnvironmentBaseProps = {
  [FIELD_NAME]: "",
  [FIELD_DESCRIPTION]: "",
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: undefined,
  [FIELD_REQUIREMENTS_FILE]: undefined,
  [FIELD_LABELS]: {},
};

export const EnvironmentBaseSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string()
    .required(validators.required.message(FIELD_NAME))
    .matches(validators.name.pattern, validators.name.message(FIELD_NAME))
    .test("reserved_name", validators.name.reservedNameYup("environment")),
  [FIELD_DESCRIPTION]: Yup.string(),
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: Yup.string().required(),
  [FIELD_REQUIREMENTS_FILE]: Yup.mixed()
    .required(validators.required.message(FIELD_REQUIREMENTS_FILE))
    .test(
      "file_type",
      yupValidators.fileExtension.message,
      yupValidators.fileExtension.test
    ),
});

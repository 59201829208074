import { Box, Typography } from "@mui/material";

import { IlluRestrictedPermissions } from "assets/images/IlluRestrictedPermissions";
import { spacing } from "assets/styles/theme";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";

import { Dialog } from "components/atoms";

export const RestrictedPermissionsDialog = ({
  open,
  onClose,
  type,
}: {
  open: boolean;
  onClose: () => void;
  type: string;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      Header={
        <DialogWarningHeader
          title={`You have ${type} only restricted permissions`}
        />
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={spacing[12]}
      >
        <IlluRestrictedPermissions />
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: spacing[20], fontWeight: 600 }}
        >
          You have read only permissions on this bucket wich restricts your
          view. To request changes to your permissions on this folder, contact
          your admin.
        </Typography>
      </Box>
    </Dialog>
  );
};

import { FIELD_ERROR_MESSAGE } from "libs/constants/fields";

import { FormTextField } from "components/atoms";

import type { OperatorFormProps } from "./types";

export const OperatorRaiseErrorForm = ({ value }: OperatorFormProps) => {
  return (
    <FormTextField
      id={FIELD_ERROR_MESSAGE}
      name={FIELD_ERROR_MESSAGE}
      label="Error message"
      defaultValue={value?.error_message ?? ""}
    />
  );
};

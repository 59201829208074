import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { DeploymentRoutesContainer } from "./:deploymentName";
import DeploymentCreate from "./:deploymentName/DeploymentCreate";
import { DeploymentsOverview } from "./DeploymentsOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").deployments;

export const DeploymentsPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).deployments;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Deployments</BreadcrumbsItem>
      <Switch>
        <Route exact path={basePath.index()} component={DeploymentsOverview} />
        <Route path={basePath.create.index()} component={DeploymentCreate} />
        <Route
          path={basePath[":deploymentName"](":deploymentName").index()}
          component={DeploymentRoutesContainer}
        />
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { getEdgeInfoFromAttachment } from "./getEdgeInfoFromAttachment";

import type { EdgeDataType, NodeDataType } from "./types";
import type { AttachmentsList, PipelineVersionDetail } from "libs/data/models";
import type { Edge, Node } from "reactflow";

export const getInitialEdges = (
  organizationName: string,
  projectName: string,
  pipelineVersionDetail?: PipelineVersionDetail,
  nodes: Node<NodeDataType>[] = [],
  isReadOnly = false
) =>
  !pipelineVersionDetail
    ? ({ defaultDiamondNodes: [], defaultEdges: [] } as {
        defaultDiamondNodes: Node<NodeDataType>[];
        defaultEdges: Edge[];
      })
    : (pipelineVersionDetail.attachments || ([] as AttachmentsList[]))?.reduce(
        ({ defaultDiamondNodes, defaultEdges }, attachment) => {
          const destinationObject = nodes.find(
            (node) =>
              node.data.pipelineObject.name === attachment.destination_name
          )?.data.pipelineObject;

          const sourceObjects = nodes.filter((node) => {
            const containsPipelineObjectName = attachment.sources
              ?.map((source) => source.source_name)
              .includes(node.data.pipelineObject.name);

            return containsPipelineObjectName;
          });

          if (!destinationObject || !sourceObjects.length) {
            return {
              defaultDiamondNodes,
              defaultEdges,
            };
          }

          const { diamondNodes, edges } = getEdgeInfoFromAttachment(
            attachment,
            destinationObject,
            nodes,
            isReadOnly,
            organizationName,
            projectName,
            pipelineVersionDetail.pipeline,
            pipelineVersionDetail.version
          );

          return {
            defaultDiamondNodes: [...defaultDiamondNodes, ...diamondNodes],
            defaultEdges: [...defaultEdges, ...edges],
          };
        },
        { defaultDiamondNodes: [], defaultEdges: [] } as {
          defaultDiamondNodes: Node<NodeDataType>[];
          defaultEdges: Edge<EdgeDataType>[];
        }
      );

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  pipelineVersionsDelete,
  usePipelineVersionsList,
} from "libs/data/endpoints/pipelines/pipelines";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const usePipelineVersionDelete = (
  projectName: string,
  pipelineName: string
) => {
  const { mutate } = usePipelineVersionsList(
    projectName,
    pipelineName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/pipelines/${pipelineName}/versions`,
      },
    }
  );
  const dispatch = useDispatch();

  return useCallback(
    async (versionName: string) => {
      try {
        await pipelineVersionsDelete(projectName, pipelineName, versionName);
        dispatch(createSuccessNotification("Pipeline version was deleted."));
        await mutate((pipelineVersions) =>
          pipelineVersions?.filter((version) => version.version !== versionName)
        );

        return true;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [mutate, projectName, pipelineName, dispatch]
  );
};

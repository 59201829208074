import { useCallback } from "react";
import { useCookies } from "react-cookie";

import { cookieSettings } from "./constants";

export const useCustomCookies = <T extends string>(dependencies?: T[]) => {
  const [cookies, setCookie, removeCookie] = useCookies(dependencies);

  const setCustomCookie: typeof setCookie = useCallback(
    (name, value, options = {}) =>
      setCookie(name, value, { ...options, ...cookieSettings }),
    [setCookie]
  );

  const removeCustomCookie: typeof removeCookie = useCallback(
    (name, options = {}) =>
      removeCookie(name, { ...options, ...cookieSettings }),
    [removeCookie]
  );

  return [cookies, setCustomCookie, removeCustomCookie] as [
    typeof cookies,
    typeof setCookie,
    typeof removeCookie
  ];
};

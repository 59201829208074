import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";
import { explanations } from "libs/utilities/explanations";

import { InfoAlert } from "components/atoms";

import { EntityChip } from "./EntityChip";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { EnvironmentList } from "libs/data/models";

interface AddEnvironmentsProps {
  environments: string[];
  setEnvironments: (environments: string[]) => void;
  customEnvironments: EnvironmentList[] | undefined;
  requiredEnvironments: string[];
}

export const AddEnvironments = ({
  environments,
  setEnvironments,
  customEnvironments,
  requiredEnvironments,
}: AddEnvironmentsProps) => {
  const theme = useTheme() as AppThemeProps;
  const [selectedEnvironments, setSelectedEnvironments] = useState<string[]>([
    "",
  ]);
  const allEnvironmentNames = customEnvironments?.map(({ name }) => name) || [];

  const availableToSelect = allEnvironmentNames.filter(
    (name) =>
      !environments.includes(name) && !requiredEnvironments.includes(name)
  );

  const removableEnvironments = environments.filter(
    (name) => !requiredEnvironments.includes(name)
  );

  return (
    <>
      <FormControl
        size="small"
        sx={{
          width: spacing[220],
        }}
      >
        <InputLabel
          sx={{
            background: theme.palette.background.paper,
            paddingLeft: spacing[8],
            paddingRight: spacing[8],
          }}
          shrink={true}
          id="environment-select-label"
        >
          Environments
        </InputLabel>
        <Select
          maxRows={10}
          multiple
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          value={selectedEnvironments}
          IconComponent={ExpandMoreIcon}
          renderValue={() =>
            selectedEnvironments.length > 1
              ? `${selectedEnvironments.length - 1} selected`
              : "Select..."
          }
          disabled={availableToSelect.length === 0}
          onClose={() => {
            setEnvironments([
              ...environments,
              ...selectedEnvironments.filter(Boolean),
            ]);
            setSelectedEnvironments([""]);
            setTimeout(() => {
              // remove select focus after closing the list
              // https://github.com/mui/material-ui/issues/26076
              // @ts-ignore
              document.activeElement.blur();
            }, 0);
          }}
          onChange={(event) =>
            setSelectedEnvironments(event.target.value as string[])
          }
          id="environment-select"
        >
          {availableToSelect.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedEnvironments.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {environments.length === 0 && requiredEnvironments.length === 0 && (
        <InfoAlert style={{ width: "fit-content" }}>
          No environments selected
        </InfoAlert>
      )}
      {(environments.length > 0 || requiredEnvironments.length > 0) && (
        <Typography>Added environments: </Typography>
      )}
      <Box>
        {removableEnvironments.map((name) => (
          <EntityChip
            key={name}
            name={name}
            onDelete={() =>
              setEnvironments(
                environments.filter((envName) => envName !== name)
              )
            }
          />
        ))}
        {requiredEnvironments.map((name) => (
          <EntityChip
            title={
              explanations.importsExports.exports.createExport
                .requiredEnvironment
            }
            key={name}
            name={name}
            disabled
          />
        ))}
      </Box>
    </>
  );
};

import Copy from "@mui/icons-material/FileCopyRounded";
import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import { BaseTable } from "components/molecules/BaseTable";
import {
  FIELD_SOURCE_DEPLOYMENT,
  FIELD_SOURCE_VERSION,
} from "libs/constants/fields";
import {
  deploymentEnvironmentVariablesCopy,
  deploymentEnvironmentVariablesList,
  deploymentVersionEnvironmentVariablesCopy,
  deploymentVersionEnvironmentVariablesList,
  useDeploymentEnvironmentVariablesList,
  useDeploymentVersionEnvironmentVariablesList,
  useDeploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { getTzAwareDate } from "libs/utilities/date-util";
import { createErrorNotification } from "libs/utilities/notifications";

import { Divider, PrimaryButton, WarningText } from "components/atoms";
import { columns } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { AxiosError } from "axios";
import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  EnvironmentVariableCopy,
  InheritedEnvironmentVariableList,
} from "libs/data/models";

type EnvironmentVariablesCopyProps = {
  isPage: boolean;
  onCopySuccess: () => void;
  deploymentName: string;
  deploymentVersionName?: string;
  onCopy?: (copiedEnvVars: InheritedEnvironmentVariableList[]) => void;
};

export const ControlledEnvironmentVariablesCopy = ({
  deploymentName,
  deploymentVersionName,
  onCopySuccess,
  onCopy,
}: EnvironmentVariablesCopyProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const dispatch = useDispatch();
  const [selectedDeployment, setSelectedDeployment] =
    useState<string | undefined>(undefined);
  const [selectedDeploymentVersion, setSelectedDeploymentVersion] =
    useState<string | undefined>(undefined);
  const [selectedDeploymentVariables, setSelectedDeploymentVariables] =
    useState<InheritedEnvironmentVariableList[]>([]);

  const theme = useTheme() as AppThemeProps;

  const { data: deployments } = useDeploymentsList(projectName);
  const { data: versions } = useDeploymentVersionsList(
    projectName,
    selectedDeployment ?? ""
  );
  const sortedVersions = useMemo(() => {
    return versions
      ? versions?.map(({ version }) => ({ label: version, value: version }))
      : [];
  }, [versions]);

  const sortedDeployments = useMemo(() => {
    if (deployments) {
      return deployments
        .sort((a, b) =>
          getTzAwareDate(b?.last_updated || "").isAfter(
            getTzAwareDate(a?.last_updated || "")
          )
            ? 1
            : -1
        )
        .map(({ name }) => ({ label: name, value: name }));
    } else return [];
  }, [deployments]);

  useEffect(() => {
    if (sortedDeployments.length)
      setSelectedDeployment(sortedDeployments[0]?.value);
  }, [sortedDeployments]);

  const { data: deploymentVariables } = useDeploymentEnvironmentVariablesList(
    projectName,
    selectedDeployment ?? ""
  );
  const { data: deploymentVersionVariables } =
    useDeploymentVersionEnvironmentVariablesList(
      projectName,
      selectedDeployment ?? "",
      selectedDeploymentVersion ?? ""
    );

  useEffect(() => {
    setSelectedDeploymentVariables(
      (deploymentVersionVariables || deploymentVariables || [])?.filter(
        (v) => !v.inheritance_type
      )
    );
  }, [deploymentVariables, deploymentVersionVariables]);

  const onCopyEnvVars = async () => {
    const request: EnvironmentVariableCopy = {
      [FIELD_SOURCE_DEPLOYMENT]: selectedDeployment ?? "",
    };

    if (selectedDeploymentVersion) {
      request[FIELD_SOURCE_VERSION] = selectedDeploymentVersion;
    }
    if (onCopy !== undefined) {
      onCopy(
        request[FIELD_SOURCE_VERSION]
          ? await deploymentVersionEnvironmentVariablesList(
              projectName,
              request[FIELD_SOURCE_DEPLOYMENT] ?? "",
              request[FIELD_SOURCE_VERSION] ?? ""
            )
          : await deploymentEnvironmentVariablesList(
              projectName,
              request[FIELD_SOURCE_DEPLOYMENT] ?? ""
            )
      );
      onCopySuccess();
    } else {
      try {
        if (deploymentVersionName) {
          await deploymentVersionEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            deploymentVersionName,
            request
          );
        } else {
          await deploymentEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            request
          );
        }
        onCopySuccess();
      } catch (e) {
        dispatch(createErrorNotification((e as AxiosError)?.message));
      }
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item>
        <AutoCompleteSelect
          label="Source deployment"
          options={sortedDeployments}
          value={{
            label: selectedDeployment ?? "",
            value: selectedDeployment ?? "",
          }}
          onChange={(option) => {
            option?.value && setSelectedDeployment(option.value as string);
            setSelectedDeploymentVersion(undefined);
          }}
        />
      </Grid>

      <Grid item>
        <AutoCompleteSelect
          label="Source version (optional)"
          options={sortedVersions}
          value={{
            label: selectedDeploymentVersion ?? "",
            value: selectedDeploymentVersion ?? "",
          }}
          onChange={(option) =>
            option?.value &&
            setSelectedDeploymentVersion(option.value as string)
          }
        />
      </Grid>
      <Divider my={2} />
      {selectedDeploymentVariables.length ? (
        <Grid container spacing={2} flexDirection="column">
          <Grid item>
            <Typography variant="subtitle1">
              The following variables will be copied:
            </Typography>
          </Grid>
          <Grid item>
            <BaseTable
              columns={columns(theme.palette.error.main) as BaseColumn[]}
              data={selectedDeploymentVariables}
              hasSearchBar={false}
              hasPagination={false}
            />
          </Grid>
          <Grid item>
            <WarningText color={theme.palette.primary.main}>
              All variables with the same name will be overwritten by this
              action.
            </WarningText>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="subtitle1" color="secondary">
            Selected deployment has no variables.
          </Typography>
        </Grid>
      )}

      <Grid item alignSelf="flex-end">
        <PrimaryButton
          onClick={onCopyEnvVars}
          startIcon={<Copy />}
          disabled={selectedDeploymentVariables?.length === 0}
        >
          Copy
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

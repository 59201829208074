import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import type { FC, Dispatch, SetStateAction } from "react";

const SidebarRightStateContext = createContext<
  [string | null, Dispatch<SetStateAction<string | null>>]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>([null, () => {}] as unknown as [
  string | null,
  Dispatch<SetStateAction<string | null>>
]);

export const SidebarRightStateContextProvider: FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [isIdOpen, setIsIdOpen] = useState<string | null>(null);

  return (
    <SidebarRightStateContext.Provider value={[isIdOpen, setIsIdOpen]}>
      {children}
    </SidebarRightStateContext.Provider>
  );
};

export const useIsSidebarRightOpen = () => {
  const [isIdOpen] = useContext(SidebarRightStateContext);

  return !!isIdOpen;
};

export const useSidebarRightState = (id: string) => {
  const [isIdOpen, setIsIdOpen] = useContext(SidebarRightStateContext);

  const isOpen = useMemo(() => isIdOpen === id, [isIdOpen, id]);

  const setIsOpen = useCallback<Dispatch<SetStateAction<boolean>>>(
    (props) => {
      setIsIdOpen((prevKeyOpen) => {
        const isOpen =
          typeof props === "function"
            ? props(prevKeyOpen ? prevKeyOpen === id : false)
            : props;

        return isOpen ? id : null;
      });
    },
    [id, setIsIdOpen]
  );

  return [isOpen, setIsOpen] as [typeof isOpen, typeof setIsOpen];
};

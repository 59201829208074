import { Box } from "@mui/material";
import { FileUploader } from "react-drag-drop-files-adjusted";

import { borders } from "assets/styles/theme";

import type { CSSProperties, ReactNode } from "react";

interface DropSelectFileProps {
  children?: ReactNode;
  handleChange?: (file: File) => void;
  name?: string;
  padding?: number;
  types?: string[];
  style?: CSSProperties;
  multiple?: boolean;
}

export const DropSelectFile = ({
  children,
  handleChange,
  padding,
  style = {},
  multiple = false,
  ...props
}: DropSelectFileProps) => (
  <FileUploader
    dropMessageStyle={{
      background: "unset",
      border: borders.thick,
    }}
    handleChange={handleChange}
    multiple={multiple}
    {...props}
  >
    <Box
      border={borders.dotted}
      borderRadius="5px"
      padding={padding === undefined ? 5 : padding}
      style={style}
    >
      {children}
    </Box>
  </FileUploader>
);

import AddIcon from "@mui/icons-material/Add";
import { Chip, Grid } from "@mui/material";

import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useGetPermissions } from "store/features/permissions";

interface AddRoleChipProps {
  toggleDialog: any;
}

export const AddRoleChip = ({ toggleDialog }: AddRoleChipProps) => {
  const [currentPermissions] = useGetPermissions();

  return (
    <Grid item>
      <Chip
        disabled={
          !currentPermissions[USER_ROLE_PERMISSIONS["assignment_create"]]
        }
        clickable
        color="secondary"
        size="small"
        onClick={toggleDialog}
        label="Add role"
        icon={<AddIcon fontSize="small" />}
      />
    </Grid>
  );
};

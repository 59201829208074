import { Box } from "@mui/material";
import { useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getTzAwareDate, DATE_TIME_FORMAT } from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import {
  Alert,
  DetailsDialogItem,
  StatusIcon,
  Dialog,
  PrimaryButton,
  DialogEmptyActions,
} from "components/atoms";

interface ImportExportDetailsDialogProps {
  onClose: () => void;
  open: boolean;
  page: string;
  recordData?: any;
  importConfirmPermission?: any;
}

export const ImportExportDetailsDialog = ({
  onClose,
  open,
  page,
  recordData,
  importConfirmPermission,
}: ImportExportDetailsDialogProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    id,
    creation_date,
    error_message,
    status,
    exported_by,
    imported_by,
    description,
  } = recordData || {};

  const showConfirmImportButton = useMemo(
    () => importConfirmPermission && status === "confirmation",
    [status, importConfirmPermission]
  );

  const toImportConfirmation = useCallback(() => {
    history.push({
      pathname: `${pathname}/create`,
      state: {
        id,
      },
    });
  }, [id, history, pathname]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title={`${page} details`}
      maxWidth="md"
      Actions={
        showConfirmImportButton ? (
          <PrimaryButton onClick={toImportConfirmation}>
            Review and confirm
          </PrimaryButton>
        ) : (
          <DialogEmptyActions />
        )
      }
    >
      <Box>
        {error_message && (
          <Alert severity="error">{`Error: ${error_message}`}</Alert>
        )}
        <DetailsDialogItem title={`${page} ID`}>{id}</DetailsDialogItem>
        {exported_by && (
          <DetailsDialogItem title="Description">
            {description}
          </DetailsDialogItem>
        )}
        <DetailsDialogItem title="Status">
          <StatusIcon label={formatStatusLabel(status)} status={status} />
        </DetailsDialogItem>

        <DetailsDialogItem title="Created at">
          {getTzAwareDate(creation_date).format(DATE_TIME_FORMAT)}
        </DetailsDialogItem>

        <DetailsDialogItem title={exported_by ? "Exported by" : "Imported by"}>
          {exported_by ?? imported_by}
        </DetailsDialogItem>
      </Box>
    </Dialog>
  );
};

import {
  usePipelinesGet,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";

export const usePipelineDefaultVersion = (
  projectName = "",
  pipelineName = ""
) => {
  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);

  return usePipelineVersionsGet(
    projectName,
    pipelineName,
    pipeline?.default_version || ""
  );
};

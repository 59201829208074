import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentsDelete,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeploymentDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useDeploymentsList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/deployments`,
    },
  });

  return useCallback(
    async (deploymentName: string) => {
      try {
        await deploymentsDelete(projectName, deploymentName);
        dispatch(createSuccessNotification("Deployment was deleted"));
        await mutate((deployments) =>
          deployments?.filter(
            (deployment) => deployment.name !== deploymentName
          )
        );

        return true;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return false;
      }
    },
    [projectName, dispatch, mutate]
  );
};

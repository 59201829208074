import { Box, Typography, useTheme } from "@mui/material";

import { NoDataIllu } from "assets/images/no-data";
import { spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties } from "react";

type NoDataProps = {
  height?: string;
  className?: string;
  style?: CSSProperties;
  text?: string;
};
export const NoData = ({
  height,
  className,
  style,
  text = "No Data",
}: NoDataProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={className}
      style={style}
      height={height}
    >
      <Box
        sx={{
          width: "100%",
          margin: "auto",
        }}
        component={NoDataIllu}
      />
      <Typography
        align="center"
        variant="subtitle1"
        style={{ color: theme.palette.neutrals[400], maxWidth: spacing[310] }}
      >
        {text}
      </Typography>
    </Box>
  );
};

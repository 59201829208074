import JSZip from "jszip";

import { MAX_DEPLOYMENT_FILE_SIZE_IN_BYTE } from "libs/constants/constants";

import { getRandomId, isArrayHasData } from "./utils";

const validFolderNames = ["deployment_package/", "model_package/"];
const requiredFilePaths = [
  "deployment_package/deployment.py",
  "deployment_package/deployment.R",
  "deployment_package/model.py",
  "model_package/deployment.py",
  "model_package/deployment.R",
  "model_package/model.py",
  "deployment.py",
  "deployment.R",
  "model.py",
];
const acceptedZipFormats = [
  ".zip",
  "application/zip",
  "application/x-zip",
  "application/x-zip-compressed",
];

export const validateZipFileType = ({ type }: { type: string }) =>
  acceptedZipFormats.indexOf(type) > -1;

export const validateZipFileSize = ({ size }: { size: number }) =>
  size <= MAX_DEPLOYMENT_FILE_SIZE_IN_BYTE;

export const getZipFilesContentAsArray = (file: File) =>
  JSZip.loadAsync(file).then((zip) => Object.keys(zip.files));

export const createTreeStructure = async (
  file: File
): Promise<{ name: string; nodeId: string; children?: unknown }[]> => {
  const contentArray = await getZipFilesContentAsArray(file);

  // To filter out __MACOSX and .DS_Store files and folders
  const filteredArray = contentArray.filter(
    (path) => !path.includes(".DS_Store") && !path.includes("__MACOSX")
  );

  return filteredArray.reduce((prevValue, currValue) => {
    currValue.split("/").reduce((prevFolder, currFolder, index) => {
      let nodesArray;
      if (index === 0) nodesArray = prevFolder;
      // @ts-ignore
      else nodesArray = prevFolder.children = prevFolder.children || [];

      let node =
        isArrayHasData(nodesArray) &&
        nodesArray.find(
          (n: Record<string, string>) => n.name === currFolder.trim()
        );

      if (!node && currFolder.trim()) {
        node = { name: currFolder.trim(), nodeId: getRandomId() };
        nodesArray.push(node);
      }

      return node;
    }, prevValue);

    return prevValue;
  }, []);
};

export const getZipFileDirectoryStructure = async (file: File) => {
  // todo: improve typing, reason string only exists when valid is false
  let result: { valid: boolean; reason?: string } = { valid: false };
  const contentArray = await getZipFilesContentAsArray(file);
  const contentTree = await createTreeStructure(file);

  const rootDirectories = contentTree.filter((item) => item.children);
  const rootDirectoryName =
    isArrayHasData(rootDirectories) && rootDirectories[0].name;
  const zipContainsMultipleDirectories = rootDirectories.length > 1;
  const foundValidFolderName = validFolderNames.some((folder) =>
    contentArray.includes(folder)
  );

  const requiredFileChecker = (requiredFilesArray: string[]) => {
    const directoryContainsRequiredFile = requiredFilesArray.some((file) =>
      contentArray.includes(file)
    );
    if (directoryContainsRequiredFile) result = { valid: true };
    else {
      result = {
        valid: false,
        reason: "Could not find deployment file / deployment file misplacement",
      };
    }
  };

  if (zipContainsMultipleDirectories) {
    if (contentArray.includes(`${rootDirectoryName}/deployment_package/`)) {
      result = {
        valid: false,
        reason: "deployment_package is not the root directory",
      };
    } else if (!foundValidFolderName) {
      result = {
        valid: false,
        reason: "Could not find deployment_package directory",
      };
    } else requiredFileChecker(requiredFilePaths);
  } else {
    const requiredFiles = [
      `${rootDirectoryName}/deployment.py`,
      `${rootDirectoryName}/deployment.R`,
      `${rootDirectoryName}/model.py`,
      "deployment.py",
      "deployment.R",
      "model.py",
    ];
    requiredFileChecker(requiredFiles);
  }

  return result;
};

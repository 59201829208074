import styled from "@emotion/styled";
import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

import { usePortal } from "libs/hooks";

import { Icon } from "components/atoms";

import { OperatorEditSidebar } from "./OperatorEditSidebar";
import { OperatorInfoSidebar } from "./OperatorInfoSidebar";
import { ActionButtons } from "../ActionButtons";
import { NodeDeleteDialog } from "../NodeDeleteDialog";
import { useSidebarRightState } from "../SidebarRight";
import {
  DIAGRAM_SIDEBAR_PARENT_ID,
  OPERATOR_RAISE_ERROR,
  operators,
  OPERATOR_COUNT_MANY,
} from "../constants";

import type { OperatorType } from "../constants";
import type { NodeDataType } from "../types";
import type { NodeProps } from "reactflow";

type NodeOperatorProps = NodeProps<NodeDataType>;

export const NodeOperator = ({ selected, id, data }: NodeOperatorProps) => {
  const [isEditSidebarOpen, setIsEditSidebarOpen] = useSidebarRightState(
    id + "-edit"
  );
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useSidebarRightState(
    id + "-info"
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const operator = operators.find(
    (operator) => operator.id === data.pipelineObject.reference_name
  ) as OperatorType;

  const operatorName = data.pipelineObject.name;
  const Portal = usePortal(document.getElementById(DIAGRAM_SIDEBAR_PARENT_ID));
  const hasEditForm = operator?.id !== OPERATOR_COUNT_MANY;

  useEffect(() => {
    if (data.new && hasEditForm) {
      setIsEditSidebarOpen(true);
    }
  }, [data.new, id, setIsEditSidebarOpen, hasEditForm]);

  const OperatorIcon = operator?.node;

  return (
    <>
      <NodeDeleteDialog
        id={id}
        pipelineObjectName={data?.pipelineObject?.name}
        pipelineObjectType="operator"
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
      <Portal>
        <OperatorInfoSidebar
          id={id}
          isOpen={isInfoSidebarOpen}
          operator={operator}
          onClose={() => setIsInfoSidebarOpen(false)}
          onClickDelete={() => setIsDeleteDialogOpen(true)}
        />
        <OperatorEditSidebar
          id={id}
          isOpen={isEditSidebarOpen}
          operator={operator}
          onClose={() => setIsEditSidebarOpen(false)}
        />
      </Portal>
      <Box
        display="flex"
        justifyContent="flex-end"
        height={35}
        marginBottom={1}
      >
        {selected && (
          <ActionButtons
            onDeleteClicked={() => setIsDeleteDialogOpen(true)}
            onInfoClicked={() => setIsInfoSidebarOpen(true)}
            onEditClicked={
              hasEditForm ? () => setIsEditSidebarOpen(true) : undefined
            }
          />
        )}
      </Box>
      <Tooltip
        title={<Typography variant="body1">{operatorName}</Typography>}
        placement="right"
      >
        <Box position="relative">
          <S_Handle type="target" position={Position.Left} />

          <Icon component={OperatorIcon} style={{ cursor: "move" }} />

          {operator?.id !== OPERATOR_RAISE_ERROR && (
            <S_Handle type="source" position={Position.Right} />
          )}
        </Box>
      </Tooltip>
    </>
  );
};

const S_Handle = styled(Handle)`
  &&& {
    background: transparent;
    z-index: 999;
    border: none;
    bottom: 0;
    top: 50%;
    left: ${(props) => (props.type === "source" ? "" : -10)};
    right: ${(props) => (props.type === "target" ? "" : 10)};
    width: 30%;
    height: 100%;
  }
`;

import BarChart from "@mui/icons-material/BarChart";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  FIELD_CREDIT_LIMIT,
  FIELD_CREDIT_LIMIT_ENABLED,
} from "libs/constants/fields";
import { PROJECT_PERMISSIONS } from "libs/constants/permissions";
import { useProjectUpdate } from "libs/data/customized/projects";
import { useOrganizationUser } from "libs/data/customized/user";
import { useProjectsUsageGet } from "libs/data/endpoints/projects/projects";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { getCompute } from "libs/utilities/metrics-helper";
import {
  useGetCurrentProject,
  useGetOrganizationFeatures,
} from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  Loader,
  Alert,
  PrimaryButton,
  InfoTooltip,
  Dialog,
} from "components/atoms";
import {
  ComputeUsageProgressBar,
  ProjectComputeLimitSliderBar,
} from "components/molecules";

type ProjectUsageProps = {
  showChangeProjectLimitsButton?: boolean;
};

const ProjectUsage = ({ showChangeProjectLimitsButton }: ProjectUsageProps) => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { isAdmin } = useOrganizationUser(organizationName);

  const computeFormMethods = useForm({
    mode: "onBlur",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentProjectMaxUsage, setCurrentProjectMaxUsage] =
    useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { handleSubmit } = computeFormMethods;

  const currentProject = useGetCurrentProject();
  const organizationFeatures = useGetOrganizationFeatures();

  const projectUpdate = useProjectUpdate(organizationName);

  const isMetricAggregationEnabled = env.get(
    ENV_NAMES.METRICS_AGGREGATION_ENABLED
  );
  const isOnPrem = env.get(ENV_NAMES.ON_PREMISE);
  const hasUnlimitedUsage = currentProjectMaxUsage === null;
  const [permissions, loadingPermissions] = useGetPermissions();
  const noMetricsPermissions =
    permissions?.[PROJECT_PERMISSIONS.metrics_get] === false;

  const { data: projectUsage, error } = useProjectsUsageGet(
    projectName,
    { interval: "day" },
    { swr: { enabled: env.get(ENV_NAMES.METRICS_AGGREGATION_ENABLED) } }
  );
  const projectUsageLoading = !projectUsage && !error;

  useEffect(() => {
    if (currentProject?.credits) {
      setCurrentProjectMaxUsage(currentProject?.credits);
    } else {
      setCurrentProjectMaxUsage(organizationFeatures?.max_credits as number);
    }
  }, [currentProject?.credits, organizationFeatures?.max_credits]);

  useEffect(() => {
    setIsLoaded(
      !projectUsageLoading &&
        (isMetricAggregationEnabled || isOnPrem || hasUnlimitedUsage)
    );
  }, [
    hasUnlimitedUsage,
    isMetricAggregationEnabled,
    isOnPrem,
    projectUsageLoading,
  ]);

  const onComputeLimitUpdate = async (data: any) => {
    if (projectName) {
      await projectUpdate(projectName, {
        [FIELD_CREDIT_LIMIT]: data[FIELD_CREDIT_LIMIT_ENABLED]
          ? data[FIELD_CREDIT_LIMIT]
          : null,
      });
      setDialogOpen(false);
    }
  };

  const showAlert =
    (error || !isAdmin || noMetricsPermissions) && !loadingPermissions;

  // Only org admin can see the subscription related details. For project usage, we also display subscription start and end date. So non org admin shouldn't see this.
  return showAlert ? (
    <Alert severity="warning">
      You currently don&apos;t have permission to see the project usage.
    </Alert>
  ) : !isLoaded || loadingPermissions ? (
    <Loader />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ComputeUsageProgressBar
          usage={projectUsage && getCompute(projectUsage?.data_project ?? [])}
          maxUsage={currentProjectMaxUsage || 0}
        />

        {showChangeProjectLimitsButton && (
          <Box display={"flex"} alignItems={"center"}>
            <PrimaryButton
              onClick={() => setDialogOpen(!dialogOpen)}
              startIcon={<BarChart />}
              size="small"
            >
              Set project limits
            </PrimaryButton>
            <InfoTooltip>
              You can set a limit on the compute resources of a project (GB
              hours). This is calculated by multiplying the deployment memory
              sizes in GB by the number of hours they are running.
            </InfoTooltip>
          </Box>
        )}
        {dialogOpen && (
          <FormProvider {...computeFormMethods}>
            <Dialog
              Actions={
                <PrimaryButton
                  onClick={handleSubmit(onComputeLimitUpdate)}
                  disabled={!projectName}
                >
                  Update
                </PrimaryButton>
              }
              onClose={() => setDialogOpen(false)}
              open={dialogOpen}
              title="Changing credit limit"
            >
              {!!projectName && (
                <Box>
                  <ProjectComputeLimitSliderBar />
                </Box>
              )}
            </Dialog>
          </FormProvider>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectUsage;

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type EnvironmentBuildListStatus =
  typeof EnvironmentBuildListStatus[keyof typeof EnvironmentBuildListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvironmentBuildListStatus = {
  queued: "queued",
  building: "building",
  success: "success",
  failed: "failed",
  cancelled: "cancelled",
} as const;

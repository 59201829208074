// @ts-ignore
import id from "uuid/v1";

import { DOUBLE_TYPE, INT_TYPE, STRING_TYPE } from "libs/constants/constants";
import { validateJSON } from "libs/utilities/validators";
// Generate a version name according to the name of the latest version
// or return the name of the latest version
export const generateVersionName = (latestName: string) => {
  const versionNameNumber = latestName.match(/\d+$/);
  // If the end of the version name is not a number, this is not an array
  if (!Array.isArray(versionNameNumber)) {
    return latestName + 2;
  }

  const prefix = latestName.replace(/\d+$/, "");
  // The first item in the array is the number at the end of the version name
  const number = parseInt(versionNameNumber[0]) + 1;

  return prefix + number;
};

export const formatLabel = (label: string) => {
  if (!label) {
    return "";
  }

  return label.charAt(0).toUpperCase() + label.slice(1).replace(/_/gi, " ");
};

export const capitalize = (text: string) =>
  text[0].toUpperCase() + text.substring(1);

export const createId = () => Math.random().toString(36).substr(2, 5);

export const isObjectHasData = (obj: Record<string, unknown>) =>
  !!Object.keys(obj || {}).length;

export const isArrayHasData = (arr?: unknown[]) =>
  Array.isArray(arr) && !!arr.length;
export const isArrayHasNoData = (arr: unknown[]) =>
  Array.isArray(arr) && arr.length === 0;

export const convertValueStringToCorrectDataType = (
  value: any,
  dataType: string
) => {
  if (dataType === INT_TYPE && !isNaN(Number(value))) {
    return Number(value);
  } else if (dataType === DOUBLE_TYPE) {
    return parseFloat(value);
  } else if (dataType === STRING_TYPE) {
    return value;
  } else {
    return validateJSON(value) ? JSON.parse(value) : value;
  }
};

export const getRandomId = () => {
  return id();
};

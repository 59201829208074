import { getTzAwareDate } from "./date-util";

export const toUpperFirstCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lastUpdated = (arr: { last_updated?: string; name: string }[]) => {
  return arr?.sort((a, b) =>
    getTzAwareDate(b?.last_updated || "").isAfter(
      getTzAwareDate(a?.last_updated || "")
    )
      ? 1
      : -1
  )?.[0].name;
};

export const isInRange = (
  value: number | null | undefined,
  min: number,
  max: number
) => {
  const num = Number(value);

  return !isNaN(num) && isFinite(num) && num >= min && num <= max;
};

export const convertSeconds = (seconds: number) => {
  const secondsInAnHour = 60 * 60;
  const secondsInADay = 24 * secondsInAnHour;
  const averageDaysInAMonth = 30.44;
  const secondsInAMonth = secondsInADay * averageDaysInAMonth;

  if (seconds < secondsInAnHour) {
    return seconds + " Seconds";
  } else if (seconds < secondsInADay) {
    const hours = Math.round(seconds / secondsInAnHour);

    return hours + " Hours";
  } else if (seconds < secondsInAMonth) {
    const days = Math.round(seconds / secondsInADay);

    return days + " Days";
  } else {
    const months = Math.round(seconds / secondsInAMonth);

    return months + " Months";
  }
};

import { Box } from "@mui/material";

import { ListItem } from "components/atoms/List/ListItem";
import { ListItemKey } from "components/atoms/List/ListItemKey";
import { ListItemValue } from "components/atoms/List/ListItemValue";
import { DATE_FORMAT, getTzAwareDate } from "libs/utilities/date-util";

type DialogDetailsProps = {
  name: string;
  entity: {
    name: string;
    size?: string | null | undefined;
    creation_time: string;
  };
};

export const DialogDetails = ({ entity, name }: DialogDetailsProps) => {
  return (
    <Box flexDirection="column" paddingTop={2}>
      <ListItem borderTop={0}>
        <ListItemKey>{name} name</ListItemKey>
        <ListItemValue>{entity.name}</ListItemValue>
      </ListItem>
      {entity.size && (
        <ListItem>
          <ListItemKey>{name} size</ListItemKey>
          <ListItemValue>{entity.size}</ListItemValue>
        </ListItem>
      )}
      <ListItem>
        <ListItemKey>Created</ListItemKey>
        <ListItemValue>
          {getTzAwareDate(entity.creation_time).format(DATE_FORMAT)}
        </ListItemValue>
      </ListItem>
    </Box>
  );
};

import { useCallback } from "react";

import {
  batchDeploymentRequestsCreate,
  batchDeploymentVersionRequestsCreate,
} from "libs/data/endpoints/deployment-requests/deployment-requests";

import type { DeploymentRequestsCreateDataBody } from "libs/data/models";

export const useDeploymentBatchRequestsCreate = (
  projectName?: string,
  deploymentName?: string,
  version?: string | null,
  timeout?: number
) => {
  const deploymentBatchRequest = useCallback(
    (
      data: Array<DeploymentRequestsCreateDataBody>,
      onRequestHasBeenMade?: () => void
    ) => {
      if (!projectName || !deploymentName) return Promise.reject();

      const promise = version
        ? batchDeploymentVersionRequestsCreate(
            projectName,
            deploymentName,
            version,
            data,
            {
              timeout,
            }
          )
        : batchDeploymentRequestsCreate(projectName, deploymentName, data, {
            timeout,
          });

      onRequestHasBeenMade?.();

      return promise;
    },
    [deploymentName, projectName, timeout, version]
  );

  return deploymentBatchRequest;
};

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { activateAccount } from "libs/data/endpoints/user/user";
import { setError } from "store/features";

import type { AxiosError } from "axios";

export const useActivateUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const activateUser = async (token: string) => {
    try {
      setIsLoading(true);
      !isLoading && (await activateAccount(token, {}));
      setIsLoading(false);
      history.push("/sign-in", { userActivated: true });
    } catch (e) {
      setIsLoading(false);
      const error = e as AxiosError;
      dispatch(setError(error?.message));
      history.push("/sign-in");
    }
  };

  return { activateUser };
};

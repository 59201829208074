import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  roleAssignmentsCreate,
  roleAssignmentsDelete,
  useRoleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { RoleAssignmentsPerObjectListResourceType } from "libs/data/models";

export const useRoleAssignmentUpdate = (
  projectName: string,
  resource?: string,
  resourceType?: RoleAssignmentsPerObjectListResourceType
) => {
  const dispatch = useDispatch();

  const { mutate: mutateBucketRoleAssignment } =
    useRoleAssignmentsPerObjectList(
      projectName,
      {
        resource,
        resource_type: resourceType,
      },
      { swr: { enabled: !!resource } }
    );

  return useCallback(
    async (roleId: any, newRole: any) => {
      if (!projectName) {
        return Promise.reject();
      }

      // We don't have PATCH endpoint, so we need to delete the assigned role of the user, before creating one again.
      return roleAssignmentsDelete(projectName, roleId)
        .then(async (result) => {
          await roleAssignmentsCreate(projectName, newRole).then(async () => {
            dispatch(
              createSuccessNotification("The role assignment was updated")
            );

            await mutateBucketRoleAssignment();

            return;
          });

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, dispatch, mutateBucketRoleAssignment]
  );
};

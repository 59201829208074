import { useEffect, useMemo } from "react";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";

import { formatCodeEnvironment } from "./CodeEnvironmentSelect";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

interface ControlledEnvironmentSelectProps {
  disabled?: boolean;
  gpuEnabled: boolean;
  onChange: (environment: string) => void;
  projectName: string;
  value?: string;
}

export const ControlledEnvironmentSelect = ({
  disabled,
  onChange,
  value,
  projectName,
  gpuEnabled,
}: ControlledEnvironmentSelectProps) => {
  const { data: availableEnvironments } = useEnvironmentsList(projectName);

  const environmentOptions = useMemo(() => {
    const filteredEnvironments =
      (gpuEnabled
        ? availableEnvironments
        : availableEnvironments?.filter((env) => !env.gpu_required)) ?? [];

    return filteredEnvironments
      .filter((env) => !env.base_environment)
      .filter(
        (env) => env.status !== "unavailable" && !env.deprecated && !env.hidden
      )
      .filter((env) => !env.implicit)
      .map(formatCodeEnvironment)
      .sort((a, b) => {
        return b.label.localeCompare(a.label, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
  }, [availableEnvironments, gpuEnabled]);

  useEffect(() => {
    if (environmentOptions.length && !value)
      onChange(environmentOptions[0].value as string);
  }, [environmentOptions, onChange, value]);

  const handleChange = (option: AutocompleteSelectOption | null) => {
    if (option?.value && option.value !== selected?.value)
      onChange(option.value as string);
  };

  const selected = useMemo(
    () =>
      environmentOptions.find((option) => option.value === value) ??
      environmentOptions[0] ?? { label: "", value: "" },
    [environmentOptions, value]
  );

  return (
    <AutoCompleteSelect
      label="Environment"
      value={selected}
      options={environmentOptions}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  Redirect,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import {
  usePipelinesGet,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";
import { routes } from "routes";

import { Route } from "components/utilities";

import PipelineVersionDetails from "./PipelineVersionDetails";
import { PipelineVersionDuplicate } from "./PipelineVersionDuplicate";
import { PipelineVersionUpdate } from "./PipelineVersionUpdate";
import { PipelineVersionGeneral } from "./general";
import PipelineVersionMetrics from "./metrics/PipelineVersionMetrics";
import PipelineVersionRequests from "./requests/PipelineVersionRequests";
import PipelineVersionBatchRequestCreate from "./requests/batch/create/PipelineVersionBatchRequestCreate";

import type { PipelineVersionRouteParams } from "./types";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .pipelines[":pipelineName"](":pipelineName")
  .versions[":versionName"](":versionName");

export const PipelineVersion = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const { error: pipelineError } = usePipelinesGet(projectName, pipelineName);
  const { error: pipelineVersionError } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  useEffect(() => {
    if (pipelineError || pipelineVersionError) {
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines.index()
      );
    }
  }, [
    history,
    organizationName,
    pipelineError,
    pipelineVersionError,
    projectName,
  ]);

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .pipelines[":pipelineName"](pipelineName)
    .versions[":versionName"](versionName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{versionName}</BreadcrumbsItem>

      <Switch>
        <Route
          exact
          path={basePath.edit.index()}
          component={PipelineVersionUpdate}
        />
        <Route
          exact
          path={basePath.duplicate.index()}
          component={PipelineVersionDuplicate}
        />

        <PipelineVersionDetails>
          <Switch>
            <Route
              path={basePath.general.index()}
              component={PipelineVersionGeneral}
            />
            <Route
              exact
              path={basePath.requests.index()}
              component={PipelineVersionRequests}
            />
            <Route
              exact
              path={basePath.requests.batch.create.index()}
              component={PipelineVersionBatchRequestCreate}
            />
            <Route
              exact
              path={basePath.metrics.index()}
              component={PipelineVersionMetrics}
            />
            <Redirect to={baseUrl.general.index()} />
          </Switch>
        </PipelineVersionDetails>
        <Redirect to={baseUrl.general.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import styled from "@emotion/styled";
import { Chip as MuiChip, Typography } from "@mui/material";
import { readableColor } from "polished";

import { ReactComponent as LabelCloseIcon } from "assets/images/label-close-icon.svg";
import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";
import { capitalize } from "libs/utilities/utils";

import type { CSSProperties, SyntheticEvent } from "react";

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ backgroundColor: string }>`
  color: ${({ backgroundColor }) => readableColor(backgroundColor ?? "")};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0px 5px;
  &.MuiChip-clickable {
    &:hover,
    &:focus {
      background-color: ${({ backgroundColor }) => backgroundColor};
    }
  }
  &.MuiChip-deletable {
    &:hover,
    &:focus {
      background-color: ${({ backgroundColor }) => backgroundColor};
    }
  }
`;

type LabelChipProps = {
  label: string;
  value?: string | null;
  onDelete?: () => void;
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
  style?: CSSProperties;
  capital?: boolean;
};

const LabelChip = ({
  label,
  value,
  onDelete,
  onClick,
  className,
  capital = true,
  ...props
}: LabelChipProps) => {
  return (
    <Chip
      size="small"
      backgroundColor={useStringToColor(label + value)}
      label={
        <Typography
          variant="body2"
          component="div"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <Typography display="inline" variant="subtitle1" component="span">
            <strong>
              {capital ? capitalize(label) : label}
              {value === undefined ? "" : ":"}
            </strong>{" "}
          </Typography>
          {value}
        </Typography>
      }
      deleteIcon={<LabelCloseIcon style={{ width: "12px" }} />}
      onDelete={onDelete}
      onClick={onClick}
      className={className}
      {...props}
    />
  );
};

export default LabelChip;

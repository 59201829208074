import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";

import { Dialog, DialogDescription, PrimaryButton } from "components/atoms";
import { ControlledCheckbox } from "components/molecules";

export interface ColumnSelectionDialogProps {
  availableColumns: string[];
  onChange: (columns: string[]) => void;
  onClose: () => void;
  open: boolean;
  selectedColumns: string[];
}

export const ColumnSelectionDialog = ({
  availableColumns,
  onChange,
  onClose,
  open,
  selectedColumns,
}: ColumnSelectionDialogProps) => {
  const [value, setValue] = useState(selectedColumns);

  useEffect(() => {
    if (!open) {
      setValue(selectedColumns);
    }
  }, [open, selectedColumns]);

  const handleOnClose = () => {
    onClose();
    setValue(selectedColumns);
  };

  const handleOnSubmit = () => {
    onChange(value);
    env.set(ENV_NAMES.EVALUATION_TABLE_COLUMNS, JSON.stringify(value));
    handleOnClose();
  };

  const toggleColumnOnTable = (column: string) => {
    setValue((previous) => {
      return previous.includes(column)
        ? previous.filter((c) => c !== column)
        : [...previous, column];
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      title="Edit table columns"
      Actions={
        <PrimaryButton onClick={handleOnSubmit}>Apply changes</PrimaryButton>
      }
    >
      <DialogDescription>
        The following columns are available for the selected training runs.
      </DialogDescription>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {availableColumns.map((column) => (
            <ControlledCheckbox
              key={column}
              label={column}
              onChange={() => toggleColumnOnTable(column)}
              value={value.includes(column)}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {/* Todo: insert list of selected columns here */}
        </Grid>
      </Grid>
    </Dialog>
  );
};

import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import type { RadioGroupProps } from "@mui/material";

export interface RadioGroupOptions<T> {
  label: string;
  value: T;
}

export type ControlledRadioGroupProps = Omit<
  RadioGroupProps,
  "error" | "onChange"
> & {
  error?: string | false;
  label?: string;
  onChange?: (value: unknown) => void;
  options: RadioGroupOptions<unknown>[];
  showErrors?: boolean;
  value?: unknown;
};

export const ControlledRadioGroup = ({
  error,
  onChange,
  options = [],
  showErrors,
  value,
}: ControlledRadioGroupProps) => {
  return (
    <RadioGroup
      onChange={(e) => onChange && onChange(e.target.value)}
      value={value}
    >
      {!!options &&
        options.map((option: RadioGroupOptions<unknown>, i: number) => (
          <FormControlLabel
            control={<Radio color="secondary" />}
            key={i}
            label={option.label}
            value={option.value}
          />
        ))}
      {showErrors && error && (
        <FormHelperText error={!!error} hidden={!error}>
          {error}
        </FormHelperText>
      )}
    </RadioGroup>
  );
};

import { useCallback } from "react";

import {
  pipelinesUpdate,
  usePipelinesGet,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";

import type { PipelineUpdate } from "libs/data/models";

export const usePipelineUpdate = (
  projectName: string,
  pipelineName: string
) => {
  const { mutate: mutatePipeline } = usePipelinesGet(projectName, pipelineName);
  const { mutate: mutatePipelineList } = usePipelinesList(projectName);

  return useCallback(
    async (data: PipelineUpdate) => {
      if (!pipelineName || !projectName) {
        return Promise.reject({
          message:
            "Couldn't make the API call because projectName and/or pipelineName are not available",
        });
      }

      return pipelinesUpdate(projectName, pipelineName, data).then(
        async (result) => {
          await mutatePipeline();
          await mutatePipelineList();

          return result;
        }
      );
    },
    [mutatePipeline, mutatePipelineList, pipelineName, projectName]
  );
};

export const defaultRolesOptions = [
  {
    value: "project-admin",
    label: "Project admin",
  },
  {
    value: "project-viewer",
    label: "Project viewer",
  },
  {
    value: "project-editor",
    label: "Project editor",
  },
];

import { useTheme } from "@mui/material";

export const OperatorCollectSubRequests = () => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2440_24735)">
        <circle
          cx="40.3902"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
      </g>
      <path
        d="M13.7139 26.7544H44.6927V45.0601H13.7139"
        stroke="white"
        strokeWidth="3"
      />
      <path d="M33.1309 26.7544V45.7641" stroke="white" strokeWidth="3" />
      <path d="M23.2744 27.4585V44.356" stroke="white" strokeWidth="3" />
      <path
        d="M66.7778 36.7778C67.2074 36.3482 67.2074 35.6518 66.7778 35.2222L59.7775 28.2218C59.3479 27.7922 58.6514 27.7922 58.2218 28.2218C57.7922 28.6514 57.7922 29.3479 58.2218 29.7775L64.4444 36L58.2218 42.2225C57.7922 42.6521 57.7922 43.3486 58.2218 43.7782C58.6514 44.2078 59.3479 44.2078 59.7775 43.7782L66.7778 36.7778ZM51 37.1L66 37.1V34.9L51 34.9V37.1Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_2440_24735"
          x="0"
          y="0"
          width="79.1104"
          height="79.1104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2440_24735"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2440_24735"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

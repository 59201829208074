import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { bucketsCreate, useBucketsList } from "libs/data/endpoints/files/files";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { BucketCreate } from "libs/data/models";

export const useBucketCreate = (projectName: string) => {
  const { mutate } = useBucketsList(projectName);
  const dispatch = useDispatch();

  return useCallback(
    async (data: BucketCreate) => {
      try {
        const result = await bucketsCreate(projectName, data);
        await mutate();
        dispatch(createSuccessNotification(`The bucket was created`));

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, mutate, projectName]
  );
};

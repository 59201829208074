// General
export const FIELD_EMAIL = "email";
export const FIELD_PHONE = "phone";
export const FIELD_ASSIGNEE = "assignee";
export const FIELD_ROLE = "role";
export const FIELD_NAME = "name";
export const FIELD_PERMISSIONS = "permissions";
export const FIELD_ADVANCED_PERMISSIONS = "advanced_permissions";
export const FIELD_CREDIT_LIMIT = "credits";
export const FIELD_CREDIT_LIMIT_ENABLED = "credits_limit_enabled";
export const FIELD_CREDIT_LIMIT_VALUE = "credits_limit_value";
export const FIELD_EXPIRY_DATE = "expiry_date";
export const FIELD_TYPE = "type";

// Account
export const FIELD_SURNAME = "surname";
export const FIELD_PASSWORD = "password";
export const FIELD_NEWSLETTER = "newsletter";
export const FIELD_REMEMBER_ME = "remember_me";
export const FIELD_TERMS_CONDITIONS = "terms_conditions";
export const FIELD_ALLOWED_CORS_ORIGINS = "allowed_cors_origins";

// Attachment
export const FIELD_SOURCE_NAMES = "sources";
export const FIELD_DESTINATION_NAME = "destination_name";
export const FIELD_MAPPING = "mapping";

// Deployment and Pipeline
export const FIELD_DESCRIPTION = "description";
export const FIELD_VERSION_DESCRIPTION = "version_description";
export const FIELD_LABELS = "labels";
export const FIELD_VERSION_LABELS = "version_labels";
export const FIELD_ADMIN = "admin";
export const FIELD_ORGANIZATION_NAME = "organization_name";
export const FIELD_PROJECT_NAME = "projectName";
export const FIELD_VERSION = "version";
export const FIELD_INPUT_TYPE = "input_type";
export const FIELD_INPUT_FIELDS = "input_fields";
export const FIELD_OUTPUT_TYPE = "output_type";
export const FIELD_OUTPUT_FIELDS = "output_fields";
export const FIELD_DEFAULT_VERSION = "default_version";
export const FIELD_RETENTION_MODE = "request_retention_mode";
export const FIELD_RETENTION_TIME = "request_retention_time";
export const FIELD_PACKAGE = "package";
export const FIELD_EXISTING_PACKAGE = "existing";
export const FIELD_NEW_PACKAGE = "new";
export const FIELD_SOURCE_DEPLOYMENT = "source_deployment";
export const FIELD_SOURCE_VERSION = "source_version";
export const FIELD_DEPLOYMENT = "deployment";
export const FIELD_COPY_ENV_VARS = "copyEnvVars";
export const FIELD_GPU_INSTANCE_ENABLED = "accelerator";
export const FIELD_REFERENCE_NAME = "reference_name";
export const FIELD_ERROR_POLICY = "error_policy";
export const FIELD_REQUEST = "request";

// Widgets
export const FIELD_INPUT_WIDGETS = "inputWidgets";
export const FIELD_OUTPUT_WIDGETS = "outputWidgets";

// Deployment version
export const FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY = "minimum_instances";
export const FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY = "maximum_instances";
export const FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT = "deployment_port";
export const FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT = "public_port";
export const FIELD_DEPLOYMENT_VERSION_PROTOCOL = "protocol";
export const FIELD_DEPLOYMENT_VERSION_IDLE_TIME = "maximum_idle_time";
export const FIELD_DEPLOYMENT_VERSION_LANGUAGE = "language";
export const FIELD_DEPLOYMENT_VERSION_ENVIRONMENT = "environment";
export const FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT = "base_environment";
export const FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE = "instance_type_group_id";
export const FIELD_DEPLOYMENT_VERSION_FILE = "version_file";
export const FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS =
  "version_file_has_requirements";
export const FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS = "maximum_queue_size_express";
export const FIELD_MAXIMUM_QUEUE_SIZE_BATCH = "maximum_queue_size_batch";
export const FIELD_STATIC_IP = "static_ip";
export const FIELD_SCALING_STRATEGY = "scaling_strategy";

// Sign-up
export const FIELD_INDUSTRY = "industry";
export const FIELD_SIGNUP_REASON = "signup_reason";
export const FIELD_PROJECT = "project";
export const FIELD_ORGANIZATION = "organization";
export const FIELD_VOUCHER = "voucher";

export const START_DATE_FILTER = "start_date";
export const END_DATE_FILTER = "end_date";

// Import & Export
export const FIELD_IMPORTED_ZIP_FILE = "imported_zipfile";
export const IMPORTS_STATUS_FILTER = [
  { title: "all" },
  { title: "completed" },
  { title: "failed" },
  { title: "confirmation" },
];
export const EXPORTS_STATUS_FILTER = [
  { title: "all" },
  { title: "completed" },
  { title: "failed" },
];
export const FIELD_IMPORT_LINK = "import_link";

// Request schedule
export const FIELD_OBJECT_TYPE = "object_type";
export const FIELD_OBJECT_NAME = "object_name";
export const FIELD_SCHEDULE = "schedule";
export const FIELD_REQUEST_DATA = "request_data";
export const FIELD_TYPE_BATCH = "batch";
export const FIELD_TIMEOUT = "timeout";

// Logging
export const FIELD_OPTION = "option";
export const FIELD_VALUE_DROPDOWN = "value-dropdown";
export const FIELD_VALUE_TEXT = "value-text";
export const FIELD_DATE_RANGE = "date_range";
export const FIELD_DATE = "date";
export const FIELD_DATES = "dates";

// Audit
export const FIELD_FILTER = "filter";

// Labels
export const FIELD_LABEL_VALUE = "value";
export const FIELD_LABEL_KEY = "key";

// Monitoring
export const FIELD_DEPLOYMENT_ERROR = "FIELD_DEPLOYMENT_ERROR";
export const FIELD_PIPELINE_ERROR = "FIELD_PIPELINE_ERROR";
export const FIELD_AGGREGATED = "aggregated";
export const FIELD_USER = "user";

// WebHooks
export const FIELD_WEBHOOK_NAME = "name";
export const FIELD_WEBHOOK_DESCRIPTION = "description";
export const FIELD_WEBHOOK_OBJECT_SELECTION = "object_type";
export const FIELD_WEBHOOK_OBJECT_NAME = "object_name";
export const FIELD_WEBHOOK_OBJECT_VERSION = "version";
export const FIELD_WEBHOOK_CALLBACKURL = "url";
export const FIELD_WEBHOOK_HEADER = "headers";
export const FIELD_WEBHOOK_EVENT = "event";
export const FIELD_WEBHOOK_LABELS = "labels";
export const FIELD_WEBHOOK_TIMEOUT = "timeout";
export const FIELD_WEBHOOK_RETRY = "retry";
export const FIELD_WEBHOOK_INCLUDE_RESULT = "include_result";

// Operators
export const FIELD_EXPRESSION = "expression";
export const FIELD_ERROR_MESSAGE = "error_message";

// Buckets
export const FIELD_PROVIDER = "provider";
export const FIELD_ACCESS_KEY = "access_key";
export const FIELD_SECRET_KEY = "secret_key";
export const FIELD_CONNECTION_STRING = "connection_string";
export const FIELD_JSON_KEY = "json_key_file";
export const FIELD_REGION = "region";
export const FIELD_MINIO_CONFIG = "minio_config";
export const FIELD_ENDPOINT_URL = "endpoint_url";
export const FIELD_CONTAINER = "container";
export const FIELD_BUCKET = "bucket";
export const FIELD_PREFIX = "prefix";
export const FIELD_TTL = "ttl";
export const FIELD_SIGNATURE_VERSION = "signature_version";
export const FIELD_VERIFY = "verify";
export const FIELD_USE_SSL = "use_ssl";
export const FIELD_CREDENTIALS = "credentials";
export const FIELD_CONFIGURATION = "configuration";

// Training
export const FIELD_TRAINING_SCRIPT = "training_code";
export const FIELD_TRAINING_DATA = "training_data";
export const FIELD_PARAMETERS = "parameters";

// Environments
export const FIELD_REQUIREMENTS_FILE = "requirements_file";

// Metrics
export const FIELD_METRIC_TYPE = "metric_type";
export const FIELD_UNIT = "unit";

// Instance type group
export const FIELD_INSTANCE_TYPES = "instance_types";

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentVersionsCreate,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { DeploymentVersionCreate } from "libs/data/models";

export const useDeploymentVersionsCreate = (
  projectName: string,
  deploymentName: string,
  versionType: "deployment version" | "experiment" = "deployment version"
) => {
  const dispatch = useDispatch();
  const { mutate: deploymentVersionsMutate } = useDeploymentVersionsList(
    projectName,
    deploymentName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      },
    }
  );

  return useCallback(
    async (data: DeploymentVersionCreate) => {
      try {
        const response = await deploymentVersionsCreate(
          projectName,
          deploymentName,
          data
        );
        dispatch(
          createSuccessNotification(`The ${versionType} has been created`)
        );
        await deploymentVersionsMutate();

        return response;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [
      deploymentName,
      deploymentVersionsMutate,
      dispatch,
      projectName,
      versionType,
    ]
  );
};

import CloudQueueIcon from "@mui/icons-material/CloudQueue";

import { ReactComponent as AWS } from "assets/images/aws.svg";
import { ReactComponent as Azure } from "assets/images/bucket-logo-azure.svg";
import { ReactComponent as GCS } from "assets/images/bucket-logo-gcs.svg";
import { ReactComponent as Kubernetes } from "assets/images/kubernetes.svg";

export const getCursor = (url: string) => {
  return Object.fromEntries(new URLSearchParams(url.split("?")[1]));
};

export const getInstanceEnvironmentIcon = (provider?: string) => {
  switch (provider) {
    case "aws":
      return AWS;
    case "azure":
      return Azure;
    case "gcp":
      return GCS;
    case "kubernetes":
      return Kubernetes;
    default:
      return CloudQueueIcon;
  }
};

import { Grid, Typography } from "@mui/material";

type Props = {
  title: string;
  children?: React.ReactNode;
  isLoaded?: boolean;
  fullWidth?: boolean;
};

const DetailsDialogItem = ({
  title,
  children,
  isLoaded = true,
  fullWidth = false,
}: Props) => (
  <>
    {isLoaded && (
      <Grid container spacing={2}>
        <Grid item xs={fullWidth ? 12 : 5} container alignItems="center">
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid
          item
          xs={fullWidth ? 12 : 7}
          style={fullWidth ? { paddingTop: 0, paddingBottom: 0 } : {}}
        >
          {children}
        </Grid>
      </Grid>
    )}
  </>
);

export default DetailsDialogItem;

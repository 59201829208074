import { Box, Grid, Typography } from "@mui/material";

import { IlluAfterSignUp as AfterSignupIllustration } from "assets/images/IlluAfterSignup";
import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_ROLE,
  FIELD_INDUSTRY,
  FIELD_SIGNUP_REASON,
} from "libs/constants/fields";
import { roles, industries, reasons } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

const rules = { required: validators.required.message("field") };

const NewAccountQuestionsForm = () => {
  return (
    <form>
      <Box maxWidth="100%" height="140px" mb={5} mt={3}>
        <AfterSignupIllustration />
      </Box>
      <Typography variant="body1" mb={3}>
        Tell us a bit more about yourself.
      </Typography>
      <Box
        display="flex"
        flexWrap={"wrap"}
        textAlign={"center"}
        justifyContent="space-between"
        alignItems={"center"}
        gap={spacing[8]}
      >
        <Typography variant="body1">{`I'm a`}</Typography>
        <Grid item container xs={12} md={5}>
          <AutoCompleteSelectHookForm
            name={FIELD_ROLE}
            options={roles}
            placeholder="Select..."
            rules={rules}
          />
        </Grid>
        <Typography variant="body1">working in</Typography>
        <Grid item container xs={12} md={4}>
          <AutoCompleteSelectHookForm
            name={FIELD_INDUSTRY}
            options={industries}
            placeholder="Select..."
            rules={rules}
          />
        </Grid>
        <Typography variant="body1">I want to use UbiOps to</Typography>{" "}
        <Grid item container xs={12} md>
          <AutoCompleteSelectHookForm
            name={FIELD_SIGNUP_REASON}
            options={reasons}
            placeholder="Select..."
            rules={rules}
          />
        </Grid>
      </Box>
    </form>
  );
};

export default NewAccountQuestionsForm;

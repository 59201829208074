import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { requestSchedulesCreate } from "libs/data/endpoints/request-schedules/request-schedules";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { ScheduleCreate } from "libs/data/models";

export const useRequestScheduleCreate = (projectName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (data: ScheduleCreate) => {
      try {
        const result = await requestSchedulesCreate(projectName, data);
        dispatch(createSuccessNotification("Request schedule was created."));

        return result;
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [dispatch, projectName]
  );
};

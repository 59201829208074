import AxiosStaticInstance from "axios";

import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { store } from "store/store";

import { errorHandler } from "./error-handler";
import { axiosRequestInterceptorRefreshAccessTokenBeforeItExpires as requestInterceptor } from "./request-middleware";
import { axiosResponseInterceptorErrorHandler as responseInterceptor } from "./response-middleware";

import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

type ApiError<Body = unknown> = {
  url: string;
  status: number;
  statusText: string;
  body: Body;
};
export type AxiosErrorType = AxiosError<ApiError<Error>>;
export type TError<Error = unknown> = ApiError<Error> & {
  status: number | string;
  message: string;
};

export const Axios = AxiosStaticInstance;

const createAxiosInstance = () => {
  const axiosInstance = Axios.create();

  axiosInstance.defaults.baseURL = env.get(ENV_NAMES.HOST);
  axiosInstance.defaults.timeout = 30000;
  //@ts-ignore
  axiosInstance.interceptors.request.use(requestInterceptor, undefined);
  axiosInstance.interceptors.response.use(undefined, async (error) =>
    responseInterceptor(error).catch((e) => {
      errorHandler(store)(e);

      throw e;
    })
  );

  return axiosInstance;
};
const axiosInstance = createAxiosInstance();

export const axios = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const source = Axios.CancelToken.source();

  const promise = axiosInstance({
    ...config,
    ...options,
    cancelToken: source.token,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export const orvalAxios = <T = unknown>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
) => axios<T>(config, options).then(({ data }) => data);

import { useTheme } from "@mui/material";

export const BucketIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0596 4.05706C10.6009 3.74412 11.3034 3.91176 11.6258 4.47059C11.9483 4.99588 11.7525 5.68882 11.2113 5.99059L1.73344 11.2994C1.18066 11.6124 0.478177 11.4335 0.155724 10.8971C-0.166729 10.3606 0.0290459 9.67882 0.581822 9.36588L10.0596 4.05706ZM4.87736 19L3.4839 12.2606L12.0289 7.47706C12.9387 6.99647 13.5145 6.08 13.5145 5.02941C13.5145 3.48706 12.2247 2.23529 10.6354 2.23529C10.0942 2.23529 9.58747 2.38059 9.14986 2.63765L2.29773 6.47118L1.4225 2.23529H0.270886V0H21V2.23529H19.8484L16.3935 19H4.87736Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M10.0596 4.05706C10.6009 3.74412 11.3034 3.91176 11.6258 4.47059C11.9483 4.99588 11.7525 5.68882 11.2113 5.99059L1.73344 11.2994C1.18066 11.6124 0.478177 11.4335 0.155724 10.8971C-0.166729 10.3606 0.0290459 9.67882 0.581822 9.36588L10.0596 4.05706ZM4.87736 19L3.4839 12.2606L12.0289 7.47706C12.9387 6.99647 13.5145 6.08 13.5145 5.02941C13.5145 3.48706 12.2247 2.23529 10.6354 2.23529C10.0942 2.23529 9.58747 2.38059 9.14986 2.63765L2.29773 6.47118L1.4225 2.23529H0.270886V0H21V2.23529H19.8484L16.3935 19H4.87736Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

import { DialogContentText, useTheme } from "@mui/material";

import type { DialogContentTextProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const DialogDescription = ({
  style = {},
  ...props
}: DialogContentTextProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <DialogContentText
      style={{
        color: theme.palette.text.primary,
        fontSize: "1em",
        fontWeight: 300,
        marginBottom: 40,
        ...style,
      }}
      {...props}
    />
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useProjectUsersList } from "libs/data/endpoints/projects/projects";
import {
  roleAssignmentsCreate,
  useRoleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type {
  RoleAssignmentCreate,
  RoleAssignmentsPerObjectListResourceType,
} from "libs/data/models";

export const useRoleAssignmentsCreate = (
  projectName: string,
  userId?: string,
  resource?: string,
  resourceType?: RoleAssignmentsPerObjectListResourceType
) => {
  const dispatch = useDispatch();
  const { mutate: mutateUserRoleAssignment } = useRoleAssignmentsPerObjectList(
    projectName,
    { assignee: userId, assignee_type: "user" },
    { swr: { enabled: !!userId } }
  );

  const { mutate: mutateBucketRoleAssignment } =
    useRoleAssignmentsPerObjectList(
      projectName,
      {
        resource,
        resource_type: resourceType,
      },
      { swr: { enabled: !!resource } }
    );

  const { mutate: mutateProjectUsersList } = useProjectUsersList(projectName, {
    user_type: "user",
  });

  return useCallback(
    async (newRole: RoleAssignmentCreate, isMemberAdded?: boolean) => {
      if (!projectName) {
        return Promise.reject();
      }

      return roleAssignmentsCreate(projectName, newRole)
        .then(async (result) => {
          dispatch(
            createSuccessNotification(
              isMemberAdded
                ? "The project member was added"
                : "The role assignment was success"
            )
          );

          await mutateProjectUsersList();
          if (resourceType === "bucket") {
            await mutateBucketRoleAssignment();
          } else {
            await mutateUserRoleAssignment();
          }

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [
      projectName,
      dispatch,
      mutateUserRoleAssignment,
      mutateProjectUsersList,
      mutateBucketRoleAssignment,
      resourceType,
    ]
  );
};

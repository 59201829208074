import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";

import { CrumbItem } from "components/atoms";

export const PageBreadcrumbs = () => (
  <Box mb={2} mt={1}>
    <Breadcrumbs
      item={CrumbItem}
      container={MuiBreadcrumbs}
      finalProps={{ isActive: () => true }}
      containerProps={{
        separator: <NavigateNextIcon fontSize="small" />,
        maxItems: 6,
      }}
    />
  </Box>
);

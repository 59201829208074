import { useTheme } from "@emotion/react";
import { useMemo } from "react";

import type { AppThemeProps } from "../theme";

export const useGetSpecialColors = () => {
  const theme = useTheme() as AppThemeProps;

  return useMemo(() => {
    const specialColors = Object.values(theme.palette.specials).filter(
      (color) => typeof color !== "string" && color.pastel && color.bright
    ) as { bright: string; pastel: string }[];

    const colors = specialColors
      .map((color) => color.bright)
      .concat(specialColors.map((color) => color.pastel));

    return colors;
  }, [theme]);
};

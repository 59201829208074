import Edit from "@mui/icons-material/EditRounded";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { SubscribeButton } from "components/molecules/SubscribeButton/SubscribeButton";
import { FIELD_NAME } from "libs/constants/fields";
import { useOrganizationUpdate } from "libs/data/customized/organization";
import { useOrganizationSubscriptionsList } from "libs/data/endpoints/organizations/organizations";
import { useGoogleAnalytics, useColorByStatus } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import validators from "libs/utilities/validators";
import { useGetCurrentOrganization } from "store/features";

import {
  Divider,
  FormTextField,
  PrimaryButton,
  Dialog,
  TextButton,
} from "components/atoms";
import { PageContainer } from "components/molecules";

import OrganizationUsage from "./OrganizationUsage";
import { SubscriptionHistoryTables } from "./SubscriptionHistoryTables";

import "./SubscriptionDetails.scss";
import type { Moment } from "moment";

export const SubscriptionDetails = () => {
  useGoogleAnalytics();
  const organization = useGetCurrentOrganization();

  const match = useRouteMatch();
  const [lastSubscriptionDate, setLastSubscriptionDate] =
    useState<Moment | null>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const colorByStatus = useColorByStatus();
  const updateOrganization = useOrganizationUpdate(organization?.name ?? "");

  const { data: billingHistory, error } = useOrganizationSubscriptionsList(
    organization?.name ?? ""
  );
  const isLoading = !billingHistory && !error;

  useEffect(() => {
    const activeSubscription = billingHistory?.find(
      (subscription) => subscription.active
    );
    if (activeSubscription?.end_date) {
      setLastSubscriptionDate(
        getTzAwareDate(activeSubscription.end_date).endOf("day")
      );
    }
  }, [billingHistory]);

  const onEdit = async ({ name }: { name: string }) => {
    const updatedOrg = await updateOrganization({ name });
    setUpdateDialogOpen(false);
    if (updatedOrg?.name) {
      // wait a bit for the success message,
      // then trigger a full page reload to avoid broken data subscriptions
      // (they may still use the original org name)
      setTimeout(() => {
        window.location.href = `${window.location.origin}/organizations/${updatedOrg.name}/subscription`;
      }, 2000);
    }
  };

  return (
    <PageContainer>
      {organization?.name && (
        <PageHeader
          title={organization.name}
          actions={
            <>
              <TextButton
                color="secondary"
                onClick={() => setUpdateDialogOpen(true)}
                startIcon={<Edit />}
              >
                Edit
              </TextButton>
            </>
          }
        />
      )}
      <BreadcrumbsItem to={match.url}>Subscription & Usage</BreadcrumbsItem>
      <Grid
        container
        spacing={1}
        className="organization-subscription__section"
      >
        <Grid component={Typography} item xs={2} variant="h2">
          Plan details
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            {organization?.status !== "cancelled" && organization?.subscription}
          </Typography>
          <Typography>
            Created on{" "}
            {organization && (
              <b>{getTzAwareDate(organization?.creation_date).format("LL")}</b>
            )}
          </Typography>
          {organization?.subscription === "free" ||
          lastSubscriptionDate === null ||
          getTzAwareDate().add(1, "months") < lastSubscriptionDate ? (
            <Typography
              className={`organization-subscription__${organization?.status}`}
              sx={{ color: colorByStatus(organization?.status ?? "") }}
            >
              Your subscription is currently <b>{organization?.status}</b>
            </Typography>
          ) : (
            <Typography sx={{ color: colorByStatus("failed") }}>
              Your subscription{" "}
              {lastSubscriptionDate.isAfter(getTzAwareDate())
                ? "is going to renew"
                : "ended"}{" "}
              on <b>{lastSubscriptionDate.format("LL")}</b>
            </Typography>
          )}

          <Typography
            className="organization-subscription__info"
            sx={{ color: colorByStatus("processing") }}
          >
            To manage or cancel your subscription, please contact our sales
            team.
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          spacing={1}
          container
          justifyContent="center"
          alignItems="flex-end"
          direction="column"
        >
          <Grid item>
            <SubscribeButton organization={organization ?? undefined} />
          </Grid>
          {/* we're not sure what the sales team wants to do with this link so it's hidden for now */}
          {/* <Grid item>
            <ExternalLink href={LINK_PRICING}>See pricing plans</ExternalLink>
          </Grid> */}
        </Grid>
      </Grid>

      {organization && organization?.subscription && (
        <>
          <Divider my={2} />
          <Grid
            container
            spacing={1}
            className="organization-subscription__section"
          >
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Usage
              </Typography>
            </Grid>
            <OrganizationUsage />
          </Grid>
        </>
      )}
      <Divider my={4} />

      <SubscriptionHistoryTables
        organization={organization ?? undefined}
        historyStatus={isLoading}
        lastSubscriptionDate={lastSubscriptionDate}
      />

      <FormProvider {...formMethods}>
        <Dialog
          Actions={
            <PrimaryButton onClick={handleSubmit(onEdit)}>Save</PrimaryButton>
          }
          onClose={() => setUpdateDialogOpen(false)}
          open={updateDialogOpen}
          title="Edit"
        >
          <FormTextField
            id={FIELD_NAME}
            name={FIELD_NAME}
            label="Organization name"
            rules={{
              required: validators.required.message(FIELD_NAME),
              pattern: {
                value: validators.name.pattern,
                message: validators.name.message(FIELD_NAME),
              },
            }}
            defaultValue={organization?.name}
            placeholder="Ex: my-organization-1"
          />
        </Dialog>
      </FormProvider>
    </PageContainer>
  );
};

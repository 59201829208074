import { useEffect, useState } from "react";

import type { AddedEntity } from "./types";
import type {
  EnvironmentList,
  ImportDetailDeployments,
} from "libs/data/models";

interface RequiredEnvironmentsProps {
  deploymentEntities: ImportDetailDeployments | undefined;
  deployments: AddedEntity[];
  customEnvironments: EnvironmentList[] | undefined;
}

export default ({
  deploymentEntities,
  deployments,
  customEnvironments,
}: RequiredEnvironmentsProps) => {
  const [requiredEnvironments, setRequiredEnvironments] = useState<string[]>(
    []
  );
  useEffect(() => {
    const newEnvironments = [] as string[];
    deployments.forEach((deployment) => {
      const versionData =
        deploymentEntities?.[deployment.name]?.versions?.[deployment.version];
      const usedEnvironment = versionData?.environment;
      const isCustomEnvironment = customEnvironments?.some(
        ({ name, implicit }) => name === usedEnvironment && !implicit
      );
      if (usedEnvironment && isCustomEnvironment) {
        newEnvironments.push(usedEnvironment);
      }
    });
    const newEnvironmentSet = [...new Set(newEnvironments)];
    const requiredEnvironmentSet = [...new Set(requiredEnvironments)];
    const needsToUpdate =
      JSON.stringify(newEnvironmentSet) !==
      JSON.stringify(requiredEnvironmentSet);
    if (needsToUpdate) {
      setRequiredEnvironments(newEnvironmentSet);
    }
  }, [
    deployments,
    deploymentEntities,
    customEnvironments,
    requiredEnvironments,
  ]);

  return requiredEnvironments;
};

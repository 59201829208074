import { useOrganizationUsersGet } from "libs/data/endpoints/organizations/organizations";
import { useUserGet } from "libs/data/endpoints/user/user";

import type { TError } from "libs/data/axios";

export const useOrganizationUser = (organizationName?: string) => {
  const { data: user } = useUserGet();
  const { data, error } = useOrganizationUsersGet<TError>(
    organizationName || "",
    user?.id || ""
  );

  return {
    isAdmin: data?.admin ?? false,
    data,
    error,
    ...user,
  };
};

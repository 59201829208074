import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { spacing } from "assets/styles/theme";
import { explanations } from "libs/utilities/explanations";

import { Dialog, SecondaryButton } from "components/atoms";
import { ControlledTextInput } from "components/molecules";

import type { WebhookHeader } from "libs/data/models";

type WebHookSecretDialogProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  headers: WebhookHeader[] | undefined;
  testWebhook: (headers: WebhookHeader[] | undefined) => void;
};

export const WebHookSecretDialog = ({
  isOpen,
  setIsOpen,
  headers,
  testWebhook,
}: WebHookSecretDialogProps) => {
  const [extendedHeaders, setExtendedHeaders] = useState<WebhookHeader[]>(
    headers || []
  );

  useEffect(() => headers && setExtendedHeaders(headers), [headers]);

  useEffect(() => {
    if (isOpen && headers?.length === 0) {
      setIsOpen(false);
      testWebhook(undefined);
    }
  }, [isOpen, headers, setIsOpen, testWebhook]);

  const missingWebhookHeaders = useMemo(
    () =>
      headers?.filter((header) => header.protected && header.value === null),
    [headers]
  );

  const isDisabled = useMemo(
    () =>
      extendedHeaders?.some(
        (header) => header.protected && header.value === null
      ),
    [extendedHeaders]
  );

  const onTestWebhook = () => {
    testWebhook(extendedHeaders);
    setIsOpen(false);
  };

  return (
    <Dialog
      title="Testing webhook"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={spacing[16]}
        paddingBottom={spacing[8]}
      >
        {explanations.webhooks.secretDialog.details}
      </Box>
      {missingWebhookHeaders?.map(({ key }) => (
        <Box
          key={key}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="50%" marginRight={spacing[8]}>
            <Typography style={{ wordBreak: "break-word" }} variant="h5">
              {key}
            </Typography>
          </Box>
          <ControlledTextInput
            value={
              extendedHeaders?.find((header) => header.key === key)?.value || ""
            }
            onChange={(value) =>
              setExtendedHeaders(
                extendedHeaders.map((header) =>
                  header?.key === key ? { ...header, value } : header
                )
              )
            }
          />
        </Box>
      ))}
      <SecondaryButton
        disabled={isDisabled}
        onClick={onTestWebhook}
        style={{ marginTop: spacing[16] }}
      >
        Test webhook
      </SecondaryButton>
    </Dialog>
  );
};

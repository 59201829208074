import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { environmentRevisionsRebuild } from "libs/data/endpoints/environments/environments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useEnvironmentRevisionRebuild = (
  projectName: string,
  environmentName: string
) => {
  const dispatch = useDispatch();

  return useCallback(
    async (rebuildId: string) => {
      try {
        const result = await environmentRevisionsRebuild(
          projectName,
          environmentName,
          rebuildId,
          {}
        );
        dispatch(
          createSuccessNotification(
            `Rebuilding environment revision successful`
          )
        );

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, environmentName, projectName]
  );
};

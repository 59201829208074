import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  useWebhooksList,
  webhooksCreate,
} from "libs/data/endpoints/webhooks/webhooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { WebhookCreate } from "libs/data/models";

export const useWebHookCreate = (projectName: string) => {
  const dispatch = useDispatch();

  const { mutate } = useWebhooksList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/webhooks`,
    },
  });

  return useCallback(
    async (data: WebhookCreate) => {
      try {
        const response = await webhooksCreate(projectName, data);

        dispatch(createSuccessNotification("The webhook was created"));
        mutate();

        return response;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [dispatch, projectName, mutate]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { organizationsCreate } from "libs/data/endpoints/organizations/organizations";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { OrganizationCreate } from "libs/data/models";

export const useOrganizationCreate = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (data: OrganizationCreate) => {
      try {
        const response = await organizationsCreate(data);

        return response;
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));

        return;
      }
    },
    [dispatch]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  environmentBuildsGet,
  environmentRevisionsFileUpload,
  useEnvironmentsGet,
} from "libs/data/endpoints/environments/environments";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { EnvironmentRevisionsFileUploadBody } from "libs/data/models";

export const useEnvironmentFileUplaod = (
  projectName: string,
  environmentName: string
) => {
  const { mutate } = useEnvironmentsGet(projectName, environmentName);
  const dispatch = useDispatch();

  return useCallback(
    async (uploadBody: EnvironmentRevisionsFileUploadBody) => {
      try {
        const { revision, build } = await environmentRevisionsFileUpload(
          projectName,
          environmentName,
          uploadBody
        );
        await environmentBuildsGet(
          projectName,
          environmentName,
          revision,
          build
        );
        await mutate();
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error?.message));

        return;
      }
    },
    [dispatch, mutate, projectName, environmentName]
  );
};

import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { exportsCreate } from "libs/data/endpoints/imports-and-exports/imports-and-exports";
import { createSuccessNotification } from "libs/utilities/notifications";

import type { AddedEntity, Entity } from "./types";
import type {
  EnvironmentList,
  ImportDetailDeployments,
  ImportDetailPipelines,
} from "libs/data/models";

interface CreateExportProps {
  environments: string[];
  deployments: AddedEntity[];
  pipelines: AddedEntity[];
  customEnvironments: EnvironmentList[] | undefined;
  deploymentEntities: Entity;
  pipelineEntities: Entity;
  requiredEnvironments: string[];
  description: string;
  includeEnvVars: boolean;
}

export const useCreateExport = ({
  environments,
  customEnvironments,
  deploymentEntities,
  deployments,
  requiredEnvironments,
  pipelines,
  pipelineEntities,
  description,
  includeEnvVars,
}: CreateExportProps) => {
  const history = useHistory();
  const { projectName } = useParams<{ projectName: string }>();
  const baseUrl = useContext(BaseUrlContext);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const createExport = useCallback(async () => {
    if (!deploymentEntities || !pipelineEntities) return;
    setLoading(true);

    const payload = {
      environments: {} as { [key: string]: EnvironmentList },
      deployments: {} as { [key: string]: ImportDetailDeployments },
      pipelines: {} as { [key: string]: ImportDetailPipelines },
      description: description,
    };

    const deploymentNames = [
      ...new Set(deployments.map((deployment) => deployment.name)),
    ];

    const pipelineNames = [
      ...new Set(pipelines.map((pipeline) => pipeline.name)),
    ];

    customEnvironments?.forEach((environment) => {
      if (
        environments.includes(environment.name) ||
        requiredEnvironments.includes(environment.name)
      ) {
        payload.environments[environment.name] = environment;
      }
    });

    deploymentNames?.forEach((deploymentName) => {
      const selectedVersions = deployments
        .filter(({ name }) => name === deploymentName)
        .map(({ version }) => version);
      const deploymentVersionObject = selectedVersions.reduce(
        (acc, versionName) => ({
          ...acc,
          [versionName]: {
            all_environment_variables: includeEnvVars,
          },
        }),
        {}
      );
      payload.deployments[deploymentName] = {
        ...deploymentEntities[deploymentName],
        versions: deploymentVersionObject,
        // @ts-ignore
        all_environment_variables: includeEnvVars,
      };
    });

    pipelineNames?.forEach((pipelineName) => {
      const selectedVersions = pipelines
        .filter(({ name }) => name === pipelineName)
        .map(({ version }) => version);
      const pipelineVersionObject = selectedVersions.reduce(
        (acc, versionName) => ({
          ...acc,
          [versionName]: {},
        }),
        {}
      );
      payload.pipelines[pipelineName] = {
        ...pipelineEntities[pipelineName],
        versions: pipelineVersionObject,
      };
    });

    await exportsCreate(projectName, payload);
    history.push(`${baseUrl}/exports`);
    dispatch(createSuccessNotification("Export created successfully"));
    setLoading(false);
  }, [
    deploymentEntities,
    pipelineEntities,
    description,
    deployments,
    pipelines,
    customEnvironments,
    projectName,
    history,
    baseUrl,
    dispatch,
    environments,
    requiredEnvironments,
    includeEnvVars,
  ]);

  return { createExport, isLoading };
};

import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";

import { ENV_NAMES } from "libs/env/env-names";

export const parseEnv = (envVar) => {
  if (["True", "true", true, 1, "yes", "Yes"].includes(envVar)) {
    return true;
  } else if (["False", "false", false, 0, "no", "No"].includes(envVar)) {
    return false;
  } else if (envVar === "") {
    return undefined;
  } else {
    return envVar;
  }
};

const ENVIRONMENTS = [
  {
    [ENV_NAMES.NAME]: window._env_?.WEBAPP_ENV,
    [ENV_NAMES.HOST]: window._env_?.WEBAPP_API_HOST,
    [ENV_NAMES.OAUTH_ENABLED]: window._env_?.WEBAPP_OAUTH_ENABLED === "True",
    // if false, the `usage` endpoint is disabled
    [ENV_NAMES.METRICS_AGGREGATION_ENABLED]:
      window._env_?.WEBAPP_METRICS_AGGREGATION_ENABLED === "True",
    [ENV_NAMES.BILLING_ENABLED]:
      window._env_?.WEBAPP_BILLING_ENABLED === "True",
    [ENV_NAMES.ON_PREMISE]: window._env_?.WEBAPP_ENV === ENV_NAMES.ON_PREMISE,
    [ENV_NAMES.GOOGLE_ANALYTICS_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.GOOGLE_ANALYTICS_ENABLED]
    ),
    [ENV_NAMES.HOTJAR_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.HOTJAR_ENABLED]
    ),
    [ENV_NAMES.PRODUCT_FRUITS_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.PRODUCT_FRUITS_ENABLED]
    ),
    [ENV_NAMES.REQUIRES_CONSENT]: parseEnv(
      window._env_?.[ENV_NAMES.REQUIRES_CONSENT]
    ),
    [ENV_NAMES.SENTRY_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.SENTRY_ENABLED]
    ),
    [ENV_NAMES.TRACKING_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.TRACKING_ENABLED]
    ),
    [ENV_NAMES.TRAINING_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.TRAINING_ENABLED]
    ),
    [ENV_NAMES.ENVIRONMENTS_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.ENVIRONMENTS_ENABLED]
    ),
    [ENV_NAMES.GOOGLE_ANALYTICS_TOKEN]:
      window._env_?.WEBAPP_GOOGLE_ANALYTICS_TOKEN,
    [ENV_NAMES.SENTRY_DSN]: window._env_?.WEBAPP_SENTRY_DSN,
    [ENV_NAMES.REQUESTS_PAGE_ENABLED]:
      window._env_?.REQUESTS_PAGE_ENABLED === "True",
    [ENV_NAMES.REQUESTS_PAGE_URL]: window._env_?.REQUESTS_PAGE_URL,
    [ENV_NAMES.FILES_PROXY_ENABLED]:
      window._env_?.FILES_PROXY_ENABLED === "True",
    [ENV_NAMES.FILES_PROXY_HOST]: window._env_?.FILES_PROXY_HOST,
  },
];

const DEFAULT_LOCALSTORAGE_KEY = "UBIOPS_ENVIRONMENT_MANAGER";
const DEFAULTS = {
  current: window._env_?.WEBAPP_ENV,
  environments: [],
};

const HTTP_REGEX = /^https?:\/\//;
const isHttpUrl = (str) => HTTP_REGEX.test(str);
const isNonEmptyString = (str) => typeof str === "string" && str.length > 0;

class EnvironmentManager {
  constructor(key = DEFAULT_LOCALSTORAGE_KEY) {
    this.db = low(new LocalStorage(key));

    this.db.defaults(DEFAULTS).write();

    this.db
      .set(
        "environments",
        this.db.get("environments").unionBy(ENVIRONMENTS, "name").value()
      )
      .write();

    ENVIRONMENTS.forEach((env) => this.hydrateEnvironment(env.name, env));

    if (!this.current()) {
      console.error("Invalid EnvironmentManager State", this.db.value()); // eslint-disable-line no-console
      throw new Error("Invalid EnvironmentManager State");
    }
  }

  __environments() {
    return this.db.get("environments");
  }

  __current() {
    return this.db
      .get("environments")
      .find({ name: this.db.get("current").value() });
  }

  environments() {
    return this.__environments().value();
  }

  current() {
    return this.__current().value();
  }

  set(key, value) {
    if (key === "name") {
      throw new Error("Setting Current Environment Name Not Allowed");
    }
    this.__current().set(key, value).write();
  }

  unset(key) {
    if (key === "name") {
      throw new Error("Unsetting Current Environment Name Not Allowed");
    }
    this.__current().set(key, null).write();
  }

  get(key) {
    return this.__current().get(key).value();
  }

  isValidEnvironment(env) {
    const lo = this.db._;

    return (
      lo.isPlainObject(env) && isNonEmptyString(env.name) && isHttpUrl(env.host)
    );
  }

  createEnvironment(newEnv) {
    if (!this.isValidEnvironment(newEnv)) {
      throw new Error("Invalid Environment Object");
    }

    if (this.hasEnvironment(newEnv.name)) {
      throw new Error("Environment Already Exists");
    }

    this.__environments().push(newEnv).write();

    return newEnv;
  }

  removeEnvironment(name) {
    if (name === this.name()) {
      throw new Error("Cannot Remove Current Environment");
    }
    this.__environments().remove({ name }).write();
  }

  getEnvironment(name) {
    return this.__environments().find({ name }).value();
  }

  hasEnvironment(name) {
    return !!this.getEnvironment(name);
  }

  hydrateEnvironment(name, data = {}) {
    if (data.name !== name && name === this.name()) {
      throw new Error("Cannot Update Current Environment Name");
    }
    this.__environments().find({ name }).assign(data).write();
  }

  setEnvironmentAsCurrent(key) {
    if (!this.hasEnvironment(key)) {
      throw new Error("Environment Not Found");
    }
    this.db.set("current", key).write();
  }

  dump() {
    console.log("EnvironmentManager", this.db.value()); // eslint-disable-line no-console
  }
}

export const env = new EnvironmentManager();

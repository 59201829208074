import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { useGoogleAnalytics, useQuery } from "libs/hooks";
import { formatLabel } from "libs/utilities/utils";

import { Loader } from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { useSignInOauth } from "../../../libs/data/customized/authorize/useSignInOauth";

import type { OauthCompleteProvider } from "libs/data/models";

const SignInOAuth = () => {
  useGoogleAnalytics();

  const { provider } = useParams<{ provider: OauthCompleteProvider }>();
  const queryParameters = useQuery();
  const [cookies] = useCustomCookies();
  const { signInOauth } = useSignInOauth();

  useEffect(() => {
    const code = queryParameters.get("code") || "";
    const state_value = queryParameters.get("state");
    const state_key = cookies[COOKIE_NAMES.STATE_KEY];

    signInOauth({
      code,
      provider,
      state_key,
      state_value,
    });
  }, [cookies, provider, queryParameters, signInOauth]);

  return (
    <AuthenticationContainer
      title={`Signing in to ${formatLabel(provider)}...`}
    >
      <Loader />
    </AuthenticationContainer>
  );
};

export default SignInOAuth;

import Flash from "@mui/icons-material/FlashOn";
import DocumentationIcon from "@mui/icons-material/MenuBookRounded";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import { Box, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { DOC_LINKS } from "libs/constants/documentation-links";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { env } from "libs/env";
import { gtmEvent } from "libs/hooks/useGoogleAnalytics";
import { useProductsFruitApi } from "libs/third-parties";

import { PrimaryButton, SecondaryButton } from "components/atoms";

type StarterTutorialProps = {
  info?: boolean;
};

export const StarterTutorial = ({ info = false }: StarterTutorialProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [cookies] = useCustomCookies([COOKIE_NAMES.COOKIES_PREFERENCE]);
  const [loadScripts] = useState(
    cookies[COOKIE_NAMES.COOKIES_PREFERENCE] !== "false" &&
      env.current().name === "production"
  );
  const [startProductFruitsTour, setStartProductFruitsTour] = useState(false);

  useProductsFruitApi(startProductFruitsTour);

  const onProductFruitsTourClick = () => setStartProductFruitsTour(true);

  return (
    <Box>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid
          item
          lg={info ? 6 : 12}
          xs={12}
          container
          direction="column"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5">Tutorials</Typography>
            <Typography variant="body2">
              Start using UbiOps with a tutorial
              {loadScripts ? " or our guided tour" : ""}.
            </Typography>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item>
              <PrimaryButton
                href={DOC_LINKS.STARTER_TUTORIAL}
                target="_blank"
                size="small"
                startIcon={<Flash />}
                onClick={() => {
                  gtmEvent("quickstart_clicked", {
                    event_category: "quickstart",
                  });
                }}
              >
                Starter tutorial
              </PrimaryButton>
            </Grid>
            {loadScripts && projectName && (
              <Grid item>
                <SecondaryButton
                  onClick={onProductFruitsTourClick}
                  size="small"
                  startIcon={<PlayCircleOutlineRoundedIcon />}
                >
                  Get a tour
                </SecondaryButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {info && (
          <>
            <Grid
              item
              lg={6}
              xs={12}
              container
              direction="column"
              spacing={1}
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="h5">Documentation</Typography>
                <Typography variant="body2">
                  Read our documentation to get started and clarify any
                  questions you might have.
                </Typography>
              </Grid>
              <Grid item>
                <SecondaryButton
                  href={DOC_LINKS.DOCS}
                  target="_blank"
                  size="small"
                  startIcon={<DocumentationIcon />}
                >
                  Documentation
                </SecondaryButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

import styled from "@emotion/styled";
import { FormControl, useTheme } from "@mui/material";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties } from "react";
import type { UseFormMethods } from "react-hook-form";

import "./Radio.scss";

export interface RadioProps {
  register: UseFormMethods["register"];
  id: string;
  name: string;
  label?: string;
  tooltip?: string;
  value: string;
  error?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: CSSProperties;
}

export const Radio = ({
  register,
  id,
  name,
  label,
  error,
  tooltip,
  value,
  disabled,
  defaultChecked,
  onChange,
  style,
}: RadioProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Container
      baseColor={theme.palette.neutrals[500]}
      errorColor={theme.palette.error.main}
      className="radio"
      style={style}
    >
      <label
        className={[
          "radio__label",
          disabled && "radio__label--disabled",
          error && "radio__label--error",
        ]
          .filter((x) => x)
          .join(" ")}
        htmlFor={id}
      >
        <input
          ref={register}
          id={id}
          name={name}
          type="radio"
          className="radio__input"
          value={value}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <Wrapper
          color={theme.palette.secondary.main}
          hoverColor={theme.palette.body.radioHover}
          className="radio__wrapper"
        >
          <span className="radio__input-wrapper">
            <div className="radio__custom-input">
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>
              <svg
                className="MuiSvgIcon-root PrivateRadioButtonIcon"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"></path>
              </svg>
            </div>
          </span>
        </Wrapper>

        <span>{label}</span>
        {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
      </label>
    </Container>
  );
};

const Wrapper = styled.span<{
  color: string;
  hoverColor: string;
}>`
  color: ${(props) => props.color};

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const Container = styled(FormControl)<{
  baseColor: string;
  errorColor: string;
}>`
  .radio__label {
    &--error {
      .radio__wrapper {
        color: ${(props) => props.errorColor};
      }
    }
  }

  .radio__input:not(:checked) + .radio__wrapper {
    color: ${(props) => props.baseColor};
  }
`;

export default Radio;

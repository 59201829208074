import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Grid, Typography } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { PrimaryButton } from "components/atoms";

import type { ElementType, SVGProps } from "react";

type Props = {
  message: string;
  Illustration: ElementType<SVGProps<SVGSVGElement>>;
  isAllowed?: boolean;
  buttonLabel?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const EmptyOverview = ({
  message,
  Illustration,
  isAllowed,
  buttonLabel,
  onClick,
  children,
}: Props) => (
  <Grid
    xs={12}
    container
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    p={4}
  >
    <Box mb={2}>
      <Typography variant="h4" textAlign="center">
        {message}
      </Typography>
    </Box>
    {isAllowed && (
      <PrimaryButton startIcon={<Plus />} onClick={onClick}>
        {buttonLabel}
      </PrimaryButton>
    )}
    {children}
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      maxHeight={spacing[500]}
      mt={8}
    >
      <Illustration />
    </Box>
  </Grid>
);

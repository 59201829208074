import { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import type { Ref } from "react";
import type { NavLinkProps } from "react-router-dom";

const NavLink = (
  props: Omit<NavLinkProps, "innerRef">,
  ref?: Ref<HTMLAnchorElement>
) => {
  return (
    <RouterLink
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          e.stopPropagation();
          props.to && window.open(props.to as string, "_blank");

          return;
        }
      }}
      innerRef={ref}
      {...props}
    />
  );
};

export default forwardRef(NavLink);

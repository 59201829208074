import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { useUserGet } from "libs/data/endpoints/user/user";
import { isDatePassed } from "libs/utilities/date-util";
import { useGetPermissions } from "store/features/permissions";

import { Card, Loader } from "components/atoms";

import { AddRoleChip } from "./AddRoleChip";
import { UserRoleChip } from "./UserRoleChip";

import type { ProjectUserList, ServiceUserList } from "libs/data/models";

type UserRoleAssignmentsProps = {
  currentUser: ProjectUserList | ServiceUserList;
  onRoleDetailsClick: (role: string) => void;
  setRoleAssignmentDialogOpen: () => void;
};

const UserRoleAssignments = ({
  currentUser,
  onRoleDetailsClick,
  setRoleAssignmentDialogOpen,
}: UserRoleAssignmentsProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const { data: loggedInUser } = useUserGet();

  const [currentPermissions] = useGetPermissions();

  const id = currentUser.id;

  const { data: userRoles } = useRoleAssignmentsPerObjectList(projectName, {
    assignee: id,
    assignee_type: "user",
  });

  const hasExpiryDate = (currentUser as ServiceUserList)?.expiry_date;

  return (
    <>
      <Card title="Roles:">
        {!userRoles ? (
          <Loader size={4} />
        ) : (
          <Grid container alignItems="center" spacing={1}>
            {userRoles.length ? (
              userRoles.map((userRole) => {
                return (
                  <UserRoleChip
                    key={userRole.id}
                    userRole={userRole}
                    loggedInUserId={loggedInUser?.id ?? ""}
                    onRoleDetailsClick={onRoleDetailsClick}
                  />
                );
              })
            ) : (
              <Grid item>
                <Typography variant="body1">
                  {!currentPermissions[USER_ROLE_PERMISSIONS["role_get"]]
                    ? "You currently don't have permissions to view roles."
                    : "This project member currently doesn't have any roles."}
                </Typography>
              </Grid>
            )}
            {((hasExpiryDate && !isDatePassed(hasExpiryDate)) ||
              !hasExpiryDate) && (
              <AddRoleChip toggleDialog={setRoleAssignmentDialogOpen} />
            )}
          </Grid>
        )}
      </Card>
    </>
  );
};

export default UserRoleAssignments;

import * as Sentry from "@sentry/react";

import { Axios } from "libs/data/axios";
import { env } from "libs/env";

import { TERMS_CONDITIONS_ERROR, TWO_FACTOR_AUTH_ERROR } from "./constants";
import { refreshToken } from "./refresh-token";
import { checkPermissionToRefreshAccessToken, parseError } from "./utilities";

import type { AxiosError } from "axios";

export const axiosResponseInterceptorErrorHandler = async (
  error: AxiosError<any, any>
) => {
  const unauthorized =
    error?.response?.status === 401 &&
    error?.response?.data?.error_type !== TWO_FACTOR_AUTH_ERROR;
  const notFound = error?.response?.status === 404;
  if (unauthorized && checkPermissionToRefreshAccessToken(env)) {
    return handleUnauthorizeError(error);
  }

  if (
    error.request?.responseType === "blob" &&
    error.response?.data instanceof Blob &&
    error.response?.data.type &&
    error.response?.data.type.toLowerCase().indexOf("json") !== -1
  ) {
    return handleBlobRequestError(error);
  }

  if (!unauthorized && !notFound) {
    Sentry.captureException(error);
  }

  const pagesWithoutAuth = ["sign-in", "sign-up", "reset-password"];
  const authRequired = pagesWithoutAuth.every(
    (page) => !location.pathname.includes(page)
  );

  if (unauthorized && authRequired) {
    window.location.pathname = "/sign-in";
  }

  return Promise.reject(parseError(error));
};

const handleUnauthorizeError = async (error: AxiosError<any, any>) => {
  const termsPage = "/sign-up/terms";
  const isTermsError =
    error?.response?.data?.error_type === TERMS_CONDITIONS_ERROR;
  if (isTermsError && window.location.pathname != termsPage) {
    window.location.pathname = termsPage;

    return;
  }
  if (!error.response?.config) {
    Promise.reject(error);
  }

  // @ts-ignore
  const config = await refreshToken(error.response.config);

  return Axios(config);
};

const handleBlobRequestError = (error: AxiosError) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // @ts-ignore
      error.response.data = JSON.parse(reader.result || {});
      resolve(Promise.reject(error));
    };
    reader.onerror = () => {
      reject(error);
    };
    // @ts-ignore
    reader.readAsText(error.response.data);
  });
};

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type WebhookCreateEvent =
  typeof WebhookCreateEvent[keyof typeof WebhookCreateEvent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookCreateEvent = {
  deployment_request_started: "deployment_request_started",
  deployment_request_completed: "deployment_request_completed",
  deployment_request_failed: "deployment_request_failed",
  deployment_request_finished: "deployment_request_finished",
  pipeline_request_started: "pipeline_request_started",
  pipeline_request_completed: "pipeline_request_completed",
  pipeline_request_failed: "pipeline_request_failed",
  pipeline_request_finished: "pipeline_request_finished",
} as const;

import {
  FIELD_IMPORTED_ZIP_FILE,
  FIELD_IMPORT_LINK,
} from "libs/constants/fields";
import { axios } from "libs/data/axios";

import { EntityType } from "./types";

import type { EntityVersionObject } from "./types";
import type { NewNamesLayer } from "./useImportConflicts";
import type { ImportDetail } from "libs/data/models";

export const getImport = (
  currentProjectName: string | undefined,
  importId: string
) =>
  axios({
    method: "GET",
    url: `/projects/${currentProjectName}/imports/${importId}`,
  });

interface FileFormData {
  [FIELD_IMPORT_LINK]: string;
  [FIELD_IMPORTED_ZIP_FILE]: File[];
}

export const createFileFormData = (data: FileFormData) => {
  const link = new FormData();
  if (data[FIELD_IMPORT_LINK]) {
    link.append("import_link", data[FIELD_IMPORT_LINK]);
    link.append("skip_confirmation", "");
  } else {
    const file = data[FIELD_IMPORTED_ZIP_FILE][0];
    link.append("file", file);
    link.append("skip_confirmation", "");
  }

  return link;
};

const convertReferenceType = (referenceType: string) =>
  ({
    pipeline: EntityType.pipelines,
    deployment: EntityType.deployments,
    environment: EntityType.environments,
  }[referenceType]);

export const resolveConflict = (
  editedImport: ImportDetail,
  newNamesLayer: NewNamesLayer
) => {
  const importToResolve = {
    ...editedImport,
  };

  const entityTypes = Object.keys(newNamesLayer) as EntityType[];

  entityTypes.forEach((entityType) => {
    const nameChangesByType = newNamesLayer[entityType];
    const entityNamesToChange = Object.keys(nameChangesByType);
    const originalEntities = importToResolve[entityType] || {};

    // rename individual entities based on the name changes
    entityNamesToChange.forEach((oldName) => {
      const newName = nameChangesByType[oldName];
      if (originalEntities) {
        originalEntities[newName] = {
          ...originalEntities[oldName],
          display_name: newName,
        };
        delete originalEntities[oldName];
      }
    });

    // check entity version for reference name changes
    const originalEntityNames = Object.keys(originalEntities);
    originalEntityNames.forEach((entityName) => {
      const originalEntity = originalEntities[entityName];
      const versionNames = Object.keys(originalEntity?.versions || {});
      versionNames.forEach((versionName) => {
        const version = originalEntity?.versions?.[versionName] || {};
        const objects = version.objects || [];

        // rename reference object names
        objects.forEach((object: EntityVersionObject) => {
          const objectType = convertReferenceType(object?.reference_type);
          const newName =
            objectType && newNamesLayer[objectType]?.[object?.reference_name];

          if (newName) {
            object.reference_name = newName;
          }
        });
      });
    });
  });

  return importToResolve;
};

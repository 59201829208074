import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  TIME_OUT_BROWSER,
  TIME_OUT_PIPELINE_REQUESTS,
  REQUEST_TASK,
  STRUCTURED_TYPE,
} from "libs/constants/constants";
import {
  pipelineRequestsCreate,
  pipelineVersionRequestsCreate,
  usePipelineVersionRequestsList,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { createErrorNotification } from "libs/utilities/notifications";
import { ERROR, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { addTask, updateTask } from "store/features/taskManager";

import type { AxiosError } from "axios";
import type {
  DeploymentRequestsCreateDataBody,
  PipelineRequestCreateResponse,
} from "libs/data/models";

export const usePipelineDirectRequestCreate = (
  projectName: string,
  pipelineName: string,
  version?: string,
  timeout?: number
) => {
  const dispatch = useDispatch();
  const taskId = getRandomId();
  const { mutate } = usePipelineVersionRequestsList(
    projectName,
    pipelineName,
    version ?? "",
    undefined,
    { swr: { enabled: !!pipelineName } }
  );

  return useCallback(
    async (data: DeploymentRequestsCreateDataBody, requestParameters: any) => {
      const result: {
        requestData: DeploymentRequestsCreateDataBody;
        responseData: PipelineRequestCreateResponse | undefined;
      } = {
        responseData: undefined,
        requestData: data,
      };
      if (!requestParameters.isRetentionModeNone)
        dispatch(
          addTask({
            id: taskId,
            type: REQUEST_TASK,
            status: LOADING,
            message: `${requestParameters.type} request`,
            requestParameters: requestParameters,
          })
        );

      try {
        const headers = {
          "Content-Type":
            requestParameters?.inputType === STRUCTURED_TYPE
              ? "application/json"
              : "text/plain",
        };
        const response = version
          ? await pipelineVersionRequestsCreate(
              projectName,
              pipelineName,
              version,
              data,
              { pipeline_timeout: timeout ?? TIME_OUT_PIPELINE_REQUESTS },
              {
                timeout: TIME_OUT_BROWSER,
                headers,
              }
            )
          : await pipelineRequestsCreate(
              projectName,
              pipelineName,
              data,
              { pipeline_timeout: timeout ?? TIME_OUT_PIPELINE_REQUESTS },
              {
                timeout: TIME_OUT_BROWSER,
                headers,
              }
            );

        if (response) {
          dispatch(
            updateTask({
              id: taskId,
              status: LOADING,
              result: { ...result, responseData: response },
            })
          );
        }

        return { ...result, responseData: response };
      } catch (err: any) {
        if (!err?.response?.data?.error_message)
          dispatch(createErrorNotification((err as AxiosError)?.message));

        dispatch(
          updateTask({
            id: taskId,
            status: ERROR,
            result: { ...result, responseData: err?.response?.data },
          })
        );

        return { ...result, responseData: err?.response?.data };
      } finally {
        mutate();
      }
    },
    [dispatch, pipelineName, projectName, taskId, timeout, version, mutate]
  );
};

import { FormControl, Typography } from "@mui/material";
import { get } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelect } from "../AutoCompleteSelect";

import type {
  AutocompleteSelectOption,
  BaseAutoCompleteSelectProps,
} from "../AutoCompleteSelect/types";
import type { KeyboardEventHandler } from "react";

export interface AutoCompleteSelectHookFormProps
  extends BaseAutoCompleteSelectProps {
  name: string;
  onChange?: any;
  rules?: any;
  label?: string;
  className?: string;
  defaultValue?: AutocompleteSelectOption;
  tooltip?: string;
  withError?: boolean;
  component?: any;
  isMulti?: boolean;
  disabled?: boolean;
  handleOnKeyDown?: KeyboardEventHandler;
  isSearchable?: boolean;
  loading?: boolean;
  freeSolo?: boolean;
  maxMenuHeight?: number;
  getOptionLabel?: (option: AutocompleteSelectOption) => string;
  groupBy?: (option: AutocompleteSelectOption) => string;
}

export const AutoCompleteSelectHookForm = ({
  options,
  onChange,
  rules,
  defaultValue,
  name,
  renderOption,
  error,
  showErrors = true,
  freeSolo = false,
  groupBy,
  placeholder,
  ...props
}: AutoCompleteSelectHookFormProps) => {
  const { register, unregister, setValue, watch, errors } = useFormContext();

  const value = watch(name) ?? null;

  const handleOnChange = (selectedOption: AutocompleteSelectOption | null) => {
    groupBy && delete selectedOption?.group;

    setValue(name, selectedOption, { shouldDirty: true });
    if (onChange) {
      onChange(selectedOption);
    }
  };

  useEffect(() => {
    register({ name }, rules);

    return () => unregister(name);
  }, [register, unregister, name, rules]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  return (
    <FormControl fullWidth error={get(errors, name)}>
      <AutoCompleteSelect
        id={name}
        options={options}
        value={value}
        renderOption={renderOption}
        onChange={handleOnChange}
        onInputChange={(value) => {
          if (freeSolo)
            setValue(
              name,
              { label: value, value: value },
              { shouldDirty: true }
            );
        }}
        groupBy={groupBy}
        error={error}
        showErrors={showErrors}
        placeholder={placeholder}
        freeSolo={freeSolo}
        {...props}
      />
      {error && showErrors && (
        // negative mt to compensate for margin bottoms for dropdowns from FormSection
        <Typography variant="caption" color="error" mt={-1.5} mb={1.5}>
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

import { useTheme } from "@mui/material";

export const IlluSubscriptionLimit = () => {
  const theme = useTheme();

  return (
    <svg
      width="318"
      height="161"
      viewBox="0 0 318 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M303 159H7V161H303V159Z" fill="#E6E6E6" />
      <path d="M179 158H130L131.661 137H177.339L179 158Z" fill="#E6E6E6" />
      <path d="M182 155H128V160H182V155Z" fill="#E6E6E6" />
      <path
        d="M247.099 0H62.901C61.6012 0 60.3546 0.515413 59.4355 1.43284C58.5164 2.35026 58 3.59455 58 4.89198V117H252V4.89198C252 4.24956 251.873 3.61343 251.627 3.0199C251.381 2.42638 251.02 1.8871 250.565 1.43284C250.109 0.978575 249.569 0.618229 248.975 0.372384C248.38 0.126538 247.743 5.5551e-07 247.099 0H247.099Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M58 117V135.158C58 135.794 58.1268 136.423 58.3731 137.011C58.6194 137.598 58.9804 138.132 59.4355 138.582C59.8906 139.031 60.4309 139.388 61.0255 139.631C61.6201 139.875 62.2574 140 62.901 140H247.099C248.399 140 249.645 139.49 250.565 138.582C251.484 137.674 252 136.442 252 135.158V117H58Z"
        fill="#E6E6E6"
      />
      <path d="M242 10H68V108H242V10Z" fill="#E6E6E6" />
      <path
        d="M155 133C157.761 133 160 130.761 160 128C160 125.239 157.761 123 155 123C152.239 123 150 125.239 150 128C150 130.761 152.239 133 155 133Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M73 14C73.5523 14 74 13.5523 74 13C74 12.4477 73.5523 12 73 12C72.4477 12 72 12.4477 72 13C72 13.5523 72.4477 14 73 14Z"
        fill="white"
      />
      <path
        d="M77 14C77.5523 14 78 13.5523 78 13C78 12.4477 77.5523 12 77 12C76.4477 12 76 12.4477 76 13C76 13.5523 76.4477 14 77 14Z"
        fill="white"
      />
      <path
        d="M80.5 14C80.7761 14 81 13.5523 81 13C81 12.4477 80.7761 12 80.5 12C80.2239 12 80 12.4477 80 13C80 13.5523 80.2239 14 80.5 14Z"
        fill="white"
      />
      <path d="M237 16H72V98H237V16Z" fill="white" />
      <path
        d="M83.5797 29C83.426 29.0001 83.2786 29.0624 83.17 29.173C83.0613 29.2836 83.0001 29.4335 83 29.5899V59.4101C83.0001 59.5665 83.0613 59.7164 83.17 59.827C83.2786 59.9376 83.426 59.9999 83.5797 60H117.42C117.574 59.9999 117.721 59.9376 117.83 59.827C117.939 59.7164 118 59.5665 118 59.4101V29.5899C118 29.4335 117.939 29.2836 117.83 29.173C117.721 29.0624 117.574 29.0001 117.42 29H83.5797Z"
        fill="#E6E6E6"
      />
      <path
        d="M137.598 29C137.44 29.0001 137.288 29.0625 137.175 29.1734C137.063 29.2844 137 29.4348 137 29.5918V59.4082C137 59.5652 137.063 59.7156 137.175 59.8266C137.288 59.9375 137.44 59.9999 137.598 60H172.402C172.561 59.9998 172.713 59.9374 172.825 59.8264C172.937 59.7155 173 59.5651 173 59.4082V29.5918C173 29.4349 172.937 29.2845 172.825 29.1736C172.713 29.0626 172.561 29.0002 172.402 29H137.598Z"
        fill="#E6E6E6"
      />
      <path
        d="M192.576 29C192.423 29.0002 192.277 29.062 192.169 29.1718C192.061 29.2817 192 29.4306 192 29.5859V59.4141C192 59.5694 192.061 59.7183 192.169 59.8282C192.277 59.938 192.423 59.9998 192.576 60H226.424C226.577 59.9998 226.723 59.938 226.831 59.8282C226.939 59.7183 227 59.5694 227 59.4141V29.5859C227 29.4306 226.939 29.2817 226.831 29.1718C226.723 29.062 226.577 29.0002 226.424 29H192.576Z"
        fill="#E6E6E6"
      />
      <path
        d="M84.224 74C83.8991 74.0005 83.5878 74.1061 83.3583 74.2936C83.1289 74.481 83 74.7351 83 75C83 75.2649 83.1289 75.519 83.3583 75.7064C83.5878 75.8939 83.8991 75.9995 84.224 76H116.776C117.101 75.9995 117.412 75.8939 117.642 75.7064C117.871 75.519 118 75.2649 118 75C118 74.7351 117.871 74.481 117.642 74.2936C117.412 74.1061 117.101 74.0005 116.776 74H84.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M84.145 80C83.8411 80.0005 83.5499 80.1061 83.3352 80.2935C83.1206 80.481 83 80.7351 83 81C83 81.2649 83.1206 81.519 83.3352 81.7064C83.5499 81.8939 83.8411 81.9995 84.145 82H97.855C98.1589 81.9995 98.4501 81.8939 98.6648 81.7064C98.8794 81.519 99 81.2649 99 81C99 80.7351 98.8794 80.481 98.6648 80.2935C98.4501 80.1061 98.1589 80.0005 97.855 80H84.145Z"
        fill="#6C63FF"
      />
      <path
        d="M139.224 74C138.899 74.0005 138.588 74.1061 138.358 74.2936C138.129 74.481 138 74.7351 138 75C138 75.2649 138.129 75.519 138.358 75.7064C138.588 75.8939 138.899 75.9995 139.224 76H171.776C172.101 75.9995 172.412 75.8939 172.642 75.7064C172.871 75.519 173 75.2649 173 75C173 74.7351 172.871 74.481 172.642 74.2936C172.412 74.1061 172.101 74.0005 171.776 74H139.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M139.217 80C138.894 80.0005 138.584 80.1061 138.356 80.2935C138.128 80.481 138 80.7351 138 81C138 81.2649 138.128 81.519 138.356 81.7064C138.584 81.8939 138.894 81.9995 139.217 82H153.783C154.106 81.9995 154.416 81.8939 154.644 81.7064C154.872 81.519 155 81.2649 155 81C155 80.7351 154.872 80.481 154.644 80.2935C154.416 80.1061 154.106 80.0005 153.783 80H139.217Z"
        fill="#6C63FF"
      />
      <path
        d="M193.224 74C192.899 74.0005 192.588 74.1061 192.358 74.2936C192.129 74.481 192 74.7351 192 75C192 75.2649 192.129 75.519 192.358 75.7064C192.588 75.8939 192.899 75.9995 193.224 76H225.776C226.101 75.9995 226.412 75.8939 226.642 75.7064C226.871 75.519 227 75.2649 227 75C227 74.7351 226.871 74.481 226.642 74.2936C226.412 74.1061 226.101 74.0005 225.776 74H193.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M84.224 68C83.8991 68.0007 83.5878 68.1591 83.3583 68.4403C83.1289 68.7216 83 69.1027 83 69.5C83 69.8973 83.1289 70.2784 83.3583 70.5597C83.5878 70.8409 83.8991 70.9993 84.224 71H116.776C117.101 70.9993 117.412 70.8409 117.642 70.5597C117.871 70.2784 118 69.8973 118 69.5C118 69.1027 117.871 68.7216 117.642 68.4403C117.412 68.1591 117.101 68.0007 116.776 68H84.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M139.224 68C138.899 68.0007 138.588 68.1591 138.358 68.4403C138.129 68.7216 138 69.1027 138 69.5C138 69.8973 138.129 70.2784 138.358 70.5597C138.588 70.8409 138.899 70.9993 139.224 71H171.776C172.101 70.9993 172.412 70.8409 172.642 70.5597C172.871 70.2784 173 69.8973 173 69.5C173 69.1027 172.871 68.7216 172.642 68.4403C172.412 68.1591 172.101 68.0007 171.776 68H139.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M193.224 68C192.899 68.0007 192.588 68.1591 192.358 68.4403C192.129 68.7216 192 69.1027 192 69.5C192 69.8973 192.129 70.2784 192.358 70.5597C192.588 70.8409 192.899 70.9993 193.224 71H225.776C226.101 70.9993 226.412 70.8409 226.642 70.5597C226.871 70.2784 227 69.8973 227 69.5C227 69.1027 226.871 68.7216 226.642 68.4403C226.412 68.1591 226.101 68.0007 225.776 68H193.224Z"
        fill="#E6E6E6"
      />
      <path
        d="M193.217 80C192.894 80.0005 192.584 80.1061 192.356 80.2935C192.128 80.481 192 80.7351 192 81C192 81.2649 192.128 81.519 192.356 81.7064C192.584 81.8939 192.894 81.9995 193.217 82H207.783C208.106 81.9995 208.416 81.8939 208.644 81.7064C208.872 81.519 209 81.2649 209 81C209 80.7351 208.872 80.481 208.644 80.2935C208.416 80.1061 208.106 80.0005 207.783 80H193.217Z"
        fill="#6C63FF"
      />
      <path
        d="M34.5 85C40.8513 85 46 79.8513 46 73.5C46 67.1487 40.8513 62 34.5 62C28.1487 62 23 67.1487 23 73.5C23 79.8513 28.1487 85 34.5 85Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M26.7649 62C25.1684 62.0001 23.6061 61.5333 22.2672 60.656C20.9282 59.7788 19.8699 58.5287 19.2203 57.0572L0.941587 15.5211C0.336095 14.1453 0.0158632 12.659 0.000574557 11.1536C-0.0147141 9.64824 0.275263 8.15563 0.852683 6.76752C1.4301 5.3794 2.28277 4.1251 3.35826 3.08173C4.43375 2.03836 5.70936 1.22795 7.10666 0.700315C8.50397 0.172682 9.99345 -0.0610256 11.4836 0.0135585C12.9737 0.0881426 14.4329 0.469442 15.7716 1.13402C17.1103 1.7986 18.3002 2.73241 19.268 3.87806C20.2359 5.02371 20.9614 6.357 21.3998 7.79596L34.6358 51.2364C35.0148 52.4798 35.0994 53.7956 34.883 55.078C34.6666 56.3604 34.1551 57.5737 33.3896 58.6205C32.6242 59.6672 31.6261 60.5183 30.4756 61.1052C29.325 61.6921 28.0541 61.9986 26.7649 62H26.7649Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M272.596 148.396C276.793 151.764 282.925 151.093 286.293 146.897C289.661 142.7 288.99 136.568 284.793 133.2C280.597 129.832 274.465 130.503 271.097 134.7C267.729 138.896 268.4 145.028 272.596 148.396Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M279.68 129.098C278.625 128.252 277.84 127.115 277.421 125.825C277.002 124.535 276.965 123.148 277.316 121.831L287.266 84.695C287.596 83.4648 288.172 82.313 288.961 81.3103C289.749 80.3076 290.732 79.4751 291.85 78.8642C292.967 78.2533 294.196 77.8767 295.46 77.7577C296.723 77.6387 297.996 77.7797 299.199 78.1721C300.402 78.5644 301.51 79.1999 302.455 80.0394C303.4 80.8789 304.162 81.9047 304.694 83.0537C305.226 84.2027 305.517 85.4506 305.549 86.7209C305.581 87.9911 305.353 89.2567 304.88 90.4399L290.588 126.161C290.179 127.183 289.538 128.097 288.714 128.83C287.891 129.562 286.91 130.093 285.849 130.378C284.788 130.664 283.678 130.697 282.606 130.475C281.535 130.252 280.533 129.781 279.68 129.098L279.68 129.098Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

import { Box, Typography, useTheme } from "@mui/material";

import "./HighlightedText.scss";

type Props = {
  children: React.ReactNode;
};

const HighlightedText = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Box component="span" ml={1}>
      <Typography
        variant="subtitle2"
        className="highlighted-text"
        display="inline"
        sx={{ color: theme.palette.success.main }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default HighlightedText;

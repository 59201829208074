/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type DeploymentRequestListStatus =
  typeof DeploymentRequestListStatus[keyof typeof DeploymentRequestListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeploymentRequestListStatus = {
  pending: "pending",
  processing: "processing",
  completed: "completed",
  failed: "failed",
  cancelled_pending: "cancelled_pending",
  cancelled: "cancelled",
} as const;

export type LabelFilter = {
  key: string;
  value: string;
};

export const onFilterAdd =
  (setFilters: React.Dispatch<React.SetStateAction<LabelFilter[]>>) =>
  ({ key, value }: { key: string; value: string }) => {
    setFilters((prevFilters) => [...prevFilters, { key, value }]);
  };

export const onFilterRemove =
  (setFilters: React.Dispatch<React.SetStateAction<LabelFilter[]>>) =>
  (key: string, value: string) => {
    setFilters((prevFilters) =>
      prevFilters.filter(
        (prevFilter) => prevFilter.key !== key && prevFilter.value !== value
      )
    );
  };

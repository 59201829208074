import { Box, useTheme } from "@mui/material";
import { isEmpty, isObject } from "lodash";

import { spacing } from "assets/styles/theme";
import { ARRAY_FILE_TYPE } from "libs/constants/constants";
import {
  destructFilePath,
  isStorageLink,
  mapStorageLinkToAppLink,
} from "pages/organizations/:organizationName/projects/:projectName/storage/utils";

import { CopyToClipboardButton, Link } from "components/atoms";

import "./ResultsField.scss";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  DeploymentOutputFieldCreate,
  DeploymentRequestSingleDetailResult,
  InputOutputFieldDetail,
  InputOutputFieldList,
} from "libs/data/models";

type ResultsFieldProps = {
  fields?:
    | InputOutputFieldList[]
    | InputOutputFieldDetail[]
    | DeploymentOutputFieldCreate[];
  data?: DeploymentRequestSingleDetailResult;
  error?: boolean;
  organizationName: string;
  projectName: string;
};

const parseRequestData = (data: string | { [key: string]: any }) => {
  try {
    if (isObject(data)) {
      return data;
    }
    if (typeof data === "string") {
      return JSON.parse(data);
    }
  } catch (error) {
    return;
  }
};

const ResultsFieldWrapper = ({
  children,
  contentToCopy,
}: {
  children: React.ReactNode;
  contentToCopy: string;
}) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box position="relative">
      <pre
        className="results-field"
        style={{
          backgroundColor: theme.palette.background.codeBlock,
        }}
      >
        <Box position="absolute" top={spacing[4]} right={0}>
          <CopyToClipboardButton
            defaultLabel="Copy"
            contentToCopy={contentToCopy}
            size="small"
          />
        </Box>
        {children}
      </pre>
    </Box>
  );
};

const ResultsField = ({
  fields = [],
  data,
  organizationName,
  projectName,
}: ResultsFieldProps) => {
  const parsedData = data && parseRequestData(data);

  // no data
  if (!data) return null;

  // result is plain, can't parse as an object
  if (!parsedData) {
    return (
      <ResultsFieldWrapper contentToCopy={data as unknown as string}>
        {data as unknown as string}
      </ResultsFieldWrapper>
    );
  }

  // If data is an empty object or there are no fields (the object was removed). Try to display the data without doing anything fancy.
  if (!fields?.length || isEmpty(data)) {
    return (
      <ResultsFieldWrapper contentToCopy={JSON.stringify(parsedData, null, 2)}>
        {JSON.stringify(parsedData, null, 2)}
      </ResultsFieldWrapper>
    );
  }

  // object mode
  return (
    <ResultsFieldWrapper contentToCopy={JSON.stringify(parsedData, null, 2)}>
      <>
        {"{"}
        {fields.map(({ name, data_type }, key) => {
          const cleanString = (
            JSON.stringify(parsedData?.[name as string]) ?? ""
          ).replace(/(^"|"$)/g, "");

          return (
            <Box key={key} className="results-field__row">
              {data_type === ARRAY_FILE_TYPE ? (
                <>
                  {`"${name}": `}
                  {"["}
                  {parsedData?.[name as string]?.map?.(
                    (item: string, idx: number) => (
                      <>
                        {idx === 0 && <br />}
                        <Box
                          style={{
                            marginLeft: "30px",
                            marginRight: "10px",
                          }}
                        >
                          &quot;
                          <Link
                            key={item}
                            to={{
                              ...location,
                              state: {
                                selectedFile: destructFilePath(item)?.file,
                              },
                              pathname: mapStorageLinkToAppLink(
                                item,
                                organizationName,
                                projectName
                              ),
                            }}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100px",
                            }}
                          >
                            {item}
                          </Link>
                          &quot;
                        </Box>
                        {idx !== parsedData?.[name as string]?.length - 1 &&
                          ","}
                      </>
                    )
                  )}
                  {"]"}
                </>
              ) : isStorageLink(cleanString) ? (
                <>
                  {`"${name}": `}
                  &quot;
                  <Link
                    to={{
                      ...location,
                      state: {
                        selectedFile: destructFilePath(cleanString)?.file,
                      },
                      pathname: mapStorageLinkToAppLink(
                        cleanString,
                        organizationName,
                        projectName
                      ),
                    }}
                  >
                    {cleanString}
                  </Link>
                  &quot;
                  {name === fields[fields.length - 1].name ? "" : ","}
                </>
              ) : (
                `"${name}": ${JSON.stringify(
                  parsedData?.[name as string],
                  null,
                  2
                )}${name === fields[fields.length - 1].name ? "" : ","}`
              )}
            </Box>
          );
        })}
        {"}"}
      </>
    </ResultsFieldWrapper>
  );
};

export default ResultsField;

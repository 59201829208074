import { Box } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { NoData } from "components/atoms";

type EmptyTableProps = {
  text?: string;
};

export const EmptyTable = ({ text }: EmptyTableProps) => {
  return (
    <tbody>
      <tr
        style={{
          display: "table-cell",
          position: "relative",
          left: "40%",
          width: "20%",
          minWidth: spacing[120],
          height: spacing[220],
          paddingTop: spacing[32],
          overflow: "visible",
        }}
      >
        <Box component={({ ...props }) => <td {...props} />}>
          <NoData height={spacing[164]} text={text} />
        </Box>
      </tr>
    </tbody>
  );
};

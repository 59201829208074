import { Typography } from "@mui/material";

import type { TypographyProps } from "@mui/material";

export const ListItemKey = (props: TypographyProps) => (
  <Typography
    variant="h5"
    style={{ flexShrink: 0, marginRight: 50 }}
    {...props}
  />
);

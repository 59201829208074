import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { FIELD_LABEL_KEY } from "libs/constants/fields";
import { labelKeyOptions } from "libs/utilities/labels-mapping";

import type { KeyboardEventHandler } from "react";

type LabelKeyFieldProps = {
  name?: string;
  handleOnPressEnter?: KeyboardEventHandler;
  loading?: boolean;
  error?: string;
};

export const LabelKeyField = ({
  handleOnPressEnter,
  name = FIELD_LABEL_KEY,
  error,
  loading,
  ...props
}: LabelKeyFieldProps) => {
  return (
    <AutoCompleteSelectHookForm
      name={name}
      id={name}
      label="Key"
      options={labelKeyOptions[0].options}
      groupBy={() => "Suggested values"}
      placeholder={`Ex: "type"`}
      freeSolo
      error={error}
      showErrors={false}
      handleOnKeyDown={handleOnPressEnter}
      loading={loading}
      {...props}
    />
  );
};

import { spacing } from "assets/styles/theme";
import { Alert } from "components/atoms/Alert/Alert";

import type { AlertProps } from "components/atoms/Alert/types";

export const ErrorAlert = ({ children, style = {} }: AlertProps) => (
  <Alert severity="error" style={{ marginBottom: spacing[16], ...style }}>
    {children}
  </Alert>
);

import { Grid } from "@mui/material";

import {
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";

import { FormTextField } from "components/atoms";
import { FormSection } from "components/molecules";

import type { DeploymentVersionDetail } from "libs/data/models";

type DeploymentVersionAdvancedParametersProps = {
  version?: DeploymentVersionDetail;
};

export const DeploymentVersionAdvancedParameters = ({
  version,
}: DeploymentVersionAdvancedParametersProps) => {
  return (
    <FormSection
      title="Scaling & Resource settings"
      description={explanations.deployments.versions.scaling}
    >
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            label="Minimum number of instances"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.deployments.versions.minInstances}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            label="Maximum number of instances"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.deployments.versions.maxInstances}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_IDLE_TIME}
            id={FIELD_DEPLOYMENT_VERSION_IDLE_TIME}
            label="Maximum idle time (seconds)"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_IDLE_TIME]}
            type="number"
            InputProps={{
              inputProps: { min: 10 },
            }}
            tooltip={explanations.deployments.versions.maxIdleTime}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};

import { FormHelperText, Grid, Typography, useTheme } from "@mui/material";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { BOOLEAN_TYPE, FILE_TYPE } from "libs/constants/constants";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { isArrayHasData } from "libs/utilities/utils";
import { widgetsMapping } from "libs/utilities/widgets-helpers";

import {
  Accordion,
  Checkbox,
  FormTextField,
  InfoTooltip,
} from "components/atoms";
import { FormSection } from "components/molecules";

const FieldWidgetSelector = ({ fieldName, dataType, widget, prefix }) => {
  const theme = useTheme();

  const { errors, clearErrors, setValue, control } = useFormContext();
  const error = get(errors, `${prefix}Widgets.${fieldName}.configuration`);

  const [configurations, setConfigurations] = useState([]);

  const { projectName } = useParams();
  const { data: buckets } = useBucketsList(projectName);

  const bucketOptions = useMemo(
    () =>
      buckets?.map((bucket) => ({ label: bucket.name, value: bucket.name })) ||
      [],
    [buckets]
  );

  const defaultType = useMemo(() => {
    const existing = widgetsMapping[prefix][dataType]?.find(
      (item) => item.name === widget?.type
    );
    if (existing) {
      return { label: existing.label, value: existing.name };
    }

    return null;
  }, [dataType, prefix, widget]);

  const bucket = useWatch({
    name: `${prefix}Widgets.${fieldName}.configuration.bucket`,
    control,
    defaultValue: widget?.configuration?.bucket ?? bucketOptions?.[0]?.value,
  });

  useEffect(() => {
    let configs = widgetsMapping[prefix][dataType]?.find(
      (item) => item.name === defaultType?.value
    )?.configuration;
    setConfigurations(configs || []);

    return () => {
      setConfigurations([]);
    };
  }, [dataType, prefix, defaultType]);

  const onTypeChange = (selectedType) => {
    clearErrors(`${prefix}Widgets.${fieldName}.configuration`);
    if (selectedType) {
      let configs = widgetsMapping[prefix][dataType].find(
        (item) => item.name === selectedType.value
      )?.configuration;
      setConfigurations(configs || []);
    } else {
      setConfigurations([]);
    }
  };

  const onBucketChange = (bucket) => {
    setValue(`${prefix}Widgets.${fieldName}.configuration.bucket`, bucket);
  };

  const getDefaultFieldValue = (value, name, fieldName, defaultValue) => {
    if (Array.isArray(value)) {
      return JSON.stringify(value);
    }

    return value !== undefined && value !== null
      ? value
      : name === "label"
      ? fieldName
      : defaultValue;
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormSection
          title={fieldName}
          description={
            <Typography variant="body2">
              Data type: <b>{dataType}</b>
            </Typography>
          }
        >
          <AutoCompleteSelectHookForm
            name={`${prefix}Widgets.${fieldName}.type`}
            options={widgetsMapping[prefix][dataType]?.map(
              ({ name, label }) => ({
                label,
                value: name,
              })
            )}
            defaultValue={defaultType}
            placeholder="Select..."
            onChange={onTypeChange}
            isClearable
          />
          {isArrayHasData(configurations) && (
            <Accordion title="Settings" titleVariant="h5">
              <Grid container spacing={2}>
                {error && (
                  <Grid item xs={12}>
                    <FormHelperText
                      style={{ color: theme.palette.error.main }}
                      error={!!error}
                      hidden={!error}
                    >
                      Something is wrong with one of the settings
                    </FormHelperText>
                  </Grid>
                )}
                {configurations.map(
                  (
                    { label, name, type, rules, defaultValue, data_type, help },
                    key
                  ) =>
                    data_type === BOOLEAN_TYPE ? (
                      <Checkbox
                        key={key}
                        name={`${prefix}Widgets.${fieldName}.configuration.${name}`}
                        color="primary"
                        label={label}
                        defaultChecked={!!widget?.configuration?.[name]}
                      />
                    ) : prefix === "input" && data_type === FILE_TYPE ? (
                      <Grid item container display="flex" alignItems="center">
                        <Grid item xs={11}>
                          <AutoCompleteSelectHookForm
                            name={`${prefix}Widgets.${fieldName}.configuration.bucket`}
                            label={label}
                            options={bucketOptions}
                            disabled={!bucketOptions?.length}
                            value={bucket}
                            onChange={(option) => onBucketChange(option.value)}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <InfoTooltip>{help}</InfoTooltip>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <FormTextField
                          key={key}
                          label={label}
                          name={`${prefix}Widgets.${fieldName}.configuration.${name}`}
                          type={type}
                          rules={rules}
                          defaultValue={getDefaultFieldValue(
                            widget?.configuration?.[name],
                            name,
                            fieldName,
                            defaultValue
                          )}
                          tooltip={help}
                        />
                      </Grid>
                    )
                )}{" "}
              </Grid>
            </Accordion>
          )}
        </FormSection>
      </Grid>
    </React.Fragment>
  );
};

FieldWidgetSelector.propTypes = {
  prefix: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  widget: PropTypes.object,
};

export default FieldWidgetSelector;

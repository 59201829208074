import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { useGoogleAnalytics } from "libs/hooks";

import { EnvVarsOverview } from "components/organisms";

import { TRAINING_DEPLOYMENT } from "../../constants";

export const ExperimentEnvVars = () => {
  useGoogleAnalytics();

  const { experimentName } =
    useParams<{
      experimentName: string;
    }>();

  const match = useRouteMatch();

  return (
    <>
      <BreadcrumbsItem to={match.url}>Environment variables</BreadcrumbsItem>
      <EnvVarsOverview
        deploymentName={TRAINING_DEPLOYMENT}
        deploymentVersionName={experimentName}
        isCopyable={false}
        includeInheritance={false}
      />
    </>
  );
};

import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_WEBHOOK_NAME } from "libs/constants/fields";
import { useWebHookCreate } from "libs/data/customized/webhooks/useWebHookCreate";
import { gtmEvent } from "libs/third-parties";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { WebHookBaseForm } from "./WebHookBaseForm";

import type { FormikHelpers } from "formik";
import type { WebhookCreate } from "libs/data/models";

export const WebHookCreate = () => {
  const { projectName, organizationName } =
    useParams<{ projectName: string; organizationName: string }>();
  const history = useHistory();

  const webhooksCreate = useWebHookCreate(projectName);

  const handleOnSubmit = async (
    data: WebhookCreate,
    actions: FormikHelpers<WebhookCreate>
  ) => {
    try {
      await webhooksCreate(data);

      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .monitoring.webHooks[":webHookName"](data[FIELD_WEBHOOK_NAME])
          .index()
      );
    } finally {
      actions.setSubmitting(false);
    }
    gtmEvent("webhook_create", {
      event_category: "webhook_create",
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Create new Webhook" />
      <FormWrapper>
        <WebHookBaseForm handleOnSubmit={handleOnSubmit} />
      </FormWrapper>
    </PageContainer>
  );
};

import { Box } from "@mui/material";

import { FormDialog } from "components/atoms/Dialog/FormDialog";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { FIELD_ERROR_POLICY } from "libs/constants/fields";
import validators from "libs/utilities/validators";

import { DialogDescription, ExternalLink } from "components/atoms";

import type { FormValuesType } from "./SharedDeploymentPipelineComponents/NodeDeploymentPipeline";
import type {
  PipelineVersionObjectConfigurationList,
  PipelineVersionObjectList,
} from "libs/data/models";

export type NodeErrorPolicyDialogProps = {
  pipelineObject: PipelineVersionObjectList;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormValuesType) => void;
};

const errorPolicyOptions = [
  { label: "Raise", value: "raise" },
  { label: "Stop", value: "stop" },
  { label: "Continue", value: "continue" },
];
const rules = { required: validators.required.message() };

export const NodeErrorPolicyDialog = ({
  pipelineObject,
  isOpen,
  onClose,
  onSubmit,
}: NodeErrorPolicyDialogProps) => {
  const {
    configuration,
  }: { configuration?: PipelineVersionObjectConfigurationList } =
    pipelineObject;

  const handleErrorPolicyUpdate = (data: FormValuesType) => {
    onSubmit(data);
    onClose();
  };

  return (
    <FormDialog
      open={isOpen}
      onClose={() => onClose()}
      onSubmit={handleErrorPolicyUpdate}
      title="On error policy"
    >
      <DialogDescription>
        The on error policy dictates what happens when this object has an error.
        It can either stop, raise or continue upon error. For more info see the{" "}
        <ExternalLink href={DOC_LINKS.ERROR_POLICY}>documentation</ExternalLink>
      </DialogDescription>
      <Box display="flex" mb={2}>
        <AutoCompleteSelectHookForm
          name={FIELD_ERROR_POLICY}
          isSearchable={false}
          label="On Error Policy"
          options={errorPolicyOptions}
          maxMenuHeight={100}
          defaultValue={
            configuration?.on_error
              ? errorPolicyOptions.find(
                  (option) => option.value === configuration.on_error
                )
              : errorPolicyOptions[0]
          }
          rules={rules}
        />
      </Box>
    </FormDialog>
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  metricsDelete,
  useMetricsList,
} from "libs/data/endpoints/metrics/metrics";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useMetricsDelete = (projectName: string) => {
  const { mutate } = useMetricsList(projectName);
  const dispatch = useDispatch();

  return useCallback(
    async (metricName: string) => {
      return metricsDelete(projectName, metricName)
        .then(async (result) => {
          dispatch(createSuccessNotification("The metric was deleted"));
          await mutate();

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [dispatch, mutate, projectName]
  );
};

import { Grid, Pagination } from "@mui/material";
import { useState } from "react";

import { ResultsField } from "components/molecules";

import type {
  DeploymentOutputFieldCreate,
  DeploymentRequestSingleDetailResult,
  InputOutputFieldDetail,
  InputOutputFieldList,
} from "libs/data/models";

type Props = {
  fields?:
    | InputOutputFieldList[]
    | InputOutputFieldDetail[]
    | DeploymentOutputFieldCreate[];
  data:
    | DeploymentRequestSingleDetailResult[]
    | DeploymentRequestSingleDetailResult;
  itemsPerPage?: number;
  error?: boolean;
  organizationName: string;
  projectName: string;
};

const ResultsFieldWithPagination = ({
  fields,
  data,
  itemsPerPage = 5,
  error = false,
  organizationName,
  projectName,
  ...props
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (_: any, value: any) => {
    setCurrentPage(value);
  };

  return (
    <Grid container>
      {currentItems.map(
        (data: DeploymentRequestSingleDetailResult, key: number) =>
          Array.isArray(data) ? (
            <Grid key={key} item xs={12}>
              {data.map((subData, subKey) => (
                <ResultsField
                  organizationName={organizationName}
                  projectName={projectName}
                  key={`${key}-${subKey}`}
                  fields={fields}
                  data={subData}
                  error={error}
                  {...props}
                />
              ))}
            </Grid>
          ) : (
            <Grid key={key} item xs={12}>
              <ResultsField
                organizationName={organizationName}
                projectName={projectName}
                fields={fields}
                data={data}
                error={error}
                {...props}
              />
            </Grid>
          )
      )}
      {data?.length > itemsPerPage && (
        <Grid item xs={12}>
          <Pagination
            sx={{
              "& .MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
            count={Math.ceil(data?.length / itemsPerPage)}
            size="small"
            color="secondary"
            page={currentPage}
            onChange={handleChange}
            showFirstButton={data?.length > 50}
            showLastButton={data?.length > 50}
            defaultPage={1}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResultsFieldWithPagination;

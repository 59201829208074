import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { borderRadius, borders, spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { FIELD_NAME, FIELD_TYPE } from "libs/constants/fields";
import { fieldTypes } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";
import {
  destructFilePath,
  mapStorageLinkToAppLink,
} from "pages/organizations/:organizationName/projects/:projectName/storage/utils";

import {
  DialogActions,
  FormTextField,
  Link,
  PrimaryButton,
} from "components/atoms";
import { FileInputField } from "components/molecules";

import type {
  NewVariableDataType,
  NodeDataType,
} from "components/templates/Diagram/types";
import type { Node } from "reactflow";

type VariableFormProps = {
  onSubmit: (data: NewVariableDataType) => void;
  node?: Node<NodeDataType>;
  isDialog?: boolean;
};
export const VariableForm = ({
  onSubmit,
  node,
  isDialog = false,
}: VariableFormProps) => {
  const formMethods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit, watch } = formMethods;
  const fieldType = watch(FIELD_TYPE)?.value;

  const nodeValue =
    node?.data?.pipelineObject.configuration?.output_values?.[0].value;

  const defaultValue =
    node?.data?.pipelineObject.configuration?.output_fields?.[0];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      padding={isDialog ? undefined : spacing[16]}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box minHeight={spacing[112]} marginBottom={spacing[8]}>
            <Box display={"flex"} gap={4}>
              <Grid item style={{ flex: 1 }}>
                <FormTextField
                  name={FIELD_NAME}
                  label="Name"
                  rules={{
                    required: validators.required.message(FIELD_NAME),
                    pattern: {
                      value: validators.name.pattern,
                      message: validators.name.message(FIELD_NAME),
                    },
                  }}
                  defaultValue={defaultValue?.name ?? ""}
                  style={{
                    margin: 0,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <AutoCompleteSelectHookForm
                  name={FIELD_TYPE}
                  label="Type"
                  options={fieldTypes}
                  defaultValue={
                    defaultValue?.data_type
                      ? fieldTypes.find(
                          (type) => type.value === defaultValue.data_type
                        )
                      : fieldTypes[0]
                  }
                  isSearchable
                />
              </Grid>
            </Box>
            {fieldType === "file" ? (
              <Box
                style={{
                  border: `${borders.tertiary}`,
                  borderRadius: borderRadius[5],
                  marginTop: spacing[16],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                {nodeValue?.startsWith("ubiops-file") && (
                  <Link
                    to={{
                      ...location,
                      pathname: mapStorageLinkToAppLink(
                        nodeValue as unknown as string,
                        node?.data?.organizationName || "",
                        node?.data?.projectName || ""
                      ),
                      state: {
                        selectedFile: destructFilePath(
                          nodeValue as unknown as string
                        )?.file,
                      },
                    }}
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      margin: `${spacing[12]} ${spacing[32]} 0`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {nodeValue as unknown as string}
                  </Link>
                )}
                <FileInputField label="" name="file_value" />
              </Box>
            ) : (
              <FormTextField
                name="field_value"
                label="Field Value"
                style={{ marginTop: spacing[16] }}
                defaultValue={nodeValue}
                // @ts-expect-error
                rules={{
                  ...validators[fieldType as keyof typeof validators],
                }}
              />
            )}
          </Box>
          {isDialog ? (
            <DialogActions>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </DialogActions>
          ) : (
            <Box marginTop={spacing[12]}>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </Box>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

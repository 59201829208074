import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { DOC_LINKS } from "libs/constants/documentation-links";
import {
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT,
  FIELD_DEPLOYMENT_VERSION_PROTOCOL,
  FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT,
  FIELD_STATIC_IP,
} from "libs/constants/fields";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";
import { useGetCurrentOrganization } from "store/features";

import {
  ExternalLink,
  FormTextField,
  InfoAlert,
  Radio,
} from "components/atoms";
import { FormSection } from "components/molecules";

import { salesContact } from "./constants";

export const PortForwarding = () => {
  const theme = useTheme();
  const organization = useGetCurrentOrganization();
  const { setValue, watch, register } = useFormContext();
  const { data: features } = useOrganizationsFeaturesGet(
    organization?.name || ""
  );

  const staticIpEnabled = watch(FIELD_STATIC_IP);
  const protocol = watch(FIELD_DEPLOYMENT_VERSION_PROTOCOL);

  const disabledForOrganization =
    organization?.subscription === "free" || !features?.feature_port_forwarding;

  const disabled = disabledForOrganization || staticIpEnabled;

  return (
    <FormSection
      title="Port forwarding"
      description={explanations.deployments.versions.portForwarding}
    >
      <>
        <InfoAlert style={{ width: "100%" }}>
          <Grid>
            {disabledForOrganization && (
              <>
                <Grid item>
                  This feature is not included in your current subscription.
                </Grid>
                <ExternalLink
                  color={`${theme.palette.secondary.main} !important`}
                  href={encodeURI(
                    `mailto:${salesContact.email}?subject=${salesContact.subject}&body=${salesContact.body}`
                  )}
                >
                  Talk to our team to upgrade and gain access
                </ExternalLink>
              </>
            )}
            {!disabledForOrganization && staticIpEnabled && (
              <>
                <Grid item>
                  This feature is not compatible with static IP addresses.
                  Please disable it to use port forwarding.
                </Grid>
              </>
            )}
            {!disabledForOrganization && (
              <>
                <Grid item>
                  This feature is only compatible with dedicated instance types.
                </Grid>
                <ExternalLink
                  color={`${theme.palette.secondary.main} !important`}
                  href={DOC_LINKS.PORT_FORWARDING}
                >
                  Read more about the supported instance types here
                </ExternalLink>
              </>
            )}
          </Grid>
        </InfoAlert>
        <FormTextField
          name={FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT}
          id={FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT}
          disabled={disabled}
          label="Deployment port"
          type="number"
          InputProps={{
            inputProps: { min: 0, placeholder: "Ex: 8080" },
          }}
        />
        <FormTextField
          name={FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT}
          id={FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT}
          disabled={disabled}
          label="Public port"
          type="number"
          InputProps={{
            inputProps: { min: 0, placeholder: "Ex: 8080" },
          }}
        />
        <Typography variant="h3">Protocol</Typography>
        <Box display="flex">
          <Radio
            id="tcp"
            value="tcp"
            name={FIELD_DEPLOYMENT_VERSION_PROTOCOL}
            register={register}
            onChange={(event) =>
              setValue(FIELD_DEPLOYMENT_VERSION_PROTOCOL, event?.target.value)
            }
            label="TCP"
            tooltip="Use the TCP protocol for port forwarding."
            defaultChecked={protocol === "tcp" || !protocol}
            disabled={disabled}
            style={{ width: "40%", minWidth: "max-content" }}
          />
          <Radio
            id="udp"
            value="udp"
            name={FIELD_DEPLOYMENT_VERSION_PROTOCOL}
            register={register}
            onChange={(event) =>
              setValue(FIELD_DEPLOYMENT_VERSION_PROTOCOL, event?.target.value)
            }
            label="UDP"
            tooltip="Use the UDP protocol for port forwarding."
            defaultChecked={protocol === "udp"}
            disabled={disabled}
          />
        </Box>
      </>
    </FormSection>
  );
};

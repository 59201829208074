import { useCallback, useEffect } from "react";

import { timeSeriesDataList } from "libs/data/endpoints/metrics/metrics";

import type {
  TimeSeriesDataList,
  TimeSeriesDataListParams,
} from "libs/data/models";

type CachedMetrics = {
  [key: string]: TimeSeriesDataList;
};

let metricCache = {} as CachedMetrics;

export const useCachedMetrics = (forceRefresh: boolean) => {
  useEffect(() => {
    metricCache = {} as CachedMetrics;
  }, [forceRefresh]);

  return useCallback(
    async (projectName: string, payload: TimeSeriesDataListParams) => {
      const cacheId = JSON.stringify({ projectName, ...payload });
      if (metricCache[cacheId]) {
        return metricCache[cacheId];
      } else {
        const metrics = await timeSeriesDataList(projectName, payload);
        metricCache[cacheId] = metrics;

        return metrics;
      }
    },
    []
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  environmentRevisionsFileUpload,
  environmentsCreate,
  useEnvironmentsList,
} from "libs/data/endpoints/environments/environments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type {
  EnvironmentRevisionsFileUploadBody,
  EnvironmentCreate,
} from "libs/data/models";

export const useEnvironmentCreate = (projectName: string) => {
  const { mutate } = useEnvironmentsList(
    projectName,
    {
      environment_type: "custom",
    },
    {
      swr: {
        swrKey: `/projects/${projectName}/environments`,
      },
    }
  );
  const dispatch = useDispatch();

  return useCallback(
    async (
      data: EnvironmentCreate,
      uploadBody: EnvironmentRevisionsFileUploadBody
    ) => {
      try {
        const result = await environmentsCreate(projectName, data);
        await environmentRevisionsFileUpload(
          projectName,
          result.name,
          uploadBody
        );
        await mutate();
        dispatch(createSuccessNotification(`Environment has been created`));

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, mutate, projectName]
  );
};

import { Box } from "@mui/material";

import { borders } from "assets/styles/theme";
import { DialogHeaderTitle } from "components/atoms/Dialog/DialogHeaderTitle";

import type { ReactNode } from "react";

interface DialogHeaderProps {
  header?: ReactNode;
  title?: string;
}

export const DialogHeader = ({ header, title }: DialogHeaderProps) => (
  <Box borderBottom={borders.primary} paddingTop={2} paddingBottom={1}>
    {header ? header : <DialogHeaderTitle>{title}</DialogHeaderTitle>}
  </Box>
);

import { useState } from "react";

import type { AddedEntity, Entity } from "./types";
import type {
  DeploymentVersionDetail,
  PipelineVersionDetail,
} from "libs/data/models";

export const useSelectAllEntities = (
  entities: Entity,
  fetchVersion: (name: string) => Promise<{
    [key: string]: PipelineVersionDetail | DeploymentVersionDetail;
  }>,
  setSelectedEntities: (entities: AddedEntity[]) => void,
  setEntities: (entity: Entity) => void
) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectAll = async (isAdd: boolean) => {
    if (!isAdd) {
      setSelectedEntities([]);
    } else {
      setIsLoading(true);
      const names = Object.keys(entities || {});
      const versions = await Promise.all(
        names.map((name) => fetchVersion(name))
      );

      const versionsWithName = versions
        .map((versionObject) => {
          const defaultVersionNames = Object.keys(versionObject).filter(
            (versionName) => versionObject[versionName]?.default
          );

          return defaultVersionNames.map((version) => ({
            version,
            name:
              // either a pipeline or a deployment
              // @ts-ignore
              versionObject[version].pipeline ||
              // @ts-ignore
              versionObject[version].deployment,
          }));
        })
        .flat();
      // @ts-ignore
      const newEntity = versions.reduce((acc, curr, i) => {
        const name = names[i];
        if (!entities) return;

        return {
          ...acc,
          [name]: {
            ...entities[name],
            versions: curr,
          },
        };
      }, {});

      setSelectedEntities(versionsWithName);
      setEntities(newEntity);
      setIsLoading(false);
    }
  };

  return { selectAll, isLoading };
};

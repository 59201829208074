/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type ScheduleCreateObjectType =
  typeof ScheduleCreateObjectType[keyof typeof ScheduleCreateObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduleCreateObjectType = {
  deployment: "deployment",
  pipeline: "pipeline",
} as const;

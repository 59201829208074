import styled from "@emotion/styled";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Typography, useTheme } from "@mui/material";

import { borderRadius, spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";

import { ExternalLink, Icon as IconWrapper } from "components/atoms";

import {
  DIAGRAM_DRAG_ID_OPERATOR,
  operators,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
  OBJECT_REFERENCE_TYPE_OPERATOR,
} from "../constants";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { PipelineVersionObjectConfigurationList } from "libs/data/models";
import type { DragEvent } from "react";

export const SidebarLeftOperatorsTab = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          maxWidth: 400,
          textAlign: "center",
          marginBottom: spacing[24],
        }}
      >
        Drag and drop any of your operators onto the canvas to use in your
        pipeline. Learn more about operators in our{" "}
        <ExternalLink href={DOC_LINKS.OPERATORS}>documentation</ExternalLink>.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        mt={spacing[20]}
      >
        {operators.map(
          ({ id, title, subtitle, icon: Icon, defaultConfig }, index) => (
            <OperatorContainer
              backgroundColor={theme.palette.pipelineDiagram.operatorContainer}
              backgroundHover={theme.palette.pipelineDiagram.operatorHover}
              key={id}
              draggable
              onDragStart={(event: DragEvent<HTMLDivElement>) => {
                event.dataTransfer.setData(
                  DIAGRAM_DRAG_ID_OPERATOR,
                  JSON.stringify(getObjectFromOperator(id, defaultConfig))
                );
              }}
              display="flex"
              marginTop={index === 0 ? undefined : spacing[8]}
            >
              <IconWrapper
                component={DragIndicatorIcon}
                style={{
                  alignSelf: "center",
                  color: theme.palette.pipelineDiagram.cardDragIndicator,
                }}
              />
              <Box width="64px">
                <Icon height={spacing[64]} width={spacing[64]} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                style={{ flex: 1, marginLeft: spacing[4] }}
              >
                <Typography
                  variant="h6"
                  style={{ marginTop: spacing[4] }}
                  sx={{ color: theme.palette.text.primary }}
                >
                  {title}
                </Typography>
                <Typography variant="caption" color="textPrimary">
                  {subtitle}
                </Typography>
              </Box>
            </OperatorContainer>
          )
        )}
      </Box>
    </>
  );
};

const OperatorContainer = styled(Box)<{
  backgroundColor: string;
  backgroundHover: string;
}>`
  padding: ${spacing[8]};
  border-radius: ${borderRadius[5]};
  cursor: pointer;
  user-select: none;
  z-index: 100;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.backgroundHover};
    cursor: move;
  }
`;

const getObjectFromOperator = (
  id: string,
  defaultConfig?: PipelineVersionObjectConfigurationList
) => ({
  id: id,
  name:
    id === OPERATOR_ONE_TO_MANY
      ? "create-subrequests"
      : id === OPERATOR_MANY_TO_ONE
      ? "collect-subrequests"
      : id,
  reference_type: OBJECT_REFERENCE_TYPE_OPERATOR,
  reference_name: id,
  version: null,
  input_type: "",
  output_type: "",
  input_fields: defaultConfig?.input_fields || [],
  output_fields: defaultConfig?.output_fields || [],
  defaultConfig,
});

import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";

import { PageTabs } from "components/atoms";

interface Props {
  children: React.ReactNode;
}

export const ImportsExportsContainer = ({ children }: Props) => {
  const { url } = useRouteMatch();

  const tabs = useMemo(
    () => [
      {
        link: `${url}/imports`,
        label: "Imports",
      },
      {
        link: `${url}/exports`,
        label: "Exports",
      },
    ],
    [url]
  );

  return (
    <DetailsContainer title="Imports & Exports">
      <PageTabs tabs={tabs}>{children}</PageTabs>
    </DetailsContainer>
  );
};

import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import {
  PROJECT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
} from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  usePipelinesGet,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";
import { useLogsUrl, useGoogleAnalytics } from "libs/hooks";
import { useGetPermissions } from "store/features/permissions";

import { Requests } from "components/organisms";

const PipelineVersionRequests = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();
  const { projectName, pipelineName, versionName } = useParams();
  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const { data: version } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      pipeline_name: pipelineName,
      pipeline_version: versionName,
      pipeline_request_id: "",
    },
  });

  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(PIPELINE_PERMISSIONS),
    pipelineName,
    "pipeline"
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Requests</BreadcrumbsItem>
      <Requests
        logsUrl={logsUrl}
        allowCreate={
          currentPermissions[PIPELINE_PERMISSIONS["version_request_create"]]
        }
        allowGet={
          currentPermissions[PIPELINE_PERMISSIONS["version_request_get"]]
        }
        allowLogs={projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
        allowDelete={
          currentPermissions[PIPELINE_PERMISSIONS["version_request_delete"]]
        }
        allowUpdate={
          currentPermissions[PIPELINE_PERMISSIONS["version_request_update"]]
        }
        requestParameters={{
          type: "pipeline",
          resourceName: pipelineName,
          inputFields: pipeline?.input_fields,
          outputFields: pipeline?.output_fields,
          resourceVersion: version?.version,
          inputType: pipeline?.input_type,
          outputType: pipeline?.output_type,
        }}
        version={version}
      />
    </>
  );
};

export default PipelineVersionRequests;

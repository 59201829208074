import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { deploymentVersionRequestsUpdate } from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

export const useRunCancel = (projectName: string, experimentName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (id: string, runName: string) => {
      try {
        await deploymentVersionRequestsUpdate(
          projectName,
          TRAINING_DEPLOYMENT,
          experimentName,
          id ?? "",
          {
            status: "cancelled",
          }
        );
        dispatch(
          createSuccessNotification(`Run ${runName} is being cancelled`)
        );
      } catch (err: any) {
        dispatch(createErrorNotification(`Run ${runName} cannot be cancelled`));
      }
    },
    [dispatch, projectName, experimentName]
  );
};

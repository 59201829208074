import { deploymentVersionRequestsBatchGet } from "libs/data/endpoints/deployment-requests/deployment-requests";

import type { TrainingRunParams } from "pages/organizations/:organizationName/projects/:projectName/training/experiments/util";

/**
 * This method is used to get deploymentVersionRequests across multiple deployments
 */
export const getWrappedDeploymentVersionRequestsBatch = (
  projectName: string,
  deploymentName: string,
  requests: TrainingRunParams[]
) => {
  const parsedDict: { [key: string]: string[] } = {};
  requests.forEach(({ id, version }: TrainingRunParams) => {
    const values = parsedDict[version] ?? [];
    parsedDict[version] = [...values, id];
  });

  return Promise.all(
    Object.keys(parsedDict).map((key: string) => {
      return deploymentVersionRequestsBatchGet(
        projectName,
        deploymentName,
        key,
        parsedDict[key]
      );
    })
  ).then((results) => results.flat());
};

import {
  TextareaAutosize,
  InputLabel,
  FormHelperText,
  useTheme,
} from "@mui/material";

import type { TextareaAutosizeProps } from "@mui/material";

import "./Textarea.scss";

export type ControlledTextareaProps = TextareaAutosizeProps & {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string | false;
};

export const ControlledTextarea = ({
  label,
  value,
  onChange,
  error,
  ...props
}: ControlledTextareaProps) => {
  const theme = useTheme();

  return (
    <div
      className={["textarea", !!error && "textarea--error"]
        .filter(Boolean)
        .join(" ")}
    >
      {label && (
        <InputLabel shrink className="textarea__label" error={!!error}>
          {label}
        </InputLabel>
      )}

      <TextareaAutosize
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="textarea__input"
        {...props}
        style={{
          borderColor: `${
            error ? theme.palette.error.main : theme.palette.primary.main
          }`,
          background: "inherit",
        }}
      />
      <FormHelperText error={!!error} hidden={!error}>
        {error}
      </FormHelperText>
    </div>
  );
};

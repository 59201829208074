import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { stringifyUrl } from "query-string";
import { useContext, useMemo } from "react";

import { RequestResultSection } from "components/molecules/RequestResults/RequestResultSection";
import {
  getCreatedByFromOrigin,
  openDataInNewTab,
} from "components/organisms/RequestResultsDialog/utils";
import { FIELD_NAME } from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import {
  deploymentVersionRequestsGet,
  useDeploymentVersionRequestsGet,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useLogsUrl } from "libs/hooks";
import {
  calculateDuration,
  FULL_DATE_TIME_FORMAT,
  getFormattedDate,
  getTzAwareDate,
} from "libs/utilities/date-util";

import {
  Alert,
  DetailsDialogItem,
  Dialog,
  Divider,
  Link,
  Loader,
  NavLink,
  OverflowTooltip,
  StatusIcon,
} from "components/atoms";

import {
  trainingInputFields,
  trainingOutputFields,
  TRAINING_DEPLOYMENT,
} from "../../constants";

type RunResultsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  organizationName: string;
  projectName: string;
  experimentName: string;
  id: string;
  shouldFetchMetadaDataOnly: boolean;
};

export const RunResultsDialog = ({
  isOpen,
  onClose,
  organizationName,
  projectName,
  experimentName,
  id,
  shouldFetchMetadaDataOnly,
}: RunResultsDialogProps) => {
  const rootUrl = useContext(RootUrlContext);
  const { data, error } = useDeploymentVersionRequestsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    id,
    {
      metadata_only: shouldFetchMetadaDataOnly,
    }
  );

  const isLoading = !data && !error;

  const runName = useMemo(
    () => data?.request_data?.[FIELD_NAME],
    [data?.request_data]
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_name: TRAINING_DEPLOYMENT,
      deployment_version: experimentName,
      deployment_request_id: "",
    },
  });

  const requestUrl = stringifyUrl({
    url: logsUrl,
    query: {
      deployment_request_id: id,
      from_date: getFormattedDate(data?.time_created),
      to_date: moment(data?.time_completed).add(5, "minutes").toISOString(),
    },
  });

  const { createdBy, url: createdByUrl } = getCreatedByFromOrigin(
    {
      ...data?.origin,
      type: "deployment",
      deployment: TRAINING_DEPLOYMENT,
      deployment_version: experimentName,
      created_by: data?.origin?.created_by ?? "",
    },
    rootUrl
  );

  const handleViewHugeResults = async () => {
    const response = await deploymentVersionRequestsGet(
      projectName,
      TRAINING_DEPLOYMENT,
      experimentName,
      id
    );
    if (response) openDataInNewTab(response, true);
  };

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
      title={`Results for ${runName ?? ""}`}
    >
      {isLoading ? (
        <Box
          minHeight="300px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
        </Box>
      ) : (
        <Box>
          {data?.error_message && (
            <Alert severity="error">Error: {data?.error_message}</Alert>
          )}
          <DetailsDialogItem title="Run ID" isLoaded={!!id}>
            <Box display="flex" alignItems="center">
              <OverflowTooltip>{id}</OverflowTooltip>
              <Tooltip title="View logs">
                <IconButton
                  edge="end"
                  component={NavLink}
                  size="small"
                  to={requestUrl}
                  color="secondary"
                >
                  <LogsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </DetailsDialogItem>
          <DetailsDialogItem title="Experiment" isLoaded={!!data?.version}>
            <Link to={`${rootUrl}/training/experiments/${experimentName}`}>
              {data?.version}
            </Link>
          </DetailsDialogItem>
          <DetailsDialogItem title="Status" isLoaded={!!data?.status}>
            <StatusIcon label={data?.status} status={data?.status ?? ""} />
          </DetailsDialogItem>
          <DetailsDialogItem title="Created" isLoaded={!!data?.time_created}>
            <OverflowTooltip>
              {getTzAwareDate(data?.time_created).format(FULL_DATE_TIME_FORMAT)}
            </OverflowTooltip>
          </DetailsDialogItem>
          <DetailsDialogItem
            title="Completed"
            isLoaded={!!data?.time_completed}
          >
            <OverflowTooltip>
              {getTzAwareDate(data?.time_completed ?? undefined).format(
                FULL_DATE_TIME_FORMAT
              )}
            </OverflowTooltip>
          </DetailsDialogItem>
          <DetailsDialogItem title="Duration" isLoaded={!!data?.time_started}>
            <OverflowTooltip>
              {data?.time_completed && data?.time_started
                ? calculateDuration(data?.time_started, data?.time_completed)
                : "--"}
            </OverflowTooltip>
          </DetailsDialogItem>
          <DetailsDialogItem title="Created by" isLoaded={!!createdBy}>
            <OverflowTooltip>
              {createdByUrl ? (
                <Link to={createdByUrl}>{createdBy}</Link>
              ) : (
                createdBy
              )}
            </OverflowTooltip>
          </DetailsDialogItem>
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <RequestResultSection
              data={data?.request_data}
              result={data?.result}
              error={!!data?.error_message}
              inputFields={trainingInputFields}
              outputFields={trainingOutputFields}
              projectName={projectName}
              organizationName={organizationName}
              inputSize={data?.input_size}
              outputSize={data?.output_size}
              handleViewHugeResult={handleViewHugeResults}
            />
          </Grid>
        </Box>
      )}
    </Dialog>
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { requestSchedulesUpdate } from "libs/data/endpoints/request-schedules/request-schedules";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { ScheduleUpdate } from "libs/data/models";

export const useRequestScheduleUpdate = (projectName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (name: string, data: ScheduleUpdate) => {
      try {
        await requestSchedulesUpdate(projectName, name, data);
        dispatch(createSuccessNotification("Request schedule was updated."));
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, projectName]
  );
};

export const successStatuses = [
  "available",
  "success",
  "completed",
  "active",
  "create",
  "success",
  "running",
];

export const infoStatuses = [
  "processing",
  "confirmation",
  "confirmation_pending",
  "running",
  "updloading",
  "initialised",
  "initialising",
  "info",
  "queued",
];

export const errorStatuses = [
  "failed",
  "delete",
  "error",
  "cancelled_pending",
  "cancelled",
  "stopping",
];

export const secondaryStatuses = [
  "edit",
  "pending",
  "unavailable",
  "building",
  "scanning",
  "secondary",
];

export const primaryStatuses = ["primary", "all", "finished"];

export const warningStatuses = ["warning", "update", "alert"];

export const disabledStatuses = ["disabled", "none", "info"];

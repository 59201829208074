import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { ENV_NAMES, env } from "libs/env";

import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  OrganizationDetail,
  OrganizationFeature,
  OrganizationList,
  OrganizationSubscriptionList,
  ResourceUsage,
  OrganizationUsage,
} from "libs/data/models";
import type { RootState } from "store/store";

const initialState: {
  organizations: OrganizationList[];
  currentOrganization: OrganizationDetail | null;
  organizationFeatures: OrganizationFeature | null;
  organizationSubscription: OrganizationSubscriptionList[] | null;
  organizationUsage: OrganizationUsage | null;
  organizationResources: ResourceUsage | null;
  organizationCreditResetDates: { start_date: string; end_date: string } | null;
  activeSubscription: OrganizationSubscriptionList | null;
} = {
  organizations: [],
  currentOrganization: null,
  organizationFeatures: null,
  organizationSubscription: null,
  organizationCreditResetDates: null,
  organizationUsage: null,
  organizationResources: null,
  activeSubscription: null,
};

export const organizations = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<OrganizationDetail>) => {
      env.set(ENV_NAMES.LAST_SEEN_ORGANIZATION, action.payload?.name);
      state.currentOrganization = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<OrganizationList[]>) => {
      state.organizations = action.payload;
    },
    setOrganizationFeatures: (
      state,
      action: PayloadAction<OrganizationFeature>
    ) => {
      state.organizationFeatures = action.payload;
    },
    setOrganizationSubscription: (
      state,
      action: PayloadAction<OrganizationSubscriptionList[]>
    ) => {
      state.organizationSubscription = action.payload;
      state.activeSubscription =
        action.payload?.find((subscription) => subscription.active) || null;
    },
    setOrganizationUsage: (state, action: PayloadAction<OrganizationUsage>) => {
      state.organizationUsage = action.payload;
    },
    setOrganizationCreditResetDates: (
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) => {
      state.organizationCreditResetDates = action.payload;
    },
    setOrganizationResources: (state, action: PayloadAction<ResourceUsage>) => {
      state.organizationResources = action.payload;
    },
    addOrganization: (state, action: PayloadAction<OrganizationList>) => {
      state.organizations.unshift(action.payload);
    },
    updateOrganization: (state, action: PayloadAction<OrganizationDetail>) => {
      state.organizations = state.organizations
        .slice()
        .map((org) =>
          org.id === action.payload.id ? { ...org, ...action.payload } : org
        );
    },
    deleteOrganization: (state, action: PayloadAction<string>) => {
      state.organizations = state.organizations
        .slice()
        .filter((org) => org.id !== action.payload);
    },
  },
});

export const useGetCurrentOrganization = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.currentOrganization
  );
};

export const useGetOrganizationFeatures = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizationFeatures
  );
};
export const useGetOrganizationResources = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizationResources
  );
};
export const useGetOrganizationUsage = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizationUsage
  );
};

export const useGetActiveSubscription = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.activeSubscription
  );
};

export const useGetOrganizationSubscription = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizationSubscription
  );
};

export const useGetOrganization = (name: string) => {
  const organizations = useSelector(
    (store: RootState) => store.newOrganizations.organizations
  );

  return organizations?.find((org) => org.name === name);
};

export const useGetOrganizations = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizations
  );
};

export const useGetOrganizationCreditResetDates = () => {
  return useSelector(
    (store: RootState) => store.newOrganizations.organizationCreditResetDates
  );
};

export const {
  addOrganization,
  updateOrganization,
  deleteOrganization,
  setOrganizations,
  setOrganization,
  setOrganizationFeatures,
  setOrganizationSubscription,
  setOrganizationResources,
  setOrganizationUsage,
  setOrganizationCreditResetDates,
} = organizations.actions;

export default organizations.reducer;

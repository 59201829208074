import type {
  ImportDetailDeployments,
  ImportDetailEnvironments,
  ImportDetailPipelines,
} from "libs/data/models";

export enum EntityType {
  pipelines = "pipelines",
  deployments = "deployments",
  environments = "environments",
}

export type Entities =
  | ImportDetailDeployments
  | ImportDetailPipelines
  | ImportDetailEnvironments
  | undefined;

export type EntityVersionObject = {
  reference_type: string;
  reference_name: string;
  name: string;
};

export type EntityVersion = {
  objects: EntityVersionObject[];
  environment?: string;
};

export type Attachment = {
  destination_name: string;
  sources: { source_name: string }[];
};

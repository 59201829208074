import WarningIcon from "@mui/icons-material/Warning";
import { Box, Link } from "@mui/material";

import type { ReactNode } from "react";

interface UploadPackageFieldFileErrorProps {
  children: ReactNode;
  onClick?: () => void;
}

export const UploadPackageFieldFileError = ({
  children,
  onClick,
}: UploadPackageFieldFileErrorProps) => {
  return (
    <Box component="span" p={1}>
      <Link
        href={onClick && "#"}
        variant="h5"
        color="error"
        onClick={onClick && onClick}
        style={{ display: "flex", alignItems: "center" }}
      >
        <WarningIcon fontSize="small" style={{ marginRight: 5 }} />
        {children}
      </Link>
    </Box>
  );
};

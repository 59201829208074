import styled from "@emotion/styled";
import { Avatar as MuiAvatar, useTheme } from "@mui/material";
import { readableColor } from "polished";

import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";

import type { AvatarProps, Theme } from "@mui/material";

const Avatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  color: ${({ color }: any) => readableColor(color)};
  background-color: ${({ color }: any) => color};
`;

type CharAvatarProps = {
  text: string;
  variant?: "circular" | "rounded" | "square";
  size?: "small" | "medium" | "large";
} & Omit<AvatarProps, "variant" | "color">;

export const CharAvatar = ({
  text,
  size = "medium",
  variant = "rounded",
  ...props
}: CharAvatarProps) => {
  const theme = useTheme();

  return (
    <Avatar
      color={useStringToColor(text)}
      variant={variant}
      sx={sizeMap(theme)[size]}
      {...props}
    >
      {text?.toUpperCase().charAt(0)}
    </Avatar>
  );
};

const sizeMap = (theme: Theme) => ({
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: "12px !important",
  },
  medium: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "14px !important",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "18px !important",
  },
});

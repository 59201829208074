import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  environmentsDelete,
  useEnvironmentsList,
} from "libs/data/endpoints/environments/environments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useEnvironmentDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useEnvironmentsList(
    projectName,
    {
      environment_type: "custom",
    },
    {
      swr: { swrKey: `/projects/${projectName}/environments` },
    }
  );

  return useCallback(
    async (environmentName: string) => {
      try {
        await environmentsDelete(projectName, environmentName);
        dispatch(createSuccessNotification("Environment was deleted"));
        await mutate();
      } catch (err: any) {
        dispatch(createErrorNotification(err.message));
      }
    },
    [projectName, dispatch, mutate]
  );
};

import styled from "@emotion/styled";
import { Box, useTheme } from "@mui/material";
import { LogOut, LogIn } from "react-feather";
import { Handle, Position } from "reactflow";

import { borderRadius, spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme";

const S_Handle = styled(Handle)`
  &&& {
    background: transparent;
    z-index: 999;
    border: none;
    bottom: 0;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

type NodeHandleProps = {
  isDeployment: boolean;
  type: "source" | "target";
  isConnectable: boolean;
};

export const NodeHandle = ({
  type,
  isConnectable,
  isDeployment,
}: NodeHandleProps) => {
  const isTarget = type === "target";
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      paddingX={spacing[6]}
      style={{
        background: isDeployment
          ? theme.palette.specials.deployment
          : theme.palette.specials.pipelines,
        borderTopRightRadius: isTarget ? 0 : borderRadius[8],
        borderBottomRightRadius: isTarget ? 0 : borderRadius[8],
        borderTopLeftRadius: isTarget ? borderRadius[8] : 0,
        borderBottomLeftRadius: isTarget ? borderRadius[8] : 0,
      }}
    >
      <S_Handle
        type={type}
        position={isTarget ? Position.Left : Position.Right}
        isConnectable={isConnectable}
      />
      {isTarget ? (
        <LogIn size={18} color={theme.palette.primary.contrastText} />
      ) : (
        <LogOut size={18} color={theme.palette.primary.contrastText} />
      )}
    </Box>
  );
};

import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const IlluExperiment = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="180"
      height="153"
      viewBox="0 0 180 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12218_29208)">
        <path
          d="M93.3208 22.7007C93.4581 23.0951 93.6802 23.4546 93.9717 23.7539C94.2631 24.0533 94.6167 24.2852 95.0076 24.4333C95.3985 24.5814 95.8172 24.6421 96.2341 24.6112C96.651 24.5802 97.0561 24.4583 97.4208 24.2541L122.223 32.1991L130.432 25.7676L98.44 20.1736C98.0559 19.5846 97.4688 19.1567 96.7899 18.9711C96.1109 18.7854 95.3874 18.855 94.7564 19.1665C94.1254 19.478 93.6307 20.0098 93.3661 20.6612C93.1016 21.3126 93.0854 22.0382 93.3208 22.7007Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M139.26 33.3181C139.26 33.3181 139.035 32.7678 136.068 33.9652C132.384 35.4516 112.772 34.4579 111.685 29.1179C111.338 29.4756 110.882 29.7078 110.388 29.778C109.895 29.8482 109.392 29.7524 108.958 29.5056L112.024 22.7857C113.62 22.2762 114.487 21.8993 114.591 22.5483L119.389 22.2665L138.821 25.5331L139.358 32.2318L139.26 33.3181Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M133.149 26.5808L142.799 25.7465L151.847 29.8765L156.927 100.059L136.99 90.8512L128.436 93.2146L133.149 26.5808Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M156.892 149.067L153.231 149.067L151.49 134.967L156.892 134.967L156.892 149.067Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M157.825 152.61L146.022 152.61V152.461C146.022 151.244 146.506 150.077 147.368 149.217C148.229 148.356 149.398 147.873 150.616 147.873L152.772 146.24L156.795 147.873L157.825 147.873L157.825 152.61Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M142.706 146.763L139.136 147.568L134.327 134.198L139.597 133.01L142.706 146.763Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M144.399 150.014L132.885 152.61L132.853 152.465C132.584 151.278 132.799 150.033 133.45 149.005C134.1 147.976 135.133 147.247 136.322 146.979L136.322 146.979L138.065 144.912L142.349 145.62L143.354 145.394L144.399 150.014Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M161.528 62.8673C163.244 68.0364 163.063 73.6473 161.115 79.6693L160.283 113.575C161.659 115.087 161.87 128.62 159.75 133.036C156.919 138.933 159.522 139.721 157.521 143.633H151.717L149.034 125.91L148.843 111.544C148.072 109.722 148.705 103.307 148.705 103.307L148.482 90.0057L138.017 113.306C142.758 117.359 142.331 130.45 142.41 136.257L143.964 143.109L136.821 143.747L133.668 134.455L130.124 128.729C128.318 118.583 127.782 111.135 129.322 108.092L139.828 69.0589L161.528 62.8673Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M162.271 61.1421L161.314 59.2311C160.143 51.9845 158.986 45.2256 160.558 39.1459C160.657 33.8386 159.014 28.8525 154.453 26.1298L152.396 24.9009L151.354 22.2627L141.763 22.4966L141.735 25.955L138.3 31.4259V31.4484L138.515 61.2182L139.463 64.2904L138.032 67.8644L138.791 70.1446L157.699 67.1259L159.898 66.7736C160.151 66.6924 160.396 66.5857 160.629 66.4551C161.418 66.0176 162.036 65.3275 162.384 64.4962C162.404 64.4483 162.421 64.4032 162.438 64.3552C162.586 63.9672 162.672 63.5581 162.692 63.1433C162.726 62.4512 162.581 61.7619 162.271 61.1421Z"
          fill={theme.palette.primary.main}
        />
        <path
          opacity="0.2"
          d="M162.692 63.1433C162.672 63.5582 162.586 63.9673 162.438 64.3553L160.629 66.4551C160.396 66.5858 160.151 66.6925 159.898 66.7736L157.699 67.1259L160.88 61.1534L153.889 45.8683L162.692 63.1433Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M14.1482 124.899C11.9267 124.921 9.707 124.768 7.50987 124.44C5.4641 124.094 4.42676 123.603 4.42676 122.981L4.42752 122.97L8.24393 93.9524L6.32182 88.4499C6.24113 88.2189 6.21731 87.9718 6.25238 87.7296C6.28745 87.4875 6.38037 87.2573 6.5233 87.0586C6.66622 86.8598 6.85495 86.6983 7.07354 86.5878C7.29213 86.4772 7.53416 86.4209 7.77917 86.4234L20.4192 86.5549C20.6598 86.5574 20.8963 86.6166 21.1096 86.7278C21.3228 86.8389 21.5067 86.9988 21.6464 87.1944C21.786 87.39 21.8774 87.6158 21.913 87.8533C21.9487 88.0909 21.9276 88.3335 21.8515 88.5614L20.0523 93.9517L23.8688 122.97C23.8696 124.495 17.7589 124.899 14.1482 124.899Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M13.1181 120.814H6.94385V121.161H13.1181V120.814Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M13.1181 118.734H6.94385V119.08H13.1181V118.734Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M13.1181 116.653H6.94385V117H13.1181V116.653Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M13.1181 114.573H6.94385V114.92H13.1181V114.573Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M13.1181 112.493H6.94385V112.839H13.1181V112.493Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M69.6989 124.899C67.4775 124.921 65.2578 124.768 63.0607 124.44C61.0149 124.094 59.9775 123.603 59.9775 122.981L59.9783 122.97L63.7947 93.9524L61.8726 88.4499C61.7919 88.2189 61.7681 87.9718 61.8032 87.7296C61.8382 87.4875 61.9311 87.2573 62.0741 87.0586C62.217 86.8598 62.4057 86.6983 62.6243 86.5878C62.8429 86.4772 63.0849 86.4209 63.3299 86.4234L75.97 86.5549C76.2106 86.5574 76.4471 86.6166 76.6603 86.7278C76.8736 86.8389 77.0575 86.9988 77.1971 87.1944C77.3368 87.39 77.4281 87.6158 77.4638 87.8533C77.4994 88.0909 77.4784 88.3335 77.4023 88.5614L75.6031 93.9517L79.4196 122.97C79.4203 124.495 73.3096 124.899 69.6989 124.899Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M68.6689 120.814H62.4946V121.161H68.6689V120.814Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M68.6689 118.734H62.4946V119.08H68.6689V118.734Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M68.6689 116.653H62.4946V117H68.6689V116.653Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M68.6689 114.573H62.4946V114.92H68.6689V114.573Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M68.6689 112.493H62.4946V112.839H68.6689V112.493Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M43.6594 124.899C41.438 124.921 39.2182 124.768 37.0211 124.44C34.9753 124.094 33.938 123.603 33.938 122.981L33.9388 122.97L37.7552 93.9524L35.8331 88.4499C35.7524 88.2189 35.7285 87.9718 35.7636 87.7296C35.7987 87.4875 35.8916 87.2573 36.0345 87.0586C36.1775 86.8598 36.3662 86.6983 36.5848 86.5878C36.8034 86.4772 37.0454 86.4209 37.2904 86.4234L49.9305 86.5549C50.171 86.5574 50.4075 86.6166 50.6208 86.7278C50.834 86.8389 51.018 86.9988 51.1576 87.1944C51.2972 87.39 51.3886 87.6158 51.4242 87.8533C51.4599 88.0909 51.4388 88.3335 51.3627 88.5614L49.5635 93.9517L53.38 122.97C53.3808 124.495 47.2701 124.899 43.6594 124.899Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M42.6294 120.814H36.4551V121.161H42.6294V120.814Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M42.6294 118.734H36.4551V119.08H42.6294V118.734Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M42.6294 116.653H36.4551V117H42.6294V116.653Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M42.6294 114.573H36.4551V114.92H42.6294V114.573Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M42.6294 112.493H36.4551V112.839H42.6294V112.493Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M84.7151 127.055H72.9105V107.292H84.7151C85.3597 107.292 85.9779 107.036 86.4336 106.581C86.8894 106.126 87.1455 105.508 87.1455 104.865C87.1455 104.221 86.8894 103.604 86.4336 103.149C85.9779 102.693 85.3597 102.438 84.7151 102.438H2.43035C1.78578 102.438 1.16761 102.693 0.711835 103.149C0.256056 103.604 0 104.221 0 104.865C0 105.508 0.256056 106.126 0.711835 106.581C1.16761 107.036 1.78578 107.292 2.43035 107.292H12.499V127.055H2.43035C1.78578 127.055 1.16761 127.31 0.711835 127.766C0.256056 128.221 0 128.838 0 129.482C0 130.125 0.256056 130.743 0.711835 131.198C1.16761 131.653 1.78578 131.909 2.43035 131.909H84.7151C85.0345 131.909 85.3508 131.847 85.646 131.725C85.9411 131.603 86.2094 131.424 86.4353 131.199C86.6613 130.973 86.8405 130.706 86.9628 130.411C87.0851 130.117 87.1481 129.801 87.1481 129.482C87.1481 129.163 87.0851 128.847 86.9628 128.552C86.8405 128.258 86.6613 127.99 86.4353 127.765C86.2094 127.539 85.9411 127.361 85.646 127.239C85.3508 127.117 85.0345 127.054 84.7151 127.055ZM17.3597 127.055V107.292H68.0498V127.055H17.3597Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M82.8034 152.643C76.7656 152.643 71.1344 152.253 66.9475 151.546C61.7824 150.673 59.2715 149.406 59.2715 147.67L59.2743 147.615L68.3401 78.679L63.8012 65.6857C63.5834 65.0744 63.5182 64.4192 63.6112 63.7771C63.7041 63.1349 63.9525 62.525 64.3348 62.0004C64.7082 61.4676 65.2077 61.0352 65.7888 60.7417C66.37 60.4482 67.0147 60.3028 67.6657 60.3183L97.7212 60.6309C98.3583 60.6375 98.9848 60.7944 99.5496 61.0887C100.114 61.383 100.602 61.8065 100.971 62.3245C101.341 62.8426 101.583 63.4406 101.678 64.0698C101.772 64.699 101.716 65.3416 101.515 65.9452L97.2661 78.6742L106.333 147.616V147.643C106.334 149.514 103.492 150.843 97.643 151.705C91.8973 152.552 85.3511 152.643 82.8034 152.643Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M82.8034 152.643C76.7656 152.643 71.1344 152.253 66.9475 151.546C61.7824 150.673 59.2715 149.406 59.2715 147.67L59.2743 147.615L68.3401 78.679L63.8012 65.6857C63.5834 65.0744 63.5182 64.4192 63.6112 63.7771C63.7041 63.1349 63.9525 62.525 64.3348 62.0004C64.7082 61.4676 65.2077 61.0352 65.7888 60.7417C66.37 60.4482 67.0147 60.3028 67.6657 60.3183L97.7212 60.6309C98.3583 60.6375 98.9848 60.7944 99.5496 61.0887C100.114 61.383 100.602 61.8065 100.971 62.3245C101.341 62.8426 101.583 63.4406 101.678 64.0698C101.772 64.699 101.716 65.3416 101.515 65.9452L97.2661 78.6742L106.333 147.616V147.643C106.334 149.514 103.492 150.843 97.643 151.705C91.8973 152.552 85.3511 152.643 82.8034 152.643ZM67.5827 61.142C67.0769 61.1415 66.5784 61.263 66.1296 61.4962C65.6809 61.7295 65.2953 62.0675 65.0055 62.4815C64.7013 62.899 64.5036 63.3842 64.4296 63.8952C64.3557 64.4062 64.4076 64.9275 64.5809 65.4139L69.1843 78.592L69.1716 78.6891L60.0956 147.697C60.0972 148.373 61.0051 149.706 67.0854 150.733C71.2281 151.433 76.8101 151.818 82.8034 151.818C88.0713 151.818 93.5739 151.471 97.5225 150.889C102.567 150.146 105.472 148.973 105.507 147.668L96.4226 78.5936L100.731 65.6845C100.892 65.2042 100.936 64.6928 100.861 64.1922C100.786 63.6915 100.593 63.2156 100.299 62.8033C100.005 62.3911 99.6171 62.0541 99.1676 61.8199C98.7182 61.5856 98.2197 61.4607 97.7127 61.4554L67.6527 61.1428C67.6293 61.1422 67.6059 61.142 67.5827 61.142Z"
          fill={theme.palette.primary.main}
        />
        <path
          opacity="0.5"
          d="M99.9366 139.235H65.6709L71.1028 102.555L71.2508 101.544L73.5137 86.2739C73.6518 85.3424 74.7256 86.0658 75.4849 85.5074C80.1989 82.0407 84.9694 81.9402 89.7813 85.2815C90.5251 85.798 91.5526 85.1935 91.6913 86.0876L94.0686 101.404L94.2984 102.886L99.9366 139.235Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M95.7073 144.372H69.8631C69.1856 144.372 68.516 144.226 67.9 143.944C67.284 143.663 66.736 143.252 66.2933 142.739C65.8507 142.227 65.5238 141.626 65.3349 140.976C65.146 140.326 65.0996 139.643 65.1987 138.974L72.7496 87.9834C72.7885 87.7227 72.8958 87.4769 73.0605 87.2709C73.2251 87.0649 73.4414 86.9059 73.6873 86.8102C73.9333 86.7144 74.2002 86.6853 74.461 86.7257C74.7219 86.766 74.9674 86.8745 75.1728 87.0401C79.7747 90.7216 84.7903 90.6387 90.0798 86.7949C90.2849 86.6452 90.5253 86.5511 90.7777 86.5217C91.0301 86.4924 91.2858 86.5288 91.5199 86.6274C91.7588 86.7254 91.9683 86.8835 92.1279 87.0863C92.2875 87.2892 92.3917 87.5299 92.4304 87.7849L100.367 138.942C100.471 139.613 100.429 140.299 100.242 140.953C100.056 141.606 99.7303 142.211 99.2876 142.727C98.8449 143.243 98.2957 143.657 97.6778 143.941C97.0598 144.225 96.3876 144.372 95.7073 144.372Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M85.3079 138.394H70.6245V139.218H85.3079V138.394Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M85.3079 133.446H70.6245V134.271H85.3079V133.446Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M85.3079 128.499H70.6245V129.324H85.3079V128.499Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M85.3079 123.552H70.6245V124.376H85.3079V123.552Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M85.3079 118.604H70.6245V119.429H85.3079V118.604Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M94.9903 105.626C93.8515 106.755 92.4815 107.625 90.9743 108.176C89.4672 108.727 87.8585 108.946 86.2588 108.817C84.434 108.652 82.7703 107.972 81.1684 107.114C79.513 106.228 77.9112 105.168 76.0782 104.674C74.4722 104.241 72.569 104.183 71.1901 105.255C71.0774 105.342 70.9397 105.391 70.7972 105.395C70.6546 105.399 70.5146 105.357 70.3975 105.276L70.5543 104.204C72.0901 103.132 73.9561 102.967 75.785 103.334C79.4675 104.076 82.2625 107.119 86.0358 107.556C87.6635 107.739 89.3113 107.515 90.831 106.905C92.3507 106.294 93.6948 105.317 94.7426 104.059L94.7467 104.055L94.9903 105.626Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M180 152.665C180 152.709 179.991 152.752 179.975 152.793C179.958 152.834 179.933 152.871 179.902 152.902C179.871 152.933 179.833 152.958 179.793 152.975C179.752 152.991 179.708 153 179.664 153H0.357843C0.268764 153 0.183334 152.965 0.120346 152.902C0.0573583 152.839 0.0219727 152.753 0.0219727 152.665C0.0219727 152.576 0.0573583 152.49 0.120346 152.427C0.183334 152.364 0.268764 152.329 0.357843 152.329H179.664C179.708 152.329 179.752 152.338 179.793 152.354C179.833 152.371 179.871 152.396 179.902 152.427C179.933 152.458 179.958 152.495 179.975 152.536C179.991 152.577 180 152.62 180 152.665Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M142.745 25.7353L152.396 24.901L161.443 29.0311L166.523 99.2135L146.586 90.0058L138.032 92.3691L142.745 25.7353Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M95.1158 25.6488C96.0511 25.6488 96.8093 24.8916 96.8093 23.9576C96.8093 23.0236 96.0511 22.2665 95.1158 22.2665C94.1806 22.2665 93.4224 23.0236 93.4224 23.9576C93.4224 24.8916 94.1806 25.6488 95.1158 25.6488Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M92.2936 46.2243C93.2288 46.2243 93.987 45.4672 93.987 44.5332C93.987 43.5992 93.2288 42.842 92.2936 42.842C91.3583 42.842 90.6001 43.5992 90.6001 44.5332C90.6001 45.4672 91.3583 46.2243 92.2936 46.2243Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M83.2618 58.3442C84.1971 58.3442 84.9553 57.587 84.9553 56.6531C84.9553 55.7191 84.1971 54.9619 83.2618 54.9619C82.3265 54.9619 81.5684 55.7191 81.5684 56.6531C81.5684 57.587 82.3265 58.3442 83.2618 58.3442Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M125.538 71.4469V53.2927C125.538 52.9252 125.684 52.5729 125.945 52.3131C126.205 52.0533 126.557 51.9072 126.925 51.9067H140.655C141.023 51.9072 141.375 52.0533 141.636 52.3131C141.896 52.5729 142.042 52.9252 142.043 53.2927V71.4469C142.042 71.8144 141.896 72.1666 141.636 72.4265C141.375 72.6863 141.023 72.8324 140.655 72.8328H126.925C126.557 72.8324 126.205 72.6863 125.945 72.4265C125.684 72.1666 125.538 71.8144 125.538 71.4469Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M126.591 65.9635V54.2923C126.591 53.9482 126.728 53.6183 126.972 53.375C127.216 53.1316 127.546 52.9948 127.891 52.9944H139.69C140.034 52.9948 140.365 53.1316 140.608 53.375C140.852 53.6183 140.989 53.9482 140.989 54.2923V70.4472C140.989 70.7913 140.852 71.1212 140.608 71.3645C140.365 71.6079 140.034 71.7447 139.69 71.7451H132.38C130.845 71.7434 129.374 71.1337 128.288 70.0498C127.203 68.966 126.593 67.4964 126.591 65.9635Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M128.501 57.5489C128.501 57.2126 128.635 56.8902 128.873 56.6524C129.111 56.4146 129.434 56.2809 129.771 56.2805H137.956C138.293 56.2805 138.616 56.4141 138.854 56.652C139.092 56.8899 139.226 57.2125 139.226 57.5489C139.226 57.8853 139.092 58.2079 138.854 58.4457C138.616 58.6836 138.293 58.8172 137.956 58.8172H129.771C129.434 58.8169 129.111 58.6831 128.873 58.4453C128.635 58.2075 128.501 57.8852 128.501 57.5489Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M128.501 61.4949C128.501 61.1586 128.635 60.8362 128.873 60.5985C129.111 60.3607 129.434 60.2269 129.771 60.2266H137.956C138.293 60.2266 138.616 60.3602 138.854 60.5981C139.092 60.8359 139.226 61.1585 139.226 61.4949C139.226 61.8313 139.092 62.1539 138.854 62.3918C138.616 62.6296 138.293 62.7633 137.956 62.7633H129.771C129.434 62.7629 129.111 62.6292 128.873 62.3914C128.635 62.1536 128.501 61.8312 128.501 61.4949Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M128.501 65.4408C128.501 65.1046 128.635 64.7822 128.873 64.5444C129.111 64.3066 129.434 64.1729 129.771 64.1725H137.956C138.293 64.1725 138.616 64.3061 138.854 64.544C139.092 64.7818 139.226 65.1045 139.226 65.4408C139.226 65.7772 139.092 66.0998 138.854 66.3377C138.616 66.5756 138.293 66.7092 137.956 66.7092H129.771C129.434 66.7088 129.111 66.5751 128.873 66.3373C128.635 66.0995 128.501 65.7771 128.501 65.4408Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M136.722 74.5789C137.109 74.4194 137.455 74.1773 137.738 73.8696C138.02 73.5618 138.232 73.1959 138.357 72.7977C138.483 72.3995 138.52 71.9786 138.465 71.5647C138.41 71.1508 138.265 70.754 138.04 70.4021L147.995 61.3713L151.487 47.8812L145.289 47.3662L143.394 57.9522L133.902 69.6201C133.335 70.0369 132.941 70.6468 132.795 71.3344C132.648 72.0219 132.759 72.7392 133.107 73.3504C133.454 73.9617 134.014 74.4243 134.681 74.6507C135.347 74.8772 136.073 74.8516 136.722 74.5789Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M152.442 35.6983C152.442 35.6983 153.952 35.7207 153.272 38.8441C152.727 41.3447 150.66 55.4164 149.011 58.5644C149.389 58.8898 149.647 59.3314 149.745 59.8196C149.844 60.3077 149.777 60.8148 149.555 61.2608L142.661 58.5898C142.06 57.028 143.068 56.5425 143.711 56.4024L143.585 51.5035L144.573 39.016L151.35 35.6628L152.442 35.6983Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M154.472 12.581C154.151 14.9232 152.911 17.0421 151.026 18.4722C149.141 19.9022 146.764 20.5264 144.418 20.2076C142.073 19.8888 139.95 18.653 138.516 16.7719C137.082 14.8907 136.454 12.518 136.771 10.1752L136.789 10.0486C137.159 7.73715 138.424 5.66366 140.311 4.27535C142.198 2.88704 144.556 2.29519 146.876 2.62747C149.197 2.95975 151.293 4.18952 152.714 6.05151C154.134 7.9135 154.765 10.2585 154.469 12.5806L154.472 12.581Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M152.199 17.1753C152.658 16.2449 153.129 15.2459 153 14.2168C152.871 13.1877 151.86 12.204 150.868 12.5099C150.275 12.6928 149.826 13.2779 149.206 13.3079C148.352 13.3491 147.863 12.3736 147.597 11.5626L146.515 8.25947C145.476 9.06809 144.272 9.6377 142.987 9.92765C141.702 10.2176 140.369 10.2207 139.083 9.93681C138.078 9.70744 137.063 9.26025 136.474 8.41603C135.885 7.57181 135.888 6.26228 136.696 5.6239C137.093 5.31088 137.62 5.19195 137.999 4.85794C138.234 4.65074 138.396 4.37351 138.461 4.06728C138.526 3.76105 138.49 3.44209 138.359 3.15759C138.229 2.8731 138.01 2.63819 137.735 2.48763C137.46 2.33707 137.144 2.27885 136.833 2.3216L139.558 1.98158L138.746 0.51554C139.331 0.975962 140.145 1.03788 140.878 0.906091C141.611 0.7743 142.301 0.47293 143.018 0.271951C146.46 -0.692885 150.461 0.984692 152.181 4.11444C152.852 3.89089 153.574 3.87603 154.253 4.07184C154.932 4.26764 155.536 4.66486 155.984 5.21086C156.713 6.13521 156.911 7.36874 156.939 8.54518C156.993 10.8648 156.486 13.1631 155.463 15.2463C155.169 15.9079 154.748 16.5051 154.222 17.0035C153.958 17.2506 153.642 17.4369 153.298 17.5494C152.954 17.6618 152.589 17.6976 152.229 17.6544"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_12218_29208">
          <rect width="180" height="153" fill={theme.palette.svgs.paperWhite} />
        </clipPath>
      </defs>
    </svg>
  );
};

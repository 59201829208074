import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { useUserUpdate } from "libs/data/customized/user";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { useSignOut } from "libs/hooks/useSignOut";
import { formatLabel } from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import { FormTextField, InfoAlert, SecondaryButton } from "components/atoms";
import { PasswordConfirmation } from "components/molecules";

const FIELD_OLD_PASSWORD = "old_password";

const PasswordUpdate = () => {
  useGoogleAnalytics();
  const { data: user, mutate } = useUserGet();

  const methods = useForm();
  const signOut = useSignOut();

  const { reset, handleSubmit } = methods;

  const updateUser = useUserUpdate(
    "Your password has been updated, log in with your new password."
  );

  const handlePasswordSave = async ({
    old_password,
    new_password,
  }: {
    old_password: string;
    new_password: string;
  }) => {
    await updateUser({
      password: new_password,
      previous_password: old_password,
    });
    reset();
    mutate();
    signOut();
  };

  return (
    <>
      {user?.authentication === "ubiops" ? (
        <Box paddingLeft={spacing[32]} paddingRight={spacing[64]}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handlePasswordSave)}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 8,
                alignItems: "flex-start",
              }}
            >
              <FormTextField
                id={FIELD_OLD_PASSWORD}
                name={FIELD_OLD_PASSWORD}
                label="Current password"
                type="password"
                rules={{
                  required: validators.required.message("current password"),
                }}
              />
              <PasswordConfirmation />
              <SecondaryButton
                type="submit"
                onClick={handleSubmit(handlePasswordSave)}
              >
                Save
              </SecondaryButton>
            </form>
          </FormProvider>
        </Box>
      ) : (
        <InfoAlert>
          Your account is managed by {formatLabel(user?.authentication ?? "")}.
          Go to your {formatLabel(user?.authentication ?? "")} account settings
          to change your settings.
        </InfoAlert>
      )}
    </>
  );
};

export default PasswordUpdate;

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import RequestScheduleRoutesContainer from "./:requestScheduleName";
import RequestScheduleCreate from "./RequestScheduleCreate";
import RequestSchedulesOverview from "./RequestSchedulesOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").requestSchedules;

export const RequestSchedulesPage = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).requestSchedules;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Request schedules</BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path={basePath.index()}
          component={RequestSchedulesOverview}
        />
        <Route
          path={basePath.create.index()}
          component={RequestScheduleCreate}
        />
        <Route
          path={basePath[":scheduleName"](":scheduleName").index()}
          component={RequestScheduleRoutesContainer}
        />
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import BackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, Box, Grid, IconButton } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useGoogleAnalytics } from "libs/hooks";
import validators from "libs/utilities/validators";
import { setError, useAuthentication } from "store/features";

import { FormTextField, Alert, SecondaryButton } from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { useAuthorize } from "../../../libs/data/customized/authorize/useAuthorize";

const SignIn2FA = () => {
  useGoogleAnalytics();

  const { authorize, isLoading } = useAuthorize();
  const { error } = useAuthentication();
  const history = useHistory();
  const dispatch = useDispatch();
  const credentials = history.location.state as {
    email: string;
    password: string;
  };

  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = methods;

  const handleLogin = ({ token }: { token: string }) => {
    authorize({ token, ...credentials });
  };

  const goBack = () => {
    dispatch(setError(null));
    history.push("/sign-in");
  };

  return (
    <AuthenticationContainer
      title="Two-factor authentication"
      description="Your account is protected using two-factor authentication. Please enter your authenticator code to continue."
      info={
        <Grid
          container
          direction="row"
          alignItems="center"
          wrap="nowrap"
          component={Box}
          mt={1}
        >
          <Grid item component="span">
            <IconButton onClick={goBack}>
              <BackIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item component="span">
            Go back to sign-in
          </Grid>
        </Grid>
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleLogin)}>
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container direction="column">
            <FormTextField
              id="token"
              name="token"
              label="2FA token"
              autoFocus
              rules={{
                required: validators.required.message("two-factor code"),
              }}
            />
            <Box my={2}>
              <SecondaryButton
                style={{ width: "100%" }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  "Sign in"
                )}
              </SecondaryButton>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </AuthenticationContainer>
  );
};

export default SignIn2FA;

import { parseConfigInputsOutputsToPipelineObject } from "components/templates/Diagram/EdgeCustom/utils";

import { OPERATOR_PIPELINE_VARIABLE } from "./constants";
import { getId } from "./getId";
import { NodeTypes } from "./types";

import type { NodeDataType } from "./types";
import type {
  PipelineDetail,
  PipelineVersionDetail,
  PipelineVersionObjectList,
  PipelineVersionObjectListMetadata,
} from "libs/data/models";
import type { Node } from "reactflow";

export const getInitialNodes = (
  isReadonly: boolean,
  organizationName: string,
  projectName: string,
  pipeline?: PipelineDetail,
  pipelineVersionDetail?: PipelineVersionDetail
): { nodes: Node<NodeDataType>[]; hasSavedPosition: boolean } => {
  if (!pipelineVersionDetail || !pipeline) {
    return { nodes: [], hasSavedPosition: false };
  }

  const isObjectPipelineVariable = (obj: PipelineVersionObjectList) =>
    obj.reference_type === "operator" &&
    obj.reference_name === OPERATOR_PIPELINE_VARIABLE;

  const { startPosition, endPosition, hasSavedPosition } =
    getStartEndObjectsPosition(pipelineVersionDetail);

  return {
    nodes: [
      {
        id: NodeTypes.pipeline_start,
        type: NodeTypes.pipeline_start,
        position: startPosition,
        height: 50,
        width: 100,
        data: {
          isReadonly,
          type: NodeTypes.pipeline_start,
          organizationName,
          projectName,
          pipelineName: pipelineVersionDetail.pipeline,
          versionName: pipelineVersionDetail.version,
          pipelineObject: {
            id: NodeTypes.pipeline_start,
            name: NodeTypes.pipeline_start,
            version: null,
            reference_name: NodeTypes.pipeline_start,
            output_fields: pipeline.input_fields,
            output_type: pipeline.input_type,
          } as PipelineVersionObjectList,
        },
      },
      ...((pipelineVersionDetail.objects || []).map((pipelineObject) => {
        const type = isObjectPipelineVariable(pipelineObject)
          ? NodeTypes.variable
          : pipelineObject.reference_type === "operator"
          ? NodeTypes.operator
          : NodeTypes.deployment;
        const height =
          type === NodeTypes.deployment
            ? 160
            : type === NodeTypes.operator
            ? 100
            : 50;
        const width =
          type === NodeTypes.deployment
            ? 360
            : type === NodeTypes.operator
            ? 70
            : 180;

        pipelineObject =
          parseConfigInputsOutputsToPipelineObject(pipelineObject);

        return {
          id: getId(),
          type,
          position: pipelineObject.metadata?.position ?? { x: 250, y: 0 },
          height,
          width,
          data: {
            isReadonly,
            type,
            organizationName,
            projectName,
            pipelineName: pipelineVersionDetail.pipeline,
            versionName: pipelineVersionDetail.version,
            pipelineObject,
          } as unknown as Node<NodeDataType>["data"],
        } as Node<NodeDataType>;
      }) as Node<NodeDataType>[]),
      {
        id: NodeTypes.pipeline_end,
        type: NodeTypes.pipeline_end,
        position: endPosition,
        height: 50,
        width: 100,
        data: {
          organizationName,
          projectName,
          pipelineName: pipelineVersionDetail.pipeline,
          versionName: pipelineVersionDetail.version,
          isReadonly,
          type: NodeTypes.pipeline_end,
          pipelineObject: {
            id: NodeTypes.pipeline_end,
            name: NodeTypes.pipeline_end,
            version: null,
            reference_name: NodeTypes.pipeline_end,
            input_fields: pipeline.output_fields,
            input_type: pipeline.output_type,
          },
        },
      },
    ],
    hasSavedPosition,
  };
};

const getStartEndObjectsPosition = (diagramObjects: PipelineVersionDetail) => {
  let startPosition = { x: 0, y: 0 };
  let endPosition = { x: 500, y: 0 };

  // If one object has position, diagram has been saved before so we calculate the start position
  const beenSaved = diagramObjects.objects?.[0]?.metadata?.position;

  if (beenSaved) {
    // find objects that are connected to pipeline start
    const destinationNames = diagramObjects.attachments
      ?.filter((attachment) =>
        attachment.sources?.find(
          (source) => source.source_name === "pipeline_start"
        )
      )
      .map(({ destination_name }) => destination_name);

    const connectedToStartNode = diagramObjects.objects
      ?.filter((obj) => destinationNames?.includes(obj.name) && !!obj.metadata)
      .map((obj) => obj.metadata);

    startPosition = getStartPosition(
      connectedToStartNode as PipelineVersionObjectListMetadata[]
    );

    // find object that's connected to pipeline end
    const sourceNames = diagramObjects.attachments
      ?.filter((attachment) => attachment.destination_name === "pipeline_end")
      ?.flatMap(({ sources }) =>
        sources?.map(({ source_name }) => source_name)
      );

    const connectedToEndNode = diagramObjects.objects
      ?.filter((obj) => sourceNames?.includes(obj.name) && !!obj.metadata)
      .map((obj) => obj.metadata);

    endPosition = getEndPosition(
      connectedToEndNode as PipelineVersionObjectListMetadata[]
    );
  }

  return {
    startPosition,
    endPosition,
    hasSavedPosition: !!beenSaved,
  };
};

const getStartPosition = (metadatas?: PipelineVersionObjectListMetadata[]) => {
  if (!metadatas || metadatas.length === 0) {
    return { y: 0, x: 0 };
  }

  const { sumY, x } = metadatas.reduce(
    (acc, obj) => {
      acc.sumY += obj.position.y + obj.height / 2;
      acc.x = Math.min(acc.x, obj.position.x);

      return acc;
    },
    { sumY: 0, x: metadatas[0].position.x }
  );

  const y = sumY / metadatas.length;

  return { y, x: x - 200 };
};

const getEndPosition = (metadatas?: PipelineVersionObjectListMetadata[]) => {
  if (!metadatas || metadatas.length === 0) {
    return { y: 500, x: 0 };
  }

  const { sumY, x } = metadatas.reduce(
    (acc, obj) => {
      acc.sumY += obj.position.y + obj.height / 2;
      acc.x = Math.max(acc.x, obj.position.x + obj.width);

      return acc;
    },
    { sumY: 0, x: metadatas[0].position.x + metadatas[0].width }
  );

  const y = sumY / metadatas.length;

  return { y, x: x + 200 };
};

import moment from "moment";

export const formatQueryParams = (options: Record<string, unknown>) => {
  return options
    ? Object.keys(options)
        .map((key) => {
          if (moment.isMoment(options[key])) {
            return `${key}=${(options[key] as moment.Moment).utc().format()}`;
          }

          return `${key}=${options[key]}`;
        })
        .join("&")
    : [];
};

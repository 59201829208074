import { useTheme } from "@mui/material";

export const LineChartIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="1" fill={theme.palette.primary.main} />
      <path
        d="M15.4839 12.2168L18.7664 6.54195L20.1058 7.31615L16.0568 14.3226L11.0168 11.4194L7.32385 17.8065H20.129V19.3549H4.64514V5.41937H6.19353V16.6761L10.4516 9.29034L15.4839 12.2168Z"
        fill={theme.palette.background.default}
      />
    </svg>
  );
};

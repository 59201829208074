import {
  FIELD_NAME,
  FIELD_OBJECT_NAME,
  FIELD_OBJECT_TYPE,
  FIELD_REQUEST_DATA,
  FIELD_SCHEDULE,
  FIELD_TIMEOUT,
  FIELD_VERSION,
} from "libs/constants/fields";
import { ScheduleCreateObjectType } from "libs/data/models";

import { DEFAULT_SCHEDULE_VALUE } from "./CronScheduler/CronFields/constants";

export const getRequestScheduleDefaultValues = (defaultObjectName: string) => ({
  [FIELD_NAME]: "",
  [FIELD_OBJECT_TYPE]: ScheduleCreateObjectType.deployment,
  [FIELD_OBJECT_NAME]: defaultObjectName,
  [FIELD_VERSION]: undefined,
  [FIELD_REQUEST_DATA]: {},
  [FIELD_SCHEDULE]: DEFAULT_SCHEDULE_VALUE,
  [FIELD_TIMEOUT]: 14400,
});

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Chip, Grid, Typography, useTheme } from "@mui/material";

import { capitalize } from "libs/utilities/utils";

import type { Label } from "./ControlledLabelsInput";
import type { AppThemeProps } from "assets/styles/theme/theme";

interface PreselectLabelsProps {
  onAdd: (value: Label) => void;
  labels: Label[];
}

export const PreselectLabels = ({ onAdd, labels }: PreselectLabelsProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Grid container spacing={1}>
      {!!labels.length && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            style={{
              color: theme.palette.neutrals[500],
            }}
          >
            Suggested labels:
          </Typography>
        </Grid>
      )}

      <>
        {labels.map((item: Label) => (
          <Grid item key={`${item.key}:${item.value}`}>
            <Chip
              deleteIcon={<AddCircleRoundedIcon />}
              size="small"
              label={
                <Typography variant="body2" component="span">
                  <Typography
                    display="inline"
                    variant="subtitle1"
                    component="span"
                  >
                    <strong>{capitalize(item?.key ?? "")}:</strong>{" "}
                  </Typography>
                  {item.value}
                </Typography>
              }
              onDelete={() => onAdd(item)}
              onClick={() => onAdd(item)}
              sx={{ backgroundColor: theme.palette.background.active }}
            />
          </Grid>
        ))}{" "}
      </>
    </Grid>
  );
};

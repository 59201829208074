import { Grid, Typography } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { LabelChip } from "components/atoms";

import type {
  DeploymentVersionDetailLabels,
  PipelineVersionDetailLabels,
} from "libs/data/models";
import type { SyntheticEvent } from "react";

export const renderLabels = (
  onFilterAdd: ({ key, value }: { key: string; value: string }) => void
) =>
  function Label(rowData: { [key: string]: string }) {
    const addFilter = (key: string) => (event: SyntheticEvent) => {
      // @ts-ignore
      onFilterAdd({ key, value: rowData.labels[key] });
      event.stopPropagation();
    };

    return (
      <Grid container spacing={1}>
        {rowData.labels ? (
          Object.keys(rowData.labels).map((key) => (
            <Grid item key={key}>
              <LabelChip
                key={key}
                label={key}
                // @ts-ignore
                value={rowData.labels[key]}
                onClick={addFilter(key)}
                style={{ width: spacing[156] }}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="caption">No labels</Typography>
        )}
      </Grid>
    );
  };

export const formatLabelsForFilter = (
  filters: { key: string; value: string }[]
) => filters?.map(({ key, value }) => `${key}:${value}`).join(",");

export const formatLabelsForRequest = (
  formLabels: Array<{ key: string; value: string }>
) => {
  const labels: Record<string, string> = {};
  if (formLabels) {
    formLabels.forEach(({ key, value }: { key: string; value: string }) => {
      labels[key] = value;
    });
  }

  return labels;
};

export const formatLabels = (
  labels: PipelineVersionDetailLabels | DeploymentVersionDetailLabels
) =>
  Object.keys(labels).map((key) => ({
    key,
    value: labels[key],
  }));

import { enqueueSnackbar } from "store/features/notifications";

export const createNotification = (
  message: string,
  variant: "default" | "success" | "error" | "info" | "warning" = "default",
  options: { key?: string } = {}
) =>
  enqueueSnackbar({
    message,
    dismissed: false,
    key: options.key ?? new Date().getTime() + Math.random().toString(),
    options: {
      variant,
      ...options,
    },
  });

export const createDefaultNotification = (
  message: string,
  options: { key?: string } = {}
) => createNotification(message, "default", options);

export const createSuccessNotification = (message: string, options = {}) =>
  createNotification(message, "success", options);

export const createInfoNotification = (message: string, options = {}) =>
  createNotification(message, "info", options);

export const createWarningNotification = (message: string, options = {}) =>
  createNotification(message, "warning", options);

export const createErrorNotification = (message: string, options = {}) => {
  if (!message) {
    message = "An unknown error occurred";
  }

  return createNotification(message, "error", options);
};

import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { expressionsEvaluate } from "libs/data/endpoints/pipelines/pipelines";
import {
  isObjectHasData,
  convertValueStringToCorrectDataType,
} from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import { Accordion, FormTextField, SecondaryButton } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  ExpressionEvaluateResponse,
  ExpressionInputFieldCreateDataType,
} from "libs/data/models";
import type { ChangeEvent } from "react";

export type Fields = Record<
  string,
  {
    name: string;
    data_type: { label: string; value: ExpressionInputFieldCreateDataType };
  }
>;

type Field = {
  id: string;
  name: string;
  label: string;
  value: any;
  data_type: string;
};

type TestExpressionProps = {
  expression: string;
  fields: Fields;
};

export const TestExpression = ({ expression, fields }: TestExpressionProps) => {
  const [response, setResponse] =
    useState<{
      type: string;
      message: string;
    } | null>(null);

  const [fieldsArray, setFieldsArray] = useState(createFieldsArray(fields));
  const theme = useTheme() as AppThemeProps;
  const formMethods = useForm({ mode: "all" });
  const { errors, trigger, formState } = formMethods;

  useEffect(() => {
    setFieldsArray((fieldsArray) => createFieldsArray(fields, fieldsArray));
    setResponse(null);
  }, [fields]);

  useEffect(() => {
    // Don't trigger form validation unless user touched a field
    if (isObjectHasData(formState?.touched)) {
      // Trigger form validation when input fields change
      trigger();
    }
  }, [trigger, fields, fieldsArray, formState?.touched]);

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setResponse(null);
    const { name, value } = event.target;
    setFieldsArray((fieldsArray) =>
      fieldsArray.map((field) => {
        return field.name === name
          ? {
              ...field,
              value: convertValueStringToCorrectDataType(
                value,
                field.data_type
              ),
            }
          : field;
      })
    );
  };

  const onSubmit = () => {
    const requestData = Object.fromEntries(
      fieldsArray.map((field) => {
        return [field.name, field.value];
      })
    );

    const isNameFieldEmpty = Object.values(fields).some(({ name }) => !name);

    expressionsEvaluate({
      expression,
      input_fields: isNameFieldEmpty
        ? []
        : Object.values(fields).map((field) => ({
            ...field,
            data_type: field.data_type.value,
          })),
      request_data: requestData,
    })
      .then((data: ExpressionEvaluateResponse) => {
        const result = data?.result?.toString() || "";
        const responseObj = {
          type: result === "false" ? "error" : "success",
          message: result,
        };
        setResponse(responseObj);
      })
      .catch((error) => setResponse({ type: "error", message: error.message }));
  };

  return (
    <Box marginY={spacing[16]}>
      <Accordion title="Test expression" titleVariant="h6">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="baseline"
          width="100%"
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ alignSelf: "center" }}
          >
            Provide input data to test the expression with
          </Typography>
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ margin: `${spacing[8]} 0` }}
            >
              Expression input
            </Typography>
          </Box>
          <FormProvider {...formMethods}>
            {fieldsArray.map((field) => (
              <FormTextField
                key={field.id}
                name={field?.name}
                label={field.label}
                onChange={onChange}
                rules={{
                  // @ts-ignore
                  ...validators[field?.data_type],
                }}
              />
            ))}
            <SecondaryButton
              style={{ marginTop: spacing[12] }}
              onClick={onSubmit}
              disabled={isObjectHasData(errors)}
            >
              Test expression
            </SecondaryButton>
          </FormProvider>
          {response && (
            <Typography
              variant="h6"
              style={{
                ...responseStyle,
                background:
                  response.type === "error"
                    ? `${theme.palette.error.main}30`
                    : `${theme.palette.success.main}30`,
                borderColor:
                  response.type === "error"
                    ? theme.palette.error.main
                    : theme.palette.success.main,
              }}
            >
              {response.message}
            </Typography>
          )}
        </Box>
      </Accordion>
    </Box>
  );
};

const responseStyle = {
  padding: spacing[8],
  marginTop: spacing[12],
  border: "2px solid",
};

const createFieldsArray = (fields: Fields, fieldsArray?: Field[]) =>
  fields
    ? Object.entries(fields)
        .map(([key, value]) => ({
          id: key,
          label: `${value?.name}(${value.data_type?.label})`,
          name: value?.name,
          value: fieldsArray?.find((field) => field.id === key)?.value ?? "",
          data_type: value?.data_type?.value,
        }))
        .filter((field) => !!field?.name)
    : [];

import { PageHeader } from "components/molecules/PageLayout";

import { PageContainer } from "components/molecules";

import { RunForm } from "./RunForm";

export const RunCreate = () => {
  return (
    <PageContainer>
      <PageHeader title="Create new training run" />
      <RunForm />
    </PageContainer>
  );
};

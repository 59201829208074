import { useMemo } from "react";
import { useNodes } from "reactflow";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { NodeTypes } from "components/templates/Diagram/types";
import { getObjectsWithoutDiamondsFromNodes } from "components/templates/Diagram/utils";

import type { NodeDataType } from "components/templates/Diagram/types";

export const OperatorManyToOneForm = () => {
  const nodes = useNodes<NodeDataType>();

  const pipelineDiagramObjects = getObjectsWithoutDiamondsFromNodes(nodes);

  const destinationObjectOptions = pipelineDiagramObjects.filter(
    (object) => object.name !== NodeTypes.pipeline_start
  );

  const options = useMemo(
    () =>
      destinationObjectOptions?.map(({ name, ...data }) => ({
        label: name,
        value: name,
        name,
        ...data,
      })),
    [destinationObjectOptions]
  );

  return (
    <>
      <AutoCompleteSelectHookForm
        id="destinationObject"
        name="destinationObject"
        label="Destination object"
        placeholder="Select..."
        options={options}
        isSearchable
      />
    </>
  );
};

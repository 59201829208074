import { BaseButtonWithTooltip } from "components/atoms/Button/BaseButtonWithTooltip";

import type { ButtonProps } from "components/atoms/Button/types";

export const DeleteButton = (props: ButtonProps) => {
  return (
    <BaseButtonWithTooltip color="error" variant="contained" {...props}>
      Delete
    </BaseButtonWithTooltip>
  );
};

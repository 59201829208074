import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  projectUsersDelete,
  useProjectUsersList,
} from "libs/data/endpoints/projects/projects";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useProjectUserDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useProjectUsersList(projectName, {
    user_type: "user",
  });

  return useCallback(
    async (userId: any) => {
      if (!projectName) {
        return Promise.reject();
      }

      return projectUsersDelete(projectName, userId)
        .then(async (result) => {
          dispatch(createSuccessNotification("The project member was removed"));
          await mutate();

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, dispatch, mutate]
  );
};

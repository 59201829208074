import { useCallback } from "react";
import { useParams } from "react-router-dom";

import {
  FIELD_VERSION,
  FIELD_DESCRIPTION,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_LABELS,
} from "libs/constants/fields";
import { usePipelineVersionUpdate } from "libs/data/customized/pipeline";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { getChanges } from "libs/utilities/patch-helper";

import type { PipelineVersionRouteParams } from "./types";
import type { PipelineVersionBaseProps } from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/versions/PipelineVersionBaseForm/constants";

export function useUpdatePipelineVersion() {
  const { projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();
  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  const updatePipelineVersion = usePipelineVersionUpdate(
    projectName,
    pipelineName,
    versionName
  );

  return useCallback(
    (data: PipelineVersionBaseProps) => {
      const newVersion = {
        [FIELD_VERSION]: data[FIELD_VERSION],
        [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
        [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE],
        ...(data[FIELD_RETENTION_MODE] !== "none" && {
          [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME],
        }),
      };

      const changes = {
        ...getChanges(
          newVersion as unknown as Record<string, string>,
          pipelineVersion as unknown as Record<string, string>
        ),
        [FIELD_LABELS]: data[FIELD_LABELS],
      };

      return updatePipelineVersion(changes);
    },
    [pipelineVersion, updatePipelineVersion]
  );
}

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  TIME_OUT_BROWSER,
  TIME_OUT_REQUESTS,
  REQUEST_TASK,
} from "libs/constants/constants";
import {
  deploymentRequestsCreate,
  deploymentVersionRequestsCreate,
  useDeploymentVersionRequestsList,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { createErrorNotification } from "libs/utilities/notifications";
import { ERROR, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { addTask, updateTask } from "store/features/taskManager";

import type { AxiosError } from "axios";
import type {
  DeploymentRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
} from "libs/data/models";

export const useDeploymentDirectRequestCreate = (
  projectName: string,
  deploymentName: string,
  version?: string,
  timeout?: number
) => {
  const dispatch = useDispatch();
  const taskId = getRandomId();
  const { mutate } = useDeploymentVersionRequestsList(
    projectName,
    deploymentName,
    version ?? "",
    undefined,
    { swr: { enabled: !!deploymentName } }
  );

  return useCallback(
    async (data: DeploymentRequestsCreateDataBody, requestParameters: any) => {
      const result: {
        responseData: DeploymentRequestCreateResponse | undefined;
        requestData: DeploymentRequestsCreateDataBody;
      } = {
        responseData: undefined,
        requestData: data,
      };
      if (!requestParameters.isRetentionModeNone)
        dispatch(
          addTask({
            id: taskId,
            type: REQUEST_TASK,
            status: LOADING,
            message: `${requestParameters.type} request`,
            requestParameters: requestParameters,
          })
        );
      try {
        const response = version
          ? await deploymentVersionRequestsCreate(
              projectName,
              deploymentName,
              version,
              data,
              { timeout: timeout ?? TIME_OUT_REQUESTS },
              { timeout: TIME_OUT_BROWSER }
            )
          : await deploymentRequestsCreate(
              projectName,
              deploymentName,
              data,
              { timeout: timeout ?? TIME_OUT_REQUESTS },
              {
                timeout: TIME_OUT_BROWSER,
              }
            );
        if (response) {
          dispatch(
            updateTask({
              id: taskId,
              status: LOADING,
              result: { ...result, responseData: response },
            })
          );
        }

        return { ...result, responseData: response };
      } catch (err: any) {
        if (!err?.response?.data?.error_message)
          dispatch(createErrorNotification((err as AxiosError)?.message));
        dispatch(
          updateTask({
            id: taskId,
            status: ERROR,
            result: { ...result, responseData: err?.response?.data },
          })
        );

        return { ...result, responseData: err?.response?.data };
      } finally {
        mutate();
      }
    },
    [deploymentName, dispatch, projectName, taskId, timeout, version, mutate]
  );
};

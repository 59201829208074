import { useState, useCallback } from "react";

import type { SetStateAction, Dispatch } from "react";

export type iDialogMethods = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  toggleDialog: () => void;
};

export type iUseDialog = (defaultOpen?: boolean) => iDialogMethods;

export const useDialog: iUseDialog = (defaultOpen = false) => {
  const [open, setOpen] = useState(defaultOpen);

  const toggleDialog = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return { open, setOpen, toggleDialog };
};

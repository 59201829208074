import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentsCreate,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { DeploymentCreate } from "libs/data/models";

export const useDeploymentCreate = (projectName: string, message?: string) => {
  const { mutate } = useDeploymentsList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/deployments`,
    },
  });
  const dispatch = useDispatch();

  return useCallback(
    async (data: DeploymentCreate) => {
      try {
        const result = await deploymentsCreate(projectName, data);
        await mutate();
        dispatch(
          createSuccessNotification(
            message ?? "Deployment successfully created"
          )
        );

        return result;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(
          createErrorNotification(error.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, message, mutate, projectName]
  );
};

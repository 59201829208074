import {
  ResultsField,
  ResultsFieldWithPagination,
} from "components/molecules/index";

import type {
  DeploymentOutputFieldCreate,
  DeploymentRequestSingleDetailResult,
  InputOutputFieldDetail,
  InputOutputFieldList,
} from "libs/data/models";

export interface RequestResultsDataProps {
  data?: DeploymentRequestSingleDetailResult;
  error: boolean;
  fields:
    | InputOutputFieldList[]
    | InputOutputFieldDetail[]
    | DeploymentOutputFieldCreate[];
  itemsPerPage?: number;
  projectName: string;
  organizationName: string;
}

export const RequestResultsData = ({
  data,
  itemsPerPage,
  ...props
}: RequestResultsDataProps) => {
  return Array.isArray(data) ? (
    <ResultsFieldWithPagination
      data={data}
      itemsPerPage={itemsPerPage}
      {...props}
    />
  ) : (
    <ResultsField data={data} {...props} />
  );
};

import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import {
  FIELD_ROLE,
  FIELD_INDUSTRY,
  FIELD_SIGNUP_REASON,
  FIELD_ORGANIZATION,
  FIELD_VOUCHER,
} from "libs/constants/fields";
import { useOrganizationCreate } from "libs/data/customized/organization";
import { useUserQuestionnaire } from "libs/data/customized/user";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";

import { Dialog, PrimaryButton } from "components/atoms";

import { NewAccountFirstOrganization } from "./NewAccountFirstOrganization";
import NewAccountQuestionsForm from "./NewAccountQuestionsForm";

type NewAccountDialogProps = {
  open: boolean;
  closeDialog: (args: boolean) => void;
  setOrganizationName: (name: string) => void;
};

const NewAccountDialog = ({
  open,
  closeDialog,
  setOrganizationName,
}: NewAccountDialogProps) => {
  const shouldSkipQuestionnaire = env.get(ENV_NAMES.ON_PREMISE);

  const [step, setStep] = useState(shouldSkipQuestionnaire ? 1 : 0);
  const createOrganization = useOrganizationCreate();
  const signUpInfo = useUserQuestionnaire();

  const questionsFormMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit: handleQuestionsSubmit, watch } = questionsFormMethods;

  const projectFormMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit: handleProjectSubmit } = projectFormMethods;

  const questionFormEnabled =
    watch(FIELD_ROLE)?.value &&
    watch(FIELD_INDUSTRY)?.value &&
    watch(FIELD_SIGNUP_REASON)?.value;

  const handleOrganizationCreate = async (data: { [x: string]: string }) => {
    const newOrg = await createOrganization({
      name: data[FIELD_ORGANIZATION],
      subscription: data[FIELD_VOUCHER]
        ? undefined
        : env.get(ENV_NAMES.ON_PREMISE)
        ? "default"
        : "free",
      voucher: data[FIELD_VOUCHER] ? data[FIELD_VOUCHER] : undefined,
    });
    if (newOrg) {
      setOrganizationName(newOrg.name);
      closeDialog(false);
    }
  };

  const handleAnswersSubmit = (data: { [x: string]: { value: string } }) => {
    const answers = {
      [FIELD_ROLE]: data[FIELD_ROLE].value,
      [FIELD_INDUSTRY]: data[FIELD_INDUSTRY].value,
      [FIELD_SIGNUP_REASON]: data[FIELD_SIGNUP_REASON].value,
    };
    signUpInfo(answers);
    setStep(1);
  };

  return (
    <Dialog
      container=".main-layout"
      dismissible={false}
      open={open}
      onClose={closeDialog}
      title="Welcome to UbiOps!"
      Actions={
        step === 0 ? (
          <PrimaryButton
            style={{ alignSelf: "flex-end" }}
            onClick={handleQuestionsSubmit(handleAnswersSubmit)}
            disabled={!questionFormEnabled}
          >
            Next
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={handleProjectSubmit(handleOrganizationCreate)}
          >
            Create organization
          </PrimaryButton>
        )
      }
    >
      <div hidden={step !== 0}>
        <FormProvider {...questionsFormMethods}>
          <NewAccountQuestionsForm />
        </FormProvider>
      </div>
      <div hidden={step !== 1}>
        <FormProvider {...projectFormMethods}>
          <NewAccountFirstOrganization
            submitForm={handleProjectSubmit(handleOrganizationCreate)}
          />
        </FormProvider>
      </div>
    </Dialog>
  );
};

export default NewAccountDialog;

import "./Footer.scss";
import type { ReactNode } from "react";

interface FooterProps {
  children: ReactNode | ReactNode[];
}

export const Footer = ({ children }: FooterProps) => {
  return <div className="footer">{children}</div>;
};

import styled from "@emotion/styled";
import { Box, useTheme } from "@mui/material";
import { Handle, Position } from "reactflow";

import type { PipelineVersionObjectList } from "libs/data/models";
import type { NodeProps } from "reactflow";

const S_Handle = styled(Handle)`
  &&& {
    z-index: 999;
  }
`;

export type NodeDiamondProps = NodeProps & {
  data: {
    pipelineObject: PipelineVersionObjectList;
  };
};

export const NodeDiamond = ({ isConnectable }: NodeDiamondProps) => {
  const theme = useTheme();

  return (
    <Box display="flex">
      <S_Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />

      <Box
        width={20}
        height={20}
        style={{ transform: "rotate(45deg)" }}
        border={`2px solid ${theme.palette.secondary.main} `}
      />

      <S_Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </Box>
  );
};

import { injectExternalScript } from "libs/utilities/injectExternalScript";

export const setupHotjar = () => {
  window.hj =
    window.hj ||
    function (...args: unknown[]) {
      (window.hj.q = window.hj.q || []).push(...args);
    };
  window._hjSettings = {
    hjid: 2315945,
    hjsv: 6,
  };

  injectExternalScript(
    `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`,
    "ubiops-hotjar"
  );
};

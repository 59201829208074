import { Box } from "@mui/material";

import { borders, spacing } from "assets/styles/theme";

import type { BoxProps } from "@mui/material";

export const ListItem = (props: BoxProps) => (
  <Box
    borderTop={borders.tertiary}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: `${spacing[48]}`,
    }}
    {...props}
  />
);

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TIME_OUT_FILES } from "libs/constants/constants";
import {
  importsCreate,
  useImportsGet,
} from "libs/data/endpoints/imports-and-exports/imports-and-exports";
import { ImportDetailStatus } from "libs/data/models";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError, AxiosProgressEvent } from "axios";
import type { ImportsCreateBody } from "libs/data/models";

export const useImportUpload = (projectName: string | undefined) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [importId, setImportId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] =
    useState<string | null | undefined>(null);
  const { data: uploadProgress, error } = useImportsGet(
    String(projectName),
    String(importId),
    {
      swr: {
        enabled: Boolean(projectName && importId && isLoading),
        refreshInterval: 2000,
        dedupingInterval: 0,
      },
    }
  );

  useEffect(() => {
    if (uploadProgress?.status === ImportDetailStatus.confirmation) {
      setLoading(false);
    }
    if (uploadProgress?.status === ImportDetailStatus.failed) {
      setLoading(false);
      setErrorMessage(uploadProgress?.error_message);
    }
  }, [uploadProgress]);

  const onUploadProgress = (event: AxiosProgressEvent) => {
    setPercentage(Math.round((event.loaded * 100) / (event?.total || 1)));
  };

  const uploadFile = async (fileData: ImportsCreateBody) => {
    if (!projectName) return;

    setErrorMessage(null);
    setImportId(null);
    setLoading(true);
    setUploadLoading(true);

    try {
      const { id } = await importsCreate(projectName, fileData, {
        onUploadProgress,
        timeout: TIME_OUT_FILES,
      });
      setImportId(id);
    } catch (error) {
      dispatch(createErrorNotification((error as AxiosError).message));
      setLoading(false);
    } finally {
      setUploadLoading(false);
    }
  };

  return {
    uploadFile,
    isLoading,
    percentage,
    uploadLoading,
    importId,
    importError: errorMessage || error?.message,
  };
};

import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_NAME } from "libs/constants/fields";
import { useRequestScheduleUpdate } from "libs/data/customized/request-schedules";
import { useRequestSchedulesGet } from "libs/data/endpoints/request-schedules/request-schedules";
import { routes } from "routes";

import { FormWrapper, Loader } from "components/atoms";
import { PageContainer } from "components/molecules";

import { RequestScheduleBaseForm } from "../RequestScheduleBaseForm";

import type { FormikHelpers } from "formik";
import type { ScheduleCreate } from "libs/data/models";

export const RequestScheduleUpdate = () => {
  const { organizationName, projectName, scheduleName } =
    useParams<{
      organizationName: string;
      projectName: string;
      scheduleName: string;
    }>();
  const history = useHistory();
  const {
    data: scheduleDetails,
    error,
    mutate,
  } = useRequestSchedulesGet(projectName, scheduleName);
  const isLoading = !scheduleDetails && !error;

  const updateSchedule = useRequestScheduleUpdate(projectName);

  const handleScheduleUpdate = async (
    data: ScheduleCreate,
    actions: FormikHelpers<ScheduleCreate>
  ) => {
    try {
      await updateSchedule(scheduleName, data);
      mutate();
    } finally {
      actions.setSubmitting(false);
    }

    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .requestSchedules[":scheduleName"](data[FIELD_NAME])
        .index()
    );
  };

  return (
    <PageContainer>
      <PageHeader title={`Edit ${scheduleDetails?.name}`} />
      <FormWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <RequestScheduleBaseForm
            defaultValues={scheduleDetails as ScheduleCreate}
            handleOnSubmit={handleScheduleUpdate}
            isEditForm
          />
        )}
      </FormWrapper>
    </PageContainer>
  );
};

const typography = {
  useNextVariants: true,
  fontFamily: [
    "Nunito Sans",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 14,
  h1: {
    fontSize: "1.7em",
    fontWeight: 600,
  },
  h2: {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  h3: {
    fontSize: "1.2em",
    fontWeight: 600,
  },
  h4: {
    fontSize: "1.1em",
    fontWeight: 600,
  },
  h5: {
    fontSize: "1em",
    fontWeight: 600,
  },
  h6: {
    fontSize: "0.9em",
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: "1em",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "0.9em",
    fontWeight: 400,
  },
  body1: {
    fontSize: "1em",
    fontWeight: 300,
  },
  body2: {
    fontSize: "0.9em",
    fontWeight: 300,
  },
  button: {
    textTransform: "none",
  },
  caption: {
    fontSize: "0.7em",
    fontWeight: 400,
  },
};

export default typography;

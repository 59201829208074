export const deploymentMetricsGraphIds = {
  requests: "deployments.requests",
  requestDuration: "deployments.request_duration",
  credits: "deployments.credits",
  instances: "deployments.instances",
  memoryUtilization: "deployments.memory_utilization",
  expressQueueTime: "deployments.express_queue_time",
  batchQueueTime: "deployments.batch_queue_time",
  expressQueueSize: "deployments.express_queue_size",
  batchQueueSize: "deployments.batch_queue_size",
  instanceStartTime: "deployments.instance_start_time",
  networkOut: "deployments.network_out",
  networkIn: "deployments.network_in",
  activeTime: "deployments.active_time",
  gpuUtilization: "deployments.gpu_utilization",
  cpuUtilization: "deployments.cpu_utilization",
  outputVolume: "deployments.output_volume",
  inputVolume: "deployments.input_volume",
  failedRequests: "deployments.failed_requests",
};

import "./MainLayout.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import DesktopIcon from "@mui/icons-material/DesktopWindows";
import Equalizer from "@mui/icons-material/Equalizer";
import {
  Hidden,
  CssBaseline,
  Drawer,
  Divider,
  Typography,
  Collapse,
  IconButton,
  Grid,
  useTheme,
  Box,
} from "@mui/material";
import clsx from "clsx";
import React, { useState, useEffect, useContext } from "react";
import { ChevronsLeft } from "react-feather";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  FIELD_CREDIT_LIMIT,
  FIELD_CREDIT_LIMIT_ENABLED,
} from "libs/constants/fields";
import { LoaderContext, BaseUrlContext } from "libs/contexts";
import { useProjectUpdate } from "libs/data/customized/projects";
import { useProjectsUsageGet } from "libs/data/endpoints/projects/projects";
import { OrganizationDetailStatus } from "libs/data/models";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useDialog, useDeviceDetect } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { getCompute } from "libs/utilities/metrics-helper";
import {
  useGetCurrentOrganization,
  useGetCurrentProject,
  useGetOrganizationFeatures,
  useGetOrganizationUsage,
} from "store/features";
import { useTaskManager } from "store/features/taskManager";

import {
  FullScreenLoader,
  Copyright,
  Alert,
  Footer,
  Dialog,
  PrimaryButton,
} from "components/atoms";
import { ProjectComputeLimitSliderBar } from "components/molecules";
import { Header, TasksManager } from "components/organisms";

import Sidebar from "./Sidebar";
import SidebarOrganizationSelect from "./SidebarOrganizationSelect";
import { SidebarProjectName } from "./SidebarProjectName";
import SidebarSupportSection from "./SidebarSupportSection";
import SidebarUsage from "./SidebarUsage";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AppThemeProps } from "assets/styles/theme/theme";

const drawerWidth = 220;

type RouteElement = {
  label: string;
  path?: string;
  icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
};

type MainLayoutProps = {
  routes: (baseUrl: string, isAdmin: boolean) => RouteElement[][];
  children: React.ReactNode;
};

const MainLayout = ({ routes, children }: MainLayoutProps) => {
  const location = useLocation();
  const theme = useTheme() as AppThemeProps;
  const history = useHistory();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const baseUrl = useContext(BaseUrlContext);
  const [open, setOpen] = useState(false);
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [displayed, showLoader] = useState(false);
  const [projectLimitIsOrganizationLimit, setProjectLimitIsOrganizationLimit] =
    useState(false);
  const [isComputeLimitDialogOpen, setIsComputeLimitDialogOpen] =
    useState(false);

  const { projectName } = useParams<{ projectName: string }>();

  const organization = useGetCurrentOrganization();
  const currentProject = useGetCurrentProject();
  const organizationFeatures = useGetOrganizationFeatures();
  const organizationUsage = useGetOrganizationUsage();
  const updateProject = useProjectUpdate(organization?.name || "");

  const { data: projectUsage } = useProjectsUsageGet(
    projectName,
    { interval: "day" },
    { swr: { enabled: env.get(ENV_NAMES.METRICS_AGGREGATION_ENABLED) } }
  );

  const temporarySidebarDrawer = useDialog();
  const {
    toggleDialog: toggleTemporarySidebar,
    open: temporarySidebarOpen,
    setOpen: setTemporarySidebar,
  } = temporarySidebarDrawer;
  const formMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = formMethods;

  const { isMobile } = useDeviceDetect();
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      const element = document.querySelector(".main-layout__body");
      if (element && element.scrollTo) {
        element.scrollTo(0, 0);
      }
    };

    scrollToTop();
  }, [pathname]);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };
  useEffect(() => {
    if (organization?.status !== "cancelled") {
      // Set the usage percentage to current project usage if available, otherwise use organization usage
      if (projectUsage && currentProject?.credits) {
        setUsagePercentage(
          Math.round(
            (getCompute(projectUsage?.data_project ?? []) /
              currentProject?.credits) *
              100
          )
        );
        setProjectLimitIsOrganizationLimit(false);
      } else if (organizationUsage && organizationFeatures?.max_credits) {
        setUsagePercentage(
          Math.round(
            (getCompute(organizationUsage?.data_organization ?? []) /
              organizationFeatures?.max_credits) *
              100
          )
        );
        setProjectLimitIsOrganizationLimit(true);
      }
    }
  }, [
    currentProject?.credits,
    organization?.status,
    organizationFeatures?.max_credits,
    organizationUsage,
    projectUsage,
  ]);

  useEffect(() => {
    const projectCredits = currentProject?.credits || 0;
    if (usagePercentage >= 85 && projectCredits > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [usagePercentage, currentProject]);

  useEffect(() => {
    const handleResize = () => {
      setTemporarySidebar(false);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setTemporarySidebar]);

  const onComputeLimitUpdate = (data: {
    [FIELD_CREDIT_LIMIT_ENABLED]: boolean;
    [FIELD_CREDIT_LIMIT]: number;
  }) => {
    updateProject(currentProject?.name || "", {
      [FIELD_CREDIT_LIMIT]: data[FIELD_CREDIT_LIMIT_ENABLED]
        ? data[FIELD_CREDIT_LIMIT]
        : null,
    });
    setIsComputeLimitDialogOpen(false);
  };

  const { tasksManager } = useTaskManager();

  return (
    <div
      className={clsx("main-layout", {
        "main-layout--sidebar-open": sidebarIsOpen,
      })}
    >
      <Header toggleSidebar={toggleTemporarySidebar} />
      <CssBaseline />
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          open={temporarySidebarOpen}
          onClose={toggleTemporarySidebar}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "44px",
            }}
          >
            <button
              className="main-layout__collapse-button"
              onClick={toggleTemporarySidebar}
            >
              <ChevronsLeft />
            </button>
          </Box>
          <LoaderContext displayed={displayed} showLoader={showLoader}>
            <SidebarOrganizationSelect
              sidebarIsOpen={sidebarIsOpen}
              toggleSidebar={toggleTemporarySidebar}
            />
          </LoaderContext>
          <LoaderContext displayed={displayed} showLoader={showLoader}>
            <SidebarProjectName
              toggleSidebar={toggleTemporarySidebar}
              sidebarIsOpen={sidebarIsOpen}
            />
          </LoaderContext>
          <Sidebar
            routes={routes}
            currentProject={currentProject}
            currentOrganization={organization}
            sidebarIsOpen={sidebarIsOpen}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="js">
        <IconButton
          onClick={toggleSidebar}
          sx={{
            left: sidebarIsOpen ? "208px" : "37px",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25px",
            width: "25px",
            top: "178px",
            backgroundColor: theme.palette.sidebarMenu.chevronButton,
            color: theme.palette.sidebarMenu.chevronColor,
            zIndex: "3",
            transition: theme.transitions.create("left", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            "&.MuiIconButton-root:hover": {
              backgroundColor: theme.palette.tertiary.light,
              color: theme.palette.text.primary,
            },
          }}
        >
          {sidebarIsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Drawer
          variant="permanent"
          sx={{
            ".MuiPaper-root": {
              width: sidebarIsOpen ? drawerWidth : "46px",
              flexShrink: 0,
              whiteSpace: "nowrap",
              background: theme.palette.sidebarMenu.background,
              overflowX: "hidden",
              paddingTop: "44px",
              zIndex: "2",
              scrollbarWidth: "none",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: sidebarIsOpen
                  ? theme.transitions.duration.enteringScreen
                  : theme.transitions.duration.leavingScreen,
              }),
              "&::-webkit-scrollbar": {
                width: "0",
              },
            },
          }}
          id="drawer"
        >
          <LoaderContext displayed={displayed} showLoader={showLoader}>
            <SidebarOrganizationSelect
              sidebarIsOpen={sidebarIsOpen}
              toggleSidebar={toggleTemporarySidebar}
            />
          </LoaderContext>
          <LoaderContext displayed={displayed} showLoader={showLoader}>
            <SidebarProjectName
              toggleSidebar={toggleTemporarySidebar}
              sidebarIsOpen={sidebarIsOpen}
            />
          </LoaderContext>
          <Divider />
          <Sidebar
            routes={routes}
            currentProject={currentProject}
            currentOrganization={organization}
            sidebarIsOpen={sidebarIsOpen}
            toggleSidebar={toggleSidebar}
          />
          <div className="main-layout__sidebar-footer">
            <Divider />
            <LoaderContext displayed={displayed} showLoader={showLoader}>
              <SidebarUsage
                organization={organization}
                organizationFeatures={organizationFeatures}
                usage={getCompute(organizationUsage?.data_organization ?? [])}
                toggleSidebar={toggleTemporarySidebar}
                sidebarIsOpen={sidebarIsOpen}
              />
            </LoaderContext>
            <Divider />
            <SidebarSupportSection sidebarIsOpen={sidebarIsOpen} />
            <Divider />
          </div>
        </Drawer>
      </Hidden>
      <div className="main-layout__body">
        <div className="main-layout__alert">
          {isMobile && (
            <Alert severity="info" icon={<DesktopIcon fontSize="inherit" />}>
              <Typography variant="body1">
                Using UbiOps from your desktop is recommended for a better user
                experience.
              </Typography>
            </Alert>
          )}
        </div>

        {(organization?.status &&
          organization?.status === OrganizationDetailStatus.suspended_manual) ||
          (organization?.status === OrganizationDetailStatus.suspended_usage &&
            explanations.security.subscription(
              "suspended",
              `${baseUrl}/${organization?.name}/subscription`
            ))}

        {organization?.status &&
          organization?.status === "cancelled" &&
          explanations.security.subscription(
            "cancelled",
            `${baseUrl}/${organization?.name}/subscription`
          )}

        <div className="main-layout__collapse">
          <Collapse in={open} sx={{ width: "100%" }}>
            <Alert
              severity={usagePercentage < 100 ? "warning" : "error"}
              className="main-layout__alert"
              action={
                <Grid container spacing={2} alignItems="center">
                  {/* we're not sure what the sales team wants to do with this link so it's hidden for now */}
                  {/* <Grid item>
                        <ExternalLink href={LINK_PRICING}>See pricing plans</ExternalLink>
                      </Grid> */}
                  <Grid item>
                    {projectLimitIsOrganizationLimit ? (
                      <PrimaryButton
                        size="small"
                        onClick={() =>
                          history.push(
                            `/organizations/${organization?.name}/subscription`
                          )
                        }
                      >
                        Upgrade
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        size="small"
                        startIcon={<Equalizer />}
                        onClick={() => setIsComputeLimitDialogOpen(true)}
                      >
                        Change project credit limit
                      </PrimaryButton>
                    )}
                  </Grid>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              }
            >
              You are currently using <b>{usagePercentage}%</b> of your monthly
              allowed usage.
            </Alert>
          </Collapse>
        </div>
        {!!tasksManager && location.pathname.includes("/projects") && (
          <TasksManager organizationName={organization?.name || ""} />
        )}
        <LoaderContext displayed={displayed} showLoader={showLoader}>
          <FullScreenLoader displayed={displayed} />
          {children}
        </LoaderContext>
        <Dialog
          onClose={() => setIsComputeLimitDialogOpen(false)}
          title="Changing credit limit"
          open={isComputeLimitDialogOpen}
          Actions={
            <PrimaryButton onClick={handleSubmit(onComputeLimitUpdate)}>
              Update
            </PrimaryButton>
          }
        >
          <FormProvider {...formMethods}>
            <form>
              <ProjectComputeLimitSliderBar />
            </form>
          </FormProvider>
        </Dialog>

        <Footer>
          <Copyright />
        </Footer>
      </div>
    </div>
  );
};

export default MainLayout;

import { Grid } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { LabelChip } from "components/atoms";

type LabelsListProps = {
  labels: { key: string; value: string }[];
  onLabelDelete: (key: string, value: string) => void;
};

export const LabelsList = ({ labels, onLabelDelete }: LabelsListProps) => (
  <Grid container>
    {labels?.map(({ key, value }) => (
      <Grid
        item
        key={key}
        style={{ marginRight: spacing[16], marginBottom: spacing[6] }}
      >
        <LabelChip
          label={key}
          value={value}
          onDelete={() => onLabelDelete(key, value)}
        />
      </Grid>
    ))}
  </Grid>
);

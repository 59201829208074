import { useCallback } from "react";

import {
  pipelineVersionsUpdate,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";

import type { PipelineVersionUpdate } from "libs/data/models";

export const usePipelineVersionUpdate = (
  projectName: string,
  pipelineName: string,
  versionName: string
) => {
  const { mutate } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  return useCallback(
    async (data: PipelineVersionUpdate) => {
      if (!pipelineName || !projectName || !versionName) {
        return Promise.reject({
          error:
            "Couldn't make the API call because projectName and/or pipelineName and/or versionName are not available",
        });
      }

      return pipelineVersionsUpdate(
        projectName,
        pipelineName,
        versionName,
        data
      ).then(async (result) => {
        await mutate();

        return result;
      });
    },
    [mutate, pipelineName, projectName, versionName]
  );
};

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_NAME,
} from "libs/constants/fields";
import {
  environmentRevisionsFileUpload,
  environmentsCreate,
  useEnvironmentsList,
} from "libs/data/endpoints/environments/environments";
import { explanations } from "libs/utilities/explanations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import validators, {
  environmentDependenciesValidator,
} from "libs/utilities/validators";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import {
  Dialog,
  DialogDescription,
  FormTextField,
  FormWrapper,
  Icon,
  PrimaryButton,
} from "components/atoms";
import {
  CodeEnvironmentSelect,
  formatCodeEnvironment,
  FormSection,
} from "components/molecules";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

const REQUIREMENTS_FILE_FIELD = "requirements_file";

interface FormData {
  [FIELD_NAME]: string;
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: AutocompleteSelectOption;
  [REQUIREMENTS_FILE_FIELD]: File[];
}

interface CodeEnvironmentDialogProps {
  gpuEnabled: boolean;
  onClose: () => void;
  onCreate: (option: AutocompleteSelectOption) => void;
  open: boolean;
  projectName: string;
}

export const CodeEnvironmentDialog = ({
  gpuEnabled,
  onClose,
  onCreate,
  open,
  projectName,
}: CodeEnvironmentDialogProps) => {
  const dispatch = useDispatch();
  const formMethods = useForm<FormData>();
  const {
    clearErrors,
    errors,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = formMethods;
  const { mutate: mutateEnvironments } = useEnvironmentsList(projectName);
  const [dependencyFile, setDependencyFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    register(REQUIREMENTS_FILE_FIELD, {
      required: validators.required.message("custom dependency file"),
    });
  }, [register]);

  const handleChange = (file: File) => {
    if (file) {
      if (environmentDependenciesValidator(file.name)) {
        clearErrors();
        setValue(REQUIREMENTS_FILE_FIELD, [file]);
        setDependencyFile(file);
      } else {
        setValue(REQUIREMENTS_FILE_FIELD, undefined);
        setDependencyFile(null);
        setError(REQUIREMENTS_FILE_FIELD, {
          message:
            "Your requirements must be contained within a requirements.txt, ubiops.yaml or a zip file.",
        });
      }
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const response = await environmentsCreate(projectName, {
        name: data[FIELD_NAME],
        base_environment: data[FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]
          ?.value as string,
      });

      if (dependencyFile) {
        await environmentRevisionsFileUpload(projectName, response.name, {
          file: dependencyFile,
        });
      }

      dispatch(createSuccessNotification("Environment created"));
      await mutateEnvironments();
      onCreate(formatCodeEnvironment(response));
      onClose();
      setDependencyFile(null);
      reset();
    } catch (err: any) {
      dispatch(createErrorNotification(err?.message ?? "Something went wrong"));
    }
    setLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title="Create new environment"
      Actions={
        <PrimaryButton loading={loading} onClick={handleSubmit(onSubmit)}>
          Save
        </PrimaryButton>
      }
      fullWidth
    >
      <DialogDescription>
        Create a new environment by specifying the language and any
        dependencies. This environment can then be used for deployments and
        experiments.
      </DialogDescription>
      <Box height="100%">
        <FormProvider {...formMethods}>
          <FormWrapper fullWidth>
            <FormSection title="Name">
              <FormTextField
                name={FIELD_NAME}
                label="Name"
                placeholder="Ex: example-environment"
                rules={{
                  required: validators.required.message("environment name"),
                }}
                withError={true}
              />
            </FormSection>
            <FormSection
              title="Base environment"
              description={explanations.environments.baseEnvironment}
            >
              <CodeEnvironmentSelect
                deployment={TRAINING_DEPLOYMENT}
                name={FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT}
                gpuEnabled={gpuEnabled}
                projectName={projectName}
              />
            </FormSection>
            <FormSection
              title="Custom dependencies"
              description={explanations.environments.customDependencies}
            >
              <DropSelectFile
                handleChange={handleChange}
                types={["zip", "txt", "R", "yaml"]}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginBottom={2}
                >
                  <Typography align="center">
                    Drag and drop your file here to upload it
                  </Typography>
                  <Typography>or</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <PrimaryButton>
                    {dependencyFile ? "Change file" : "Upload file"}
                  </PrimaryButton>
                </Box>
                {dependencyFile && (
                  <Box display="flex" alignItems="center" marginTop={2}>
                    <Icon
                      component={CheckCircleRoundedIcon}
                      status="available"
                    />
                    <Typography variant="body2" display="inline">
                      {dependencyFile?.name}
                    </Typography>
                  </Box>
                )}
              </DropSelectFile>
              {!!errors?.[REQUIREMENTS_FILE_FIELD] && (
                <FormHelperText
                  id={REQUIREMENTS_FILE_FIELD}
                  error={!!errors?.[REQUIREMENTS_FILE_FIELD]}
                >
                  {(errors[REQUIREMENTS_FILE_FIELD] as any).message}
                </FormHelperText>
              )}
            </FormSection>
          </FormWrapper>
        </FormProvider>
      </Box>
    </Dialog>
  );
};

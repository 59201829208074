import {
  DESCRIPTION_FIELD_MAX_CHARACTERS,
  NAME_FIELD_MAX_CHARACTERS,
} from "libs/constants/constants";
import { FIELD_NAME, FIELD_DESCRIPTION } from "libs/constants/fields";
import validators from "libs/utilities/validators";

import { FormTextField } from "components/atoms";
import { FormSection } from "components/molecules";

import type { ReactElement } from "react";

type GeneralFieldsSectionProps = {
  title?: string;
  description?: ReactElement | string;
  name?: string;
  label?: string;
  customClassName?: string;
  ruleName?: string;
  validateValue?: string;
  nameDefaultValue?: string;
  namePlaceholder?: string;
  descriptionDefaultValue?: string;
  descriptionPlacholder?: string;
  info?: React.ReactNode;
  duplicateOption?: React.ReactNode;
  notes?: React.ReactNode;
  children?: React.ReactNode;
  descriptionField?: boolean;
  descriptionName?: string;
};

const GeneralFieldsSection = ({
  title = "General",
  description,
  name = FIELD_NAME,
  descriptionField = true,
  descriptionName = FIELD_DESCRIPTION,
  label = "Name",
  customClassName = "required-field-with-asterisk",
  ruleName = FIELD_NAME,
  validateValue,
  nameDefaultValue,
  namePlaceholder,
  descriptionDefaultValue,
  descriptionPlacholder,
  info,
  duplicateOption,
  notes,
  children,
}: GeneralFieldsSectionProps) => {
  return (
    <FormSection title={title} description={description}>
      {info && info}
      {duplicateOption ? (
        duplicateOption
      ) : (
        <FormTextField
          id={name}
          name={name}
          label={label}
          className={customClassName}
          rules={{
            required: validators.required.message(ruleName),
            pattern: {
              value: validators.name.pattern,
              message: validators.name.message(ruleName),
            },
            validate: validators.name.value(validateValue),
          }}
          defaultValue={nameDefaultValue}
          placeholder={namePlaceholder}
          required
          inputProps={{
            maxLength: NAME_FIELD_MAX_CHARACTERS,
          }}
        />
      )}
      {notes && notes}
      {descriptionField && (
        <FormTextField
          name={descriptionName}
          label="Description"
          defaultValue={descriptionDefaultValue}
          placeholder={descriptionPlacholder}
          withCharCounter
          multiline
          minRows={3}
          inputProps={{
            maxLength: DESCRIPTION_FIELD_MAX_CHARACTERS,
          }}
          maxChars={DESCRIPTION_FIELD_MAX_CHARACTERS}
        />
      )}
      {children}
    </FormSection>
  );
};

export default GeneralFieldsSection;

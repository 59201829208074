/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  BucketList,
  BucketsListParams,
  BucketCreate,
  BucketDetail,
  BucketUpdate,
  FileDetail,
  FilesListParams,
  FileItem,
  FileUploadResponse,
  FileUploadBody,
  FilesUploadParams,
  FileMultipartUpload,
  FileCompleteMultipartUpload,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List buckets in a project

### Optional Parameters
- `labels`: Filter on labels of the buckets. Should be given in the format 'label:label_value'. Separate multiple label-pairs with a comma (,). Buckets that have at least one of the labels in the filter are returned. This parameter should be given as query parameter.

### Response Structure
A list of details of the buckets in the project
- `id`: Unique identifier for the bucket (UUID)
- `name`: Name of the bucket
- `project`: Project name in which the bucket is defined
- `provider`: Provider of the bucket
- `configuration`: Additional configuration details for the bucket
- `creation_date`: The date when the bucket was created
- `description`: Description of the bucket
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `ttl`: Time to live for the files in the bucket

#### Response Examples
```
[
  {
    "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
    "name": "bucket-1",
    "project": "project-1",
    "provider": "ubiops",
    "creation_date": "2022-05-12T16:23:15.456812Z",
    "configuration": {},
    "labels": {
      "type": "bucket"
    },
    "description": "My bucket description",
    "ttl": null
  },
  {
    "id": "5f4e942f-d5b8-4d62-99b2-870c15a82127",
    "name": "bucket-2",
    "project": "project-1",
    "provider": "ubiops",
    "creation_date": "2022-05-12T16:23:15.456812Z",
    "configuration": {},
    "labels": {},
    "description": "My bucket 2 description",
    "ttl": null
  }
]
```

 * @summary List buckets
 */
export const bucketsList = (
  projectName: string,
  params?: BucketsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<BucketList[]>(
    { url: `/projects/${projectName}/buckets`, method: "get", params },
    options
  );
};

export const getBucketsListKey = (
  projectName: string,
  params?: BucketsListParams
) => [`/projects/${projectName}/buckets`, ...(params ? [params] : [])];

export type BucketsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof bucketsList>>
>;
export type BucketsListQueryError = ErrorType<unknown>;

export const useBucketsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: BucketsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof bucketsList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getBucketsListKey(projectName, params) : null));
  const swrFn = () => bucketsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a bucket in a project

### Required Parameters
- `name`: Name of the bucket. It is unique within a project.

### Optional Parameters
- `provider`: Provider of the bucket. It can be 'ubiops', 'google_cloud_storage', 'amazon_s3' or 'azure_blob_storage'. The default is **ubiops**.
- `credentials`: A dictionary for credentials to connect to the bucket. It is only required for providers other than *ubiops*. Each provider requires a different set of fields:
    - For Amazon S3, provide the fields `access_key` and `secret_key`.
    - For Azure Blob Storage, provide the field `connection_string` in the format: *DefaultEndpointsProtocol=https;AccountName=<account-name>;AccountKey=<account-key>;EndpointSuffix=core.windows.net*.
    - For Google Cloud Storage, provide the field `json_key_file`.
- `configuration`: A dictionary for additional configuration details for the bucket. It is only required for providers other than *ubiops*. Each provider requires a different set of fields:
    - For Amazon S3, provide the fields `bucket` and `prefix`. One of the fields `region` or `endpoint_url` needs to be provided. The fields `signature_version`, `verify` and `use_ssl` are optional.
    - For Azure Blob Storage, provide the fields `container` and `prefix`.
    - For Google Cloud Storage, provide the fields `bucket` and `prefix`.
    UbiOps always makes sure that the prefix ends with a '/'.
- `description`: Description of the bucket
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `ttl`: Time to live for the files in the bucket. It must be a multiple of 604800 (1 week). Pass `null` to keep them forever.

#### Request Examples
```
{
  "name": "bucket-1",
  "provider": "ubiops",
  "credentials": {},
  "configuration": {},
  "labels": {
    "type": "bucket"
  },
  "description": "My bucket description"
}
```

### Response Structure
Details of the created bucket
- `id`: Unique identifier for the bucket (UUID)
- `name`: Name of the bucket
- `project`: Project name in which the bucket is created
- `provider`: Provider of the bucket
- `credentials`: Credentials to connect to the bucket
- `configuration`: Additional configuration details for the bucket
- `creation_date`: The date when the bucket was created
- `description`: Description of the bucket
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `ttl`: Time to live for the files in the bucket

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "bucket-1",
  "project": "project-1",
  "provider": "ubiops",
  "credentials": {},
  "configuration": {},
  "creation_date": "2022-05-12T16:23:15.456812Z",
  "labels": {
    "type": "bucket"
  },
  "description": "My bucket description",
  "ttl": null
}
```

 * @summary Create bucket
 */
export const bucketsCreate = (
  projectName: string,
  bucketCreate: BucketCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<BucketList>(
    {
      url: `/projects/${projectName}/buckets`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: bucketCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a bucket in a project

### Response Structure
Details of a bucket
- `id`: Unique identifier for the bucket (UUID)
- `name`: Name of the bucket
- `project`: Project name in which the bucket is defined
- `provider`: Provider of the bucket
- `configuration`: Additional configuration details for the bucket
- `creation_date`: The date when the bucket was created
- `description`: Description of the bucket
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `ttl`: Time to live for the files in the bucket
- `size`: Size of the bucket according to the last measurement date
- `size_measurement_date`: Last measurement date of the size of the bucket

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "bucket-1",
  "project": "project-1",
  "provider": "ubiops",
  "configuration": {},
  "creation_date": "2022-05-12T16:23:15.456812Z",
  "labels": {
    "type": "bucket"
  },
  "description": "My bucket description",
  "ttl": null,
  "size": 2048,
  "size_measurement_date": "2022-05-24T02:23:15.456812Z",
}
```

 * @summary Get details of a bucket
 */
export const bucketsGet = (
  projectName: string,
  bucketName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<BucketDetail>(
    { url: `/projects/${projectName}/buckets/${bucketName}`, method: "get" },
    options
  );
};

export const getBucketsGetKey = (projectName: string, bucketName: string) => [
  `/projects/${projectName}/buckets/${bucketName}`,
];

export type BucketsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof bucketsGet>>
>;
export type BucketsGetQueryError = ErrorType<unknown>;

export const useBucketsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  bucketName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof bucketsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && bucketName);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getBucketsGetKey(projectName, bucketName) : null));
  const swrFn = () => bucketsGet(projectName, bucketName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a bucket

### Optional Parameters
- `credentials`: Credentials to connect to the bucket
- `configuration`: Additional configuration details for the bucket
- `description`: New description for the bucket
- `labels`: New dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label. The new labels will replace the existing value for labels.
- `ttl`: Time to live for the files in the bucket. It must be a multiple of 604800 (1 week).

#### Request Examples
```
{
  "description": "New description for the bucket"
}
```

### Response Structure
Details of the updated bucket
- `id`: Unique identifier for the bucket (UUID)
- `name`: Name of the bucket
- `project`: Project name in which the bucket is defined
- `provider`: Provider of the bucket
- `configuration`: Additional configuration details for the bucket
- `creation_date`: The date when the bucket was created
- `description`: Description of the bucket
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `ttl`: Time to live for the files in the bucket
- `size`: Size of the bucket according to the last measurement date
- `size_measurement_date`: Last measurement date of the size of the bucket

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "new-bucket-name",
  "project": "project-1",
  "provider": "ubiops",
  "configuration": {},
  "creation_date": "2022-05-12T16:23:15.456812Z",
  "labels": {
    "type": "bucket"
  },
  "description": "My bucket description",
  "ttl": null,
  "size": 2048,
  "size_measurement_date": "2022-05-24T02:23:15.456812Z",
}
```

 * @summary Update a bucket
 */
export const bucketsUpdate = (
  projectName: string,
  bucketName: string,
  bucketUpdate: BucketUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<BucketDetail>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: bucketUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a bucket. If the bucket provider is UbiOps, the files in the bucket will be deleted together with the bucket. For other providers, the files in the bucket are not removed but just the connection from UbiOps to the bucket.

 * @summary Delete a bucket
 */
export const bucketsDelete = (
  projectName: string,
  bucketName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/buckets/${bucketName}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
List files in a bucket. The maximum number of files that can be listed at once is 1000.

### Optional Parameters
The following parameters should be given as query parameters:
- `prefix`: Prefix to filter files
- `delimiter`: Delimiter used with prefix to emulate hierarchy to filter files
- `limit`: The maximum number of files returned, default is 100
- `continuation_token`: A token that indicates the start point of the returned the files

### Response Structure
A dictionary containing the details of files and prefixes in the bucket
- `continuation_token`: Next token to get the next set of files
- `files`: A list of dictionaries containing the details of the files. It contains the file name ('file'), size of the file ('size') and the creation time of the file ('time_created').
- `prefixes`: A list of directories

#### Response Examples
```
{
  "continuation_token": "1234",
  "files": [
    {
      "file": "my-file-1",
      "size": 123,
      "time_created": "2022-05-12T16:23:15.456812Z"
    },
    {
      "file": "my-file-2",
      "size": 456,
      "time_created": "2022-06-05T10:56:12.186046Z"
    }
  ],
  "prefixes": [
    "my-dir-1",
    "my-dir-2"
  ]
}
```

 * @summary List files
 */
export const filesList = (
  projectName: string,
  bucketName: string,
  params?: FilesListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileDetail>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files`,
      method: "get",
      params,
    },
    options
  );
};

export const getFilesListKey = (
  projectName: string,
  bucketName: string,
  params?: FilesListParams
) => [
  `/projects/${projectName}/buckets/${bucketName}/files`,
  ...(params ? [params] : []),
];

export type FilesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesList>>
>;
export type FilesListQueryError = ErrorType<unknown>;

export const useFilesList = <TError = ErrorType<unknown>>(
  projectName: string,
  bucketName: string,
  params?: FilesListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof filesList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && bucketName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getFilesListKey(projectName, bucketName, params) : null);
  const swrFn = () =>
    filesList(projectName, bucketName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get the details of a file in the bucket

### Response Structure
- `file`: Name of the file
- `size`: Size of the file
- `time_created`: The time that the file was created

#### Response Examples
```
{
  "file": "my-file-1",
  "size": 123,
  "time_created": "2022-05-12T16:23:15.456812Z"
}
```

 * @summary Get a file
 */
export const filesGet = (
  projectName: string,
  bucketName: string,
  file: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileItem>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}`,
      method: "get",
    },
    options
  );
};

export const getFilesGetKey = (
  projectName: string,
  bucketName: string,
  file: string
) => [`/projects/${projectName}/buckets/${bucketName}/files/${file}`];

export type FilesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesGet>>
>;
export type FilesGetQueryError = ErrorType<unknown>;

export const useFilesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  bucketName: string,
  file: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof filesGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && bucketName && file);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getFilesGetKey(projectName, bucketName, file) : null));
  const swrFn = () => filesGet(projectName, bucketName, file, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Generate a signed url to upload a file. Request body should be an empty dictionary.

Note: When using the url generated by this endpoint for Azure Blob Storage, the following headers must be added to the upload request to Azure Blob Storage:
- `x-ms-version`: '2020-04-08'
- `x-ms-blob-type`: 'BlockBlob'

### Optional Parameters
- `upload_id`: ID of the upload for the file. It should be used with multipart uploads.
- `part_number`: Part number of the upload. It should be used with multipart uploads.

### Response Structure
- `url`: A url which can be used to upload the file to bucket. Make a PUT request to this url with the file content to upload the file.
- `provider`: Provider of the bucket where the file will be uploaded

#### Response Examples
```
{
  "url": "https://storage.apis.com/my-bucket/my-file.jpg...",
  "provider": "ubiops"
}
```

 * @summary Upload a file
 */
export const filesUpload = (
  projectName: string,
  bucketName: string,
  file: string,
  fileUploadBody: FileUploadBody,
  params?: FilesUploadParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileUploadResponse>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: fileUploadBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Delete a file from a bucket

 * @summary Delete a file
 */
export const filesDelete = (
  projectName: string,
  bucketName: string,
  file: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Complete a multipart upload for a file

### Request Structure
- `parts`: A list of parts that were uploaded

#### Request Examples
```
{
  "parts": [
    {
      "ETag": "etag-2",
      "PartNumber": 1
    },
    {
      "ETag": "etag-2",
      "PartNumber": 2
    }
  ]
}
```

### Response Structure
- `upload_id`: ID of the uploaded for the file
- `provider`: Provider of the bucket where the file will be uploaded

#### Response Examples
```
{
  "upload_id": "upload-id",
  "provider": "google_cloud_storage"
}
```

 * @summary Complete multipart upload
 */
export const filesCompleteMultipartUpload = (
  projectName: string,
  bucketName: string,
  file: string,
  fileCompleteMultipartUpload: FileCompleteMultipartUpload,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileMultipartUpload>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}/complete-multipart-upload`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: fileCompleteMultipartUpload,
    },
    options
  );
};

/**
 * 
### Description
Generate a signed url to download a file. Request body should be an empty dictionary.

### Response Structure
- `url`: A url which can be used to download the file from bucket. Make a GET request to this url to download the file.
- `provider`: Provider of the bucket where the file will be uploaded

#### Response Examples
```
{
  "url": "https://storage.apis.com/my-bucket/my-file.jpg...",
  "provider": "ubiops"
}
```

 * @summary Download a file
 */
export const filesDownload = (
  projectName: string,
  bucketName: string,
  file: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileUploadResponse>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}/download`,
      method: "get",
    },
    options
  );
};

export const getFilesDownloadKey = (
  projectName: string,
  bucketName: string,
  file: string
) => [`/projects/${projectName}/buckets/${bucketName}/files/${file}/download`];

export type FilesDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesDownload>>
>;
export type FilesDownloadQueryError = ErrorType<unknown>;

export const useFilesDownload = <TError = ErrorType<unknown>>(
  projectName: string,
  bucketName: string,
  file: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof filesDownload>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && bucketName && file);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getFilesDownloadKey(projectName, bucketName, file) : null);
  const swrFn = () =>
    filesDownload(projectName, bucketName, file, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Start a multipart upload for a file

### Response Structure
- `upload_id`: ID of the upload for the file
- `provider`: Provider of the bucket where the file will be uploaded

#### Response Examples
```
{
  "upload_id": "upload-id",
  "provider": "google_cloud_storage"
}
```

 * @summary Start multipart upload
 */
export const filesStartMultipartUpload = (
  projectName: string,
  bucketName: string,
  file: string,
  fileUploadBody: FileUploadBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<FileMultipartUpload>(
    {
      url: `/projects/${projectName}/buckets/${bucketName}/files/${file}/start-multipart-upload`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: fileUploadBody,
    },
    options
  );
};

import { useTheme } from "@mui/material";

export const UploadFileDocument = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="105"
      viewBox="0 0 106 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.4167 39.375H81.7084L57.4167 15.3125V39.375ZM26.5001 8.75H61.8334L88.3334 35V87.5C88.3334 89.8206 87.4028 92.0462 85.7462 93.6872C84.0896 95.3281 81.8428 96.25 79.5001 96.25H26.5001C21.5976 96.25 17.6667 92.3125 17.6667 87.5V17.5C17.6667 12.6437 21.5976 8.75 26.5001 8.75ZM66.2501 78.75V70H26.5001V78.75H66.2501ZM79.5001 61.25V52.5H26.5001V61.25H79.5001Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
};

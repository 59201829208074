/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type DeploymentVersionDetailStatus =
  typeof DeploymentVersionDetailStatus[keyof typeof DeploymentVersionDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeploymentVersionDetailStatus = {
  unavailable: "unavailable",
  available: "available",
  building: "building",
} as const;

const toRem = (number: number) => `${number / 16}rem`;

export const spacing = {
  2: toRem(2),
  4: toRem(4),
  6: toRem(6),
  8: toRem(8),
  12: toRem(12),
  14: toRem(14),
  16: toRem(16),
  20: toRem(20),
  24: toRem(24),
  28: toRem(28),
  30: toRem(30),
  32: toRem(32),
  36: toRem(36),
  38: toRem(38),
  40: toRem(40),
  44: toRem(44),
  48: toRem(48),
  52: toRem(52),
  64: toRem(64),
  80: toRem(80),
  96: toRem(96),
  112: toRem(112),
  116: toRem(116),
  120: toRem(120),
  128: toRem(128),
  144: toRem(144),
  156: toRem(156),
  164: toRem(164),
  200: toRem(200),
  220: toRem(220),
  250: toRem(250),
  256: toRem(256),
  300: toRem(300),
  310: toRem(310),
  400: toRem(400),
  440: toRem(440),
  500: toRem(500),
  560: toRem(560),
};

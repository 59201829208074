import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { DOC_LINKS } from "libs/constants/documentation-links";

import { Dialog, ExternalLink } from "components/atoms";

import { StatusTitle, StatusIcon } from "./WarningErrorStatus.styles";

interface WarningErrorModalProps {
  errors: string[];
  warnings: string[];
  isOpen: boolean;
  onClose: () => void;
}

export const WarningErrorModal = ({
  errors = [],
  warnings = [],
  isOpen = false,
  onClose,
}: WarningErrorModalProps) => (
  <Dialog
    title="There are some issues with your pipeline"
    open={isOpen}
    onClose={onClose}
    maxWidth="md"
  >
    <Box marginBottom={2}>
      <List dense disablePadding>
        {errors.map((error) => (
          <ListItem key={error} dense>
            <Box
              component={ListItemIcon}
              marginRight={1}
              style={{ minWidth: "auto", marginTop: "3px" }}
            >
              <StatusIcon marginRight={1} variant="error" />
            </Box>

            <ListItemText
              primary={
                <Box marginTop={0.75}>
                  <StatusTitle variant="error" />
                  <Typography variant="body1" display="inline">
                    <span>{error}</span>
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}
        {warnings.map((warning) => (
          <ListItem key={warning} dense>
            <Box
              component={ListItemIcon}
              marginRight={1}
              marginTop={1}
              style={{ minWidth: "auto", marginTop: "3px" }}
            >
              <StatusIcon marginRight={1} variant="warning" />
            </Box>

            <ListItemText
              primary={
                <Box marginTop={0.75}>
                  <StatusTitle variant="warning" />
                  <Typography variant="body1" display="inline">
                    <span>{warning}</span>
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
    <Grid container>
      <Grid item xs={12}>
        <Box marginBottom={2}>
          <Typography variant="body1" style={{ marginLeft: "60px" }}>
            {`For more information on these issues, have a look at `}
            <ExternalLink href={DOC_LINKS.TROUBLESHOOTING}>
              <Typography component="span" variant="body1" color="secondary">
                the documentation
              </Typography>
            </ExternalLink>
            {`.`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Dialog>
);

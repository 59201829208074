import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { pipelineVersionsCreate } from "libs/data/endpoints/pipelines/pipelines";

import type { PipelineVersionCreate } from "libs/data/models";

export const usePipelineVersionCreate = (
  projectName: string,
  pipelineName: string
) => {
  const { mutate } = useSWRConfig();

  return useCallback(
    async (data: PipelineVersionCreate, pipelineNameOnTheGo?: string) => {
      const pipelineNameString = pipelineName || pipelineNameOnTheGo;
      if (!projectName || !pipelineNameString) {
        return Promise.reject({
          message:
            "Couldn't make the API call because projectName and/or pipelineName are not available",
        });
      }

      return pipelineVersionsCreate(projectName, pipelineNameString, data).then(
        async (result) => {
          await mutate(
            `/projects/${projectName}/pipelines/${
              pipelineNameString || pipelineName
            }/versions`
          );

          return result;
        }
      );
    },
    [mutate, projectName, pipelineName]
  );
};

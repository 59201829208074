import { Box } from "@mui/material";

import { CardHeaderTitle } from "components/atoms/Card/CardHeaderTitle";

import type { ReactNode } from "react";

interface CardHeaderProps {
  header?: ReactNode;
  title?: string;
}

export const CardHeader = ({ header, title }: CardHeaderProps) => (
  <Box display="flex" flexWrap="wrap" alignItems="center">
    {header ? header : <CardHeaderTitle>{title}</CardHeaderTitle>}
  </Box>
);

import { bytesFormatter } from "libs/utilities/metrics-helper";

import { DialogDetails } from "./DialogDetails";

import type { File } from "components/organisms/FilesExplorer";

export const DuplicateFileWarning = ({ files }: { files: File[] }) => {
  return (
    <>
      {files?.map((file) => (
        <DialogDetails
          name="File"
          key={file.file}
          entity={{
            name: file.file,
            size: bytesFormatter(file.size, "_"),
            creation_time: file.time_created ?? "",
          }}
        />
      ))}
    </>
  );
};

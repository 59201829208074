import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { userDelete } from "libs/data/endpoints/user/user";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useDeleteUser = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    try {
      await userDelete();
      dispatch(createSuccessNotification("User was deleted."));
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(createErrorNotification(error.message));
    }
  }, [dispatch]);
};

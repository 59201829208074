import { Typography, Box } from "@mui/material";
import { useWatch } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { FIELD_EXPRESSION, FIELD_INPUT_FIELDS } from "libs/constants/fields";

import { DynamicFields, Field } from "components/molecules";

import { ExpressionField } from "./OperatorConditionalForm";
import { TestExpression } from "./TestExpression";

import type { Fields } from "./TestExpression";
import type { OperatorFormProps } from "./types";

export const OperatorFunctionForm = ({ value, control }: OperatorFormProps) => {
  const expression: string | undefined = useWatch({
    control,
    name: FIELD_EXPRESSION,
  });
  const fields: Fields | undefined = useWatch({
    control,
    name: FIELD_INPUT_FIELDS,
  });

  return (
    <>
      <ExpressionField defaultValue={value?.expression ?? ""} />
      <Box marginY={spacing[16]}>
        <Typography variant="h6" color="textPrimary">
          Expression variables
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Specify the variables used in the expression above (if any)
        </Typography>
      </Box>
      <DynamicFields
        name={FIELD_INPUT_FIELDS}
        fields={value?.input_fields ?? []}
        color="primary"
      />
      <Box marginY={spacing[16]}>
        <Typography variant="h6" color="textPrimary">
          Output fields
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          This operator has a single output field called &quot;output&quot; and
          contains the output value of your expression. Select the correct data
          type of the field so it matches the value.
        </Typography>
      </Box>
      <Field
        name={"output_field"}
        index={"output_field_1"}
        defaultValue={value?.output_fields?.[0]}
        hasCloseButton={false}
        disabled
      />
      <TestExpression
        expression={expression as string}
        fields={fields as Fields}
      />
    </>
  );
};

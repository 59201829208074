import LaunchIcon from "@mui/icons-material/Launch";
import { Grid, Typography, Box } from "@mui/material";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import "./RequestsPageConfiguration.scss";

import {
  FIELD_INPUT_WIDGETS,
  FIELD_OUTPUT_WIDGETS,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import { useDeploymentUpdate } from "libs/data/customized/deployment/useDeploymentUpdate";
import { usePipelineUpdate } from "libs/data/customized/pipeline";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";
import { createErrorNotification } from "libs/utilities/notifications";
import { updateFieldsWidgets } from "libs/utilities/widgets-helpers";

import {
  Card,
  Divider,
  FormWrapper,
  InfoTooltip,
  ExternalLink,
  Link,
  SecondaryButton,
} from "components/atoms";

import FieldWidgetSelector from "./FieldWidgetSelector";
import RequestPagePreview from "./RequestPagePreview";

import type { DeploymentUpdate, PipelineUpdate } from "libs/data/models";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";

const RequestsPageConfiguration = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();

  const history = useHistory();
  const { projectName, pipelineName, deploymentName } =
    useParams<
      ProjectDetailsRouteParams & {
        deploymentName?: string;
        pipelineName?: string;
      }
    >();

  const { data: pipeline } = usePipelinesGet(
    projectName,
    pipelineName as string,
    { swr: { enabled: !!pipelineName } }
  );
  const { data: deployment } = useDeploymentsGet(
    projectName,
    deploymentName as string,
    { swr: { enabled: !!deploymentName } }
  );

  const updateDeployment = useDeploymentUpdate(
    projectName,
    deploymentName as string
  );
  const updatePipeline = usePipelineUpdate(projectName, pipelineName as string);

  const requestType = deploymentName ? "deployment" : "pipeline";
  const requestObject = deploymentName ? deployment : pipeline;

  const rootUrl = useContext(RootUrlContext);
  const simpleRequestUrl = `${env.get(
    ENV_NAMES.REQUESTS_PAGE_URL
  )}/projects/${projectName}/${requestType}s/${requestObject?.name}`;

  const defaultValues = useMemo(() => {
    const dropdownOptions: any = {
      [FIELD_INPUT_WIDGETS]: {},
      [FIELD_OUTPUT_WIDGETS]: {},
    };

    (requestObject?.input_fields || []).forEach(({ name, widget }) => {
      if (widget?.configuration) {
        dropdownOptions[FIELD_INPUT_WIDGETS][name as string] = {
          configuration: widget.configuration,
        };
      }
    });
    (requestObject?.output_fields || []).forEach(({ name, widget }) => {
      if (widget?.configuration) {
        dropdownOptions[FIELD_OUTPUT_WIDGETS][name as string] = {
          configuration: widget.configuration,
        };
      }
    });

    return dropdownOptions;
  }, [requestObject]);

  const methods = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    shouldUnregister: false,
    defaultValues,
  });

  const handleDeploymentUpdate = async (data: any) => {
    const response = await updateDeployment({
      input_fields: updateFieldsWidgets(
        // @ts-ignore
        requestObject.input_fields,
        data[FIELD_INPUT_WIDGETS]
      ),
      output_fields: updateFieldsWidgets(
        // @ts-ignore
        requestObject.output_fields,
        data[FIELD_OUTPUT_WIDGETS],
        "output"
      ),
    } as DeploymentUpdate);

    if (response) {
      history.push(`${rootUrl}/deployments/${deployment?.name}/use-deployment`);
    }
  };

  const handlePipelineUpdate = (data: any) => {
    updatePipeline({
      ...pipeline,
      input_fields: updateFieldsWidgets(
        // @ts-ignore
        requestObject?.input_fields ?? [],
        data[FIELD_INPUT_WIDGETS]
      ),
      output_fields: updateFieldsWidgets(
        // @ts-ignore
        requestObject?.output_fields ?? [],
        data[FIELD_OUTPUT_WIDGETS],
        "output"
      ),
    } as PipelineUpdate)
      .then(() => {
        history.push(`${rootUrl}/pipelines/${pipeline?.name}/use-pipeline`);
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      });
  };

  return (
    <>
      <BreadcrumbsItem
        to={`${rootUrl}/${requestType}s/${requestObject?.name}/use-${requestType}`}
      >
        Use {requestType}
      </BreadcrumbsItem>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box padding={"16px"}>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(
                  requestType === "deployment"
                    ? handleDeploymentUpdate
                    : handlePipelineUpdate
                )}
              >
                <FormWrapper fullWidth>
                  <Grid container spacing={2}>
                    <Grid item xs={12} marginBottom={"30px"}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h3">Input widgets</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1">
                          Select widget types for your input fields
                        </Typography>
                      </Box>
                    </Grid>
                    {!requestObject?.input_fields?.length ? (
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="subtitle1">
                          <i>No input fields to configure</i>
                        </Typography>
                      </Grid>
                    ) : (
                      (requestObject?.input_fields || []).map(
                        ({ name: fieldName, data_type, widget }, key) => (
                          <FieldWidgetSelector
                            key={key}
                            prefix={"input"}
                            // @ts-ignore
                            fieldName={fieldName}
                            // @ts-ignore
                            dataType={data_type}
                            widget={widget}
                          />
                        )
                      )
                    )}
                  </Grid>
                  <Grid container spacing={2} marginTop="40px">
                    <Grid item xs={12} marginBottom={"30px"}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h3">Output widgets</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1">
                          Select widget types for your output fields
                        </Typography>
                      </Box>
                    </Grid>
                    {isEmpty(requestObject?.output_fields) ? (
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="subtitle1">
                          <i>No output fields to configure</i>
                        </Typography>
                      </Grid>
                    ) : (
                      (requestObject?.output_fields || []).map(
                        ({ name: fieldName, data_type, widget }, key) => (
                          <FieldWidgetSelector
                            key={key}
                            prefix={"output"}
                            // @ts-ignore
                            fieldName={fieldName}
                            // @ts-ignore
                            dataType={data_type}
                            widget={widget}
                          />
                        )
                      )
                    )}
                  </Grid>
                  <SecondaryButton style={{ marginLeft: "auto" }} type="submit">
                    Save
                  </SecondaryButton>
                </FormWrapper>
              </form>
            </FormProvider>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card title="Preview">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ border: "0px" }}
                >
                  <ExternalLink
                    href={simpleRequestUrl}
                    underline="none"
                    launchIcon={false}
                  >
                    <SecondaryButton
                      startIcon={<LaunchIcon fontSize="small" />}
                    >
                      Go to {requestType} interface
                    </SecondaryButton>
                  </ExternalLink>
                  <InfoTooltip>
                    An interface for your deployment or pipeline which is
                    accessible with an API token.
                  </InfoTooltip>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can use our automatically generated interface for your
                  deployment or pipeline. You will need{" "}
                  <Link to={`${rootUrl}/settings/api-tokens/create`}>
                    an API token
                  </Link>{" "}
                  with &quot;<code>{requestType}-request-user</code>&quot;
                  rights to work with it.
                </Typography>
              </Grid>
            </Grid>
            <Divider className="requests-page-configuration__divider" />
            <RequestPagePreview
              // @ts-ignore
              requestObject={requestObject}
              requestType={requestType}
              methods={methods}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default RequestsPageConfiguration;

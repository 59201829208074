import { stringifyUrl } from "query-string";
import { useContext, useMemo } from "react";

import { RootUrlContext } from "libs/contexts";

export const useLogsUrl = ({
  queryParameters = {},
  redirectUrl,
}: {
  redirectUrl?: string;
  queryParameters?: Record<string, string | number | undefined | null>;
}) => {
  const rootUrl = useContext(RootUrlContext);

  const url = useMemo(
    () =>
      stringifyUrl({
        url: `${redirectUrl ?? rootUrl}/logs`,
        query: queryParameters,
      }),
    [queryParameters, redirectUrl, rootUrl]
  );

  return url;
};

import { Grid, TextField, useTheme } from "@mui/material";

import type { TextFieldProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { SyntheticEvent } from "react";

export type ControlledTextFieldProps = Omit<
  TextFieldProps,
  "error" | "onChange"
> & {
  error?: string | false;
  onChange?: (value: string) => void;
  showErrors?: boolean;
};

export const ControlledTextInput = ({
  error,
  showErrors,
  onChange,
  ...props
}: ControlledTextFieldProps) => {
  const theme = useTheme() as AppThemeProps;

  const handleOnChange = (e: SyntheticEvent) =>
    onChange && onChange((e?.target as HTMLInputElement)?.value);

  return (
    <Grid container marginBottom={0.5}>
      <Grid item xs>
        <TextField
          error={showErrors && !!error}
          helperText={error ? error : ""}
          margin="dense"
          onChange={handleOnChange}
          variant="outlined"
          size="small"
          fullWidth
          style={{}}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            style: {
              background: "transparent",
              fontSize: 14,
              lineHeight: "20px",
              height: "unset",
              minHeight: 20 * ((props.minRows as number) || 1),
              paddingBottom: 11,
              paddingTop: 11,
            },
          }}
          sx={{
            ".MuiInputBase-multiline": {
              paddingBottom: 0,
              paddingTop: 0,
              background: "transparent",
              "& textarea": {
                border: 0,
              },
              " & fieldset": {
                border: error ? `${theme.palette.error.main} 2px solid` : null,
                borderRadius: "4px",
              },
            },
            ".MuiInputBase-input": {
              border: error ? `${theme.palette.error.main} 1px solid` : null,
              borderRadius: error ? "4px" : null,
            },
            ".MuiInputBase-root:hover": {
              " & fieldset": {
                border: error ? `${theme.palette.error.main} 2px solid` : null,
                borderRadius: "4px",
              },
            },
            ".MuiInputLabel-root.Mui-focused": {
              color: error
                ? theme.palette.error.main
                : theme.palette.primary.main,
            },
            "& label": {
              backgroundColor: theme.palette.background.paper,
              padding: "0 10px",
              marginLeft: "-5px",
              color: error
                ? theme.palette.error.main
                : theme.palette.text.primary,
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                border: "none",
                borderRadius: "4px",
                " & fieldset": {
                  border: error
                    ? `${theme.palette.error.main} 2px solid`
                    : `${theme.palette.primary.main} 2px solid`,
                  borderRadius: "4px",
                },
              },
              "& .MuiFormHelperText-root ": {
                color: `${theme.palette.text.primary} !important`,
              },
            },
          }}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

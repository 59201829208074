import { useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";

import { Loader } from "components/atoms";
import { PageContainer } from "components/molecules";

import { TRAINING_DEPLOYMENT } from "../../../constants";
import { RunForm } from "../RunForm";

export const RunDuplicate = () => {
  const { projectName, experimentName, runId } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
      runId: string;
    }>();
  const { data: runDetails, error } = useDeploymentVersionRequestsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    runId
  );

  const isLoading = runDetails === undefined && !error;

  return (
    <PageContainer>
      <PageHeader title="Duplicate run" />
      {isLoading ? <Loader /> : <RunForm runDetails={runDetails} />}
    </PageContainer>
  );
};

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { InfoTooltip, SecondaryButton } from "components/atoms";

type CreateBatchRequestSectionProps = {
  objectType: "deployment" | "pipeline";
  createBatchRequestsUrl: string;
  onClickCreateBatchRequest: () => void;
};

export const CreateBatchRequestSection = ({
  objectType,
  createBatchRequestsUrl,
  onClickCreateBatchRequest,
}: CreateBatchRequestSectionProps) => {
  const history = useHistory();

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item xs={4} container alignItems="center">
        <Typography variant="h3">Batch requests</Typography>
        <InfoTooltip>
          Batch request allows to send multiple data points to the {objectType}.
        </InfoTooltip>
      </Grid>
      <Grid item xs={8} container justifyContent="flex-end">
        <SecondaryButton
          startIcon={<OpenInNewIcon />}
          size="small"
          onClick={() => {
            onClickCreateBatchRequest();
            history.push(createBatchRequestsUrl);
          }}
        >
          Create batch requests
        </SecondaryButton>
      </Grid>
    </Grid>
  );
};

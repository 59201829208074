import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  pipelinesDelete,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const usePipelineDelete = (projectName: string) => {
  const { mutate } = usePipelinesList(projectName, undefined, {
    swr: { swrKey: `/projects/${projectName}/pipelines` },
  });
  const dispatch = useDispatch();

  return useCallback(
    async (pipelineName: string) => {
      try {
        await pipelinesDelete(projectName, pipelineName);
        dispatch(createSuccessNotification("Pipeline was deleted"));
        await mutate((pipelines) =>
          pipelines?.filter((pipeline) => pipeline.name !== pipelineName)
        );

        return true;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(createErrorNotification(error.message));

        return false;
      }
    },
    [mutate, projectName, dispatch]
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  metricsUpdate,
  useMetricsList,
} from "libs/data/endpoints/metrics/metrics";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { MetricCreate } from "libs/data/models";

export const useMetricsEdit = (projectName: string) => {
  const { mutate } = useMetricsList(projectName);
  const dispatch = useDispatch();

  return useCallback(
    async (name: any, data: MetricCreate) => {
      try {
        await metricsUpdate(projectName, name, data);
        dispatch(createSuccessNotification("Custom metric was updated."));
        mutate();
      } catch (err) {
        const message = (err as AxiosError).message;
        dispatch(createErrorNotification(message));
      }
    },
    [mutate, dispatch, projectName]
  );
};

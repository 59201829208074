import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import {
  PROJECT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
} from "libs/constants/permissions";
import {
  usePipelineDefaultVersion,
  usePipelineDelete,
} from "libs/data/customized/pipeline";
import { usePermissionValidation } from "libs/data/customized/roles";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { useLogsUrl, useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  PageTabs,
  DeleteDialog,
  PrimaryButton,
  TextButton,
  ButtonGroup,
} from "components/atoms";
import { RequestDialog } from "components/organisms";

const PipelineDetails = ({ children }) => {
  useGoogleAnalytics();
  const history = useHistory();
  const { organizationName, projectName, pipelineName } = useParams();

  const currentOrganization = useGetCurrentOrganization();

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const { data: pipelineDefaultVersion } = usePipelineDefaultVersion(
    projectName,
    pipelineName
  );
  const deletePipeline = usePipelineDelete(projectName);
  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(PIPELINE_PERMISSIONS),
    pipelineName,
    "pipeline"
  );

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName),
    [pipelineName, organizationName, projectName]
  );
  const logsUrl = useLogsUrl({
    queryParameters: { pipeline_name: pipelineName },
  });

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isForcedCreateOpen, setForcedCreateOpen] = useState(false);

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.configuration.index(),
      label: "Configuration",
    },
    {
      link: baseUrl.auditEvents.index(),
      label: "Audit events",
      disabled: !currentPermissions[PIPELINE_PERMISSIONS["audit_list"]],
    },
    {
      link: baseUrl.usePipeline.index(),
      label: "Use pipeline",
      disabled: !pipelineDefaultVersion,
      disabledText:
        "There is no default version available, please create a version first and set a default version to start using your pipeline.",
    },
  ];

  const onDelete = async () => {
    const response = await deletePipeline(pipeline.name);

    if (response) {
      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines.index()
      );
    }
  };

  return (
    <>
      <RequestDialog
        isOpen={isRequestDialogOpen}
        setIsOpen={setIsRequestDialogOpen}
        isForcedCreateOpen={isForcedCreateOpen}
        setForcedCreateOpen={setForcedCreateOpen}
        organizationName={organizationName}
        projectName={projectName}
        isRetentionModeNone={
          pipelineDefaultVersion?.request_retention_mode === "none"
        }
        requestParameters={{
          type: "pipeline",
          resourceName: pipeline?.name,
          resourceVersion: pipeline?.default_version,
          inputType: pipeline?.input_type,
          inputFields: pipeline?.input_fields,
          outputType: pipeline?.output_type,
          outputFields: pipeline?.output_fields,
        }}
      />

      <DetailsContainer
        title={pipelineName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[PIPELINE_PERMISSIONS["update"]] ||
                currentOrganization?.status !== "active"
              }
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              link={logsUrl}
              startIcon={<LogsIcon />}
            >
              Logs
            </TextButton>
            <TextButton
              disabled={!currentPermissions[PIPELINE_PERMISSIONS["delete"]]}
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<PlayArrow />}
              disabled={
                !pipeline?.default_version ||
                !currentPermissions[
                  PIPELINE_PERMISSIONS["version_request_create"]
                ]
              }
              onClick={() => {
                setForcedCreateOpen(true);
                setIsRequestDialogOpen(true);
              }}
              style={{ marginLeft: "auto" }}
            >
              Create request
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        {pipeline && <PageTabs tabs={tabs}>{children}</PageTabs>}
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={() => onDelete(pipeline?.name)}
        >
          Are you sure you want to delete pipeline &quot;
          <b>{pipeline?.name}</b>&quot; and all of its versions?
        </DeleteDialog>
      </DetailsContainer>
    </>
  );
};

PipelineDetails.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PipelineDetails;

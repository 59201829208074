/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type ImportDetailStatus =
  typeof ImportDetailStatus[keyof typeof ImportDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportDetailStatus = {
  pending: "pending",
  scanning: "scanning",
  confirmation: "confirmation",
  confirmation_pending: "confirmation_pending",
  processing: "processing",
  completed: "completed",
  failed: "failed",
} as const;

import { Stepper, Step, StepLabel, Typography, Box } from "@mui/material";
import { forEach } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_EMAIL, FIELD_ADMIN, FIELD_ROLE } from "libs/constants/fields";
import { BaseUrlContext } from "libs/contexts";
import { useOrganizationUserCreate } from "libs/data/customized/organization";
import { roleAssignmentsCreate } from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";
import {
  useGetOrganizationFeatures,
  useGetOrganizationResources,
  useGetProjects,
} from "store/features";

import { FormTextField, Radio } from "components/atoms";
import {
  FormSection,
  MultipleCheckboxes,
  PageContainer,
} from "components/molecules";
import { FormContainer, UpgradeSubscriptionDialog } from "components/organisms";

import { defaultRolesOptions } from "./constants";

const fields = {
  [FIELD_ADMIN]: "false",
};

const roleRules = {
  required: validators.required.message(FIELD_ROLE),
};

const OrganizationUsersCreate = () => {
  useGoogleAnalytics();

  const history = useHistory();
  const baseUrl = useContext(BaseUrlContext);
  const { organizationName } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [invitedUserId, setInvitedUserId] = useState(undefined);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  const organizationFeatures = useGetOrganizationFeatures();
  const organizationResources = useGetOrganizationResources();
  const projects = useGetProjects();

  const createOrganizationUser = useOrganizationUserCreate(organizationName);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: fields,
  });

  const { register } = methods;

  useEffect(() => {
    if (organizationFeatures?.max_users) {
      organizationResources?.users >= organizationFeatures?.max_users &&
        setIsSubscriptionDialogOpen(true);
    }
  }, [
    organizationResources,
    organizationFeatures,
    setIsSubscriptionDialogOpen,
  ]);

  const handleCreateOrganizationUser = async (data) => {
    setCreatingUser(true);
    let newData = {
      [FIELD_EMAIL]: data[FIELD_EMAIL],
      [FIELD_ADMIN]: data[FIELD_ADMIN],
    };
    const newUser = await createOrganizationUser(newData);
    setActiveStep(1);
    setInvitedUserId(newUser?.id);
    setCreatingUser(false);
  };

  const handleAssignRoles = ({ role, projects }) => {
    forEach(projects, async (checked, projectName) => {
      if (checked && invitedUserId) {
        const newRole = {
          role: role.value,
          assignee: invitedUserId,
          assignee_type: "user",
          resource: projectName,
          resource_type: "project",
        };
        await roleAssignmentsCreate(projectName, newRole);
      }
    });
    history.push(`${baseUrl}/team`);
  };

  return (
    <PageContainer>
      <PageHeader title={organizationName} />
      <BreadcrumbsItem to={`${baseUrl}/team`}>Team</BreadcrumbsItem>
      <Stepper activeStep={activeStep} orientation="horizontal">
        <Step key={0}>
          <StepLabel>Invite user</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>Assign roles</StepLabel>
        </Step>
      </Stepper>
      <div hidden={activeStep !== 0}>
        <FormContainer
          onSubmit={handleCreateOrganizationUser}
          buttonLabel="Next step"
          formMethods={methods}
          status={creatingUser ? LOADING : LOADED}
        >
          <FormSection title="User">
            <FormTextField
              id={FIELD_EMAIL}
              name={FIELD_EMAIL}
              autoComplete="email"
              autoCapitalize="none"
              type="email"
              label="Email"
              rules={{
                required: validators.required.message(FIELD_EMAIL),
                pattern: {
                  value: validators.email.pattern,
                  message: validators.email.message,
                },
              }}
            />
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">Admin</Typography>
              <Typography variant="body2">
                Do you want to make this user an organization admin?
              </Typography>
              <Box display="flex" mt={1}>
                <Box>
                  <Radio
                    value="true"
                    register={register({
                      required: true,
                    })}
                    name={FIELD_ADMIN}
                    label="Yes"
                    id="yes"
                  />
                </Box>
                <Box>
                  <Radio
                    value="false"
                    register={register({
                      required: true,
                    })}
                    name={FIELD_ADMIN}
                    label="No"
                    id="no"
                  />
                </Box>
              </Box>
            </Box>
          </FormSection>
        </FormContainer>
      </div>
      <div hidden={activeStep !== 1}>
        {activeStep === 1 && (
          <FormContainer
            onSubmit={handleAssignRoles}
            buttonLabel="Done"
            formMethods={methods}
          >
            <FormSection title="Assign project roles">
              <Box>
                <AutoCompleteSelectHookForm
                  name="role"
                  label="Role"
                  options={defaultRolesOptions}
                  defaultValue={defaultRolesOptions[0]}
                  isSearchable
                  rules={roleRules}
                />
              </Box>
              <Box mt={1}>
                <Typography variant="h4">Applied to</Typography>
                <MultipleCheckboxes
                  list={projects}
                  label="Select all projects"
                />
              </Box>
            </FormSection>
          </FormContainer>
        )}
      </div>
      <UpgradeSubscriptionDialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
      >
        {"You've reached your current subscription's limit of "}
        <b>{organizationFeatures?.max_users} maximum user(s)</b>. Please upgrade
        your subscription.
      </UpgradeSubscriptionDialog>
    </PageContainer>
  );
};

OrganizationUsersCreate.propTypes = {
  currentUser: PropTypes.string,
};

export default OrganizationUsersCreate;

import { Grid } from "@mui/material";

import {
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import { requestRetentionModes } from "libs/utilities/labels-mapping";

import { FormikSelect } from "components/molecules";

import { useRetentionTimeSettings } from "./useRetentionTimeSettings";

import type { FormikProps, FormikValues } from "formik";

interface PipelineVersionRetentionModeProps {
  values: FormikValues;
  control: FormikProps<FormikValues>;
}

export const PipelineVersionRetentionMode = ({
  values,
  control,
}: PipelineVersionRetentionModeProps) => {
  const { noRequestRetentionMode, possibleRequestRetentionTimesOptions } =
    useRetentionTimeSettings();

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <Grid item>
        <FormikSelect
          control={control}
          name={FIELD_RETENTION_MODE}
          label="Request retention mode"
          options={requestRetentionModes}
          tooltip={explanations.deployments.versions.retentionMode}
        />
      </Grid>
      {!noRequestRetentionMode(values[FIELD_RETENTION_MODE]) && (
        <Grid item>
          <FormikSelect
            control={control}
            name={FIELD_RETENTION_TIME}
            label="Request retention time (seconds)"
            options={possibleRequestRetentionTimesOptions}
            tooltip={explanations.deployments.versions.retentionTime}
            freeSolo
            placeholder="Type and press enter..."
          />
        </Grid>
      )}
    </Grid>
  );
};

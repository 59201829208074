import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_NAME } from "libs/constants/fields";
import { useRequestScheduleCreate } from "libs/data/customized/request-schedules";
import { useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { RequestScheduleBaseForm } from "./RequestScheduleBaseForm";

import type { FormikHelpers } from "formik";
import type { ScheduleCreate } from "libs/data/models";

const RequestScheduleCreate = () => {
  useGoogleAnalytics();

  const history = useHistory();

  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const createRequestSchedule = useRequestScheduleCreate(projectName);

  const handleScheduleCreate = async (
    data: ScheduleCreate,
    actions: FormikHelpers<ScheduleCreate>
  ) => {
    try {
      await createRequestSchedule(data);
    } finally {
      actions.setSubmitting(false);
    }

    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .requestSchedules[":scheduleName"](data[FIELD_NAME])
        .index()
    );
  };

  return (
    <PageContainer>
      <PageHeader title="Create new request schedule" />
      <FormWrapper>
        <RequestScheduleBaseForm handleOnSubmit={handleScheduleCreate} />
      </FormWrapper>
    </PageContainer>
  );
};

export default RequestScheduleCreate;

import { useTheme } from "@mui/material";

export const UploadFileImage = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="107"
      viewBox="0 0 108 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.5 40.1253H83.25L58.5 15.6045V40.1253ZM27 8.91699H63L90 35.667V89.167C90 91.5318 89.0518 93.7998 87.364 95.472C85.6761 97.1442 83.3869 98.0836 81 98.0836H27C22.005 98.0836 18 94.0712 18 89.167V17.8337C18 12.8849 22.005 8.91699 27 8.91699ZM27 89.167H67.5H81V53.5003L63 71.3337L54 62.417L27 89.167ZM36 40.1253C33.6131 40.1253 31.3239 41.0648 29.636 42.737C27.9482 44.4092 27 46.6771 27 49.042C27 51.4068 27.9482 53.6748 29.636 55.347C31.3239 57.0192 33.6131 57.9587 36 57.9587C38.3869 57.9587 40.6761 57.0192 42.364 55.347C44.0518 53.6748 45 51.4068 45 49.042C45 46.6771 44.0518 44.4092 42.364 42.737C40.6761 41.0648 38.3869 40.1253 36 40.1253Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
};

import { Chip, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { X as XIcon } from "react-feather";

import { shadows } from "assets/styles/theme";
import { DATE_TIME_FORMAT } from "libs/utilities/date-util";

import type { ChipTypeMap } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { Filters } from "pages/organizations/:organizationName/projects/:projectName/logs/types";

interface LogFilterChipProps extends ChipTypeMap {
  filterName: string;
  filterValue: string;
  onDelete: (filter: keyof Filters) => void;
}

export const LogFilterChip = ({
  filterName,
  filterValue,
  ...props
}: LogFilterChipProps) => {
  const theme = useTheme() as AppThemeProps;

  if (filterValue && (filterName === "from_date" || filterName === "to_date")) {
    filterValue = moment(filterValue).format(DATE_TIME_FORMAT);
  }

  return (
    <Chip
      size="small"
      deleteIcon={<XIcon />}
      label={
        <Typography>
          <Typography display="inline" variant="subtitle1">
            {filterName}:{" "}
          </Typography>
          {JSON.stringify(filterValue)}
        </Typography>
      }
      sx={{
        marginRight: "0.5em",
        marginBottom: "0.5em",
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.light,

        "&:focus ": {
          boxShadow: shadows.tertiary,
        },

        "&:last-child": {
          marginRight: 0,
        },

        ".MuiChip-label": {
          marginRight: "0.4em",
        },

        ".MuiChip-deleteIcon ": {
          width: "1.2em",
          marginRight: "0.6em",
          color: theme.palette.info.main,
        },
      }}
      {...props}
    />
  );
};

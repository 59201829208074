import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { FIELD_DEPLOYMENT_VERSION_ENVIRONMENT } from "libs/constants/fields";

import { AddButton } from "components/atoms";
import { CodeEnvironmentSelect } from "components/molecules";

import { TRAINING_DEPLOYMENT } from "../constants";

type CodeEnvironmentProps = {
  defaultEnvironment?: string;
  disabled?: boolean;
  gpuEnabled: boolean;
  onAddEnvironment: () => void;
};

export const CodeEnvironmentSection = ({
  defaultEnvironment,
  disabled,
  gpuEnabled,
  onAddEnvironment,
}: CodeEnvironmentProps) => {
  const { projectName } = useParams<{ projectName: string }>();

  return (
    <Grid container style={{ marginTop: spacing[8], marginBottom: spacing[8] }}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          gap={spacing[24]}
          alignItems="center"
        >
          <Box width={spacing[310]}>
            <CodeEnvironmentSelect
              defaultEnvironment={defaultEnvironment}
              deployment={TRAINING_DEPLOYMENT}
              gpuEnabled={gpuEnabled}
              name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
              projectName={projectName}
              customEnvironmentsVisible={true}
            />
          </Box>
          <Typography variant="subtitle1" color="textSecondary">
            OR
          </Typography>
          <AddButton
            color="secondary"
            disabled={disabled}
            onClick={onAddEnvironment}
          >
            Create new
          </AddButton>
        </Box>
      </Grid>
    </Grid>
  );
};

import { DeleteDialog } from "components/atoms";

import { useDeleteNode } from "./useDeleteNode";

export type NodeDeleteDialogProps = {
  id: string;
  pipelineObjectName: string;
  pipelineObjectType?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const NodeDeleteDialog = ({
  id,
  pipelineObjectName,
  pipelineObjectType,
  isOpen,
  onClose,
}: NodeDeleteDialogProps) => {
  const deleteNode = useDeleteNode();

  return (
    <DeleteDialog
      open={isOpen}
      onClose={() => onClose()}
      onDelete={() => deleteNode(id)}
    >
      Are you sure you want to delete the {pipelineObjectType} {`"`}
      <b>{pipelineObjectName}</b>
      {`"`} from the pipeline?
    </DeleteDialog>
  );
};

import { useFormContext } from "react-hook-form";

import validators from "libs/utilities/validators";

import { FormTextField } from "components/atoms";

const FIELD_PASSWORD_CONFIRMATION = "password_confirmation";

type PasswordConfirmationProps = {
  fieldName?: string;
  fieldLabel?: string;
};

const PasswordConfirmation = ({
  fieldName = "new_password",
  fieldLabel = "New password",
}: PasswordConfirmationProps) => {
  const { watch, clearErrors, setError } = useFormContext();
  const newPassword = watch(fieldName);
  const passwordConfirmation = watch(FIELD_PASSWORD_CONFIRMATION);

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordConfirmation) {
      if (event.target.value === passwordConfirmation) {
        clearErrors(FIELD_PASSWORD_CONFIRMATION);
      } else {
        setError(FIELD_PASSWORD_CONFIRMATION, {
          type: "validate",
          message: validators.password_confirmation.message,
        });
      }
    }
  };

  return (
    <>
      <FormTextField
        name={fieldName}
        id={fieldName}
        type="password"
        label={fieldLabel}
        onChange={onPasswordChange}
        rules={{
          required: validators.required.message(fieldName.replace("_", " ")),
          minLength: {
            value: validators.password_requirements.minLength,
            message: validators.password_requirements.message,
          },
        }}
      />
      <FormTextField
        name={FIELD_PASSWORD_CONFIRMATION}
        id={FIELD_PASSWORD_CONFIRMATION}
        type="password"
        label="Password confirmation"
        rules={{
          required: validators.required.message("password confirmation"),
          validate: (value) =>
            value === newPassword || validators.password_confirmation.message,
        }}
      />
    </>
  );
};

export default PasswordConfirmation;

import {
  PERIOD,
  MONTHS,
  MONTHDAYS,
  WEEKDAYS,
  HOURS,
  MINUTES,
} from "./constants";

type Unit = {
  type: string;
  min: number;
  max: number;
  total: number;
  alt?: string[];
};

export const DEFAULT_FIELDS = {
  everyText: "every",
  emptyMonths: "every month",
  emptyMonthDays: "every day of the month",
  emptyWeekDays: "every day of the week",
  emptyHours: "every hour",
  emptyMinutes: "every minute",
  emptyMinutesForHourPeriod: "every",
  prefixPeriod: "Every",
  prefixMonths: "in",
  prefixMonthDays: "on",
  prefixWeekDays: "on",
  prefixWeekDaysForMonthAndYearPeriod: "and",
  prefixHours: "at",
  prefixMinutes: ":",
  prefixMinutesForHourPeriod: "at",
  suffixMinutesForHourPeriod: "minute(s)",
  errorInvalidCron: "Invalid cron expression",
  weekDays: [
    // Order is important, the index will be used as value
    "Sunday", // Sunday must always be first, it's "0"
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    // Order is important, the index will be used as value
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  periods: ["year", "month", "week", "day", "hour", "minute"],
};

const requireLeadingZero = (value: number, unit: Unit) => {
  return (
    value < 10 &&
    (unit.type === "minutes" ||
      unit.type === "hours" ||
      unit.type === "monthDays")
  );
};

/**
 * Creates an array of integers from start to end, inclusive
 */
export function range(start: number, end: number) {
  const array = [];

  for (let i = start; i <= end; i++) {
    array.push(i);
  }

  return array;
}

/**
 * Sorts an array of numbers
 */
export function sort(array: number[]) {
  array.sort(function (a, b) {
    return a - b;
  });

  return array;
}

/**
 * Removes duplicate entries from an array
 */
function dedup(array: number[]) {
  const result: typeof array = [];

  array.forEach(function (i) {
    if (result.indexOf(i) < 0) {
      result.push(i);
    }
  });

  return result;
}

export const UNITS = [
  {
    type: "minutes",
    min: 0,
    max: 59,
    total: 60,
  },
  {
    type: "hours",
    min: 0,
    max: 23,
    total: 24,
  },
  {
    type: "monthDays",
    min: 1,
    max: 31,
    total: 31,
  },
  {
    type: "months",
    min: 1,
    max: 12,
    total: 12,
    alt: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
  },
  {
    type: "weekDays",
    min: 0,
    max: 6,
    total: 7,
    alt: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  },
];

/**
 * Set values from cron string
 */
export function setValuesFromCronString(
  cronString: string,
  onError?: () => void
) {
  try {
    const cronParts = parseCronString(cronString);

    return {
      [PERIOD]: getPeriodFromCronparts(cronParts),
      [MINUTES]: getObjectFromValues(cronParts[0], UNITS[0]),
      [HOURS]: getObjectFromValues(cronParts[1], UNITS[1]),
      [MONTHDAYS]: getObjectFromValues(cronParts[2], UNITS[2]),
      [MONTHS]: getObjectFromValues(
        cronParts[3],
        UNITS[3],
        DEFAULT_FIELDS.months
      ),
      [WEEKDAYS]: getObjectFromValues(
        cronParts[4],
        UNITS[4],
        DEFAULT_FIELDS.weekDays
      ),
    };

    // Handle invalid cron string during onChange
    // resetError && resetError();
  } catch (err) {
    onError?.();

    return;
  }
}

/**
 * Get cron string from values
 */
export function getCronStringFromValues({
  months,
  monthDays,
  weekDays,
  hours,
  minutes,
  period,
}: {
  [MONTHS]: { label: string; value: number }[];
  [MONTHDAYS]: { label: string; value: number }[];
  [WEEKDAYS]: { label: string; value: number }[];
  [HOURS]: { label: string; value: number }[];
  [MINUTES]: { label: string; value: number }[];
  [PERIOD]: { label: string; value: number };
}) {
  const periodLabel = period.label;
  const monthsArray = periodLabel === "year" ? getArrayFromValue(months) : [];
  const monthDaysArray =
    periodLabel === "year" || periodLabel === "month"
      ? getArrayFromValue(monthDays)
      : [];
  const weekDaysArray =
    periodLabel === "year" || periodLabel === "month" || periodLabel === "week"
      ? getArrayFromValue(weekDays)
      : [];
  const hoursArray =
    periodLabel !== "hour" && periodLabel !== "minute"
      ? getArrayFromValue(hours)
      : [];
  const minutesArray =
    periodLabel !== "minute" ? getArrayFromValue(minutes) : [];

  const parsedArray = parseCronArray(
    [minutesArray, hoursArray, monthDaysArray, monthsArray, weekDaysArray],
    false
  );

  return cronToString(parsedArray);
}

/**
 * Returns the cron part array as a string.
 */

export function partToString(
  cronPart: number[],
  unit: Unit,
  humanize: unknown,
  leadingZero: unknown,
  clockFormat: string
) {
  let retval = "";

  if (isFull(cronPart, unit) || cronPart.length === 0) {
    retval = "*";
  } else {
    const step = getStep(cronPart);

    if (step && isInterval(cronPart, step)) {
      if (isFullInterval(cronPart, unit, step)) {
        retval = `*/${step}`;
      } else {
        retval = `${formatValue(
          getMin(cronPart),
          unit,
          humanize,
          leadingZero,
          clockFormat
        )}-${formatValue(
          getMax(cronPart),
          unit,
          humanize,
          leadingZero,
          clockFormat
        )}/${step}`;
      }
    } else {
      retval = toRanges(cronPart)
        .map((range) => {
          if (Array.isArray(range)) {
            return `${formatValue(
              range[0],
              unit,
              humanize,
              leadingZero,
              clockFormat
            )}-${formatValue(
              range[1],
              unit,
              humanize,
              leadingZero,
              clockFormat
            )}`;
          }

          return formatValue(range, unit, humanize, leadingZero, clockFormat);
        })
        .join(",");
    }
  }

  return retval;
}

/**
 * Format the value
 */
export function formatValue(
  value: number,
  unit: Unit,
  humanize: unknown,
  leadingZero: any,
  clockFormat: string
) {
  let cronPartString = value.toString();
  const { type, alt, min } = unit;
  const needLeadingZero =
    leadingZero && (leadingZero === true || leadingZero.includes(type));
  const need24HourClock =
    clockFormat === "24-hour-clock" && (type === "hours" || type === "minutes");

  if ((humanize && type === "weekDays") || (humanize && type === "months")) {
    cronPartString = alt?.[value - min] || "";
  } else if (value < 10 && (needLeadingZero || need24HourClock)) {
    cronPartString = cronPartString.padStart(2, "0");
  }

  if (type === "hours" && clockFormat === "12-hour-clock") {
    const suffix = value >= 12 ? "PM" : "AM";
    let hour = value % 12 || 12;

    if (hour < 10 && needLeadingZero) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      hour = hour.toString().padStart(2, "0");
    }

    cronPartString = `${hour}${suffix}`;
  }

  return cronPartString;
}

/**
 * Parses a 2-dimentional array of integers as a cron schedule
 */
function parseCronArray(cronArr: number[][], humanizeValue: unknown) {
  if (cronArr.length === 5) {
    return cronArr.map((partArr, idx) => {
      const unit = UNITS[idx];
      const parsedArray = parsePartArray(partArr, unit);

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 3.
      return partToString(parsedArray, unit, humanizeValue);
    });
  }

  throw new Error("Invalid cron array");
}

/**
 * Returns the cron array as a string
 */
function cronToString(parts: string[]) {
  return parts.join(" ");
}

/**
 * Find the period from cron parts
 */
export function getPeriodFromCronparts(cronParts: number[][]) {
  if (cronParts[3].length > 0) {
    return { value: 1, label: "year" };
  } else if (cronParts[2].length > 0) {
    return { value: 2, label: "month" };
  } else if (cronParts[4].length > 0) {
    return { value: 3, label: "week" };
  } else if (cronParts[1].length > 0) {
    return { value: 4, label: "day" };
  } else if (cronParts[0].length > 0) {
    return { value: 5, label: "hour" };
  }

  return { value: 6, label: "minute" };
}

/**
 * Find value from cron parts
 */
const getObjectFromValues = (
  values: number[],
  unit: Unit,
  options?: string[]
) => {
  return values.map((value) => {
    const values = {
      value,
      label: unit.alt
        ? unit.alt[value - unit.min]
        : requireLeadingZero(value, unit)
        ? value.toString().padStart(2, "0")
        : options?.[value - unit.min] ?? value.toString(),
    };

    return values;
  });
};

/**
 * Parses a cron string to an array of parts
 */
export function parseCronString(str: string) {
  if (typeof str !== "string") {
    throw new Error("Invalid cron string");
  }

  const parts = str.replace(/\s+/g, " ").trim().split(" ");

  if (parts.length === 5) {
    return parts.map((partStr, idx) => {
      return parsePartString(partStr, UNITS[idx]);
    });
  }

  throw new Error("Invalid cron string format");
}

/**
 * Parses a string as a range of positive integers
 */
function parsePartString(str: string, unit: Unit) {
  if (str === "*" || str === "*/1") {
    return [];
  }

  const stringParts = str.split("/");

  if (stringParts.length > 2) {
    throw new Error(`Invalid value "${unit.type}"`);
  }

  const rangeString = replaceAlternatives(
    stringParts[0],
    unit.min,
    unit.alt || []
  );
  let parsedValues;

  if (rangeString === "*") {
    parsedValues = range(unit.min, unit.max);
  } else {
    parsedValues = sort(
      dedup(
        fixSunday(
          rangeString
            .split(",")
            .map((range) => {
              return parseRange(range, str, unit);
            })
            .flat(),
          unit
        )
      )
    );

    const value = outOfRange(parsedValues, unit);

    if (typeof value !== "undefined") {
      throw new Error(`Value "${value}" out of range for ${unit.type}`);
    }
  }

  const step = parseStep(stringParts[1], unit);
  const intervalValues = applyInterval(parsedValues, step);

  if (intervalValues.length === unit.total) {
    return [];
  } else if (intervalValues.length === 0) {
    throw new Error(`Empty interval value "${str}" for ${unit.type}`);
  }

  return intervalValues;
}

/**
 * Replaces the alternative representations of numbers in a string
 */
function replaceAlternatives(str: string, min: number, alt: string[]) {
  if (alt) {
    str = str.toUpperCase();

    for (let i = 0; i < alt.length; i++) {
      str = str.replace(alt[i], `${i + min}`);
    }
  }

  return str;
}

/**
 * Replace all 7 with 0 as Sunday can be represented by both
 */
function fixSunday(values: number[], unit: Unit) {
  if (unit.type === "weekDays") {
    values = values.map(function (value) {
      if (value === 7) {
        return 0;
      }

      return value;
    });
  }

  return values;
}

/**
 * Parses a range string
 */
function parseRange(rangeStr: string, context: string, unit: Unit) {
  const subparts = rangeStr.split("-");

  if (subparts.length === 1) {
    const value = parseInt(subparts[0], 10);

    if (isNaN(value)) {
      throw new Error(`Invalid value "${context}" for ${unit.type}`);
    }

    return [value];
  } else if (subparts.length === 2) {
    const minValue = parseInt(subparts[0], 10);
    const maxValue = parseInt(subparts[1], 10);

    if (maxValue <= minValue) {
      throw new Error(
        `Max range is less than min range in "${rangeStr}" for ${unit.type}`
      );
    }

    return range(minValue, maxValue);
  } else {
    throw new Error(`Invalid value "${rangeStr}" for ${unit.type}`);
  }
}

/**
 * Finds an element from values that is outside of the range of unit
 */
function outOfRange(values: number[], unit: Unit) {
  const first = values[0];
  const last = values[values.length - 1];

  if (first < unit.min) {
    return first;
  } else if (last > unit.max) {
    return last;
  }

  return;
}

/**
 * Parses the step from a part string
 */
function parseStep(step: string, unit: Unit) {
  if (typeof step !== "undefined") {
    const parsedStep = parseInt(step, 10);

    if (isNaN(parsedStep) || parsedStep < 1) {
      throw new Error(`Invalid interval step value "${step}" for ${unit.type}`);
    }

    return parsedStep;
  }

  return 0;
}

/**
 * Applies an interval step to a collection of values
 */
function applyInterval(values: number[], step: number) {
  if (step) {
    const minVal = values[0];

    values = values.filter((value) => {
      return value % step === minVal % step || value === minVal;
    });
  }

  return values;
}

/**
 * Validates a range of positive integers
 */
export function parsePartArray(arr: number[], unit: Unit) {
  const values = sort(dedup(fixSunday(arr, unit)));

  if (values.length === 0) {
    return values;
  }

  const value = outOfRange(values, unit);

  if (typeof value !== "undefined") {
    throw new Error(`Value "${value}" out of range for ${unit.type}`);
  }

  return values;
}

/**
 * Returns true if range has all the values of the unit
 */
function isFull(values: unknown[], unit: Unit) {
  return values.length === unit.max - unit.min + 1;
}

/**
 * Returns the difference between first and second elements in the range
 */
function getStep(values: number[]) {
  if (values.length > 2) {
    const step = values[1] - values[0];

    if (step > 1) {
      return step;
    }

    return 0;
  }

  return 0;
}

/**
 * Returns true if the range can be represented as an interval
 */
function isInterval(values: number[], step: number) {
  for (let i = 1; i < values.length; i++) {
    const prev = values[i - 1];
    const value = values[i];

    if (value - prev !== step) {
      return false;
    }
  }

  return true;
}

/**
 * Returns true if the range contains all the interval values
 */
function isFullInterval(values: number[], unit: Unit, step: number) {
  const min = getMin(values);
  const max = getMax(values);
  const haveAllValues = values.length === (max - min) / step + 1;

  if (min === unit.min && max + step > unit.max && haveAllValues) {
    return true;
  }

  return false;
}

/**
 * Returns the smallest value in the range
 */
function getMin(values: number[]) {
  return values[0];
}

/**
 * Returns the largest value in the range
 */
function getMax(values: number[]) {
  return values[values.length - 1];
}

/**
 * Returns the range as an array of ranges
 * defined as arrays of positive integers
 */
function toRanges(values: number[]) {
  const retval: any[] = [];
  let startPart: number | null = null;

  values.forEach((value, index, self) => {
    if (value !== self[index + 1] - 1) {
      if (startPart !== null) {
        retval.push([startPart, value]);
        startPart = null;
      } else {
        retval.push(value);
      }
    } else if (startPart === null) {
      startPart = value;
    }
  });

  return retval;
}

export const getOptionsFromLabels = (options: string[], unit: Unit) =>
  options.map((option, index) => {
    const number = unit.min === 0 ? index : index + 1;

    return {
      value: number,
      label: unit.alt?.[number - unit.min] ?? option,
    };
  });

export const getOptionsFromNumbers = (unit: Unit) =>
  [...Array(unit.total)].map((_, index) => {
    const number = unit.min === 0 ? index : index + 1;

    return {
      value: number,
      label:
        number < 10 ? number.toString().padStart(2, "0") : number.toString(),
    };
  });

export const getPeriodOptions = (options: string[]) =>
  options.map((option, index) => ({
    value: index,
    label: option,
  }));
export const getArrayFromValue = (input: { value: number }[]) => {
  return (input || []).map((item) => item.value);
};

import { Grid } from "@mui/material";
import { useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";

import { FormWrapper } from "components/atoms";
import {
  ControlledEnvironmentVariablesCopy,
  FormSection,
} from "components/molecules";

import type { DeploymentDetailsRouteParams } from "./types";

type ControlledEnvVarsCopyProps = {
  onCopySuccess?: () => void;
};

export const ControlledEnvVarsCopy = ({
  onCopySuccess,
}: ControlledEnvVarsCopyProps) => {
  const history = useHistory();
  const match = useRouteMatch();

  const baseUrl = useContext(BaseUrlContext);

  const { deploymentName } = useParams<DeploymentDetailsRouteParams>();

  const onSuccess = () => {
    onCopySuccess
      ? onCopySuccess()
      : history.push(`${baseUrl}/environment-variables`);
  };

  const { versionName } =
    useParams<{
      versionName: string;
    }>();

  return (
    <>
      <BreadcrumbsItem to={match.url}>Environment variables</BreadcrumbsItem>

      <FormWrapper>
        <Grid container direction="column" wrap="nowrap" mt={4}>
          <Grid item>
            <FormSection title="Copy from">
              <ControlledEnvironmentVariablesCopy
                deploymentName={deploymentName}
                deploymentVersionName={versionName}
                onCopySuccess={onSuccess}
                isPage={true}
              />
            </FormSection>
          </Grid>
        </Grid>
      </FormWrapper>
    </>
  );
};

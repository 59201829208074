import {
  Card as MuiCard,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router";

import { spacing } from "assets/styles/theme";

import { CardHeader } from "./CardHeader";
import { CardHeaderTitle } from "./CardHeaderTitle";
import { Link } from "../Link";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { SxProps, TypographyVariant } from "@mui/material/styles";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties } from "react";

type CardProps = {
  title?: string | React.ReactNode;
  icon?: OverridableComponent<SvgIconTypeMap>;
  iconColor?:
    | "secondary"
    | "inherit"
    | "disabled"
    | "info"
    | "primary"
    | "error"
    | undefined;
  actions?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  customActions?: React.ReactNode;
  link?: string;
  contentStyleClassName?: string;
  variant?: TypographyVariant;
  height?: string;
  sx?: SxProps;
  contentStyle?: CSSProperties;
};

export const Card = ({
  title,
  icon: Icon,
  iconColor = "secondary",
  actions = null,
  contentStyleClassName = "",
  children,
  link,
  variant = "h5",
  height = "100%",
  sx,
  contentStyle,
}: CardProps) => {
  const history = useHistory();
  const theme = useTheme() as AppThemeProps;

  const redirectLink = () => {
    if (link) history.push(link);
  };

  return (
    <MuiCard
      variant="outlined"
      sx={{
        ...sx,
        height: height,
      }}
    >
      {title && (
        <CardHeader
          header={
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              paddingTop={spacing[16]}
            >
              <CardHeaderTitle
                style={{
                  display: "flex",
                  paddingLeft: spacing[16],
                }}
              >
                {Icon &&
                  (link ? (
                    <IconButton
                      onClick={redirectLink}
                      sx={{
                        backgroundColor: "transparent",
                        width: spacing[24],
                        height: spacing[24],
                        marginRight: spacing[8],
                      }}
                    >
                      <Icon
                        color={iconColor}
                        fontSize="small"
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </IconButton>
                  ) : (
                    <Avatar
                      sizes="small"
                      sx={{
                        backgroundColor: "transparent",
                        width: spacing[24],
                        height: spacing[24],
                        marginRight: spacing[8],
                      }}
                    >
                      <Icon
                        fontSize="small"
                        width={20}
                        height={20}
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </Avatar>
                  ))}
                {link ? (
                  <Link
                    to={link}
                    color="primary"
                    underline="none"
                    variant="h5"
                    className="CardHeader"
                  >
                    {title}
                  </Link>
                ) : (
                  <Typography variant={variant}>{title}</Typography>
                )}
              </CardHeaderTitle>
              <Box paddingRight={spacing[16]}>{actions}</Box>
            </Box>
          }
        />
      )}
      <CardContent
        className={contentStyleClassName}
        style={contentStyle || { height: "100%" }}
      >
        {children}
      </CardContent>
    </MuiCard>
  );
};

import styled from "@emotion/styled";
import { Box } from "@mui/material";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

import type { ReactNode, FC } from "react";

export type SwitchWrapperProps = {
  name?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
};

export const SwitchWrapper: FC<React.PropsWithChildren<SwitchWrapperProps>> = ({
  tooltip,
  children,
}) => (
  <S_SwitchWrapper display="flex" alignItems="center">
    <Box>{children}</Box>
    {!!tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
  </S_SwitchWrapper>
);

const S_SwitchWrapper = styled(Box)`
  & .info-tooltip {
    margin: 0;
  }
`;

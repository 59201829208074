import { Route as RouterRoute } from "react-router-dom";

import { ErrorBoundary } from "./ErrorBoundary";

import type { RouteProps } from "react-router-dom";

export const Route = ({
  component: Component,
  render,
  children,
  ...rest
}: RouteProps) => (
  <RouterRoute
    {...rest}
    render={(routeProps) => (
      <ErrorBoundary>
        {/** @ts-expect-error */}
        {Component ? (
          <Component {...routeProps} />
        ) : (
          render?.(routeProps) || children
        )}
      </ErrorBoundary>
    )}
  />
);

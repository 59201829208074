import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetPassword } from "libs/data/endpoints/user/user";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const usePasswordResetToken = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const resetPasswordToken = async (new_password: string, token: string) => {
    setIsLoading(true);
    try {
      await resetPassword(token, { new_password });
      history.push("/sign-in");
      dispatch(
        createSuccessNotification("Your password was changed successfully.")
      );
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError)?.message));
    } finally {
      setIsLoading(false);
    }
  };

  return { resetPasswordToken, isLoading };
};

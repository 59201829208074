import { ENV_NAMES } from "libs/env/env-names";

import { env } from "./EnvironmentManager";

export const trackingEnabled = () => {
  const enabled = env.get(ENV_NAMES.TRACKING_ENABLED);

  return enabled !== undefined ? enabled : true;
};

export const googleAnalyticsEnabled = (): boolean => {
  const enabled = env.get(ENV_NAMES.GOOGLE_ANALYTICS_ENABLED);

  return enabled !== undefined ? enabled : trackingEnabled();
};

export const hotjarEnabled = (): boolean => {
  const enabled = env.get(ENV_NAMES.HOTJAR_ENABLED);

  return enabled !== undefined ? enabled : trackingEnabled();
};

export const productFruitsEnabled = (): boolean => {
  const enabled = env.get(ENV_NAMES.PRODUCT_FRUITS_ENABLED);

  return enabled !== undefined ? enabled : trackingEnabled();
};

export const requiresConsent = (): boolean => {
  return !!env.get(ENV_NAMES.REQUIRES_CONSENT);
};

export const sentryEnabled = (): boolean => {
  const enabled =
    env.get(ENV_NAMES.SENTRY_ENABLED) && env.get(ENV_NAMES.SENTRY_DSN);

  return enabled !== undefined ? enabled : trackingEnabled();
};

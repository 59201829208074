import { useTheme } from "@mui/material";

export const OperatorCreateSubRequests = () => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2440_24716)">
        <circle
          cx="41.5441"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
      </g>
      <path
        d="M67.502 28H34.7903V47.3296H67.502"
        stroke="white"
        strokeWidth="3"
      />
      <path d="M45.9434 28V48.0731" stroke="white" strokeWidth="3" />
      <path d="M57.3516 28.7432V46.5859" stroke="white" strokeWidth="3" />
      <path
        d="M28.7778 38.7832C29.2074 38.3536 29.2074 37.6571 28.7778 37.2276L21.7775 30.2272C21.3479 29.7976 20.6514 29.7976 20.2218 30.2272C19.7922 30.6568 19.7922 31.3533 20.2218 31.7828L26.4444 38.0054L20.2218 44.2279C19.7922 44.6575 19.7922 45.354 20.2218 45.7835C20.6514 46.2131 21.3479 46.2131 21.7775 45.7835L28.7778 38.7832ZM13 39.1054L28 39.1054V36.9054L13 36.9054V39.1054Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_2440_24716"
          x="0"
          y="0"
          width="83.0879"
          height="83.0884"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2440_24716"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2440_24716"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

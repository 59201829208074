import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { createId } from "libs/utilities/utils";

import { Dialog, PrimaryButton, TextField } from "components/atoms";

interface ImportConflictDialogProps {
  onClose: () => void;
  open: boolean;
  selectedEntity: { type: string; name: string } | null;
  resolveNameConflict: (oldName: string, newName: string) => void;
  isEntityNameConflict: (entityName: string) => boolean;
  isMissingEnvVariables: (entityName: string) => boolean;
  addEnvironmentVariableValue: (
    entityName: string,
    variableName: string,
    value: string
  ) => void;
  missingVars: (entityName: string) => string[];
}

export const ImportConflictDialog = ({
  onClose,
  open,
  selectedEntity,
  resolveNameConflict,
  isEntityNameConflict,
  isMissingEnvVariables,
  addEnvironmentVariableValue,
  missingVars,
}: ImportConflictDialogProps) => {
  const [newName, setNewName] = useState<string | undefined>(
    selectedEntity?.name
  );
  const [newVariables, setNewVariables] = useState<{
    [id: string]: string;
  }>({});
  const [inputError, setInputError] = useState<boolean>(false);

  useEffect(() => {
    const newRandomName =
      selectedEntity?.name && `${selectedEntity.name}-${createId()}`;
    newRandomName && setNewName(newRandomName);
  }, [selectedEntity?.name]);

  const handleResolve = () => {
    if (selectedEntity?.name && newName && newName !== selectedEntity?.name) {
      setInputError(false);
      isEntityNameConflict(selectedEntity?.name) &&
        resolveNameConflict(selectedEntity?.name, newName);
      const envVarNames = Object.keys(newVariables);
      envVarNames.forEach((envVarName) => {
        addEnvironmentVariableValue(
          selectedEntity.name,
          envVarName,
          newVariables[envVarName]
        );
      });
      onClose();
    } else {
      setInputError(true);
    }
  };

  const handleOnChange = (input: string) => {
    setInputError(false);
    setNewName(input);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      Actions={<PrimaryButton onClick={handleResolve}>Resolve</PrimaryButton>}
      title={`Resolve conflicts for ${selectedEntity?.type} ${selectedEntity?.name}`}
    >
      {isEntityNameConflict(selectedEntity?.name || "") && (
        <>
          <Typography mt={-1} mb={1}>
            You already have a {selectedEntity?.type} with the same name. Please
            provide a new name for <strong>{selectedEntity?.name}</strong>
          </Typography>
          <TextField
            name="name"
            label="New name"
            defaultValue={newName}
            value={newName}
            onChange={(e) => handleOnChange(e.target.value)}
            error={inputError}
            sx={{ width: "50%" }}
            size="small"
          />
          {inputError && (
            <Typography color="error" variant="subtitle2">
              Name conflict not resolved with this new name
            </Typography>
          )}
        </>
      )}
      {isMissingEnvVariables(selectedEntity?.name || "") && (
        <>
          <Typography mt={3}>
            {`Environment variables are missing values, please provide them below`}
          </Typography>

          <>
            {selectedEntity &&
              missingVars(selectedEntity.name).map((variable: string) => (
                <>
                  <Box
                    alignItems="center"
                    width="100%"
                    height="100%"
                    display="flex"
                    mt={1}
                  >
                    <Typography variant="h5">{variable}</Typography>

                    <TextField
                      name="envvar"
                      label="Value"
                      placeholder="value"
                      value={newVariables[variable]}
                      onChange={(e) =>
                        setNewVariables({
                          ...newVariables,
                          [variable]: e.target.value,
                        })
                      }
                      error={inputError}
                      style={{ width: "50%", marginLeft: "20px" }}
                      size="small"
                    />
                  </Box>
                </>
              ))}
          </>

          {inputError && (
            <Typography color="error" variant="subtitle2">
              Name conflict not resolved with this new name
            </Typography>
          )}
        </>
      )}
    </Dialog>
  );
};

import { Alert as MuiAlert, useTheme } from "@mui/material";

import type { AlertProps as MuiAlertProps } from "@mui/lab";
import "./Alert.scss";
import type { AppThemeProps } from "assets/styles/theme/theme";

type AlertProps = {
  children: React.ReactNode;
} & MuiAlertProps;

export const Alert = ({ children, ...props }: AlertProps) => {
  const severity = props.severity;
  const theme = useTheme() as AppThemeProps;

  // All Alerts filled except for info
  const variant = severity === "info" ? "standard" : "filled";

  return (
    <MuiAlert
      className="alert"
      variant={variant}
      {...props}
      severity={severity}
      sx={{
        color:
          severity === "info"
            ? theme.palette.primary.main
            : theme.palette.success.contrastText,
        bgcolor: severity === "info" ? theme.palette.info.light : null,
        "& .MuiAlert-icon": {
          color: severity === "info" ? theme.palette.info.main : null,
        },
      }}
    >
      {children}
    </MuiAlert>
  );
};

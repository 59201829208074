import { useCallback } from "react";

import {
  pipelinesCreate,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";

import type { PipelineCreate } from "libs/data/models";

export const usePipelineCreate = (projectName: string) => {
  const { mutate } = usePipelinesList(projectName, undefined, {
    swr: { swrKey: `/projects/${projectName}/pipelines` },
  });

  return useCallback(
    async (data: PipelineCreate) => {
      if (!projectName) {
        return Promise.reject({
          error:
            "Couldn't make the API call because projectName is not available",
        });
      }

      return pipelinesCreate(projectName, data).then(async (result) => {
        await mutate();

        return result;
      });
    },
    [mutate, projectName]
  );
};

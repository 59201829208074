import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "@mui/material";

import type { LinkProps } from "@mui/material";
import type { CSSProperties } from "react";

const iconStyle: CSSProperties = {
  fontSize: 12,
  position: "relative",
  top: "2px",
};

interface ExternalLinkProps {
  launchIcon?: boolean;
}

const ExternalLink = ({
  href,
  children,
  variant = "inherit",
  color = "secondary",
  underline = "always",
  launchIcon = true,
  ...props
}: ExternalLinkProps & LinkProps) => {
  return (
    <>
      <Link
        href={href}
        color={color}
        variant={variant}
        underline={underline}
        target="_blank"
        {...props}
      >
        {children}
        {launchIcon && (
          <>
            {} <LaunchIcon style={iconStyle} />
          </>
        )}
      </Link>
    </>
  );
};

export default ExternalLink;

import { useEffect } from "react";
import { string } from "yup";

import {
  FIELD_ACCESS_KEY,
  FIELD_CONNECTION_STRING,
  FIELD_CREDENTIALS,
  FIELD_JSON_KEY,
  FIELD_PROVIDER,
  FIELD_SECRET_KEY,
} from "libs/constants/fields";
import { BucketDetailProvider } from "libs/data/models";

import { FormikTextInput } from "components/molecules";

import type { FormikProps, FormikValues } from "formik";

export interface CredentialsFieldProps {
  control: FormikProps<FormikValues>;
  placeholder?: string;
  editing?: boolean;
}

export const S3CredentialsField = ({
  control,
  placeholder,
  editing,
}: CredentialsFieldProps) => {
  useEffect(() => {
    control.setFieldValue(FIELD_CREDENTIALS, {
      [FIELD_ACCESS_KEY]: "",
      [FIELD_SECRET_KEY]: "",
    });

    return () => control.setFieldValue(FIELD_CREDENTIALS, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikTextInput
        control={control}
        label="Access Key"
        name={`${FIELD_CREDENTIALS}.${FIELD_ACCESS_KEY}`}
        placeholder={placeholder}
        autoComplete="off"
        validation={editing ? string().optional() : string().required()}
        required={!editing}
      />
      <FormikTextInput
        autoComplete="new-password"
        control={control}
        label="Secret Key"
        name={`${FIELD_CREDENTIALS}.${FIELD_SECRET_KEY}`}
        placeholder={placeholder}
        type="password"
        validation={editing ? string().optional() : string().required()}
        required={!editing}
      />
    </>
  );
};

export const AzureCredentialsField = ({
  control,
  placeholder,
  editing,
}: CredentialsFieldProps) => {
  useEffect(() => {
    control.setFieldValue(FIELD_CREDENTIALS, {
      [FIELD_CONNECTION_STRING]: "",
    });

    return () => control.setFieldValue(FIELD_CREDENTIALS, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikTextInput
      control={control}
      label="Connection string"
      name={`${FIELD_CREDENTIALS}.${FIELD_CONNECTION_STRING}`}
      placeholder={placeholder}
      autoComplete="off"
      validation={editing ? string().optional() : string().required()}
      required={!editing}
    />
  );
};

export const GoogleCredentialsField = ({
  control,
  placeholder,
  editing,
}: CredentialsFieldProps) => {
  useEffect(() => {
    control.setFieldValue(FIELD_CREDENTIALS, {
      [FIELD_JSON_KEY]: "",
    });

    return () => control.setFieldValue(FIELD_CREDENTIALS, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikTextInput
      control={control}
      label="JSON Key File"
      name={`${FIELD_CREDENTIALS}.${FIELD_JSON_KEY}`}
      placeholder={placeholder}
      autoComplete="off"
      multiline
      minRows={7}
      maxRows={20}
      validation={editing ? string().optional() : string().required()}
      required={!editing}
    />
  );
};

export const CredentialsField = ({
  control,
  placeholder,
  editing = false,
}: CredentialsFieldProps) => {
  const providerName = control.values[FIELD_PROVIDER];

  useEffect(() => {
    return () => control.setFieldValue(FIELD_CREDENTIALS, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (providerName) {
    case BucketDetailProvider.amazon_s3:
      return (
        <S3CredentialsField
          control={control}
          placeholder={placeholder}
          editing={editing}
        />
      );
    case BucketDetailProvider.azure_blob_storage:
      return (
        <AzureCredentialsField
          control={control}
          placeholder={placeholder}
          editing={editing}
        />
      );
    case BucketDetailProvider.google_cloud_storage:
      return (
        <GoogleCredentialsField
          control={control}
          placeholder={placeholder}
          editing={editing}
        />
      );
    default:
      return null;
  }
};

//TO DO: remove overrides from this file that deal with colors (apply into components with sx instead) - not possible to update the colors on theme toggle from here
import appTheme from "./theme";

import type { OverridesStyleRules } from "@mui/material/styles/overrides";

const components: OverridesStyleRules = {
  MuiGrid: {
    styleOverrides: {
      "spacing-xs-1": {
        width: "calc(100% + 8px)",
        margin: "-4px",
        "& > .MuiGrid-item": {
          padding: "4px",
        },
      },
      "spacing-xs-2": {
        width: "calc(100% + 16px)",
        margin: "-8px",
        "& > .MuiGrid-item": {
          padding: "8px",
        },
      },
      "spacing-xs-3": {
        width: "calc(100% + 24px)",
        margin: "-12px",
        "& > .MuiGrid-item": {
          padding: "12px",
        },
      },
      "spacing-xs-4": {
        width: "calc(100% + 32px)",
        margin: "-16px",
        "& > .MuiGrid-item": {
          padding: "16px",
        },
      },
      "spacing-xs-5": {
        width: "calc(100% + 40px)",
        margin: "-20px",
        "& > .MuiGrid-item": {
          padding: "16px",
        },
      },
      "grid-xs-4": {
        width: "33.3%",
      },
      "grid-xs-5": {
        width: "41.6%",
      },
      "grid-xs-6": {
        width: "50%",
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        // TO DO: Improve disabled states for double theme logic
        "&.Mui-disabled > .MuiTypography-root": {
          color: appTheme.palette.disabled.primary,
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        "&.MuiTextField-root": {
          "& p": {
            // text under labels within forms (used for errors)
            // TO DO: separate helper texts from error under the forms inputs to handle colors
            color: appTheme.palette.error.main,
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: "3px",
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          color: appTheme.palette.secondary,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected, &.Mui-selected:hover": {
          background: appTheme.palette.primary.main,
          color: appTheme.palette.secondary.light,
        },
      },
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      root: {
        "&.Mui-focused, &.Mui-hovered": {
          backgroundColor: "transparent",
        },
      },
      content: {
        // @ts-ignore
        padding: 0,
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      displayedRows: {
        display: "unset !important",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        "&.MuiLink-root": {
          textDecorationColor: "unset",
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      filledWarning: {
        color: appTheme.palette.primary.contrastText,
      },
    },
  },

  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        // @ts-ignore
        margin: "32px 0 4px",
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: "calc(50% - 16px)",
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "1.9rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      icon: {
        height: "1em",
        width: "1em",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "6px",
        "& > * > * > .MuiLink-root": {
          textDecoration: "none",
        },
        "& > * > * > .MuiLink-root:hover": {
          textDecoration: "underline",
        },
      },
      paddingNone: {
        padding: 0,
      },
      head: {
        fontWeight: 500,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: { overflowY: "unset" },
      // @ts-ignore
      container: { paddingTop: "40px" },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: { padding: "12px 24px" },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: { position: "absolute" },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        webkitFontSmoothing: "antialiased",
        fontWeight: 600,
      },
    },
  },
};

export default components;

import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import { Box, InputLabel, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";
import { MaterialIcon } from "components/atoms/Icon/MaterialIcon";

import { SecondaryButton } from "components/atoms";

interface UploadPackageFieldDropzoneProps {
  disabled: boolean;
  label: string;
  name: string;
  onChange: any;
  rules: any;
  uploading: boolean;
}

export const UploadPackageFieldDropzone = ({
  disabled,
  label,
  name,
  onChange,
  rules,
  uploading,
}: UploadPackageFieldDropzoneProps) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    register(name, rules);
  }, [name, register, rules]);

  const onHandleChange = (file: any) => {
    setValue(name, [file]);
    onChange(file);
  };

  return (
    <>
      <InputLabel style={{ marginBottom: 8 }}>{label}</InputLabel>
      <DropSelectFile
        handleChange={onHandleChange}
        name={name}
        padding={4}
        types={["zip"]}
      >
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography>Drag and drop your file here to upload it</Typography>
          <Typography style={{ marginBottom: 8 }}>or</Typography>
          <SecondaryButton
            disabled={disabled}
            loading={uploading}
            startIcon={<MaterialIcon component={CloudUploadIcon} />}
          >
            {uploading ? "Uploading" : "Upload zip"}
          </SecondaryButton>
        </Box>
      </DropSelectFile>
    </>
  );
};

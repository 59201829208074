import styled from "@emotion/styled";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandIcon from "@mui/icons-material/ExpandLessRounded";
import HideIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Card,
  CardHeader,
  IconButton,
  MenuList,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { UPLOAD_TASK, REQUEST_TASK } from "libs/constants/constants";
import { useDialog } from "libs/hooks";
import {
  deleteTask,
  toggleTasksManager,
  useTaskManager,
} from "store/features/taskManager";

import { RequestTaskItem } from "./RequestTaskItem";
import { UploadTaskItem } from "./UploadTaskItem";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { LOADED, LOADING } from "libs/utilities/request-statuses";

export type RequestTask = {
  id: string;
  status: typeof LOADED | typeof LOADING;
  message: string;
  type: typeof REQUEST_TASK;
  requestParameters: any;
};
export type UploadTask = {
  id: string;
  status: typeof LOADED | typeof LOADING;
  message: string;
  type: typeof UPLOAD_TASK;
  version?: { id: string; deployment: string; version: string };
  bucket?: { name: string; prefix: string };
};

type TasksManagerProps = {
  organizationName: string;
};

const TasksManager = ({ organizationName }: TasksManagerProps) => {
  const {
    toggleDialog: toggleContent,
    open: showContent,
    setOpen,
  } = useDialog(true);
  const dispatch = useDispatch();
  const { tasks } = useTaskManager();
  const theme = useTheme() as AppThemeProps;
  useEffect(() => {
    setOpen(true);
  }, [tasks, setOpen]);

  const deleteItem = (id: string) => dispatch(deleteTask(id));
  const toggle = () => dispatch(toggleTasksManager());

  return (
    <TaskManager elevation={3}>
      <CardHeader
        sx={{
          padding: "4px 16px",
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.main,
        }}
        title="Tasks"
        action={
          <>
            <IconButton
              aria-label="expand"
              color="inherit"
              size="small"
              onClick={toggleContent}
            >
              {showContent ? <HideIcon /> : <ExpandIcon />}
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={toggle}
            >
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <S_MenuList hidden={!showContent}>
        {tasks?.map(({ id, type, ...task }) => {
          if (type === UPLOAD_TASK) {
            return (
              <UploadTaskItem
                id={id}
                key={id}
                deleteTask={deleteItem}
                {...(task as Omit<UploadTask, "id">)}
              />
            );
          } else if (type === REQUEST_TASK) {
            return (
              <RequestTaskItem
                organizationName={organizationName}
                id={id}
                key={id}
                deleteTask={deleteItem}
                {...(task as Omit<RequestTask, "id">)}
              />
            );
          }

          return null;
        })}
      </S_MenuList>
    </TaskManager>
  );
};

const TaskManager = styled(Card)`
  position: fixed;
  right: 80px;
  bottom: 0px;
  width: 390px;
  z-index: 1201;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const S_MenuList = styled(MenuList)`
  padding: 0;
  overflow: auto;
  max-height: 250px;
`;

export default TasksManager;

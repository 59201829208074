import BarChart from "@mui/icons-material/BarChartRounded";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Flash from "@mui/icons-material/FlashOn";
import RequestIcon from "@mui/icons-material/PublishRounded";
import { Grid, useTheme } from "@mui/material";
import { useMemo, useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { RootUrlContext } from "libs/contexts";
import { useGoogleAnalytics } from "libs/hooks";

import { Card } from "components/atoms";
import { PageContainer } from "components/molecules";

import GetStarted from "./GetStarted";
import LastWeekSummary from "./LastWeekSummary";
import ProjectUsage from "./ProjectUsage";
import Resources from "./Resources";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const ProjectDashboard = () => {
  useGoogleAnalytics();
  const match = useRouteMatch();
  const rootUrl = useContext(RootUrlContext);
  const theme = useTheme() as AppThemeProps;

  // hide active instances for now
  // const { projectName } = useParams<{ projectName: string }>();
  // const { data: instances, mutate } = useProjectInstancesList(
  //   projectName,
  //   { limit: 20 },
  //   { swr: { dedupingInterval: 0 } }
  // );

  // useInterval(
  //   () => {
  //     mutate();
  //   },
  //   [mutate],
  //   CONTINUOUS_INSTANCES_DELAY
  // );

  const requestBlock = useMemo(
    () => [
      {
        title: "Last week's activity",
        icon: RequestIcon,
        content: <LastWeekSummary />,
        link: `${rootUrl}/monitoring`,
      },
    ],
    [rootUrl]
  );

  const usageBlocks = useMemo(
    () => [
      { title: "Get started", icon: Flash, content: <GetStarted /> },
      {
        title: "Resources",
        icon: BookmarkIcon,
        content: <Resources />,
      },
      {
        title: "Project usage",
        icon: BarChart,
        content: <ProjectUsage />,
      },
    ],
    []
  );

  return (
    <PageContainer>
      <PageHeader title="Project Dashboard" />
      <BreadcrumbsItem to={match.url}>Dashboard</BreadcrumbsItem>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} container rowGap={2}>
          {usageBlocks.map(({ content, ...props }, key) => (
            <Grid item xs={12} key={key}>
              <Card iconColor="primary" {...props}>
                {content}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6} container>
          {requestBlock.map(({ content, ...props }, key) => (
            <Grid item xs={12} key={key}>
              <Card
                iconColor="primary"
                {...props}
                sx={{
                  "& span": {
                    "& a": { color: theme.palette.text.primary },
                    "& a:visited": { color: theme.palette.text.primary },
                  },
                }}
              >
                {content}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

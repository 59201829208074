/* eslint-disable no-constant-condition */
import { forwardRef } from "react";

import { useColorByStatus } from "libs/hooks";

import type { statusValues } from "libs/utilities/statuses";
import type { ReactNode, CSSProperties } from "react";

type IconProps = {
  component: any;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  className?: string;
  disabled?: boolean;
  htmlColor?: string;
  onClick?: () => void;
  size?: number;
  status?: typeof statusValues[number];
  style?: CSSProperties;
  svgFile?: ReactNode;
};

const Icon = (
  {
    component: IconComponent,
    status,
    className,
    disabled,
    color,
    ...props
  }: IconProps,
  ref: any
) => {
  const colorByStatus = useColorByStatus();

  return IconComponent ? (
    <IconComponent
      className={["icon", className].filter((x) => x).join(" ")}
      style={{
        cursor: "pointer",
        color: color
          ? colorByStatus(color)
          : status
          ? colorByStatus(status)
          : "inherit",
      }}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  ) : null;
};

export default forwardRef(Icon);

import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const IlluRoles = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="600"
      height="410"
      viewBox="0 0 806 610"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M657.569 434.548H16.7305C12.3559 434.543 8.16194 432.803 5.06866 429.71C1.97539 426.616 0.235413 422.422 0.230469 418.048V22.894C0.234034 19.6892 1.50871 16.6166 3.77484 14.3504C6.04097 12.0841 9.1135 10.8094 12.3184 10.8057H661.772C665.032 10.8094 668.158 12.1062 670.463 14.4116C672.769 16.717 674.065 19.8427 674.069 23.103V418.048C674.064 422.422 672.324 426.616 669.231 429.71C666.138 432.803 661.944 434.543 657.569 434.548Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M636.849 413.716H39.6041C30.8491 413.716 23.7266 407.046 23.7266 398.848V54.7427C23.7266 48.6626 29.0046 43.7158 35.4922 43.7158H640.766C647.361 43.7158 652.727 48.7441 652.727 54.9243V398.848C652.727 407.046 645.604 413.716 636.849 413.716Z"
        fill={theme.palette.svgs.widgetBackGround}
      />
      <path
        d="M673.84 24.9502H0V16.5498C0.00634366 12.1607 1.75342 7.95332 4.85796 4.85071C7.96249 1.7481 12.1709 0.00362614 16.5601 0H657.28C661.669 0.00360765 665.878 1.74808 668.982 4.85069C672.087 7.95331 673.834 12.1607 673.84 16.5498V24.9502Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M30.0959 16.7836C32.4616 16.7836 34.3793 14.8659 34.3793 12.5002C34.3793 10.1345 32.4616 8.2168 30.0959 8.2168C27.7303 8.2168 25.8125 10.1345 25.8125 12.5002C25.8125 14.8659 27.7303 16.7836 30.0959 16.7836Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M46.3537 16.7836C48.7194 16.7836 50.6371 14.8659 50.6371 12.5002C50.6371 10.1345 48.7194 8.2168 46.3537 8.2168C43.9881 8.2168 42.0703 10.1345 42.0703 12.5002C42.0703 14.8659 43.9881 16.7836 46.3537 16.7836Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M62.6115 16.7836C64.9772 16.7836 66.895 14.8659 66.895 12.5002C66.895 10.1345 64.9772 8.2168 62.6115 8.2168C60.2459 8.2168 58.3281 10.1345 58.3281 12.5002C58.3281 14.8659 60.2459 16.7836 62.6115 16.7836Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M192.347 97.7656H86.7969C84.6956 97.7651 82.6147 98.1785 80.6733 98.9824C78.7318 99.7863 76.9678 100.965 75.4819 102.451C73.9961 103.937 72.8175 105.701 72.0136 107.642C71.2098 109.583 70.7963 111.664 70.7969 113.766V185.596C70.7963 187.697 71.2098 189.778 72.0136 191.719C72.8175 193.661 73.9961 195.425 75.4819 196.911C76.9678 198.396 78.7318 199.575 80.6733 200.379C82.6147 201.183 84.6956 201.596 86.7969 201.596H192.347C196.59 201.595 200.66 199.909 203.66 196.909C206.661 193.908 208.347 189.839 208.347 185.596V113.766C208.347 109.522 206.661 105.453 203.66 102.453C200.66 99.452 196.59 97.7662 192.347 97.7656Z"
        fill={theme.palette.svgs.starDust}
      />
      <path
        d="M266.797 113.762V185.6C266.797 189.844 268.483 193.913 271.483 196.914C274.484 199.915 278.553 201.6 282.797 201.6H388.345C392.588 201.6 396.658 199.915 399.658 196.914C402.659 193.913 404.345 189.844 404.345 185.6V113.762C404.345 109.518 402.659 105.449 399.658 102.448C396.658 99.4474 392.588 97.7617 388.345 97.7617H282.797C278.553 97.7617 274.484 99.4474 271.483 102.448C268.483 105.449 266.797 109.518 266.797 113.762Z"
        fill={theme.palette.svgs.starDust}
      />
      <path
        d="M462.797 113.762V185.6C462.797 189.844 464.483 193.913 467.483 196.914C470.484 199.915 474.553 201.6 478.797 201.6H584.345C588.588 201.6 592.658 199.915 595.658 196.914C598.659 193.913 600.345 189.844 600.345 185.6V113.762C600.345 109.518 598.659 105.449 595.658 102.448C592.658 99.4474 588.588 97.7617 584.345 97.7617H478.797C474.553 97.7617 470.484 99.4474 467.483 102.448C464.483 105.449 462.797 109.518 462.797 113.762Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M70.7969 272.762V344.6C70.7969 348.844 72.4826 352.913 75.4832 355.914C78.4838 358.915 82.5534 360.6 86.7969 360.6H192.345C196.588 360.6 200.658 358.915 203.658 355.914C206.659 352.913 208.345 348.844 208.345 344.6V272.762C208.345 268.518 206.659 264.449 203.658 261.448C200.658 258.447 196.588 256.762 192.345 256.762H86.7969C82.5534 256.762 78.4838 258.447 75.4832 261.448C72.4826 264.449 70.7969 268.518 70.7969 272.762Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M266.797 272.762V344.6C266.797 348.844 268.483 352.913 271.483 355.914C274.484 358.915 278.553 360.6 282.797 360.6H388.345C392.588 360.6 396.658 358.915 399.658 355.914C402.659 352.913 404.345 348.844 404.345 344.6V272.762C404.345 268.518 402.659 264.449 399.658 261.448C396.658 258.447 392.588 256.762 388.345 256.762H282.797C278.553 256.762 274.484 258.447 271.483 261.448C268.483 264.449 266.797 268.518 266.797 272.762Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M462.797 272.762V344.6C462.797 348.844 464.483 352.913 467.483 355.914C470.484 358.915 474.553 360.6 478.797 360.6H584.345C588.588 360.6 592.658 358.915 595.658 355.914C598.659 352.913 600.345 348.844 600.345 344.6V272.762C600.345 268.518 598.659 264.449 595.658 261.448C592.658 258.447 588.588 256.762 584.345 256.762H478.797C474.553 256.762 470.484 258.447 467.483 261.448C464.483 264.449 462.797 268.518 462.797 272.762Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M139.57 171.681C151.721 171.681 161.57 161.831 161.57 149.681C161.57 137.531 151.721 127.681 139.57 127.681C127.42 127.681 117.57 137.531 117.57 149.681C117.57 161.831 127.42 171.681 139.57 171.681Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M173.189 203.595H105.949C107.52 200.564 109.547 197.792 111.959 195.375C115.02 192.308 118.656 189.875 122.66 188.217C126.664 186.56 130.956 185.709 135.289 185.715H143.849C149.91 185.713 155.855 187.38 161.031 190.534C166.206 193.688 170.413 198.207 173.189 203.595Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M335.57 171.681C347.721 171.681 357.57 161.831 357.57 149.681C357.57 137.531 347.721 127.681 335.57 127.681C323.42 127.681 313.57 137.531 313.57 149.681C313.57 161.831 323.42 171.681 335.57 171.681Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M369.189 203.595H301.949C303.52 200.564 305.547 197.792 307.959 195.375C311.02 192.308 314.656 189.875 318.66 188.217C322.664 186.56 326.956 185.709 331.289 185.715H339.849C345.91 185.713 351.855 187.38 357.031 190.534C362.206 193.688 366.413 198.207 369.189 203.595Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M531.57 171.681C543.721 171.681 553.57 161.831 553.57 149.681C553.57 137.531 543.721 127.681 531.57 127.681C519.42 127.681 509.57 137.531 509.57 149.681C509.57 161.831 519.42 171.681 531.57 171.681Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M565.189 203.595H497.949C499.52 200.564 501.547 197.792 503.959 195.375C507.02 192.308 510.656 189.875 514.66 188.217C518.664 186.56 522.956 185.709 527.289 185.715H535.849C541.91 185.713 547.855 187.38 553.031 190.534C558.206 193.688 562.413 198.207 565.189 203.595Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M139.57 330.681C151.721 330.681 161.57 320.831 161.57 308.681C161.57 296.531 151.721 286.681 139.57 286.681C127.42 286.681 117.57 296.531 117.57 308.681C117.57 320.831 127.42 330.681 139.57 330.681Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M173.189 362.595H105.949C107.52 359.564 109.547 356.792 111.959 354.375C115.02 351.308 118.656 348.875 122.66 347.217C126.664 345.56 130.956 344.709 135.289 344.715H143.849C149.91 344.713 155.855 346.38 161.031 349.534C166.206 352.688 170.413 357.207 173.189 362.595Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M335.57 330.681C347.721 330.681 357.57 320.831 357.57 308.681C357.57 296.531 347.721 286.681 335.57 286.681C323.42 286.681 313.57 296.531 313.57 308.681C313.57 320.831 323.42 330.681 335.57 330.681Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M369.189 362.595H301.949C303.52 359.564 305.547 356.792 307.959 354.375C311.02 351.308 314.656 348.875 318.66 347.217C322.664 345.56 326.956 344.709 331.289 344.715H339.849C345.91 344.713 351.855 346.38 357.031 349.534C362.206 352.688 366.413 357.207 369.189 362.595Z"
        fill={theme.palette.svgs.darkenScreen}
      />
      <path
        d="M531.57 330.681C543.721 330.681 553.57 320.831 553.57 308.681C553.57 296.531 543.721 286.681 531.57 286.681C519.42 286.681 509.57 296.531 509.57 308.681C509.57 320.831 519.42 330.681 531.57 330.681Z"
        fill={theme.palette.svgs.paperWhite}
      />
      <path
        d="M565.189 362.595H497.949C499.52 359.564 501.547 356.792 503.959 354.375C507.02 351.308 510.656 348.875 514.66 347.217C518.664 345.56 522.956 344.709 527.289 344.715H535.849C541.91 344.713 547.855 346.38 553.031 349.534C558.206 352.688 562.413 357.207 565.189 362.595Z"
        fill={theme.palette.svgs.paperWhite}
      />
      <path
        d="M805.008 609.716H544.008C543.743 609.716 543.488 609.61 543.301 609.423C543.113 609.235 543.008 608.981 543.008 608.716C543.008 608.451 543.113 608.196 543.301 608.009C543.488 607.821 543.743 607.716 544.008 607.716H805.008C805.273 607.716 805.527 607.821 805.715 608.009C805.902 608.196 806.008 608.451 806.008 608.716C806.008 608.981 805.902 609.235 805.715 609.423C805.527 609.61 805.273 609.716 805.008 609.716Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M670.717 597.522L658.458 597.521L652.625 550.233L670.719 550.234L670.717 597.522Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M649.699 594.019H673.343V608.905H634.813C634.812 606.95 635.198 605.015 635.946 603.208C636.694 601.402 637.79 599.761 639.173 598.379C640.555 596.996 642.196 595.9 644.002 595.152C645.809 594.404 647.744 594.019 649.699 594.019Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M716.717 597.522L704.458 597.521L698.625 550.233L716.719 550.234L716.717 597.522Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M695.699 594.019H719.343V608.905H680.813C680.812 606.95 681.198 605.015 681.946 603.208C682.694 601.402 683.79 599.761 685.173 598.379C686.555 596.996 688.196 595.9 690.002 595.152C691.809 594.404 693.744 594.019 695.699 594.019Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M715.219 456.683C713.898 455.833 712.779 454.704 711.942 453.376C711.105 452.047 710.569 450.551 710.372 448.993C710.176 447.435 710.323 445.853 710.804 444.358C711.285 442.863 712.088 441.491 713.156 440.34L705.084 325.782L728.337 328.037L728.976 440.224C730.79 442.147 731.837 444.669 731.917 447.312C731.996 449.955 731.104 452.535 729.408 454.564C727.712 456.593 725.331 457.929 722.716 458.32C720.101 458.71 717.433 458.128 715.219 456.683Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M670.138 573.699L656.642 573.055C655.492 573.001 654.407 572.508 653.61 571.678C652.813 570.847 652.364 569.743 652.356 568.592C648.798 513.673 643.87 454.785 651.414 432.036C651.41 431.388 651.546 430.746 651.812 430.155C652.078 429.564 652.469 429.038 652.958 428.612C653.447 428.186 654.022 427.871 654.643 427.687C655.265 427.504 655.919 427.457 656.561 427.55L710.554 435.388C711.621 435.538 712.598 436.067 713.306 436.879C714.014 437.691 714.405 438.731 714.408 439.808C721.302 466.745 721.613 515.59 721.352 566.342C721.357 566.936 721.244 567.525 721.019 568.075C720.795 568.625 720.465 569.125 720.046 569.546C719.628 569.968 719.13 570.302 718.582 570.531C718.034 570.759 717.446 570.877 716.852 570.877H702.302C701.229 570.881 700.19 570.5 699.374 569.802C698.558 569.104 698.019 568.137 697.857 567.076L688.88 510.008C688.749 509.173 688.32 508.414 687.672 507.871C687.025 507.327 686.203 507.037 685.358 507.052C684.513 507.068 683.702 507.389 683.075 507.956C682.448 508.523 682.047 509.297 681.947 510.136L674.821 569.739C674.687 570.831 674.159 571.836 673.336 572.566C672.513 573.296 671.452 573.701 670.352 573.705C670.281 573.705 670.21 573.703 670.138 573.699Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M679.638 439.814C667.739 433.203 658.441 431.466 653.959 431.018C653.363 430.961 652.785 430.783 652.26 430.495C651.735 430.208 651.274 429.817 650.905 429.345C650.528 428.871 650.251 428.325 650.091 427.74C649.93 427.156 649.891 426.545 649.974 425.944L662.911 329.893C663.656 324.274 665.827 318.939 669.216 314.395C672.605 309.852 677.101 306.251 682.275 303.936C687.283 301.634 692.798 300.651 698.293 301.082C703.788 301.513 709.082 303.343 713.67 306.397C714.114 306.692 714.549 306.993 714.976 307.301C720.26 311.172 724.279 316.521 726.528 322.674C728.777 328.827 729.154 335.507 727.612 341.874C719.679 374.329 716.953 427.536 716.488 437.873C716.451 438.754 716.154 439.604 715.635 440.317C715.116 441.03 714.397 441.573 713.57 441.878C708.688 443.652 703.537 444.569 698.344 444.589C691.802 444.628 685.361 442.984 679.638 439.814Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M710.347 364.276C709.802 363.884 709.353 363.375 709.031 362.787C708.709 362.198 708.524 361.545 708.488 360.875L706.784 329.999C706.616 326.94 707.586 323.927 709.507 321.541C711.429 319.154 714.165 317.564 717.19 317.076C720.215 316.588 723.313 317.238 725.887 318.899C728.461 320.56 730.329 323.115 731.131 326.072L738.615 353.677C738.926 354.829 738.768 356.057 738.174 357.092C737.581 358.127 736.601 358.884 735.45 359.198L714.159 364.97C713.512 365.147 712.834 365.176 712.174 365.056C711.514 364.936 710.89 364.669 710.347 364.276Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M690.459 290.895C704.024 290.895 715.021 279.898 715.021 266.333C715.021 252.769 704.024 241.772 690.459 241.772C676.895 241.772 665.898 252.769 665.898 266.333C665.898 279.898 676.895 290.895 690.459 290.895Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M601.456 314.588C601.579 315.128 601.659 315.676 601.695 316.228L644.653 341.01L655.094 334.999L666.226 349.572L643.888 365.492L594.88 326.83C592.882 327.569 590.705 327.683 588.64 327.155C586.575 326.628 584.72 325.484 583.32 323.876C581.921 322.268 581.045 320.273 580.807 318.155C580.57 316.037 580.983 313.897 581.992 312.019C583 310.142 584.557 308.616 586.454 307.644C588.351 306.673 590.498 306.302 592.611 306.58C594.724 306.859 596.702 307.775 598.282 309.205C599.862 310.636 600.969 312.513 601.456 314.588Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M646.186 338.903C646.114 338.236 646.193 337.561 646.417 336.929C646.641 336.297 647.004 335.723 647.479 335.249L669.342 313.381C671.509 311.215 674.4 309.927 677.46 309.766C680.519 309.605 683.53 310.583 685.911 312.511C688.292 314.438 689.875 317.179 690.355 320.205C690.835 323.231 690.178 326.327 688.51 328.897L672.94 352.889C672.29 353.889 671.269 354.591 670.102 354.839C668.935 355.087 667.717 354.863 666.716 354.215L648.212 342.206C647.648 341.842 647.174 341.356 646.823 340.785C646.473 340.213 646.255 339.57 646.186 338.903Z"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M707.839 285.765C703.257 290.646 694.748 288.026 694.151 281.358C694.104 280.84 694.108 280.319 694.161 279.802C694.469 276.849 696.176 274.167 695.767 271.049C695.674 270.273 695.385 269.533 694.927 268.9C691.276 264.01 682.705 271.087 679.259 266.66C677.146 263.946 679.629 259.673 678.008 256.64C675.868 252.636 669.529 254.611 665.554 252.419C661.131 249.979 661.396 243.194 664.307 239.066C667.858 234.033 674.083 231.347 680.231 230.959C686.378 230.572 692.484 232.234 698.223 234.471C704.744 237.012 711.211 240.524 715.224 246.258C720.104 253.232 720.573 262.606 718.133 270.76C716.648 275.72 711.581 281.779 707.839 285.765Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M575.473 291.475C574.779 291.476 574.103 291.252 573.547 290.835L573.513 290.809L566.259 285.26C565.582 284.74 565.139 283.972 565.027 283.125C564.916 282.278 565.146 281.421 565.665 280.743C566.185 280.065 566.953 279.622 567.799 279.509C568.646 279.397 569.503 279.625 570.181 280.144L574.88 283.747L585.983 269.262C586.24 268.926 586.561 268.644 586.928 268.432C587.294 268.221 587.699 268.083 588.118 268.028C588.538 267.972 588.964 268 589.373 268.109C589.781 268.219 590.165 268.408 590.501 268.665L590.501 268.666L590.433 268.761L590.503 268.666C591.181 269.187 591.624 269.955 591.736 270.802C591.848 271.649 591.619 272.506 591.099 273.184L578.04 290.214C577.738 290.607 577.349 290.924 576.905 291.142C576.46 291.36 575.971 291.473 575.476 291.471L575.473 291.475Z"
        fill={theme.palette.svgs.paperWhite}
      />
    </svg>
  );
};

import { Typography, useTheme } from "@mui/material";

import { DeleteButton } from "components/atoms/Button";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";

import { Dialog } from "./Dialog";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ReactNode } from "react";

interface DeleteDialogProps {
  children: ReactNode;
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  actionDisabled?: boolean;
}

export const DeleteDialog = ({
  loading,
  onClose,
  onDelete,
  children,
  actionDisabled,
  ...props
}: DeleteDialogProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Dialog
      Actions={
        <DeleteButton
          loading={loading}
          disabled={actionDisabled}
          onClick={() => {
            onDelete();
            onClose();
          }}
        />
      }
      dialogBodyStyles={{
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: 1.43,
      }}
      Header={<DialogWarningHeader title="Warning" />}
      onClose={onClose}
      {...props}
    >
      <Typography>{children}</Typography>
    </Dialog>
  );
};

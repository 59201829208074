import { injectExternalScript } from "libs/utilities/injectExternalScript";

const hubspotId = "7281963";

export const setupHubspot = () => {
  injectExternalScript(
    `https://js.hs-scripts.com/${hubspotId}.js`,
    "ubiops-hubspot",
    document.body
  );
};

import {
  ARRAY_FILE_TYPE,
  ARRAY_STRING_TYPE,
  FILE_TYPE,
  STRING_TYPE,
} from "libs/constants/constants";

import type {
  DeploymentInputFieldCreate,
  DeploymentOutputFieldCreate,
  InputOutputFieldBaseDataType,
  PipelineVersionObjectList,
} from "libs/data/models";

export const parseConfigInputsOutputsToPipelineObject = (
  pipelineObject: PipelineVersionObjectList
) => {
  const configuration = pipelineObject.configuration;

  if (
    pipelineObject.input_fields &&
    configuration?.input_fields &&
    pipelineObject.input_fields !== configuration?.input_fields
  ) {
    pipelineObject.input_fields =
      configuration.input_fields as DeploymentInputFieldCreate[];
  }

  if (
    pipelineObject.output_fields &&
    configuration?.output_fields &&
    pipelineObject.output_fields !== configuration?.output_fields
  ) {
    pipelineObject.output_fields =
      configuration.output_fields as DeploymentOutputFieldCreate[];
  }

  return pipelineObject;
};

export const areFieldTypesCompatible = (
  inputType: InputOutputFieldBaseDataType,
  outputType: InputOutputFieldBaseDataType
) => {
  const isSameType = inputType === outputType;

  const isStringFileConversion =
    [STRING_TYPE, FILE_TYPE].includes(inputType) &&
    [STRING_TYPE, FILE_TYPE].includes(outputType);

  const isStringArrFileArrConversion =
    [ARRAY_STRING_TYPE, ARRAY_FILE_TYPE].includes(inputType) &&
    [ARRAY_STRING_TYPE, ARRAY_FILE_TYPE].includes(outputType);

  return isSameType || isStringFileConversion || isStringArrFileArrConversion;
};

import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useParams, useRouteMatch } from "react-router-dom";

import { borderRadius, spacing } from "assets/styles/theme";
import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import { FIELD_DATES } from "libs/constants/fields";
import { useGetMonitoringMetrics } from "libs/data/customized/metrics/useGetMonitoringMetrics";
import { ENV_NAMES, env } from "libs/env";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import {
  getGraphsWithDynamicUnit,
  getStoredChartType,
} from "libs/utilities/metrics-helper";

import { SecondaryButton } from "components/atoms";
import { MetricsLayout } from "components/organisms";

import { AddObjectDialog } from "./AddObjectDialog";
import MonitoringChip from "./MonitoringChip";
import { graphs as monitoringGraphs } from "./constants";
import useAddObjects from "./useAddObjects";

import type { MonitoringObject } from "./AddObjectDialog";
import type { MonitoringGraph } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const MonitoringGeneral = () => {
  const { projectName } = useParams<{ projectName: string }>();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [IsAddObjectDialogOpen, setIsAddObjectDialogOpen] = useState(false);
  const { selectedObjects, setSelectedObjects } = useAddObjects(projectName);
  const savedGraphs: { [key: string]: MonitoringGraph[] } = env.get(
    ENV_NAMES.USER_MONITORING_GRAPHS
  );
  const [graphs, setGraphs] = useState(
    savedGraphs?.[projectName] ?? monitoringGraphs
  );

  useEffect(() => {
    const labels = [
      projectName,
      ...selectedObjects.map((object) => object.label),
    ];
    setGraphs((graphs) => {
      const graphWithLabels = graphs.map((graph) => ({
        ...graph,
        labels,
      }));

      return getGraphsWithDynamicUnit(graphWithLabels);
    });
  }, [selectedObjects, projectName]);

  const methods = useForm({
    defaultValues: {
      dates: {
        startDate: getTzAwareDate().subtract(7, "days").startOf("day"),
        endDate: getTzAwareDate(),
      },
    },
  });
  const { control } = methods;
  const colors = useGetSpecialColors();
  const theme = useTheme() as AppThemeProps;

  const dates: any = useWatch({ control, name: FIELD_DATES });

  useGoogleAnalytics();
  const match = useRouteMatch();

  const onGraphRefresh = () => {
    setForceRefresh(!forceRefresh);
  };

  const onObjectDelete = (id: string, userId?: string) => {
    setSelectedObjects(
      selectedObjects?.filter(
        (version) => !(version?.userId === userId && version.id === id)
      )
    );
  };

  const handleObjectSelect = (object: MonitoringObject) => {
    setSelectedObjects([...selectedObjects, object]);
    setIsAddObjectDialogOpen(false);
  };

  const handleGraphRemove = (title: string) => {
    setGraphs((graphs) => graphs.filter((graph) => graph.title !== title));
    env.set(ENV_NAMES.USER_MONITORING_GRAPHS, {
      ...savedGraphs,
      [projectName]: graphs.filter((graph) => graph.title !== title),
    });
  };

  const updateGraphs = (graphs: MonitoringGraph[]) => {
    const labels = [
      projectName,
      ...selectedObjects.map((object) => object.label),
    ];
    setGraphs(graphs.map((graph) => ({ ...graph, labels: labels })));
  };

  const graphsWithChartType = useMemo(
    () => getStoredChartType("monitoring", graphs),
    [graphs]
  );

  const { metricGraphs, isMetricGraphsLoading, setGraphType } =
    useGetMonitoringMetrics(
      [undefined, ...selectedObjects],
      graphsWithChartType,
      forceRefresh,
      dates.startDate,
      dates.endDate,
      handleGraphRemove
    );

  return (
    <>
      <FormProvider {...methods}>
        <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
        <MetricsLayout
          hasAddGraph={graphs?.length < 10}
          visibleGraphs={graphs}
          updateGraphs={updateGraphs}
          hasRemove
          graphs={metricGraphs || []}
          onGraphRefresh={onGraphRefresh}
          isLoading={isMetricGraphsLoading && !metricGraphs}
          enableCrosshair
          handleRemove={handleGraphRemove}
          setGraphType={setGraphType}
          defaultSelectedRange={"Last Week"}
          filters={
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Box display="flex" flexWrap="wrap" flex={1} gap={1}>
                {selectedObjects.map((object, idx) => (
                  <Grid item key={object.id}>
                    <MonitoringChip
                      itemId={object.id}
                      itemLabel={object.label}
                      onDelete={() => onObjectDelete(object.id, object.userId)}
                      color={colors[idx + 1]}
                    />
                  </Grid>
                ))}
              </Box>
              <Box
                alignSelf="flex-end"
                display="flex"
                gap={spacing[4]}
                alignItems="center"
              >
                <Typography variant="body2">Total metrics:</Typography>
                <Box
                  sx={{
                    backgroundColor:
                      metricGraphs?.length === 10
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: `${spacing[2]} ${spacing[6]}`,
                    borderRadius: borderRadius[4],
                    cursor: "default",
                  }}
                >
                  <Tooltip title="Your dashboard can show up to 10 graphs at once.">
                    <Typography variant="h6">
                      {metricGraphs?.length} / 10
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          }
        >
          <SecondaryButton
            startIcon={<FilterListIcon />}
            onClick={() => setIsAddObjectDialogOpen(true)}
            disabled={selectedObjects.length === 5}
            tooltip={
              selectedObjects.length === 5
                ? "Can only view 5 objects at a time. Remove one from the selection to view data of a different object."
                : ""
            }
          >
            Add object
          </SecondaryButton>
        </MetricsLayout>
      </FormProvider>
      <AddObjectDialog
        open={IsAddObjectDialogOpen}
        onClose={() => setIsAddObjectDialogOpen(false)}
        onSelect={handleObjectSelect}
      />
    </>
  );
};

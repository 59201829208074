import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";

import { EnvVarsOverview } from "components/organisms";

import type { DeploymentVersionDetailsRouteParams } from "./types";

export const DeploymentVersionEnvVars = () => {
  const { projectName, versionName, deploymentName } =
    useParams<DeploymentVersionDetailsRouteParams>();
  const match = useRouteMatch();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Advanced</BreadcrumbsItem>
      <EnvVarsOverview
        deploymentName={deploymentName}
        deploymentVersionName={versionName}
        warningMessage="The version is restarting and your changes will be available in ~30 seconds"
        isCreatable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_env_vars_create"]]
        }
        isEditable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_env_vars_update"]]
        }
        isDeletable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_env_vars_delete"]]
        }
      />
    </>
  );
};

import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  Redirect,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { routes } from "routes";

import { Route } from "components/utilities";

import { DeploymentVersionActiveInstances } from "./DeploymentVersionActiveInstances";
import { DeploymentVersionBatchRequestCreate } from "./DeploymentVersionBatchRequestCreate";
import { DeploymentVersionDetails } from "./DeploymentVersionDetails";
import { DeploymentVersionDuplicate } from "./DeploymentVersionDuplicate";
import { DeploymentVersionEdit } from "./DeploymentVersionEdit";
import { DeploymentVersionEnvVars } from "./DeploymentVersionEnvVars";
import { DeploymentVersionGeneral } from "./DeploymentVersionGeneral";
import { DeploymentVersionMetrics } from "./DeploymentVersionMetrics";
import { DeploymentVersionRequests } from "./DeploymentVersionRequests";
import { DeploymentVersionRevisions } from "./DeploymentVersionRevisions";
import { ControlledEnvVarsCopy } from "../../ControlledEnvVarsCopy";

import type { DeploymentVersionDetailsRouteParams } from "./types";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .deployments[":deploymentName"](":deploymentName")
  .versions[":versionName"](":versionName");

export const DeploymentVersionRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName, deploymentName, versionName } =
    useParams<DeploymentVersionDetailsRouteParams>();
  const { error } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );
  const history = useHistory();

  useEffect(() => {
    if (error) {
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .deployments.index()
      );
    }
  }, [error, history, organizationName, projectName]);

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .deployments[":deploymentName"](deploymentName)
    .versions[":versionName"](versionName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{versionName}</BreadcrumbsItem>

      <Switch>
        <Route
          exact
          path={basePath.edit.index()}
          component={DeploymentVersionEdit}
        />
        <Route
          exact
          path={basePath.duplicate.index()}
          component={DeploymentVersionDuplicate}
        />
        <DeploymentVersionDetails>
          <Switch>
            <Route
              exact
              path={basePath.general.index()}
              component={DeploymentVersionGeneral}
            />
            <Route
              exact
              path={basePath.requests.index()}
              component={DeploymentVersionRequests}
            />
            <Route exact path={basePath.requests.batch.create.index()}>
              <DeploymentVersionBatchRequestCreate />
            </Route>
            <Route
              exact
              path={basePath.metrics.index()}
              component={DeploymentVersionMetrics}
            />
            <Route
              exact
              path={basePath.environmentVariables.index()}
              component={DeploymentVersionEnvVars}
            />
            <Route
              exact
              path={basePath.environmentVariables.copy.index()}
              component={ControlledEnvVarsCopy}
            />
            <Route
              exact
              path={basePath.revisions.index()}
              component={DeploymentVersionRevisions}
            />
            <Route
              exact
              path={basePath.activeInstances.index()}
              component={DeploymentVersionActiveInstances}
            />
            <Redirect to={baseUrl.general.index()} />
          </Switch>
        </DeploymentVersionDetails>
        <Redirect to={baseUrl.general.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGoogleAnalytics } from "libs/hooks";

import { Loader } from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { useActivateUser } from "../../../../libs/data/customized/user/useActivateUser";

const SignUpActivation = () => {
  useGoogleAnalytics();

  const { token } = useParams<{ token: string }>();

  const { activateUser } = useActivateUser();

  useEffect(() => {
    token && activateUser(token);
  }, [token, activateUser]);

  return (
    <AuthenticationContainer title="Activating your account...">
      <Loader />
    </AuthenticationContainer>
  );
};

export default SignUpActivation;

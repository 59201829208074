import { Box } from "@mui/material";

import { Loader } from "components/atoms";

export const LogsLoader = () => {
  return (
    <Box style={{ height: 26 }}>
      <Loader size={12} />
    </Box>
  );
};

import { Field } from "formik";
import { get } from "lodash";
import { useMemo, useState } from "react";

import { ControlledTextInput } from "components/molecules/ControlledInputs";
import { parseErrorFromControl } from "components/molecules/FormikInputs/utils";

import type { ControlledTextFieldProps } from "components/molecules/ControlledInputs";
import type { FormikProps, FormikValues } from "formik";
import type { ValidationError } from "yup";

export type FormikTextInputProps = Omit<
  ControlledTextFieldProps,
  "error" | "name" | "onChange"
> & {
  control: FormikProps<FormikValues>;
  fastField?: boolean;
  name: string;
  showErrors?: boolean;
  validation?: any;
  onChange?: (change: string) => void;
};

export const FormikTextInput = ({
  control,
  // todo: as a basic rule all formik elements should implement fast field
  // fastField = true,
  name,
  showErrors = true,
  validation,
  onChange,
  ...props
}: FormikTextInputProps) => {
  const [internalError, setInternalError] =
    useState<undefined | string>(undefined);

  const validate = async (value: string) => {
    try {
      await validation.validate(value);

      setInternalError(undefined);
    } catch (e: unknown) {
      const error = e as ValidationError;
      setInternalError(error?.message);
    }
  };

  const error = useMemo(
    () =>
      (get(control.touched, name) && internalError) ??
      parseErrorFromControl(control, name),
    [control, internalError, name]
  );

  return (
    <Field name={name} validate={validation && validate}>
      {() => (
        <ControlledTextInput
          error={showErrors && error}
          onBlur={control.handleBlur}
          onChange={onChange || control.handleChange(name)}
          value={get(control.values, name) ?? ""}
          name={name}
          id={name}
          {...props}
        />
      )}
    </Field>
  );
};

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { Entity } from "./types";
import type {
  deploymentVersionsGet,
  deploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import type {
  pipelineVersionsGet,
  pipelineVersionsList,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";

export const placeholderVersionKey = "Loading...";
const placeholderVersion = {
  [placeholderVersionKey]: null,
};

interface EntitiesProps {
  useFetchFunction: typeof useDeploymentsList | typeof usePipelinesList;
  fetchVersions: typeof deploymentVersionsList | typeof pipelineVersionsList;
  fetchVersion?: typeof deploymentVersionsGet | typeof pipelineVersionsGet;
}

export default ({
  useFetchFunction,
  fetchVersions,
  fetchVersion,
}: EntitiesProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const { data: entityList } = useFetchFunction(projectName);
  const [entities, setEntities] = useState<Entity>(undefined);

  const fetchEntityVersions = useCallback(
    async (name: string) => {
      const currentVersions = entities?.[name].versions || {};
      const isPlaceholderVersion =
        currentVersions[placeholderVersionKey] === null;

      if (!entities || !isPlaceholderVersion) {
        return entities?.[name].versions;
      }

      const versionList = await fetchVersions(projectName, name);

      // fetch each version for additional meta data if needed
      // eg attached objects of pipeline versions
      const versionListWithMetaData =
        fetchVersion &&
        (await Promise.all(
          versionList.map((version) =>
            fetchVersion(projectName, name, version.version)
          )
        ));

      // we're iterating on either a DeploymentVersionDetail[] or a PipelineVersionDetail[]
      // and TS is not happy about it
      // @ts-ignore
      const versions = (versionListWithMetaData || versionList).reduce(
        // version can be DeploymentVersionList | PipelineVersionList | DeploymentVersionDetail | PipelineVersionDetail
        // @ts-ignore
        (acc, version) => ({
          ...acc,
          [version.version]: version,
        }),
        {}
      );

      setEntities({
        ...entities,
        [name]: {
          ...entities[name],
          versions,
        },
      });

      return versions;
    },
    [entities, fetchVersions, projectName, fetchVersion]
  );

  // create object from list of entities
  useEffect(() => {
    if (entities) return;

    // we're iterating on either a DeploymentList[] or a PipelineList[]
    // and TS is not happy about it
    // @ts-ignore
    const entityObject = entityList?.reduce(
      // currentEntity is either a PipelineList or DeploymentList
      // @ts-ignore
      (entityList, currentEntity) => ({
        ...entityList,
        [currentEntity?.name]: {
          ...currentEntity,
          versions: placeholderVersion,
        },
      }),
      {}
    );
    setEntities(entityObject as Entity);
  }, [entities, entityList]);

  return { entities, fetchEntityVersions, setEntities };
};

import { filter, find } from "lodash";

import {
  roleAssignmentsCreate,
  roleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import { createErrorNotification } from "libs/utilities/notifications";

import { TRAINING_DEPLOYMENT } from "./constants";

import type { AxiosError } from "axios";
import type { Dispatch } from "redux";

export const AssignBucketPermissionsToExperiment = async (
  projectName: string,
  bucketName: string,
  dispatch: Dispatch<any>
) => {
  const roles = await roleAssignmentsPerObjectList(projectName, {
    assignee: TRAINING_DEPLOYMENT,
    assignee_type: "deployment",
    resource: bucketName,
    resource_type: "bucket",
  });

  const bucketRoles = filter(
    roles,
    (role) => role.resource_type === "bucket" && role.resource === bucketName
  );
  const filesWriterRole = find(
    bucketRoles,
    (role) => role.role === "files-writer"
  );
  if (!filesWriterRole) {
    try {
      roleAssignmentsCreate(projectName, {
        role: "files-writer",
        assignee: TRAINING_DEPLOYMENT,
        assignee_type: "deployment",
        resource: bucketName,
        resource_type: "bucket",
      });
    } catch (e) {
      dispatch(createErrorNotification((e as AxiosError)?.message));
    }
  }
};

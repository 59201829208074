import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import { Box, Grid, IconButton, TablePagination, Tooltip } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { CONTINUOUS_INSTANCES_DELAY } from "libs/constants/constants";
import { useInstancesGet } from "libs/data/endpoints/instances/instances";
import { useDeviceDetect, useInterval } from "libs/hooks";
import { renderTimestamp } from "libs/utilities/date-util";
import { toUpperFirstCase } from "libs/utilities/util-functions";

import {
  RefreshButton,
  StatusIcon,
  TableLink,
  ToggleButtonGroup,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { InstanceDetailsDialog } from "./InstanceDetailsDialog";
import { STATUS_FILTERS } from "./constants";
import { getCursor } from "./utils";
import { getObjectLink } from "../RequestResultsDialog/utils";

import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  InstanceListPaginated,
  InstancesListParams,
  InstanceTypeList,
  ProjectInstanceList,
} from "libs/data/models";
import type { DateType } from "libs/utilities/date-util";

type ActiveInstancesTableProps = {
  query?: InstancesListParams;
  setQuery: (query: InstancesListParams) => void;
  data?: InstanceListPaginated;
  showDeployment?: boolean;
  refreshTable?: () => void;
};

export const ActiveInstancesTable = ({
  query,
  setQuery,
  data,
  showDeployment = false,
  refreshTable = () => {},
}: ActiveInstancesTableProps) => {
  const { isMobile } = useDeviceDetect();
  const { organizationName, projectName, deploymentName, versionName } =
    useParams<{
      organizationName: string;
      projectName: string;
      deploymentName?: string;
      versionName?: string;
    }>();

  const [page, setPage] = useState(0);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] =
    useState<ProjectInstanceList | InstanceTypeList | undefined>(undefined);

  const { data: instanceDetails, mutate } = useInstancesGet(
    projectName,
    deploymentName ??
      (selectedInstance as ProjectInstanceList)?.deployment ??
      "",
    versionName ?? (selectedInstance as ProjectInstanceList)?.version ?? "",
    selectedInstance?.id ?? "",
    {
      swr: { enabled: !!selectedInstance },
    }
  );

  const onViewDetails = (rowData: ProjectInstanceList | InstanceTypeList) => {
    setSelectedInstance(rowData);
    setIsDetailsDialogOpen(true);
  };

  useInterval(
    () => {
      if (isDetailsDialogOpen) mutate();
    },
    [mutate, isDetailsDialogOpen],
    CONTINUOUS_INSTANCES_DELAY
  );

  const columns = [
    {
      title: "Status",
      field: "status",
      nowrap: true,
      width: "10%",
      render: (rowData: { status: string }) => (
        <StatusIcon
          label={rowData ? toUpperFirstCase(rowData.status) : ""}
          status={rowData?.status}
          animation={rowData?.status !== "running"}
        />
      ),
    },
    {
      title: "ID",
      field: "id",
      nowrap: true,
      width: "25%",
    },
    showDeployment && {
      title: "Deployment",
      field: "deployment",
      nowrap: true,
      width: "15%",
      render: (rowData: ProjectInstanceList) => {
        if (!rowData.deployment) return;
        const deploymentLink = getObjectLink(
          "deployment",
          organizationName,
          projectName,
          rowData.deployment
        );

        return (
          <Tooltip title={rowData.deployment}>
            <TableLink
              to={deploymentLink}
              variant="bold"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                display: "block",
              }}
            >
              {rowData.deployment}
            </TableLink>
          </Tooltip>
        );
      },
    },
    showDeployment && {
      title: "Deployment version",
      field: "version",
      nowrap: true,
      width: "15%",
      render: (rowData: ProjectInstanceList) => {
        if (!rowData.deployment || !rowData.version) return;
        const deploymentVersionLink = getObjectLink(
          "deployment",
          organizationName,
          projectName,
          rowData.deployment,
          rowData.version
        );

        return (
          <Tooltip title={rowData.version}>
            <TableLink
              to={deploymentVersionLink}
              variant="bold"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                display: "block",
              }}
            >
              {rowData.version}
            </TableLink>
          </Tooltip>
        );
      },
    },
    !isMobile && {
      title: "Created",
      field: "time_created",
      nowrap: true,
      width: "15%",
      render: (rowData: { time_created: string }) =>
        renderTimestamp(rowData, "time_created"),
    },
    !isMobile && {
      title: "Updated",
      field: "time_updated",
      nowrap: true,
      width: "15%",
      render: (rowData: Record<string, DateType>) =>
        renderTimestamp(rowData, "time_updated"),
    },
    {
      sorting: false,
      disableClick: true,
      nowrap: true,
      align: "right",
      render: (rowData: ProjectInstanceList | InstanceTypeList) => (
        <Tooltip title="View details">
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              color="secondary"
              onClick={() => onViewDetails(rowData)}
            >
              <GetResultsIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ].filter(Boolean);

  const handlePageChange = (newPage: number) => {
    const { cursor } = getCursor(
      (newPage > page ? data?.next : data?.previous) ?? ""
    );

    setQuery({ ...query, cursor });
    setPage(newPage);
  };

  return (
    <Grid container xs={12} display="flex" flexDirection="column" height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          items={STATUS_FILTERS}
          onChange={(value) => {
            const newValue =
              value === STATUS_FILTERS[0].title ? undefined : value;
            setQuery({ ...query, status: newValue, cursor: undefined });
          }}
          value={query?.status ?? STATUS_FILTERS[0].title}
        />
        <RefreshButton tooltip="Refresh table" onClick={refreshTable} />
      </Box>
      <BaseTable
        hasPagination={false}
        hasSearchBar={false}
        onRowClick={(_e, row) => onViewDetails(row)}
        columns={columns as unknown as BaseColumn[]}
        data={data?.results}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        nextIconButtonProps={{
          disabled: !data?.next,
        }}
        count={-1}
        labelDisplayedRows={({ from, to }) => {
          const rows = data?.results.length || 0;
          const rowCount = rows < (query?.limit ?? 0) ? from + rows - 1 : to;

          return `${from}-${rowCount}`;
        }}
        rowsPerPage={query?.limit || 10}
        page={page}
        onRowsPerPageChange={(event) => {
          setQuery({
            ...query,
            limit: Number(event.target.value),
            cursor: undefined,
          });
          setPage(0);
        }}
        onPageChange={(_event, page: number) => handlePageChange(page)}
      />
      <InstanceDetailsDialog
        isOpen={isDetailsDialogOpen}
        onClose={() => setIsDetailsDialogOpen(false)}
        details={instanceDetails}
        projectName={projectName}
        organizationName={organizationName}
      />
    </Grid>
  );
};

import { Box, Typography } from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";

import { SearchBarCustomStyle } from "components/atoms";

import { NodeDeploymentPipelineReadonly } from "../SharedDeploymentPipelineComponents";

import type { PipelineList } from "libs/data/models";

export type SidebarLeftPipelinesTabProps = {
  pipelines: PipelineList[] | undefined;
};

export const SidebarLeftPipelinesTab = ({
  pipelines,
}: SidebarLeftPipelinesTabProps) => {
  const [query, setQuery] = useState("");

  return (
    <>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          textAlign: "center",
          marginBottom: spacing[16],
        }}
      >
        Drag and drop any of your pipelines onto the canvas to use as a
        subpipeline.
      </Typography>

      <SearchBarCustomStyle
        fullWidth
        value={query}
        onChange={(value) => setQuery(value)}
      />

      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        mt={spacing[20]}
      >
        {pipelines
          ?.filter((pipeline) => new RegExp(query).test(pipeline.name))
          ?.map((pipeline) => (
            <NodeDeploymentPipelineReadonly
              key={pipeline.id}
              object={pipeline}
              objectType="pipeline"
              style={{ marginBottom: spacing[16], zIndex: "100" }}
            />
          ))}
      </Box>
    </>
  );
};

import { PrimaryButton } from "components/atoms/Button/PrimaryButton";

import { Dialog } from "./Dialog";

import type { DialogProps } from "./Dialog";
import type { IconProps } from "components/atoms/Icon/MaterialIcon";
import type { CSSProperties, ReactElement, ReactNode } from "react";

export interface ActionDialogProps extends DialogProps {
  actionButtonIcon?: ReactElement<IconProps>;
  dialogBodyStyles?: CSSProperties;
  actionButtonText?: ReactNode;
  Header?: ReactNode;
  dialogTitle?: string;
  onClose: () => void;
  onAction: () => void;
  disableActionButton?: boolean;
}

export const ActionDialog = ({
  actionButtonIcon,
  open,
  onClose,
  onAction,
  actionButtonText,
  dialogTitle = "Warning",
  disableActionButton = false,
  children,
  ...props
}: ActionDialogProps) => (
  <Dialog
    Actions={
      <>
        <PrimaryButton
          disabled={disableActionButton}
          onClick={onAction}
          startIcon={actionButtonIcon}
        >
          {actionButtonText}
        </PrimaryButton>
      </>
    }
    open={open}
    onClose={onClose}
    title={dialogTitle}
    {...props}
  >
    {children}
  </Dialog>
);

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CustomToggleProps {
  isCustom: boolean;
  setCustom: (value: boolean) => void;
  label?: string;
}

export const CustomToggle = ({
  isCustom,
  setCustom,
  label,
}: CustomToggleProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="body2"
        component="span"
        marginBottom={theme.spacing(1)}
        marginTop={`-${theme.spacing(1)}`}
      >
        {label}
      </Typography>
      <ToggleButtonGroup
        onChange={() => setCustom(!isCustom)}
        value={isCustom ? "custom" : "all"}
        exclusive
        size="small"
      >
        <ToggleButton
          disableFocusRipple={true}
          value="all"
          sx={{
            ...buttonStyle,
            color: !isCustom
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          }}
        >
          Default
        </ToggleButton>
        <ToggleButton
          disableFocusRipple={true}
          value="custom"
          sx={{
            ...buttonStyle,
            color: isCustom
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          }}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const buttonStyle = { height: "auto", fontSize: "0.9em", padding: "2px 11px" };

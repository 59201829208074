import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { Diagram } from "components/templates/Diagram";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { routes } from "routes";

import { Header } from "components/organisms";

import type { PipelineVersionRouteParams } from "../types";

export const PipelineVersionEditModePage = () => {
  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const { error: pipelineVersionDetailError } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  useEffect(() => {
    if (pipelineVersionDetailError) {
      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines[":pipelineName"](pipelineName)
          .index()
      );
    }
  }, [
    history,
    organizationName,
    pipelineName,
    pipelineVersionDetailError,
    projectName,
  ]);

  const onExit = () =>
    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName)
        .versions[":versionName"](versionName)
        .general.index()
    );

  return (
    <>
      <Header toggleSidebar={() => null} />

      <Diagram
        organizationName={organizationName}
        projectName={projectName}
        pipelineName={pipelineName}
        versionName={versionName}
        onExit={onExit}
        as="main"
        style={{
          flex: 1,
          height: `calc(100vh - ${spacing[44]})`,
          marginTop: spacing[44],
          fontSize: "1.1em",
        }}
      />
    </>
  );
};

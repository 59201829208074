import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useGoogleAnalytics } from "libs/hooks";

import { EnvVarsOverview } from "components/organisms";

import type { DeploymentDetailsRouteParams } from "./types";

export const DeploymentEnvVars = () => {
  useGoogleAnalytics();

  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();
  const match = useRouteMatch();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Environment variables</BreadcrumbsItem>
      <EnvVarsOverview
        deploymentName={deploymentName}
        warningMessage="The deployment versions are restarting and will therefore be unavailable for ~30
          seconds."
        isCreatable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_create"]]
        }
        isEditable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_update"]]
        }
        isDeletable={
          currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_delete"]]
        }
      />
    </>
  );
};

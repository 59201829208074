import { Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { routes } from "routes";

import { TableLink } from "components/atoms";
import { BaseTable } from "components/molecules";

import type { PipelineVersionRouteParams } from "../types";

export const ObjectsOverview = () => {
  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  const objects = (pipelineVersion?.objects || []).map((pipelineObject) => ({
    ...pipelineObject,
    version:
      pipelineObject.reference_type !== "operator" &&
      (pipelineObject.version || "default"),
  }));

  const getObjectVersionPath = (
    referenceName: string,
    versionName: string,
    type: "deployment" | "pipeline"
  ) => {
    const basePath =
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName);
    const objectPath =
      type === "deployment"
        ? basePath.deployments[":deploymentName"](referenceName)
        : basePath.pipelines[":pipelineName"](referenceName);

    // The version name will be "default" due to the mapping of the pipeline objects above
    // If the version name is empty (mapped to "default") it means that the user wants the pipeline to use the default
    // version rather than a specific version.
    return versionName === "default"
      ? objectPath.versions.index()
      : objectPath.versions[":versionName"](versionName).index();
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: "asc",
      width: "25%",
      nowrap: true,
      render: (rowData: any) =>
        rowData.reference_type !== "operator" ? (
          <TableLink
            to={getObjectVersionPath(
              rowData.reference_name,
              rowData.version,
              rowData.reference_type
            )}
          >
            {rowData.name}
          </TableLink>
        ) : (
          rowData.name
        ),
    },
    {
      title: "Reference",
      field: "reference_name",
      width: "25%",
      nowrap: true,
    },
    {
      title: "Type",
      field: "reference_type",
      width: "25%",
      nowrap: true,
    },
    {
      title: "Version",
      field: "version",
      width: "25%",
      nowrap: true,
    },
  ];

  return (
    <Box paddingX={1}>
      <BaseTable
        data={objects}
        columns={columns}
        defaultPageSize={5}
        onRowClick={(_: any, rowData: any) => {
          if (rowData.reference_type !== "operator") {
            const { reference_name, reference_type, version } = rowData;
            history.push(
              getObjectVersionPath(reference_name, version, reference_type)
            );
          }
        }}
        rowsPerPageOptions={[]}
      />
    </Box>
  );
};

import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { IlluTrainingDeployment } from "assets/images/IlluTrainingDeployment";
import { BaseUrlContext } from "libs/contexts";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { explanations } from "libs/utilities/explanations";
import { createErrorNotification } from "libs/utilities/notifications";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { routes } from "routes";

import { Dialog, DialogWarningHeader, PrimaryButton } from "components/atoms";
import { RequestsPageConfiguration } from "components/subpages";
import { Route } from "components/utilities";

import { ControlledEnvVarsCopy } from "./ControlledEnvVarsCopy";
import { DeploymentAuditEvents } from "./DeploymentAuditEvents";
import { DeploymentConfiguration } from "./DeploymentConfiguration";
import { DeploymentDetails } from "./DeploymentDetails";
import DeploymentDuplicate from "./DeploymentDuplicate";
import { DeploymentEnvVars } from "./DeploymentEnvVars";
import { DeploymentGeneral } from "./DeploymentGeneral";
import DeploymentUpdate from "./DeploymentUpdate";
import { DeploymentUsing } from "./DeploymentUsing";
import { DeploymentVersionsPage } from "./versions";

import type { DeploymentDetailsRouteParams } from "./types";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .deployments[":deploymentName"](":deploymentName");

export const DeploymentRoutesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { organizationName, projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();
  const [
    isTrainingDeploymentInfoDialogOpen,
    setIsTrainingDeploymentInfoDialogOpen,
  ] = useState(false);

  const { data: deployment, error } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName),
    [deploymentName, organizationName, projectName]
  );
  const deploymentsOverviewUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments.index(),
    [organizationName, projectName]
  );
  const trainingUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .training.experiments.index(),
    [organizationName, projectName]
  );

  useEffect(() => {
    if (!isTrainingDeploymentInfoDialogOpen) {
      setIsTrainingDeploymentInfoDialogOpen(
        deployment?.name === TRAINING_DEPLOYMENT
      );
    }
  }, [deployment, isTrainingDeploymentInfoDialogOpen]);

  useEffect(() => {
    if (error) {
      dispatch(createErrorNotification(error.message));
      history.push(deploymentsOverviewUrl);
    }
  }, [dispatch, error, history, organizationName, deploymentsOverviewUrl]);

  return (
    <>
      <Dialog
        open={isTrainingDeploymentInfoDialogOpen}
        onClose={() => {
          setIsTrainingDeploymentInfoDialogOpen(false);
          history.push(deploymentsOverviewUrl);
        }}
        Header={
          <DialogWarningHeader title="This deployment is autogenerated and immutable" />
        }
      >
        {" "}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={3}
        >
          <IlluTrainingDeployment />
          {explanations.training.templateDeploymentDialog(trainingUrl)}
          <PrimaryButton
            onClick={() => {
              history.push(trainingUrl);
            }}
          >
            Take me to training
          </PrimaryButton>
        </Box>
      </Dialog>
      <BaseUrlContext.Provider value={match.url}>
        <BreadcrumbsItem to={match.url}>{deploymentName}</BreadcrumbsItem>
        <Switch>
          <Route
            path={basePath.versions.index()}
            component={DeploymentVersionsPage}
          />
          <Route
            exact
            path={basePath.edit.index()}
            component={DeploymentUpdate}
          />
          <Route
            exact
            path={basePath.duplicate.index()}
            component={DeploymentDuplicate}
          />
          <Route path={basePath.index()}>
            <DeploymentDetails>
              <Switch>
                <Route
                  exact
                  path={basePath.general.index()}
                  component={DeploymentGeneral}
                />
                <Route
                  exact
                  path={basePath.configuration.index()}
                  component={DeploymentConfiguration}
                />
                <Route
                  exact
                  path={basePath.auditEvents.index()}
                  component={DeploymentAuditEvents}
                />
                <Route
                  exact
                  path={basePath.environmentVariables.index()}
                  component={DeploymentEnvVars}
                />
                <Route
                  exact
                  path={basePath.useDeployment.index()}
                  component={DeploymentUsing}
                />
                <Route
                  exact
                  path={basePath.environmentVariables.copy.index()}
                  component={ControlledEnvVarsCopy}
                />
                {env.get(ENV_NAMES.REQUESTS_PAGE_ENABLED) && (
                  <Route
                    exact
                    path={basePath.useDeployment.configure.index()}
                    component={RequestsPageConfiguration}
                  />
                )}
                <Redirect to={baseUrl.general.index()} />
              </Switch>
            </DeploymentDetails>
          </Route>
          <Redirect to={baseUrl.general.index()} />
        </Switch>
      </BaseUrlContext.Provider>
    </>
  );
};

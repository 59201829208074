import JSZip from "jszip";

import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { isArrayHasData, isArrayHasNoData } from "libs/utilities/utils";

export const downloadURL = (url: string, name: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Convenience method for downloading larger files. Creates a url for the file download and clicks it to trigger a browser
 * download. Streams files directly to disk, but if the request fails this is not reflected in the redux state.
 * @param url Url for file download. *Must* contain an authtoken query parameter.
 */
export const downloadFileStreaming = (url: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = `${env.get(ENV_NAMES.HOST)}${url}`;
  a.click();
  if (a?.parentNode) a.parentNode.removeChild(a);
};

export const downloadFile = (content: Blob | string, fileName = "file.txt") => {
  const downloadUrl = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadZip = (
  filesArray: { name: string; content: string }[],
  zipName = "deployment_package"
) => {
  if (isArrayHasData(filesArray)) {
    const zip = JSZip();
    const zippedFolder = zip.folder(zipName); // Create folder

    // Add files to folder
    for (const file of filesArray)
      if (zippedFolder) zippedFolder.file(file.name, file.content);

    // Generate and download zip file
    zip.generateAsync({ type: "blob" }).then((content) => {
      downloadFile(content, `${zipName}.zip`);
    });
  }
};

// CSV Utils
const convertArrayOfObjectsToCSV = (
  arrayOfObjects: Record<string, unknown>[]
) => {
  if (isArrayHasNoData(arrayOfObjects)) return null;

  const csvFirstLine = Object.keys(arrayOfObjects[0]).join(",");
  const csvOtherLines = arrayOfObjects.map((item) =>
    Object.values(item).join(",")
  );

  return [csvFirstLine, ...csvOtherLines].join("\n");
};

export const downloadCSV = ({
  data,
  fileName = "export",
}: {
  data: Record<string, unknown>[];
  fileName: string;
}) => {
  let csv = convertArrayOfObjectsToCSV(data);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = "data:text/csv;charset=utf-8," + csv;
  }
  const csvData = encodeURI(csv);

  const link = document.createElement("a");
  link.setAttribute("href", csvData);
  link.setAttribute("download", `${fileName}.csv`);
  link.click();
};

import Pipelines from "@mui/icons-material/AccountTreeRounded";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import GroupIcon from "@mui/icons-material/Group";
import Dashboard from "@mui/icons-material/HomeRounded";
import ImportExportIcon from "@mui/icons-material/ImportExportRounded";
import MonitoringIcon from "@mui/icons-material/InsertChartRounded";
import IntegrationInstructionsRounded from "@mui/icons-material/IntegrationInstructionsRounded";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import UsersPermissions from "@mui/icons-material/PeopleAltRounded";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import Deployments from "@mui/icons-material/WidgetsRounded";

export const organizationsRoutes = (baseUrl: string, isAdmin: boolean) => [
  // organization routes
  [
    {
      label: "Dashboard",
      path: `${baseUrl}/dashboard`,
      icon: Dashboard,
      exact: false,
    },
  ],
  [
    {
      label: "Subscription & Usage",
      path: `${baseUrl}/subscription`,
      icon: CreditCardIcon,
      hidden: !isAdmin,
    },
    {
      label: "Team",
      path: `${baseUrl}/team`,
      icon: GroupIcon,
      hidden: !isAdmin,
    },
    {
      label: "Projects",
      path: `${baseUrl}/overview`,
      icon: DashboardIcon,
      hidden: !isAdmin,
    },
  ],
  // project routes
  [
    {
      label: "Project dashboard",
      url: `${baseUrl}`,
      path: `/dashboard`,
      icon: Dashboard,
      exact: true,
    },
    {
      label: "Deployments",
      url: `${baseUrl}`,
      path: `/deployments`,
      icon: Deployments,
    },
    {
      label: "Pipelines",
      url: `${baseUrl}`,
      path: `/pipelines`,
      icon: Pipelines,
    },
    {
      label: "Environments",
      url: `${baseUrl}`,
      path: "/environments",
      icon: IntegrationInstructionsRounded,
    },
    {
      label: "Storage",
      url: `${baseUrl}`,
      path: `/storage/buckets`,
      icon: FolderOpenIcon,
    },
    {
      label: "Training",
      url: `${baseUrl}`,
      path: `/training`,
      icon: ModelTrainingIcon,
    },
    {
      label: "Request schedules",
      url: `${baseUrl}`,
      path: `/request-schedules`,
      icon: ScheduleIcon,
    },
    {
      label: "Logging",
      url: `${baseUrl}`,
      path: `/logs`,
      icon: LogsIcon,
    },
    {
      label: "Monitoring",
      url: `${baseUrl}`,
      path: `/monitoring`,
      icon: MonitoringIcon,
    },
    {
      label: "Project Admin",
      icon: UsersPermissions,
      subRoutes: [
        {
          label: "Imports & Exports",
          url: `${baseUrl}`,
          path: `/imports-exports`,
          icon: ImportExportIcon,
        },
        {
          label: "Permissions",
          url: `${baseUrl}`,
          path: `/settings`,
          icon: UsersPermissions,
        },
        {
          label: "Project settings",
          url: `${baseUrl}`,
          path: `/project-settings`,
          icon: UsersPermissions,
        },
      ],
    },
  ].filter(Boolean),
];

export const OrganizationRoutes = typeof organizationsRoutes;

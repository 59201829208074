import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import Permissions from "./Permissions";
import ApiTokensOverview from "./api-tokens/ApiTokensOverview";
import ApiTokenCreate from "./api-tokens/create/ApiTokenCreate";
import RolesOverview from "./roles/RolesOverview";
import ProjectUsersOverview from "./users/ProjectUsersOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").settings;

const PermissionsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).settings;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Permissions</BreadcrumbsItem>
      <Switch>
        <Route path={basePath.index()}>
          <Permissions>
            <Switch>
              <Route
                path={basePath.users.index()}
                component={ProjectUsersOverview}
                exact
              />
              <Route path={basePath.roles.index()} component={RolesOverview} />
              <Route
                path={basePath.apiTokens.index()}
                component={ApiTokensOverview}
                exact
              />
              <Route
                path={basePath.apiTokens.create.index()}
                component={ApiTokenCreate}
                exact
              />

              <Redirect to={baseUrl.users.index()} />
            </Switch>
          </Permissions>
        </Route>
      </Switch>
    </BaseUrlContext.Provider>
  );
};

export default PermissionsRoutesContainer;

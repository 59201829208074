import { Box } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { CardHeaderTitle, LabelChip } from "components/atoms";

import type { Variant } from "@mui/material/styles/createTypography";

interface LabeledTitleProps {
  title: string;
  variant?: Variant;
  labels?: { key: string; value: string | null }[];
}

export const LabeledTitle = ({ title, variant, labels }: LabeledTitleProps) => {
  return (
    <>
      <CardHeaderTitle
        variant={variant}
        style={{ marginBottom: spacing[8], marginRight: spacing[32] }}
      >
        {title}
      </CardHeaderTitle>
      {labels?.map(({ key, value }) => (
        <Box key={key} marginRight={spacing[8]} paddingBottom={spacing[8]}>
          <LabelChip key={key} label={key} value={value} />
        </Box>
      ))}
    </>
  );
};

/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type PipelineVersionUpdateRequestRetentionMode =
  typeof PipelineVersionUpdateRequestRetentionMode[keyof typeof PipelineVersionUpdateRequestRetentionMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PipelineVersionUpdateRequestRetentionMode = {
  none: "none",
  metadata: "metadata",
  full: "full",
} as const;

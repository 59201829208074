import CancelIcon from "@mui/icons-material/Cancel";
import Trash from "@mui/icons-material/DeleteRounded";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { FIELD_NAME } from "libs/constants/fields";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useRunCancel } from "libs/data/customized/training";
import {
  deploymentVersionRequestsDelete,
  useDeploymentVersionRequestsGet,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useInterval } from "libs/hooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import {
  ActionDialog,
  ButtonGroup,
  DeleteDialog,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { shouldFetchMetadataOnly } from "components/organisms";

import { TRAINING_DEPLOYMENT } from "../../../constants";
import { RunResultsDialog } from "../RunResultsDialog";

import type { ReactNode } from "react";

type RunDetailsProps = {
  children: ReactNode;
};
export const RunDetails = ({ children }: RunDetailsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { organizationName, projectName, experimentName, runId } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
      runId: string;
    }>();
  const { data: runDetails, mutate } = useDeploymentVersionRequestsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    runId
  );
  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    TRAINING_DEPLOYMENT,
    "deployment"
  );
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .training.experiments[":experimentName"](experimentName)
    .runs[":runId"](runId);

  const listUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .training.experiments[":experimentName"](experimentName)
    .index();

  const isRunning = useMemo(
    () =>
      runDetails?.status === "pending" ||
      runDetails?.status === "processing" ||
      runDetails?.status === "cancelled_pending",
    [runDetails?.status]
  );

  const runName = useMemo(
    () => runDetails?.request_data?.[FIELD_NAME] ?? "",
    [runDetails]
  );

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.metrics.index(),
      label: "Metrics",
    },
  ];

  const onCancelRun = useRunCancel(projectName, experimentName);

  const handleCancel = async (id: string, name: string) => {
    setLoading(true);
    await onCancelRun(id, name);
    setIsCancelDialogOpen(false);
    setLoading(false);
  };

  const handleRunDelete = () => {
    runDetails?.id &&
      deploymentVersionRequestsDelete(
        projectName,
        TRAINING_DEPLOYMENT,
        experimentName,
        runDetails?.id
      )
        .then(() => {
          dispatch(createSuccessNotification("Selected run deleted"));
          history.push(listUrl);
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          setIsDeleteDialogOpen(false);
        });
  };

  useInterval(
    () => {
      if (isRunning) mutate();
    },
    [isRunning, mutate],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  const hasResults =
    runDetails?.status && ["completed", "failed"].includes(runDetails?.status);

  return (
    <>
      <DetailsContainer
        title={runName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              link={baseUrl.duplicate.index()}
              startIcon={<Duplicate />}
              disabled={
                !currentPermissions[
                  DEPLOYMENT_PERMISSIONS["version_request_create"]
                ]
              }
            >
              Duplicate
            </TextButton>
            {isRunning && (
              <TextButton
                color="secondary"
                disabled={runDetails?.status === "cancelled_pending"}
                onClick={() => setIsCancelDialogOpen(true)}
                startIcon={<CancelIcon />}
              >
                Cancel
              </TextButton>
            )}
            <TextButton
              onClick={() => setIsDeleteDialogOpen(true)}
              startIcon={<Trash />}
              disabled={isRunning}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<EqualizerIcon />}
              disabled={!hasResults}
              onClick={() => setIsResultsDialogOpen(true)}
              style={{ marginLeft: "auto" }}
              tooltip={
                !hasResults
                  ? "Your run hasn’t finished yet. When it’s finished, you can check the results with this button"
                  : ""
              }
            >
              View results
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs}>{children}</PageTabs>
      </DetailsContainer>

      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleRunDelete}
      >
        Are you sure you want to delete the run <b>{runName}</b> and all of its
        content?
      </DeleteDialog>
      <RunResultsDialog
        isOpen={isResultsDialogOpen}
        id={runDetails?.id as string}
        onClose={() => setIsResultsDialogOpen(false)}
        organizationName={organizationName}
        projectName={projectName}
        experimentName={experimentName}
        shouldFetchMetadaDataOnly={shouldFetchMetadataOnly(runDetails)}
      />

      <ActionDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onAction={() => runDetails?.id && handleCancel(runDetails?.id, runName)}
        actionButtonText="Cancel run"
        disableActionButton={loading}
      >
        <Box>
          Are you sure you want to cancel run <b>{runName}</b>?
        </Box>
      </ActionDialog>
    </>
  );
};

import { useCallback } from "react";
import { useReactFlow, getIncomers, addEdge, getOutgoers } from "reactflow";

import {
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "./constants";
import { getId } from "./getId";
import { NodeTypes } from "./types";

import type { NodeDataType, EdgeDataType } from "./types";
import type { Edge, Node } from "reactflow";

export const useDeleteNode = () => {
  const { setNodes, getEdges, setEdges, getNode } =
    useReactFlow<NodeDataType, EdgeDataType>();

  const deleteRelatedEdges = useCallback(
    (id: string) => {
      setEdges((edges) =>
        edges.reduce((edges, edge) => {
          if ([edge.target, edge.source].includes(id)) {
            return edges;
          }

          if (!edge.data) {
            return edges;
          }

          edge.data = {
            ...edge.data,
            sources: edge.data.sources.filter(
              (source) =>
                source.source_name !== getNode(id)?.data.pipelineObject.name
            ),
          };

          return [...edges, edge];
        }, [] as Edge<EdgeDataType>[])
      );
    },
    [getNode, setEdges]
  );

  const deleteRelatedNodes = useCallback(
    (id: string) => {
      setNodes((nodes) => {
        const deletedNode = nodes.find((node) => node.id === id);

        if (!deletedNode) {
          return nodes;
        }

        const edges = getEdges();
        const incomers = getIncomers(deletedNode, nodes, edges);
        const outgoers = getOutgoers(deletedNode, nodes, edges);

        const incomerDiamondIds = incomers
          .filter((node) => node.type === "diamond")
          .map((node) => node.id);

        const isOutgoerNodeOneToManyOperator = outgoers.find(
          (outgoerNode) =>
            outgoerNode.data.pipelineObject.reference_type ===
              OBJECT_REFERENCE_TYPE_OPERATOR &&
            outgoerNode.data.pipelineObject.reference_name ===
              OPERATOR_ONE_TO_MANY
        );

        const isIncomerNodeManyToOneOperator = incomers.find(
          (incomerNode) =>
            incomerNode.data.pipelineObject.reference_type ===
              OBJECT_REFERENCE_TYPE_OPERATOR &&
            incomerNode.data.pipelineObject.reference_name ===
              OPERATOR_MANY_TO_ONE
        );

        let newNodes = nodes.filter(
          (node) => node.id !== id && !incomerDiamondIds.includes(node.id)
        );

        if (isOutgoerNodeOneToManyOperator) {
          newNodes = nodes.filter(
            (node) =>
              node.id !== id && node.id !== isOutgoerNodeOneToManyOperator.id
          );
        }

        if (isIncomerNodeManyToOneOperator) {
          newNodes = nodes.filter(
            (node) =>
              node.id !== id && node.id !== isIncomerNodeManyToOneOperator.id
          );
        }

        const diamonds = newNodes.filter(
          (node) => node.type === NodeTypes.diamond
        );

        diamonds.forEach((diamond) => {
          const { organizationName, projectName, pipelineName, versionName } =
            diamond.data;

          const incomerEdges = (edges || []).filter(
            (edge) => edge.target === diamond.id
          );
          const outgoingEdge = (edges || []).find(
            (edge) => edge.source === diamond.id
          );

          if (incomerEdges.length === 1 && outgoingEdge) {
            const newEdge: Edge<EdgeDataType> = {
              id: getId(),
              source: incomerEdges[0].source,
              target: outgoingEdge?.target,
              type: "custom",
              data: {
                sources: outgoingEdge?.data?.sources || [],
                organizationName,
                projectName,
                pipelineName,
                versionName,
              },
            };

            setEdges((edges) => addEdge(newEdge, edges));

            newNodes = nodes.filter((node) => node.id !== diamond.id);
          }
        });

        return newNodes;
      });
    },
    [getEdges, setEdges, setNodes]
  );

  const deleteUnnecessaryDiamondNodes = useCallback(() => {
    setNodes((nodes) => {
      const removedDiamondNodes: Node<NodeDataType>[] = [];

      const newNodes = nodes.filter((node) => {
        if (node.type !== "diamond") return true;

        const incomers = getIncomers(node, nodes, getEdges());
        if (incomers.length === 1) {
          removedDiamondNodes.push(node);

          return false;
        }

        return true;
      });

      setEdges((edges) => {
        const fixedEdges: Edge<EdgeDataType>[] = [];

        const newEdges = edges.filter((edge) => {
          const diamondNode = removedDiamondNodes.find((node) =>
            [edge.target, edge.source].includes(node.id)
          );

          if (!diamondNode?.id) return true;

          if (diamondNode?.id === edge.source) return false;

          const diamondNodeOutgoerEdge = edges.find(
            (edge) => edge.source === diamondNode.id
          );

          if (diamondNode.id === edge.target && diamondNodeOutgoerEdge) {
            fixedEdges.push({
              ...edge,
              target: diamondNodeOutgoerEdge.target,
            });

            return false;
          }

          return true;
        });

        return [...newEdges, ...fixedEdges];
      });

      return newNodes;
    });
  }, [getEdges, setEdges, setNodes]);

  return useCallback(
    (id: string) => {
      deleteRelatedEdges(id);
      deleteRelatedNodes(id);

      setTimeout(() => {
        deleteUnnecessaryDiamondNodes();
      }, 50);
    },
    [deleteRelatedEdges, deleteRelatedNodes, deleteUnnecessaryDiamondNodes]
  );
};

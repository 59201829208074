import { FormProvider, useForm } from "react-hook-form";

import { Dialog } from "components/atoms/Dialog";

import { PrimaryButton } from "components/atoms";

import type { DialogProps } from "./Dialog";
import type { IconProps } from "../Icon/MaterialIcon";
import type { ReactElement, ReactNode } from "react";
import type { SubmitHandler } from "react-hook-form";

export interface FormDialogProps extends DialogProps {
  onSubmit: SubmitHandler<any>;
  disableSubmitButton?: boolean;
  actionButtonText?: string;
  actionButtonIcon?: ReactElement<IconProps>;
  onClose: () => void;
  title: string;
  description?: string | ReactNode;
}

export const FormDialog = ({
  onClose,
  onSubmit,
  open,
  title,
  disableSubmitButton = false,
  actionButtonText = "Save",
  actionButtonIcon,
  children,
}: FormDialogProps) => {
  const formMethods = useForm();

  return (
    <Dialog
      Actions={
        <PrimaryButton
          disabled={disableSubmitButton}
          onClick={formMethods.handleSubmit(onSubmit)}
          startIcon={actionButtonIcon}
        >
          {actionButtonText}
        </PrimaryButton>
      }
      onClose={onClose}
      open={open}
      title={title}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </Dialog>
  );
};

import { convertSeconds } from "libs/utilities/util-functions";

import type { OrganizationFeature } from "libs/data/models";

export const resources = [
  {
    label: "GPU",
    resource: "resource_gpu",
    limit: "max_gpu",
  },
  {
    label: "Users",
    resource: "users",
    limit: "max_users",
  },
  {
    label: "Projects",
    resource: "projects",
    limit: "max_projects",
  },
  {
    label: "Deployment versions",
    resource: "deployment_versions",
    limit: "max_deployment_versions",
  },
  {
    label: "Storage buckets",
    resource: "buckets",
    limit: "max_buckets",
  },
  {
    label: "Custom environments",
    resource: "environments",
    limit: "max_environments",
  },
];

export const limits = (features: OrganizationFeature, subscription: string) => [
  { title: "Subscription", value: subscription },
  { title: "Max projects", value: actualValue(features.max_projects) },
  { title: "Max users", value: actualValue(features.max_users) },
  // credits can be 0 but mean infinite :)
  {
    title: "Monthly credit limit",
    value: actualValue(features.max_credits || null),
  },
  {
    title: "Max deployment versions",
    value: actualValue(features.max_deployment_versions),
  },
  {
    title: "Max deployment memory",
    value: `${features.max_deployment_memory} MB`,
  },
  {
    title: "Max deployment concurrency",
    value:
      actualValue(features.max_deployment_concurrency) +
      " (max amount of deployments which can run at the same time)",
  },

  {
    title: "Resource GPU",
    value: features.resource_gpu
      ? "Enabled"
      : "Disabled - if you like to enable GPUs please contact our sales team",
  },
  {
    title: "Max retention logs",
    value: convertSeconds(features.max_retention_logs),
  },
  {
    title: "Max retention requests",
    value: convertSeconds(features.max_retention_requests),
  },

  {
    title: "Max batch deployments timeout",
    value: convertSeconds(features.max_batch_deployment_timeout),
  },
  {
    title: "Max batch pipelines timeout",
    value: convertSeconds(features.max_batch_pipeline_timeout),
  },
];

const actualValue = (value: null | number) => {
  if (value === null) return "∞";

  return value;
};

import { Grid, Typography } from "@mui/material";

import { DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE } from "libs/constants/constants";
import {
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_GPU_INSTANCE_ENABLED,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";

import { FormSwitch, InfoAlert } from "components/atoms";

import { InstanceTypeField } from "./InstanceTypeField";

import type { InstanceTypeFieldProps } from "./InstanceTypeField";
import type { DeploymentVersionDetail } from "libs/data/models";

export interface DeploymentVersionInstanceTypeProps
  extends Omit<InstanceTypeFieldProps, "defaultValue"> {
  defaultValue?: boolean;
  disableGPUToggle: boolean;
  version?: DeploymentVersionDetail;
  preLoadedDefaultInstanceType?: string;
}

export const DeploymentVersionInstanceType = ({
  defaultValue = false,
  disableGPUToggle = false,
  version,
  preLoadedDefaultInstanceType = undefined,
  ...props
}: DeploymentVersionInstanceTypeProps) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <Typography variant="h6">Select hardware</Typography>
    </Grid>
    <Grid item>
      <FormSwitch
        name={FIELD_GPU_INSTANCE_ENABLED}
        label="Enable accelerated hardware"
        infoTooltipText={
          !disableGPUToggle
            ? explanations.deployments.versions.instanceMode.gpu
            : explanations.deployments.versions.instanceMode.gpuDisabled
        }
        defaultValue={defaultValue}
        disabled={disableGPUToggle}
      />
    </Grid>
    {disableGPUToggle && (
      <Grid item>
        <InfoAlert>
          {explanations.deployments.versions.instanceMode.gpuDisabledWithLink}
        </InfoAlert>
      </Grid>
    )}
    <Grid item>
      <InstanceTypeField
        defaultValue={
          preLoadedDefaultInstanceType ||
          version?.[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE] ||
          DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE
        }
        gpuEnabled={!disableGPUToggle}
        isDeploymentVersionForm
        {...props}
      />
    </Grid>
  </Grid>
);

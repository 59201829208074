import Plus from "@mui/icons-material/AddBoxRounded";

import { SecondaryButton } from "components/atoms/Button/SecondaryButton";
import { MaterialIcon } from "components/atoms/Icon/MaterialIcon";

import type { CSSProperties, ReactNode } from "react";

export interface AddButtonProps {
  children?: ReactNode;
  color?: "secondary";
  onClick?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
}

export const AddButton = (props: AddButtonProps) => {
  return (
    <SecondaryButton startIcon={<MaterialIcon component={Plus} />} {...props} />
  );
};

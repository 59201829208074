import { createTheme } from "@mui/material";

import breakpoints from "./breakpoints";
import components from "./components";
import { darkPalette } from "./darkPalette";
import { lightPalette } from "./lightPalette";
import props from "./props";
import typography from "./typography";

import type { PaletteProps } from "./lightPalette";
import type { Theme } from "@mui/material";

export * from "./borders";
export * from "./colors";
export * from "./spacing";
export * from "./shadows";

export const lightTheme: Theme = createTheme({
  palette: lightPalette as PaletteProps,
  components,
  props,
  //@ts-expect-error
  typography,
  breakpoints,
});

export const darkTheme: Theme = createTheme({
  palette: darkPalette as PaletteProps,
  components,
  props,
  //@ts-expect-error
  typography,
  breakpoints,
});

import styled from "@emotion/styled";
import RetryIcon from "@mui/icons-material/RefreshRounded";
import { IconButton, Tooltip } from "@mui/material";

import type { IconButtonProps } from "@mui/material";

const S_IconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "spinCondition",
})<{ spinCondition: boolean }>`
  ${({ spinCondition }) =>
    spinCondition && {
      animation: "$spin 1.5s infinite",
      cursor: "not-allowed",
      pointerEvents: "none",
    }}, 
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
`;

type RetryIconSpinnerProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  spinCondition?: boolean;
  tooltipText?: string;
} & Omit<IconButtonProps, "onClick">;

export const RetryIconSpinner = ({
  disabled,
  onClick,
  spinCondition = false,
  tooltipText = "Retry",
  ...props
}: RetryIconSpinnerProps) => {
  return (
    <Tooltip title={tooltipText}>
      <span>
        <S_IconButton
          color="secondary"
          disabled={disabled}
          spinCondition={spinCondition}
          onClick={onClick}
          {...props}
        >
          <RetryIcon />
        </S_IconButton>
      </span>
    </Tooltip>
  );
};

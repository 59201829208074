import {
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "components/templates/Diagram/constants";
import { NodeTypes } from "components/templates/Diagram/types";

import type {
  NodeDataType,
  EdgeDataType,
} from "components/templates/Diagram/types";
import type { Edge, Node } from "reactflow";

export const getObjectsWithoutDiamondsFromNodes = (
  nodes: Node<NodeDataType>[]
) =>
  nodes
    .filter((node) => node.type !== NodeTypes.diamond)
    .map((node) => node.data.pipelineObject);

export const operatorIsInvalid = (
  node: Node<NodeDataType> | undefined,
  attachments: Edge<EdgeDataType>[]
) => {
  const pipelineObject = node?.data?.pipelineObject;
  const id = node?.id;

  if (
    pipelineObject?.reference_type === OBJECT_REFERENCE_TYPE_OPERATOR &&
    pipelineObject?.reference_name === OPERATOR_ONE_TO_MANY
  ) {
    return !attachments?.find((attachment) => attachment.target === id);
  }

  if (
    pipelineObject?.reference_type === OBJECT_REFERENCE_TYPE_OPERATOR &&
    pipelineObject?.reference_name === OPERATOR_MANY_TO_ONE
  ) {
    return !attachments?.find((attachment) => attachment.source === id);
  }

  return false;
};

export const findEmptyPosition = (nodes: Node<NodeDataType>[]) => {
  const minX = Math.min.apply(Math, [...nodes.map((node) => node.position.x)]);
  const maxY = Math.min.apply(Math, [...nodes.map((node) => node.position.y)]);
  const maxX = Math.max.apply(Math, [...nodes.map((node) => node.position.x)]);
  const minY = Math.min.apply(Math, [...nodes.map((node) => node.position.y)]);
  let position = {
    x: (minX + maxX) / 2,
    y: (minY + maxY) / 2,
  };
  let isPositionEmpty = false;
  while (!isPositionEmpty) {
    if (
      nodes.find(
        (node) =>
          node.position.x <= position.x &&
          position.x <= node.position.x + (node?.width || 200) &&
          node.position.y <= position.y &&
          position.y <= node.position.y + (node?.height || 40)
      )
    )
      position = { x: position.x, y: position.y + 10 };
    else isPositionEmpty = true;
  }

  return position;
};

import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

import { borderRadius, spacing } from "assets/styles/theme";

import { FormTextField } from "components/atoms";

export const NodeContainer = styled(Box)<{
  isSelected?: boolean;
  outlineColor: string;
  hoverColor: string;
  backgroundColor: string;
}>`
  display: flex;
  border-radius: ${borderRadius[8]};
  background: ${(props) => props.backgroundColor};
  width: 100%;
  cursor: auto;
  user-select: none;
  outline: ${(props) =>
    props.isSelected ? `2px dashed ${props.outlineColor}` : ""};

  &:hover {
    background-color: ${(props) => props.hoverColor};
    outline: ${(props) =>
      props.isSelected ? `2px dashed ${props.outlineColor}` : ""};
    cursor: ${(props) => (props.isSelected ? "initial" : "move")};
  }
`;

export const DetailsContainer = styled.div<{ color: string }>`
  display: flex;
  flex: 1;
  align-items: center;

  min-height: ${spacing[40]};
  padding: ${spacing[32]} ${spacing[16]};

  border-left: 2px solid ${(props) => props.color};
  border-right: 2px solid ${(props) => props.color};
`;

export const NodeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const NodeName = styled(Typography)`
  display: flex;
  cursor: auto;
  flex: 1;
  width: 100%;
  align-items: center;
`;

export const ObjectNameTextField = styled(FormTextField)<{
  errorColor: string;
  backgroundColor: string;
}>`
  display: flex;
  cursor: auto;
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: ${spacing[8]};

  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 28px;
    border-radius: ${borderRadius[4]};
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: ${spacing[4]};
  }
  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall {
    position: absolute;
    bottom: 45px;
    left: -75px;
    margin: 0;
    width: 290px;
    font-size: 9px;
    font-weight: 700;
    padding: ${spacing[4]};
    background: ${(props) => props.backgroundColor};
    border: 1px solid ${(props) => props.errorColor};
  }
`;

export const ObjectNameTitle = styled(Typography)`
  cursor: auto;
  justify-content: center;
  line-height: 1.5;
  height: inherit;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentsUpdate,
  useDeploymentsGet,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { DeploymentUpdate } from "libs/data/models";

export const useDeploymentUpdate = (
  projectName: string,
  deploymentName: string
) => {
  const { mutate } = useDeploymentsGet(projectName, deploymentName);
  const { mutate: mutateList } = useDeploymentsList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/deployments`,
    },
  });
  const dispatch = useDispatch();

  return useCallback(
    async (data: DeploymentUpdate) => {
      try {
        const result = await deploymentsUpdate(
          projectName,
          deploymentName,
          data
        );
        await mutate(() => result, { revalidate: false });
        await mutateList(
          (deployments) =>
            deployments?.map((deployment) =>
              deployment.name === result.name ? result : deployment
            ),
          { revalidate: false }
        );
        dispatch(createSuccessNotification("Deployment successfully updated"));

        return result;
      } catch (e: unknown) {
        const error = e as AxiosError;
        dispatch(
          createErrorNotification(error.message ?? "Something went wrong")
        );

        return;
      }
    },
    [deploymentName, dispatch, mutate, mutateList, projectName]
  );
};

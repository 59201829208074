// @ts-nocheck
import { Redirect } from "react-router-dom";

import { useOrganizationUser } from "libs/data/customized/user";
import { routes } from "routes";

import { Loader } from "components/atoms";

import { Route } from "./Route";

import type { RouteProps } from "react-router-dom";

interface AdminRouteProps extends RouteProps {
  organizationName: string;
}

export const AdminRoute = ({
  organizationName,
  component: Component,
  ...rest
}: AdminRouteProps) => {
  const { data, error, isAdmin } = useOrganizationUser(organizationName);
  const isLoadingOrganizationUser = !error && !data;

  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoadingOrganizationUser ? (
          <Loader />
        ) : !error && !isAdmin ? (
          <Redirect
            to={routes.organizations[":organizationName"](
              organizationName
            ).dashboard.index()}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  deploymentEnvironmentVariablesCopy,
  deploymentVersionEnvironmentVariablesCopy,
  useDeploymentEnvironmentVariablesList,
  useDeploymentVersionEnvironmentVariablesList,
} from "libs/data/endpoints/deployments/deployments";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { EnvironmentVariableCopyBody } from "libs/data/models";

export const useDeploymentEnvironmentVariablesCopy = (
  projectName: string,
  deploymentName: string,
  version?: string
) => {
  const dispatch = useDispatch();
  const { mutate: mutateDeploymentEnvVars } =
    useDeploymentEnvironmentVariablesList(projectName, deploymentName);
  const { mutate: mutateDeploymentVersionEnvVars } =
    useDeploymentVersionEnvironmentVariablesList(
      projectName,
      deploymentName,
      version || ""
    );

  return useCallback(
    (environmentVariableCopyBody: EnvironmentVariableCopyBody) => {
      if (!version) {
        try {
          deploymentEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            environmentVariableCopyBody
          );
          mutateDeploymentEnvVars();
        } catch (e) {
          dispatch(createErrorNotification((e as AxiosError)?.message));
        }
      } else
        try {
          deploymentVersionEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            version,
            environmentVariableCopyBody
          );
          mutateDeploymentVersionEnvVars();
        } catch (e) {
          dispatch(createErrorNotification((e as AxiosError)?.message));
        }
    },
    [
      deploymentName,
      dispatch,
      mutateDeploymentEnvVars,
      mutateDeploymentVersionEnvVars,
      projectName,
      version,
    ]
  );
};

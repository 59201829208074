import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const EngineeringTeamIllustration = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="100%"
      height="132"
      viewBox="0 0 259 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6600_43610)">
        <path
          d="M247.504 72.9769L246.692 79.4185L244.679 95.4181H204.227C204.543 91.8321 204.975 86.6705 205.436 81.0984C205.481 80.5419 205.529 79.982 205.575 79.4185C206.731 65.4119 207.997 49.5618 207.997 49.5618L213.315 50.5496L223.633 52.4626L227.838 53.2417L239.097 55.3321C244.711 56.3721 248.572 64.4762 247.504 72.9769Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M216.906 23.6425L221.88 32.3221L212.319 39.0782L208.24 28.4092L216.906 23.6425Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M210.573 31.8538C217.379 31.8538 222.896 26.3525 222.896 19.5662C222.896 12.78 217.379 7.27867 210.573 7.27867C203.766 7.27867 198.249 12.78 198.249 19.5662C198.249 26.3525 203.766 31.8538 210.573 31.8538Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M209.688 22.3132C208.709 20.8283 209.093 20.0516 208.274 19.5059C208.274 19.5059 207.944 19.2863 205.585 19.1611C205.057 15.3704 204.397 14.9107 204.397 14.9107C203.637 14.3809 202.51 14.553 201.808 14.8771C200.324 15.5624 200.496 17.0428 199.497 17.3261C198.117 17.7174 195.605 15.5113 195.358 12.9857C195.156 10.9255 196.487 8.92456 197.015 9.03041C197.539 9.13536 198.792 7.5399 199.469 7.56451C199.931 7.58132 201.819 6.76294 202.327 6.82693C202.882 6.89681 204.711 7.84858 204.788 7.63113C205.317 6.12006 205.162 5.7008 205.611 5.26852C206.408 4.50251 207.71 5.03594 209.754 5.32231C213.96 5.91153 214.694 4.52895 216.849 5.46607C217.937 5.93946 218.848 7.21613 220.626 9.75015C223.112 13.2939 224.355 15.0658 224.362 17.1325C224.371 19.2401 223.347 19.1063 222.897 22.2265C222.397 25.6998 223.46 27.2879 222.299 28.4163C221.401 29.2884 219.576 29.4954 218.617 28.73C216.975 27.4196 219.124 24.2575 217.307 21.9473C216.192 20.5292 213.949 19.8982 212.922 20.5992C211.76 21.3918 212.4 23.7183 211.583 23.9389C210.884 24.1278 209.858 22.572 209.688 22.3132Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M238.395 70.7083C238.051 76.5684 236.686 78.4185 236.208 85.3393C236.103 86.8587 236.041 88.6224 236.041 90.7257C236.041 91.2619 236.043 91.7804 236.043 92.2869C236.043 93.3653 236.023 94.3843 235.897 95.418C235.802 96.2433 235.637 97.0745 235.365 97.9504H208.823C208.82 97.9444 208.82 97.9384 208.817 97.9325C208.662 97.2592 208.563 96.6157 208.488 95.999C208.465 95.8024 208.444 95.6087 208.426 95.418C208.402 95.1946 208.381 94.9742 208.36 94.7567C208.279 93.8897 208.199 93.0704 208.007 92.2869C207.751 91.2054 207.284 90.1865 206.328 89.2034C205.937 88.8012 205.545 88.4884 205.163 88.2143C205.088 88.1636 205.016 88.113 204.942 88.0623C203.884 87.3383 202.883 86.817 202.017 85.3393C202.002 85.3185 201.99 85.2946 201.978 85.2738C201.828 85.0146 201.44 84.3352 201.159 83.4297C200.863 82.4882 200.684 81.3054 201.007 80.0959C201.775 77.2299 204.619 76.9439 206.944 74.048C207.195 73.7353 207.443 73.3896 207.679 73.0053C208.73 71.3011 209.014 69.7997 209.349 68.0151C209.403 67.726 209.451 67.44 209.489 67.1631C209.519 66.9545 209.546 66.7489 209.567 66.5463C209.752 64.8451 209.663 63.3794 209.445 62.0686C208.734 57.7754 206.636 55.118 208.118 51.2658C208.584 50.0564 209.245 49.1476 209.911 48.3701C211.321 46.7225 212.77 45.653 212.519 43.5259C212.265 41.3808 210.634 41.0858 210.452 39.2118C210.359 38.2645 210.67 37.2665 211.252 36.2863V36.2834C213.117 33.1283 217.772 30.2235 220.73 30.3159C221.277 30.3309 221.761 30.4529 222.164 30.6883C223.626 31.5463 223.016 33.4202 225.254 36.048C226.918 37.9993 228.801 38.7799 229.515 39.1493C232.344 40.6212 234.645 44.9618 236.208 50.5121C236.22 50.5539 236.232 50.5955 236.244 50.6403C237.209 54.117 237.89 58.0645 238.228 62.0686C238.371 63.7637 238.455 65.4679 238.473 67.154C238.488 68.5007 238.458 69.6686 238.395 70.7083Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M218.519 90.7854L217.978 92.2869L217.638 93.2283L216.849 95.418L215.934 97.9504H210.057L211.043 95.418L211.5 94.2383L212.259 92.2869L213.532 89.0098L218.519 90.7854Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M236.3 49.9996C236.286 50.1934 236.265 50.4078 236.244 50.6403C236.011 53.0058 235.461 57.2392 234.657 62.0686C234.233 64.6157 233.737 67.3299 233.178 70.0261C231.977 75.8444 230.483 81.5736 228.795 85.3393C228.493 86.0186 228.182 86.6324 227.865 87.1745C227.623 87.5887 227.369 87.9967 227.103 88.3989C226.243 89.7188 225.278 90.9701 224.277 92.138C224.238 92.1886 224.193 92.2363 224.148 92.2869C223.987 92.4775 223.82 92.6653 223.652 92.8529C222.834 93.7735 221.997 94.6315 221.187 95.418C220.201 96.3774 219.251 97.2265 218.4 97.9504H208.856L208.817 97.9325L207.443 97.3247C207.443 97.3247 207.834 96.8659 208.488 95.999C208.623 95.8232 208.766 95.6295 208.922 95.418C209.113 95.1588 209.319 94.8728 209.54 94.5629C210 93.9136 210.523 93.1509 211.085 92.2869C212.173 90.6155 213.412 88.5598 214.665 86.1795C214.808 85.9024 214.954 85.6223 215.101 85.3393C215.104 85.3304 215.11 85.3214 215.113 85.3125C215.361 84.8298 215.606 84.3323 215.851 83.8258C217.927 79.5178 218.522 75.9428 218.693 74.7839C218.931 73.1454 219.263 70.8542 219.173 68.3338C219.114 66.6715 218.872 64.9137 218.295 63.1679V63.165C218.175 62.7985 218.041 62.4321 217.889 62.0685C217.617 61.4161 217.294 60.7726 216.911 60.138C215.223 57.3435 213.852 57.2273 212.761 54.1825C212.438 53.2798 211.706 51.2421 211.93 49.091V49.0881C211.958 48.8229 212.002 48.5548 212.062 48.2866V48.2836C212.077 48.2092 212.098 48.1318 212.119 48.0573C212.289 47.3989 212.57 46.7463 212.997 46.1266C213.831 44.9231 214.969 44.208 215.988 43.7821L215.991 43.779C217.168 43.2905 218.184 43.1862 218.382 43.1683C222.747 42.7811 226.132 46.4395 227.982 48.4386C228.696 49.2101 229.351 49.8568 229.948 50.3839C234.6 54.5252 235.876 51.8795 236.208 50.5121C236.258 50.3006 236.286 50.1189 236.3 49.9996Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M236.315 49.8448C236.315 49.8597 236.312 49.9103 236.3 49.9938C236.306 49.949 236.31 49.9043 236.312 49.8626C236.312 49.8537 236.312 49.8478 236.315 49.8448Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M212.585 112.709H52.415C50.9269 112.709 49.7139 113.916 49.7139 115.4C49.7139 116.886 50.9269 118.093 52.415 118.093H212.585C214.073 118.093 215.283 116.886 215.283 115.4C215.283 113.916 214.073 112.709 212.585 112.709Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M177.04 126.617H87.9568C86.4688 126.617 85.2588 127.823 85.2588 129.307C85.2588 130.79 86.4688 132 87.9568 132H177.04C178.528 132 179.741 130.79 179.741 129.307C179.741 127.823 178.528 126.617 177.04 126.617Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M70.238 24.2136L80.3324 19.9778L84.6621 28.8278L74.0543 33.1448L70.238 24.2136Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          opacity="0.1"
          d="M70.238 24.2136L80.3324 19.9778L84.6621 28.8278L74.0543 33.1448L70.238 24.2136Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M71.673 20.8412L81.6313 32.2814C81.6313 32.2814 74.7038 54.0825 67.7763 56.0251C60.8487 57.9678 48.0761 67.4653 48.0761 67.4653L32.0562 57.1044L39.8497 45.6642C39.8497 45.6642 58.6839 19.9778 63.663 19.9778C68.6422 19.9778 71.673 20.8412 71.673 20.8412Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M58.2185 95.4181H35.0372L33.5716 93.4647L27.588 85.4889L26.2111 83.6542C26.2111 83.6542 21.0155 74.1567 22.0979 65.5226C23.1803 56.8885 34.4376 54.9459 34.4376 54.9459H34.9008L48.6823 67.0206L47.8596 78.2579L52.224 85.4889L58.2185 95.4181Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M39.7848 95.4181H33.5716V86.2444L39.7848 95.4181Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M173.793 81.4914L173.021 85.4889L171.103 95.4181H132.57C132.871 93.1927 133.283 89.9895 133.722 86.5315C133.765 86.1861 133.811 85.8386 133.854 85.4889C134.956 76.7966 136.162 66.9602 136.162 66.9602L141.228 67.5732L151.056 68.7604L155.061 69.2439L165.786 70.5412C171.133 71.1866 174.811 76.2159 173.793 81.4914Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M228.324 95.4181H207.424C207.743 95.0447 207.922 94.8289 207.922 94.8289L214.187 93.7971L227.096 91.6731C227.096 91.6731 227.685 93.1905 228.324 95.4181Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M139.674 28.531L148.763 41.347L132.909 41.8191L131.207 29.775L139.674 28.531Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          opacity="0.1"
          d="M139.674 28.531L148.763 41.347L132.909 41.8191L131.207 29.775L139.674 28.531Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M94.8518 55.0849C93.6856 51.7305 91.0045 49.6115 88.8636 50.3519C86.7228 51.0922 85.9331 54.4112 87.1002 57.7666C87.548 59.1133 88.3127 60.3335 89.3304 61.3252L94.4516 75.4755L101.06 72.9377L95.3099 59.2574C95.4936 57.8501 95.3366 56.4193 94.8518 55.0849Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M156.374 48.5545L156.362 61.6157L155.143 66.44L152.636 76.3627L150.811 85.4889L149.452 92.2883L148.967 94.7166C148.601 94.9605 148.237 95.1936 147.874 95.4181H116.208C114.409 94.341 113.403 93.5035 113.403 93.5035C113.403 93.5035 113.901 93.0373 114.47 92.2883C115.431 91.0234 116.594 88.9512 115.881 86.9718C115.732 86.5574 115.693 86.0523 115.739 85.4889C116.029 81.772 119.938 75.4863 119.938 75.4863L119.129 66.1572L118.774 62.069L121.277 42.1349L129.436 37.4056L130.845 37.2697L144.75 36.5207L150.497 36.851L150.759 36.9222L156.374 48.5545Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M156.992 95.4181H150.064L149.759 92.2883L149.753 92.2257L151.714 92.0918L156.817 91.7443L156.905 93.6179L156.975 95.0555L156.992 95.4181Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M158.697 51.7124L161.607 68.7367L159.233 85.4889L158.862 88.0964L158.209 92.7005L158.007 94.1165L158.014 94.2698L158.064 95.4181H149.837L149.534 92.2883L149.508 92.0077L148.889 85.5969L148.878 85.4889L147.564 71.8946L150.14 62.069L150.331 61.3459V61.3437L151.452 44.5202L150.584 36.8747L150.575 36.8035C150.592 36.8143 150.607 36.8251 150.625 36.8359C150.67 36.8639 150.716 36.892 150.759 36.9222C155.816 40.1212 158.829 45.7333 158.697 51.7124Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M124.224 45.3971L121.061 42.1347C121.061 42.1347 114.289 45.1787 113.951 46.8676C113.613 48.5564 101.657 73.3605 101.657 73.3605L96.4545 59.5075L89.6832 64.0151C89.6832 64.0151 95.1165 85.5228 100.198 86.5325C105.279 87.5422 122.309 61.0761 122.309 61.0761L124.224 45.3971Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M252.674 85.3393H6.32259C2.83565 85.3393 0 88.1695 0 91.6463C0 95.123 2.83565 97.9504 6.32259 97.9504H252.674C256.161 97.9504 259 95.1232 259 91.6463C259 88.1694 256.161 85.3393 252.674 85.3393Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M154.933 66.1209L154.454 88.4063C154.407 90.5636 152.64 92.2883 150.476 92.2883H109.361C107.248 92.2883 105.504 90.6415 105.388 88.5379L104.166 66.2525C104.042 63.9799 105.856 62.069 108.139 62.069H150.955C153.186 62.069 154.981 63.8973 154.933 66.1209Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M88.5588 29.5931C93.9916 29.5931 98.3957 25.2018 98.3957 19.7849C98.3957 14.368 93.9916 9.97676 88.5588 9.97676C83.126 9.97676 78.7219 14.368 78.7219 19.7849C78.7219 25.2018 83.126 29.5931 88.5588 29.5931Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M72.106 45.4484L73.1884 57.1044L80.5489 86.6761L74.1899 88.8984L63.4466 56.6727L61.9627 43.7215L72.106 45.4484Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M69.1781 66.1209L68.6991 88.4063C68.6528 90.5636 66.8855 92.2883 64.7214 92.2883H23.6062C21.4933 92.2883 19.7489 90.6415 19.6336 88.5379L18.4117 66.2525C18.2871 63.9799 20.1017 62.069 22.3844 62.069H65.2004C67.4311 62.069 69.2258 63.8973 69.1781 66.1209Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M81.7209 92.4389C86.0115 92.4389 89.4896 90.905 89.4896 89.0128C89.4896 87.1206 86.0115 85.5867 81.7209 85.5867C77.4304 85.5867 73.9522 87.1206 73.9522 89.0128C73.9522 90.905 77.4304 92.4389 81.7209 92.4389Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M56.8438 47.2304L61.2817 23.6473C61.2817 23.6473 73.6214 21.9205 74.0544 26.0217C74.4873 30.1229 73.745 50.166 73.745 50.166L56.8438 47.2304Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M84.5879 21.3271C84.5879 21.3271 90.673 19.1617 93.2398 17.9966C95.8067 16.8315 100.906 24.958 101.02 20.6661C101.133 16.3742 99.3709 14.5744 99.3709 14.5744C99.3709 14.5744 98.9309 5.18536 89.4439 6.35922C89.4439 6.35922 91.5306 1.66176 84.6809 0.935389C77.8311 0.209014 67.1745 16.7018 68.1289 19.1665C69.0833 21.6313 70.2273 23.669 67.5197 26.3043C64.8121 28.9397 59.7419 43.813 63.5995 47.8153C67.4571 51.8176 67.7102 53.5544 65.0671 56.0443C62.4241 58.5343 56.1767 63.9483 62.9316 64.8882C69.6865 65.8281 62.3136 65.1261 67.3495 60.7095C72.3854 56.2928 79.7338 56.4732 76.99 49.387C74.2462 42.3009 79.0132 32.1465 78.4167 30.606C77.8203 29.0655 79.7531 19.2834 79.7531 19.2834C79.7531 19.2834 84.2515 18.6245 84.5879 21.3271Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M134.814 33.1697C140.728 33.1697 145.523 28.3895 145.523 22.4928C145.523 16.5961 140.728 11.8159 134.814 11.8159C128.9 11.8159 124.106 16.5961 124.106 22.4928C124.106 28.3895 128.9 33.1697 134.814 33.1697Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M135.669 8.13849C135.359 8.31884 134.944 8.04598 134.845 7.70224C134.745 7.35849 134.861 6.99353 134.976 6.6546L135.554 4.94834C135.963 3.73823 136.398 2.48584 137.286 1.56478C138.625 0.174602 140.754 -0.179053 142.67 0.077158C145.131 0.406184 147.559 1.73482 148.703 3.93188C149.848 6.12895 149.36 9.19465 147.293 10.5654C150.24 13.9329 151.267 17.6859 151.105 22.1521C150.942 26.6182 146.061 30.7285 142.878 33.8748C142.167 33.4451 141.521 31.4315 141.912 30.7C142.303 29.9685 141.742 29.1211 142.227 28.4473C142.711 27.7735 143.116 28.8464 142.626 28.1764C142.317 27.7537 143.523 26.7811 143.064 26.5281C140.843 25.3042 140.105 22.5445 138.71 20.4312C137.028 17.8821 134.149 16.1559 131.102 15.8695C129.424 15.7117 127.651 15.9974 126.275 16.969C124.899 17.9405 124.009 19.6767 124.328 21.3272C123.502 20.4908 123.09 19.265 123.245 18.1012C123.401 16.9375 124.119 15.8613 125.135 15.2691C124.517 13.2309 125.047 10.8863 126.481 9.30909C127.916 7.73184 133.736 8.00048 135.831 8.41558L135.669 8.13849Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M135.994 15.9313C138.769 16.23 140.772 18.6261 142.463 20.8389C143.438 22.1143 144.459 23.5228 144.435 25.1262C144.41 26.7473 143.323 28.1378 142.804 29.6741C141.954 32.1853 142.782 35.173 144.803 36.8937C142.806 37.2716 140.647 35.7783 140.302 33.7806C139.901 31.455 141.669 29.2105 141.459 26.8599C141.275 24.7891 139.638 23.1953 138.247 21.6469C136.855 20.0986 135.548 18.0439 136.188 16.0653L135.994 15.9313Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M177.47 86.6888C174.722 88.2472 173.301 90.9242 174.296 92.6677C175.291 94.4113 178.324 94.561 181.073 93.0016C182.18 92.3938 183.124 91.5305 183.827 90.4841L195.387 83.7344L192.095 78.3932L181.049 85.6142C179.787 85.6823 178.559 86.0508 177.47 86.6888Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M216.608 37.1929C216.263 37.0564 213.232 35.912 210.464 37.423C207.286 39.1588 206.795 43.1212 206.623 44.511C206.225 47.7214 207.397 48.441 207.644 51.6911C208.107 57.7718 209.327 50.8349 207.378 53.6105C206.704 54.5712 199.814 69.4594 196.014 72.3756C188.623 78.0473 183.931 82.0415 183.931 82.0415L189.288 87.8443C189.288 87.8443 197.754 85.5951 205.237 80.7507C212.912 75.7813 216.75 73.2966 219.719 68.436C219.975 68.0174 226.093 57.6583 222.807 46.2037C222.058 43.5909 220.685 38.8037 216.608 37.1929Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M244.273 66.1209L243.794 88.4063C243.748 90.5636 241.98 92.2883 239.816 92.2883H198.701C196.588 92.2883 194.844 90.6415 194.728 88.5379L193.507 66.2525C193.382 63.9799 195.197 62.069 197.479 62.069H240.295C242.526 62.069 244.321 63.8973 244.273 66.1209Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_6600_43610">
          <rect width="259" height="132" fill={theme.palette.svgs.paperWhite} />
        </clipPath>
      </defs>
    </svg>
  );
};

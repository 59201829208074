import { useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { parseAuditParams } from "components/organisms/Audit/utils";
import {
  useDeploymentAuditEventsList,
  useDeploymentsGet,
} from "libs/data/endpoints/deployments/deployments";
import { useGoogleAnalytics } from "libs/hooks";

import { Loader } from "components/atoms";
import { Audit } from "components/organisms";

import type { DeploymentDetailsRouteParams } from "./types";
import type { DeploymentAuditEventsListAction } from "libs/data/models";

export const DeploymentAuditEvents = () => {
  useGoogleAnalytics();
  const [params, setParams] = useState<{
    action?: DeploymentAuditEventsListAction;
    offset: number;
  }>({
    action: undefined,
    offset: 0,
  });

  const match = useRouteMatch();

  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const { data: deployment, error: deploymentError } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const { data: auditLogs, isValidating } = useDeploymentAuditEventsList(
    projectName,
    deploymentName,
    parseAuditParams(params.offset, params.action),
    {}
  );

  const deploymentLoading = !deployment && !deploymentError;

  const handleSetAction = (value?: DeploymentAuditEventsListAction) => {
    setParams({
      action: value,
      offset: 0,
    });
  };

  return (
    <>
      <BreadcrumbsItem to={match.url}>Audit events</BreadcrumbsItem>
      {!deploymentLoading ? (
        <Audit
          action={params.action}
          auditLogs={auditLogs ?? []}
          loading={isValidating}
          offset={params.offset}
          projectName={projectName}
          setAction={handleSetAction}
          setOffset={(value: number) => setParams({ ...params, offset: value })}
          showObjectInfo={false}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

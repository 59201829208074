import type {
  PipelineVersionObjectCreate,
  PipelineVersionObjectCreateConfiguration,
  PipelineVersionObjectCreateReferenceType,
} from "libs/data/models";
import type { Node } from "reactflow";

export const getCurrentObjects = (
  nodes: Node[]
): PipelineVersionObjectCreate[] =>
  nodes
    .filter(
      (node) =>
        !["diamond", "pipeline_start", "pipeline_end"].includes(node?.data.type)
    )
    .map((node) => {
      const pipelineObject = node.data.pipelineObject;
      const type: PipelineVersionObjectCreateReferenceType =
        pipelineObject.reference_type === "deployment"
          ? "deployment"
          : pipelineObject.reference_type === "pipeline"
          ? "pipeline"
          : "operator";

      const hasBatchSize = Object.keys(
        pipelineObject.configuration || {}
      ).includes("batch_size");

      const batch_size = hasBatchSize
        ? pipelineObject.configuration?.batch_size || null
        : undefined;

      return {
        name: pipelineObject.name,
        reference_type: type,
        reference_name: pipelineObject.reference_name,
        version: pipelineObject.version,
        metadata: {
          position: node.position,
          height: node.height,
          width: node.width,
        },
        // @ts-ignore
        configuration: {
          ...pipelineObject.configuration,
          batch_size,
        } as PipelineVersionObjectCreateConfiguration,
      };
    });

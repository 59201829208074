import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  requestSchedulesDelete,
  useRequestSchedulesList,
} from "libs/data/endpoints/request-schedules/request-schedules";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useRequestScheduleDelete = (projectName: string) => {
  const dispatch = useDispatch();

  const { mutate } = useRequestSchedulesList(projectName);

  return useCallback(
    async (name: string) => {
      try {
        await requestSchedulesDelete(projectName, name);
        dispatch(createSuccessNotification("Request schedule was deleted."));
        mutate();
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, projectName, mutate]
  );
};

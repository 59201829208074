import { Typography } from "@mui/material";

import type { TypographyProps } from "@mui/material";

/**
 * We use the Text Typography as default Text component, for example for paragraphs within forms.
 */
export const Text = ({ ...props }: TypographyProps) => {
  return <Typography variant="body2" {...props} />;
};

import { Axios } from "libs/data/axios";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { store } from "store/store";

import { getAuthorizationHeader, signOut } from "./utilities";

import type { AxiosError, AxiosRequestConfig } from "axios";

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: ((accessToken: string) => void)[] = [];
let isAlreadyFetchingAccessToken = false;

export async function refreshToken(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  try {
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from therequest. */
    const originalRequest = new Promise<AxiosRequestConfig>((resolve) => {
      /* We need to add the request retry to the queue
      since there another request that already attempt to
      refresh the token */
      subscribers.push((accessToken: string) => {
        if (config.headers)
          config.headers.Authorization = getAuthorizationHeader(accessToken);
        resolve(config);
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const response = await Axios({
        method: "POST",
        url: env.get(ENV_NAMES.HOST) + "/authorize/refresh-token",
        data: {
          refresh: env.get(ENV_NAMES.REFRESH_TOKEN),
        },
      });

      if (!response?.data) {
        return Promise.reject(response);
      }

      const newAccessToken = response.data.access;
      env.set(ENV_NAMES.ACCESS_TOKEN, newAccessToken);
      // When the refresh is successful, we start retrying the requests one by one and empty the queue
      subscribers.forEach((callback) => callback(newAccessToken));
      subscribers = [];

      isAlreadyFetchingAccessToken = false;
    }

    return originalRequest;
  } catch (err: unknown) {
    const axiosError = err as AxiosError<any, any>;

    if (axiosError?.response?.data?.error === "Token revoked") {
      signOut(store);
    }

    return Promise.reject(err);
  }
}

import styled from "@emotion/styled";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { DateTimeRangeInput } from "components/molecules/Fields/DateTimeRangeSelector";
import {
  FIELD_OPTION,
  FIELD_VALUE_DROPDOWN,
  FIELD_VALUE_TEXT,
  FIELD_DATE,
} from "libs/constants/fields";
import {
  useDeploymentsList,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import {
  usePipelinesList,
  usePipelineVersionsGet,
  usePipelineVersionsList,
} from "libs/data/endpoints/pipelines/pipelines";
import { useWebhooksList } from "libs/data/endpoints/webhooks/webhooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import validators from "libs/utilities/validators";
import { useGetCurrentProject } from "store/features";

import {
  FormTextField,
  SecondaryButton,
  Switch,
  TextButton,
} from "components/atoms";

import {
  systemOptions,
  updateOptions,
  logLevels,
  getFiltersFromUrl,
} from "./utils";

import type { Filter, Filters } from "./types";
import type {
  DateRange,
  FormRange,
} from "components/molecules/Fields/DateTimeRangeSelector/types";
import type { DateType } from "libs/utilities/date-util";
import type { Moment } from "moment";

type LoggingFiltersProps = {
  onAdd: (filter: Filter) => void;
  onClear: () => void;
  disabled: boolean;
  handleAutoFetch: ({
    target: { checked },
  }: {
    target: { checked: boolean };
  }) => void;
  handleDateChange: (value: DateRange) => void;
  onRefresh?: () => void;
  defaultDate?: DateType | Moment | null;
  isFetchLogsAutomaticallyEnabled: boolean;
};

const LoggingFilters = ({
  onAdd,
  onClear,
  disabled = false,
  handleDateChange,
  handleAutoFetch,
  isFetchLogsAutomaticallyEnabled,
  onRefresh,
}: LoggingFiltersProps) => {
  const location = useLocation();
  const theme = useTheme();
  const { projectName } = useParams<{ projectName: string }>();

  const { handleSubmit, setValue, control } = useFormContext();

  const filterField: { dropdown?: boolean } | undefined = useWatch({
    name: FIELD_OPTION,
    control,
  });
  const dateRange = useWatch<FormRange>({ name: FIELD_DATE, control });
  const currentProjectName = useGetCurrentProject()?.name;

  const [options, setOptions] = useState<Options[]>([]);
  const [optionValues, setOptionValues] = useState<any | undefined>([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const { deployment_name, pipeline_name, pipeline_version } =
    getFiltersFromUrl(new URLSearchParams(location.search));

  const { data: environments } = useEnvironmentsList(projectName, {
    environment_type: "custom",
  });
  const { data: deployments } = useDeploymentsList(projectName);
  const { data: deploymentVersions } = useDeploymentVersionsList(
    projectName,
    deployment_name ?? ""
  );

  const { data: pipelines } = usePipelinesList(projectName);
  const { data: pipelineVersions } = usePipelineVersionsList(
    projectName,
    pipeline_name ?? ""
  );

  const { data: webhooks } = useWebhooksList(projectName);

  useEffect(() => {
    setOptions(updateOptions(location.search));
  }, [location.search]);

  const { data: pipelineVersion } = usePipelineVersionsGet(
    (!!pipeline_version && currentProjectName) || "",
    pipeline_name || "",
    pipeline_version || ""
  );

  const handleDateSelect = (range: DateRange) => {
    setValue(FIELD_DATE, {
      startDate: getTzAwareDate(range.from),
      endDate: getTzAwareDate(range.to),
    });
    handleDateChange(range);
  };

  const resetOptions = () => {
    setValue(FIELD_VALUE_DROPDOWN, null);
    setValue(FIELD_VALUE_TEXT, null);
    setOptionValues([]);
  };

  const resetValues = () => {
    setValue(FIELD_OPTION, null);
    resetOptions();
  };

  const handleClear = () => {
    resetValues();
    onClear();
    setIsFilterSelected(false);
  };

  const onSubmit = (data: {
    [x: string]: {
      name: string;
      version: string;
      value: string;
    };
  }) => {
    const newFilter: Partial<Filter> = {};
    newFilter.key = data[FIELD_OPTION].value as keyof Filters;
    if (data[FIELD_VALUE_TEXT]) {
      newFilter.value = data[FIELD_VALUE_TEXT] as unknown as string;
    } else {
      newFilter.value =
        data[FIELD_VALUE_DROPDOWN]?.value ??
        data[FIELD_VALUE_DROPDOWN]?.name ??
        data[FIELD_VALUE_DROPDOWN]?.version;
    }

    onAdd(newFilter as Filter);
    resetValues();
  };

  const handleFilterChange = (option: { value: string }) => {
    switch (option?.value) {
      case "deployment_name": {
        // This resets any previously selected option first
        resetOptions();
        setOptionValues(deployments);
        break;
      }
      case "deployment_version": {
        resetOptions();
        setOptionValues(deploymentVersions);
        break;
      }
      case "pipeline_name": {
        resetOptions();
        setOptionValues(pipelines);
        break;
      }
      case "pipeline_version": {
        resetOptions();
        setOptionValues(pipelineVersions);
        break;
      }
      case "pipeline_object_name": {
        resetOptions();
        setOptionValues(pipelineVersion?.objects);
        break;
      }
      case "system": {
        resetOptions();
        setOptionValues(systemOptions);
        break;
      }
      case "level": {
        resetOptions();
        setOptionValues(logLevels);
        break;
      }
      case "environment_name": {
        resetOptions();
        setOptionValues(environments);
        break;
      }
      case "webhook_name": {
        resetOptions();
        setOptionValues(webhooks);
        break;
      }
      default: {
        resetOptions();
        setOptionValues([]);
      }
    }
  };

  const optionDropdownValues = useMemo(
    () =>
      optionValues?.map(({ name, version }: any) => {
        const value = name || version;

        return {
          value,
          label: value,
        };
      }),
    [optionValues]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      style={{ paddingBottom: "16px" }}
    >
      {isFilterSelected ? (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: "8px",
            flex: 1,
          }}
        >
          <Box style={{ width: "200px", minWidth: "200px" }}>
            <AutoCompleteSelectHookForm
              name={FIELD_OPTION}
              defaultValue={undefined}
              placeholder="Select..."
              options={options?.filter(({ isDisabled }) => !isDisabled)}
              isSearchable={true}
              onChange={handleFilterChange}
            />
          </Box>
          {filterField && (
            <>
              {filterField.dropdown ? (
                <Box style={{ width: "200px", minWidth: "200px" }}>
                  <AutoCompleteSelectHookForm
                    name={FIELD_VALUE_DROPDOWN}
                    options={optionDropdownValues || []}
                    defaultValue={optionDropdownValues?.[0]}
                    isSearchable={true}
                  />
                </Box>
              ) : (
                <FormTextField
                  rules={{
                    required: validators.required.message("id"),
                  }}
                  id={FIELD_VALUE_TEXT}
                  name={FIELD_VALUE_TEXT}
                  placeholder="value"
                  style={{ margin: 0, width: "200px" }}
                />
              )}
              <SecondaryButton type="submit">Add</SecondaryButton>
            </>
          )}
          <TextButton
            style={{
              color: theme.palette.text.primary,
              textTransform: "none",
              textDecoration: "underline",
              fontWeight: 400,
            }}
            onClick={handleClear}
          >
            Clear all
          </TextButton>
        </Form>
      ) : (
        <SecondaryButton
          startIcon={<FilterListIcon />}
          onClick={() => setIsFilterSelected(true)}
          disabled={disabled}
        >
          Filter
        </SecondaryButton>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
        gap={spacing[8]}
      >
        <DateTimeRangeInput
          name={FIELD_DATE}
          handleDateChange={handleDateSelect}
          onRangeInputClick={() =>
            handleAutoFetch({ target: { checked: false } })
          }
          hasRangeChip={false}
          withPredefinedRanges={false}
          customRangeButtonText="Time range"
          defaultValue={dateRange}
        />
        <SecondaryButton
          onClick={onRefresh}
          disabled={disabled}
          style={{ flexShrink: 0 }}
        >
          Jump to now
        </SecondaryButton>{" "}
        <Switch
          checked={isFetchLogsAutomaticallyEnabled}
          disabledTooltipText="Unable to automatically load logs since the requested date is in the past"
          onChange={handleAutoFetch}
          infoTooltipText="Logs are fetching automatically, disable toggle to stop fetching logs"
          label="Auto load logs"
        />
      </Box>
    </Box>
  );
};

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  > * {
    max-width: 200px;
  }
`;

type Options = {
  label: string;
  value: string;
  isDisabled: boolean;
  isSelected: boolean;
  dropdown: boolean;
};

export { LoggingFilters };

import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_NAME } from "libs/constants/fields";
import { useRequestScheduleCreate } from "libs/data/customized/request-schedules";
import { useRequestSchedulesGet } from "libs/data/endpoints/request-schedules/request-schedules";
import { useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";

import { FormWrapper, Loader } from "components/atoms";
import { PageContainer } from "components/molecules";

import { RequestScheduleBaseForm } from "../RequestScheduleBaseForm";

import type { FormikHelpers } from "formik";
import type { ScheduleCreate } from "libs/data/models";

export const RequestScheduleDuplicate = () => {
  useGoogleAnalytics();

  const history = useHistory();

  const { organizationName, projectName, scheduleName } =
    useParams<{
      organizationName: string;
      projectName: string;
      scheduleName: string;
    }>();
  const { data: scheduleDetails, error } = useRequestSchedulesGet(
    projectName,
    scheduleName
  );
  const isLoading = !scheduleDetails && !error;

  const createRequestSchedule = useRequestScheduleCreate(projectName);

  const handleScheduleCreate = async (
    data: ScheduleCreate,
    actions: FormikHelpers<ScheduleCreate>
  ) => {
    try {
      const result = await createRequestSchedule(data);
      result &&
        history.push(
          routes.organizations[":organizationName"](organizationName)
            .projects[":projectName"](projectName)
            .requestSchedules[":scheduleName"](data[FIELD_NAME])
            .index()
        );
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <PageHeader title={`Duplicate ${scheduleName}`} />
      {isLoading ? (
        <Loader />
      ) : (
        <FormWrapper>
          <RequestScheduleBaseForm
            handleOnSubmit={handleScheduleCreate}
            defaultValues={
              {
                ...scheduleDetails,
                name: scheduleDetails?.name + "-copy",
              } as ScheduleCreate
            }
          />
        </FormWrapper>
      )}
    </PageContainer>
  );
};
